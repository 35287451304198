import * as React from 'react'

function SvgCreditCardF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 20" {...props}>
      <path
        d="M25 0H3a3.009 3.009 0 00-3 3v17h25a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3zM14 16H3v-2h11v2zm12-8H2V6h24v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCreditCardF
