import * as React from 'react'

function SvgTapeF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 13" {...props}>
      <path
        d="M28 7a6.005 6.005 0 01-6 6H6a6.022 6.022 0 114.46-2h7.08A5.996 5.996 0 1128 7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTapeF
