import isEmpty from 'lodash/isEmpty'

export function getInvalidFileMessage(content: string): string {
  if (isEmpty(content) || content === 'field,value')
    return 'The CSV file does not contain any data.'
  // invalid CSV type (i.e. Macintosh Comma Separated)
  if (content.indexOf('\n') === -1)
    return 'Invalid CSV File Type. Expected "Comma Seperated Values (.csv) or MS-DOS Comma Seperated (.csv)"'
  const rowsArray = content.replace(/\r/g, '').split('\n')
  const headerRow = rowsArray[0]
  if (headerRow !== 'field,value')
    return 'Invalid CSV column headers. Expected "field" and "value".'
  if (rowsArray.length > 501)
    return 'Exceeded CSV row limit. There cannot be more than 500 rows (excluding column headers).'
  return ''
}
