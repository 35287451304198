import * as React from 'react'

function SvgMicrophoneF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M2 12c0 4.41 4.487 8 10 8 5.514 0 10-3.59 10-8h2c0 5.232-4.85 9.531-11 9.957V26h3a2 2 0 012 2H6a2 2 0 012-2h3v-4.043C4.85 21.531 0 17.232 0 12zM16 0a3.009 3.009 0 013 3v8c0 2.99-2.16 6-7 6s-7-3.01-7-6V3a3.009 3.009 0 013-3zm-1 9H9v2h6V9zm0-4H9v2h6V5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMicrophoneF
