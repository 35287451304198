import * as React from 'react'

function SvgBank(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M14.51.724a3 3 0 00-3.022 0L0 7.425V11h4v9H2v2h22v-2h-2v-9h4V7.425L14.51.725zM6 11h6v9H6v-9zm14 9h-6v-9h6v9zm4-11H2v-.426l10.496-6.122V2.45a1 1 0 011.007 0L24 8.575V9zM0 24h26v2H0v-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgBank
