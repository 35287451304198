import styled, { css, createGlobalStyle } from 'styled-components'
import { element } from 'components/shared/utils/spacing'
import { colors } from 'components/shared/utils/colors'
import Tooltip from 'components/shared/Tooltip'
import { mediaQueries } from 'components/shared/utils/breakpoints'

export interface FontProps {
  $color?: string
  $isDarkMode?: boolean
  $align?: 'left' | 'center' | 'right' | 'justify' | 'inherit'
}

export const fontStyles = css<FontProps>`
  font-family: 'Spectrum Sans', 'Open Sans', 'San Francisco', 'Helvetica', 'Arial', sans-serif;
  text-align: ${({ $align }) => $align};
  color: ${({ $color, $isDarkMode, theme }) =>
    $color ||
    ((theme as any).isDarkMode || $isDarkMode ? colors.darkMode.text : colors.lightMode.text)};
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
`

interface VariantProps extends FontProps {
  $scale: string
  $noMargin: boolean
}

// Standalone components with individual styling
export const display = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 64px;
  font-weight: 800;
  line-height: 72px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.lg)};
`
export const displaySmall = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 48px;
  font-weight: 800;
  line-height: 58px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.md)};
`
const Display = styled.h1<VariantProps>`
  ${({ $scale }) => $scale === 'large' && display}
  ${({ $scale }) => $scale === 'small' && displaySmall}
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${display}

      ${mediaQueries.lessThanXxl} {
        ${displaySmall}
      }
    `}
`

export const title1 = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 48px;
  font-weight: 800;
  line-height: 56px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.md)};
`
export const title1Small = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 42px;
  font-weight: 800;
  line-height: 52px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.sm)};
`
const Title1 = styled.h1<VariantProps>`
  ${({ $scale }) => $scale === 'large' && title1}
  ${({ $scale }) => $scale === 'small' && title1Small}
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${title1}

      ${mediaQueries.lessThanXxl} {
        ${title1Small}
      }
    `}
`

export const title2 = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 42px;
  font-weight: 700;
  line-height: 52px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.sm)};
`
export const title2Small = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xs)};
`
const Title2 = styled.h2<VariantProps>`
  ${({ $scale }) => $scale === 'large' && title2}
  ${({ $scale }) => $scale === 'small' && title2Small}
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${title2}

      ${mediaQueries.lessThanXxl} {
        ${title2Small}
      }
    `}
`

export const title3 = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xs)};
`
export const title3Small = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xs)};
`
const Title3 = styled.h3<VariantProps>`
  ${({ $scale }) => $scale === 'large' && title3}
  ${({ $scale }) => $scale === 'small' && title3Small}
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${title3}

      ${mediaQueries.lessThanXxl} {
        ${title3Small}
      }
    `}
`

export const title4 = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xs)};
`
export const title4Small = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xs)};
`
const Title4 = styled.h4<VariantProps>`
  ${({ $scale }) => $scale === 'large' && title4}
  ${({ $scale }) => $scale === 'small' && title4Small}
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${title4}

      ${mediaQueries.lessThanXxl} {
        ${title4Small}
      }
    `}
`

export const title5 = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xs)};
`
export const title5Small = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xxs)};
`
const Title5 = styled.h5<VariantProps>`
  ${({ $scale }) => $scale === 'large' && title5}
  ${({ $scale }) => $scale === 'small' && title5Small}
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${title5}

      ${mediaQueries.lessThanXxl} {
        ${title5Small}
      }
    `}
`

export const title6 = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xs)};
`
export const title6Small = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xxs)};
`
const Title6 = styled.h6<VariantProps>`
  ${({ $scale }) => $scale === 'large' && title6}
  ${({ $scale }) => $scale === 'small' && title6Small}
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${title6}

      ${mediaQueries.lessThanXxl} {
        ${title6Small}
      }
    `}
`

export const bodyDisplay = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xl)};
`
export const bodyDisplaySmall = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xl)};
`
const BodyDisplay = styled.p<VariantProps>`
  ${({ $scale }) => $scale === 'large' && bodyDisplay}
  ${({ $scale }) => $scale === 'small' && bodyDisplaySmall}
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${bodyDisplay}

      ${mediaQueries.lessThanXxl} {
        ${bodyDisplaySmall}
      }
    `}
`

export const body = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.md)};
`
export const bodySmall = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.md)};
`
const Body = styled.p<VariantProps>`
  ${({ $scale }) => $scale === 'large' && body}
  ${({ $scale }) => $scale === 'small' && bodySmall}
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${body}

      ${mediaQueries.lessThanXxl} {
        ${bodySmall}
      }
    `}
`

export const eyebrow = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xs)};
`
export const eyebrowSmall = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.xs)};
`
const Eyebrow = styled.p.attrs(() => ({ role: 'doc-subtitle' }))<VariantProps>`
  ${({ $scale }) => $scale === 'large' && eyebrow}
  ${({ $scale }) => $scale === 'small' && eyebrowSmall}
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${eyebrow}

      ${mediaQueries.lessThanXxl} {
        ${eyebrowSmall}
      }
    `}
`

export const caption = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.sm)};
`
export const captionSmall = css<{ $noMargin: boolean }>`
  ${fontStyles}
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : element.sm)};
`
const Caption = styled.p<VariantProps>`
  ${({ $scale }) => $scale === 'large' && caption}
  ${({ $scale }) => $scale === 'small' && captionSmall}
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${caption}

      ${mediaQueries.lessThanXxl} {
        ${captionSmall}
      }
    `}
`

export const variants: Record<string, any> = {
  Display,
  Title1,
  Title2,
  Title3,
  Title4,
  Title5,
  Title6,
  BodyDisplay,
  Body,
  Eyebrow,
  Caption,
}
export type VariantName = keyof typeof variants

// Container component with all the styles
export const typographyLarge = css`
  ${body}
  margin-top: 0;
  margin-bottom: 0;

  h1 > strong {
    ${display}
  }

  h1 {
    ${title1}
  }

  h2 {
    ${title2}
  }

  h3 {
    ${title3}
  }

  h4 {
    ${title4}
  }

  h5 {
    ${title5}
  }

  h6 {
    ${title6}
  }

  p > strong {
    ${bodyDisplay}
  }

  p {
    ${body}
  }

  header > p {
    ${eyebrow}
  }

  p > small {
    ${caption}
  }
`
export const typographySmall = css`
  h1 > strong {
    ${displaySmall}
  }

  h1 {
    ${title1Small}
  }

  h2 {
    ${title2Small}
  }

  h3 {
    ${title3Small}
  }

  h4 {
    ${title4Small}
  }

  h5 {
    ${title5Small}
  }

  h6 {
    ${title6Small}
  }

  p > strong {
    ${bodyDisplaySmall}
  }

  p {
    ${bodySmall}
  }

  header > p {
    ${eyebrowSmall}
  }

  p > small {
    ${captionSmall}
  }
`
export const typography = css<any>`
  body {
    ${fontStyles}
    font-size: 16px;
  }

  ${typographyLarge}

  ${mediaQueries.lessThanXxl} {
    ${typographySmall}
  }
`
export const GlobalTypography = createGlobalStyle`
  ${typography}
`
export const Typography = styled.div<VariantProps>`
  ${({ $scale }) =>
    $scale === 'large' &&
    css`
      ${body}
      margin-top: 0;
      margin-bottom: 0;
      ${typographyLarge}
    `};
  ${({ $scale }) =>
    $scale === 'small' &&
    css`
      ${bodySmall}
      margin-top: 0;
      margin-bottom: 0;
      ${typographySmall}
    `};
  ${({ $scale }) =>
    $scale === 'auto' &&
    css`
      ${body}
      margin-top: 0;
      margin-bottom: 0;
      ${typographyLarge}

      ${mediaQueries.lessThanXxl} {
        ${bodySmall}
        margin-top: 0;
        margin-bottom: 0;
        ${typographySmall}
      }
    `};
`

interface EllipsisProps {
  $ellipsis: number
}

export const Ellipsis = styled.div<EllipsisProps>`
  > * {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: ${({ $ellipsis }) => $ellipsis};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-break: anywhere;
  }
`

export const CenterEllipsis = styled.div`
  white-space: nowrap;
  width: fit-content;

  > * {
    width: max-content;
  }
`

export const StyledToolTip = styled(Tooltip)``
