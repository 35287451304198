import * as React from 'react'

function SvgFolder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 4h-9.764a.995.995 0 01-.894-.553l-.895-1.788a2.984 2.984 0 00-2.683-1.66H3a3.003 3.003 0 00-3 3v21h25a3.003 3.003 0 003-3V7a3.003 3.003 0 00-3-3zM3 2h7.764a.994.994 0 01.894.553l.895 1.788A2.985 2.985 0 0015.236 6H25c.552 0 1 .447 1 1v2H2V3c0-.553.448-1 1-1zm22 20H2V11h24v10c0 .552-.448.999-1 1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFolder
