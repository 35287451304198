import React, { MouseEventHandler } from 'react'

export interface FilterMenuOpenCloseStateValue {
  isFilterMenuOpen: boolean
  setIsFilterMenuOpen: Function
  toggleFilterMenu: MouseEventHandler<HTMLButtonElement>
  closeFilterMenu: Function
  filterMenuPosition: { x: number; y: number; width: number; height: number } | null
  setFilterMenuPosition: Function
}

const initialValue: FilterMenuOpenCloseStateValue = {
  isFilterMenuOpen: false,
  setIsFilterMenuOpen: () => null,
  toggleFilterMenu: () => null,
  closeFilterMenu: () => null,
  setFilterMenuPosition: () => null,
  filterMenuPosition: null,
}

export const FilterMenuOpenCloseStateContext =
  React.createContext<FilterMenuOpenCloseStateValue>(initialValue)

function FilterMenuOpenCloseStateProvider({ children }: { children: React.ReactNode }) {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = React.useState(false)
  const [filterMenuPosition, setFilterMenuPosition] = React.useState(null)

  function toggleFilterMenu() {
    setIsFilterMenuOpen(!isFilterMenuOpen)
  }

  function closeFilterMenu() {
    setIsFilterMenuOpen(false)
  }

  return (
    <FilterMenuOpenCloseStateContext.Provider
      value={{
        isFilterMenuOpen,
        setIsFilterMenuOpen,
        toggleFilterMenu,
        closeFilterMenu,
        filterMenuPosition,
        setFilterMenuPosition,
      }}
    >
      {children}
    </FilterMenuOpenCloseStateContext.Provider>
  )
}

export default FilterMenuOpenCloseStateProvider

export const FilterMenuOpenCloseStateContextProvider = FilterMenuOpenCloseStateContext.Provider
export const FilterMenuOpenCloseStateContextConsumer = FilterMenuOpenCloseStateContext.Consumer
