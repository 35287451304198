import * as React from 'react'

function SvgPhoneF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 17.25v5.9a2.837 2.837 0 01-.88 2.06 2.933 2.933 0 01-1.98.79H23C8.62 25.33.67 17.38 0 3A2.965 2.965 0 01.79.88 2.837 2.837 0 012.85 0h5.9l2.41 8.41-3.56 2.52a19.542 19.542 0 007.58 7.51l2.39-3.6L26 17.25z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPhoneF
