import React from 'react'
import { AccordionPanel, Panel } from './AccordionPanel'
import * as S from './styles'
import { Elevation } from 'components/shared/utils/elevation'
import { useDarkMode } from 'components/App/Providers/DarkMode'
import { numberToPixels } from 'components/shared/utils/number-to-pixels'
import { Card } from 'components/shared/Card'

export interface AccordionProps extends React.PropsWithoutRef<JSX.IntrinsicElements['div']> {
  /** Panels have the shape of { title: string; label?: string | ReactNode; labelVariant?: string; content: ReactNode; disabled?: boolean } */
  panels: Panel[]
  /** A string or number (of pixels) to set the width to. (If you manually set a width, consider using Prism React's "Grid" component so the div can "snap" to the grid.) */
  width?: string | number
  /** A string or number (of pixels) to set the min-width to. */
  minWidth?: string | number
  /** A string or number (of pixels) to set the max-width to. */
  maxWidth?: string | number
  /** Use this prop to display the accordion inside a card. */
  asCard?: boolean
  /** Change the semantic HTML elements the accordion is made up of into a <ul> with <li>s instead of <div>s. Use this when you're representing a series of collapsed short form content. */
  asList?: boolean
  /** Only applies if "asCard" is true. (0-3) */
  cardElevation?: Elevation
  /** Use this prop to specify the index of the panel to have expanded upon initialization */
  initialExpandedIndex?: any
  /** Max height when Accordion is expanded */
  maxHeightWhenExpanded?: string
  /** Used to hook into the click event for the panels. Provides the panel's index as a second argument. */
  onPanelClick?: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void
  enablePanelTitleCopy?: boolean
}

export const Accordion = ({
  panels,
  width = '100%',
  minWidth = 'unset',
  maxWidth = 'unset',
  asCard = false,
  asList = false,
  onPanelClick,
  initialExpandedIndex = null,
  maxHeightWhenExpanded,
  enablePanelTitleCopy,
  ...props
}: AccordionProps) => {
  const { isDarkMode } = useDarkMode()
  const [expandedIndex, setExpandedIndex] = React.useState(initialExpandedIndex)
  const [hasClicked, setHasClicked] = React.useState(false)
  const pixelWidth = numberToPixels(width)
  const pixelMinWidth = numberToPixels(minWidth)
  const pixelMaxWidth = numberToPixels(maxWidth)

  React.useEffect(() => {
    if (!hasClicked) {
      setExpandedIndex(initialExpandedIndex)
    }
  }, [setExpandedIndex, initialExpandedIndex])

  const handleClick = React.useCallback(
    (index: number) => {
      setHasClicked(true)
      if (index === expandedIndex) {
        setExpandedIndex(null)
      } else {
        setExpandedIndex(index)
      }
    },
    [expandedIndex, setExpandedIndex]
  )

  const content = (
    <S.Container
      as={asList ? 'ul' : 'div'}
      $width={pixelWidth}
      $minWidth={pixelMinWidth}
      $maxWidth={pixelMaxWidth}
      {...props}
    >
      {panels.map((panel: Panel, index: number) => (
        <AccordionPanel
          isExpanded={index === expandedIndex}
          key={`panel-${index}`}
          title={panel.title}
          label={panel.label}
          labelVariant={panel.labelVariant}
          content={panel.content}
          index={index}
          handleClick={handleClick}
          asList={asList}
          asCard={asCard}
          isDarkMode={isDarkMode}
          onClick={onPanelClick}
          maxHeightWhenExpanded={maxHeightWhenExpanded}
          enablePanelTitleCopy={enablePanelTitleCopy}
        />
      ))}
    </S.Container>
  )

  return asCard ? (
    <Card width={pixelWidth} minWidth={pixelMinWidth} maxWidth={pixelMaxWidth} disablePadding>
      {content}
    </Card>
  ) : (
    content
  )
}
