import * as React from 'react'

function SvgCloudF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 22" {...props}>
      <path
        d="M28 14.5a7.454 7.454 0 00-5.074-7.092A8.805 8.805 0 0014 0a8.8 8.8 0 00-8.92 7.408A7.49 7.49 0 007 21.975V22h14v-.025a7.503 7.503 0 007-7.475z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCloudF
