import * as React from 'react'

function SvgHeartF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 25" {...props}>
      <path
        d="M24.63 13.56a36.641 36.641 0 01-6.53 7.32 68.271 68.271 0 01-4.51 3.75l-.59.44-.59-.44c-.32-.24-8.03-6.06-11.04-11.07a8.3 8.3 0 01.37-9.67 7.81 7.81 0 018.63-2.55c.967.344 1.86.87 2.63 1.55a8.052 8.052 0 012.63-1.55 7.81 7.81 0 018.63 2.55 8.3 8.3 0 01.37 9.67z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHeartF
