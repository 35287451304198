type FiscalDateObject = {
  start: {
    year: number
    month: number
    day: number
  }
  end: {
    year: number
    month: number
    day: number
  }
}

export enum Months {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

export function getFiscalMonthDates(
  year: number,
  fiscalMonthToReturn: number,
  isLeapYear: boolean
): FiscalDateObject {
  const fiscalMonthDefinitions = {
    [Months.JANUARY]: {
      start: {
        day: 29,
        month: Months.DECEMBER,
        year: year - 1,
      },
      end: {
        day: 28,
        month: Months.JANUARY,
        year: year,
      },
    },
    [Months.FEBRUARY]: {
      start: {
        day: 29,
        month: Months.JANUARY,
        year: year,
      },
      end: {
        day: 28,
        month: Months.FEBRUARY,
        year: year,
      },
    },
    [Months.MARCH]: {
      start: {
        day: isLeapYear ? 29 : 1,
        month: isLeapYear ? Months.FEBRUARY : Months.MARCH,
        year: year,
      },
      end: {
        day: 28,
        month: Months.MARCH,
        year: year,
      },
    },
    [Months.APRIL]: {
      start: {
        day: 29,
        month: Months.MARCH,
        year: year,
      },
      end: {
        day: 28,
        month: Months.APRIL,
        year: year,
      },
    },
    [Months.MAY]: {
      start: {
        day: 29,
        month: Months.APRIL,
        year: year,
      },
      end: {
        day: 28,
        month: Months.MAY,
        year: year,
      },
    },
    [Months.JUNE]: {
      start: {
        day: 29,
        month: Months.MAY,
        year: year,
      },
      end: {
        day: 28,
        month: Months.JUNE,
        year: year,
      },
    },
    [Months.JULY]: {
      start: {
        day: 29,
        month: Months.JUNE,
        year: year,
      },
      end: {
        day: 28,
        month: Months.JULY,
        year: year,
      },
    },
    [Months.AUGUST]: {
      start: {
        day: 29,
        month: Months.JULY,
        year: year,
      },
      end: {
        day: 28,
        month: Months.AUGUST,
        year: year,
      },
    },
    [Months.SEPTEMBER]: {
      start: {
        day: 29,
        month: Months.AUGUST,
        year: year,
      },
      end: {
        day: 28,
        month: Months.SEPTEMBER,
        year: year,
      },
    },
    [Months.OCTOBER]: {
      start: {
        day: 29,
        month: Months.SEPTEMBER,
        year: year,
      },
      end: {
        day: 28,
        month: Months.OCTOBER,
        year: year,
      },
    },
    [Months.NOVEMBER]: {
      start: {
        day: 29,
        month: Months.OCTOBER,
        year: year,
      },
      end: {
        day: 28,
        month: Months.NOVEMBER,
        year: year,
      },
    },
    [Months.DECEMBER]: {
      start: {
        day: 29,
        month: Months.NOVEMBER,
        year: year,
      },
      end: {
        day: 28,
        month: Months.DECEMBER,
        year: year,
      },
    },
  }

  return fiscalMonthDefinitions[fiscalMonthToReturn]
}
