import * as React from 'react'

function SvgFilterF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 20" {...props}>
      <path
        d="M21 12a4 4 0 110 8 4 4 0 010-8zm-9 3v2H0v-2h12zM5 0a4 4 0 110 8 4 4 0 010-8zm21 3v2H14V3h12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFilterF
