import styled, { css } from 'styled-components'
import chroma from 'chroma-js'
import { element } from 'components/shared/utils/spacing'
import { mediaQueries } from 'components/shared/utils/breakpoints'
import { colors } from 'components/shared/utils/colors'
import Flex from 'components/shared/flex/Flex'
import { durations } from 'components/shared/utils/transition'
import Icon from 'components/shared/icon/Icon'

const timingSlowEntryAndExitFunctions = {
  standard: 'cubic-bezier(.25, 0.1, .25, 1)',
  entry: 'cubic-bezier(0, 0, 0.58, 1)',
  exit: 'cubic-bezier(0.42, 0, 1, 1)',
}

const padding = css`
  padding: 0 ${element.xxl};

  ${mediaQueries.md} {
    padding: 0 ${element.xl};
  }

  ${mediaQueries.xs},${mediaQueries.sm} {
    padding: 0 ${element.md};
  }
`

interface ContainerProps {
  $width: string
  $minWidth: string
  $maxWidth: string
}

export const Container = styled.div<any>`
  width: ${({ $width }) => $width};
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};
  padding: 0;
  margin: 0;
  list-style-type: none;
`

interface PanelContainerProps {
  $isDarkMode?: boolean
  $asCard: boolean
}

export const PanelContainer = styled.div<PanelContainerProps>`
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid ${({ $isDarkMode }) => ($isDarkMode ? colors.gray40 : colors.gray20)};

  &:hover #copyButtonContainer {
    display: block;
  }

  ${({ $asCard, $isDarkMode }) =>
    !$asCard &&
    css`
      &:first-child {
        border-top: 1px solid ${$isDarkMode ? colors.gray40 : colors.gray20};
      }
    `}
`

export const PanelButtonContent = styled(Flex)`
  ${padding}
`

interface RowTitleProps {
  $isExpanded: boolean
  $isDarkMode: boolean
}

export const PanelButton = styled.button<RowTitleProps>`
  width: 100%;
  height: 58px;
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;

  /* Interactions */
  :hover {
    background-color: rgba(
      ${({ $isDarkMode }) =>
        chroma($isDarkMode ? colors.white : colors.black)
          .alpha(0.025)
          .rgba()
          .join(',')}
    );
  }

  :active {
    background-color: rgba(
      ${({ $isDarkMode }) =>
        chroma($isDarkMode ? colors.white : colors.black)
          .alpha(0.05)
          .rgba()
          .join(',')}
    );
  }

  /* Disable outline when click-focused */
  :focus,
  > ${PanelButtonContent} {
    outline: none;
  }

  /* Enable outline when tab-focused */
  :focus > ${PanelButtonContent} {
    box-shadow: ${colors.blue20} 0px 0px 2px 2px;
  }
`

export const PanelContentContainer = styled.div<{
  $isExpanded: boolean
  $maxHeightWhenExpanded?: string
}>`
  ${padding}
  max-height: 0;
  overflow: hidden;

  text-transform: ${timingSlowEntryAndExitFunctions.entry};
  transition-duration: ${durations.medium};
  transition-property: max-height;
  z-index: 1;
  position: relative;

  ${({ $isExpanded, $maxHeightWhenExpanded }) =>
    $isExpanded &&
    css`
      max-height: ${$maxHeightWhenExpanded};
      transition-timing-function: ${timingSlowEntryAndExitFunctions.exit};
      transition-duration: ${durations.medium};
      transition-property: max-height;
    `}
`

export const StyledIcon = styled(Icon)<{ $rotation: string }>`
  transform: rotate(${({ $rotation }) => $rotation});
  transition-property: transform;
  transition-duration: ${durations.medium};
  transition-timing-function: ${timingSlowEntryAndExitFunctions.standard};
`

export const CopyButtonContainer = styled.div`
  display: none;
  margin-left: ${element.sm};
`

export const PanelContent = styled.div<{ $isExpanded: boolean }>`
  padding-bottom: 32px;
  white-space: normal;

  opacity: 0;
  transform: translateY(-1rem);
  transition-timing-function: linear, ease;
  transition-duration: 0.1s;
  transition-property: opacity, transform;
  transition-delay: 0.5s;

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      opacity: 1;
      transform: translateY(0);
      transition-delay: ${durations.short};
      transition-timing-function: ease-in-out;
      transition-duration: ${durations.short};
      transition-property: opacity, transform;
    `}
`
