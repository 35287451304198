import * as React from 'react'

function SvgDoorbellF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M21.1 14.997H2.9l-2.9 3.6v5.399h7.1a5 5 0 009.8 0H24v-5.4l-2.9-3.599zM12 25.995a2.9 2.9 0 01-2.8-2h5.6a2.9 2.9 0 01-2.8 2zm8-14.697a8.498 8.498 0 00-5-7.699V0H9v3.6a8.3 8.3 0 00-2.6 1.7A7.998 7.998 0 004 10.997v2h16v-1.7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDoorbellF
