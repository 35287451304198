import { useEffect, useState } from 'react'
import useFilterValues from 'hooks/useFilterValues'
import { getRefreshIntervalFromStep, times } from 'utils'

function useChartRefresh(
  setRefreshInterval: (refreshInterval: number) => void,
  refreshInterval: number,
  data: any
) {
  const { period, step, partialData } = useFilterValues()
  const [nextIncrement, setNextIncrement] = useState(() =>
    times.getNextIncrementFromStep(Number(step), !!partialData)
  )

  useEffect(() => {
    const newRefreshInterval = getRefreshIntervalFromStep(Number(step), !!partialData)
    const newNextIncrement = times.getNextIncrementFromStep(Number(step), !!partialData)

    if (newRefreshInterval !== refreshInterval && newNextIncrement !== nextIncrement) {
      setRefreshInterval(newRefreshInterval)
      setNextIncrement(newNextIncrement)
    }
  }, [data, period, step, setRefreshInterval, refreshInterval, partialData, nextIncrement])
}

export default useChartRefresh
