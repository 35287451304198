import * as React from 'react'

function SvgStarF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 27" {...props}>
      <path
        d="M6.633 27.002a2.095 2.095 0 01-1.977-2.735l2.35-7.28-6.15-4.498a2.086 2.086 0 011.224-3.77h7.595l2.348-7.277a2.076 2.076 0 013.953 0l2.349 7.276h7.595a2.086 2.086 0 011.225 3.77l-6.151 4.499 2.35 7.28a2.079 2.079 0 01-3.201 2.327L14 22.102l-6.143 4.493a2.067 2.067 0 01-1.224.407z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgStarF
