import * as React from 'react'

function SvgEyeCross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 22" {...props}>
      <path
        d="M15 0c9.57 0 13.75 10.19 13.93 10.63l.15.37-.15.37a20.984 20.984 0 01-5.04 7.1l1.82 1.82-1.42 1.42-2-2A12.69 12.69 0 0115 22C5.43 22 1.25 11.81 1.07 11.37L.92 11l.15-.37a20.984 20.984 0 015.04-7.1L4.29 1.71 5.71.29l2 2A12.691 12.691 0 0115 0zM7.55 4.97A19.358 19.358 0 003.09 11c.82 1.74 4.66 9 11.91 9a10.73 10.73 0 005.83-1.75l-2.36-2.36A5.88 5.88 0 0115 17a5.995 5.995 0 01-4.89-9.47zM15 2a10.729 10.729 0 00-5.83 1.75l13.28 13.28A19.358 19.358 0 0026.91 11C26.09 9.26 22.25 2 15 2zm-3.44 6.98A3.816 3.816 0 0011 11a3.999 3.999 0 004 4 3.937 3.937 0 002.03-.55zM15 5.004a6.007 6.007 0 016 6h-2a4.004 4.004 0 00-4-4v-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgEyeCross
