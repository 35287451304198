import {
  getApiNameFromQuery,
  getReleaseVersionFromQuery,
  getPageNameFromQuery,
  getErrorCodeFromQuery,
  getEventCaseIDFromQuery,
  getAbsolutePeriodFromURL,
  getIntervalFromUrl,
  getDaysDistanceFromAbsolutePeriod,
} from 'utils'
import includes from 'lodash/includes'
import compact from 'lodash/compact'
import mapValues from 'lodash/mapValues'
import isArray from 'lodash/isArray'
import get from 'lodash/get'
import omitDeep from 'omit-deep-lodash'
import times from 'utils/times'

export function getOpenSearchUrlProps({
  filterValues,
  location,
  appsByDomain,
  appsByDataSource,
  entityID,
  Domains,
  currentGroup,
  name,
  type,
}: any) {
  const environment = filterValues?.environment || 'production'
  const interval = `${getIntervalFromUrl(location)}ms`

  const periodMs = getAbsolutePeriodFromURL(location)
  const daysDistance = getDaysDistanceFromAbsolutePeriod(periodMs)
  const isLessThanDayDistance = daysDistance < 1

  const apiName = getApiNameFromQuery(location)
  const releaseVersion = getReleaseVersionFromQuery(location)
  const pageName = `'${getPageNameFromQuery(location)}'`
  const errorCode = getErrorCodeFromQuery(location)
  const eventCaseID = getEventCaseIDFromQuery(location)
  const isInternalApp = includes(appsByDomain[Domains.Internal], entityID)
  const isQubeApp = includes(appsByDataSource['qube'], entityID)
  const appCategory = isQubeApp ? 'qubeApp' : isInternalApp ? 'internalApp' : 'notInternalApp'
  const openSearchEnvironment = environment === 'production' ? 'production' : 'staging'

  const indexPatterns = {
    internalApp: {
      production: {
        indexId: 'prod_internal_quantum*',
        openSearchIndex: '5c7ba170-438c-11ea-b8db-8d45bbf49206',
      },
      staging: {
        indexId: 'stg_internal_quantum*',
        openSearchIndex: '1a517d60-3e2d-11ea-9b4c-3317ae61693a',
      },
    },
    notInternalApp: {
      production: {
        indexId: 'quantum_prod',
        openSearchIndex: '475ce400-cd83-11e8-bee7-6dfb640ad131',
      },
      staging: {
        indexId: 'quantum_staging',
        openSearchIndex: '0d0cc0a0-910e-11e9-aff3-6d170f6e6220',
      },
    },
    qubeApp: {
      production: {
        indexId: 'prod_qube_finished_*',
        openSearchIndex: '34055d80-93d7-11ea-8e62-63b16c43a055',
      },
      staging: {
        indexId: 'stg_qube_finished*',
        openSearchIndex: '0418f2d0-7906-11ea-a50c-d7a9b912ef70',
      },
    },
  }
  const { indexId, openSearchIndex } = indexPatterns[appCategory][openSearchEnvironment]
  const { openSearchFrom, openSearchTo } = times.msToOpenSearchTimeFormat(periodMs)

  const getAppIDsfiltered = () => {
    if (!currentGroup) {
      return { appName: compact([name]), appType: compact([type]) }
    } else {
      const filteredIds = currentGroup?.applicationIDs?.reduce(
        (acc: any, id: string) => {
          // filtering out equal appTypes and appNames
          const appIdParsed = { name: id.split('@')[0], type: id.split('@')[1] }
          const appNameAlreadyExists = acc.appName.some((item: string) => item === appIdParsed.name)
          const appTypeAlreadyExists = acc.appType.some((item: string) => item === appIdParsed.type)
          if (appTypeAlreadyExists && appNameAlreadyExists) return acc

          if (appNameAlreadyExists) acc.appType.push(appIdParsed.type)

          if (appTypeAlreadyExists) acc.appName.push(appIdParsed.name)
          if (!appTypeAlreadyExists && !appNameAlreadyExists) {
            acc.appName.push(appIdParsed.name)
            acc.appType.push(appIdParsed.type)
          }
          return acc
        },
        { appName: [] as string[], appType: [] as string[] }
      ) || { appName: [name], appType: [type] }

      return mapValues(filteredIds, compact)
    }
  }

  const appIDsfiltered = getAppIDsfiltered() as any
  const appNameString = appIDsfiltered.appName.toString().replace(/,/g, ',+').replace(/ /g, '+')
  const appNameStringSecond = appIDsfiltered.appName?.reduce((acc: any, item: any) => {
    return `${acc}${acc ? ',' : ''}'${item.replace(/ /g, '+')}'`
  }, '')
  const appTypeString = appIDsfiltered.appType.toString().replace(/,/g, ',+').replace(/ /g, '+')
  const appTypeStringSecond = appIDsfiltered.appType.reduce((acc: any, item: any) => {
    return `${acc}${acc ? ',' : ''}'${item.replace(/ /g, '+')}'`
  }, '')
  const appNameMatchPhraseList = appIDsfiltered.appName.reduce((acc: any, value: any) => {
    return `${acc}${
      acc ? ',' : ''
    }(match_phrase:(visit.applicationDetails.applicationName:'${value.replace(/ /g, '+')}'))`
  }, '')
  const appTypeMatchPhraseList = appIDsfiltered.appType.reduce((acc: any, value: any) => {
    return `${acc}${
      acc ? ',' : ''
    }(match_phrase:(visit.applicationDetails.applicationType:'${value.replace(/ /g, '+')}'))`
  }, '')

  return {
    interval,
    apiName,
    releaseVersion,
    pageName,
    errorCode,
    eventCaseID,
    indexId,
    openSearchIndex,
    openSearchFrom,
    openSearchTo,
    appNameString,
    appTypeString,
    appNameMatchPhraseList,
    appTypeMatchPhraseList,
    appNameStringSecond,
    appTypeStringSecond,
    isLessThanDayDistance,
  }
}

export function formatMetaData(metaData: string | Record<string, string>) {
  if (typeof metaData === 'string') {
    return JSON.stringify(metaData, null, 2)
  }
  if (isArray(metaData)) {
    return JSON.stringify(metaData?.[0], null, 2)
  }
  //Removing any token
  const metaDataWithoutHeader = omitDeep(metaData, 'headers', 'authorization')
  const realtimeDashboardInfoMetaData = get(metaDataWithoutHeader, 'realtimeDashboardInfo')
  return JSON.stringify(realtimeDashboardInfoMetaData || metaDataWithoutHeader, null, 2)
}
