import * as React from 'react'

function SvgBrokenImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M3 16v6h21a1.003 1.003 0 001-1v-3h2v3a3.009 3.009 0 01-3 3H1v-8h2zM24 0a3.009 3.009 0 013 3v11h1v2h-2.362l-5.576-4.647-5.11 5.11-7-8L2.413 14H0v-2h1V3a3.009 3.009 0 013-3zm0 2H4a1.003 1.003 0 00-1 1v7.586l5.049-5.049 7 8 4.89-4.89L25 12.865V3a1.003 1.003 0 00-1-1zm-8 1a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgBrokenImage
