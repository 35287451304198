import React, { useEffect, useState } from 'react'
import TimeMenu, { Period } from 'components/shared/VoCTimeMenu'
import LayoutTimeMenu from 'components/shared/TimeMenu'
import { Filter } from 'components/shared/FilterMenu/types'
import { QueryParamsProps } from './useQueryStringParams'
import useGroups from './useGroups'
import useApps from './useApps'
import { useLocation } from '@reach/router'
import { isObject, omit } from 'lodash'
import { differenceInDays, subDays } from 'date-fns'
import { isPeriodAbsolute } from '../components/shared/VoCTimeMenu/utils'
import { getAbsoluteFromRelativePeriodVoC } from '../utils/times/times'

type TimeMenuComponent = React.FC<Omit<any, 'options' | 'values' | 'updateValues'>>

export interface TimeValues {
  period: string
  step: string | number
  periodOffset?: number
}

interface TimeMenuProps {
  filters?: Filter[]
  periods?: Period[]
  values: any
  updateValues: (newValues: Record<string, any>) => void
}

const ssppApps = [
  'SpecMobile',
  'SpecMobile',
  'SpecMobile',
  'SMB',
  'SpecNet',
  'MySpectrum',
  'MySpectrum',
  'IDManagement',
  'PrivacyMicrosite',
  'ServiceManagement',
  'SpectrumWiFi',
  'SpectrumWiFiGui',
  'CEW',
  'SSPP',
]

export default function useTimeMenu(props: Omit<QueryParamsProps, 'params'> & TimeMenuProps) {
  const location = useLocation()
  const { currentGroup } = useGroups()
  const { app } = useApps()

  const [isVoc, setIsVoc] = useState(location.pathname.includes('/voice-of-customer'))

  const { filters, periods, values, updateValues } = props
  const convertedValues =
    isVoc && !isPeriodAbsolute(values.period)
      ? {
          ...omit(values, ['period', 'periodOffset']),
          period: getAbsoluteFromRelativePeriodVoC(values.period, values?.periodOffset),
        }
      : values
  const header = currentGroup
    ? { title: currentGroup.name, subtitle: currentGroup.description }
    : { title: app.name as string, subtitle: app.type }
  const headerTitle = header.title
  const isSSPP = ssppApps.includes(headerTitle)

  useEffect(() => {
    if (location.pathname.includes('/voice-of-customer')) {
      setIsVoc(true)
    } else {
      setIsVoc(false)
    }
  }, [location.pathname])

  const handleTimeChange = (vals: any) => {
    if (isObject(vals.range)) {
      const { range, ...rest } = vals
      vals = {
        ...rest,
        ...range,
        range: null,
        partialData: vals?.isPartialData,
        step: vals?.step,
        period: `${range.startMs}:${range.endMs}`,
      }
    } else {
      const days = vals?.range.includes('d') ? vals?.range.match(/\d/g).join('') : null
      const period = vals?.range.split('-')[1]
      vals = {
        startMs: null,
        endMs: null,
        step: vals?.step,
        partialData: vals?.isPartialData,
        period:
          days && ['1', '2', '3'].includes(days)
            ? `${subDays(new Date(), +days).getTime()}:${new Date().getTime()}`
            : period,
      }
    }
    updateValues(vals)
  }

  const initialRangeSet = (period: any) => {
    const defaultPeriod = `-${period}`
    if (period.includes(':')) {
      const [start, end] = period.split(':')
      const days = differenceInDays(+end, +start)
      const startDate = new Date(start * 1)
      const currentDate = new Date().setHours(0, 0, 0, 0)
      const showDateRangeLabel = differenceInDays(currentDate, startDate) <= 2
      if ([1, 2, 3].includes(days) && showDateRangeLabel) {
        return `-${days}d`
      } else {
        return { startMs: +start, endMs: +end }
      }
    }
    return defaultPeriod
  }

  const TimeMenuWithValues: TimeMenuComponent = props => (
    <>
      {isVoc ? (
        <TimeMenu filters={filters} periods={periods} values={values} updateValues={updateValues} />
      ) : (
        <LayoutTimeMenu
          onChange={values => handleTimeChange(values)}
          isSSPP={isSSPP}
          initialRange={initialRangeSet(values.period)}
          initialStep={values.step}
          initialPartialData={values.partialData}
        />
      )}
    </>
  )

  return {
    timeValues: convertedValues as TimeValues,
    TimeMenu: TimeMenuWithValues,
    updateTimeValues: updateValues,
  }
}
