import React from 'react'
import ReactDOM from 'react-dom'
import { useToggle } from './useToggle'

export interface UsePortalArg {
  /** Content that will appear inside the Portal, once open. Can either be a render prop with an argument of "toggleOpen" or a React Node. */
  content:
    | React.ReactNode
    | ((toggleOpen: (isOpenOverride?: boolean) => void) => React.ReactNode)
  /** An optional property to control whether the Portal is open or not. */
  isOpen?: boolean
  /** An optional ref for an HTMLElement that Portal will attach to. (Portal will default to document.body if no ref is provided.) */
  rootRef?: React.MutableRefObject<HTMLElement>
}

export const usePortal = ({
  content,
  isOpen: isOpenControlled,
  rootRef: preMadeRef,
}: UsePortalArg) => {
  const ref = React.useRef<HTMLElement>(null)
  const [isOpen, toggleOpen] = useToggle()

  const refElement = preMadeRef?.current || ref.current || document.body
  const isTrulyOpen = isOpenControlled ?? isOpen
  const isContentARenderProp = typeof content === 'function'
  const Content = isContentARenderProp
    ? (
        content as (
          toggleOpen: (isOpenOverride?: boolean) => void
        ) => React.ReactNode
      )(toggleOpen)
    : content

  function handleClickOutside(event: MouseEvent) {
    if (refElement && !refElement.contains(event.target as Node)) {
      toggleOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  })

  return {
    isOpen: isTrulyOpen,
    toggleOpen,
    ref,
    portal: ReactDOM.createPortal(isTrulyOpen ? Content : null, refElement),
  }
}
