import * as React from 'react'

function SvgPerson(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 27" {...props}>
      <path
        d="M17.09 12.664a7 7 0 10-8.18 0A13.025 13.025 0 000 25.004v1h26v-1a13.025 13.025 0 00-8.91-12.34zM8 7.004a5 5 0 1110 0 5 5 0 01-10 0zm-5.96 17a11 11 0 0121.91 0H2.04z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPerson
