import * as React from 'react'

function SvgQuestionCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.732 0 14 6.268 14 14-.008 7.728-6.271 13.99-14 14-7.732 0-14-6.268-14-14S6.268 0 14 0zm0 2C7.373 2 2 7.372 2 14c0 6.627 5.373 12 12 12 6.628 0 12-5.373 12-12-.007-6.625-5.375-11.993-12-12zm1.26 17v3h-2v-3h2zm-1-12c2.421 0 3.75 1.126 4.186 2.18.376.86.186 1.863-.479 2.527-.38.37-.778.721-1.193 1.051-1.022.838-1.514 1.284-1.514 2.242v2h-2v-2c0-1.948 1.194-2.925 2.245-3.789.35-.286.71-.582 1.048-.918a.277.277 0 00.045-.348C16.453 9.595 15.796 9 14.26 9a3.394 3.394 0 00-3.112 1.46l-1.782-.908A5.315 5.315 0 0114.26 7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgQuestionCircle
