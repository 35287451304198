import * as React from 'react'

function SvgHeart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 25" {...props}>
      <path
        d="M13 25.073l-.588-.441c-.327-.246-8.035-6.058-11.042-11.069a8.303 8.303 0 01.366-9.67 7.808 7.808 0 018.632-2.554A8.288 8.288 0 0113 2.885a8.288 8.288 0 012.632-1.546 7.806 7.806 0 018.632 2.555 8.303 8.303 0 01.366 9.67c-3.007 5.01-10.715 10.822-11.042 11.068l-.588.441zM7.861 2.891a5.873 5.873 0 00-4.594 2.23 6.308 6.308 0 00-.215 7.433c2.353 3.92 8.092 8.598 9.948 10.054 1.856-1.457 7.597-6.137 9.948-10.054a6.308 6.308 0 00-.215-7.432 5.868 5.868 0 00-6.48-1.923 7.038 7.038 0 00-2.526 1.691L13 5.692l-.727-.802A7.01 7.01 0 009.747 3.2a5.982 5.982 0 00-1.886-.309z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHeart
