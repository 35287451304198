import styled, { css } from 'styled-components'
import { body } from 'components/shared/typography'
import Icon from 'components/shared/icon'
import { colors } from 'components/shared/utils/colors'
import { element } from 'components/shared/utils/spacing'

interface StyledInput {
  disabled: boolean
  $isDarkMode?: boolean
  checked: boolean
}

export const Input = styled.input.attrs({ type: 'radio' })<StyledInput>`
  appearance: none;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 50%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  :focus {
    outline: none;
    box-shadow: ${colors.blue20} 0px 0px 2px 2px;
  }
  :focus:not(.focus-visible) {
    outline: none;
    box-shadow: none;
  }
`

interface LabelProps {
  $disabled?: boolean
  $isDarkMode?: boolean
}

export const Label = styled.label<any>`
  ${body}
  margin-bottom: 0;
  padding-left: ${element.xs};
  line-height: 20px;
  cursor: pointer;

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${colors.gray25};
      cursor: not-allowed;
    `};
`

export const RadioIconF = styled(Icon).attrs({
  name: 'RadioF',
})<{ disabled: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const RadioIcon = styled(Icon).attrs({
  name: 'Radio',
})<{ disabled: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
