import * as React from 'react'

function SvgFullScreenReturnF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 0H3a3.009 3.009 0 00-3 3v21h25a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3zM12 20h-2v-4H4v-2h8v6zm0-10H4V8h6V4h2v6zm12 6h-6v4h-2v-6h8v2zm0-6h-8V4h2v4h6v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFullScreenReturnF
