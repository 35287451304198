import * as React from 'react'

function SvgMobileF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 28" {...props}>
      <path
        d="M15 0H3a3.009 3.009 0 00-3 3v22a3.009 3.009 0 003 3h12a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3zm1 25a1.003 1.003 0 01-1 1H3a1.003 1.003 0 01-1-1v-1h14v1zm0-21H2V3a1.003 1.003 0 011-1h12c.552.002.998.448 1 1v1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMobileF
