import * as React from 'react'

function SvgLock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M12 0a7 7 0 017 7v3h5v15a3.003 3.003 0 01-3 3H3a3.003 3.003 0 01-3-3V10h5V7a7 7 0 017-7zm10 12H2v13a1 1 0 001 1h18a1 1 0 001-1V12zm-9 3v8h-2v-8h2zM12 2a5 5 0 00-5 5v3h10V7a5 5 0 00-5-5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLock
