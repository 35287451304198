import * as React from 'react'

function SvgCreditCardSuccess(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 23" {...props}>
      <path
        d="M18 2.004v2H3c-.552 0-.999.448-1 1v1h16.01v2H2v2h23v-2.01h2v11.01a3.003 3.003 0 01-3 3H0v-17a3.003 3.003 0 013-3h15zm7 10H2v8h22c.553 0 1-.448 1-1v-7zM14 16v2H3v-2h11zM26.293.293l1.414 1.414L22 7.414l-2.707-2.707 1.414-1.414L22 4.586 26.293.293z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCreditCardSuccess
