import * as React from 'react'

function SvgCopyF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M2 4v20h21v2H0V4h2zm21-4a3.009 3.009 0 013 3v15a3.009 3.009 0 01-3 3H5V3a3.009 3.009 0 013-3h15z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCopyF
