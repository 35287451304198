import * as React from 'react'

function SvgHostingF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M0 0v12h26V0H0zm7 7H4V5h3v2zm15 0H10V5h12v2zM0 14v9a3.009 3.009 0 003 3h20a3.009 3.009 0 003-3v-9H0zm7 7H4v-2h3v2zm15 0H10v-2h12v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHostingF
