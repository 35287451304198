import * as React from 'react'

function SvgPresentation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M22.088 9v2H5.912V9h16.176zm-4.044-4v2H9.956V5h8.088zm9.935 8.717L25.998 0H2.002L.021 13.717a1.982 1.982 0 00.472 1.594c.385.439.943.69 1.53.689h10.966v6H9.956a2.011 2.011 0 00-2.022 2h12.132c0-1.105-.905-2-2.022-2h-3.033v-6h10.967a2.028 2.028 0 001.529-.689c.384-.438.556-1.02.472-1.594zM2.022 14L3.755 2h20.49l1.735 12H2.022z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPresentation
