import * as React from 'react'

function SvgLockUnlockF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M7 10V7a5.005 5.005 0 019.59-2h2.12A7.001 7.001 0 005 7v3H0v15a3.009 3.009 0 003 3h18a3.009 3.009 0 003-3V10H7zm6 13h-2v-8h2v8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLockUnlockF
