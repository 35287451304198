import * as React from 'react'

function SvgRouterF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 28" {...props}>
      <path
        d="M11 0H3a3.009 3.009 0 00-3 3v21a3.01 3.01 0 002 2.82V28h2v-1h6v1h2v-1.18A3.01 3.01 0 0014 24V3a3.009 3.009 0 00-3-3zM6 5h2v3H6V5zm0 5h2v3H6v-3zm6 13H2v-2h10v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRouterF
