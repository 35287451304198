import * as React from 'react'

function SvgStore(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M22 22h-7v-8h7v8zm-5-2h3v-4h-3v4zm6.6-20H2.4L0 4.8V23a2.9 2.9 0 003 3h3v-8.5A2.5 2.5 0 018.5 15a2.5 2.5 0 011.8.8 2.4 2.4 0 01.7 1.7V26h12a3 3 0 003-3V4.8L23.6 0zM2 6h4v1a2 2 0 11-4 0V6zm6 0h4v1a2 2 0 11-4 0V6zm6 0h4v1a2 2 0 11-4 0V6zm6 0h4v1a2 2 0 11-4 0V6zm2.4-4l1 2H2.6l1-2h18.8zm.6 22H13v-6.5a4.8 4.8 0 00-1.3-3.2A4.8 4.8 0 008.5 13 4.5 4.5 0 004 17.5V24H3a.9.9 0 01-1-1V10.5a4.6 4.6 0 002 .5 3.7 3.7 0 003-1.4 3.7 3.7 0 003 1.4 3.7 3.7 0 003-1.4 3.9 3.9 0 006 0 3.7 3.7 0 003 1.4 4.6 4.6 0 002-.5V23a1 1 0 01-1 1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgStore
