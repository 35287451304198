export const compareMenuOptionLabels = {
  null: 'None',
  allData: 'All App Data',
  anotherVersion: 'Another Version',
  previousPeriod: 'Previous Period (All Data)',
  previousPeriodOtherVersion: 'Previous Period (Other Version)',
}

export const compareMenuFeatureMenuOptions = [
  { label: 'None', value: null },
  { label: 'All App Data', value: 'allData' },
  { label: 'Another Version', value: 'anotherVersion' },
  { label: 'Previous Period (All Data)', value: 'previousPeriod' },
  {
    label: 'Previous Period (Other Version)',
    value: 'previousPeriodOtherVersion',
  },
]
