import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import TableCell from '@material-ui/core/TableCell'
import CategorizeFeedbackIcon from './CategorizeFeedbackIcon'

import { types } from '.'
import * as S from './styles'

export function getCategoryByValue(
  id?: string | null,
  categories?: { label: string; value: string }[]
) {
  return categories?.find(category => category.value === id)
}

function CategoryTableCell({
  categoryContent,
  suppressManualCategory,
  feedbackID,
  manualCategories,
  categories,
  columnWidth,
}: CategoryTableCellProps) {
  const categoriesAndManualCategories: Array<
    (types.ManualCategory & types.CategoryModel) | undefined
  > = isArray(categoryContent)
      ? categoryContent.concat(manualCategories || [])
      : [categoryContent].concat(manualCategories || [])
  const cellContent = categoriesAndManualCategories.map(item => {
    const categoryLabelByValue = getCategoryByValue(item?.category, categories)?.label
    const categoryLabelById = getCategoryByValue(item?._id, categories)?.label

    return (
      <S.CategoryTableCellContainer
        key={item?._id}
        hasMultiple={categoriesAndManualCategories?.length > 1}
      >
        <S.CategoryTableCellCategory>
          {categoryLabelByValue || categoryLabelById || item?.category}
        </S.CategoryTableCellCategory>
        {suppressManualCategory ? null : (
          <S.CategoryTableCellSubcategory>{item?.manualCategory}</S.CategoryTableCellSubcategory>
        )}
      </S.CategoryTableCellContainer>
    )
  })

  return (
    <TableCell>
      <S.CategoryTableCell width={columnWidth} >
        {!suppressManualCategory && <CategorizeFeedbackIcon feedbackID={feedbackID} />}
        {isEmpty(categoryContent) ? (
          'N/A'
        ) : (
          <S.CategoriesCellContainer>{cellContent}</S.CategoriesCellContainer>
        )}
      </S.CategoryTableCell>
    </TableCell>
  )
}

interface CategoryTableCellProps {
  categoryContent?: types.CategoryModel | types.CategoryModel[]
  suppressManualCategory?: boolean
  feedbackID?: string
  manualCategories?: types.ManualCategory[]
  categories?: { label: string; value: string }[]
  columnWidth?: number
}

export default CategoryTableCell
