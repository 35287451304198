import * as React from 'react'

function SvgShoppingCartF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 26" {...props}>
      <path
        d="M7 22a2 2 0 110 4 2 2 0 010-4zm14 0a2 2 0 110 4 2 2 0 010-4zM3.847 0l.666 4H26.22l-1.918 9.589A3.007 3.007 0 0121.36 16H6.514l.194 1.165c.08.483.497.837.986.835H23v2H7.694a2.99 2.99 0 01-2.959-2.507L2.153 2H0V0h3.847z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgShoppingCartF
