import styled from 'styled-components'

export const LiveSupport = styled.div`
  margin-bottom: 0.3rem;
`

export const TextButton = styled.div`
  color: #008cff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
