import React from 'react'
import { useLocation } from '@reach/router'
import { Button } from 'components/shared/Button'
import Tooltip from 'components/shared/Tooltip'
import MenuList from '@material-ui/core/MenuList'
import Entity from 'components/shared/Entity'
import useApps from 'hooks/useApps'
import useGroups from 'hooks/useGroups'
import Flex from 'components/shared/flex/Flex'
import SmallerHeading from '../SmallerHeading'
import { getTitle } from '../utils'
import { UserPageFavorite } from 'components/App/Providers/Data/PrismUser'

const CurrentlyAtPage = ({
  setFavoriteName,
  setFavoriteDescription,
  setIsAddModalOpen,
  currentPageFavorites,
}: {
  setFavoriteName: Function
  setFavoriteDescription: Function
  setIsAddModalOpen: Function
  currentPageFavorites: UserPageFavorite[]
}) => {
  const { entityID, app } = useApps()
  const { currentGroup } = useGroups()
  const location = useLocation()

  const path = location.pathname
  const title = getTitle(path, entityID)

  const handleOpenAddDialog = () => {
    setFavoriteName(title)
    setFavoriteDescription(currentGroup ? currentGroup.description : app.id || '')
    setIsAddModalOpen(true)
  }

  const AddButton = () =>
    currentPageFavorites.length < 5 ? (
      <Button
        variant="borderless"
        icon="PlusCircleF"
        width="100%"
        onClick={handleOpenAddDialog}
        data-test="addPageCurrentSectionButton"
      >
        Add Current Page
      </Button>
    ) : (
      <Tooltip title="5 Page Favorite limit reached. Click edit icon next to the Pages menu to remove some.">
        <Button
          variant="borderless"
          icon="PlusCircleF"
          width="100%"
          disabled
          data-test="addPageCurrentSectionButton"
        >
          Add Current Page
        </Button>
      </Tooltip>
    )

  return (
    <Flex direction="column">
      <SmallerHeading title="at Page" indent={true} dataTest="atPageTitle" />
      <MenuList>
        <Entity
          title={title}
          description={currentGroup ? currentGroup.description : app.id || ''}
          data-pho={title}
        />
      </MenuList>
      <AddButton />
    </Flex>
  )
}

export default CurrentlyAtPage
