import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'
import isString from 'lodash/isString'

export const sortDataByColumn = (data: any, activeColumnIndex: number, sortReverse: boolean) => {
  const sortedByColumnData = sortBy(data, [
    function (data) {
      return isString(data[activeColumnIndex])
        ? data[activeColumnIndex]?.toLowerCase()
        : data[activeColumnIndex]
    },
  ])

  if (sortReverse) {
    return reverse(sortedByColumnData)
  } else return sortedByColumnData
}
