import * as React from 'react'

function SvgEyeF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 22" {...props}>
      <path
        d="M28.93 10.63C28.75 10.19 24.57 0 15 0S1.25 10.19 1.07 10.63L.92 11l.15.37C1.25 11.81 5.43 22 15 22s13.75-10.19 13.93-10.63l.15-.37-.15-.37zM15 16a5 5 0 110-10 5 5 0 010 10z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgEyeF
