import * as React from 'react'

function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M28 14a14.002 14.002 0 01-11.81 13.83v-9.78h3.26l.62-4.05h-3.88v-2.63a2.02 2.02 0 012.28-2.18h1.76V5.74a21.737 21.737 0 00-3.13-.27c-3.2 0-5.29 1.94-5.29 5.45V14H8.26v4.05h3.55v9.78C4.454 26.666-.703 19.946.074 12.539.852 5.132 7.293-.37 14.731.019 22.168.41 27.999 6.552 28 14z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFacebook
