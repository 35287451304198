import * as React from 'react'

function SvgSignalF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M4 16a2 2 0 012 2v6a2 2 0 01-2 2H0v-8a2 2 0 012-2h2zM24 0a2 2 0 012 2v22a2 2 0 01-2 2h-4V2a2 2 0 012-2h2zM14 8a2 2 0 012 2v14a2 2 0 01-2 2h-4V10a2 2 0 012-2h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSignalF
