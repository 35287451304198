import * as React from 'react'

function SvgLockF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M19 10V7A7 7 0 005 7v3H0v15a3.009 3.009 0 003 3h18a3.009 3.009 0 003-3V10h-5zm-6 13h-2v-8h2v8zm4-13H7V7a5 5 0 0110 0v3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLockF
