import * as React from 'react'

function SvgDvrNav(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M23 0a3 3 0 013 3v20a3 3 0 01-3 3H0V3a3 3 0 013-3h20zm0 2H3a1 1 0 00-1 1v21h21a1 1 0 001-1V3a1 1 0 00-1-1zM13 4a8.881 8.881 0 015.3 1.74l-1.43 1.43A7.006 7.006 0 1020 13a2.17 2.17 0 00-.03-.44l1.76-1.76A8.9 8.9 0 0122 13a9 9 0 11-9-9zm7.293 2.579l1.414 1.414L13 16.7l-4.136-4.136 1.414-1.414L13 13.872l7.293-7.293z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDvrNav
