import styled, { css } from 'styled-components'
import { body } from '../typography'
import { colors } from 'components/shared/utils/colors'
import { element } from 'components/shared/utils/spacing'
import Icon from '../icon/Icon'

interface StyledInput {
  disabled?: boolean
  $isDarkMode?: boolean
  checked: boolean
}

export const Input = styled.input.attrs({ type: 'checkbox' })<StyledInput>`
  appearance: none;
  width: 20px;
  height: 20px;
  margin: 0;
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ $isDarkMode }) => ($isDarkMode ? colors.white : colors.gray30)};
  cursor: pointer;

  :checked {
    background-color: ${colors.blue20};
    border-color: ${colors.blue20};
  }

  :checked:disabled {
    background-color: ${({ $isDarkMode }) => ($isDarkMode ? colors.gray30 : colors.gray25)};
  }

  :disabled {
    border-color: ${({ $isDarkMode }) => ($isDarkMode ? colors.gray30 : colors.gray25)};
    cursor: not-allowed;
  }

  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  :focus {
    outline: none;
    box-shadow: ${colors.blue20} 0px 0px 2px 2px;
  }
  :focus:not(.focus-visible) {
    outline: none;
    box-shadow: none;
  }
`

interface LabelProps {
  $disabled?: boolean
  $isDarkMode?: boolean
}

export const Label = styled.label<any>`
  ${body}
  margin-bottom: 0;
  padding-left: ${element.xs};
  line-height: 20px;
  cursor: pointer;

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${colors.gray25};
      cursor: not-allowed;
    `};
`

export const CheckIcon = styled(Icon).attrs({
  name: 'Checkmark',
  color: 'white',
  size: 'xs',
})`
  position: absolute;
  top: 3px;
  left: 3px;
  cursor: pointer;
`

export const DashIcon = styled(Icon).attrs({
  name: 'MinusAlt',
  size: '12px',
})<{ $isDarkMode?: boolean }>`
  position: absolute;
  top: 4px;
  left: 4px;
  cursor: pointer;
  color: ${({ $isDarkMode }) => ($isDarkMode ? colors.white : colors.black)};
`
