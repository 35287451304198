import React from 'react'
import { Link, useLocation } from '@reach/router'
import Entity from 'components/shared/Entity'
import useGroups from 'hooks/useGroups'
import useApps from 'hooks/useApps'
import { getServerAnalyticsItems } from 'components/layout/DashboardSidebar/items'
import {
  getAppOrGroupLink,
  getClientAnalyticsDevResourcesItems,
  getDomain,
  APP_PLACEHOLDER,
} from '../utils'
import Flex from 'components/shared/flex/Flex'
import Tooltip from '@material-ui/core/Tooltip'

function GroupsList({
  handleGroupSwitcherClose,
}: {
  handleGroupSwitcherClose: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}) {
  const { appsByDataSource, appsByDomain, entityID } = useApps()
  const { groups } = useGroups()
  const { pathname } = useLocation()

  const groupRows = groups.map(group => {
    const applicationName = group.applicationIDs
    const groupName = group.name
    const groupDescription = group.description
    const clientAnalyticsDevResourcesItems = getClientAnalyticsDevResourcesItems()
    const serverAnalyticsItems = getServerAnalyticsItems(APP_PLACEHOLDER)
    const section = appsByDataSource.quantum.some(app => group.applicationIDs.includes(app))
      ? 'client-analytics'
      : 'server-analytics'
    const domain = getDomain(group.applicationIDs[0], appsByDomain)
    const items = appsByDataSource.quantum.some(app => group.applicationIDs.includes(app))
      ? clientAnalyticsDevResourcesItems
      : serverAnalyticsItems

    const link = getAppOrGroupLink(items, domain, group._id, pathname, entityID, section, true)

    return (
      <Tooltip
        arrow={true}
        title={applicationName.map(data => (
          <Flex height={40} style={{ wordBreak: 'break-all' }}>
            <ul>
              <li>{data}</li>
            </ul>
          </Flex>
        ))}
        placement="right"
      >
        <Link onClick={handleGroupSwitcherClose} to={link}>
          <Entity title={groupName} description={groupDescription} data-pho="group" />
        </Link>
      </Tooltip>
    )
  })

  return <div>{groupRows}</div>
}

export default GroupsList
