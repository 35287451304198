import styled from 'styled-components'
import { Card } from 'components/shared/Card'
import { section } from 'components/shared/utils/spacing'
import { mediaQueries } from 'components/shared/utils/breakpoints'

export const ButtonContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

export const DialogCard = styled(Card)`
  max-width: 100vw;
  max-height: 100vh;
  padding: ${section.xs};

  ${mediaQueries.xs},${mediaQueries.sm} {
    border-radius: 0;
    height: 100vh;
  }

  ${mediaQueries.md},${mediaQueries.lg} {
    max-height: 72vh;
  }

  ${mediaQueries.xl},${mediaQueries.xxl} {
    max-height: 84vh;
  }
`

export const DialogCardDs = styled(Card).attrs({
  tabIndex: -1,
  disableBorder: true,
  disableShadow: true,
  disablePadding: true,
})<{ $maxHeight?: string | number; $minWidth?: string | number }>`
  position: relative;
  width: 67vw;
  height: 84vh;
  padding: ${section.xs};
  max-height: ${({ $maxHeight }) => $maxHeight ?? 'unset'};
  min-width: ${({ $minWidth }) => $minWidth ?? 'unset'};
  overflow: visible;

  > div {
    height: 100%;
  }

  ${mediaQueries.xs},${mediaQueries.sm} {
    border-radius: 0;
    width: 100vw;
    height: 100vh;
    min-width: ${({ $minWidth }) => $minWidth ?? 'unset'};
  }

  ${mediaQueries.md} {
    width: 75vh;
    min-width: ${({ $minWidth }) => $minWidth ?? 'unset'};
  }

  ${mediaQueries.xl},${mediaQueries.xxl} {
    height: 84vh;
    min-width: ${({ $minWidth }) => $minWidth ?? 'unset'};
  }

  ${mediaQueries.xxl} {
    width: 50vw;
    min-width: ${({ $minWidth }) => $minWidth ?? 'unset'};
  }
`
