import keyMirror from 'keymirror'
import { colors } from 'components/shared/utils/colors'
import { css } from 'styled-components'

export const entityTypes = keyMirror({
  GROUP: null,
  EVENT_TYPE: null,
  EVENT_CASE: null,
  TEST_CASE: null,
  FORMULA: null,
  ATOM: null,
  ACTIVATION_EVENT: null,
})

export const sizes = keyMirror({
  SM: null,
  MD: null,
  LG: null,
  XL: null,
})

export const environments = {
  DEVELOP: 'develop',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

export enum Domains {
  Internal = 'internal_apps',
  Portals = 'portals',
  Video = 'video',
  JourneySurvey = 'journey_survey',
  EmployeeExperience = 'employee_experience',
  CstSurvey = 'cst_survey',
  Unknown = 'unknown',
}

export enum OsIndex {
  PROD_INTERNAL = 'prod_internal_quantum*',
  PROD_SSPP = 'quantum_prod_sspp_finished*',
  PROD_VIDEO = 'quantum_prod',
  PROD_ALL = 'quantum_prod_all',
  STAGING_INTERNAL = 'stg_internal_quantum*',
  STAGING_SSPP = 'stg_sspp_finished*',
  STAGING_VIDEO = 'quantum_staging',
  STAGING_SPECGUIDE = 'stg_specguide_finished_*',
  STAGING_ALL = 'quantum_all',
}

export const enumValues = {
  DENSITY: [{ label: 'Moderate', value: 'moderate' }],
  ELEMENT_TYPE: [
    { label: 'Component', value: 'component' },
    { label: 'ExternalApp', value: 'externalApp' },
    { label: 'OtaChannels', value: 'otaChannels' },
    { label: 'SearchResult', value: 'searchResult' },
    { label: 'SegmentInfo', value: 'segmentInfo' },
    { label: 'String', value: 'string' },
  ],
  INPUT_TYPE: [{ label: 'Date Time', value: 'datetime' }],
  ORDER: [
    { label: 'Ascending', value: 'ascending' },
    { label: 'Descending', value: 'descending' },
  ],
  SOURCE: [
    { label: 'Client', value: 'client' },
    { label: 'Collector', value: 'collector' },
    { label: 'Enrichment', value: 'enrichment' },
    { label: 'SDK', value: 'sdk' },
    { label: 'Core', value: 'core' },
  ],
  EVENT_CASE_SOURCE: [
    { label: 'Client', value: 'client' },
    { label: 'Collector', value: 'collector' },
    { label: 'Enrichment', value: 'enrichment' },
    { label: 'SDK', value: 'sdk' },
  ],
  TRACKER_TYPE: ['Venona'],
  TYPE: [
    { label: 'Array', value: 'array' },
    { label: 'Boolean', value: 'boolean' },
    { label: 'Double', value: 'double' },
    { label: 'Int', value: 'int' },
    { label: 'Long', value: 'long' },
    { label: 'Map', value: 'map' },
    { label: 'String', value: 'string' },
  ],
}

export const rootApiUrl = process.env.REACT_APP_PRISM_API_ENDPOINT || 'http://localhost:8081'
export const cdnUrl = process.env.REACT_APP_CDN_ENDPOINT || 'https://cdn.spectruminternal.com'
export const quantumUiUrl = 'quantum.spectrumtoolbox.com'

export const chartColors = [
  colors.blue10,
  colors.green10,
  colors.teal,
  colors.yellow10,
  colors.salmon,
  colors.purple,
  colors.orange,
  colors.red,
  colors.green,
  colors.darkBlue10,
]

export const regex = {
  entityIDFromPath: /\/[a-zA-Z-@\s]*/g,
}

export const REGEX_VALID_VISIT_ID_FORMAT = RegExp(
  '^([a-zA-Z0-9-x]*)?[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$'
)

export const chartsGridColumnCount = 12

export enum DataSources {
  Quantum = 'QUANTUM',
  Qube = 'QUBE',
}

export const authorizedNameMappings = [
  'quantum-mapping-edit',
  'qube-mapping-edit',
  'internal-quantum-mapping-edit',
  'prism-admin',
  'prism-superadmin',
]
export const authorizedRequirementsVersionSwap = ['quantum-basic-SDK-Developers-DL-Quantum']
export enum codeSnippetLanguages {
  JAVASCRIPT = 'JAVASCRIPT',
  C_SHARP = 'C_SHARP',
  JSON = 'JSON',
  BRIGHTSCRIPT = 'BRIGHTSCRIPT',
  KOTLIN = 'KOTLIN',
  SWIFT = 'SWIFT',
}

export enum codeSnippetLanguagesDisplay {
  JAVASCRIPT = 'JavaScript',
  C_SHARP = 'C#',
  JSON = 'JSON',
  BRIGHTSCRIPT = 'BrightScript',
  KOTLIN = 'Kotlin',
  SWIFT = 'Swift',
  XAMARIN = 'Xamarin',
}

export enum LanguagesPlatformsMap {
  JAVASCRIPT = 'JS',
  KOTLIN = 'ANDROID',
  BRIGHTSCRIPT = 'ROKU',
  SWIFT = 'SWIFT',
  C_SHARP = 'XU',
}

export enum ReleaseNotesPlatform {
  PRISM = 'PRISM',
  QUANTUMJS = 'QUANTUMJS',
  JS = 'JS',
  ANDROID = 'ANDROID',
  ROKU = 'ROKU',
  SWIFT = 'SWIFT',
  XU = 'XU',
}

export enum ReleaseNotesPlatformDisplay {
  PRISM = 'Prism',
  QUANTUMJS = 'Javascript (Quantum)',
  JS = 'JavaScript',
  XU = 'XU',
  ROKU = 'Roku',
  SWIFT = 'Swift',
  ANDROID = 'Android',
  MORPH = 'Morph',
  VOLT = 'Volt API',
  GENERATOR = 'Requirements Generator',
  EXTENSION = 'Quantum Chrome Extension',
  POSITRON = 'Positron',
  RDT = 'Quantum Dashboard (RTD)',
}

export enum ReleaseNotesToolsPlatform {
  MORPH = 'MORPH',
  VOLT = 'VOLT',
  GENERATOR = 'GENERATOR',
  EXTENSION = 'EXTENSION',
  POSITRON = 'POSITRON',
  RDT = 'RDT',
}

export enum WikiIds {
  PRISM = 7704, // gitlab.spectrumflow.net/client-analytics/dev/prism
  QUANTUMJS = 167, // gitlab.spectrumflow.net/product-intelligence/quantum-js-client
  JS = 4288, // gitlab.spectrumflow.net/product-intelligence/quantum-js-helix
  XU = 3293, // gitlab.spectrumflow.net/product-intelligence/XUAnalytics
  ROKU = 166, // gitlab.spectrumflow.net/product-intelligence/quantum-roku-client
  SWIFT = 169, // gitlab.spectrumflow.net/product-intelligence/quantum-swift-client
  ANDROID = 25488, // gitlab.spectrumflow.net/product-intelligence/quantum-android-client
  MORPH = 8275, //gitlab.spectrumflow.net/client-analytics/sdk/morph/
  VOLT = 17300, // gitlab.spectrumflow.net/client-analytics/sdk/volt-test-engine
  GENERATOR = 4447, // gitlab.spectrumflow.net/product-intelligence/quantum-js-requirements
  EXTENSION = 23970, // gitlab.spectrumflow.net/client-analytics/sdk/quantum-chrome-extension
  POSITRON = 28016, //gitlab.spectrumflow.net/client-analytics/sdk/positron
  RDT = 22054, //gitlab.spectrumflow.net/product-intelligence/quantum-dashboard
  QST = 1657, //https://gitlab.spectrumflow.net/product-intelligence/quantum-specs-tools
  LUMEN = 40451, //https://gitlab.spectrumflow.net/client-analytics/sdk/lumen
}

export enum propertyTypes {
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  DOUBLE = 'double',
  INTEGER = 'int',
  LONG = 'long',
  MAP = 'map',
  STRING = 'string',
  TEXT = 'text',
}

export enum dataTypes {
  SCALAR = 'SCALAR',
  ENUM = 'ENUM',
}

export enum enumTypes {
  DENSITY = 'DENSITY',
  ELEMENT_TYPE = 'ELEMENT_TYPE',
  INPUT_TYPE = 'INPUT_TYPE',
  ORDER = 'ORDER',
  SOURCE = 'SOURCE',
  TRACKER_TYPE = 'TRACKER_TYPE',
  TYPE = 'TYPE',
}

const spectrumSans = [
  '"Spectrum Sans"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',')

export const defaultFont = css`
  font-family: ${spectrumSans};
`

export enum OperatorMapping {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
  IS_ONE_OF = 'IS_ONE_OF',
  IS_ALL_OF = 'IS_ALL_OF',
  IS_NONE_OF = 'IS_NONE_OF',
  EXISTS = 'EXISTS',
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
  EXISTS_AND_IS_NOT = 'EXISTS_AND_IS_NOT',
  EXISTS_AND_IS_NOT_ONE_OF = 'EXISTS_AND_IS_NOT_ONE_OF',
}

export const operatorLabelMapping = {
  [OperatorMapping.IS]: 'Is',
  [OperatorMapping.IS_NOT]: 'Is not',
  [OperatorMapping.IS_ONE_OF]: 'Is one of',
  [OperatorMapping.IS_ALL_OF]: 'Is all of',
  [OperatorMapping.IS_NONE_OF]: 'Is none of',
  [OperatorMapping.EXISTS]: 'Exists',
  [OperatorMapping.DOES_NOT_EXIST]: 'Does not exist',
  [OperatorMapping.EXISTS_AND_IS_NOT]: 'Exists and is not',
  [OperatorMapping.EXISTS_AND_IS_NOT_ONE_OF]: 'Exists and is not one of',
}
