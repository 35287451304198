import * as React from 'react'

function SvgRouterWave(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 28" {...props}>
      <path
        d="M15.948 23.464l-.509-3.52.516-16.445A3 3 0 0012.997 0H2.998A3 3 0 00.04 3.499l.516 16.445-.5 3.467A3 3 0 002.999 27v1h3v-1h4v1h3v-1a2.99 2.99 0 002.32-1.098 2.96 2.96 0 00.63-2.438zM2.225 2.366A.996.996 0 012.998 2h10a1 1 0 01.98 1.196L13.468 19H2.528L2.037 3.361l-.02-.165a.995.995 0 01.208-.83zM13.77 24.634a.996.996 0 01-.773.366H2.998a.996.996 0 01-.773-.366 1.031 1.031 0 01-.198-.883L2.425 21H13.57l.407 2.804a.995.995 0 01-.208.83z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRouterWave
