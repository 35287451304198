import * as React from 'react'

function SvgAirplay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 22" {...props}>
      <path
        d="M13 14.5l7 7H6l7-7zm10-14a3.003 3.003 0 013 3v12a3.003 3.003 0 01-3 3h-4v-2h4c.552 0 1-.448 1-1v-12c0-.552-.448-1-1-1H3c-.552 0-1 .448-1 1v12c0 .552.448 1 1 1h4v2H3a3.003 3.003 0 01-3-3v-12a3.003 3.003 0 013-3h20z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAirplay
