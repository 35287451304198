import * as React from 'react'

function SvgLllustratorSwatchF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M14 14H4V4h10v10zm-8-2h6V6H6v6zm12-6V0H0v18h6v6h18V6h-6zM2 16V2h14v14H2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLllustratorSwatchF
