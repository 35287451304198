import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import fileDownload from 'js-file-download'
import Alert from '../Alert'
import MuiTableBody from '@material-ui/core/TableBody'
import { Button } from 'components/shared/Button'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import * as converter from 'json-2-csv'
import ExportButton from 'components/shared/ExportButton'
import TableHeaders from './eventSchemaTableHeaders'
import TableContent from './eventSchemaTableContent'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import * as S from './styles'

export default function SchemaTable({
  columnHeaders,
  columnAlignment,
  sortByColumn,
  data,
  eventCasesLink,
  columnCellFormat,
  twoColumns,
  tableRowCSS = twoColumns ? S.twoRowCSS : S.defaultRowCSS,
  eventCasesLinkColumn = 3,
  handleSelectFavorite,
  favorites,
  initialColumnSortIndex = 0,
  initialSortReverse = true,
  csvData,
  dataPho,
}: TableProps): React.FunctionComponentElement<TableProps> {
  const [sortReverse, setSortReverse] = useState(initialSortReverse)
  const [activeColumnIndex, setActiveColumnIndex] = useState(initialColumnSortIndex)
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadAlertMessage, setDownloadAlertMessage] = React.useState('')

  function handleSortDirectionChange(index: number) {
    setSortReverse(activeColumnIndex === index ? !sortReverse : false)
    setActiveColumnIndex(index)
  }

  function downloadDataAsCSV() {
    setIsDownloading(true)
    const fileDownloader = (data: any) => {
      fileDownload(data, `${csvData.fileName}.csv`)
      setIsDownloading(false)
    }
    converter.json2csv(csvData.data, (error, value) => {
      if (error) {
        console.error('Error converting JSON to CSV!')
        console.error(error)
        fileDownloader([])
      } else {
        fileDownloader(value)
      }
    })
  }

  function handleDownloadAlert(errorMessage: string) {
    setDownloadAlertMessage(errorMessage)
  }

  function handleDownloadAlertClose() {
    setDownloadAlertMessage('')
  }

  if (!data) {
    return (
      <S.TableContainer isLoading>
        <NoDataChartMessage />
      </S.TableContainer>
    )
  }

  if (isEmpty(data)) {
    return (
      <S.TableContainer isLoading>
        <LoadingSpinner />
      </S.TableContainer>
    )
  }

  return (
    <>
      {downloadAlertMessage && (
        <Alert
          children={downloadAlertMessage}
          variant="page-caution"
          onClose={handleDownloadAlertClose}
        />
      )}
      <S.TableContainer>
        {csvData && (
          <S.CSVDownloadContainer>
            <Button onClick={downloadDataAsCSV} loading={isDownloading}>
              Download CSV
            </Button>
            <ExportButton
              btnText="Download CSV"
              data={csvData.data}
              path={csvData.fileName}
              onError={handleDownloadAlert}
            />
          </S.CSVDownloadContainer>
        )}
        <S.StyledTable tableRowCSS={tableRowCSS} data-pho={dataPho}>
          <MuiTableBody>
            <TableHeaders
              columnHeaders={columnHeaders}
              columnAlignment={columnAlignment}
              sortByColumn={sortByColumn}
              handleSortDirectionChange={handleSortDirectionChange}
              activeColumnIndex={activeColumnIndex}
              sortReverse={sortReverse}
            />
            <TableContent
              data={data}
              columnAlignment={columnAlignment}
              columnCellFormat={columnCellFormat}
              eventCasesLink={eventCasesLink}
              sortByColumn={sortByColumn}
              activeColumnIndex={activeColumnIndex}
              sortReverse={sortReverse}
              eventCasesLinkColumn={eventCasesLinkColumn}
              handleSelectFavorite={handleSelectFavorite}
              favorites={favorites}
            />
          </MuiTableBody>
        </S.StyledTable>
      </S.TableContainer>
    </>
  )
}

interface TableProps {
  columnHeaders?: string[]
  columnAlignment?: { [key: number]: string }
  columnCellFormat?: { [key: number]: Function }
  sortByColumn: boolean
  twoColumns?: boolean
  data?: any
  eventCasesLink?: string
  tableRowCSS?: string
  eventCasesLinkColumn?: number
  handleSelectFavorite?: Function
  favorites?: string[]
  initialColumnSortIndex?: number
  initialSortReverse?: boolean
  csvData?: any
  dataPho?: string
}
