import { RefObject } from 'react'
import { WindowLocation } from '@reach/router'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import round from 'lodash/round'
import slice from 'lodash/slice'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import queryString from 'query-string'
import moment from 'moment'
import times from './times'
import numeral from 'numeral'
import { Group } from 'components/App/Providers/Data/Groups'
import { getAbsoluteFromRelativePeriod } from './times/times'
import keyMirror from 'keymirror'
import { codeSnippetLanguages } from 'common/constants'

export const appTypes = keyMirror({
  Web: null,
  OVP: null,
  TVSDK: null,
  OneAppAuthContext: null,
  Roku: null,
  RokuRsg: null,
  iOS: null,
  Android: null,
  AppleTV: null,
})

export const appNames = keyMirror({
  MySpectrum: null,
})

export const getCodeSnippetLanguages = (applications: any = []) => {
  const languages = applications.reduce((resultValue: any, application: any) => {
    const appType = (application?.type || '').toLowerCase()
    const appName = (application?.name || '').toLowerCase()
    if (
      (appType === appTypes.Web.toLowerCase() ||
        appType === appTypes.OVP.toLowerCase() ||
        appType === appTypes.TVSDK.toLowerCase() ||
        appType === appTypes.OneAppAuthContext.toLowerCase()) &&
      !resultValue.includes(codeSnippetLanguages.JAVASCRIPT)
    ) {
      return resultValue.concat(codeSnippetLanguages.JAVASCRIPT)
    }
    if (
      (appType === appTypes.Roku.toLowerCase() || appType === appTypes.RokuRsg.toLowerCase()) &&
      !resultValue.includes(codeSnippetLanguages.BRIGHTSCRIPT)
    ) {
      return resultValue.concat(codeSnippetLanguages.BRIGHTSCRIPT)
    }
    if (
      appName === appNames.MySpectrum.toLowerCase() &&
      !resultValue.includes(codeSnippetLanguages.C_SHARP)
    ) {
      return resultValue.concat(codeSnippetLanguages.C_SHARP)
    }
    if (
      appType === appTypes.Android.toLowerCase() &&
      !resultValue.includes(codeSnippetLanguages.KOTLIN)
    ) {
      return resultValue.concat(codeSnippetLanguages.KOTLIN)
    }
    if (
      (appType === appTypes.iOS.toLowerCase() || appType === appTypes.AppleTV.toLowerCase()) &&
      !resultValue.includes(codeSnippetLanguages.SWIFT)
    ) {
      return resultValue.concat(codeSnippetLanguages.SWIFT)
    }
    return resultValue
  }, [])

  return languages
}

export function getRouteWithQueries(
  route: string,
  location: WindowLocation,
  queriesToRetain: string[] = [
    'environment',
    'dataSource',
    'timePeriod',
    'startDate',
    'endDate',
    'period',
    'step',
    'activatedExperiments',
    'variantUuids',
  ],
  arrayFormat: 'bracket' | 'index' | 'comma' | 'separator' | 'none' | undefined = 'bracket'
): string {
  const currentQueries = queryString.parse(location.search)
  const retainedQueries = pick(currentQueries, queriesToRetain)
  if (isEmpty(retainedQueries)) {
    return route
  }
  return `${route}?${queryString.stringify(retainedQueries, {
    encode: false,
    arrayFormat,
  })}`
}

export function getPageNameFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query)) {
    return ''
  }
  return query.pageName as string
}

export function getApiNameFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query)) {
    return ''
  }
  return query.apiName as string
}

export function getHttpVerbFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query) || !query.httpVerb) {
    return ''
  }
  return query.httpVerb as string
}

export function getApiOperationNameFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query) || !query.apiOperationName) {
    return ''
  }
  return query.apiOperationName as string
}

export function getApiOperationTypeFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query) || !query.apiOperationType) {
    return ''
  }
  return query.apiOperationType as string
}

export function getApiArchitectureFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query) || !query.apiArchitecture) {
    return ''
  }

  return query.apiArchitecture as string
}

export function getErrorCodeFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query)) {
    return ''
  }
  return query.errorCode as string
}

export function getErrorTypeFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query)) {
    return ''
  }
  return query.errorType as string
}

export function getEventTypeFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query)) {
    return ''
  }
  return query.eventType as string
}

export function getValidationTypeFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query)) {
    return ''
  }
  return query.validationType as string
}

export function getErrorMessageFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query)) {
    return ''
  }
  return query.errorMessage as string
}

export function getReleaseVersionFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query)) {
    return ''
  }
  return query.releaseVersion as string
}
export function getResponseCodeFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query)) {
    return ''
  }
  return query.responseCode as string
}

export function getResponseTextFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query) || !query.responseText) {
    return ''
  }
  return query.responseText as string
}

export function roundToHundredth(x: number): number {
  return round(x, 2)
}

export function getEventCaseIDFromQuery(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query)) {
    return ''
  }

  return query.eventCaseID as string
}

export function limitTopData(data: Record<string, string>[] = [], limit?: number, source?: string) {
  if (source && source === 'qube') {
    return data
  }
  if (limit && limit >= data.length) {
    return slice(data, 0, limit)
  } else if (data.length > 10) {
    return slice(data, 0, 10)
  }

  return data
}

export function copyToClipboard(textToCopy: string): void {
  navigator?.clipboard?.writeText(textToCopy)
}

export function roundToTwoDecimals(value: number): number {
  return Math.round(value * 100) / 100
}

export function getPreviousPeriod(period: string): string {
  const normalizedPeriod = times.normalizeToAbsolutePeriod(period)
  const [startDate, endDate] = normalizedPeriod.split(':')
  const parsedStartDate = Number(startDate)
  const parsedEndDate = Number(endDate)

  const daysDistance = moment(parsedEndDate).diff(moment(parsedStartDate), 'days') + 1

  const prevStartDate = moment(parsedStartDate).subtract('days', daysDistance).startOf('day')

  return `${prevStartDate.valueOf()}:${moment(parsedStartDate).valueOf()}`
}

export function getPercentageChange(
  prevValue?: number,
  newValue?: number,
  returnAsFraction?: boolean
): number {
  if (prevValue === undefined || newValue === undefined) {
    return 0
  }
  const percentageChange = (newValue - prevValue) / Math.abs(prevValue)
  if (returnAsFraction) {
    return roundToTwoDecimals(percentageChange)
  }
  return roundToTwoDecimals(percentageChange * 100)
}

export function getAbsolutePeriodFromURL(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')

  if (isEmpty(query)) {
    return `${times.getDays('7')}:${times.lastFiveMinuteInterval()}`
  }
  return times.normalizeToAbsolutePeriod((query?.period as string) ?? '7d')
}

export function getPeriodFromUrl(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  return query.period as string
}

export function getIntervalFromUrl(location?: WindowLocation): string {
  const query = queryString.parse(location?.search || '')
  if (isEmpty(query)) {
    return '86400000'
  }
  return (query.step as string) || '86400000' // 1 day interval
}

export function lineChartYAxisFormater(value: number | string): string | number {
  if ((value as number) >= 1000000) {
    return numeral(value).format('0.0a').toUpperCase()
  }
  if ((value as number) >= 1000) {
    return numeral(value).format('0a').toUpperCase()
  }

  return value
}

export function isEmptyOrNil(value?: string | string[] | object | null | boolean): boolean {
  if (isArray(value)) {
    return isEmpty(value)
  }
  if (isString(value)) {
    return value.length === 0
  }
  return isNil(value)
}

export function getRefreshIntervalFromStep(step: number, partialData: boolean): number {
  const parsedStep = isNaN(step) ? 86400000 : Number(step) // for non-numerical steps, defaults to day step
  const nextIncrement = times.getNextIncrementFromStep(parsedStep, !!partialData)
  const diff = moment(nextIncrement).diff(moment())

  return diff
}

export function getDaysDistanceFromAbsolutePeriod(period: string): number {
  let p = period
  if (!period.includes(':')) {
    p = getAbsoluteFromRelativePeriod(period)
  }

  if (!p) throw new Error('Invalid period supplied')

  const [startDate, endDate] = p.split(':')
  const diff = moment(Number(startDate)).diff(Number(endDate), 'days')

  return Math.abs(diff)
}

export function getFullElementHeight(
  chartContainerRef: RefObject<HTMLDivElement>,
  elementContainerRef: RefObject<HTMLDivElement>
): number {
  const actualChartHeight =
    (chartContainerRef &&
      chartContainerRef?.current &&
      get(chartContainerRef, 'current.parentElement.parentElement.clientHeight')) ||
    0

  const currentChartContainerHeight =
    (chartContainerRef &&
      chartContainerRef?.current &&
      get(chartContainerRef, 'current.clientHeight')) ||
    0

  const currentElementHeight =
    (elementContainerRef &&
      elementContainerRef?.current &&
      get(elementContainerRef, 'current.clientHeight')) ||
    0

  return actualChartHeight - currentChartContainerHeight + currentElementHeight
}

export function checkIfGroupsAreTheSame(groups: Group[], newGroups: Group[]): boolean {
  const groupIds = groups.map((group: Group) => group._id).sort()
  const newGroupIds = newGroups.map((group: Group) => group._id).sort()
  const areGroupsTheSame = JSON.stringify(groupIds) === JSON.stringify(newGroupIds)

  return areGroupsTheSame
}

export function getMockedNumWithTwoDecimals(start: number, end: number): string {
  return (Math.random() * (start - end) + end).toFixed(2)
}

export { default as graphQL } from './graphQL'
export { default as times } from './times'
