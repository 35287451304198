import * as React from 'react'

function SvgSetTopBox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 16" {...props}>
      <path
        d="M28 0v14h-2v2h-5v-2H7v2H2v-2H0V0h28zm-2 2H2v10h24V2zM11 8v2H4V8h7zm12 0a1 1 0 110 2 1 1 0 010-2zM5 4a1 1 0 110 2 1 1 0 010-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSetTopBox
