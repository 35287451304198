import * as React from 'react'

function SvgTvSendF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M14 0v14h14v3a3.009 3.009 0 01-3 3H15v2h4a2 2 0 012 2H7a2 2 0 012-2h4v-2H3a3.009 3.009 0 01-3-3V3a3.009 3.009 0 013-3h11zm4 0v8.591l7.588-7.589 1.414 1.415L19.419 10H28v2H16V0h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTvSendF
