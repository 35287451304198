import * as React from 'react'

function SvgLink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M6.343 7.758l1.414 1.414-4.242 4.242a5 5 0 007.07 7.071l4.243-4.242 1.414 1.414L12 21.9a6.979 6.979 0 01-4.95 2.047A6.997 6.997 0 012.1 12l4.243-4.242zm9.9-1.415l1.414 1.414-9.9 9.9-1.414-1.414 9.9-9.9zM21.9 2.1a7 7 0 010 9.899l-4.242 4.242-1.414-1.414 4.242-4.242a5 5 0 10-7.07-7.071L9.171 7.757 7.758 6.343 12 2.1a7 7 0 019.899 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLink
