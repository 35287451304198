import { useState } from 'react'
import * as S from './styles'
import Tooltip from '@material-ui/core/Tooltip'
import { Button } from 'components/shared/Button'
import { Toast } from 'components/shared/Toast'
import { copyToClipboard } from 'utils'

function CopyVisitIDButton({
  visitID,
  isTextButton = false,
}: {
  visitID: string
  isTextButton?: boolean
}) {
  const [open, setOpen] = useState(false)

  function handleClick() {
    copyToClipboard(visitID)
    setOpen(true)
  }
  if (isTextButton) {
    return (
      <>
        <Toast
          placement="bottom-left"
          isToastOpen={open}
          onDismiss={() => setOpen(false)}
          duration={6}
          label={`Copied to Clipboard: ${visitID}`}
        />
        <Tooltip title={`Copy to clipboard: ${visitID}`} placement="top" arrow>
          <div>
              <Button icon="Copy" onClick={handleClick} variant="borderless" data-pho="copyVisitID">
                Copy Visit ID
              </Button>
          </div>
        </Tooltip>
      </>
    )
  } else {
    return (
      <>
        <S.ButtonDiv onClick={handleClick} data-pho="copyVisitID">
          <Toast
            placement="bottom-left"
            isToastOpen={open}
            onDismiss={() => setOpen(false)}
            duration={6}
            label={`Copied to Clipboard: ${visitID}`}
          />
          <Tooltip title={`Copy to clipboard: ${visitID}`} placement="top" arrow>
            <S.VisitIdIcon />
          </Tooltip>
        </S.ButtonDiv>
      </>
    )
}
}

export default CopyVisitIDButton
