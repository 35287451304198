import * as services from './services'
import isNil from 'lodash/isNil'
import { rootApiUrl } from './constants'
import { Method } from 'axios'
import { axios, axiosConfigBase, FetchArgs } from '../hooks/useApi'

export async function fetch<T = any, V = Record<string, any> | string>({
  service,
  query = '',
  variables,
  route = '',
  method,
  body,
}: FetchArgs<T, V>) {
  if (service) {
    if (service.type === services.ServiceType.REST) {
      const url = isNil(query)
        ? `${rootApiUrl}${service.url}${route}`
        : `${rootApiUrl}${service.url}${route}?${query}`

      const axiosConfig = {
        ...axiosConfigBase(),
        method: (method ?? 'GET') as Method,
        url,
        data: body,
      }

      const res = await axios(axiosConfig)
      return res.data
    } else {
      const axiosConfig = {
        ...axiosConfigBase(),
        method: (method ?? 'POST') as Method,
        data: body || { query, variables },
        url: `${rootApiUrl}${service.url}`,
      }
      const res = await axios({ ...axiosConfig })
      return res.data
    }
  }
  throw new Error('service is undefined, cannot fetch data from url')
}
