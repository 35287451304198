import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { colors } from 'components/shared/utils/colors'
import NucleusReactSunburstChart from './NucleusReactSunburstChart'
import { FormattedSunburstData } from 'components/shared/ChartData/types'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import * as S from './styled'
import { useDarkMode } from 'components/App/Providers/DarkMode'

export default function SunburstChart({
  data,
  infoText,
  hoveredItemPath,
  ...props
}: {
  data: FormattedSunburstData
  infoText?: string
  hoveredItemPath?: Function
}) {
  const { isDarkMode } = useDarkMode()

  if (isEmpty(data)) {
    return <NoDataChartMessage />
  }

  return (
    <S.Container hoverInfoColor={isDarkMode ? colors.darkMode.text : colors.gray30}>
      <NucleusReactSunburstChart data={data} hoveredItemPath={hoveredItemPath} {...props} />
      {infoText && <S.InfoText>{infoText}</S.InfoText>}
    </S.Container>
  )
}
