import * as React from 'react'

function SvgCast(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 25" {...props}>
      <path
        d="M25 .004a3.003 3.003 0 013 3v21H17v-2h9v-19c0-.552-.448-1-1-1H2V7H0V.004h25zm-14.293 13.29A14.67 14.67 0 0115 24h-2A12.715 12.715 0 000 11V9a14.67 14.67 0 0110.707 4.293zM0 14a9.894 9.894 0 0110 10H8a7.825 7.825 0 00-8-8v-2zm0 4.91a5.475 5.475 0 015.09 5.089H1a1 1 0 01-1-1v-4.09z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCast
