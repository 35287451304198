import * as React from 'react'

function SvgCameraF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M22.62 4l-1.17-2.34A2.992 2.992 0 0018.76 0H9.24a2.992 2.992 0 00-2.69 1.66L5.38 4H0v20h28V4h-5.38zM8.34 2.55c.175-.336.521-.548.9-.55h9.52c.38-.005.73.21.9.55L20.38 4H7.62l.72-1.45zM14 19a5 5 0 110-10 5 5 0 010 10zm10-9h-4V8h4v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCameraF
