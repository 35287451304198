import * as React from 'react'

function SvgRouter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 28" {...props}>
      <path
        d="M11 0a3.003 3.003 0 013 3v21a2.995 2.995 0 01-2 2.816V28h-2v-1H4v1H2v-1.184A2.995 2.995 0 010 24V3a3.003 3.003 0 013-3zm1 23H2v1a1 1 0 001 1h8a1 1 0 001-1v-1zM11 2H3a1 1 0 00-1 1v18h10V3a1 1 0 00-1-1zm-3 8v3H6v-3h2zm0-5v3H6V5h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRouter
