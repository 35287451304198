import { ItemProps } from 'components/shared/Sidebar/Item'
import { orderBy } from 'lodash'
export interface WikiPageProps extends ItemProps {
  title: string
  id: string
  homePage: string
  wikiId: number | string
  wikiPath: string
  group?: string
  render?: string
  link?: string
  endpoint?: string
  secureSection?: string
  hasBottomDivider?: boolean
}

export const docsWikisNotOrdered: WikiPageProps[] = [
  {
    title: 'Prism',
    id: 'prismdocs',
    homePage: 'home',
    wikiId: 7704,
    group: 'client-analytics',
    wikiPath: 'prism',
    link: '/documentation/prismdocs',
    hasBottomDivider: true,
  },
]

const docsWikisOrdered: WikiPageProps[] = orderBy(
  [
    {
      title: 'Quantum Documentation',
      id: 'quantum',
      homePage: 'Quantum/home',
      wikiId: 1657,
      group: 'product-intelligence',
      wikiPath: 'venona-specs-tools',
      link: '/documentation/quantum',
      secureSection: 'restricted',
    },
    {
      title: 'Quantum Libraries',
      id: 'helix',
      homePage: 'helixJS/home',
      wikiId: 1657,
      group: 'product-intelligence',
      wikiPath: 'venona-specs-tools',
      link: '/documentation/helix',
    },
    {
      title: 'Quantum Training',
      id: 'quantumTraining',
      homePage: 'quantumTraining/home',
      wikiId: 1657,
      group: 'product-intelligence',
      wikiPath: 'venona-specs-tools',
      link: '/documentation/quantumTraining',
    },

    {
      title: 'Morph',
      id: 'morph',
      homePage: 'home',
      wikiId: 8275,
      group: 'client-analytics',
      wikiPath: 'morph',
      link: '/documentation/morph',
    },
    {
      title: 'Qube',
      id: 'qube',
      homePage: 'home',
      wikiId: 6632,
      group: 'product-intelligence',
      wikiPath: 'quantum-for-backend-specs',
      link: '/documentation/qube',
    },
    {
      title: 'Data Engineering',
      id: 'data-platform-documentation',
      homePage: 'home',
      wikiId: 15559,
      group: 'product-intelligence',
      wikiPath: 'data-platform-documentation',
      link: '/documentation/data-platform-documentation',
      render: 'plain',
      secureSection: 'internal',
    },
    {
      title: 'Tools & Testing',
      id: 'tools',
      homePage: 'home',
      wikiId: 'client-analytics',
      group: 'groups',
      wikiPath: 'client-analytics',
      link: '/documentation/prism',
      endpoint: 'readGroupWiki',
    },

    {
      title: 'Metrics UI',
      id: 'metrics',
      homePage: 'metricsUI',
      wikiId: 7704,
      group: 'client-analytics',
      wikiPath: 'prism',
      //link: '/documentation/metrics/metricsUI', // holding this link information
      link: '/documentation/metrics',
    },

    {
      title: 'Airlytics',
      id: 'airlytics',
      homePage: 'home',
      wikiId: 5076,
      group: 'product-intelligence',
      wikiPath: 'airlytics',
      link: '/documentation/airlytics',
      secureSection: 'internal',
    },
    {
      title: 'Data Science',
      id: 'data-platform-documentation',
      homePage: 'Data-Science-Jobs',
      wikiId: 15559,
      group: 'product-intelligence',
      wikiPath: 'data-platform-documentation',
      //link: '/documentation/dataScience/home', // holding this information
      link: '/documentation/data-platform-documentation',
      render: 'plain',
      secureSection: 'internal',
    },
    {
      title: 'Connectivity Insights',
      id: 'connectivity-insights',
      homePage: 'Connectivity-Insights',
      wikiId: 15559,
      group: 'product-intelligence',
      wikiPath: 'connectivity-insights',
      link: '/documentation/connectivity-insights',
      render: 'plain',
      secureSection: 'internal',
    },
    {
      title: 'IP Video',
      id: 'IP-Video',
      homePage: 'IP-Video',
      wikiId: 15559,
      group: 'product-intelligence',
      wikiPath: 'IP-Video',
      link: '/documentation/IP-Video',
      render: 'plain',
      secureSection: 'internal',
    },
    {
      title: 'Wireless Insights',
      id: 'wireless-insights',
      homePage: 'Wireless-Insights',
      wikiId: 15559,
      group: 'product-intelligence',
      wikiPath: 'Wireless-Insights',
      link: '/documentation/wireless-insights',
      render: 'plain',
      secureSection: 'internal',
    },
    {
      title: 'Identity',
      id: 'Identity',
      homePage: 'Identity',
      wikiId: 15559,
      group: 'product-intelligence',
      wikiPath: 'Identity',
      link: '/documentation/Identity',
      render: 'plain',
      secureSection: 'internal',
    },
  ],
  ['title'],
  ['asc']
)

// wiki page objects that are not listed in the docs dropdown in the header
export const unlistedDocsWikis: WikiPageProps[] = [
  {
    title: 'ML Best Practices',
    id: 'Machine-Learning-Best-Practices',
    homePage: 'Machine-Learning-Best-Practices',
    wikiId: 7704,
    group: 'client-analytics',
    wikiPath: 'prism',
    link: '/documentation/ML-Best-Practices',
    render: 'plain',
    secureSection: 'internal',
  },
]

export const docsWikis: WikiPageProps[] = docsWikisNotOrdered.concat(docsWikisOrdered)
export const allWikis: WikiPageProps[] = docsWikis.concat(unlistedDocsWikis)
