import * as React from 'react'

function SvgTvF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M26 0H2a2 2 0 00-2 2v17a2 2 0 002 2h2l-1 3h2l1-3h16l1 3h2l-1-3h2a2 2 0 002-2V2a2 2 0 00-2-2zm0 19H2V2h24v17zM4 4h20v13H4V4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTvF
