import * as React from 'react'

function SvgEquipmentF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M28 6v18H17V6h11zM24 .004v4h-2v-2H4v13h11v2H2v.91c0 .602.488 1.089 1.09 1.09H15v2H3.09A3.093 3.093 0 010 17.914v-2.91h2v-15h22z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgEquipmentF
