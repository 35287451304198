import React, { useState, useEffect, useRef } from 'react'
import Typography from '../typography/Typography'
import { colors } from 'components/shared/utils/colors'
import ChartsHeading from 'components/shared/chart/ChartsHeading'
import { getFullElementHeight } from 'utils'
import * as S from './styles'

function NoDataChartMessage({
  title,
  dataSource,
  tabButtonChart,
  tabButtonProps,
  ActionsMenu,
  underConstructionText,
  underConstructionExpectedDate,
  underConstruction,
  customMessageText = 'No Data Found',
  isExtendContentActive,
  showMetricOutdatedWarning,
  renderTabButtons,
}: {
  title?: string
  dataSource?: string | boolean
  tabButtonChart?: boolean
  tabButtonProps?: {
    mainTitle?: string
    description?: string
    activeTab: number
    tabTitles: string[]
  }
  underConstruction?: boolean
  underConstructionExpectedDate?: string
  underConstructionText?: React.ReactElement | string
  customMessageText?: React.ReactElement | string
  ActionsMenu?: React.ReactElement
  isExtendContentActive?: boolean
  showMetricOutdatedWarning?: boolean
  renderTabButtons?: Function
}) {
  const [noDataHeight, setNoDataHeight] = useState(0)
  const chartContainerRef = useRef(null)
  const noDataContainerRef = useRef(null)

  // If chart container's height set to fit-content, need to compensate left over space in cases content can't fill the chart container
  useEffect(() => {
    if (isExtendContentActive) {
      const fullNoChartDataHeight = getFullElementHeight(chartContainerRef, noDataContainerRef)
      setNoDataHeight(fullNoChartDataHeight)
    }
  }, [isExtendContentActive])

  if (tabButtonChart) {
    const multiChartHeaderWithTitleAndDescription = (
      <>
        <S.ChartDescriptionContainer>
          <ChartsHeading
            title={tabButtonProps?.mainTitle || ''}
            description={tabButtonProps?.description || ''}
            data-pho={tabButtonProps?.mainTitle || ''}
            actionMenu={ActionsMenu}
          />
        </S.ChartDescriptionContainer>
        {renderTabButtons && renderTabButtons()}
      </>
    )
    const multiChartHeaderWithoutTitleAndDescription = (
      <>
        {renderTabButtons && renderTabButtons()}
        {ActionsMenu}
      </>
    )
    const multiChartHeader =
      tabButtonProps?.mainTitle || tabButtonProps?.description
        ? multiChartHeaderWithTitleAndDescription
        : multiChartHeaderWithoutTitleAndDescription
    return (
      <S.Container>
        <S.TabbedChartTopContainer>{multiChartHeader}</S.TabbedChartTopContainer>
        <S.BottomContainer>
          <S.StyledNoDataMessage>
            {underConstruction ? (
              <S.UnderConstructionContainer>
                <S.UnderConstructionTitle>Under construction</S.UnderConstructionTitle>
                {underConstructionText && <>{underConstructionText}</>}
                {underConstructionExpectedDate && (
                  <S.UnderConstructionExpectedBy>
                    Expected to be back by <strong>{underConstructionExpectedDate}</strong>
                  </S.UnderConstructionExpectedBy>
                )}
              </S.UnderConstructionContainer>
            ) : (
              <Typography color={colors.gray30}>
                <p>{customMessageText}</p>
              </Typography>
            )}
          </S.StyledNoDataMessage>
        </S.BottomContainer>
        {dataSource && (
          <Typography noMargin scale="small" color={colors.gray30}>
            <p style={{ fontSize: '14px' }}>
              Data Source
              {`: ${dataSource}`}
            </p>
          </Typography>
        )}
      </S.Container>
    )
  }
  return (
    <S.Container ref={chartContainerRef}>
      <S.TopContainer>
        <ChartsHeading title={title} actionMenu={ActionsMenu}></ChartsHeading>
      </S.TopContainer>
      <S.BottomContainer ref={noDataContainerRef} height={noDataHeight}>
        <S.StyledNoDataMessage>
          {underConstruction && (
            <S.UnderConstructionContainer>
              <S.UnderConstructionTitle>Under construction</S.UnderConstructionTitle>
              {underConstructionText && <>{underConstructionText}</>}
              {underConstructionExpectedDate && (
                <S.UnderConstructionExpectedBy>
                  Expected to be back by <strong>{underConstructionExpectedDate}</strong>
                </S.UnderConstructionExpectedBy>
              )}
            </S.UnderConstructionContainer>
          )}
          {showMetricOutdatedWarning && (
            <Typography color={colors.gray30}>
              <p>This metric is no longer valid.</p>
            </Typography>
          )}
          {!underConstruction && !showMetricOutdatedWarning && (
            <Typography variant="body" color={colors.gray30}>
              {customMessageText}
            </Typography>
          )}
        </S.StyledNoDataMessage>
      </S.BottomContainer>
    </S.Container>
  )
}
export default NoDataChartMessage
