import * as React from 'react'

function SvgTruck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 12h-1.31l-1.5-4-.77-2.05A2.972 2.972 0 0019 4.03V2h3V0H0v2h3v2a3.009 3.009 0 00-3 3v12a3.009 3.009 0 003 3h1.35a3.48 3.48 0 006.3 0h6.7a3.48 3.48 0 006.3 0H28v-7a3.009 3.009 0 00-3-3zm-4.19-2l.75 2H16v-2h4.81zM12 2h5v2h-5V2zM5 2h5v2H5V2zm2.5 20A1.498 1.498 0 016 20.5c-.001-.17.03-.34.09-.5a1.494 1.494 0 012.82 0c.06.16.091.33.09.5A1.498 1.498 0 017.5 22zM19 20.5c-.001-.17.03-.34.09-.5a1.494 1.494 0 012.82 0c.06.16.091.33.09.5a1.5 1.5 0 01-3 0zm7-.5h-2.04a3.495 3.495 0 00-6.92 0h-6.08a3.495 3.495 0 00-6.92 0H3a1.003 1.003 0 01-1-1V7a1.003 1.003 0 011-1h15.61c.418 0 .792.26.94.65L20.06 8H14v6h11c.552.002.998.448 1 1v5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTruck
