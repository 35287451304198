import * as React from 'react'

function SvgCancel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14C27.991 6.272 21.728.009 14 0zm12 14a11.94 11.94 0 01-2.845 7.74L6.259 4.846A11.987 11.987 0 0126 14zM2 14a11.94 11.94 0 012.845-7.74l16.896 16.895A11.987 11.987 0 012 14z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCancel
