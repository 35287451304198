import React from 'react'
import { Button } from 'components/shared/Button'
import Flex from 'components/shared/flex/Flex'

export const AddNewTabButton = ({
  isDisabled = false,
  handleAddNewTab,
  addTabButtonRef,
  dataTest = 'addTabButton',
  tabShouldNotBeRendered,
}: {
  isDisabled?: boolean
  handleAddNewTab: Function
  addTabButtonRef?: React.RefObject<HTMLDivElement>
  dataTest?: string
  tabShouldNotBeRendered?: boolean
}) => {
  return (
    <Flex alignItems="center" margin={`${tabShouldNotBeRendered ? 0 : '0 0 0 0.5rem'}`}>
      <div ref={addTabButtonRef}>
        <Button
          icon="Plus"
          variant="borderless"
          disabled={isDisabled}
          onClick={() => handleAddNewTab()}
          data-test={dataTest}
        >
          Add a Tab
        </Button>
      </div>
    </Flex>
  )
}
