import * as React from 'react'

function SvgLinkout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M10 0v2H3a1 1 0 00-1 1v21h21a1 1 0 001-1v-7h2v7a3 3 0 01-3 3H0V3a3 3 0 013-3h7zm16 .004v12h-2v-8.59L13.708 13.707l-1.415-1.415L22.582 2.004H14v-2h12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLinkout
