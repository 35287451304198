import * as React from 'react'

function SvgSpectrumSansF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 26" {...props}>
      <path
        d="M17.6 0h-7.2L0 26h8l1.8-5h8.4l1.8 5h8L17.6 0zM11 15l3-7.8 3 7.8h-6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSpectrumSansF
