import { createGlobalStyle } from 'styled-components'
import { colors } from 'components/shared/utils/colors'
import { typography } from 'components/shared/typography'

export const ThemeGlobalStyle = createGlobalStyle`
  body {
    min-width: 320px;
    background-color: ${({ theme }) =>
      (theme as any)?.isDarkMode ? colors.darkMode.background : colors.lightMode.background};
  }

  ${typography}
`
