import { Card } from 'components/shared/Card'
import Flex from 'components/shared/flex/Flex'
import Typography from 'components/shared/typography/Typography'
import * as S from './styles'
import { IconButton } from 'components/shared/IconButton'
import { Dispatch, SetStateAction } from 'react'

const DevBanner = ({ setShowBanner }: { setShowBanner: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <Card
      maxHeight="3rem"
      minHeight="3rem"
      data-pho="topbar"
      elevation="3"
      disableShadow
      disablePadding
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex flex="1" alignItems="center" justifyContent="center" gap="2rem" padding=".5rem 0">
          <Typography>Currently in Development</Typography>
          <a href="https://prism.spectruminternal.com">
            <S.ProdButton>Production</S.ProdButton>
          </a>
        </Flex>
        <Flex alignItems="flex-end">
          <IconButton icon="X" size="xs" color="black" onClick={() => setShowBanner(false)} />
        </Flex>
      </Flex>
    </Card>
  )
}

export default DevBanner
