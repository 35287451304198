import * as React from 'react'

function SvgSquareCheckboxF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M24 0a2 2 0 012 2v22a2 2 0 01-2 2H0V2a2 2 0 012-2h22zm-5.707 8.293L11 15.586l-3.293-3.293-1.414 1.414L11 18.414l8.707-8.707-1.414-1.414z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSquareCheckboxF
