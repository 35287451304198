export function findClosestNumber(stepPosition: number, sliderMarks: number[]) {
  return sliderMarks.reduce((a, b) => {
    const aDiff = Math.abs(a - stepPosition)
    const bDiff = Math.abs(b - stepPosition)

    if (aDiff == bDiff) {
      return a > b ? a : b
    } else {
      return bDiff < aDiff ? b : a
    }
  })
}
