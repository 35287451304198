import * as React from 'react'

function SvgFaceId(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 17v6a3.003 3.003 0 01-3 3h-6v-2h6c.552 0 1-.448 1-1v-6h2zM2 17v7h7v2H0v-9h2zm14.53.536l1.42 1.408A6.92 6.92 0 0113 21a6.92 6.92 0 01-4.95-2.056l1.42-1.408a4.988 4.988 0 007.06 0zM14 8v8h-4v-2h2V8h2zM8 8v4H6V8h2zm12 0v4h-2V8h2zM9 0v2H3c-.552 0-1 .448-1 1v6H0V3a3.003 3.003 0 013-3h6zm14 0a3.003 3.003 0 013 3v6h-2V3c0-.552-.448-1-1-1h-6V0h6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFaceId
