import React, { useEffect, useState } from 'react'
import isArray from 'lodash/isArray'
import first from 'lodash/first'
import { useLazyQuery, useQuery } from '@apollo/client'
import ErrorMessage from 'components/shared/ErrorMessage'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import MuiTableRow from '@material-ui/core/TableRow'
import queryString from 'query-string'
import TableRow from './TableRow'
import useFilterValues from 'hooks/useFilterValues'
import useAuth from 'hooks/useAuth'
import useVocState from 'hooks/useVocState'
import { getVocClient } from 'components/voiceOfCustomer/utils'
import { getPathValue } from 'components/shared/FilterMenu/VoCActivationMethodPathFilter'
import DownloadCSV from '../DownloadCSV'
import TableSettings from './TableSettings'
import SortLabel from './SortLabel'
import TableFilters from './TableFilters'
import useApps from 'hooks/useApps'
import {
  allColumnHeaders,
  reviewsView,
  detailsView,
  epepView,
  reviewsViewColumnHeaderLabels,
  detailsViewColumnHeaderLabels,
  epepViewColumnHeaderLabels,
  searchByFields,
} from '../constants'
import * as T from '../types'
import {
  getMatchingGroupFromId,
  getCurrentOrder,
  getSortedColumnHeaders,
  getActiveColumnHeaders,
} from '../utils'
import { queries, types } from '..'
import * as S from '../styles'
import { Tooltip } from '@material-ui/core'
import Flex from 'components/shared/flex/Flex'
import { Button } from 'components/shared/Button'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'

const INITIAL_ROWS_PER_PAGE_LIMIT = 10
const allColumnHeaderIDs = allColumnHeaders.map(header => header.id)

export default function FeedbackTable({
  category,
  manualCategory,
  isReviewed,
  isUserSelectedCategory,
  source,
  isSubcategoryDetailsPage,
  detailsTable,
  permissionId,
  savedGroupFavoriteColumnsIds,
  columnGroups,
  savedViewId,
  refetchGetUser,
  loading: tableLoading,
}: T.FeedbackTableProps) {
  const { currentDomains } = useApps()
  const setReviewHeaders = () => {
    const isEpepDomain = currentDomains?.includes('employee_experience')
    if (isEpepDomain) {
      return epepViewColumnHeaderLabels
    }
    return (
      savedGroupFavoriteColumnsIds ||
      (detailsTable ? detailsViewColumnHeaderLabels : reviewsViewColumnHeaderLabels)
    )
  }
  const setActiveCurrentView = () => {
    const isEpepDomain = currentDomains?.includes('employee_experience')
    if (isEpepDomain) {
      return epepView.value
    }
    return savedViewId || (detailsTable ? detailsView.value : reviewsView.value)
  }
  const [open, setOpen] = useState<boolean>(false)
  const [activeColumnHeaders, setActiveColumnHeaders] = useState(setReviewHeaders)
  const [sortField, setSortField] = useState('timestamp')
  const [sortDirection, setSortDirection] = useState('desc')
  const [page, setPage] = useState(0)
  const [searchBy, setSearchBy] = useState(searchByFields[0])
  const [textToSearch, setTextToSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(INITIAL_ROWS_PER_PAGE_LIMIT)
  const [includeNullVerbatims, setIncludeNullVerbatims] = useState(false)
  const [decryptedAccountNumber, setDecryptedAccountNumber] = useState('')
  const [csvExportInProgress, setCSVExportInProgress] = useState(false)
  const [csvExportRecordID, setCSVExportRecordID] = useState('')
  const [csvExportURL, setCSVExportURL] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [columnGroupFavoriteID, setColumnGroupFavoriteID] = useState(savedViewId)
  const [currentViewId, setCurrentView] = useState(setActiveCurrentView)

  const currentSavedView = getMatchingGroupFromId(columnGroups, currentViewId)
  const currentOrder = currentSavedView ? getCurrentOrder(currentSavedView) : null

  const auth = useAuth()
  const userID = auth.user?.id || ''
  const hasTransformerDecryptAccess = auth.user?.roles.includes('transformer-decrypt') ?? false
  const searchText = textToSearch === '' ? {} : { searchText: textToSearch }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const { state } = useVocState()
  const { applicationIDs, categoryOptions, middleCategoryOptions } = state
  const filterValues = useFilterValues()
  const {
    period,
    showPII,
    environment,
    appVersion,
    surveyID,
    CSAT,
    starRating,
    starRatingExternal,
    SR,
    awsSentimentPositive,
    awsSentimentNegative,
    billerType,
    billingServices,
    userRole,
    accountExpired,
    autoPayStatus,
    loggedInStatus,
    footprint,
    SCP,
    pageName,
    subscribedServices,
    userSelectedCategories,
    accountType,
    userType,
    isMobileUser,
    journeyType,
    vocFeedbackID,
    activationMethod,
    activatedExperimentUuids,
    experimentUuids,
    variantUuids,
    middleCategory,
    verbatimLanguage,
  } = filterValues
  const _id = vocFeedbackID ? { _id: vocFeedbackID } : {}
  const showPIIFilterVal = isArray(showPII) ? first(showPII) : showPII
  const showPIIFilter = showPIIFilterVal ? { showPII: true } : {}
  const activationMethodValues = getPathValue(activationMethod)

  const defaultFilters = {
    ..._id,
    appVersion,
    surveyID,
    CSAT,
    starRating,
    starRatingExternal,
    SR,
    awsSentimentPositive,
    awsSentimentNegative,
    billerType,
    billingServices,
    userRole,
    accountExpired,
    autoPayStatus,
    loggedInStatus,
    footprint,
    SCP,
    pageName,
    subscribedServices,
    userSelectedCategories,
    accountType,
    userType,
    isMobileUser,
    journeyType,
    activationMethod: activationMethodValues,
    activatedExperimentUuids,
    experimentUuids,
    variantUuids,
    middleCategory,
    language: verbatimLanguage,
  }
  const client = getVocClient(environment as string)

  useEffect(() => {
    page !== 0 && setPage(0)
  }, [filterValues?.filters])

  const categoryFilter = category ? { category } : {}
  const manualCategoryFilter = manualCategory ? { manualCategory } : {}
  const sourceQuery = source ? { source } : {}

  const columnHeaders = allColumnHeaders.filter(columnHeader => {
    const matchingHeader = activeColumnHeaders.find(
      headerLabel => headerLabel === columnHeader.label
    )

    return !!matchingHeader
  })

  const sortedColumnHeaders = getSortedColumnHeaders(currentOrder, columnHeaders)
  const fileUploadPresent = columnHeaders.some(header => header.id === 'fileUploads')

  const activeColumnHeaderIDs =
    sortedColumnHeaders?.map((columnHeader: T.DefaultColumnHeader) => columnHeader.id) ?? []
  const { loading, error, data } = useQuery(queries.GET_FEEDBACK, {
    client,
    variables: {
      ...defaultFilters,
      ...showPIIFilter,
      applicationIDs,
      period,
      ...(isSubcategoryDetailsPage ? {} : categoryFilter),
      ...manualCategoryFilter,
      searchBy: searchBy.value,
      ...searchText,
      page,
      limit: rowsPerPage,
      sortField,
      sortDirection,
      ...isReviewed,
      isUserSelectedCategory,
      ...sourceQuery,
      includeNullVerbatims,
      feedbackFields: [], // always include all fields
    },
    fetchPolicy: 'no-cache',
  })

  const feedbackCountQuery = useQuery(queries.GET_FEEDBACK_COUNT, {
    client,
    variables: {
      ...defaultFilters,
      applicationIDs,
      period,
      ...(isSubcategoryDetailsPage ? {} : categoryFilter),
      manualCategory,
      searchBy: searchBy.value,
      ...searchText,
      ...isReviewed,
      isUserSelectedCategory,
      ...sourceQuery,
      includeNullVerbatims,
    },
    fetchPolicy: 'no-cache',
  })

  const [feedback, setFeedback] = useState(data?.getFeedback)
  const [fileUploadIds, setFileUploadIds] = useState<string[]>()
  const countData = feedbackCountQuery?.data?.getFeedbackCount

  useEffect(() => {
    if(data?.getFeedback) {
      fileUploadIds && setFileUploadIds(undefined)
      setFeedback(data.getFeedback)
    }
  }, [data])

  useEffect(() => {
    if (!fileUploadIds?.length && fileUploadPresent) {
      const ids = feedback?.reduce((idArray: string[], currentFeedback: any) => {
        if (currentFeedback.fileUploadID) return [...idArray, currentFeedback.fileUploadID]
        return idArray
      }, [])
      setFileUploadIds(ids)
    }
  }, [feedback, fileUploadPresent])

  const [
    getFileUrls,
    { data: fileUploadData, error: fileUploadError, loading: fileUploadLoading },
  ] = useLazyQuery(queries.GET_FILE_UPLOADS, {
    client,
    variables: {
      _ids: fileUploadIds,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (fileUploadIds?.length) {
      getFileUrls()
    }
  }, [fileUploadIds])

  useEffect(() => {
    if (fileUploadData?.getFileUploads) {
      setFeedback((prevFeedback: any) => {
        const result = prevFeedback.map((feedbackItem: any) => {
          const fileUploadAdd = fileUploadData.getFileUploads.find(
            upload => upload._id === feedbackItem?.fileUploadID
          )
          if (fileUploadAdd) {
            feedbackItem.fileUploads = fileUploadAdd.files.map((file: string) => file.url)
          }
          return feedbackItem
        })
        return [...result]
      })
    }
  }, [fileUploadData])

  if (error) {
    return (
      <ErrorMessage errorType="api" errorMessage="Error fetching Voice of Customer feedback." />
    )
  }

  function handleSortChange(newSortField: string) {
    if (newSortField === sortField) {
      if (sortDirection === 'asc') setSortDirection('desc')
      else if (sortDirection === 'desc') setSortDirection('asc')
    } else {
      setSortField(newSortField)
      setSortDirection('desc')
    }
  }

  function handleViewChange(newViewId: string, updatedColumnLabels?: string[]) {
    const activeColumnHeaders =
      updatedColumnLabels || getActiveColumnHeaders(newViewId, columnGroups)

    setActiveColumnHeaders(activeColumnHeaders)
    setCurrentView(newViewId)
  }

  function handleSetTextToSearch(textToSearch: string) {
    setPage(0)
    setTextToSearch(textToSearch)
  }

  const key = queryString.stringify(filterValues)

  const calculateColumnWidths = (headers: any) => {
    return headers?.reduce((acc: any, header: any) => {
      acc[header.id] = header?.minWidth || 150;
      return acc;
    }, {});
  };

  const [columnWidths, setColumnWidths] = useState(calculateColumnWidths(allColumnHeaders));

  useEffect(() => {
    setColumnWidths(calculateColumnWidths(allColumnHeaders));
  }, [activeColumnHeaders]);

  return (
    <S.TableContainer disablePadding style={{ width: '100%', overflow: 'hidden' }}>
      <S.TableActions>
        <TableFilters
          handleSetTextToSearch={handleSetTextToSearch}
          textToSearch={textToSearch}
          searchBy={searchBy}
          searchByFields={searchByFields}
          sortField={sortField}
          includeNullVerbatims={includeNullVerbatims}
          setSearchBy={setSearchBy}
          setSortField={setSortField}
          setSortDirection={setSortDirection}
          setIncludeNullVerbatims={setIncludeNullVerbatims}
        />
        <Flex center margin="0 0.75rem">
          <TableSettings
            columnGroups={columnGroups}
            permissionId={permissionId}
            columnGroupFavoriteID={columnGroupFavoriteID}
            selected={currentViewId}
            setColumnGroupFavoriteID={setColumnGroupFavoriteID}
            detailsTable={detailsTable}
            refetchGetUser={refetchGetUser}
            handleViewChange={handleViewChange}
          />
          <>
            <Tooltip title={'Export as CSV'} placement="bottom">
              <Button
                onClick={() => setOpen(true)}
                disabled={countData === 0}
                data-pho="downloadCSVButton"
                icon={countData === 0 ? 'CautionAlert' : 'Download'}
                variant="borderless"
              >
                Export as CSV
              </Button>
            </Tooltip>
          </>
          <DownloadCSV
            // This key fixes issue when CSV downloads on each filter change
            key={`downloadCSV_${key}`}
            open={open}
            setOpen={open => setOpen(open)}
            userID={userID}
            client={client}
            category={categoryFilter as string[]}
            manualCategory={manualCategory}
            searchBy={searchBy.value}
            searchText={searchText}
            isReviewed={isReviewed}
            isUserSelectedCategory={isUserSelectedCategory}
            source={source}
            count={countData}
            includeNullVerbatims={includeNullVerbatims}
            allColumnHeaders={allColumnHeaderIDs}
            activeColumnHeaders={activeColumnHeaderIDs}
            csvExportInProgress={csvExportInProgress}
            setCSVExportInProgress={setCSVExportInProgress}
            csvExportRecordID={csvExportRecordID}
            setCSVExportRecordID={setCSVExportRecordID}
            csvExportURL={csvExportURL}
            setCSVExportURL={setCSVExportURL}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            {...showPIIFilter}
            {...defaultFilters}
          />
        </Flex>
      </S.TableActions>
      <S.ReviewTableContainer>
        {tableLoading || loading || fileUploadLoading ? (
          <S.TableLoadingSpinnerContainer>
            <LoadingSpinner />
          </S.TableLoadingSpinnerContainer>
        ) : (
          <Table
            stickyHeader
            aria-label="custom pagination table sticky"
            data-pho="feedbackTable"
            style={{ width: '100%' }}
          >
            <TableHead>
              <MuiTableRow>
                {sortedColumnHeaders.map((header: T.DefaultColumnHeader) => (
                  <SortLabel
                    sortField={sortField}
                    header={header}
                    sortDirection={sortDirection}
                    handleSortChange={handleSortChange}
                    columnWidths={columnWidths}
                    setColumnWidths={setColumnWidths}
                  />
                ))}
              </MuiTableRow>
            </TableHead>
            <TableBody>
              {feedback &&
                !loading &&
                !fileUploadLoading &&
                feedback.map((row: types.FeedbackModel) => (
                  <TableRow
                    key={row._id}
                    row={row}
                    columnHeaders={sortedColumnHeaders}
                    columnWidths={columnWidths}
                    decryptedAccountNumber={decryptedAccountNumber}
                    setDecryptedAccountNumber={setDecryptedAccountNumber}
                    hasTransformerDecryptAccess={hasTransformerDecryptAccess}
                    categories={categoryOptions.concat(middleCategoryOptions)}
                  />
                ))}
            </TableBody>
          </Table>
        )}
      </S.ReviewTableContainer>
      {feedbackCountQuery?.data && (
        <S.StyledTablePagination
          rowsPerPageOptions={[10, 25, 100]}
          count={countData}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          data-pho="feedbackTablePagination"
          labelRowsPerPage={<div data-pho="paginationLabel">Rows per page:</div>}
          SelectProps={{ 'data-pho': 'rowsPerPageSelect' } as Partial<any>}
        />
      )}
    </S.TableContainer>
  )
}
