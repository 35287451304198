import * as React from 'react'

function SvgXAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M23.414 3.414L20.586.586 12 9.172 3.414.586.586 3.414 9.172 12 .586 20.586l2.828 2.828L12 14.828l8.586 8.586 2.828-2.828L14.828 12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgXAlt
