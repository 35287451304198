import * as React from 'react'

function SvgArrowUpRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M1.707 25.707L.293 24.293 22.586 2H7V0h19v19h-2V3.414z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgArrowUpRight
