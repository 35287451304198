import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import Alert from 'components/shared/Alert'
import { TextInput } from 'components/shared/TextInput'
import { Button } from 'components/shared/Button'
import { Method } from 'axios'
import useApps from 'hooks/useApps'
import useGroups from 'hooks/useGroups'
import { axios, axiosConfigBase } from 'hooks/useApi'
import { Group } from 'components/App/Providers/Data/Groups'
import services from 'common/services'
import Autocomplete from 'components/shared/Autocomplete'
import { rootApiUrl } from 'common/constants'
import * as S from './styles'
import { debug } from 'common/services'

function EditGroup({ group, handleCloseModal }: { group?: Group; handleCloseModal: () => void }) {
  const [groupName, setGroupName] = useState(group?.name)
  const [groupDescription, setGroupDescription] = useState(group?.description)
  const [selectedAppList, setSelectedAppList] = useState(group?.applicationIDs || [])
  const [isLoading, setIsLoading] = useState(false)
  const { appsByDomain } = useApps()
  const { groups, fetchNewData } = useGroups()
  const [applicationsDomain, setApplicationsDomain] = useState('')
  const [apps, setApps] = useState<string[]>([])
  const [alertMessage, setAlertMessage] = useState('')

  React.useEffect(() => {
    if (appsByDomain.video.includes(selectedAppList?.[0])) {
      setApps(appsByDomain.video)
      setApplicationsDomain('Video')
    } else if (appsByDomain.internal_apps.includes(selectedAppList?.[0])) {
      setApps(appsByDomain.internal_apps)
      setApplicationsDomain('Internal')
    } else {
      setApps(appsByDomain.portals)
      setApplicationsDomain('SSPP')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function updateGroup() {
    if (isEmpty(groupName) || isEmpty(groupDescription)) {
      setAlertMessage('Please fill out all fields')
    } else if (selectedAppList?.length <= 1) {
      setAlertMessage('Please choose at least 2 applications.')
    } else {
      setAlertMessage('')
      setIsLoading(true)
      const axiosConfig = {
        ...axiosConfigBase(),
        method: 'PATCH' as Method,
        url: `${rootApiUrl}${services.groups.url}/${group?._id}`,
        data: {
          applicationIDs: selectedAppList,
          name: groupName,
          description: groupDescription,
        },
      }
      const updatedGroups = groups?.map((group: Group) => {
        if (group._id === group?._id) {
          return {
            applicationIDs: selectedAppList,
            name: groupName,
            description: groupDescription,
            _id: group?._id,
          }
        }
        return group
      })

      await axios({ ...axiosConfig })
      fetchNewData(updatedGroups)
      handleCloseModal()
    }
  }
  function handleGroupNameChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
    setGroupName(value)
  }

  function handleGroupDescriptionChange({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setGroupDescription(value)
  }

  function handleApplicationsListChange(value: any) {
    setSelectedAppList(value)
  }

  function handleApplicationTypeChange(value: any) {
    setApplicationsDomain(value)
    setSelectedAppList([])
  }

  return (
    <S.GroupContainer>
      {alertMessage && (
        <S.StyledAlertContainer>
          <Alert children={alertMessage} variant="page-error" isPersistent />
        </S.StyledAlertContainer>
      )}
      <S.ModalTitle variant="title6">Edit Group</S.ModalTitle>
      <TextInput
        width="100%"
        label="Group Name"
        hint="Edit Group name"
        type="text"
        value={groupName}
        onChange={handleGroupNameChange}
      />
      <TextInput
        width="100%"
        label="Group Description"
        hint="Edit Group description"
        type="text"
        value={groupDescription}
        onChange={handleGroupDescriptionChange}
      />
      <S.StyledAutocompleteContainer>
        <Autocomplete
          options={['SSPP', 'Video', 'Internal']}
          onChange={handleApplicationTypeChange}
          placeholder="Choose Applications domain"
          canSelectMultipleValues={false}
          disableCloseOnSelect={false}
          defaultValue={applicationsDomain}
          key={`chooseApplicationDomain${applicationsDomain}`}
          fullWidth={true}
          fullHeight={true}
        ></Autocomplete>
      </S.StyledAutocompleteContainer>
      <S.StyledAutocompleteContainer>
        <Autocomplete
          options={applicationsDomain ? apps : []}
          onChange={handleApplicationsListChange}
          placeholder="Add Applications"
          value={selectedAppList}
          fullWidth={true}
          fullHeight={true}
        ></Autocomplete>
      </S.StyledAutocompleteContainer>
      <S.CreateNewGroupButtonContainer>
        <Button onClick={updateGroup} loading={isLoading}>
          Save
        </Button>
      </S.CreateNewGroupButtonContainer>
    </S.GroupContainer>
  )
}

export default EditGroup
