import React from 'react'
import isEmpty from 'lodash/isEmpty'
import BarChart from 'components/shared/barChart'
import TopChart from 'components/shared/topChart'
import { FormattedTopData, FormattedBarData } from 'components/shared/ChartData/types'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import * as S from './styled'

export default function BarWithTopChart({
  data,
  mini,
  title,
  ...props
}: {
  data: {
    barData: FormattedBarData
    topData: {
      topData: FormattedTopData
      aggrData?: {
        avg: number
        total: number
      }
    }
  }
  mini?: boolean
  title?: string
}) {
  if (mini) {
    return (
      <S.MiniContainer>
        <div>
          <h4>{title}</h4>
        </div>
      </S.MiniContainer>
    )
  }
  if (isEmpty(data)) {
    return <NoDataChartMessage title={title} />
  }

  const isTopChartWide = Object.keys(data.topData.topData[0] || []).length > 2

  return (
    <S.Container>
      <S.TopChartContainer isTopChartWide={isTopChartWide}>
        <TopChart data={data.topData} showTitle={false} {...props} />
      </S.TopChartContainer>
      <S.BarChartContainer isTopChartWide={isTopChartWide}>
        <BarChart data={data.barData} {...props} />
      </S.BarChartContainer>
    </S.Container>
  )
}
