import * as React from 'react'

function SvgOnDemandF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 24" {...props}>
      <path
        d="M24 0v24h2V0h-2zm-4 13v11h2V0h-2v13zM15 0H3a3.009 3.009 0 00-3 3v21h15a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgOnDemandF
