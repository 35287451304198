import { Point } from '@nivo/line'
import { Card } from 'components/shared/Card'
import Typography from '../typography/Typography'
import queryString from 'query-string'
import get from 'lodash/get'
import moment from 'moment'
import numeral from 'numeral'
import orderBy from 'lodash/orderBy'
import * as S from './styled'

export default function LineChartTooltip({
  tooltipRef,
  slice,
  step,
  numericalStep,
  variantNames,
  query,
  tooltipPointTitle,
  tooltipFormat,
}: {
  tooltipRef: React.MutableRefObject<null>
  slice: any
  step: string
  numericalStep: boolean
  variantNames: Record<string, string>
  query: queryString.ParsedQuery<string>
  tooltipPointTitle?: string
  tooltipFormat?: Function
}) {
  const sortedPoints = orderBy(slice.points, ['data.y'], ['desc'])

  return (
    <div ref={tooltipRef}>
      <Card padding="0.5rem 1rem">
        <Typography variant="body" noMargin style={{ fontWeight: 600 }}>
          {moment(get(slice, 'points[0].data.x')).format(
            parseInt(step as string) <= 43200000 && numericalStep ? 'MMMM Do, hh:mm a' : 'MMMM Do'
          )}
        </Typography>
        {sortedPoints.map((point: Point) => {
          const customTooltipValue =
            variantNames && query.groupBy === 'variantUuids'
              ? variantNames[point.serieId]
              : tooltipPointTitle

          return (
            <S.TooltipContent key={point.id} data-pho="lineChartTooltip">
              <div>
                <S.ColorBlock color={point.serieColor} />
              </div>
              <S.TooltipContentText data-pho="lineChartTooltipValue">
                {`${customTooltipValue ? customTooltipValue : point.serieId}: `}
                <Typography variant="body" noMargin>
                  {(tooltipFormat && tooltipFormat(point.data.y)) ||
                    numeral(point.data.y).format('0,0')}
                </Typography>
              </S.TooltipContentText>
            </S.TooltipContent>
          )
        })}
      </Card>
    </div>
  )
}
