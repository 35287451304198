import * as React from 'react'

function SvgLightning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 30" {...props}>
      <path
        d="M9 29.766V19H.277L11 .234V11h8.723L9 29.766zM3.723 17H11v5.234L16.277 13H9V7.766L3.723 17z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLightning
