import React, { ReactNode } from 'react'
import * as S from './styles'
import Flex from 'components/shared/flex'
import Typography from 'components/shared/typography'
import { element } from 'components/shared/utils/spacing'
import { useDarkMode } from 'components/App/Providers/DarkMode'

export interface HeaderProps {
  /** Title of the Header */
  title?: string
  /** Subtitle of the Header. It will appear right bellow the title. */
  subTitle?: string
  /** Hide Title of the Header */
  hideTitle?: boolean
  /** Description of the Header. It will appear right bellow the subtitle. */
  description?: string | ReactNode
  /** Items that will appear on the right side of the Header */
  toolbar?: ReactNode
  /** Possibility to hide toolbar */
  showToolbar?: boolean
  /** Margin to add to the top of the Header component */
  topMargin?: string
  /** alignItems and justifyContent styles for toolbar in format { alignItems: 'center', justifyContent: 'flex-end' } */
  toolbarStyles?: Record<string, any>
  /** Items that will appear before toolbar items */
  additionalToolbarItemsBefore?: ReactNode
  /** Items that will appear after toolbar items */
  additionalToolbarItemsAfter?: ReactNode
  /** Items that will appear bellow the Header, but above the children*/
  belowTheFold?: ReactNode
  /** Additional action items that will appear above the toolbar items */
  additionalActionItems?: ReactNode
  /** The children text or element to render inside the component */
  children?: ReactNode
}

export const Header = ({
  title,
  description,
  toolbar,
  children,
  hideTitle = false,
  showToolbar = true,
  topMargin,
  toolbarStyles,
  additionalToolbarItemsBefore,
  additionalToolbarItemsAfter,
  additionalActionItems,
  belowTheFold,
  subTitle,
}: HeaderProps) => {
  const { isDarkMode } = useDarkMode()

  const onlyOneRow = !description && !additionalActionItems && !subTitle
  const supraTitleColor = !!subTitle ? (isDarkMode ? 'gray-10' : 'gray-30') : 'black'

  return (
    <>
      <S.HeadlineContainer
        data-test="HeadlineContainer"
        topMargin={topMargin}
        $isDarkMode={isDarkMode}
        direction={onlyOneRow ? 'row' : 'column'}
        justifyContent="space-between"
        alignItems={onlyOneRow ? 'center' : 'flex-start'}
      >
        {!hideTitle && (
          <Flex justifyContent="space-between" alignItems="flex-end">
            <Typography
              data-test="pageHeader"
              data-pho="pageHeader"
              noMargin={!!subTitle || !description}
              variant={subTitle ? 'eyebrow' : 'title4'}
              color={supraTitleColor}
              style={{
                marginBottom: 0,
                textTransform: 'uppercase',
              }}
            >
              {title}
            </Typography>

            {additionalActionItems && (
              <Flex justifyContent="flex-end" gap="16px">
                {additionalActionItems}
              </Flex>
            )}
          </Flex>
        )}
        <S.MainHeader
          direction="row"
          justifyContent="space-between"
          alignItems={description ? 'flex-end' : 'baseline'}
          gap="50px"
        >
          {(subTitle || description) && (
            <Flex direction="column" width="100%">
              {subTitle && (
                <Typography
                  variant="title4"
                  as="h2"
                  data-test="subTitle"
                  ellipsis={1}
                  noMargin={!description}
                >
                  {subTitle}
                </Typography>
              )}
              {description && (
                <Typography
                  variant="body"
                  color={isDarkMode ? 'gray-10' : 'gray-30'}
                  data-test="description"
                  noMargin
                  style={{ marginBottom: '8px' }}
                >
                  {description}
                </Typography>
              )}
            </Flex>
          )}
          <S.ToolbarContainer toolbarStyles={toolbarStyles} data-test="toolbarContainer">
            {additionalToolbarItemsBefore}
            {showToolbar && toolbar}
            {additionalToolbarItemsAfter}
          </S.ToolbarContainer>
        </S.MainHeader>
      </S.HeadlineContainer>
      <Flex justifyContent="space-between" margin={`${element.sm} 0 ${element.md}`}>
        <Flex>{belowTheFold}</Flex>
      </Flex>
      {children}
    </>
  )
}
