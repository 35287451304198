import * as React from 'react'

function SvgAsterisk(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M28 13H16.414l8.192-8.192-1.414-1.414L15 11.586V0h-2v11.586L4.808 3.394 3.394 4.808 11.586 13H0v2h11.586l-8.192 8.192 1.414 1.414L13 16.414V28h2V16.414l8.192 8.192 1.414-1.414L16.414 15H28z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAsterisk
