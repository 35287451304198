import styled from 'styled-components'
import ReactDayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'

import { colors } from 'components/shared/utils/colors'

export const DayPicker = styled(ReactDayPicker)`
  && .DayPicker-wrapper {
    padding: 0;
  }

  && .DayPicker-NavBar span {
    top: 0;
    margin-top: 0;
  }

  && .DayPicker-Month {
    margin: 0;
  }

  && .DayPicker-Day {
    border-radius: 0 !important;
    border-radius: 50% !important;
  }

  && .DayPicker-Day--start {
    border-radius: 50% !important;
    background-color: ${colors.blue20};
  }

  && .DayPicker-Day--end {
    border-radius: 50% !important;
    background-color: ${colors.blue20};
  }

  && .DayPicker-Day--today {
    color: ${colors.blue20};
  }

  &&
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(
      .DayPicker-Day--outside
    ) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }

  && .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected.DayPicker-Day--outside {
    background-color: unset;
  }

  && .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected.DayPicker-Day--outside {
    background-color: unset;
  }

  && .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--outside {
    background-color: unset;
  }
`
