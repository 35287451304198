import * as React from 'react'

function SvgActivityF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M8 8v16H2a2 2 0 01-2-2V8h8zm20-3v17a2 2 0 01-2 2h-6V5h8zM18 0v24h-8V0h8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgActivityF
