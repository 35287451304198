import * as React from 'react'

function SvgCautionAlertF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0L0 28h28L14 0zm-1.5 11h3v7h-3v-7zM14 24a2 2 0 110-4 2 2 0 010 4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCautionAlertF
