import * as React from 'react'

function SvgListAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M6 0h20a2 2 0 110 4H6V0zM0 0h4v4H0V0zm0 10h4v4H0v-4zm0 10h4v4H0v-4zm6-10h20a2 2 0 110 4H6v-4zm0 10h20a2 2 0 110 4H6v-4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgListAlt
