import * as React from 'react'

function SvgHomeAppliance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M21.002 0h-18a2.9 2.9 0 00-3 3v25h21a2.9 2.9 0 003-3V3a2.9 2.9 0 00-3-3zm-17 4h2v5h-2V4zm0 11h2v8h-2v-8zm18 10a.9.9 0 01-1 1h-19V13h20v12zm-20-14V3a.9.9 0 011-1h18a.9.9 0 011 1v8h-20z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHomeAppliance
