import useAuth from './useAuth'

interface AuthorizeRoleFromAuth {
  roleToMatch: ROLES
  contains: boolean
}
interface AuthorizeRolesFromAuth {
  rolesToMatch: ROLES[]
  contains: boolean
}
interface AuthorizeRoles {
  roles: string[]
  roleToMatch: ROLES
  contains: boolean
  caseSensitive?: boolean
}

export enum ROLES {
  PRISM_ADMIN = 'prism-admin',
  PRISM_FULL = 'prism-full',
  PRISM_BASIC = 'prism-basic',
  PRISM_BETA = 'prism-beta',
  PRISM_BETA_FAVORITES = 'prism-beta-favorites',
  PRISM_PITCH = 'prism-pitch',
  PRISM_DOCS = 'prism-docs',
  PRISM = 'prism-',
  VOC_READ = 'voc-read',
  VOC_PII_READ = 'customer-feedback-access',
  METRIC_READ = 'metrics-read',
  METRIC_WRITE = 'metrics-write',
}

export function authorizeRoleFromRoles({
  roles,
  roleToMatch,
  contains,
  caseSensitive = false,
}: AuthorizeRoles): boolean {
  if (!Array.isArray(roles)) return false

  const rolesSensitivityAware = caseSensitive ? roles : roles?.map(role => role.toLowerCase())

  if (contains) {
    return rolesSensitivityAware?.some((role: string) => role?.includes(roleToMatch)) ?? false
  }
  return rolesSensitivityAware?.includes(roleToMatch) ?? false
}

function useRoles() {
  const auth = useAuth()
  const roles = auth.user?.roles
  const authorizeRole = ({ roleToMatch, contains }: AuthorizeRoleFromAuth) =>
    authorizeRoleFromRoles({ roles: roles ?? [], roleToMatch, contains })

  const authorizeRoles = ({ rolesToMatch, contains }: AuthorizeRolesFromAuth) =>
    rolesToMatch.some(roleToMatch => authorizeRole({ roleToMatch, contains }))

  return {
    roles,
    authorizeRole,
    authorizeRoles,
  }
}

export default useRoles
