import * as React from 'react'

function SvgFilter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 22" {...props}>
      <path
        d="M5 10A5 5 0 115 0a5 5 0 010 10zm0-8a3 3 0 100 6 3 3 0 000-6zm16 20a5 5 0 110-10 5 5 0 010 10zm0-8a3 3 0 100 6 3 3 0 000-6zM14 4h12v2H14V4zM0 16h12v2H0v-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFilter
