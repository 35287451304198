import React from 'react'
import MenuList from '@material-ui/core/MenuList'
import { Button } from 'components/shared/Button'
import useApps from 'hooks/useApps'
import Favorites from './Favorites'
import ApplicationsList from './ApplicationsList'
import { HandleApplicationSwitcherClose } from 'components/layout/Topbar/ApplicationSwitcher'
import * as S from './styles'

export default function Applications({
  searchInput,
  isViewingAll,
  handleClickViewAll,
  handleApplicationSwitcherClose,
}: {
  searchInput: string
  isViewingAll: boolean
  handleClickViewAll: (e: React.SyntheticEvent) => void
  handleApplicationSwitcherClose: (event: HandleApplicationSwitcherClose) => void
}) {
  const { apps } = useApps()

  if (searchInput) {
    return (
      <>
        <S.NavTitle>Search Results</S.NavTitle>
        <MenuList>
          <ApplicationsList
            apps={apps}
            search={searchInput}
            handleApplicationSwitcherClose={handleApplicationSwitcherClose}
          />
        </MenuList>
      </>
    )
  }

  return (
    <>
      <Favorites handleApplicationSwitcherClose={handleApplicationSwitcherClose} />
      {isViewingAll ? (
        <>
          <S.NavTitle>Applications</S.NavTitle>
          <MenuList>
            <ApplicationsList
              apps={apps}
              handleApplicationSwitcherClose={handleApplicationSwitcherClose}
            />
          </MenuList>
        </>
      ) : (
        <Button
          variant="borderless"
          onClick={handleClickViewAll}
          data-pho="viewAllApplications"
          width="100%"
        >
          View All
        </Button>
      )}
    </>
  )
}
