import * as React from 'react'

function SvgPlumePod(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 24" {...props}>
      <path
        d="M16.134 19H9.866a2.052 2.052 0 01-1.762-1.1L5.754 13a1.832 1.832 0 010-2l2.35-4.9A2.052 2.052 0 019.866 5h6.268a2.052 2.052 0 011.762 1.1l2.35 4.9a1.832 1.832 0 010 2l-2.35 4.9a2.052 2.052 0 01-1.762 1.1zM9.866 7h-.098l-2.252 4.9v.2l2.35 4.9h6.268l2.35-4.9v-.2L16.134 7H9.866zm8.814 17H7.32a2.834 2.834 0 01-2.546-1.6l-4.407-9a2.851 2.851 0 010-2.8l4.31-9A3.124 3.124 0 017.32 0h11.36a2.834 2.834 0 012.546 1.6l4.407 9c.49.866.49 1.934 0 2.8l-4.31 9A3.124 3.124 0 0118.68 24zM7.32 2a1.072 1.072 0 00-.881.5l-4.309 9a.916.916 0 000 1l4.309 9c.194.305.524.493.881.5h11.36c.357-.007.687-.195.881-.5l4.309-9a.916.916 0 000-1l-4.309-9a1.072 1.072 0 00-.881-.5H7.32z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPlumePod
