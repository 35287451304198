import * as React from 'react'

function SvgEditF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 25" {...props}>
      <path
        d="M28 9v13.182A2.962 2.962 0 0124.92 25H0v-2h24.92c.586 0 1.08-.375 1.08-.818V9h2zM17.88 1.7a3.009 3.009 0 014.24 0l.18.18a3.018 3.018 0 010 4.24L8.58 19.88l-5.95 1.49 1.49-5.95zm2.115 1.127a.996.996 0 00-.705.293l-1.27 1.26 2.66.54.2-.21a.996.996 0 000-1.41l-.18-.18a.996.996 0 00-.705-.293z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgEditF
