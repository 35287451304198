import * as React from 'react'

function SvgQuestionCircleF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14C27.992 6.272 21.728.008 14 0zm1.26 22h-2v-3h2v3zm2.71-10.29c-.41.4-.83.75-1.2 1.05-1.02.84-1.51 1.28-1.51 2.24v2h-2v-2c0-1.95 1.19-2.93 2.25-3.79a11.05 11.05 0 001.04-.92c.1-.089.12-.237.05-.35-.15-.35-.8-.94-2.34-.94a3.381 3.381 0 00-3.11 1.46l-1.78-.91A5.305 5.305 0 0114.26 7c2.42 0 3.75 1.13 4.19 2.18a2.296 2.296 0 01-.48 2.53z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgQuestionCircleF
