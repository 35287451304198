import { Button } from 'components/shared/Button'
import PopoverMenu from 'components/shared/popover'
import FavoritesPopover from './FavoritesPopover'

const PageFavoritesSwitcher = () => (
  <>
    <div data-pho="topbarPageFavoritesSwitcher">
      <PopoverMenu
        anchorContent={
          <Button variant="neutral" icon="Star">
            My Favorites
          </Button>
        }
      >
        {({ isPopoverOpen, handleClose }) =>
          isPopoverOpen ? <FavoritesPopover handleClose={handleClose} /> : null
        }
      </PopoverMenu>
    </div>
  </>
)

export default PageFavoritesSwitcher
