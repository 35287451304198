import * as React from 'react'

function SvgPauseCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.732 0 14 6.268 14 14-.009 7.728-6.272 13.991-14 14-7.732 0-14-6.268-14-14S6.268 0 14 0zm0 2C7.373 2 2 7.373 2 14s5.373 12 12 12 12-5.373 12-12c-.007-6.624-5.376-11.993-12-12zm-1 6v12H7V8h6zm8 0v12h-6V8h6zm-10 2H9v8h2v-8zm8 0h-2v8h2v-8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPauseCircle
