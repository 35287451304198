import { useState, useEffect } from 'react'
import useAuth from 'hooks/useAuth'

enum ROLES {
  KRYPTO_PREFIX = 'transformer',
  KRYPTO_ENCRYPT = 'transformer_access',
  KRYPTO_DECRYPT = 'transformer-decrypt',
}

const checkKryptoGeneralAccess = (roles?: string[]): boolean =>
  roles?.some((role: string) => role?.includes(ROLES.KRYPTO_PREFIX)) ?? false
const checkKryptoEncryptAccess = (roles?: string[]): boolean =>
  roles?.includes(ROLES.KRYPTO_ENCRYPT) ?? false
const checkKryptoDecryptAccess = (roles?: string[]): boolean =>
  roles?.includes(ROLES.KRYPTO_DECRYPT) ?? false

const useKrypto = () => {
  const auth = useAuth()
  const roles = auth.user?.roles
  const [hasGeneralKryptoAccess, setHasGeneralKryptoAccess] = useState(
    checkKryptoGeneralAccess(roles)
  )
  const [hasKryptoEncryptAccess, setHasKryptoEncryptAccess] = useState(
    checkKryptoEncryptAccess(roles)
  )
  const [hasKryptoDecryptAccess, setHasKryptoDecrypt] = useState(checkKryptoDecryptAccess(roles))

  useEffect(() => {
    if (roles) {
      setHasGeneralKryptoAccess(checkKryptoGeneralAccess(roles))
      setHasKryptoEncryptAccess(checkKryptoEncryptAccess(roles))
      setHasKryptoDecrypt(checkKryptoDecryptAccess(roles))
    }
  }, [roles])

  return {
    hasGeneralKryptoAccess,
    hasKryptoEncryptAccess,
    hasKryptoDecryptAccess,
  }
}

export default useKrypto
