import styled from 'styled-components'

export const BottomContainer = styled.div<{
  height?: number
}>`
  height: ${props => (props.height ? props.height + 'px' : '100%')};
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: 'Open Sans';
  height: 100%;
  padding: 1rem;
  width: 100%;
`

export const TopContainer = styled.div<{ withTopMargin?: boolean }>`
  display: flex;
  flex-grow: 0;
  justify-content: space-between;
`

export const TopContent = styled.div`
  display: flex;
  width: 100%;
`

export const ChartDescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1 0 0;
  position: relative;
`

export const MultiChartDescriptionContainer = styled.div<{ hasNoTopMargin?: any }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
`
