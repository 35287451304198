import React, { useState, MouseEventHandler } from 'react'
import Modal from 'components/shared/Modal'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import ErrorMessage from 'components/shared/ErrorMessage'
import usePrismUser from 'hooks/usePrismUser'
import FavoritesModalContent from './Content'
import WelcomeModalContent from './WelcomeContent'
import { HandleApplicationSwitcherClose } from 'components/layout/Topbar/ApplicationSwitcher'
import * as S from './styles'

function FavoritesModal({
  isOpen,
  onClose,
  handleApplicationSwitcherClose,
  shouldNavigate,
}: {
  isOpen: boolean
  onClose: MouseEventHandler<HTMLElement>
  handleApplicationSwitcherClose?: (event: HandleApplicationSwitcherClose) => void
  shouldNavigate?: boolean
}) {
  // Data
  const [modalIndex, setModalIndex] = useState(0)
  const prismUser = usePrismUser()
  const [isNewUser] = useState(prismUser.favorites?.length === 0)

  if (prismUser.favorites === undefined) return <LoadingSpinner />
  if (prismUser.favorites === null) {
    return (
      <ErrorMessage includeMailTo errorType="api" errorMessage="Error fetching user favorites." />
    )
  }

  // Methods
  function handleContinue() {
    setModalIndex(modalIndex + 1)
  }

  function handleBack() {
    setModalIndex(modalIndex - 1)
  }

  // Modals
  const welcomeModal = {
    title: <S.ModalTitle variant="title1">Welcome to Prism</S.ModalTitle>,
    content: <WelcomeModalContent handleContinue={handleContinue} />,
    justifyTitle: 'center',
    props: { maxWidth: '100%' },
  }
  const favoritesModal = {
    title: null,
    content: (
      <FavoritesModalContent
        prismUser={prismUser}
        handleBack={handleBack}
        isNewUser={isNewUser}
        onClose={onClose}
        handleApplicationSwitcherClose={handleApplicationSwitcherClose}
        shouldNavigate={shouldNavigate}
      />
    ),
    justifyTitle: 'flex-start',
    props: { minWidth: '90%', maxWidth: '90%', minHeight: '90%', maxHeight: '90%' },
  }
  const modals = isNewUser ? [welcomeModal, favoritesModal] : [favoritesModal]
  const currentModal = modals[modalIndex]

  // Render
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      xClick={onClose}
      title={currentModal.title}
      justifyActionsContent="center"
      justifyTitle={currentModal.justifyTitle}
      content={currentModal.content}
      PaperProps={{
        style: currentModal.props,
      }}
    />
  )
}

export default FavoritesModal
