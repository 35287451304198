import React, { useState } from 'react'
import Modal from 'components/shared/Modal'
import { Button } from 'components/shared/Button'
import { IconButton } from 'components/shared/IconButton'
import CreateGroup from './CreateGroup'
import EditOrDeleteGroups from './EditOrDeleteGroups'
import * as S from './styles'

function ModalContent({ handleCloseModal }: any) {
  const [showEditContent, setShowEditContent] = useState(false)
  const [showCreateContent, setShowCreateContent] = useState(false)

  function handleEditCardClick() {
    setShowEditContent(true)
    setShowCreateContent(false)
  }
  function handleCreateCardClick() {
    setShowEditContent(false)
    setShowCreateContent(true)
  }

  if (showEditContent)
    return (
      <S.ModalContentContainer>
        <EditOrDeleteGroups handleCloseModal={handleCloseModal} />
      </S.ModalContentContainer>
    )

  if (showCreateContent)
    return (
      <S.ModalContentContainer>
        <CreateGroup handleCloseModal={handleCloseModal} />
      </S.ModalContentContainer>
    )

  return (
    <S.ModalContentContainer>
      <Button data-pho="editExistingGroupsButton" variant="secondary" onClick={handleEditCardClick}>
        Edit existing Groups
      </Button>
      <Button data-pho="createNewGroupButton" onClick={handleCreateCardClick}>
        Create new Group
      </Button>
    </S.ModalContentContainer>
  )
}

function SettingsButton() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  function handleClick() {
    setIsModalOpen(true)
  }

  function handleCloseModal() {
    setIsModalOpen(false)
  }

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => handleCloseModal()}
        xClick={() => handleCloseModal()}
        content={<ModalContent handleCloseModal={handleCloseModal} />}
        justifyActionsContent="center"
      />
      <IconButton
        icon="Settings"
        onClick={handleClick}
        data-pho="groupsSettingsButton"
        color="black"
      />
    </>
  )
}

export default SettingsButton
