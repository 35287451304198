import * as React from 'react'

function SvgPlusAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path d="M24 10H14V0h-4v10H0v4h10v10h4V14h10z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default SvgPlusAlt
