import React from 'react'
import { LegendProps } from '@nivo/legends'
import { ResponsiveBar } from '@nivo/bar'
import numeral from 'numeral'
import isEmpty from 'lodash/isEmpty'
import { colors } from 'components/shared/utils/colors'
import { chartColors } from 'common/constants'
import { FormattedHorizontalBarData } from 'components/shared/ChartData/types'
import { ChartProps } from 'components/shared/chart/types'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import NivoContainer from 'components/shared/NivoContainer'
import { useDarkMode } from 'components/App/Providers/DarkMode'

function HorizontalBarChart({
  data,
  legendTitle,
  margin,
  dataSource,
  legends,
  barColors,
}: HorizontalBarChartProps) {
  const { isDarkMode } = useDarkMode()

  const chartTheme = {
    tooltip: {
      container: {
        background: isDarkMode ? colors.darkMode.background : colors.white,
      },
    },
    textColor: isDarkMode ? 'white' : 'black',
    axis: {
      ticks: {
        text: {
          fontSize: '12px',
          fontFamily: 'Open Sans',
        },
      },
    },
  }

  if (isEmpty(data)) {
    return <NoDataChartMessage dataSource={dataSource} />
  }
  return (
    <NivoContainer>
      <ResponsiveBar
        data={data.barData}
        theme={chartTheme}
        layout="horizontal"
        labelSkipWidth={19}
        indexBy="title"
        colors={barColors ? bar => barColors[bar.id] : chartColors}
        keys={data.barKeys}
        labelTextColor={colors.white}
        legends={legends}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: legendTitle,
          legendPosition: 'middle',
          legendOffset: -110,
        }}
        axisBottom={{
          format: (value: any) => numeral(value).format('0a'),
        }}
        margin={margin || { top: 20, right: 20, bottom: 30, left: 50 }}
      />
    </NivoContainer>
  )
}

interface HorizontalBarChartProps extends Omit<ChartProps, 'legends'> {
  data: FormattedHorizontalBarData
  legendTitle?: string
  dataSource?: string
  legends?: ({ dataFrom: 'keys' | 'indexes' } & LegendProps)[]
  barColors?: {
    [key: string]: string
  }
}

export default HorizontalBarChart
