import React from 'react'
import { useState } from 'react'
import { omit, isEmpty } from 'lodash'
import queryString from 'query-string'
import { useLocation, navigate } from '@reach/router'
import { Menu, MenuProps } from 'components/shared/Menu'
import { Dialog } from 'components/shared/Dialog'
import { Domains } from 'common/constants'
import { downloadData } from 'components/shared/DownloadButton/utils'
import useGroups from 'hooks/useGroups'
import useApps from 'hooks/useApps'
import useFilterValues from 'hooks/useFilterValues'
import { parseChartDataToCSV } from '../utils'
import * as T from '../types'
import * as S from '../styled'
import { getOpenSearchUrlProps, formatMetaData } from './utils'

export default function ActionsMenu({
  chartData,
  chartTitle,
  chartType,
  getOpenSearchUrl,
  handleLineZoom,
  inSmallTabsContainer,
  isLineChartZoomedOut,
  metaData,
  removeChart,
  setDisplayTable,
  zoomOption,
  hasAbsolutePosition,
  shouldDisplayTable,
  chartID,
  changelog,
  metricQuery,
  timeZone,
  dataSource,
  link,
  linkPath,
}: {
  chartData?: any
  chartQuery?: any
  chartTitle?: string
  chartType?: T.DataType
  getOpenSearchUrl?: (args: T.OpenSearchUrlArgs) => string
  handleLineZoom?: any
  inSmallTabsContainer?: boolean
  isLineChartZoomedOut?: boolean
  metaData?: any
  removeChart?: Function
  setDisplayTable?: Function
  zoomOption?: boolean
  hasAbsolutePosition?: boolean
  shouldDisplayTable?: boolean
  chartID?: string
  changelog?: any
  metricQuery?: any
  timeZone?: string
  dataSource?: string | true | JSX.Element
  link?: JSX.Element
  linkPath?: string
}) {
  const [isChangelogOpen, setIsChangelogOpen] = useState(false)
  const [isMetricQueryOpen, setIsMetricQueryOpen] = useState(false)
  const [showInfoSection, setShowInfoSection] = useState(false)

  const location = useLocation()
  const queries = queryString.parse(location.search)
  const filterValues = useFilterValues()
  const {
    appsByDomain,
    appsByDataSource,
    entityID,
    app: { name, type },
  } = useApps()
  const { currentGroup } = useGroups()

  // TODO: Kite. After changing the Menu this is still needed?
  // const parsedCSVData = parseChartDataToCSV(chartData, queries.step as string, chartType)
  // const chartDataIsEmpty = _.isEmpty(parsedCSVData)
  const openSearchUrlProps = getOpenSearchUrlProps({
    filterValues,
    location,
    appsByDomain,
    appsByDataSource,
    entityID,
    Domains,
    currentGroup,
    name,
    type,
  })

  const openSearchUrl = getOpenSearchUrl
    ? getOpenSearchUrl({
        name,
        type,
        ...openSearchUrlProps,
        currentGroupsIds: currentGroup?.applicationIDs || [],
      })
    : null
  function handleLineChartZoom() {
    handleLineZoom()
  }

  function handleRemove() {
    removeChart && removeChart(chartID)
  }

  function toggleTable() {
    setDisplayTable && setDisplayTable(true)
  }

  async function handleDownloadClick() {
    const data = parseChartDataToCSV(chartData, queries.step as string, chartType)
    const path = `${chartTitle}_${location.pathname.replace(/\//g, '_')}`
    await downloadData(path, data)
  }

  const handleCloseChangelog = () => {
    setIsChangelogOpen(false)
  }

  const handleOpenChangelog = () => {
    setIsChangelogOpen(true)
  }

  const handleCloseMetricQuery = () => {
    setIsMetricQueryOpen(false)
  }

  const handleOpenMetricQuery = () => {
    setIsMetricQueryOpen(true)
  }
  const handleOpenInfoSection = () => {
    setShowInfoSection(true)
  }
  const infoSection = timeZone || dataSource || link

  const hasData = !isEmpty(omit(chartData, 'metaData'))

  const menuItems = [
    openSearchUrl && {
      label: 'See in OpenSearch',
      icon: 'ArrowUpRight',
      onClick: () => window.open(openSearchUrl),
      'data-pho': `${chartTitle}actionBtnMenuItemOpenSearch`,
      iconColor: 'blue 20'
    },
    hasData &&
      zoomOption && {
        label: isLineChartZoomedOut ? 'Zoom In' : 'Zoom Out',
        icon: 'Zoom',
        onClick: handleLineChartZoom,
        'data-pho': `${chartTitle}actionBtnMenuItemZoom`,
        iconColor: 'blue 20'
      },
    hasData &&
      shouldDisplayTable &&
      setDisplayTable && {
        label: 'Display Table',
        icon: 'ListAlt',
        onClick: toggleTable,
        'data-pho': `${chartTitle}actionBtnMenuItemDisplayTable`,
        iconColor: 'blue 20'
      },
    hasData &&
      chartType !== T.DataType.METRIC_AGGREGATE && {
        label: 'Export as CSV',
        icon: 'Download',
        onClick: handleDownloadClick,
        'data-pho': `${chartTitle}actionBtnMenuItemDownloadCSV`,
        iconColor: 'blue 20'
      },
    removeChart && {
      label: 'Remove Metric',
      icon: 'Trash',
      onClick: handleRemove,
      'data-pho': `${chartTitle}removeMetricBtn`,
      iconColor: 'blue 20'
    },
    changelog && {
      label: 'Changelog',
      icon: 'InfoCircle',
      onClick: handleOpenChangelog,
      'data-pho': `${chartTitle}changeLogBtn`,
      iconColor: 'blue 20'
    },
    metricQuery && {
      label: 'Metric Query',
      icon: 'InfoCircle',
      onClick: handleOpenMetricQuery,
      'data-pho': `${chartTitle}metricQueryBtn`,
      iconColor: 'blue 20'
    },
    // Divisor
    infoSection && {
      label: 'More Info',
      icon: 'InfoCircle',
      onClick: handleOpenInfoSection,
      'data-pho': `${chartTitle}moreInfoBtn`,
      iconColor: 'blue 20'
    },
    // @TODO: Using onClick here as a workaround until the Menu component is updated to support links
    // once links are supported, revert the changes from this MR: https://gitlab.spectrumflow.net/client-analytics/prism/-/merge_requests/1401
    link && linkPath && { label: link, onClick: () => navigate(linkPath) },
  ]

  const formattedMetaData = formatMetaData(metaData)

  return (
    <S.ActionsMenuContainer
      inSmallTabsContainer={inSmallTabsContainer}
      hasAbsolutePosition={hasAbsolutePosition}
    >
      <Menu
        items={menuItems.filter(menuItem => menuItem) as MenuProps['items']}
        data-pho="actionBtn"
        iconButton={{
          icon: 'MoreVert',
          color: 'black',
          'data-pho': `${chartTitle}actionMenuButton`,
        }}
      />
      <Dialog
        title="More Info"
        acceptLabel="Close"
        isOpen={showInfoSection}
        onAccept={() => {
          setShowInfoSection(false)
        }}
        onCancel={() => {
          setShowInfoSection(false)
        }}
      >
        <>
          {timeZone && (
            <>
              <h6>Time Zone</h6>
              <p>{timeZone}</p>
            </>
          )}
          {dataSource && (
            <>
              <h6>Data Source</h6>
              <p>{dataSource}</p>
            </>
          )}
          {metaData && (
            <>
              <h6>Meta Data</h6>
              <S.MetaDataContainer>
                <p>{formattedMetaData}</p>
              </S.MetaDataContainer>
            </>
          )}
        </>
      </Dialog>
      <Dialog
        onAccept={handleCloseChangelog}
        acceptLabel="Close"
        title="Changelog"
        isOpen={isChangelogOpen}
        onCancel={handleCloseChangelog}
      >
        {changelog}
      </Dialog>
      <Dialog
        onAccept={handleCloseMetricQuery}
        acceptLabel="Close"
        width="auto"
        title="MetricQuery"
        isOpen={isMetricQueryOpen}
        onCancel={handleCloseMetricQuery}
      >
        <S.MetricQuery>{metricQuery}</S.MetricQuery>
      </Dialog>
    </S.ActionsMenuContainer>
  )
}
