import Tooltip from '@material-ui/core/Tooltip'
import { IconButton } from 'components/shared/IconButton'

export default function NextPeriodButton({
  tooltipText,
  isDisabled,
  handleMoveToFuture,
}: {
  tooltipText: string
  isDisabled: boolean
  handleMoveToFuture: () => void
}) {
  return (
    <Tooltip title={tooltipText} placement="top">
      <div>
        <IconButton
          disabled={isDisabled}
          icon="ChevronRight"
          size="md"
          onClick={handleMoveToFuture}
        />
      </div>
    </Tooltip>
  )
}
