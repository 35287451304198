import * as React from 'react'

function SvgChat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M12.988 0H13a12.578 12.578 0 0113 13c0 8.019-4.982 13-13 13l-13.002-.005L0 13c0-4.095 1.276-7.46 3.69-9.731A13.245 13.245 0 0112.988 0zM5.06 4.725C3.03 6.637 2.001 9.42 2.001 13l-.002 10.995L13 24c6.99 0 11-4.01 11-11 0-6.473-4.523-10.996-11-11h-.012A11.3 11.3 0 005.06 4.725zM14.999 14v2H6v-2h9zm5-4v2H6v-2h14z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChat
