import * as React from 'react'

function SvgArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 28" {...props}>
      <path
        d="M.645 13v2h24.527L14.151 26.021l1.414 1.414L29 14 15.565.565l-1.414 1.414L25.172 13z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgArrowRight
