import React, { useRef } from 'react'
import Popover, { PopoverOrigin } from '@material-ui/core/Popover'
import * as S from './styles'

function PopoverMenu({
  children,
  onClose,
  anchorContent,
  anchorButtonProps,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  optimizeForSmallScreen,
  anchorButton,
  zIndex,
}: PopoverMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const AnchorButtonRef = useRef<HTMLDivElement>(null)

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose(
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    onClose && onClose(event)
    setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)
  const id = isOpen ? 'simple-popover' : undefined

  // If popover is larger than screen, it will not open where anchor has been set.
  // To solve this, height of the popover was decreased according to the screen size.
  // Now popover overflows on smaller screens if optimizeForSmallScreen flag is passed.
  const AnchorButton = anchorButton || S.AnchorButton
  const anchorBoundingClientBottom: any =
    AnchorButtonRef &&
    AnchorButtonRef?.current &&
    AnchorButtonRef?.current.getBoundingClientRect().bottom

  return (
    <S.PopoverContainer ref={AnchorButtonRef} data-test="popoverContainer">
      <AnchorButton
        data-test="anchorButton"
        aria-controls="simple-menu"
        aria-describedby={id}
        onClick={handleClick}
        {...anchorButtonProps}
      >
        {anchorContent}
      </AnchorButton>
      <Popover
        data-test="popover"
        style={{ ...(zIndex ? { zIndex } : {}) }}
        id={id}
        open={isOpen}
        PaperProps={
          optimizeForSmallScreen
            ? {
                style: { maxHeight: `${window.innerHeight - anchorBoundingClientBottom - 16}px` },
              }
            : {}
        }
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {children({ isPopoverOpen: isOpen, handleClose })}
      </Popover>
    </S.PopoverContainer>
  )
}

interface PopoverMenuProps {
  children: (args: {
    isPopoverOpen: boolean
    handleClose: (
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => void
  }) => React.ReactNode
  onClose?: (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void
  anchorContent?: React.ReactNode
  anchorButtonProps?: Record<string, any>
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  optimizeForSmallScreen?: boolean
  anchorButton?: any
  zIndex?: number
}

export default PopoverMenu
