import { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import pick from 'lodash/pick'
import last from 'lodash/last'
import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { useLocation } from '@reach/router'
import { Button } from 'components/shared/Button'
import PopoverMenu from 'components/shared/popover'
import { times, getPeriodFromUrl } from 'utils'
import VoCTimeMenu from './components/VoCTimeMenu'
import PrevPeriodButton from './components/PrevPeriodButton'
import NextPeriodButton from './components/NextPeriodButton'
import {
  getAbsoluteFromRelativePeriodVoC,
  getEndOfYesterday,
  getRelativeValueIsValid,
} from 'utils/times/times'
import * as C from './constants'
import * as S from './styled'
import * as U from './utils'
import * as T from './types'
import useAuth from 'hooks/useAuth'
import isNil from 'lodash/isNil'

function TimeMenu({
  filters = U.defaultFilters,
  periods = U.defaultPeriods,
  values,
  updateValues,
  displayFiscalFilter = false,
  absoluteTimeRangeLimit = 0,
}: T.TimeMenuProps) {
  const location = useLocation()
  const scopedFilterNames = [
    'period',
    'step',
    'origintz',
    'periodOffset',
    ...filters.map(filter => filter.id),
  ]
  const scopedValues = pick(values, scopedFilterNames)
  const [selectedValues, setSelectedValues] = useState(scopedValues)
  const [activeTab, setActiveTab] = useState(0)
  const isAbsoluteTimePickerTabOpen = activeTab === C.ABSOLUTE_TIME_PICKER_TAB_INDEX
  const [error, setError] = useState<Nullable<string>>(null)
  const [selectedRange, setSelectedRange] = useState<T.SelectedRange>()
  const [inputRange, setInputRange] = useState<{ from?: Date; to?: Date }>()
  const { user } = useAuth()

  const isAbsolutePeriod = selectedValues.period && selectedValues.period.split(':').length > 1
  const currentSelectedPeriod = isAbsolutePeriod
    ? selectedValues.period
    : periods.find(
        period =>
          period.value === selectedValues.period &&
          period.periodOffset === (selectedValues?.periodOffset || 0)
      )
  const currentStepOptions = isAbsoluteTimePickerTabOpen
    ? U.getAbsoluteTimeStepOptions(selectedValues.period)
    : currentSelectedPeriod?.steps
  const lastFiscalWeekPeriod = getAbsoluteFromRelativePeriodVoC('1fw', 1)
  const currentFiscalMonthPeriod = getAbsoluteFromRelativePeriodVoC('1fm', 0)
  const previousFiscalMonthPeriod = getAbsoluteFromRelativePeriodVoC('1fm', 1)
  const yesterdayAbsolutePeriod = getAbsoluteFromRelativePeriodVoC('1d', 1)
  const last10DaysAbsolutePeriod = getAbsoluteFromRelativePeriodVoC('10d', 1)
  const last30DaysAbsolutePeriod = getAbsoluteFromRelativePeriodVoC('30d', 1)

  const todayGMT = moment().endOf('day').valueOf()
  const todayUTC = moment.utc().endOf('day').valueOf()

  const handleAbsoluteTimeChange = (newPeriod: T.SelectedRange) => {
    const { periodOffset, ...values } = selectedValues
    const { from, to } = newPeriod
    setSelectedRange(newPeriod)
    if (from && to) {
      const [fromDateVal, toDateVal] = to < from ? [to, from] : [from, to]
      setSelectedRange({ from: fromDateVal, to: toDateVal })
      const hasOffset =
        `${-new Date().getTimezoneOffset() / 60}` !==
        `${-new Date(fromDateVal).getTimezoneOffset() / 60}`
      const timezoneOffset =
        hasOffset && selectedValues?.origintz
          ? parseInt(selectedValues?.origintz) * C.HOUR_IN_MILLISECONDS
          : 0
      const dateFrom = times.getTimestampDayStart(moment(fromDateVal).valueOf(), timezoneOffset)
      const dateTo = times.getTimestampDayEnd(moment(toDateVal).valueOf(), timezoneOffset)
      const period = `${dateFrom}:${dateTo}`
      const diffTime = Math.abs(dateFrom - dateTo)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      if ((dateTo as number) > todayGMT && (dateTo as number) > todayUTC) {
        setError('You cannot pick a date after today.')
      } else if (absoluteTimeRangeLimit && diffDays > absoluteTimeRangeLimit) {
        setError(
          `We are currently working on our servers to allow for requests for more than ${absoluteTimeRangeLimit} days.`
        )
      } else if (
        diffDays > 31 &&
        !user?.roles.some((role: string) => role.includes('prism-full'))
      ) {
        setError('You do not have permissions to request greater than 31 days of data.')
      } else {
        const step = 86400000
        setSelectedValues({
          ...values,
          period,
          step,
        })
        setError(null)
      }
    }
  }

  const { completeMonths, absoluteMonths } = groupBy(periods, 'type')

  const months = [...(completeMonths || []), ...(absoluteMonths || [])]

  const handlePeriodClick = (newPeriod: T.Period) => () => {
    const { periodOffset, ...values } = selectedValues
    const stepOptions = U.getStepOptions(periods, newPeriod.value)
    const step =
      stepOptions?.find(stepOption => stepOption.step === selectedValues?.step)?.step ||
      last(stepOptions)?.step

    const period = newPeriod.value
    let modifiedPeriodOffset = {}

    if (!isNil(newPeriod?.periodOffset))
      modifiedPeriodOffset = { periodOffset: newPeriod.periodOffset }
    const [dateFrom, dateTo] = getAbsoluteFromRelativePeriodVoC(
      newPeriod.value,
      newPeriod?.periodOffset || periodOffset
    ).split(':')

    setInputRange({
      from: new Date(parseInt(dateFrom)),
      to: new Date(parseInt(dateTo)),
    })
    setSelectedRange(undefined)

    setError(null)
    setSelectedValues({
      ...values,
      ...modifiedPeriodOffset,
      period,
      step,
    })
  }

  function isPeriodSelected(period: T.Period): boolean {
    return U.getIsPeriodSelected(
      period,
      currentSelectedPeriod,
      lastFiscalWeekPeriod,
      currentFiscalMonthPeriod,
      previousFiscalMonthPeriod,
      yesterdayAbsolutePeriod,
      last10DaysAbsolutePeriod,
      last30DaysAbsolutePeriod
    )
  }

  function applyTimeQuickChange(increaseToTheFuture: boolean, applyVocLatestDate?: boolean) {
    const period = selectedValues?.period
    if (U.isPeriodAbsolute(period)) {
      const [fromDate, toDate] = period.split(':')

      const periodDifference =
        Math.round((parseInt(toDate) - parseInt(fromDate)) / C.DAY_IN_MILLISECONDS) *
        C.DAY_IN_MILLISECONDS
      const newFromDate = increaseToTheFuture
        ? parseInt(fromDate) + periodDifference
        : parseInt(fromDate) - periodDifference
      const vocFurthestDateAvailable = applyVocLatestDate
        ? getEndOfYesterday()
        : parseInt(toDate) + periodDifference
      const newToDate = increaseToTheFuture
        ? vocFurthestDateAvailable
        : parseInt(toDate) - periodDifference
      const newFromDateWithDST =
        !moment(newFromDate).isDST() && moment(parseInt(fromDate)).isDST()
          ? moment(newFromDate).add(1, 'hours').valueOf()
          : newFromDate
      const newToDateWithDST =
        moment(newToDate).isDST() && !moment(parseInt(toDate)).isDST()
          ? moment(newToDate).subtract(1, 'hours').valueOf()
          : newToDate
      const newPeriod = `${newFromDateWithDST}:${newToDateWithDST}`
      const updatedValues = omit(selectedValues, 'period')

      updateValues({ ...updatedValues, period: newPeriod })
    } else if (getRelativeValueIsValid(period)) {
      const offset = selectedValues?.periodOffset ? parseInt(selectedValues?.periodOffset) : 0
      const periodOffset = increaseToTheFuture ? offset - 1 : offset + 1
      updateValues({ ...updateValues, periodOffset })
    }
  }

  function handleMoveToPast() {
    applyTimeQuickChange(false)
  }

  function handleMoveToFuture() {
    const applyVocLatestDate = U.getIsFutureNotAvailable(selectedValues, todayGMT, todayUTC)
    applyTimeQuickChange(true, applyVocLatestDate)
  }

  function applyFilters(): void {
    updateValues(selectedValues)
  }

  useDeepCompareEffect(() => {
    if (!displayFiscalFilter && selectedValues.fiscalTime) {
      updateValues({
        fiscalTime: null,
      })
    }
  }, [currentStepOptions, displayFiscalFilter, isAbsolutePeriod, selectedValues, updateValues])

  const defaultDisableApplyChecks =
    isEmpty(currentSelectedPeriod) ||
    (typeof currentSelectedPeriod === 'string' && currentSelectedPeriod?.includes('NaN'))

  const shouldDisableApplyVoC = Boolean(error) || defaultDisableApplyChecks
  return (
    <>
      <PrevPeriodButton handleMoveToPast={handleMoveToPast} />
      <PopoverMenu
        optimizeForSmallScreen
        anchorContent={U.getLabel(periods, values.period, true, values?.periodOffset) as string}
        anchorButtonProps={{ icon: 'Reminder', variant: 'neutral', 'data-pho': 'timeMenuButton' }}
        anchorButton={Button}
        onClose={() => setSelectedValues(scopedValues)}
      >
        {({ isPopoverOpen }: { isPopoverOpen: boolean }) =>
          isPopoverOpen && (
            <S.TimeMenuContainer isTwoColumns={!isEmpty(months)} isVoC={true}>
              <VoCTimeMenu
                periods={periods}
                handlePeriodClick={handlePeriodClick}
                isPeriodSelected={isPeriodSelected}
                inputRange={inputRange}
                handleAbsoluteTimeChange={handleAbsoluteTimeChange}
                getDefaultAbsoluteSelectedRange={(isDateInput: boolean) =>
                  U.getDefaultAbsoluteSelectedRange(selectedValues, selectedRange, isDateInput)
                }
                error={error}
                setInputRange={setInputRange}
              />
              <S.StyledButtonContainer>
                <S.StyledButton
                  onClick={applyFilters}
                  data-pho="applyBtn"
                  disabled={shouldDisableApplyVoC}
                >
                  Apply
                </S.StyledButton>
              </S.StyledButtonContainer>
            </S.TimeMenuContainer>
          )
        }
      </PopoverMenu>
      <NextPeriodButton
        tooltipText={U.getTimeQuickTooltipText(selectedValues, todayGMT, todayUTC)}
        isDisabled={U.getIsFutureDisabled(selectedValues, todayGMT, todayUTC)}
        handleMoveToFuture={handleMoveToFuture}
      />
    </>
  )
}

export default TimeMenu
