import Tooltip from '@material-ui/core/Tooltip'
import Link from 'components/shared/Link/PrismReactLink'

export default function SequenceButton({ url }: { url: string }) {
  return (
    <Tooltip title={`Redirect to Sequence with Visit ID`} placement="top" arrow>
      <div>
        <Link href={url} variant="standalone" newTab data-pho="Sequence">
          Sequence
        </Link>
      </div>
    </Tooltip>
  )
}
