import * as React from 'react'

function SvgSquareCheckbox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M24 26H0V2a2 2 0 012-2h22a2 2 0 012 2v22a2 2 0 01-2 2zM2 24h22V2H2v22z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSquareCheckbox
