import React, { useState } from 'react'
import { DownloadCSVProps } from './types'
import useGroups from 'hooks/useGroups'
import useApps from 'hooks/useApps'
import moment from 'moment'

import DownloadCSVModal from './DownloadCSVModal'

function DownloadCSV(props: DownloadCSVProps) {
  const { app } = useApps()
  const { currentGroup } = useGroups()
  const isoTimestamp = moment().toISOString(true)
  const formattedTimestamp = isoTimestamp.slice(0, isoTimestamp.length - 6)
  const [entityName] = useState(currentGroup ? currentGroup.description : `${app.name}-${app.type}`)
  const [fileName] = useState(
    `${entityName?.replace(/ /g, '_')}_${formattedTimestamp}_${props.userID}`
  )

  return (
    <DownloadCSVModal
      key={`exportFromAPI${Math.floor(Math.random() * 100000 + 1)}`}
      {...props}
      entityName={entityName}
      fileName={fileName}
    />
  )
}

export default DownloadCSV
