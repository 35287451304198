import * as React from 'react'

function SvgServiceAltF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 17" {...props}>
      <path
        d="M26.67.51a2.993 2.993 0 00-4.13.78l-2.17 2.89A2.993 2.993 0 0018 3H8.82A3.01 3.01 0 006 1H3a2.996 2.996 0 00-3 3v13h6a3.01 3.01 0 002.82-2H19a3.006 3.006 0 002.5-1.34l5.94-8.91A3.13 3.13 0 0028 2.97 2.971 2.971 0 0026.67.51zm-.84 3.05l-6 9A1.01 1.01 0 0119 13H9V5h9a1 1 0 010 2h-4v2h4a2.97 2.97 0 002.55-1.45 20.57 20.57 0 011.13-1.78l2.49-3.32a.957.957 0 01.64-.43c.26-.05.53.004.75.15a.978.978 0 01.42.64c.051.26-.003.53-.15.75z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgServiceAltF
