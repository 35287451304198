import React from 'react'
import Tooltip from 'components/shared/Tooltip'
import Typography from 'components/shared/typography'
import Icon from 'components/shared/icon'
import * as S from './styled'

function UniqueUserWarning() {
  return (
    <Tooltip title="This chart may be affected by the Recent Billing Account Migration.">
      <S.WarningContent>
        <Icon name="CautionAlertF" color="red-20" size="sm" />
        <Typography variant="caption" color="red-20" noMargin>
          Data Warning
        </Typography>
        <S.UniqueUserWarningLink to="/documentation/prismdocs/Account-Number-Migrations">
          (Read more here)
        </S.UniqueUserWarningLink>
      </S.WarningContent>
    </Tooltip>
  )
}

export default UniqueUserWarning
