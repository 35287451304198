import * as React from 'react'

function SvgAirplayAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 24" {...props}>
      <path
        d="M13 15.586L21.414 24H4.586L13 15.586zM13 .004c7.18 0 13 5.82 13 13a12.878 12.878 0 01-3.814 9.19l-1.411-1.419A10.888 10.888 0 0024 13.004c0-6.075-4.924-11-11-11-6.075 0-11 4.925-11 11a10.888 10.888 0 003.226 7.771l-1.411 1.418A12.875 12.875 0 010 13.003c0-7.179 5.82-13 13-13zm0 18.41L9.414 22h7.172L13 18.414zm0-14.71a9.31 9.31 0 019.31 9.3 9.208 9.208 0 01-2.735 6.58l-1.41-1.42a7.22 7.22 0 002.145-5.16 7.31 7.31 0 00-14.62 0 7.22 7.22 0 002.145 5.16l-1.41 1.42a9.208 9.208 0 01-2.735-6.58 9.31 9.31 0 019.31-9.3zm3.534 5.764a5 5 0 01.008 7.068l-1.423-1.404a3 3 0 10-4.237 0l-1.423 1.404a5 5 0 017.075-7.068z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAirplayAlt
