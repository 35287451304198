import React from 'react'
import Typography from 'components/shared/typography/Typography'
import * as S from '../styles'

const IndentedHeading = ({ indent, children }: { indent?: boolean; children: React.ReactNode }) => {
  return indent ? <S.SmallHeading>{children}</S.SmallHeading> : <div>{children}</div>
}

const SmallerHeading = ({
  title,
  indent,
  dataTest,
}: {
  title: string
  indent?: boolean
  dataTest?: string
}) => (
  <S.SmallHeading>
    <IndentedHeading indent={indent}>
      <Typography variant="eyebrow" noMargin data-test={dataTest ? dataTest : 'smallerHeading'}>
        {title}
      </Typography>
    </IndentedHeading>
  </S.SmallHeading>
)

export default SmallerHeading
