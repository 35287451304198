import React from 'react'
import * as S from './styled'
import { getColor } from './utils'

function CustomNode({ node }: any) {
  return (
    <S.Node fill={getColor(node.successRate)}>
      {Number.parseFloat(node.successRate).toFixed(1).replace(/[.]0$/, '')}%
    </S.Node>
  )
}

export default CustomNode
