import * as React from 'react'

function SvgArrowDownLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M25.707 1.707L24.293.293 2 22.586V7H0v19h19v-2H3.414z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgArrowDownLeft
