import * as React from 'react'

function SvgPersonF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 25v1H0v-1a13.025 13.025 0 018.91-12.34 7 7 0 118.18 0A13.025 13.025 0 0126 25z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPersonF
