import React, { useState, useEffect, useRef } from 'react'
import { Location, WindowLocation } from '@reach/router'
import { navigate } from '@reach/router'
import queryString from 'query-string'
import get from 'lodash/get'
import keys from 'lodash/keys'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import numeral from 'numeral'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import NucleusReactTopChart from './NucleusReactTopChart'
import { limitTopData } from 'utils'
import { getDataWithoutColumnNullValues } from './utils'
import * as S from './styled'

function TopChart({
  className,
  id,
  style,
  title = 'Top Chart',
  titles,
  data,
  mini,
  showTitles = true,
  showTitle = true,
  showSearch = false,
  isBubbleData,
  hoveredItemPath,
  firstRowPathObj,
  limit,
  source,
  isActive,
  columnWidths,
  extendContent,
  removeColumnsWithoutValues,
  filterValuesToTrim,
  ...props
}: {
  className?: string
  id?: string
  style?: object
  title?: string
  titles?: Array<string | { apiTitle: string; title: string }>
  data: any
  mini?: boolean
  showTitles?: boolean
  showTitle?: boolean
  showSearch?: boolean
  isBubbleData?: boolean
  hoveredItemPath?: string[]
  firstRowPathObj?: {
    pathName: string
    queryParam: string
    queryParamFromUrl?: string
    customQueryParams?: any
    queryParams?: {
      queryParam: string
      tableCellId: string
    }[]
  }
  rowItemClick?: Function
  rowItemIsClickable?: boolean
  limit?: number
  source?: string
  isActive?: boolean
  columnWidths?: string[]
  extendContent?: boolean
  removeColumnsWithoutValues?: boolean
  filterValuesToTrim?: string[]
}) {
  const [chartContainerHeight, setChartContainerHeight] = useState(0)
  const chartContainerRef = useRef(null)

  // If chart container's height set to fit-content, need to compensate left over space in cases content can't fill the chart container
  useEffect(() => {
    if (extendContent) {
      const actualChartHeight =
        (chartContainerRef &&
          chartContainerRef?.current &&
          get(
            chartContainerRef,
            'current.parentElement.parentElement.parentElement.clientHeight'
          )) ||
        0

      const currentChartContainerHeight =
        (chartContainerRef &&
          chartContainerRef?.current &&
          get(chartContainerRef, 'current.parentElement.parentElement.clientHeight')) ||
        0

      const topChartHeight =
        (chartContainerRef &&
          chartContainerRef?.current &&
          get(chartContainerRef, 'current.parentElement.clientHeight')) ||
        0

      const leftOverSpace = actualChartHeight - currentChartContainerHeight
      if (leftOverSpace > 0) {
        const containerBorder = 2
        setChartContainerHeight(leftOverSpace + topChartHeight + containerBorder)
      } else setChartContainerHeight(0)
    }
  }, [data, extendContent])

  if (mini) {
    return (
      <S.MiniContainer className={className} id={id} style={style}>
        <div>
          <h4>{title}</h4>
        </div>
      </S.MiniContainer>
    )
  }

  function handleFirstRowClick(
    clickedItemName: string,
    location: WindowLocation,
    row: Record<string, any>
  ) {
    const query = queryString.parse((location && location.search) || '')

    if (firstRowPathObj?.queryParams) {
      const extraQueryParamFromUrl = firstRowPathObj?.queryParamFromUrl || ''
      const newQuery = firstRowPathObj?.queryParams?.reduce(
        (
          acc: Record<string, any>,
          param: { queryParam: string; tableCellId: string; quotationMarksForString?: boolean }
        ) => {
          const rowValue = row[param.tableCellId]

          if (rowValue && param.quotationMarksForString) {
            acc[param.queryParam] = `${rowValue}`
          } else if (rowValue) {
            acc[param.queryParam] = rowValue
          }
          return acc
        },
        {}
      )

      const newQueryStringified = queryString.stringify(
        { ...query, ...newQuery, [extraQueryParamFromUrl]: query[extraQueryParamFromUrl as any] },
        { encode: true }
      )

      navigate && navigate(`${firstRowPathObj.pathName}?${newQueryStringified}`)
      return
    }

    if (firstRowPathObj?.customQueryParams) {
      const newQuery = queryString.stringify(
        {
          ...query,
          ...firstRowPathObj?.customQueryParams[clickedItemName],
        },
        { encode: false }
      )
      navigate(`${firstRowPathObj.pathName}?${newQuery}`)
      return
    }

    if (firstRowPathObj) {
      const extraQueryParamFromUrl = firstRowPathObj?.queryParamFromUrl || ''

      const queryParam = firstRowPathObj?.queryParam
        ? { [firstRowPathObj?.queryParam as any]: clickedItemName }
        : {}

      const newQuery = queryString.stringify(
        {
          ...query,
          ...queryParam,
          [extraQueryParamFromUrl]: query[extraQueryParamFromUrl as any],
        },
        { encode: false }
      )

      navigate(`${firstRowPathObj.pathName.replace(':id', clickedItemName)}?${newQuery}`)
    }
  }

  const aggrQuery = Boolean(data.aggrData)
  const defaultTitles = isBubbleData && ['Group', 'Name', 'Count']
  const columnTitles = titles || defaultTitles

   function checkForReplaceValue(value?: Nullable<string>) {
    switch (value) {
      case null:
        return 'other'
      default:
        return value
    }
  }

  function checkIfValueWasReplaced(value?: string) {
    switch (value) {
      case 'Null':
        return 'other'
      default:
        return value
    }
  }

  const getDataWithTitles = (data: any) => {
    if (aggrQuery) {
      const dataWithAggr = data?.topData?.map((item: any) => {
        const chartLabels = keys(item)
        const countLabel =
          chartLabels.filter((label: string) => typeof item[label] === 'number')[0] || 0
          
        return {
          ...item,
          '% of Total': numeral(item[countLabel] / data.aggrData.total).format('0.00 %'),
        }
      })

      return dataWithAggr
    } else if (has(data, 'topData')) {
      return data?.topData
    } else return data
  }

  const chartData = getDataWithTitles(data)
  const filteredChartData = removeColumnsWithoutValues
    ? getDataWithoutColumnNullValues(chartData)
    : chartData
  const dataWithTitles = columnTitles
    ? filteredChartData?.map((item: any) => {
        if (isBubbleData) {
          if (aggrQuery) {
            return {
              [columnTitles[0] as string]: String(checkForReplaceValue(item.group)),
              [columnTitles[1] as string]: String(checkForReplaceValue(item.name)),
              [columnTitles[2] as string]: String(checkForReplaceValue(item.value)),
              '% of Total': item['% of Total'],
            }
          } else {
            return {
              [columnTitles[0] as string]: String(checkForReplaceValue(item.group)),
              [columnTitles[1] as string]: String(checkForReplaceValue(item.name)),
              [columnTitles[2] as string]: String(checkForReplaceValue(item.value)),
            }
          }
        }
        const chartDataObj = columnTitles.reduce((acc: any, columnTitle: any) => {
          const title = columnTitle.title || columnTitle
          const apiTitle = columnTitle.apiTitle || columnTitle
          acc[title] = String(checkForReplaceValue(item[apiTitle]))
          return acc
        }, {})

        if (aggrQuery) {
          return {
            ...chartDataObj,
            '% of Total': item['% of Total'],
          }
        }
        return {
          ...chartDataObj,
        }
      })
    : filteredChartData?.map((item: any) => {
        const dataTitles = Object.keys(item)
        const firstRowTitle = dataTitles[0]
        return {
          ...item,
          [firstRowTitle]: checkForReplaceValue(item[firstRowTitle]),
        }
      })

  if (isEmpty(filteredChartData)) {
    return <NoDataChartMessage title={(showTitle && title) || undefined} />
  }

  const limitedData = limitTopData(dataWithTitles, limit, source) || []

  return (
    <Location>
      {({ location }) => (
        <S.Container
          className={className}
          id={id}
          style={style}
          height={chartContainerHeight}
          ref={chartContainerRef}
        >
          {showTitle && <h4>{title}</h4>}
          <NucleusReactTopChart
            key={`Top_Chart_${title}_${id}_${showTitles}_${hoveredItemPath}`}
            data={isArray(limitedData) ? limitedData : []}
            showTitle={showTitles}
            // hoveredItemPath={hoveredItemPath}
            rowItemClick={(clickedItemName: string, row: Record<string, any>) =>
              handleFirstRowClick(checkIfValueWasReplaced(clickedItemName) as string, location, row)
            }
            rowItemIsClickable={!!firstRowPathObj?.pathName}
            columnWidths={columnWidths}
            filterValuesToTrim={filterValuesToTrim}
            {...props}
          />
        </S.Container>
      )}
    </Location>
  )
}

export default TopChart
