import styled, { css } from 'styled-components'
import { isNil } from 'lodash'

interface FlexProps {
  $inline?: boolean
  $width?: string
  $height?: string
  $minWidth?: string
  $minHeight?: string
  $maxWidth?: string
  $maxHeight?: string
  $border?: string
  $direction: string
  $wrap: boolean
  $backgroundColor?: string
  $flex?: string | number
  $justifyContent?: string
  $alignContent?: string
  $justifyItems?: string
  $alignItems?: string
  $marginBetween?: string
  $padding?: string
  $position?: string
  $zIndex?: string | number
  $margin?: string
  $center?: boolean
  $top?: string | number
  $bottom?: string | number
  $left?: string | number
  $right?: string | number
  $gap?: string
  $grow?: string | number
}

const flexStyles = css<FlexProps>`
  box-sizing: border-box;
  display: ${({ $inline }) => ($inline ? 'inline-flex' : 'flex')};
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `};
  ${({ $height }) =>
    $height &&
    css`
      height: ${$height};
    `};
  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth};
    `};
  ${({ $minHeight }) =>
    $minHeight &&
    css`
      min-height: ${$minHeight};
    `};
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `};
  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight};
    `};
  ${({ $border }) =>
    $border &&
    css`
      border: ${$border};
    `};
  flex-direction: ${({ $direction }) => $direction};
  flex-wrap: ${({ $wrap }) => ($wrap ? 'wrap' : 'nowrap')};
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};
  ${({ $flex }) =>
    $flex &&
    css`
      flex: ${$flex};
    `};
  ${({ $direction }) =>
    $direction &&
    css`
      flex-direction: ${$direction};
    `};
  ${({ $justifyContent, $center }) =>
    ($justifyContent || $center) &&
    css`
      justify-content: ${$justifyContent || 'center'};
    `};
  ${({ $alignContent, $center }) =>
    ($alignContent || $center) &&
    css`
      align-content: ${$alignContent || 'center'};
    `};
  ${({ $justifyItems, $center }) =>
    ($justifyItems || $center) &&
    css`
      justify-items: ${$justifyItems || 'center'};
    `};
  ${({ $alignItems, $center }) =>
    ($alignItems || $center) &&
    css`
      align-items: ${$alignItems || 'center'};
    `};
  ${({ $marginBetween, $direction }) =>
    $marginBetween && $direction.includes('row')
      ? css`
          > *:not(:last-child) {
            margin-right: ${$marginBetween};
          }
        `
      : css`
          > *:not(:last-child) {
            margin-bottom: ${$marginBetween};
          }
        `};
  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${$padding};
    `};
  ${({ $margin }) =>
    $margin &&
    css`
      margin: ${$margin};
    `};
  ${({ $position }) =>
    $position &&
    css`
      position: ${$position};
    `};
  ${({ $zIndex }) =>
    $zIndex &&
    css`
      z-index: ${$zIndex};
    `};
  ${({ $top }) =>
    !isNil($top) &&
    css`
      top: ${$top};
    `};
  ${({ $bottom }) =>
    !isNil($bottom) &&
    css`
      bottom: ${$bottom};
    `};
  ${({ $left }) =>
    !isNil($left) &&
    css`
      left: ${$left};
    `};
  ${({ $right }) =>
    !isNil($right) &&
    css`
      right: ${$right};
    `};
  ${({ $gap }) =>
    !isNil($gap) &&
    css`
      gap: ${$gap};
    `};
  ${({ $grow }) =>
    !isNil($grow) &&
    css`
      flex-grow: ${$grow};
    `};
`

export const Div = styled.div<FlexProps>`
  ${flexStyles};
`

export const Span = styled.span<FlexProps>`
  ${flexStyles};
`
