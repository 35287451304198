import * as React from 'react'

function SvgChevronDownCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.732 0 14 6.268 14 14-.009 7.728-6.272 13.99-14 14-7.732 0-14-6.268-14-14S6.268 0 14 0zm0 2C7.373 2 2 7.372 2 14c0 6.627 5.373 12 12 12s12-5.373 12-12c-.007-6.625-5.376-11.993-12-12zm5.793 8.542l1.414 1.415L14 19.164l-7.207-7.207 1.414-1.415L14 16.335l5.793-5.793z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronDownCircle
