import React from 'react'
import styled from 'styled-components'

export const Container = styled(
  ({ mini, ...otherProps }: { mini?: boolean; [key: string]: any }) => <div {...otherProps} />
)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Percentage = styled.p`
  font-family: 'Open Sans';
`
