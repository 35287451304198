import * as React from 'react'

function SvgChevronUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 16" {...props}>
      <path
        d="M.293 14.146L14 .439l13.707 13.707-1.414 1.415L14 3.268 1.707 15.561z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronUp
