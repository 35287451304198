import * as React from 'react'

function SvgModemF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 28" {...props}>
      <path
        d="M12 26V3a3.003 3.003 0 00-3-3H5a3.003 3.003 0 00-3 3v23H0v2h14v-2h-2zm-4-5H6v-2h2v2zm0-4H6v-2h2v2zm0-4H6v-2h2v2zm0-4H6V7h2v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgModemF
