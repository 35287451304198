import * as React from 'react'

function SvgRecordSeriesReminder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 14" {...props}>
      <path
        d="M21 0a7 7 0 010 14H7A7 7 0 017 0zM7 2a5 5 0 100 10A5 5 0 007 2zm7 0h-2.105l.028.03c.074.075.143.154.214.231.082.09.166.176.244.269.065.078.124.163.186.245.075.098.152.193.222.295.058.085.108.175.162.263.065.105.133.208.193.317.05.091.092.188.138.282.054.11.112.219.16.332.043.101.077.207.116.311.041.111.087.22.122.334.037.116.063.237.093.355.028.106.06.21.082.319.03.14.046.284.067.427.013.09.032.18.041.27.05.479.05.961 0 1.44-.01.09-.028.18-.041.27-.02.143-.038.287-.067.427-.022.108-.054.212-.082.319-.03.118-.056.239-.093.355-.035.114-.08.223-.122.334-.039.104-.073.21-.116.311-.048.114-.106.222-.16.332-.046.094-.088.19-.138.282-.06.11-.128.212-.193.318-.054.087-.104.177-.162.262-.07.101-.147.197-.221.295-.063.082-.122.167-.187.246-.078.093-.162.18-.244.268-.071.077-.14.157-.214.23l-.028.031H14a5 5 0 000-10zm7 0h-2.11a6.978 6.978 0 010 10H21a5 5 0 000-10zM8 4v2h2v2H6V4h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRecordSeriesReminder
