import React from 'react'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { navigate } from '@reach/router'
import ErrorMessage from 'components/shared/ErrorMessage'
import { useGetTechTypeOptions, getAvailableFilters } from 'components/shared/FilterMenu/common'
import { common } from 'components/shared/FilterMenu'
import ScreenContentContainer from 'components/layout/ScreenContentContainer'
import { ScreenProps } from 'screens'
import useHeader from 'hooks/useHeader'
import { Button } from 'components/shared/Button'
import Icon from 'components/shared/icon/Icon'
import { colors } from 'components/shared/utils/colors'
import useApps from 'hooks/useApps'
import useGroups from 'hooks/useGroups'
import * as S from './styles'

const oldUrlRegex = /\/application\/([A-Za-z-]+)\?.*appID.*/

const renderNewUrl = (oldUrl: string) => {
  const page = oldUrl.match(oldUrlRegex)?.[1]
  const query = oldUrl.split('?')[1]
  const queryObj = queryString.parse(`?${query}`)
  const newQuery = queryString.stringify(omit(queryObj, 'appID'))

  const newUrl = `/${queryObj.appID}/client-analytics/${page}?${newQuery}`
  return (
    <S.ErrorMessageOldUrlLink onClick={() => navigate(newUrl)}>{newUrl}</S.ErrorMessageOldUrlLink>
  )
}

function NotFoundScreen({
  location,
  copy = 'You seem to have gotten lost. Try clicking on an item in the sidebar to find your way.',
  title = '404 - Page Not Found',
  error = false,
}: NotFoundScreenProps) {
  const { currentDomains, currentApps } = useApps()
  const { currentGroup } = useGroups()
  const { options: techTypeOptions } = useGetTechTypeOptions()
  const availableFilters = getAvailableFilters(
    currentApps,
    currentDomains,
    currentGroup,
    'eventsDetails'
  )
  const filters = !isEmpty(techTypeOptions)
    ? availableFilters.concat(common.filters.TECHNOLOGY_TYPE)
    : availableFilters
  useHeader({ title, filters, pageName: 'notFoundPage' })
  const isOldUrl = oldUrlRegex.test(location?.href || '')

  return (
    <ScreenContentContainer data-pho="notFoundScreen">
      <S.ErrorMessageContainer>
        {!isOldUrl && (
          <ErrorMessage
            title={title}
            copy={copy}
            mailToDetails={{
              email: 'DP-Prism@charter.com',
              subject: 'Not Found Error',
              text: 'Please message the Tools & Testing Team if this page is not working for you.',
              body: copy,
            }}
            errorMessage="Not Found Error"
            includeMailTo
          />
        )}
        {isOldUrl && (
          <>
            <Icon name="Upgrade" color={colors.gray30} />
            <S.UpdateTitle>Update Required</S.UpdateTitle>
            <S.ErrorMessageOldUrl>
              <p>
                We have migrated our URLs to a new format. You are likely trying to visit the page
                below. Please update your bookmarks accordingly.
              </p>
              {renderNewUrl(location?.href || '')}
            </S.ErrorMessageOldUrl>
          </>
        )}
        {error && (
          <>
            <S.ErrorMessageReport>
              If the error persists please report the issue by creating a ticket
            </S.ErrorMessageReport>
            <S.IssueButton>
              <Button
                onClick={() => {
                  window.open(
                    'https://jira.charter.com/secure/CreateIssue.jspa?pid=74201&issuetype=1',
                    '_blank'
                  )
                }}
              >
                Open Issue
              </Button>
            </S.IssueButton>
          </>
        )}
      </S.ErrorMessageContainer>
    </ScreenContentContainer>
  )
}

interface NotFoundScreenProps extends ScreenProps {
  title?: string
  copy?: string
  error?: boolean
}

export default NotFoundScreen
