import * as React from 'react'

function SvgX(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <path
        d="M21.707 1.707L20.293.293 11 9.586 1.707.293.293 1.707 9.586 11 .293 20.293l1.414 1.414L11 12.414l9.293 9.293 1.414-1.414L12.414 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgX
