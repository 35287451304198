import * as React from 'react'

function SvgCalendarF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M22 3V0h-2v3H6V0H4v3H0v20a3.009 3.009 0 003 3h20a3.009 3.009 0 003-3V3h-4zm-1 18h-4v-4h4v4zm3-11H2V8h22v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCalendarF
