import pick from 'lodash/pick'
import reduce from 'lodash/reduce'
import lowerFirst from 'lodash/lowerFirst'
import { ChartObj } from 'components/shared/chart/types'

export function filterCharts(
  charts: ChartObj[],
  current: {
    dataSources?: string[]
    domains?: string[]
    group?: string
    apps?: string[]
    appNames?: string[]
    appTypes?: string[]
    errorTypes?: string[]
  }
): ChartObj[] {
  return charts.filter(chart => {
    const filters = pick(chart, [
      'dataSources',
      'excludeDataSources',
      'domains',
      'excludeDomains',
      'groups',
      'excludeGroups',
      'apps',
      'excludeApps',
      'appNames',
      'excludeAppNames',
      'appTypes',
      'excludeAppTypes',
      'errorTypes',
      'excludeErrorTypes',
    ])
    const shouldKeep = reduce(
      filters,
      (accum, filter: unknown, key) => {
        if (key.includes('exclude')) {
          return (
            accum &&
            !(filter as string[]).some((filterItem: string) =>
              current[lowerFirst(key.replace('exclude', ''))]?.includes(filterItem)
            )
          )
        }
        return (
          accum &&
          (filter as string[]).some((filterItem: string) => current[key]?.includes(filterItem))
        )
      },
      true
    )
    return shouldKeep
  })
}
