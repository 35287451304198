import React from 'react'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import usePrismUser from 'hooks/usePrismUser'
import useGroups from 'hooks/useGroups'
import useApps from 'hooks/useApps'
import { Container } from '../Auth'
import PrismUser from './PrismUser'
import Groups from './Groups'
import Apps from './Apps'
import VariantNames from './VariantNames'

function Data({ children }: { children: React.ReactNode }) {
  return (
    <PrismUser>
      <Groups>
        <Apps>
          <Consumer>
            <VariantNames>{children}</VariantNames>
          </Consumer>
        </Apps>
      </Groups>
    </PrismUser>
  )
}

function Consumer({ children }: { children: React.ReactNode }) {
  const { favorites } = usePrismUser()
  const { groups } = useGroups()
  const { apps, currentApps } = useApps()

  const isSurveyPage = window.location.pathname.slice(0, 7) === '/survey'

  if (isSurveyPage || (favorites && groups.length > 0 && apps.length > 0 && currentApps)) {
    return <>{children}</>
  }
  return (
    <Container>
      <LoadingSpinner />
    </Container>
  )
}

export default Data
