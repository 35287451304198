import * as React from 'react'

function SvgKite(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M13 19l-6-6 6-6 6 6-6 6zm-3-6l3 3 3-3-3-3-3 3zm3 13L0 13 13 0l13 13-13 13zM3 13l10 10 10-10L13 3 3 13z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgKite
