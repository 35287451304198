import React, { useCallback, useEffect, useState } from 'react'
import * as S from './styles'
import { debounce } from 'lodash'
import { useDarkMode } from 'components/App/Providers/DarkMode'
import Typography from 'components/shared/typography'
import { colors } from 'components/shared/utils/colors'

const ESCAPE_KEY_CODE = 27

export interface TooltipProps {
  /** The content that will activate the tooltip */
  children?: React.ReactNode
  /** The content for the tooltip */
  title: string | number | React.ReactNode
  /** The background color of the tooltip */
  backgroundColor?: string
  /** The content padding inside the tooltip container */
  padding?: string
  /** The placement for the tooltip. Defaults to top */
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  /** Allows the tooltip to be used as a controlled component*/
  open?: boolean
  /** Callback function that is triggered when the tooltip is opened*/
  onClose?: (event: React.MouseEvent<HTMLDivElement> | KeyboardEvent) => void
  /** Callback function that is triggered when the tooltip is closed*/
  onOpen?: (event: React.MouseEvent<HTMLDivElement>) => void
  /** The minimum width for the tooltip container */
  minWidth?: number
  dataTest?: string
}

export default ({
  children,
  title,
  placement = 'top',
  open,
  onOpen,
  onClose,
  minWidth = 0,
  dataTest,
}: TooltipProps) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [shouldDisplay, setShouldDisplay] = useState(false)
  const { isDarkMode } = useDarkMode()
  const shouldTrulyDisplay = open === undefined ? shouldDisplay : open

  const handleOnMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setShouldDisplay(true)
      onOpen && onOpen(event)
    },
    [onOpen]
  )

  const handleOnMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setShouldDisplay(false)
      onClose && onClose(event)
    },
    [onClose]
  )

  const handlePressEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        setShouldDisplay(false)
        onClose && onClose(event)
      }
    },
    [onClose]
  )

  const measuredRef = useCallback(node => {
    if (node !== null) {
      window.addEventListener(
        'resize',
        debounce(
          () => {
            setWidth(node.getBoundingClientRect().width)
            setHeight(node.getBoundingClientRect().height)
          },
          1000,
          { maxWait: 1000 }
        )
      )
      setWidth(node.getBoundingClientRect().width)
      setHeight(node.getBoundingClientRect().height)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handlePressEscape, false)

    return () => {
      document.removeEventListener('keydown', handlePressEscape, false)
    }
  }, [])

  return (
    <div
      ref={measuredRef}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      style={{ position: 'relative' }}
    >
      <S.TooltipContainer $width={getWidth({ calculatedWidth: width, minWidth })} $height={height}>
        <S.StyledTooltip
          $isDarkMode={isDarkMode}
          $shouldDisplay={shouldTrulyDisplay}
          $placement={placement}
        >
          {['string', 'number'].includes(typeof title) ? (
            <Typography
              color={colors.white}
              variant="caption"
              scale="small"
              noMargin
              data-test={dataTest}
            >
              {title}
            </Typography>
          ) : (
            title
          )}
        </S.StyledTooltip>
      </S.TooltipContainer>
      {children}
    </div>
  )
}

const getWidth = ({
  calculatedWidth,
  minWidth,
}: {
  calculatedWidth: number
  minWidth: number
}): number => (calculatedWidth < minWidth ? minWidth : calculatedWidth)
