import React, { ReactNode } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { colors } from 'components/shared/utils/colors'
import { element, section } from 'components/shared/utils/spacing'
import { timingFunctions, durations } from 'components/shared/utils/transition'
import { ThemeGlobalStyle } from 'components/App/Providers/Theme'
import { breakpoints, mediaQueries } from 'components/shared/utils/breakpoints'
import { useDarkMode } from '../DarkMode'

interface ThemeProps {
  children: ReactNode
  overrides?: Record<string, unknown>
}

export const theme = {
  colors,
  element,
  section,
  timingFunctions,
  durations,
  breakpoints,
  mediaQueries,
  isDarkMode: false,
}

export type Theme = typeof theme

export const ThemeProvider = ({ children, overrides = {} }: ThemeProps) => {
  const { isDarkMode } = useDarkMode()
  return (
    <SCThemeProvider theme={{ ...theme, isDarkMode, ...overrides }}>
      <ThemeGlobalStyle />
      {children}
    </SCThemeProvider>
  )
}
