import * as React from 'react'

function SvgLiveTvF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 0H3a3.009 3.009 0 00-3 3v14a3.009 3.009 0 003 3h10v2H9a2 2 0 00-2 2h14a2 2 0 00-2-2h-4v-2h10a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3zM11 14.01V5.99L17.99 10 11 14.01z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLiveTvF
