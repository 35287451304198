function MetricTooltipTitle({ fieldValue, userSelectedCategory }: MetricTooltipTitleProps): any {
  const fieldLabels = String(userSelectedCategory).split(', ')
  const fieldValues = String(fieldValue).split(', ')

  function getAverage(values: string[]) {
    const sum = values.reduce((acc, value) => acc + Number(value), 0)
    return Math.round((sum / values.length) * 100) / 100
  }

  return fieldValues.map((value, index) => {
    const valueRow = (
      <div>
        {value} - {fieldLabels[index]}
      </div>
    )

    if (fieldValues.length === 1) {
      return valueRow
    }

    if (index === 0) {
      return (
        <>
          <div>{getAverage(fieldValues)} (AVERAGE)</div>
          <br />
          {valueRow}
          <br />
        </>
      )
    }

    return index === fieldValues.length - 1 ? (
      valueRow
    ) : (
      <>
        {valueRow}
        <br />
      </>
    )
  })
}

interface MetricTooltipTitleProps {
  fieldValue: string | number
  userSelectedCategory: string
}

export default MetricTooltipTitle
