import { Filter } from 'components/shared/FilterMenu/types'

export interface Step {
  value: number
  step: any
  label: string
}

export enum periodType {
  hoursAndDays = 'hoursAndDays',
  completeMonths = 'completeMonths',
  absoluteWeeks = 'absoluteWeeks',
  absoluteMonths = 'absoluteMonths',
  absoluteDays = 'absoluteDays',
}

export interface Period {
  label: string
  type: periodType
  value: any
  valueInHrs: number
  periodOffset?: number
  steps?: Step[]
}

export interface TimeMenuProps {
  filters?: Filter[]
  periods?: Period[]
  values: Record<string, any>
  updateValues: (newVals: Record<string, any>) => void
  hideStepSlider?: boolean
  displayFiscalFilter?: boolean
  displayTimeZoneFilter?: boolean
  suppressPopover?: boolean
  absoluteTimeRangeLimit?: number
}

export interface TimeValue {
  label: string
  type: string
  value: string
  valueInHrs: number
}

export interface SelectedRange {
  from: string | Date
  to: string | Date
}
