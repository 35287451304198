import React from 'react'
import { useLocation } from '@reach/router'
import isNull from 'lodash/isNull'

const scroll = (container?: HTMLElement | null, posX: number = 0, posY: number = 0) => {
  if (isNull(container)) return
  container?.scrollTo?.(posX, posY)
}

const getContentContainer = () => document.getElementById('content-container')

const scrollToTopOfContentContainer = () => scroll(getContentContainer())

function ScrollToTop({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation()

  React.useEffect(() => {
    scrollToTopOfContentContainer()
  }, [pathname])

  return <>{children}</>
}

export default ScrollToTop
