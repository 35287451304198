import * as React from 'react'

function SvgCollapse(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 12v-2h-8.581l7.583-7.583-1.414-1.415L16 8.591V0h-2v12h12zM0 14v2h8.581L.998 23.583l1.414 1.415L10 17.409V26h2V14H0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCollapse
