import * as React from 'react'

function SvgSettingsF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 28" {...props}>
      <path
        d="M26 14c0-.916.458-1.771 1.22-2.28l2.13-1.42-4.03-6.71-1.67 1.11a2.752 2.752 0 01-3.17-.09l-.39-.29A2.752 2.752 0 0119 2.13V0h-8v2.13a2.752 2.752 0 01-1.09 2.19l-.39.29c-.931.697-2.2.733-3.17.09L4.68 3.59.65 10.3l2.13 1.42a2.74 2.74 0 010 4.56L.65 17.7l4.03 6.71 1.67-1.11a2.752 2.752 0 013.17.09l.39.29A2.752 2.752 0 0111 25.87V28h8v-2.13c.001-.86.404-1.67 1.09-2.19l.39-.29a2.752 2.752 0 013.17-.09l1.67 1.11 4.03-6.71-2.13-1.42A2.743 2.743 0 0126 14zm-11 5a5 5 0 110-10 5 5 0 010 10z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSettingsF
