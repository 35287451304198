import styled from 'styled-components'
import { element } from 'components/shared/utils/spacing'

export const ButtonTabContainer = styled.div<{ hasTopMargin?: any }>`
  display: flex;
  margin-top: ${props => (props.hasTopMargin ? '0.5rem' : '0')};
  gap: ${element.xs};
`

export const ActionMenuSummaryContainer = styled.div`
  display: flex;
`
