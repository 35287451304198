import React, { useState } from 'react'
import isNil from 'lodash/isNil'
import toNumber from 'lodash/toNumber'

import * as ReactDayPicker from 'react-day-picker'
import * as S from './styles'
import * as U from './utils'

function DatePicker({
  onSelectDate,
  defaultRange,
  isDoubleCalendar,
}: DatePickerProps): React.FunctionComponentElement<DatePickerProps> {
  const [from, setFrom] = useState<Date | undefined>(undefined)
  const [to, setTo] = useState<Date | undefined>(undefined)
  const [enteredTo, setEnteredTo] = useState<Date | undefined>(undefined)
  const defaultActiveMonth = U.getMonth(defaultRange, isDoubleCalendar)
  const [activeMonth, setActiveMonth] = useState<Date | undefined>(defaultActiveMonth)

  function handleDayClick(day: Date) {
    if ((from && to) || (!from && !to)) {
      setFrom(day)
      setTo(undefined)
      setEnteredTo(undefined)
      onSelectDate({ from: day, to: undefined })
    } else {
      setTo(day)
      setEnteredTo(day)
      onSelectDate({ from: from, to: day })
    }
  }

  function handleDayMouseEnter(day: Date) {
    if (from && !to) {
      setEnteredTo(day)
    }
  }

  function getSelectedDays(): Array<Date | ReactDayPicker.RangeModifier | undefined> {
    if (defaultRange) {
      return [
        { from: new Date(toNumber(defaultRange.from)), to: new Date(toNumber(defaultRange.to)) },
      ]
    }
    if (isNil(from)) return []
    if (isNil(enteredTo)) return []

    return [from, { from, to: enteredTo }]
  }

  const modifiers = { start: from, end: enteredTo }

  const selectedDays: Array<Date | ReactDayPicker.RangeModifier | undefined> = getSelectedDays()

  return (
    <S.DayPicker
      className="Range"
      selectedDays={selectedDays}
      modifiers={modifiers}
      month={activeMonth}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayMouseEnter}
      numberOfMonths={isDoubleCalendar ? 2 : 1}
      onMonthChange={month => setActiveMonth(month)}
      showOutsideDays={false}
      enableOutsideDaysClick={false}
    />
  )
}

interface DatePickerProps {
  onSelectDate: Function
  defaultRange?: { from: string; to: string }
  isDoubleCalendar?: boolean
}

export default DatePicker
export { S as Styles }
