import * as React from 'react'

function SvgRecord(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M13 26C5.82 26 0 20.18 0 13S5.82 0 13 0s13 5.82 13 13c-.008 7.176-5.824 12.992-13 13zm0-24C6.925 2 2 6.925 2 13s4.925 11 11 11 11-4.925 11-11c-.007-6.072-4.928-10.993-11-11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRecord
