import * as React from 'react'

function SvgPin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M23.125 19a11.17 11.17 0 00-5.837-9.781L16.491 2h2.634V0H4.875v2h2.634l-.797 7.219A11.17 11.17 0 00.875 19v1H11v8h2v-8h10.125v-1zM2.931 18a9.16 9.16 0 015.145-7.231l.503-.24L9.521 2h4.959l.94 8.53.504.239A9.16 9.16 0 0121.069 18H2.931z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPin
