import * as React from 'react'

function SvgFiberF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 28" {...props}>
      <path
        d="M16 20V8h-2.9a31.7 31.7 0 011.8-6.6L13.1.6a32.2 32.2 0 00-2 7.4H10V0H8v8H6.9a32.2 32.2 0 00-2-7.4l-1.8.8A31.7 31.7 0 014.9 8H2v12H0v8h18v-8h-2zm0 6H2v-4h14v4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFiberF
