import React from 'react'
import { Link } from '@reach/router'
import MuiTableRow from '@material-ui/core/TableRow'
import { sortDataByColumn } from './utils'
import * as S from './styles'
import FavoriteIcon from './favoriteIcon'
import Typography from '../typography/Typography'

export default function TableContent({
  data,
  eventCasesLink,
  sortByColumn,
  activeColumnIndex,
  sortReverse,
  columnCellFormat,
  columnAlignment,
  eventCasesLinkColumn,
  handleSelectFavorite,
  favorites,
}: TableContentProps): React.FunctionComponentElement<TableContentProps> {
  const dataToUse = sortByColumn
    ? sortDataByColumn(data, activeColumnIndex, sortReverse)
    : [...data]

  const result = dataToUse.map((schemaType: any, index: number) => {
    let mappedSchemaTypes = schemaType.map((rowItem: any, index: number) => {
      let content = rowItem
      let favoriteIcon = (
        <span style={{ zIndex: 100 }}>
          <FavoriteIcon
            handleSelectFavorite={handleSelectFavorite}
            index={index}
            content={content}
            favorites={favorites}
            dataPho={`favoriteIcon${index}`}
          />
        </span>
      )

      if (eventCasesLink && index === eventCasesLinkColumn) {
        return (
          <S.StyledMuiTableCell key={`${index}_${content}`} data-test={content}>
            <S.CellContent>
              <S.StyledCellFavorite>{favoriteIcon}</S.StyledCellFavorite>
              <Link to={`${eventCasesLink}${content}`}>
                <S.StyledCellContent key={`${schemaType}_${index}_${content}`}>
                  <Typography variant="body" noMargin>
                    {content}
                  </Typography>
                </S.StyledCellContent>
              </Link>
            </S.CellContent>
          </S.StyledMuiTableCell>
        )
      }

      return (
        <S.StyledMuiTableCell key={`${index}_${content}`} data-test={content}>
          <S.CellContent align={columnAlignment && columnAlignment[index]}>
            {favoriteIcon}
            <S.StyledCellContent>
              <Typography variant="body" noMargin>
                {columnCellFormat && columnCellFormat[index]
                  ? columnCellFormat[index](content)
                  : content}
              </Typography>
            </S.StyledCellContent>
          </S.CellContent>
        </S.StyledMuiTableCell>
      )
    })

    return (
      <MuiTableRow hover key={`${index}_${schemaType}`}>
        {mappedSchemaTypes}
      </MuiTableRow>
    )
  })
  return <>{result}</>
}

interface TableContentProps {
  data?: any
  eventCasesLink?: String
  sortByColumn: boolean
  activeColumnIndex: number
  sortReverse: boolean
  columnAlignment?: { [key: number]: string }
  columnCellFormat?: { [key: number]: Function }
  eventCasesLinkColumn: number
  handleSelectFavorite?: Function
  favorites?: string[]
}
