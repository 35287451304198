import * as React from 'react'

function SvgGrid(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M12 12H0V3a3.003 3.003 0 013-3h9v12zM2 10h8V2H3a1 1 0 00-1 1v7zm24 2H14V0h9a3.003 3.003 0 013 3v9zm-10-2h8V3a1 1 0 00-1-1h-7v8zm-4 16H0V14h12v12zM2 24h8v-8H2v8zm21 2h-9V14h12v9a3.003 3.003 0 01-3 3zm-7-2h7a1 1 0 001-1v-7h-8v8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgGrid
