import React, { Dispatch } from 'react'
import fileDownload from 'js-file-download'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import * as converter from 'json-2-csv'
import * as S from './styled'

export function onDownloadClick({ data, path, convertOptions }: DownloadProps): void {
  const fileName = path.includes('/') ? path.split('/').pop() : path
  if (!data) {
    console.error(`No Data provided for Download`)
    throw new Error(`No Data provided for Download`)
  }
  converter.json2csv(
    data,
    (error, value) => {
      if (error) {
        console.error(`Error converting JSON to CSV: ${error}`)
        throw new Error(`Error converting JSON to CSV: ${error}`)
      }
      if (!value || isEmpty(value)) {
        console.error(`Error Download: Empty`)
        throw new Error(`Error Download: Empty`)
      } else if (value) {
        fileDownload(value, `${fileName}_data.csv`)
      }
    },
    convertOptions
  )
}

function ExportButton({
  data,
  path,
  onClick,
  btnText = 'Export All',
  enabled,
  triggerDownload = false,
  setTriggerDownload,
  onError,
  convertOptions,
}: ExportButtonProps) {
  React.useEffect(() => {
    if (!isEmpty(data) && triggerDownload) {
      setTriggerDownload && setTriggerDownload(false)
      try {
        onDownloadClick({ data, path, convertOptions })
      } catch (e) {
        onError(`Error Exporting ${e}`)
      }
    }
  }, [triggerDownload, data, path, setTriggerDownload, convertOptions, onError])

  const noData = !data || (isArray(data) && data.length === 0)

  return (
    <>
      <S.AnchorButton
        data-pho="ExportBtn"
        disabled={!enabled && noData}
        onClick={() => {
          try {
            onClick ? onClick() : onDownloadClick({ data, path, convertOptions })
          } catch (e) {
            onError('Error exporting')
          }
        }}
        variant="borderless"
        icon="Download"
        iconSize="sm"
      >
        {btnText}
      </S.AnchorButton>
    </>
  )
}

export default ExportButton

interface ExportButtonProps {
  data?: Record<string, unknown>[]
  path: string
  onError: Function
  onClick?: Function
  btnText?: string
  enabled?: boolean
  triggerDownload?: boolean
  setTriggerDownload?: Dispatch<boolean>
  convertOptions?: Record<string, any>
}

interface DownloadProps {
  data?: Record<string, unknown>[]
  path: string
  convertOptions?: Record<string, any>
}
