import * as React from 'react'

function SvgLinkedin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 2v22a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h22a2 2 0 012 2zM7.75 9.732H3.84v12.417h3.91V9.732zm.298-3.964A2.254 2.254 0 105.795 8.01a2.214 2.214 0 002.253-2.243zm14.111 8.711c0-3.793-2.357-5.087-4.538-5.087a4.264 4.264 0 00-3.715 2.07h-.05v-1.72h-3.759v12.417h3.909v-6.732a2.377 2.377 0 012.297-2.668c1.097 0 1.948.617 1.948 2.618v6.782h3.908v-7.68z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLinkedin
