import moment from 'moment'
import { times } from 'utils'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'
import {
  RelativePeriods,
  getAbsoluteFromRelativePeriodVoC,
  getIsToDateYesterday,
  getRelativeValueIsValid,
} from 'utils/times/times'
import { Filter } from '../FilterMenu/types'
import * as C from './constants'
import * as T from './types'

interface TimeZone {
  label: string
  value: string
}

export const timeZoneOptions: TimeZone[] = [
  { label: 'EET (UTC+3)', value: '3' },
  { label: 'CEST (UTC+2)', value: '2' },
  { label: 'BST (UTC+1)', value: '1' },
  { label: 'GMT (UTC 0)', value: '0' },
  { label: 'EDT (UTC-4)', value: '-4' },
  { label: 'CDT (UTC-5)', value: '-5' },
  { label: 'MDT (UTC-6)', value: '-6' },
  { label: 'MST (UTC-7)', value: '-7' },
  { label: 'AKDT (UTC-8)', value: '-8' },
  { label: 'HST (UTC-10)', value: '-10' },
]

export const defaultPeriods: T.Period[] = [
  {
    label: 'Last 1 hour',
    value: RelativePeriods.LastOneHour.valueOf(),
    valueInHrs: 1,
    type: T.periodType.hoursAndDays,
    steps: [
      {
        value: 0,
        step: 60000,
        label: '1min',
      },
      {
        value: 50,
        step: 300000,
        label: '5min',
      },
      {
        value: 100,
        step: 600000,
        label: '10min',
      },
    ],
  },
  {
    label: 'Last 2 hours',
    value: RelativePeriods.LastTwoHours.valueOf(),
    valueInHrs: 2,
    type: T.periodType.hoursAndDays,
    steps: [
      {
        value: 0,
        step: 300000,
        label: '5min',
      },
      {
        value: 33,
        step: 600000,
        label: '10min',
      },
      {
        value: 66,
        step: 1800000,
        label: '30min',
      },
      {
        value: 100,
        step: 3600000,
        label: '1h',
      },
    ],
  },
  {
    label: 'Last 12 hours',
    value: RelativePeriods.LastTwelveHours.valueOf(),
    valueInHrs: 12,
    type: T.periodType.hoursAndDays,
    steps: [
      {
        value: 0,
        step: 300000,
        label: '5min',
      },
      {
        value: 33,
        step: 600000,
        label: '10min',
      },
      {
        value: 66,
        step: 1800000,
        label: '30min',
      },
      {
        value: 100,
        step: 3600000,
        label: '1h',
      },
    ],
  },
  {
    label: 'Last 24 hours',
    value: RelativePeriods.LastTwentyFourHours.valueOf(),
    valueInHrs: 24,
    type: T.periodType.hoursAndDays,
    steps: [
      {
        value: 0,
        step: 300000,
        label: '5min',
      },
      {
        value: 33,
        step: 600000,
        label: '10min',
      },
      {
        value: 66,
        step: 1800000,
        label: '30min',
      },
      {
        value: 100,
        step: 3600000,
        label: '1h',
      },
    ],
  },
  {
    label: 'Last 7 days',
    value: RelativePeriods.LastSevenDays.valueOf(),
    valueInHrs: 168,
    type: T.periodType.hoursAndDays,
    steps: [
      {
        value: 0,
        step: 1800000,
        label: '30min',
      },
      {
        value: 33,
        step: 3600000,
        label: '1h',
      },
      {
        value: 66,
        step: 43200000,
        label: '12h',
      },
      {
        value: 100,
        step: 86400000,
        label: '1d',
      },
    ],
  },
  {
    label: 'Last 14 days',
    value: RelativePeriods.LastFourteenDays.valueOf(),
    valueInHrs: 336,
    type: T.periodType.hoursAndDays,
    steps: [
      {
        value: 0,
        step: 1800000,
        label: '30min',
      },
      {
        value: 33,
        step: 3600000,
        label: '1h',
      },
      {
        value: 66,
        step: 43200000,
        label: '12h',
      },
      {
        value: 100,
        step: 86400000,
        label: '1d',
      },
    ],
  },
  {
    label: 'Last 30 days',
    value: RelativePeriods.LastThirtyDays.valueOf(),
    valueInHrs: 720,
    type: T.periodType.hoursAndDays,
    steps: [
      {
        value: 0,
        step: 43200000,
        label: '12h',
      },
      {
        value: 100,
        step: 86400000,
        label: '1d',
      },
    ],
  },
]
export function getDefaultFilters(displayTimeZoneFilter?: boolean) {
  return displayTimeZoneFilter ? defaultFilters.concat(origintzFilter) : defaultFilters
}

export const defaultFilters: Filter[] = [
  {
    id: 'partialData',
    label: 'Partial Data',
    type: 'TOGGLE',
    options: [{ value: true }, { value: false }],
    initialValue: window.localStorage.getItem('partialData') === 'true' || undefined,
    tooltip:
      'When partial data is enabled, this will show steps that have not yet completed (like the current day or hour).',
  },
  {
    id: 'fiscalTime',
    label: 'Fiscal Time',
    type: 'TOGGLE',
    options: [{ value: true }, { value: false }],
    initialValue: window.localStorage.getItem('fiscalTime') === 'true' || undefined,
    tooltip:
      'Fiscal week starts on Friday and ends on Thursday. When fiscal week is off the week starts on Sunday and ends on Saturday. Fiscal month starts on the 29th of each month and ends on 28th of the following month. When fiscal month is off the month starts on the 1st and ends on the last day of the month.',
  },
]

export const origintzFilter: Filter[] = [
  {
    id: 'origintz',
    label: 'Time Zone',
    type: 'AUTOCOMPLETE',
    initialValue:
      window.localStorage.getItem('origintz') ?? `${-new Date().getTimezoneOffset() / 60}`, // if user comes first time we are defaulting to their timezone
    options: getTimeZoneValues(),
  },
]

export function getTimeZoneLabel(timeZoneIndex: number) {
  const timeZoneOption = timeZoneOptions.find((timeZone: { label: string; value: string }) => {
    return parseInt(timeZone.value) === timeZoneIndex
  })
  return !timeZoneOption ? `(UTC ${timeZoneIndex})` : timeZoneOption?.label
}

export function getTechnologyTypes(appID: string) {
  switch (appID) {
    case 'OneApp@Roku':
    case 'BulkMDU@Roku':
      return ['Legacy', 'RSG']
    case 'OneApp@SamsungTV':
    case 'BulkMDU@SamsungTV':
      return ['Legacy', 'TVSDK']
    default:
      return null
  }
}

export function getStepOptions(options: T.Period[], period?: string): T.Step[] | undefined {
  if (period?.includes(':')) {
    const periodInHrs = times.getPeriodInHours(period)
    const closestOption = options.reduce((accum, option) => {
      const absoluteDistanceToOpt = Math.abs(option.valueInHrs - periodInHrs)
      const absoluteDistanceToAccum = Math.abs(accum.valueInHrs - periodInHrs)
      return absoluteDistanceToOpt < absoluteDistanceToAccum ? option : accum
    })
    return closestOption.steps
  }
  const matchingOption = options.find(option => option.value === period || option.label === period)
  return matchingOption?.steps
}

export function getOptionLabel(options: T.Period[], period?: string): string {
  const foundLabel = options.find(option => option.value === period)?.label
  return foundLabel || period || ''
}

export function getLabel(
  options: T.Period[],
  period?: string,
  isVoC?: boolean,
  periodOffset?: number
): string {
  if (period && period.includes(':')) {
    const [dateFrom, dateTo] = period.split(':')
    return `${moment(parseInt(dateFrom)).format('MMM Do')} - ${moment(parseInt(dateTo)).format(
      'MMM Do'
    )}`
  }
  if (period && isVoC) {
    const absolutePeriod = getAbsoluteFromRelativePeriodVoC(period, periodOffset)
    const [dateFrom, dateTo] = absolutePeriod.split(':')
    return `${moment(parseInt(dateFrom)).startOf('day').format('MMM Do')} - ${moment(
      parseInt(dateTo)
    )
      .startOf('day')
      .format('MMM Do')}`
  }
  return getOptionLabel(options, period)
}

export const genericTimeValueList = [
  [
    { label: 'Last 1 hour', value: '1h', valueInHrs: 1, type: T.periodType.hoursAndDays },
    { label: 'Last 12 hours', value: '12h', valueInHrs: 12, type: T.periodType.hoursAndDays },
    { label: 'Last 24 hours', value: '24h', valueInHrs: 24, type: T.periodType.hoursAndDays },
  ],
  [
    { label: 'Last 7 days', value: '7d', valueInHrs: 168, type: T.periodType.hoursAndDays },
    { label: 'Last 14 days', value: '14d', valueInHrs: 336, type: T.periodType.hoursAndDays },
    { label: 'Last 30 days', value: '30d', valueInHrs: 720, type: T.periodType.hoursAndDays },
  ],
]

export const defaultAbsoluteTimeSteps = {
  sm: [
    {
      value: 0,
      step: C.DAY_IN_MILLISECONDS / 24,
      label: '1h',
    },
    {
      value: 50,
      step: C.DAY_IN_MILLISECONDS / 2,
      label: '12h',
    },
    {
      value: 100,
      step: C.DAY_IN_MILLISECONDS,
      label: '1d',
    },
  ],
  md: [
    {
      value: 0,
      step: C.DAY_IN_MILLISECONDS / 2,
      label: '12h',
    },
    {
      value: 50,
      step: C.DAY_IN_MILLISECONDS,
      label: '1d',
    },
    {
      value: 100,
      step: C.DAY_IN_MILLISECONDS * 7,
      label: '7d',
    },
  ],
  lg: [
    {
      value: 0,
      step: C.DAY_IN_MILLISECONDS,
      label: '1d',
    },
    {
      value: 50,
      step: C.DAY_IN_MILLISECONDS * 7,
      label: '7d',
    },
    {
      value: 100,
      step: C.DAY_IN_MILLISECONDS * 30,
      label: '30d',
    },
  ],
  xl: [
    {
      value: 0,
      step: C.DAY_IN_MILLISECONDS * 7,
      label: '7d',
    },
    {
      value: 100,
      step: C.DAY_IN_MILLISECONDS * 30,
      label: '30d',
    },
  ],
}

export function getAbsoluteTimeStepOptions(period: string) {
  const [fromDate, toDate] = times.normalizeToAbsolutePeriod(period).split(':')
  const periodInDays = Math.round((parseInt(toDate) - parseInt(fromDate)) / C.DAY_IN_MILLISECONDS)
  if (periodInDays < 7) return defaultAbsoluteTimeSteps.sm
  if (periodInDays >= 7 && periodInDays < 30) return defaultAbsoluteTimeSteps.md
  if (periodInDays >= 30 && periodInDays < 90) return defaultAbsoluteTimeSteps.lg
  return defaultAbsoluteTimeSteps.xl
}

export function getTimeZoneValues() {
  return timeZoneOptions.map(timeZone => ({
    value: timeZone.value,
  }))
}

export function getLabelFromTimestamps(timeValueList: T.TimeValue[][], timestamp?: string): string {
  if (timestamp) {
    const relativePeriods = Object.values(times.RelativePeriods) as string[]
    if (relativePeriods.includes(timestamp)) return getRelativeLabel(timestamp, timeValueList)

    const [dateFrom, dateTo] = timestamp.split(':')

    return `${moment(parseInt(dateFrom)).format('MMM Do')} - ${moment(parseInt(dateTo)).format(
      'MMM Do'
    )}`
  }
  return ''
}

export function getRelativeLabel(period: string, timeValueList: T.TimeValue[][]): string {
  const relativeLabelsAndValues = timeValueList.flat()

  return (
    relativeLabelsAndValues.find((relativeObj: T.TimeValue) => relativeObj.value === period)
      ?.label ?? period
  )
}

export function isPeriodAbsolute(period?: string) {
  return period && period.includes(':')
}

export function getDefaultAbsoluteSelectedRange(
  selectedValues: Pick<Record<string, any>, string>,
  selectedRange?: T.SelectedRange,
  isDateInput?: boolean
) {
  const fromString = selectedRange?.from?.toString()
  const toString = selectedRange?.to?.toString()
  const fromIsInvalidDate = fromString === 'Invalid Date'
  const toIsInvalidDate = toString === 'Invalid Date'
  if (isNil(fromString) && isNil(fromString) && !selectedValues.period.includes(':')) {
    const [dateFrom, dateTo] = getAbsoluteFromRelativePeriodVoC(
      selectedValues.period,
      selectedValues?.periodOffset
    ).split(':')
    return {
      from: dateFrom,
      to: dateTo,
    }
  }
  if (selectedRange && ((!fromIsInvalidDate && !toIsInvalidDate) || isDateInput))
    return selectedRange
  if (selectedRange && (fromIsInvalidDate || toIsInvalidDate) && !isDateInput)
    return {
      from: '',
      to: '',
    }
  if (selectedValues.period.includes(':')) {
    const [dateFrom, dateTo] = selectedValues.period.split(':')
    return {
      from: dateFrom,
      to: dateTo,
    }
  }
  return
}

export function getIsPeriodSelected(
  period: T.Period,
  currentSelectedPeriod: any,
  lastFiscalWeekPeriod: string,
  currentFiscalMonthPeriod: string,
  previousFiscalMonthPeriod: string,
  yesterdayAbsolutePeriod: string,
  last10DaysAbsolutePeriod: string,
  last30DaysAbsolutePeriod: string
): boolean {
  if (period.type === 'absoluteWeeks' && typeof currentSelectedPeriod === 'string') {
    return isEqual(currentSelectedPeriod, lastFiscalWeekPeriod)
  }
  if (period.type === 'absoluteMonths' && typeof currentSelectedPeriod === 'string') {
    if (period?.periodOffset && period.periodOffset === 1)
      return isEqual(currentSelectedPeriod, previousFiscalMonthPeriod)
    else if (!period?.periodOffset || period?.periodOffset === 0)
      return isEqual(currentSelectedPeriod, currentFiscalMonthPeriod)
  }
  if (typeof currentSelectedPeriod === 'string' && period.type === T.periodType.absoluteDays) {
    if (period.value === 'yesterday') {
      return isEqual(currentSelectedPeriod, yesterdayAbsolutePeriod)
    }
    if (period.value === '10d') {
      return isEqual(currentSelectedPeriod, last10DaysAbsolutePeriod)
    }
    return isEqual(currentSelectedPeriod, last30DaysAbsolutePeriod)
  }
  return (
    isEqual(period.value, currentSelectedPeriod?.value) &&
    isEqual(period.periodOffset, currentSelectedPeriod?.periodOffset)
  )
}

export function getIsFutureNotAvailable(
  selectedValues: Pick<Record<string, any>, string>,
  todayGMT: number,
  todayUTC: number
) {
  const period = selectedValues?.period

  if (getRelativeValueIsValid(period)) return parseInt(selectedValues?.periodOffset || 0) - 1 < 0

  const [fromDate, toDate] = period.split(':')
  const periodDifference =
    Math.round((parseInt(toDate) - parseInt(fromDate)) / C.DAY_IN_MILLISECONDS) *
    C.DAY_IN_MILLISECONDS
  const newToDate = parseInt(toDate) + periodDifference

    return (
      newToDate > todayGMT - C.DAY_IN_MILLISECONDS && newToDate > todayUTC - C.DAY_IN_MILLISECONDS
    )
}

export function getTimeQuickTooltipText(
  selectedValues: Pick<Record<string, any>, string>,
  todayGMT: number,
  todayUTC: number
) {
  const futureNotAvailable = getIsFutureNotAvailable(selectedValues, todayGMT, todayUTC)
  const isToDateYesterday = getIsToDateYesterday(selectedValues?.period)

  if (futureNotAvailable && isToDateYesterday) {
    return 'Next period is out of the available range'
  } else if (futureNotAvailable) {
    return 'Next period is out of the available range'
  } else return 'Next Period'
}

export function getIsFutureDisabled(
  selectedValues: Pick<Record<string, any>, string>,
  todayGMT: number,
  todayUTC: number
) {
  const futureNotAvailable = getIsFutureNotAvailable(selectedValues, todayGMT, todayUTC)
  const isToDateYesterday = getIsToDateYesterday(selectedValues?.period)

  if (futureNotAvailable && isToDateYesterday) {
    return true
  } else if (futureNotAvailable) {
    return true
  } else return false
}

export function getDefaultTimeZoneValue(values: Record<string, any>) {
  const origintzValue = values.origintz ?? C.DEFAULT_TIME_ZONE_INDEX
  return getTimeZoneLabel(parseInt(origintzValue))
}
export { times }
