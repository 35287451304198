import * as React from 'react'

function SvgLaptopF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 22" {...props}>
      <path
        d="M26 16V0H2v16H0v3a3.009 3.009 0 003 3h22a3.009 3.009 0 003-3v-3h-2zm0 3a1.003 1.003 0 01-1 1H3a1.003 1.003 0 01-1-1v-1h24v1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLaptopF
