import * as React from 'react'

function SvgSupportF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14C27.992 6.272 21.728.008 14 0zm0 2a11.936 11.936 0 017.74 2.84L18.18 8.4a6.94 6.94 0 00-8.36 0L6.26 4.84A11.936 11.936 0 0114 2zm5 12a5 5 0 11-10 0 5 5 0 0110 0zm-5 12a11.891 11.891 0 01-7.74-2.85l3.56-3.55a6.94 6.94 0 008.36 0l3.56 3.55A11.891 11.891 0 0114 26z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSupportF
