import * as React from 'react'

function SvgCcF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 0H3a3.009 3.009 0 00-3 3v21h25a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3zM8 16h2a1.003 1.003 0 001-1h2a3.009 3.009 0 01-3 3H8a3.009 3.009 0 01-3-3V9a3.009 3.009 0 013-3h2a3.009 3.009 0 013 3h-2a1.003 1.003 0 00-1-1H8a1.003 1.003 0 00-1 1v6c.002.552.448.998 1 1zm10 0h2a1.003 1.003 0 001-1h2a3.009 3.009 0 01-3 3h-2a3.009 3.009 0 01-3-3V9a3.009 3.009 0 013-3h2a3.009 3.009 0 013 3h-2a1.003 1.003 0 00-1-1h-2a1.003 1.003 0 00-1 1v6c.002.552.448.998 1 1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCcF
