import * as React from 'react'

function SvgRewindF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 20" {...props}>
      <path
        d="M26 .21v19.57l-10-6.15v6.16L.09 10 16 .21v6.15z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRewindF
