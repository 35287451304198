import * as React from 'react'

function SvgLeafCross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 28" {...props}>
      <path
        d="M2 2l24 24-1.414 1.414-2.578-2.578a14.507 14.507 0 01-8.728 2.714A14.658 14.658 0 019 27c-.31-.17-8-4.52-8-13a14.127 14.127 0 012.349-7.823L.586 3.414 2 2zm15.168 17.996L14 23l-2.54 2.43c3.181.428 6.41-.294 9.107-2.035l-3.399-3.399zM4.817 7.645A12.083 12.083 0 003 14a13.172 13.172 0 005 10.04V10.828zM10 12.828V24.03a14.025 14.025 0 004.525-6.677L10 12.828zM9 .85l.5.28a16.486 16.486 0 016.74 8.28 20.026 20.026 0 0110.94-1.39l.68.12.12.68c.06.33 1.28 7.45-3.13 13.21l-1.44-1.44a16.176 16.176 0 002.69-10.7 17.881 17.881 0 00-9.35 1.5c.162.86.246 1.734.25 2.61a.914.914 0 01-.01.17l-2.16-2.16A13.98 13.98 0 009 3.19c-.582.406-1.133.854-1.65 1.34L5.93 3.11A16.184 16.184 0 018.5 1.13L9 .85zm11.33 13.41l1.34 1.48-1.6 1.51-1.44-1.44 1.7-1.55z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLeafCross
