import styled from 'styled-components'

export const CompareMenuContainer = styled.div`
  min-width: 36rem;
  min-height: 12rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`

export const AutocompleteContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ApplyButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
`

export const StyledAlertContainer = styled.div`
  margin-bottom: 1rem;
`
