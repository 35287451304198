import * as React from 'react'

function SvgDashboard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.732 0 14 6.268 14 14v1h-.05c-.517 7.323-6.608 13-13.95 13C6.66 28 .568 22.323.051 15h-.05v-1C0 6.268 6.268 0 14 0zm-3.858 15H2.051a12.01 12.01 0 0011.95 11A12.01 12.01 0 0025.95 15h-8.092a3.98 3.98 0 01-7.716 0zM17 21v2h-6v-2h6zm-3-9a2 2 0 100 4 2 2 0 000-4zm0-10A12.017 12.017 0 002.041 13h2.01a10 10 0 0119.899 0h2.01A12.017 12.017 0 0014 2zm6.312 7.102l-2.878 2.878c.188.318.331.662.424 1.02h4.073a7.95 7.95 0 00-1.619-3.898zm-1.414-1.414A7.98 7.98 0 006.07 13h4.072a3.955 3.955 0 015.878-2.434z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDashboard
