import * as React from 'react'

function SvgCoffeeMaker(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M14 4.067h2V6.06h-2V4.067zm6-2.073V0H4v1.994H0V28h24V1.994h-4zm-14 0h12v7.059H6V1.994zm0 24.012V24.01h12v1.995H6zm2-7.978v-.997h8v.997a3.994 3.994 0 01-4 3.989c-2.21 0-4-1.786-4-3.99zm14 7.978h-2v-3.99h-3.558a5.953 5.953 0 001.468-2.991H20v-1.994h-2v-1.995H6v2.992c0 1.474.55 2.895 1.54 3.989H4v3.989H2V3.989h2v5.064c0 1.101.895 1.994 2 1.994h5v1.995h2v-1.995h5c1.105 0 2-.893 2-1.994V3.989h2v22.017z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCoffeeMaker
