import * as React from 'react'

function SvgTicketF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M27 9h1V0H0v9h1a3 3 0 010 6H0v9h28v-9h-1a3 3 0 010-6zM11 22H9v-2h2v2zm0-4H9v-3h2v3zm0-5H9V2h2v11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTicketF
