import React from 'react'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import pickBy from 'lodash/pickBy'
import isNull from 'lodash/isNull'
import isNil from 'lodash/isNil'
import defaults from 'lodash/defaults'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import uniq from 'lodash/uniq'
import identity from 'lodash/identity'
import pick from 'lodash/pick'
import compact from 'lodash/compact'
import keys from 'lodash/keys'
import isArray from 'lodash/isArray'
import omit from 'lodash/omit'
import first from 'lodash/first'
import { WindowLocation } from '@reach/router'
import moment from 'moment'
import queryString from 'query-string'
import BarChart from 'components/shared/barChart'
import MetricBarChart from 'components/shared/barChart/MetricBarChart'
import BarWithTopChart from 'components/shared/barWithTopChart'
import HorizontalBarChart from 'components/shared/horizontalBarChart'
import LineChart from 'components/shared/lineChart'
import PercentageChart from 'components/shared/percentageChart'
import SunburstChart from 'components/shared/sunburstChart'
import SunburstWithTopChart from 'components/shared/sunburstWithTopChart'
import TopChart from 'components/shared/topChart'
import TableChart from 'components/shared/tableChart'
import MetricTable from 'components/shared/Table/Table'
import Sankey from 'components/shared/sankey'
import GridChart from 'components/shared/gridChart'
import NetworkChart from 'components/shared/NetworkChart'
import AggregateChart from 'components/shared/AggregateChart'
import StatsChart from 'components/shared/StatsChart'
import { getTimeZoneLabel } from 'components/shared/VoCTimeMenu/utils'
import * as T from './types'
import { filterTemplates } from './filterTemplates'
import { times } from 'utils'

const HOUR_IN_MILLISECONDS = 3600000

export function filterCharts({
  charts,
  app,
  currentDomains,
  currentGroup,
}: {
  charts: any
  currentDomains?: string[]
  app?: { id?: string; name?: string; type?: string }
  currentGroup?: { name: string; applicationIDs: string[] }
}) {
  return charts?.reduce((acc: any, chart: any) => {
    const chartDomain = chart?.domain
    const multiChart = chart?.charts

    if (multiChart && !chartDomain) {
      const filteredSubCharts = filter(multiChart, subChart => {
        const subChartDomain = subChart?.domain
        const appTypeFilters = subChart?.appTypeFilters

        if (!subChartDomain && !appTypeFilters) {
          return true
        }

        if (appTypeFilters) {
          if (!isEmpty(currentGroup?.applicationIDs)) {
            const allAppsIncludesFilteredAppType = currentGroup?.applicationIDs.reduce(
              (acc: boolean, filter: string) => {
                const appType = filter.split('@')[1]
                if (!appTypeFilters.includes(appType)) return false
                return acc
              },
              true
            )
            return allAppsIncludesFilteredAppType
          }
          return appTypeFilters.includes(app?.type)
        }

        if (subChartDomain) {
          return currentDomains?.includes(subChartDomain)
        }

        return true
      })

      if (isEmpty(filteredSubCharts)) return acc
      acc.push({ ...chart, charts: filteredSubCharts })
    } else if (!chartDomain) {
      acc.push(chart)
    } else if (currentDomains?.includes(chartDomain)) {
      acc.push(chart)
    }
    return acc
  }, [])
}

export function getComponent(type: T.DataType) {
  switch (type) {
    case T.DataType.LINE:
    case T.DataType.METRIC_LINE:
    case T.DataType.OS_LINE:
    case T.DataType.TECH_MOBILE_LINE:
      return LineChart
    case T.DataType.PERCENTAGE:
      return PercentageChart
    case T.DataType.TOP:
    case T.DataType.OS_TOP:
      return TopChart
    case T.DataType.SUNBURST:
    case T.DataType.OS_SUNBURST:
      return SunburstChart
    case T.DataType.SUNBURST_WITH_TOP:
    case T.DataType.OS_SUNBURST_WITH_TOP:
      return SunburstWithTopChart
    case T.DataType.OS_TABLE:
    case T.DataType.TABLE:
      return TableChart
    case T.DataType.METRIC_TABLE:
      return MetricTable
    case T.DataType.BAR:
      return BarChart
    case T.DataType.METRIC_BAR:
      return MetricBarChart
    case T.DataType.BAR_WITH_TOP:
    case T.DataType.OS_BAR_WITH_TOP:
      return BarWithTopChart
    case T.DataType.SANKEY:
    case T.DataType.OS_SANKEY:
      return Sankey
    case T.DataType.HORIZONTAL_BAR:
    case T.DataType.OS_HORIZONTAL_BAR:
      return HorizontalBarChart
    case T.DataType.GRID:
      return GridChart
    case T.DataType.NETWORK:
      return NetworkChart
    case T.DataType.METRIC_AGGREGATE:
      return AggregateChart
    case T.DataType.STATS:
      return StatsChart
    default:
      return React.Fragment
  }
}

export const filtersList = [
  'apiName',
  'appVersion',
  'browser',
  'connectionType',
  'contentClass',
  'daienabled',
  'deviceModel',
  'division',
  'drmType',
  'errorCode',
  'experimentUuids',
  'featureName',
  'formFactor',
  'legacyCompany',
  'computedMSO',
  'locationState',
  'networkStatus',
  'pageName',
  'platformType',
  'quadId',
  'responseCode',
  'operation_type',
  'streamCdn',
  'streamContentFormat',
  'streamType',
  'technologyType',
  'variantUuids',
  'applicationName',
  'applicationType',
  'applicationNameAndType',
  'httpVerb',
  'apiArchitecture',
  'apiOperationName',
  'apiOperationType',
  'billingServices',
  'activatedExperiments',
  'responseText',
  'appSection',
  'mobileStatus',
  'configurationFactors',
  'resourceID',
  'mapped_device_platform',
  'apiClusterVersion',
  'environmentName',
  'operatingSystem',
  'priority',
]

export function generateChartQueryString(
  query?: T.Query,
  filterValues?: Record<string, any>,
  chartsFilterTemplates?: string[],
  chartType?: T.DataType,
  customFilters?: Record<string, any>,
  periodOverride?: string
) {
  const filterValuesToUse = isNil(customFilters)
    ? filterValues
    : defaults({}, pickBy(customFilters, identity), filterValues)

  try {
    if (!query) {
      throw new Error('No query provided')
    } else {
      const availableFiltersList = chartsFilterTemplates
        ? chartsFilterTemplates?.reduce((acc: string[], filterTemplateName: string) => {
            return acc.concat(filterTemplates[filterTemplateName])
          }, [])
        : filtersList

      const filterValuesWithoutEmptyValues = pickBy(filterValuesToUse, filterValue => {
        return isNumber(filterValue) || (!isEmpty(filterValue) && !isNull(filterValue))
      })
      // We do not need appVersion in here, because we are adding it below in "const filters"
      const availableFiltersListWithoutAppVersion = availableFiltersList.filter(
        availableFilter => availableFilter !== 'appVersion'
      )
      const filteredFilters = pick(
        filterValuesWithoutEmptyValues,
        uniq(availableFiltersListWithoutAppVersion)
      )
      const parsedFilters = parseFilters(filteredFilters)

      const environment = filterValuesToUse?.environment
      const period = periodOverride || filterValuesToUse?.period

      const step = filterValuesToUse?.step
      const partialData = filterValuesToUse?.partialData
      const fiscalTime = filterValuesToUse?.fiscalTime
      const origintz = filterValuesToUse?.origintz
      const operatingSystemFilter = filterValuesToUse?.operatingSystem
        ? [['operatingSystem', filterValuesToUse?.operatingSystem]]
        : []

      const filters = [
        ['eventCaseId', filterValuesToUse?.eventCaseID],
        ['appVersion', filterValuesToUse?.releaseVersion],
        ['appVersion', filterValuesToUse?.appVersion],
        ['applicationAppVersion', filterValuesToUse?.qubeAppVersion],
        ['messageContext', filterValuesToUse?.selfInstall],
        ['messageName', filterValuesToUse?.messageName ?? query.messageName],
        ...operatingSystemFilter,
      ]

      // Group By only is applied to line charts and to charts that do not have default group defined in chart query.
      // _stats are percentale(25, 50, 75, 95) charts, we do not need to add group to them
      const groupBy =
        query.type === 'line' && !query.metric.includes('_stats') && !isNull(query.group)
          ? getGroupBy(filterValuesWithoutEmptyValues?.groupBy, query)
          : undefined

      const queryStr = queryString.stringify({
        ...query,
        period:
          chartType === T.DataType.METRIC_AGGREGATE
            ? times.normalizeToAbsolutePeriod(period)
            : period,
        step: chartType === T.DataType.METRIC_AGGREGATE ? null : step,
        environment: environment || 'production',
        groupBy: chartType === T.DataType.METRIC_AGGREGATE ? null : groupBy,
        group: chartType === T.DataType.METRIC_AGGREGATE ? null : query.group ?? groupBy,
        partialData,
        fiscalTime,
        origintz,
        filter: filters.reduce(
          (accum, filterTuple) => {
            if (!isEmpty(filterTuple[1]) && !isNil(filterTuple[1])) {
              return accum.concat(`${filterTuple[0]}:${filterTuple[1]}`)
            }
            return accum
          },
          [...get(query, 'filter', []), ...parsedFilters]
        ),
      })
      return queryStr
    }
  } catch (error) {
    console.error(error)
    return ''
  }
}

export function getComparePeriod(period?: any, compareFeature?: null | string) {
  if (compareFeature === 'previousPeriod' || compareFeature === 'previousPeriodOtherVersion') {
    const [startTimestamp, endTimestap] = times.normalizeToAbsolutePeriod(period).split(':')
    const hoursBetween = times.getDistanceInHours(
      moment(parseInt(startTimestamp)).valueOf(),
      moment(parseInt(endTimestap)).valueOf()
    )
    const previousPeriodStartTimestamp = times.getHoursFromStartingTimestamp(
      hoursBetween.toString(),
      moment(parseInt(startTimestamp)).valueOf()
    )
    return `${previousPeriodStartTimestamp}:${startTimestamp}`
  }
  return period
}

export function generateChartCompareQueryString(
  query?: T.Query,
  filterValues?: Record<string, any>
) {
  try {
    if (!query) {
      throw new Error('No query provided')
    } else {
      const environment = filterValues?.environment
      const period = filterValues?.period
      const step = filterValues?.step
      const technologyType = filterValues?.technologyType
      const partialData = filterValues?.partialData
      const fiscalTime = filterValues?.fiscalTime
      const compareVersion = filterValues?.compareVersion
      const compareFeature = filterValues?.compareFeature
      const queryStr = queryString.stringify({
        ...query,
        period: getComparePeriod(period, compareFeature),
        step,
        environment: environment || 'production',
        partialData,
        fiscalTime,
        group: query.group,
        filter: compact([
          ...get(query, 'filter', []),
          isEmpty(compareVersion) ? null : `appVersion:${compareVersion}`,
          isEmpty(technologyType) ? null : `technologyType:${technologyType}`,
        ]),
      })
      return queryStr
    }
  } catch (error) {
    console.error(error)
    return ''
  }
}

export function getGroupBy(group?: Nullable<string | string[]>, query?: T.Query) {
  if (
    group === 'Job Name' &&
    !(query?.metric === 'jobStartSuccessRate' || query?.metric === 'jobCompletionSuccessRate')
  ) {
    return null
  }

  return query?.group || group
}

export function formatTimestamp(timestamp: number, step: string) {
  const step12H = 43200000
  const ignoreTimestamp = ['1d', '1w', '1mo'].includes(step)
  return moment(timestamp).format(
    !ignoreTimestamp && parseInt(step) <= step12H ? 'MMMM D, YYYY hh:mm a' : 'MMMM D, YYYY'
  )
}

export function parseChartDataToCSV(data: any, step: string, chartType?: any) {
  if (data?.lineData) {
    if (data.lineData.length === 1) {
      return data.lineData[0].data?.map((item: { x: number; y: number }) => {
        return {
          Date: formatTimestamp(item.x, step),
          [data.lineData[0].id]: item.y,
        }
      })
    }
    const formatLinechartDataToCSV = data.lineData.reduce(
      (acc: Record<string, any>, value: { data: { x: number; y: number }[]; id: string }) => {
        const formattedData = value.data.map((item: { x: number; y: number }) => {
          return {
            LineName: value.id,
            Date: formatTimestamp(item.x, step),
            Value: item.y,
          }
        })
        return acc.concat(formattedData)
      },
      []
    )
    return formatLinechartDataToCSV
  }
  if (chartType === T.DataType.METRIC_BAR) {
    return data?.reduce((acc: any, item: Record<string, any>) => {
      const objectKeys = keys(item)
      objectKeys.forEach((key: string) => {
        if (key !== 'index') {
          acc.push({
            BarName: key,
            Date: formatTimestamp(item.index, step),
            Value: item[key],
          })
        }
      })
      return acc
    }, [])
  }
  return data?.topData?.topData || data?.topData || data?.barData || data
}

export function getShowWarning(subChart: any, groupBy?: any) {
  if (groupBy && groupBy !== 'none') {
    if (subChart.showGroupByWarningToAllGroupBy) return true
    return (
      subChart?.query?.type !== 'line' ||
      subChart.query.metric.includes('_stats') ||
      subChart?.query?.group ||
      isNull(subChart?.query?.group)
    )
  }

  return false
}

// returns filters in format of - [ "platformType:web", "platformType:mobile", "legacyCompany:CHARTER", "legacyCompany:TWC"]
export function parseFilters(filteredFilters: Record<string, any>) {
  const parsedFilters = keys(filteredFilters).reduce((acc: any, filterKey: string) => {
    const filterValues = filteredFilters[filterKey]

    if (isArray(filterValues)) {
      const filterKeyFilters = filteredFilters[filterKey].map(
        (filter: string) => `${filterKey}:${filter}`
      )
      return [...acc, ...filterKeyFilters]
    } else {
      return [...acc, `${filterKey}:${filteredFilters[filterKey]}`]
    }
  }, [])

  return parsedFilters
}

function getFilterLabels(
  filtersList: Record<string, string>[],
  unappliedFilters: Record<string, string>
) {
  const unappliedFiltersKeys = keys(unappliedFilters)
  return filtersList.reduce((acc: any, filter: any) => {
    if (unappliedFiltersKeys.includes(filter.id)) return acc.concat(filter.label)
    return acc
  }, [])
}

export function getUnappliedFilterLabels(subChart: any, filterValues?: any): string[] {
  const filterValuesWithoutEmptyValues = pickBy(filterValues, filterValue => {
    return isNumber(filterValue) || !isEmpty(filterValue)
  })

  // Showing warning when chart query filter is equal to applied filter, because chart query filter can not be overwritten
  const unappliedFiltersFromQuery = subChart?.query?.filter?.reduce((acc: any, filter: string) => {
    const filterName = filter.split(':')[0]

    if (filterValuesWithoutEmptyValues[filterName]) {
      acc[filterName] = filterValuesWithoutEmptyValues[filterName]
    }
    return acc
  }, {})

  // if filterTemplates are not defined in chart settings, prism applies all available filters to chart
  const availableFilters: string[] = subChart.filterTemplates
    ? subChart.filterTemplates?.reduce((acc: string[], filterTemplateName: string) => {
        return acc.concat(filterTemplates[filterTemplateName])
      }, [])
    : filtersList

  const appliedFiltersFromFilterList = pick(filterValuesWithoutEmptyValues, filtersList)
  const appliedFiltersFromAvailableList = pick(
    filterValuesWithoutEmptyValues,
    uniq(availableFilters)
  )

  // showFilterWarningToAllFilters is used for charts with rawData to show warning for all filters
  const unappliedFilters = subChart.showFilterWarningToAllFilters
    ? appliedFiltersFromFilterList
    : omit(appliedFiltersFromFilterList, keys(appliedFiltersFromAvailableList))
  // getting filter labels fro unapplied filters from filterMenu filters
  const filterLabels = getFilterLabels(filterValues.filters, {
    ...unappliedFiltersFromQuery,
    ...unappliedFilters,
  })
  return filterLabels
}

export function getTimeZone({
  query,
  location,
  isQubeApp,
  dataSource,
}: {
  query: Record<string, any>
  location: WindowLocation
  isQubeApp?: boolean
  dataSource?: string
}) {
  const isMetricsPage = location.pathname.includes('/client-analytics/metrics')
  const isDruidQuery = dataSource === 'Druid'
  const requiresTemporaryUTCFix =
    !isQubeApp && !isMetricsPage && isDruidQuery && Number(query?.step) === 86400000

  if (requiresTemporaryUTCFix) {
    return getTimeZoneLabel(0)
  }

  const localTimeZone = moment().format('Z')
  if (!!query.origintz) {
    const timeZone = parseInt(query.origintz)
    return getTimeZoneLabel(timeZone)
  }
  return getTimeZoneLabel(parseInt(localTimeZone))
}

export function getOffset(origintz: number, CLIENT_TIMEZONE: number) {
  // if url timezone matches client timezone - return 0 offset
  if (origintz === CLIENT_TIMEZONE) {
    return 0
  } else {
    // if url timezone does not match client timezone - return offset in milliseconds
    return (origintz - CLIENT_TIMEZONE) * HOUR_IN_MILLISECONDS
  }
}

export function getDaylightSavingAdjustedOffset(timestamp: number, offset: number) {
  const isTimestampDuringDST = moment(timestamp).isDST()
  const isCurrentlyDST = moment.utc().isDST()

  if (isTimestampDuringDST && isCurrentlyDST) return offset
  if (isTimestampDuringDST && !isCurrentlyDST) return offset - HOUR_IN_MILLISECONDS
  if (!isTimestampDuringDST && isCurrentlyDST) return offset + HOUR_IN_MILLISECONDS

  return offset
}

export function getTimezoneOffsetData(
  chartData: any[],
  CLIENT_TIMEZONE: number,
  location: WindowLocation
) {
  const query = queryString.parse((location && location.search) || '')

  const chart = get(first(chartData), 'formattedData') || first(chartData)
  const origintz = query.origintz ? parseInt(query.origintz?.toString()) : undefined

  if (origintz === undefined) return chart
  const offset = getOffset(origintz, CLIENT_TIMEZONE)

  if (!!offset && chart?.lineData) {
    const updatedData = chart.lineData.map((lineData: { data: [{ x: number; y: number }] }) => {
      const updatedPoints = lineData?.data.map((point: any) => {
        const x = point.x + getDaylightSavingAdjustedOffset(point.x, offset)
        return { ...point, x }
      })
      return { ...lineData, data: updatedPoints }
    })

    return { aggrData: chart.aggrData, lineData: updatedData }
  }

  return chart
}

export function getTimezoneOffsetDataForDashboards(chart: any, filters?: Record<string, any>) {
  const origintz = filters?.origintz ? parseInt(filters?.origintz?.toString()) : undefined

  if (origintz === undefined) return chart
  const offset = getOffset(origintz, 0)
  if (!!offset && chart) {
    const updatedData = chart.map((lineData: { data: [{ x: number; y: number }] }) => {
      const updatedPoints = lineData?.data.map((point: any) => {
        const x = point.x + getDaylightSavingAdjustedOffset(point.x, offset)
        return { ...point, x }
      })
      return { ...lineData, data: updatedPoints }
    })

    return updatedData
  }

  return chart
}

export function getPeriods(metaData: any) {
  if (typeof metaData === 'string') return [metaData]

  if (metaData?.filters?.period) return [metaData?.filters?.period]

  return metaData?.map((metaDataInfo: any) => {
    if (metaDataInfo?.period) return metaDataInfo.period
    const urlQuery = queryString.parse(metaDataInfo || '')
    return urlQuery.period as string
  })
}

const datesForWarning = ['2021-05-09', '2021-08-07', '2021-08-08']

export function checkIfDateIncludedInPeriod(dataArray: any) {
  const chart = first(dataArray) || dataArray
  const metaData = get(chart, 'metaData')
  if (metaData) {
    const periods = getPeriods(metaData)
    if (!!periods[0]) {
      return periods.some((period: string) => {
        const [dateFrom, dateTo] = period.split(':')
        return datesForWarning.some(date => {
          return moment(date).isBetween(parseInt(dateFrom), parseInt(dateTo), undefined, '[]')
        })
      })
    }
  }
  return false
}
