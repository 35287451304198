import React from 'react'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import Typography from '../typography/Typography'
import FormControl from '@material-ui/core/FormControl'
import Option from 'components/shared/Option'
import { UseGetOptions } from 'components/shared/FilterMenu/types'
import { isEmptyOrNil } from 'utils'
import * as S from './styled'


export function formatToBooleanValue(value?: any) {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return value
  }
}

export default function OptionGroupContainer(props: OptionGroupProps) {
  if (props.useGetOptions) {
    return <OptionGroupGetOptions {...props} />
  }
  return <OptionGroup {...props} />
}

export function OptionGroupGetOptions({ useGetOptions, ...props }: OptionGroupProps) {
  const { isLoading, error, options } = (useGetOptions as UseGetOptions)()

  if (isLoading) {
    return (
      <S.OptionGroupFormControl component="fieldset">
        <S.FormLabel>{props.label}</S.FormLabel>
        <S.StyledLoadingSpinner small data-test="optionGroupCircularProgress" />
      </S.OptionGroupFormControl>
    )
  }

  if (error) {
    return (
      <S.ErrorMessage data-test="optionGroupErrorMessage">Could not load options.</S.ErrorMessage>
    )
  }

  if (isEmpty(options)) {
    return null
  }

  return <OptionGroup {...props} options={options} />
}

function OptionGroup({
  options,
  label,
  defaultValue,
  labelPlacement,
  className,
  isRadioButton,
  onChange,
  ...props
}: OptionGroupProps): React.FunctionComponentElement<OptionGroupProps> {
  const [previousDefaultValue, setPreviousDefaultValue] = React.useState(defaultValue)
  const [value, setValue] = React.useState(defaultValue)
  const hasSelectAllCheckbox = options?.length > 1

  React.useEffect(() => {
    if (defaultValue !== previousDefaultValue) {
      setValue(defaultValue)
      setPreviousDefaultValue(defaultValue)
    }
  }, [defaultValue, previousDefaultValue])

  function handleChange(key: string | boolean, label: string) {
    const optionValue = options.reduce((acc: any, option: any) => {
      if (option?.label === label) {
        acc = option?.value
      }
      return acc
    }, '')
    const formattedValue = formatToBooleanValue(optionValue)
    if (isRadioButton) {
      setValue(formattedValue)
      onChange && onChange(formattedValue)
    } else {
      const arrayVal = (value as string[] | undefined) || []
      const newVal = arrayVal.includes(formattedValue)
        ? arrayVal.filter((option: any) => option !== formattedValue)
        : arrayVal.concat(formattedValue)

      setValue(newVal)
      onChange && onChange(newVal)
    }
  }

  function renderInputs(id: string) {
    if (isEmpty(options)) return null
    return options.map((option, index) => {
      const optionLabel = get(option, 'label', option)
      const optionValue = formatToBooleanValue(get(option, 'value', option))
      return (
        <Option
          key={index}
          label={optionLabel}
          isRadioButton={isRadioButton}
          value={optionValue}
          checked={getCheckedValue(optionValue, value)}
          data-test="optionGroupOption"
          onChange={handleChange}
        />
      )
    })
  }

  function handleSelectAll(value: boolean) {
    if (value) {
      const allSelection = (options as any[]).map(option => (option as any)?.value ?? option)
      setValue(allSelection)
      onChange && onChange(allSelection)
    } else {
      setValue([])
      onChange && onChange([])
    }
  }

  function getSelectAllChecked() {
    const isIndeterminate =
      !isEmpty(value) && (value as string | string[])?.length !== (options as any[])?.length
    const isChecked = (value as string | string[])?.length === options?.length
    return isIndeterminate ? 'indeterminate' : isChecked
  }

  const id = `OptionGroup-${new Date().getUTCMilliseconds()}`

  return (
    <FormControl component="fieldset" className={className}>
      <S.SelectAllContainer>
        {hasSelectAllCheckbox && !isRadioButton ? (
          <S.SelectAllOption
            label={label || ''}
            onChange={handleSelectAll}
            checked={getSelectAllChecked()}
            data-test="optionGroupSelectAll"
          />
        ) : (
          <Typography variant="body" data-test="optionGroupFormLabel">
            {label}
          </Typography>
        )}
      </S.SelectAllContainer>

      <S.GroupComponent>{renderInputs(id)}</S.GroupComponent>
    </FormControl>
  )
}

export function getCheckedValue(option: any, value?: string | string[] | boolean) {
  if (isEmptyOrNil(option)) {
    return isEmptyOrNil(value)
  }
  return Array.isArray(value) ? value.includes(option) : value === option
}

export type OptionProps =
  | string
  | {
      label?: string
      value?: string | null | boolean
    }
  | null
  | undefined

interface OptionGroupProps {
  options: OptionProps[]
  label?: any
  defaultValue?: string | string[] | boolean
  onChange: Function
  labelPlacement?: 'start'
  className?: string
  isRadioButton?: boolean
  useGetOptions?: UseGetOptions
}
