import * as React from 'react'

function SvgMinusCircleAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14C27.999 6.269 21.731.001 14 0zM3 16v-4h22v4H3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMinusCircleAlt
