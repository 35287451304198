import * as React from 'react'

function SvgPause(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 26" {...props}>
      <path
        d="M20 26h-8V0h8v26zm-6-2h4V2h-4v22zm-6 2H0V0h8v26zm-6-2h4V2H2v22z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPause
