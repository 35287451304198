import * as React from 'react'

function SvgStop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path d="M26 26H0V0h26v26zM2 24h22V2H2v22z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default SvgStop
