import * as React from 'react'

function SvgStarHalf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 27" {...props}>
      <path
        d="M27.89 10.15a2.04 2.04 0 00-1.97-1.43h-7.6l-2.34-7.28a2.081 2.081 0 00-3.96 0L9.68 8.72h-7.6c-.9-.01-1.7.57-1.97 1.43-.07.22-.108.45-.11.68.002.66.322 1.278.86 1.66l6.15 4.5-2.35 7.28A2.09 2.09 0 006.63 27c.442.001.873-.14 1.23-.4L14 22.1l6.14 4.49a2.076 2.076 0 003.2-2.32l-2.35-7.28 6.16-4.5a2.06 2.06 0 00.74-2.34zm-1.93.72l-7.31 5.36 2.79 8.65c.01.03.02.06-.12.1L14 19.62V2c.05 0 .06.02.07.06l2.8 8.66 9.12.05c.02.07 0 .09-.03.1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgStarHalf
