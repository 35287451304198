import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import first from 'lodash/first'
import { Dialog } from 'components/shared/Dialog'
import useFilterValues from 'hooks/useFilterValues'
import ChartData from 'components/shared/ChartData'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import ChartContainer from 'components/shared/chart/ChartContainer'
import Table from 'components/shared/Table/Table'
import { getDataSource } from 'components/shared/ChartData/utils'
import CustomLoading from 'components/shared/customLoading'
import { chartColors } from 'common/constants'
import { getTotal, getAverage, getLatest } from 'components/shared/lineChart'
import { getLabelFromTimestamps, genericTimeValueList } from 'components/shared/VoCTimeMenu/utils'
import useHeader from 'hooks/useHeader'
import useApps from 'hooks/useApps'
import ActionsMenu from './actionsMenu'
import * as T from './types'
import * as S from './styled'
import { getPreviousPeriod } from 'utils'
import {
  getComponent,
  generateChartQueryString,
  generateChartCompareQueryString,
  getShowWarning,
  getUnappliedFilterLabels,
  getTimeZone,
  getTimezoneOffsetData,
} from './utils'

// create new variable to determine if currently daylight saving time
const CLIENT_TIMEZONE = -new Date().getTimezoneOffset() / 60 // add +1 if daylight savings time

function SingleChartContainer({ chart }: { chart: T.SingleChartObj }) {
  // #region Temporary UTC Fix
  const { appsByDataSource, entityID } = useApps()
  const isQubeApp = includes(appsByDataSource['qube'], entityID)
  // #endregion

  const location = useLocation()
  const { areFiltersReady } = useHeader().state
  const query = queryString.parse((location && location.search) || '')
  const [yMinScaleIsAuto, setYMinScaleIsAuto] = useState(false)
  const [open, setIsOpen] = useState(false)

  const filterValues = useFilterValues()
  const singleChart = chart as T.SingleChartObj
  const chartTitle = singleChart.customName || singleChart.title
  const chartDescription = singleChart?.customDescription || singleChart.description

  const dataSource = getDataSource(chart.type, chart.dataSource)
  const getOpenSearchUrl = singleChart.getOpenSearchUrl
  const Component = getComponent(singleChart.type)

  const queries = singleChart.query
    ? [
        {
          query: generateChartQueryString(
            singleChart.query,
            filterValues,
            singleChart.filterTemplates,
            singleChart.type,
            singleChart?.customFilters
          ),
          aggrQuery: singleChart.aggrQuery
            ? generateChartQueryString(
                singleChart.aggrQuery,
                filterValues,
                singleChart.filterTemplates,
                undefined,
                singleChart?.customFilters
              )
            : null,
          compareQuery: singleChart.isComparable
            ? generateChartCompareQueryString(singleChart.query, filterValues)
            : null,
          previousQuery: singleChart?.showPreviousQuery
            ? generateChartQueryString(
                singleChart.query,
                filterValues,
                singleChart.filterTemplates,
                singleChart.type,
                singleChart?.customFilters,
                getPreviousPeriod(filterValues?.period)
              )
            : null,
        },
      ]
    : undefined
  const { period } = query
  const periodLabel = getLabelFromTimestamps(genericTimeValueList, period as string)

  const isLineChart =
    chart.type === T.DataType.LINE ||
    chart.type === T.DataType.OS_LINE ||
    chart.type === T.DataType.METRIC_LINE

  const shouldDisplayTable = isLineChart || chart.type === T.DataType.METRIC_BAR

  const handleLineZoom = () => {
    setYMinScaleIsAuto(!yMinScaleIsAuto)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  function renderActionsMenu(dataArray: Record<string, any>[]) {
    if (chart.type === T.DataType.METRIC_AGGREGATE && !chart.removeChart) return <></>
    return (
      <ActionsMenu
        getOpenSearchUrl={getOpenSearchUrl}
        zoomOption={isLineChart}
        handleLineZoom={handleLineZoom}
        isLineChartZoomedOut={!yMinScaleIsAuto}
        chartData={getTimezoneOffsetData(dataArray, CLIENT_TIMEZONE, location) as any}
        chartTitle={singleChart.title}
        chartType={chart.type}
        removeChart={chart.removeChart}
        setDisplayTable={setIsOpen}
        shouldDisplayTable={shouldDisplayTable}
        chartID={chart?.id}
        changelog={chart.history}
        metaData={get(first(dataArray), 'metaData')}
        metricQuery={chart.chartQuery}
        timeZone={getTimeZone({
          query,
          location,
          isQubeApp,
          dataSource,
        })}
        dataSource={dataSource}
      />
    )
  }

  if (!areFiltersReady || !filterValues?.period) {
    return <CustomLoading title={chart.title} dataSource={dataSource} />
  }

  if (
    (isEmpty(queries) && !chart.rawDataArray) ||
    chart.underConstruction ||
    chart.isMetricOutdated
  ) {
    return (
      <NoDataChartMessage
        title={chart.title}
        dataSource={dataSource}
        underConstruction={chart.underConstruction}
        underConstructionExpectedDate={chart.underConstructionExpectedDate}
        underConstructionText={chart.underConstructionText}
        showMetricOutdatedWarning={chart.isMetricOutdated}
        ActionsMenu={renderActionsMenu([{}])}
      />
    )
  }

  const groupBy = queryString.parse((location && location.search) || '').groupBy
  const showWarning = getShowWarning(chart, groupBy)
  const unappliedFilterLabels = getUnappliedFilterLabels(chart, filterValues)
  function getSummaryContent(chartData: any) {
    const summaryContent =
      singleChart?.buttonTabbedSummaryFormatter &&
      singleChart?.buttonTabbedSummaryFormatter({
        total: getTotal(chartData),
        average: getAverage(chartData),
        latest: getLatest(chartData?.lineData),
        data: chartData,
        timePeriod: periodLabel,
      })

    return {
      fullNumber: summaryContent?.fullNumber,
      value: summaryContent?.title,
      valueLabel: summaryContent?.time,
    }
  }

  return (
    <ChartData
      key={`Chart_Data_Container_${
        chart.rawDataArray || generateChartQueryString(singleChart.query, location)
      }`}
      queries={queries}
      title={chart.title}
      rawDataArray={chart.rawDataArray}
      types={[singleChart.type]}
      chartType={singleChart.type}
      isSmall={singleChart.isSmall}
      extendContent={singleChart.extendContent}
      filterValues={filterValues}
      renderActionsMenu={renderActionsMenu}
    >
      {dataArray => {
        const { fullNumber, value, valueLabel } = getSummaryContent(
          getTimezoneOffsetData(dataArray, CLIENT_TIMEZONE, location) as any
        )
        const component = (
          <Component
            data={getTimezoneOffsetData(dataArray, CLIENT_TIMEZONE, location) as any}
            {...singleChart}
            total={singleChart.total}
            colors={chart.colors || chartColors}
            yMinScaleIsAuto={yMinScaleIsAuto}
            dataSource={dataSource}
            isBubbleData={
              singleChart.type === T.DataType.SUNBURST_WITH_TOP ||
              singleChart.type === T.DataType.OS_SUNBURST_WITH_TOP
            }
            showTitle={false}
            showSearch={chart.showSearch}
            location={location}
            origintz={query.origintz}
            group={singleChart.query?.group || filterValues?.groupBy}
            step={filterValues?.step}
            customFilters={chart?.customFilters}
            isChart
          />
        )
        const chartContainerProps = {
          component,
          chartTitle,
          showRealTitleTooltip: singleChart.showRealTitleTooltip,
          title: singleChart.title,
          metric: singleChart?.query?.metric,
          dataArray,
          showWarning,
          unappliedFilterLabels,
          chartType: singleChart?.type,
          chartsHeadingProps: {
            title: chartTitle,
            description: chartDescription,
            value,
            valueLabel,
            valueTooltip: fullNumber,
            actionMenu: <>{renderActionsMenu(dataArray)}</>,
          },
        }
        return (
          <>
            <Dialog
              onAccept={handleClose}
              acceptLabel="Close Table"
              title={singleChart.title}
              isOpen={open}
              onCancel={handleClose}
            >
              <S.TableContainer>
                {shouldDisplayTable && (
                  <Table
                    data={getTimezoneOffsetData(dataArray, CLIENT_TIMEZONE, location)}
                    group={singleChart.query?.group || filterValues?.groupBy}
                    title={singleChart.title}
                    step={filterValues?.step}
                  />
                )}
              </S.TableContainer>
            </Dialog>
            <ChartContainer {...chartContainerProps} />
          </>
        )
      }}
    </ChartData>
  )
}

export default SingleChartContainer
