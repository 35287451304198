import styled from 'styled-components'

export const TooltipContent = styled.div`
  display: flex;
  padding: 0.5rem;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`

export const TooltipContentText = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Colorblock = styled.div<{ color?: string }>`
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${props => props.color};
  margin-right: 0.5rem;
  border-radius: 50%;
`
