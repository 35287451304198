import * as React from 'react'

function SvgFullScreenF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 0H3a3.009 3.009 0 00-3 3v21h25a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3zM12 20H4v-6h2v4h6v2zm0-14H6v4H4V4h8v2zm12 14h-8v-2h6v-4h2v6zm0-10h-2V6h-6V4h8v6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFullScreenF
