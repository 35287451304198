import * as React from 'react'

function SvgChevronDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 16" {...props}>
      <path
        d="M27.707 1.854L14 15.561.293 1.854 1.707.439 14 12.732 26.293.439z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronDown
