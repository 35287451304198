import * as React from 'react'

function SvgPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path d="M24 11H13V0h-2v11H0v2h11v11h2V13h11z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default SvgPlus
