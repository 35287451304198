import { ChangeEventHandler, MouseEventHandler } from 'react'
import Files from 'react-files'
import Typography from 'components/shared/typography/Typography'
import { Button } from 'components/shared/Button'
import * as S from './styles'

export default function Decryption({
  showFormatError,
  handleDecryptTextareaOnChange,
  decryptedData,
  hasDecryptAccess,
  uploadDecryptFileIsLoading,
  handleDecryptButton,
  decryptIsLoading,
  onFilesChange,
  onFilesError,
}: {
  showFormatError: boolean
  handleDecryptTextareaOnChange: ChangeEventHandler<HTMLTextAreaElement>
  decryptedData: any
  hasDecryptAccess?: boolean
  uploadDecryptFileIsLoading: boolean
  handleDecryptButton: MouseEventHandler<HTMLButtonElement>
  decryptIsLoading: boolean
  onFilesChange: Function
  onFilesError: Function
}) {
  return (
    <S.DecryptContainer>
      <Typography variant="title5" as="label">
        Decrypt
      </Typography>
      <S.TopContainer>
        <S.FormatError>{showFormatError && 'Decryption Failed'}</S.FormatError>
      </S.TopContainer>
      <S.StyledTextarea
        data-pho="decryptTextbox"
        onChange={handleDecryptTextareaOnChange}
        value={decryptedData}
        disabled={!hasDecryptAccess}
      />
      <S.ActionButtonContainer>
        <Files
          className="files-dropzone"
          onChange={(file: any) => onFilesChange(file, 'decrypt')}
          onError={(error: any) => onFilesError(error, 'decrypt')}
          accepts={['.csv']}
          // maxFileSize={10000000}
          clickable
        >
          <Button
            data-pho="uploadCSVDecrypt"
            variant="secondary"
            loading={uploadDecryptFileIsLoading}
          >
            Upload CSV
          </Button>
        </Files>
        <Button
          data-pho="decryptButton"
          onClick={handleDecryptButton}
          loading={decryptIsLoading}
          disabled={!hasDecryptAccess}
        >
          Decrypt
        </Button>
      </S.ActionButtonContainer>
    </S.DecryptContainer>
  )
}
