import * as React from 'react'

function SvgTrashF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M17 4V3a3.009 3.009 0 00-3-3h-4a3.009 3.009 0 00-3 3v1H0v2h2.04l.87 19.14a3.002 3.002 0 003 2.86h12.18a3.002 3.002 0 003-2.86L21.96 6H24V4h-7zM9 23H7V9h2v14zM9 3a1.003 1.003 0 011-1h4c.552.002.998.448 1 1v1H9V3zm4 20h-2V9h2v14zm4 0h-2V9h2v14z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTrashF
