import * as React from 'react'

function SvgInfoCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14S0 21.73 0 14C.009 6.27 6.272.008 14 0zm0 2C7.372 2 2 7.372 2 14c.007 6.624 5.376 11.992 12 12 6.628 0 12-5.373 12-12 0-6.628-5.373-12-12-12zm1.5 10v10h-3V12h3zM14 6a2 2 0 110 4 2 2 0 010-4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgInfoCircle
