const analyticsConfig = {
  collector: {
    appName: 'Prism',
    appType: 'Web',
    requirementsVersion: '2.517',
    startSession: 'PRISM_startSession',
    domain: 'internal_apps',
    deviceType: 'webBrowser',
    msgTriggeredBy: 'user',
  },
  helix: {
    settings: {
      logLevel: 'error',
    },
  },
  quantum: {
    endpoint:
      process.env.REACT_APP_QUANTUM_ENDPOINT ||
      'https://collector.pi-charter.net/topics/internal_quantum?bulk=true',
  },
  debugAppEvents: false,
}

export default analyticsConfig
