import * as React from 'react'

function SvgTabletF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 28" {...props}>
      <path
        d="M19 0H3a3.009 3.009 0 00-3 3v22a3.009 3.009 0 003 3h16a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3zm-5 24H8v-2h6v2zm6-20H2V3a1.003 1.003 0 011-1h16c.552.002.998.448 1 1v1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTabletF
