import * as React from 'react'

function SvgCompassF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M13,0 C20.1768615,0.00685236054 25.9931476,5.82313851 26,13 C26,20.1797017 20.1797017,26 13,26 C5.82029825,26 0,20.1797017 0,13 C0,5.82029825 5.82029825,0 13,0 Z M13,20 C12.4477153,20 12,20.4477153 12,21 C12,21.5522847 12.4477153,22 13,22 C13.5516214,21.9984017 13.9984017,21.5516214 14,21 C14,20.4477153 13.5522847,20 13,20 Z M19,7 L10,10 L7,19 L16,16 L19,7 Z M15.84,10.16 L14.42,14.42 L11.58,11.58 L15.84,10.16 Z M5,12 C4.44771525,12 4,12.4477153 4,13 C4,13.5522847 4.44771525,14 5,14 C5.55162138,13.9984017 5.99840175,13.5516214 6,13 C6,12.4477153 5.55228475,12 5,12 Z M21,12 C20.4477153,12 20,12.4477153 20,13 C20,13.5522847 20.4477153,14 21,14 C21.5516214,13.9984017 21.9984017,13.5516214 22,13 C22,12.4477153 21.5522847,12 21,12 Z M13,4 C12.4483786,4.00159825 12.0015983,4.44837862 12,5 C12,5.55228475 12.4477153,6 13,6 C13.5522847,6 14,5.55228475 14,5 C14,4.44771525 13.5522847,4 13,4 Z"
        id="Combined-Shape"
      ></path>
    </svg>
  )
}

export default SvgCompassF
