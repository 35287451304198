import React from 'react'
import { HeaderContext } from 'components/App/Providers/Header'

export default function useFilterValues(): Record<string, any> {
  const { timeValues, filterValues, groupByValues, filters } = React.useContext(HeaderContext)

  return {
    filters,
    ...filterValues,
    ...groupByValues,
    ...timeValues,
  }
}
