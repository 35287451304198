import styled from 'styled-components'
import { Link } from '@reach/router'
import { colors } from 'components/shared/utils/colors'
import { Card } from 'components/shared/Card'

const TopBarHeight = `3rem`

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 2rem;

  & > div {
    flex-shrink: 0;
    flex-grow: 1;
    height: ${TopBarHeight};
  }

  && {
    color: ${({ theme: { isDarkMode } }) => (isDarkMode ? colors.white : colors.black)};
    text-decoration: none;
  }
`

export const PrismLogo = styled.img`
  height: 2rem;
  margin-top: 2px;
  margin-right: 2px;
`

export const TopbarContainer = styled(Card).attrs(props => ({
  elevation: 1,
  disablePadding: true,
}))`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 100;
  height: ${TopBarHeight};
  max-height: ${TopBarHeight};
  padding: 0 1rem;
  background-color: ${colors.white};
`

export const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
`

export const SwitchContainer = styled.div`
  display: flex;
`

export const FeedbackContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
`

export const PrismLogoName = styled.span`
  && {
    font-weight: 200;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: -1;
  }
`
