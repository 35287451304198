import * as React from 'react'

function SvgZoomOut(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 27" {...props}>
      <path
        d="M4.51188065,4.5130508 C7.17041757,1.85428827 11.4513659,1.76109902 14.2230835,4.30165381 C16.994801,6.84220859 17.2738296,11.1150702 14.8560514,13.994512 L14.8560514,13.994512 L18.4556434,17.594104 L17.5938116,18.4559357 L13.9942197,14.8564169 C11.114983,17.2744394 6.84209776,16.9957735 4.30130777,14.2242715 C1.76051778,11.4527696 1.85334373,7.17181334 4.51188065,4.5130508 Z M9.49174013,3.70179105 C6.29387809,3.70179105 3.7014987,6.29417044 3.7014987,9.49203248 C3.7014987,12.6898945 6.29387809,15.2822739 9.49174013,15.2822739 C12.688097,15.278643 15.2783506,12.6883893 15.2819816,9.49203248 C15.2819816,6.29417044 12.6896022,3.70179105 9.49174013,3.70179105 Z M12.5392356,8.88253339 L12.5392356,10.1015316 L6.44424464,10.1015316 L6.44424464,8.88253339 L12.5392356,8.88253339 Z"
        id="Combined-Shape"
        fill="currentColor"
        fillRule="evenodd"
      ></path>
    </svg>
  )
}

export default SvgZoomOut
