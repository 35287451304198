import React from 'react'
import { Method } from 'axios'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import csv from 'csvtojson'
import { Popover } from '@material-ui/core'
import { IconButton } from 'components/shared/IconButton'
import Typography from 'components/shared/typography/Typography'
import Alert from 'components/shared/Alert'
import Flex from 'components/shared/flex/Flex'
import { Toast } from 'components/shared/Toast'
import { axios, axiosConfigBase } from 'hooks/useApi'
import useDownloadCsv from 'hooks/useDownloadCsv'
import { rootApiUrl } from 'common/constants'
import { services } from 'common/services'
import KryptoInstructions from './KryptoInstructions'
import Decryption from './Decryption'
import Encryption from './Encryption'
import * as S from './styles'
import * as U from './utils'
import { useUserAccessLog } from 'hooks/useUserAccessLog'

const DECRYPT_ERROR_MESSAGE = 'Spec Guide decryption failed'

export default function Krypto({ hasDecryptAccess }: { hasDecryptAccess?: boolean }) {
  const [encryptedData, setEncryptedData] = React.useState<Nullable<any>>()
  const [decryptedData, setDecryptedData] = React.useState<Nullable<any>>()
  const [CSVData, setCSVData] = React.useState<Nullable<any>>()
  const [decryptIsLoading, setDecryptIsLoading] = React.useState(false)
  const [encryptIsLoading, setEncryptIsLoading] = React.useState(false)
  const [uploadEncryptFileIsLoading, setUploadEncryptFileIsLoading] = React.useState(false)
  const [uploadDecryptFileIsLoading, setUploadDecryptFileIsLoading] = React.useState(false)
  const [showFormatError, setShowFormatError] = React.useState(false)
  const [toastMessage, setToastMessage] = React.useState('')
  const [decryptAlertMessage, setDecryptAlertMessage] = React.useState('')
  const [encryptAlertMessage, setEncryptAlertMessage] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const logUserAccess = useUserAccessLog('krypto', [
    'transformer_access',
    'transformer-decrypt',
    'transformer-decrypt-bulk',
  ])

  const handleInfoOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const { DownloadCsvButton, DownloadCsvAlert } = useDownloadCsv(
    `krypto_CSV_result_${moment().format('MMMM Do')}.csv`,
    CSVData
  )

  function decryptFile(data: any) {
    async function decryptData() {
      try {
        const axiosConfig = {
          ...axiosConfigBase(),
          method: 'POST' as Method,
          data: {
            data,
            cryptingMethod: 'decrypt',
          },
          url: `${rootApiUrl}${services.transformer.url}`,
        }

        const res = await axios({ ...axiosConfig })
        setCSVData(res?.data)
        setUploadDecryptFileIsLoading(false)
      } catch (error) {
        onFilesError(error, 'decrypt')
      }
    }
    decryptData()
  }

  function encryptFile(data: any) {
    async function encryptData() {
      try {
        const axiosConfig = {
          ...axiosConfigBase(),
          method: 'POST' as Method,
          data: {
            data,
            cryptingMethod: 'encrypt',
          },
          url: `${rootApiUrl}${services.transformer.url}`,
        }

        const res = await axios({ ...axiosConfig })
        setCSVData(res?.data)
        setUploadEncryptFileIsLoading(false)
      } catch (error) {
        onFilesError(error, 'encrypt')
      }
    }
    encryptData()
  }

  function handleFile(e: any, uploadFileTo: string) {
    try {
      const content = e.target.result
      const invalidFileMessage = U.getInvalidFileMessage(content)
      if (!isEmpty(invalidFileMessage)) throw new Error(invalidFileMessage)
      csv()
        .fromString(content)
        .then((jsonObj: any) => {
          if (uploadFileTo === 'encrypt') {
            logUserAccess('bulk-encrypt')
            encryptFile(jsonObj)
          } else {
            logUserAccess('bulk-decrypt')
            decryptFile(jsonObj)
          }
        })
    } catch (error) {
      onFilesError(error, uploadFileTo)
    }
  }

  function onFilesChange(files: any, uploadFileTo: string) {
    uploadFileTo === 'decrypt' && setDecryptAlertMessage('')
    uploadFileTo === 'encrypt' && setEncryptAlertMessage('')
    setToastMessage('')
    setCSVData(null)
    if (!files || files.length === 0) return
    uploadFileTo === 'encrypt'
      ? setUploadEncryptFileIsLoading(true)
      : setUploadDecryptFileIsLoading(true)
    const currentFileIndex = files.length - 1
    const fileReader = new FileReader()
    fileReader.onloadend = e => handleFile(e, uploadFileTo)
    fileReader.readAsText(files[currentFileIndex])
  }

  function onFilesError(error: any, uploadFileTo: string) {
    uploadFileTo === 'encrypt'
      ? setUploadEncryptFileIsLoading(false)
      : setUploadDecryptFileIsLoading(false)

    const axiosError = error.response?.data?.error?.message || error.response?.data?.error
    const errorMessage = error?.isAxiosError ? axiosError : error.message

    const notification = `CSV Upload Error: ${errorMessage}`
    setToastMessage(notification)
  }

  async function decryptData() {
    const axiosConfig = {
      ...axiosConfigBase(),
      method: 'POST' as Method,
      data: {
        data: [{ field: 'decrypt', value: decryptedData }],
        cryptingMethod: 'decrypt',
      },
      url: `${rootApiUrl}${services.transformer.url}`,
    }

    const res = await axios({ ...axiosConfig })

    // this way of catching error is used because Transformer API returns error with 200 status and error text in value
    if (res?.data[0]?.value.includes(DECRYPT_ERROR_MESSAGE)) {
      setShowFormatError(true)
      setEncryptedData('')
      setDecryptIsLoading(false)
      return
    }

    setEncryptedData(res?.data[0]?.value)
    setShowFormatError(false)
    setDecryptIsLoading(false)
  }

  function handleDecryptButton() {
    if (!decryptedData) {
      setDecryptAlertMessage('No decrypt data entered.')
    } else {
      setDecryptAlertMessage('')
      setCSVData(null)
      setDecryptIsLoading(true)
      logUserAccess('single-decrypt')
      decryptData()
    }
  }

  async function encryptData() {
    const axiosConfig = {
      ...axiosConfigBase(),
      method: 'POST' as Method,
      data: {
        data: [{ field: 'encrypt', value: encryptedData }],
        cryptingMethod: 'encrypt',
      },
      url: `${rootApiUrl}${services.transformer.url}`,
    }

    const res = await axios({ ...axiosConfig })
    setDecryptedData(res?.data[0]?.value)
    setShowFormatError(false)
    setEncryptIsLoading(false)
  }

  function handleEncryptButton() {
    if (!encryptedData) {
      setEncryptAlertMessage('No encrypt data entered.')
    } else {
      setEncryptAlertMessage('')
      setCSVData(null)
      setEncryptIsLoading(true)
      logUserAccess('single-encrypt')
      encryptData()
    }
  }

  function handleDecryptTextareaOnChange(e: any) {
    setDecryptAlertMessage('')
    setDecryptedData(e.target.value)
  }

  function handleEncryptTextareaOnChange(e: any) {
    setEncryptAlertMessage('')
    setEncryptedData(e.target.value)
  }

  return (
    <>
      {decryptAlertMessage && (
        <S.StyledAlertContainer>
          <Alert children={decryptAlertMessage} variant="page-error" isPersistent />
        </S.StyledAlertContainer>
      )}
      {encryptAlertMessage && (
        <S.StyledAlertContainer>
          <Alert children={encryptAlertMessage} variant="page-error" isPersistent />
        </S.StyledAlertContainer>
      )}
      <S.StyledAlertContainer>
        <DownloadCsvAlert />
      </S.StyledAlertContainer>
      <KryptoInstructions />
      <Flex center>
        <Decryption
          showFormatError={showFormatError}
          handleDecryptTextareaOnChange={handleDecryptTextareaOnChange}
          decryptedData={decryptedData}
          hasDecryptAccess={hasDecryptAccess}
          uploadDecryptFileIsLoading={uploadDecryptFileIsLoading}
          handleDecryptButton={handleDecryptButton}
          decryptIsLoading={decryptIsLoading}
          onFilesChange={onFilesChange}
          onFilesError={onFilesError}
        />
        <Encryption
          handleEncryptTextareaOnChange={handleEncryptTextareaOnChange}
          encryptedData={encryptedData}
          uploadEncryptFileIsLoading={uploadEncryptFileIsLoading}
          handleEncryptButton={handleEncryptButton}
          encryptIsLoading={encryptIsLoading}
          onFilesChange={onFilesChange}
          onFilesError={onFilesError}
        />
      </Flex>

      {toastMessage && (
        <Toast placement="bottom-left" onDismiss={() => setToastMessage('')} label={toastMessage} />
      )}
      <S.DownloadButtonContainer>
        <DownloadCsvButton disabled={!CSVData}>Download CSV result</DownloadCsvButton>
      </S.DownloadButtonContainer>
      <Flex center>
        <Typography variant="caption" scale="small" noMargin>
          CSV Data Format Help
        </Typography>
        <IconButton icon="QuestionCircle" onClick={handleInfoOpen} />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <S.PopoverContentContainer>
            <Typography data-test="OpenAcsv">
              <h5>How to Open a CSV</h5>
            </Typography>
            <Typography data-test="CSVdocMessage">
              If you are having trouble with the format of the CSV data, please check out the
              documentation link below.
            </Typography>
            <S.StyledLink
              variant="standalone"
              icon
              data-test="CSVdocumentation"
              href="https://prism.spectruminternal.com/documentation/prismdocs/csv-formatting"
              newTab={true}
              disableVisited={true}
            >
              Documentation
            </S.StyledLink>
          </S.PopoverContentContainer>
        </Popover>
      </Flex>
    </>
  )
}
