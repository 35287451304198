import { defaultPeriods } from 'components/shared/VoCTimeMenu/utils'

const defaultValues = {
  groupBys: undefined,
  filters: [],
  time: {
    periods: defaultPeriods,
  },
}

export default defaultValues
