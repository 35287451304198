import * as React from 'react'

function SvgBillingF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M23 10h-1V3a3.009 3.009 0 00-3-3H2.92A2.923 2.923 0 000 2.92V23a3.009 3.009 0 003 3h20a3.009 3.009 0 003-3V13a3.009 3.009 0 00-3-3zm-13 4H5v-2h5v2zm7 0h-3v-2h3v2zm0-4H5V8h12v2zm7 13a1 1 0 01-2 0V12h1c.552.002.998.448 1 1v10z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgBillingF
