import React from 'react'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import uniq from 'lodash/uniq'
import isNil from 'lodash/isNil'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import { NetworkStatus } from '@apollo/client'
import { types } from 'components/voiceOfCustomer'
import * as S from './styles'

function doesCatHaveManualCatAssigned(
  category: types.CategoryModel,
  manualCategoryValues: types.ManualCategory[]
): boolean {
  return manualCategoryValues.some(
    (manualCategory: types.ManualCategory) =>
      manualCategory.category === category._id && !manualCategory.isArchived
  )
}

// If feedback has no categories assigned, but has aws category assigned, it will automatically add the aws category to prism selected categories, rerouted categories, flag the feedback and reroute it.
// This is array of values that VoC API will use to do the above mentioned.
export function getAwsCategoriesToReroute(
  feedbackIDs: string[],
  feedbackList: types.FeedbackModel[],
  categoryValues: types.CategoryModel[],
  manualCategoryValues: types.ManualCategory[]
): types.Reroute[] {
  const rerouteAwsCats = feedbackIDs.reduce((acc: types.Reroute[], feedbackId: string) => {
    const feedback = find(feedbackList, { _id: feedbackId })

    if (feedback) {
      const awsCatIds = feedback.awsCategories.map((awsCat: types.CategoryModel) => awsCat._id)
      const awsCatIdsToReroute = isEmpty(feedback.prismAppliedCategories) ? awsCatIds : []

      // checks if the user has selected aws category in Prism and assigned a manualCategory, if yes, then don't reroute
      const awsCatsCheckedAgainstUISelected = awsCatIdsToReroute.filter((awsCatId: string) => {
        const awsCategoryUISelected = categoryValues.find(
          (categoryValue: types.CategoryModel) => categoryValue._id === awsCatId
        ) as types.CategoryModel
        if (!isEmpty(awsCategoryUISelected) && awsCategoryUISelected) {
          return checkIfIsReroute(awsCategoryUISelected, categoryValues, manualCategoryValues)
        } else return true
      })

      return acc.concat({
        feedbackId: feedback._id,
        idsToReroute: awsCatsCheckedAgainstUISelected,
      })
    } else return acc
  }, [])
  return rerouteAwsCats
}

// This function double checks each feedback applied categories and categories selected in batch edit have a manual category assigned. If not, those categories will be rerouted.
export function getCategoriesToReroute(
  feedbackIDs: string[],
  feedbackList: types.FeedbackModel[],
  categoryValues: types.CategoryModel[],
  manualCategoryValues: types.ManualCategory[],
  manualCategories: types.ManualCategory[]
): types.Reroute[] {
  const rerouteCats = feedbackIDs.reduce((acc: types.Reroute[], feedbackId: string) => {
    const feedback = find(feedbackList, { _id: feedbackId })

    if (feedback) {
      const batchEditSelectedCatIds = categoryValues.map(categoryValue => categoryValue._id)
      const allAppliedCats = (feedback.prismAppliedCategories || []).concat(categoryValues)

      const allAppliedCatIds = (feedback.prismAppliedCategories || [])
        .map((cat: types.CategoryModel) => cat._id)
        .concat(batchEditSelectedCatIds)

      const batchEditSelectedManualCats = (feedback.prismAppliedManualCategories || [])
        .map((cat: types.ManualCategory) => {
          return manualCategories.find(manualCategory => manualCategory._id === cat._id)
        })
        .filter(manualCat => !isNil(manualCat)) as types.ManualCategory[]

      const allAppliedManualCatIds = batchEditSelectedManualCats.concat(manualCategoryValues)

      const uniqueCats = uniq(allAppliedCatIds)

      const catsCheckedAgainstUISelected = uniqueCats.filter((uniqueCat: string) => {
        const categoryToCheck = allAppliedCats.find(
          (categoryValue: types.CategoryModel) => categoryValue._id === uniqueCat
        ) as types.CategoryModel
        return checkIfIsReroute(categoryToCheck, categoryValues, allAppliedManualCatIds)
      })

      return acc.concat({
        feedbackId: feedback._id,
        idsToReroute: catsCheckedAgainstUISelected,
      })
    } else return acc
  }, [])
  return rerouteCats
}

export function getIsReviewedQuery(tabValue: number) {
  switch (tabValue) {
    case 3:
      return { isReviewed: true }
    case 0:
      return { isReviewed: false }
    default:
      return {}
  }
}
export function getRerouteSentQuery(tabValue: number) {
  switch (tabValue) {
    case 2:
      return { isRerouteSent: true }
    default:
      return {}
  }
}

export function getIsFlaggedQuery(tabValue: number) {
  switch (tabValue) {
    case 0:
      return { isFlagged: false }
    case 1:
      return { isFlagged: true, reroutePage: true }
    case 2:
      return { isFlagged: true, isRerouteSent: true }
    default:
      return {}
  }
}

export function getFeedbackReviewedCountLabel(
  feedbackReviewedCountQuery: Record<string, any>,
  feedbackReviewedCount: string
) {
  if (feedbackReviewedCountQuery.networkStatus === NetworkStatus.refetch)
    return (
      <S.StyledTab>
        <span>Completed Reviews</span> <LoadingSpinner />
      </S.StyledTab>
    )
  return feedbackReviewedCount
    ? `Completed Reviews (${feedbackReviewedCount})`
    : 'Completed Reviews'
}

export function getFeedbackPendingCountLabel(
  pendingCountQuery: Record<string, any>,
  feedbacksPending: string
) {
  if (pendingCountQuery.networkStatus === NetworkStatus.refetch)
    return (
      <S.StyledTab>
        <span>Pending Reviews</span> <LoadingSpinner />
      </S.StyledTab>
    )
  return feedbacksPending ? `Pending Reviews (${feedbacksPending})` : 'Pending Reviews'
}

export function getFeedbackReroutedCountLabel(
  feedbackReroutedCountQuery: Record<string, any>,
  feedbackReroutedCount: string
) {
  if (feedbackReroutedCountQuery.networkStatus === NetworkStatus.refetch)
    return (
      <S.StyledTab>
        <span>Rerouted Reviews</span> <LoadingSpinner />
      </S.StyledTab>
    )
  return feedbackReroutedCount ? `Rerouted Reviews (${feedbackReroutedCount})` : 'Rerouted Reviews'
}

export function getParentCategory(
  categoriesData: types.CategoryModel[],
  parentId: string,
  previousCategory: types.CategoryModel[],
  appliedCategories: types.CategoryModel[]
): types.CategoryModel[] {
  const parentCategory = categoriesData.find(
    (category: types.CategoryModel) => category?._id === parentId
  )
  const isParentCategorySelectedNewValue = appliedCategories.some(
    (category: types.CategoryModel) => category?._id === parentId
  )

  if (parentCategory) {
    const previousCategoryAndParentCategory = isParentCategorySelectedNewValue
      ? previousCategory
      : previousCategory.concat([parentCategory])
    if (parentCategory?.parent) {
      return getParentCategory(
        categoriesData,
        parentCategory?.parent,
        previousCategoryAndParentCategory,
        appliedCategories
      )
    }
    return previousCategoryAndParentCategory
  } else {
    return previousCategory
  }
}

export function getIsTopLevelCategory(category: types.CategoryModel) {
  return category.category !== null && isNil(category.parent)
}

export function getCategoryByID(categoriesData: types.CategoryModel[], id?: Nullable<string>) {
  return categoriesData.find((category: types.CategoryModel) => category._id === id)
}

export function getParentCategoryNameByID(
  categoriesData: types.CategoryModel[],
  id?: Nullable<string>
) {
  const category = getCategoryByID(categoriesData, id)

  return category?.category || ''
}

function getMiddleLevelNameWithoutParentText(
  middleLevelCat: types.CategoryModel,
  categoriesData: types.CategoryModel[]
) {
  const parentCat = getCategoryByID(categoriesData, middleLevelCat.parent)
  const parentCatName = parentCat?.category
  const charactersToRemove = !isEmpty(parentCat) ? parentCatName?.length || 0 : 0 // To remove top-level category that is being modified in data entry point

  return charactersToRemove
    ? removeCharsFromEnd(charactersToRemove + 3, middleLevelCat.category)
    : middleLevelCat.category
}

export function getDropdownGroups(
  uniqueByKey: types.CategoryModel[],
  categoriesData: types.CategoryModel[],
  getCategoryLabel: (category: types.CategoryModel & types.ManualCategory) => string,
  getCategoryValue: (category: types.CategoryModel & types.ManualCategory) => string,
  isMiddleLevelCat?: boolean
) {
  const uniqDropdownGroups = uniqueByKey.reduce(
    (acc: types.DropdownGroup[], value: types.CategoryModel & types.ManualCategory) => {
      const categoryById = getCategoryByID(
        categoriesData,
        isMiddleLevelCat ? value.parent || '' : value.category || ''
      )
      const setCategoryName = (): string => {
        let groupName = ''
        if (categoryById?.parent) {
          const parentName = categoriesData.find(
            category => category._id === categoryById.parent
          )?.category
          groupName = `${categoryById?.category} (${parentName})`
        } else {
          groupName = categoryById?.category || (value.category as string)
        }
        return groupName
      }
      const categoryName = setCategoryName()

      const existingGroup = acc.some(accValue => accValue?.groupName === categoryName)
      if (existingGroup) {
        return acc.map(accValue => {
          const categoryAlreadyExists = accValue.groupItems.some(
            groupItem => groupItem.value === getCategoryValue(value)
          )
          if (accValue.groupName === categoryName && !categoryAlreadyExists) {
            return {
              ...accValue,
              groupItems: [
                ...accValue.groupItems,
                {
                  value: getCategoryValue(value),
                  label: getCategoryLabel(value),
                  searchLabel:
                    (isMiddleLevelCat
                      ? getMiddleLevelNameWithoutParentText(value, categoriesData)
                      : value.manualCategory) || '',
                  ...value,
                },
              ],
            }
          }
          return accValue
        })
      }

      return acc.concat([
        {
          groupName: categoryName,
          groupItems: [
            {
              value: getCategoryValue(value),
              label: getCategoryLabel(value),
              searchLabel:
                (isMiddleLevelCat
                  ? getMiddleLevelNameWithoutParentText(value, categoriesData)
                  : value.manualCategory) || '',
              ...value,
            },
          ],
        },
      ])
    },
    []
  )
  return uniqDropdownGroups
}

export function checkForSubsAndManuals(
  categoryValues: types.CategoryModel[],
  manualCategoryValues: types.ManualCategory[]
) {
  return categoryValues.every((category: types.CategoryModel) => {
    // If there is manual cat selected and it has related category selected all good, else need to do extra checks
    const manualCategoryMatch = manualCategoryValues.some(
      (manualCategory: types.ManualCategory) =>
        manualCategory.category === category._id && !manualCategory.isArchived
    )

    if (manualCategoryMatch) {
      return true
    }

    // EXTRA CHECKS
    const isTopLevelCategory = getIsTopLevelCategory(category)

    if (isTopLevelCategory) {
      const selectedRelatedMiddleLevelCat = categoryValues.find(cat => cat.parent === category._id)

      return isEmpty(selectedRelatedMiddleLevelCat)
        ? manualCategoryMatch
        : doesCatHaveManualCatAssigned(
            selectedRelatedMiddleLevelCat as types.CategoryModel,
            manualCategoryValues
          )
    } else {
      return manualCategoryMatch
    }
  })
}

export function getCategoryIDs(categories?: types.CategoryModel[] | types.ManualCategory[]) {
  const categoryIDs = categories?.map(
    (category: types.CategoryModel | types.ManualCategory) => category?._id
  )
  return isEmpty(categoryIDs) ? null : categoryIDs
}

function checkIfIsReroute(
  category: types.CategoryModel,
  categoryValues: types.CategoryModel[],
  manualCategoryValues: types.ManualCategory[]
) {
  const catHasManualCatSelected = doesCatHaveManualCatAssigned(category, manualCategoryValues)
  // If has related manual cat selected don't reroute, else we do extra checks
  if (catHasManualCatSelected) {
    return false
  }

  // EXTRA CHECKS
  const isTopLevelCategory = getIsTopLevelCategory(category)
  if (isTopLevelCategory) {
    const selectedRelatedMiddleLevelCat = categoryValues.find(cat => cat.parent === category._id)

    return isEmpty(selectedRelatedMiddleLevelCat)
      ? manualCategoryValues.length > 0
      : !doesCatHaveManualCatAssigned(
          selectedRelatedMiddleLevelCat as types.CategoryModel,
          manualCategoryValues
        )
  } else {
    return !catHasManualCatSelected
  }
}

export function getReroutes(
  categoryValues: types.CategoryModel[],
  manualCategoryValues: types.ManualCategory[],
  prismAppliedCategoriesIds?: string[],
  awsCategoriesIds?: string[]
) {
  const filteredValues = categoryValues.filter((category: types.CategoryModel) => {
    return checkIfIsReroute(category, categoryValues, manualCategoryValues)
  })

  return getCategoryIDs(filteredValues)
}

export function getRerouteSent(
  categoryValues: types.CategoryModel[],
  manualCategoryValues: types.ManualCategory[],
  categoriesData: types.CategoryModel[]
) {
  const middleLevelCategories = categoriesData.filter(
    (cat: types.CategoryModel) => !isNil(cat.parent)
  )
  const filteredValues = categoryValues.filter((category: types.CategoryModel) => {
    const middleLevelCategoriesRelatedToTopLevelCategory = middleLevelCategories.filter(
      middleLevelCategory => middleLevelCategory.parent === category._id
    )
    const middleLevelCategoriesRelatedToTopLevelCategoryIds =
      middleLevelCategoriesRelatedToTopLevelCategory.map(
        middleLevelCategoryRelatedToTopLevelCategory =>
          middleLevelCategoryRelatedToTopLevelCategory._id
      )
    return manualCategoryValues.some(
      (manualCategory: types.ManualCategory) =>
        manualCategory.category === category._id ||
        middleLevelCategoriesRelatedToTopLevelCategoryIds.includes(manualCategory.category || '')
    )
  })
  if (isEmpty(manualCategoryValues)) return []
  return getCategoryIDs(filteredValues)
}

export function removeCharsFromEnd(charactersToRemove: number, string?: string | null) {
  return charactersToRemove
    ? string?.substring(0, string.length - charactersToRemove).trim()
    : string?.trim()
}

export function getRelatedManualCategories(
  categoryValues: types.CategoryModel[],
  manualCategoryValues: types.ManualCategory[]
) {
  const categoryNames = categoryValues.map((value: types.CategoryModel) => value.category)
  const relatedSubcategories = manualCategoryValues.filter((value: types.CategoryModel) => {
    const categoryId = value.category
    const categoryName =
      categoryValues.find(categoryValue => categoryValue._id === categoryId)?.category || ''

    return categoryNames.includes(categoryName)
  })
  return relatedSubcategories
}

export function getSelectableMiddleLevelCats(
  middleLevelCategories: types.CategoryModel[],
  currentTopLevelValues: types.CategoryModel[]
) {
  return middleLevelCategories.filter(middleCat => {
    const matchedParent = currentTopLevelValues.find(
      topLevelCat => topLevelCat._id === middleCat.parent
    )
    return !isEmpty(matchedParent)
  })
}

export function getSelectableMiddleLevelCategory(
  middleLevelCategories: types.CategoryModel[],
  selectableMiddleLevelCategoryDropdown: types.CategoryModel[]
) {
  return middleLevelCategories.filter((middleCat: types.CategoryModel) => {
    const middleCategory = middleCat.category
    const matchedParent = selectableMiddleLevelCategoryDropdown.find(
      topLevelCat => topLevelCat.category === middleCategory
    )
    return !isEmpty(matchedParent)
  })
}

export function sortByCategoryAndName(
  subcategories: Array<types.Subcategory & types.ManualCategory>
): types.Subcategory[] {
  return subcategories.sort((a, b) => {
    const aCat: string = a.category.toLowerCase()
    const bCat: string = b.category.toLowerCase()
    const aSubcat: string = a?.subcategory?.toLowerCase() || a?.manualCategory?.toLowerCase() || ''
    const bSubcat: string = b?.subcategory?.toLowerCase() || b.manualCategory?.toLowerCase() || ''

    if (aCat === bCat) {
      return aSubcat > bSubcat ? 1 : -1
    }
    return aCat > bCat ? 1 : -1
  })
}

export function getUniqueByKey(
  categoryValues: types.CategoryModel[],
  categoriesData: Array<types.CategoryModel & types.ManualCategory>
) {
  function filterByCategory(): types.Subcategory[] {
    const categories = categoryValues?.map(category => category.category)
    const categoriesIDs = categoryValues?.map(category => category._id)

    const categoriesToReference = categoriesData.filter(
      (category: types.CategoryModel & types.ManualCategory) => {
        const isNotArchived = category.isArchived !== true
        const isManualCategory = category.manualCategory !== null && category.manualCategory
        const includedInCategories = categories?.includes(category.category)
        const includedInCategoriesIDs = categoriesIDs?.includes(category?.category || '')

        return (
          (includedInCategories || includedInCategoriesIDs) && isManualCategory && isNotArchived
        )
      }
    )

    return categoriesToReference as types.Subcategory[]
  }
  const subcategories = filterByCategory()
  sortByCategoryAndName(subcategories)

  return categoriesData.filter(
    (category: types.CategoryModel & types.ManualCategory) =>
      category.category !== null && category.subcategory === null
  )
}
