import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  Operation,
  NextLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import analytics from '../utils/analytics'
import { createUploadLink } from 'apollo-upload-client'
import Helix from '@charter/helix/build/portals/index.min'

export const devCJLink = process.env.REACT_APP_CJ_API_ENDPOINT || 'http://localhost:8083/graphql'
export const devVocLink =
  process.env.REACT_APP_VOC_DEV_API_ENDPOINT || 'http://localhost:8083/graphql'
export const prodVocLink =
  process.env.REACT_APP_VOC_PROD_API_ENDPOINT || 'http://localhost:8083/graphql'
export const devVocLinkNoCache =
  process.env.REACT_APP_VOC_DEV_API_ENDPOINT || 'http://localhost:8083/graphql'
export const prodVocLinkNoCache =
  process.env.REACT_APP_VOC_PROD_API_ENDPOINT || 'http://localhost:8083/graphql'

const cjHttpLink = new HttpLink({
  uri: devCJLink,
})

const devVocHttpLink = new HttpLink({
  uri: devVocLink,
})

const prodVocHttpLink = new HttpLink({
  uri: prodVocLink,
})

const devVocHttpLinkNoCache = new HttpLink({
  uri: devVocLinkNoCache,
})

const prodVocHttpLinkNoCache = new HttpLink({
  uri: prodVocLinkNoCache,
})

const egHttpLink = new HttpLink({
  uri: process.env.REACT_APP_EG_API_ENDPOINT || 'http://localhost:8084/graphql',
})

const morphHttpLink = new HttpLink({
  uri: process.env.REACT_APP_MORPH_API_ENDPOINT || 'http://localhost:8088/graphql',
})

const dashboardHttpLink = new HttpLink({
  uri: process.env.REACT_APP_METRICS_DASHBOARD_API_ENDPOINT || 'http://localhost:8089/graphql',
})

const dashboardBuilderHttpLink = new HttpLink({
  uri: process.env.REACT_APP_DASHBOARDS_API_ENDPOINT || 'http://localhost:8089/graphql',
})

const authLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const { headers } = operation.getContext()
  const accessToken = localStorage.getItem('accessToken') || 'placeholder'
  operation.setContext({
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return forward(operation)
})

const addAnalyticDetails = new ApolloLink((operation: Operation, forward: NextLink) => {
  const { headers } = operation.getContext()
  const userId = localStorage.getItem('id') ?? ''
  operation.setContext({
    headers: {
      ...headers,
      ...Helix.getQubeHeaders(userId),
    },
    ts: performance.now(),
  })
  return forward(operation)
})

const trackAnalytics = new ApolloLink((operation: Operation, forward: NextLink) => {
  const {
    variables,
    operationName,
    query: { kind },
  } = operation

  return forward(operation).map(response => {
    const { response: opResponse, ts, traceId } = operation.getContext()
    const responseTime = performance.now() - ts

    const queryParameters = {
      queryKind: kind,
      queryVariables: variables,
    }

    const status = opResponse?.status
    const url = opResponse?.url

    analytics.track('apiCall', {
      appApiHost: url,
      appApiHttpVerb: 'POST',
      appApiPath: `/${operationName}`,
      appApiResponseCode: status.toString(),
      appApiResponseTimeMs: responseTime,
      opSuccess: true,
      appApiTraceId: traceId,
      appApiQueryParameters: JSON.stringify(queryParameters),
    })

    return response
  })
})

const checkForError = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const {
    variables,
    operationName,
    query: { kind },
  } = operation

  const { response, ts, traceId } = operation.getContext()
  const status = networkError && !response?.status ? 500 : response?.status
  const url = response?.url
  const responseTime = performance.now() - ts

  const queryParameters = {
    queryKind: kind,
    queryVariables: variables,
  }

  const responseText = {
    ...(graphQLErrors ? { graphQLErrors } : {}),
    ...(networkError ? { networkError: networkError.message } : {}),
  }

  analytics.track('apiCall', {
    appApiHost: url,
    appApiHttpVerb: 'POST',
    appApiPath: `/${operationName}`,
    appApiResponseCode: status?.toString(),
    appApiResponseTimeMs: responseTime,
    opSuccess: false,
    appApiResponseText: JSON.stringify(responseText),
    appApiTraceId: traceId,
    appApiQueryParameters: JSON.stringify(queryParameters),
  })
})

export const customerJourneyClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink
    .concat(addAnalyticDetails)
    .concat(trackAnalytics)
    .concat(checkForError as unknown as ApolloLink) //https://github.com/apollographql/apollo-link/issues/538 & https://github.com/apollographql/apollo-link/issues/1258
    .concat(cjHttpLink),
  defaultOptions: { query: { errorPolicy: 'all' } },
})

export const devVoiceOfCustomerClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink
    .concat(addAnalyticDetails)
    .concat(trackAnalytics)
    .concat(checkForError as unknown as ApolloLink)
    .concat(devVocHttpLink),
  defaultOptions: { query: { errorPolicy: 'all' } },
})

export const prodVoiceOfCustomerClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink
    .concat(addAnalyticDetails)
    .concat(trackAnalytics)
    .concat(checkForError as unknown as ApolloLink)
    .concat(prodVocHttpLink),
  defaultOptions: { query: { errorPolicy: 'all' } },
})

export const devVoiceOfCustomerClientNoCache = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink
    .concat(addAnalyticDetails)
    .concat(trackAnalytics)
    .concat(checkForError as unknown as ApolloLink)
    .concat(devVocHttpLinkNoCache),
  defaultOptions: { query: { errorPolicy: 'all', fetchPolicy: 'no-cache' } },
})

export const prodVoiceOfCustomerClientNoCache = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink
    .concat(addAnalyticDetails)
    .concat(trackAnalytics)
    .concat(checkForError as unknown as ApolloLink)
    .concat(prodVocHttpLinkNoCache),
  defaultOptions: { query: { errorPolicy: 'all', fetchPolicy: 'no-cache' } },
})

export const elasticsearchGraphQLClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink
    .concat(addAnalyticDetails)
    .concat(trackAnalytics)
    .concat(checkForError as unknown as ApolloLink)
    .concat(egHttpLink),
  defaultOptions: { query: { errorPolicy: 'all', fetchPolicy: 'no-cache' } },
})

export const morphClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink
    .concat(addAnalyticDetails)
    .concat(trackAnalytics)
    .concat(checkForError as unknown as ApolloLink)
    .concat(morphHttpLink),
  defaultOptions: { query: { errorPolicy: 'all', fetchPolicy: 'no-cache' } },
})

export const dashboardClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink
    .concat(addAnalyticDetails)
    .concat(trackAnalytics)
    .concat(checkForError as unknown as ApolloLink)
    .concat(dashboardHttpLink),
  defaultOptions: { query: { errorPolicy: 'all', fetchPolicy: 'no-cache' } },
})

export const dashboardBuilderClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink
    .concat(addAnalyticDetails)
    .concat(trackAnalytics)
    .concat(checkForError as unknown as ApolloLink)
    .concat(dashboardBuilderHttpLink),
  defaultOptions: { query: { errorPolicy: 'all', fetchPolicy: 'no-cache' } },
})

export const dataslensClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink
    .concat(addAnalyticDetails)
    .concat(trackAnalytics)
    .concat(checkForError as unknown as ApolloLink)
    .concat(
      createUploadLink({
        uri: process.env.REACT_APP_DATALENS_API_ENDPOINT || 'http://localhost:9001/graphql',
      }) as unknown as ApolloLink
    ),
  defaultOptions: { query: { errorPolicy: 'all', fetchPolicy: 'no-cache' } },
})

const graphqlUtils = {
  customerJourneyClient,
  devVoiceOfCustomerClient,
  prodVoiceOfCustomerClient,
  elasticsearchGraphQLClient,
  morphClient,
  dashboardClient,
  dashboardBuilderClient,
}

export default graphqlUtils
