import React from 'react'
import isEmpty from 'lodash/isEmpty'
import * as S from './styled'
import { ChartProps } from 'components/shared/chart/types'
import { FormattedNetworkData } from 'components/shared/ChartData/types'
import { Graph } from 'react-d3-graph'
import { config } from './config'

import NoDataChartMessage from 'components/shared/NoDataChartMessage'

function NetworkChart({ data, dataSource }: NetworkChartProps) {
  if (isEmpty(data.networkData)) {
    return <NoDataChartMessage />
  }

  const MyGraph = (data: any) => {
    return <Graph id="qube-network" data={data.networkData} config={config as any} />
  }
  return <S.Container>{MyGraph(data)}</S.Container>
}

interface NetworkChartProps extends ChartProps {
  data: FormattedNetworkData
  dataSource?: string
}

export default NetworkChart
