import * as React from 'react'

function SvgSpeakerMuteF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 24" {...props}>
      <path
        d="M6.697 6.868H3a3.003 3.003 0 00-3 3v5a3.003 3.003 0 003 3h3.741L17 23.568V0L6.697 6.868zm-4.697 8v-5a1 1 0 011-1h3v7H3a1 1 0 01-1-1zm26.707-6.293l-1.414-1.414L24 10.454l-3.293-3.293-1.414 1.414 3.293 3.293-3.293 3.293 1.414 1.414L24 13.282l3.293 3.293 1.414-1.414-3.293-3.293 3.293-3.293z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSpeakerMuteF
