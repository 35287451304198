import React from 'react'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

import * as S from './styled'

function Suggestion({
  suggestion,
  query = '',
  isHighlighted,
}: SuggestionProps): React.FunctionComponentElement<SuggestionProps> {
  const suggestionVal = typeof suggestion === 'string' ? suggestion : suggestion && suggestion.value
  const matches = match(suggestionVal, query)
  const parts = parse(suggestionVal, matches)

  return (
    <S.Suggestion component="div" isHighlighted={isHighlighted}>
      {parts.map((part, index) => {
        return part.highlight ? (
          <S.SuggestionText key={index.toString()} style={{ fontWeight: 'bold' }}>
            {part.text}
          </S.SuggestionText>
        ) : (
          <S.SuggestionText key={index.toString()} style={{ fontWeight: 'normal' }}>
            {part.text}
          </S.SuggestionText>
        )
      })}
    </S.Suggestion>
  )
}

interface SuggestionProps {
  suggestion?: { label?: string; value?: any } | string
  query?: string
  isHighlighted?: boolean
}

export default Suggestion
