import React from 'react'
import * as S from './styles'

function Chip({
  label = 'NEW',
  isRound,
  className,
}: {
  label?: string
  isRound?: boolean
  className?: string
}) {
  return <S.Chip label={label} isRound={isRound} className={className} />
}

export default Chip
