import * as React from 'react'

function SvgTape(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 12" {...props}>
      <path
        d="M22 0a5.99 5.99 0 00-4.46 10h-7.08A5.991 5.991 0 106 12h16a6 6 0 000-12zM2 6a4 4 0 118 0 4 4 0 01-8 0zm20 4a4 4 0 110-8 4 4 0 010 8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTape
