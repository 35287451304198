import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import { useLocation } from '@reach/router'
import Flex from 'components/shared/flex/Flex'
import { Button } from 'components/shared/Button'
import Alert from 'components/shared/Alert'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import usePrismUser from 'hooks/usePrismUser'
import useApps from 'hooks/useApps'
import useGroups from 'hooks/useGroups'
import { UserPageFavorite } from 'components/App/Providers/Data/PrismUser'
import FavoritesModal from 'components/shared/FavoritesModal'
import { filterOutDatedFavorites } from 'components/shared/FavoritesModal/utils'
import CurrentlyAtPage from './CurrentlyAtPage'
import CurrentlyOnAppOrGroup from './CurrentlyOnAppOrGroup'
import Heading from './Heading'
import FavoritePages from './FavoritePages'
import FavoriteApps from './FavoriteApps'
import FavoriteGroups from './FavoriteGroups'
import { getTitle } from './utils'
import * as S from './styles'
import * as T from './types'
import * as U from './utils'

const FavoritesPopover = ({ handleClose }: PopoverProps) => {
  const [isEditPageMode, setIsEditPageMode] = useState(false)
  const [isEditGroupsMode, setIsEditGroupsMode] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [favoriteName, setFavoriteName] = useState('')
  const [favoriteDescription, setFavoriteDescription] = useState('')
  const [favoriteUrl, setFavoriteUrl] = useState('')
  const [favoriteId, setFavoriteId] = useState('')
  const [error, setError] = useState(false)
  const [isAppEditModalOpen, setIsAppEditModalOpen] = React.useState(false)

  const prismUser = usePrismUser()
  const location = useLocation()
  const path = location.pathname

  const { currentGroup } = useGroups()
  const { entityID, app, apps } = useApps()

  const currentPageFavorites = prismUser?.pageFavorites || []
  const currentAppFavorites = prismUser?.favorites || []
  const currentGroupFavorites = prismUser?.groupFavorites || []

  const handleAddFavorite = async () => {
    if (favoriteName === '') {
      setError(true)
    } else {
      const path = location.pathname
      const filters = location.search
      const pathToSet = path

      const pageFavoriteToAdd = {
        title: favoriteName,
        url: `${pathToSet}${filters}`,
        description: favoriteDescription,
        pageName: getTitle(path, entityID),
        appOrGroupName: currentGroup ? currentGroup.description : app.id || '',
        appliedFilterQueryString: filters,
      }

      const checkIfDuplicate = U.checkDuplicateFavoritesPage(
        currentPageFavorites,
        pageFavoriteToAdd
      )
      if (!checkIfDuplicate) {
        await prismUser.update({
          pageFavorites: [...currentPageFavorites, pageFavoriteToAdd],
        })
      }
      setIsAddModalOpen(false)
      setFavoriteName('')
      setFavoriteDescription('')
      setError(false)
    }
  }

  const handleEditFavorite = async () => {
    if (favoriteName === '') {
      setError(true)
    } else {
      const updateFavorites = currentPageFavorites.map(favorite => {
        if (favorite._id === favoriteId) {
          return {
            ...favorite,
            _id: favoriteId,
            title: favoriteName,
            url: favoriteUrl,
            description: favoriteDescription,
          }
        } else return favorite
      })

      await prismUser.update({
        pageFavorites: updateFavorites,
      })

      setIsAddModalOpen(false)
      setFavoriteName('')
      setFavoriteDescription('')
      setFavoriteUrl('')
      setFavoriteId('')
      setError(false)
    }
  }

  const handleCancel = () => {
    setIsAddModalOpen(false)
    setFavoriteName('')
    setFavoriteDescription('')
    setFavoriteUrl('')
    setFavoriteId('')
  }

  const handleEditFavoriteModal = (pageFavorite: UserPageFavorite) => {
    setIsAddModalOpen(true)
    setFavoriteName(pageFavorite.title)
    setFavoriteDescription(pageFavorite.description || '')
    setFavoriteUrl(pageFavorite.url)
    setFavoriteId(pageFavorite._id || '')
    setIsAddModalOpen(true)
  }

  const handleDeleteFavorite = async (idToDelete: string) => {
    const updatedFavorites = currentPageFavorites.filter(favorite => favorite._id !== idToDelete)

    await prismUser.update({
      pageFavorites: updatedFavorites,
    })
    isEmpty(updatedFavorites) && setIsEditPageMode(false)
  }

  const handleAddCurrentGroup = async () => {
    const currentGroupFavorites = prismUser.groupFavorites || []
    const newGroupFavorites = entityID
      ? currentGroupFavorites.concat(entityID)
      : currentGroupFavorites

    await prismUser.update({ groupFavorites: compact(newGroupFavorites) })
  }

  function handleAppEditClick() {
    setIsAppEditModalOpen(true)
  }

  function onAppEditModalClose() {
    setIsAppEditModalOpen(false)
  }

  const handleAddCurrentApplication = async () => {
    const currentPageFavorites = filterOutDatedFavorites(prismUser.favorites, apps)
    const newAppFavorites = entityID ? currentPageFavorites.concat(entityID) : currentPageFavorites

    await prismUser.update({ favorites: compact(newAppFavorites) })
  }

  const handleOpenAddDialog = () => {
    const favoriteNameTitle = getTitle(path, entityID)

    setFavoriteName(favoriteNameTitle)
    setFavoriteDescription(currentGroup ? currentGroup.description : app.id || '')
    setIsAddModalOpen(true)
  }

  const ModalPanel = ({ children, direction, ...props }: T.ModalPanelProps) => (
    <S.MenuContainer>
      <Heading {...props} />
      <Flex direction={direction}>{children}</Flex>
    </S.MenuContainer>
  )

  return (
    <Flex>
      <ModalPanel direction="column" title="You are currently" dataTest="currentlyAtTitle">
        <CurrentlyAtPage
          setFavoriteName={setFavoriteName}
          setFavoriteDescription={setFavoriteDescription}
          setIsAddModalOpen={setIsAddModalOpen}
          currentPageFavorites={currentPageFavorites}
        />
        <CurrentlyOnAppOrGroup
          currentGroupFavorites={currentGroupFavorites}
          currentAppFavorites={currentAppFavorites}
        />
      </ModalPanel>
      {/* Divisor not showing up to full height, must adjust with Flex */}
      <S.ModalPanelVerticalDivisor />
      {/* Divisor not showing up to full height, must adjust with Flex */}
      <S.MyFavoritesContainer>
        <Heading title="My Favorites" dataTest="favoritesTitle" margin="0 0 0 1.5rem" />
        <Flex
          direction="row"
          height="100%"
          maxHeight={currentAppFavorites.length > 5 ? '22rem' : 'none'}
        >
          <FavoritePages
            currentPageFavorites={currentPageFavorites}
            isEditPageMode={isEditPageMode}
            handleOpenAddDialog={handleOpenAddDialog}
            handleClose={handleClose}
            handleDeleteFavorite={handleDeleteFavorite}
            handleEditFavoriteModal={handleEditFavoriteModal}
            setIsEditPageMode={setIsEditPageMode}
          />
          <S.FavoritesVerticalDivisor />
          <FavoriteApps
            handleAddCurrentApplication={handleAddCurrentApplication}
            handleAppEditClick={handleAppEditClick}
            currentAppFavorites={currentAppFavorites}
            handleClose={handleClose}
          />
          <S.FavoritesVerticalDivisor />
          <FavoriteGroups
            handleAddCurrentGroup={handleAddCurrentGroup}
            currentGroupFavorites={currentGroupFavorites}
            isEditGroupsMode={isEditGroupsMode}
            setIsEditGroupsMode={setIsEditGroupsMode}
            handleClose={handleClose}
          />
        </Flex>
      </S.MyFavoritesContainer>

      <Dialog open={isAddModalOpen} onClose={handleCancel}>
        <S.ModalContentContainer>
          {error && (
            <Alert variant="page-error" isPersistent children={'Favorite Name cannot be empty'} />
          )}
          <DialogTitle id="alert-dialog-title">
            <span>Customize Your Favorite Details</span>
          </DialogTitle>
          <DialogContent>
            <S.StyledTextField
              value={favoriteName}
              required
              label="Favorite Name"
              onChange={({ target: { value } }) => setFavoriteName(value)}
            />
            <S.StyledTextField
              value={favoriteDescription}
              label="Favorite Description"
              onChange={({ target: { value } }) => setFavoriteDescription(value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} variant="primary">
              Cancel
            </Button>
            <Button
              onClick={isEditPageMode ? handleEditFavorite : handleAddFavorite}
              variant="primary"
            >
              Save
            </Button>
          </DialogActions>
        </S.ModalContentContainer>
      </Dialog>
      <FavoritesModal isOpen={isAppEditModalOpen} onClose={onAppEditModalClose} />
    </Flex>
  )
}

interface PopoverProps {
  handleClose: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export default FavoritesPopover
