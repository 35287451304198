import React from 'react'
import CompareMenu, { CompareMenuProps } from 'components/shared/CompareMenu'
import { QueryParamsProps } from './useQueryStringParams'

export default function useCompareMenu(
  args: Omit<QueryParamsProps, 'params'> & {
    values: Record<string, any>
    updateValues: (newValues: Record<string, any>) => void
  }
): {
  CompareMenu: CompareMenuComponent
} {
  const CompareMenuWithValues: CompareMenuComponent = props => (
    <CompareMenu values={args.values} updateValues={args.updateValues} {...props} />
  )
  return {
    CompareMenu: CompareMenuWithValues,
  }
}

type CompareMenuComponent = React.FC<Omit<CompareMenuProps, 'values' | 'updateValues'>>
