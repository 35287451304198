import React from 'react'
import styled, { css } from 'styled-components'
import MuiTable from '@material-ui/core/Table'
import MuiTableCell from '@material-ui/core/TableCell'
import MuiTableRow from '@material-ui/core/TableRow'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import { defaultFont } from 'common/constants'
import { colors } from 'components/shared/utils/colors'
export const copyStyles = css`
  ${defaultFont};
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
`

export const StyledTable = styled(({ tableRowCSS, ...props }) => <MuiTable {...props} />)`
  && {
    ${defaultFont};
  }
  ${props => props.tableRowCSS}

  && tr td {
    padding: 0.5rem 1.5rem 0.5rem 0;
    border-color: ${colors.gray20};
    width: 160px;
    max-width: 160px;
    word-wrap: break-word;
    font-family: 'Open Sans';
    font-size: 0.875rem;
  }

  && tr {
    height: 2.25rem;
  }
`

export const TableContainer = styled.div<{ isLoading?: boolean }>`
  width: 100%;
  margin-top: 1rem;

  && {
    border-radius: 0.25rem;
    border: solid 1px ${colors.gray20};
    display: inline-table;
    color: ${colors.black};
    background-color: ${colors.white};
  }

  && table tbody :last-child td {
    border: none;
  }

  && table tbody :first-child td {
    padding: 1rem 1.5rem 1rem 0;
  }

  && table tbody tr > :first-child {
    padding-left: 1rem;
  }

  ${props =>
    props.isLoading &&
    css`
      && {
        min-height: 400px;
        display: flex;
        align-items: center;
      }
    `}
`

export const StyledMuiTableTitleCell = styled(MuiTableCell)<{ align?: string }>`
  && {
    color: ${colors.gray30};
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25;
    margin-bottom: 0.25rem;
    text-align: ${props => (props.align ? props.align : 'initial')};
  }
`

export const StyledMuiTableCell = styled(MuiTableCell)`
  && {
    font-size: 0.875rem;
    color: ${colors.black};
    ${copyStyles};
  }
`

export const CellContent = styled.div<{ align?: string }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: ${props => {
    switch (props.align) {
      case 'right':
        return 'flex-end'
      case 'left':
        return 'flex-start'
      case 'center':
        return 'center'
      default:
        return 'initial'
    }
  }};
`

export const ColumnHeadersRow = styled(MuiTableRow)`
  && td {
    color: ${colors.gray30};
    font-weight: 500;
  }
`

// @TODO rework header title sort div to button
export const StyledSort = styled.div`
  cursor: pointer;
`

export const twoRowCSS = `
&& tr :nth-of-type(2)
{
  width: 10%;
}
`

export const defaultRowCSS = `
&& tr td{
  &:nth-of-type(1)
  {
    width: 25%;
  }
  &:nth-of-type(2),
  &:nth-of-type(3)
  {
    width: 10%;
  }
  &:nth-of-type(4),
  &:nth-of-type(5)
  {
    width: 15%;
  }
  &:nth-of-type(6) {
    width: 25%;
  }
}

`

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-right: 0.3rem;
`

export const StyledCellContent = styled.div`
  width: 100%;
`

export const CSVDownloadContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem 1rem 0 1rem;
  justify-content: flex-end;
`

export const StyledCellFavorite = styled.span`
  min-width: 30px;
`
