import React from 'react'
import { Link } from '@reach/router'
import isEmpty from 'lodash/isEmpty'
import toPairs from 'lodash/toPairs'
import queryString from 'query-string'
import Tooltip from '@material-ui/core/Tooltip'
import { IconButton } from 'components/shared/IconButton'
import Entity from 'components/shared/Entity'
import { UserPageFavorite } from 'components/App/Providers/Data/PrismUser'
import * as S from '../../styles'

export default function PageFavoritesList({
  handleClose,
  pageFavorites,
  handleDeleteFavorite,
  isEditPageMode,
  handleEditFavoriteModal,
}: PageFavoritesListProps) {
  function renderTooltip(pageFavorite: UserPageFavorite) {
    const filterObject = queryString.parse(pageFavorite.appliedFilterQueryString || '')
    const filterPairs = toPairs(filterObject)

    const isTooltipDataDefined =
      pageFavorite?.pageName ||
      pageFavorite?.appOrGroupName ||
      pageFavorite?.appliedFilterQueryString

    return isTooltipDataDefined ? (
      <div>
        <div>{pageFavorite.pageName}</div>
        <div>{pageFavorite.appOrGroupName}</div>

        {!isEmpty(filterPairs) && (
          <>
            <br />
            <div>
              <div>Filters</div>
              {filterPairs.map(filterPair => (
                <S.TooltipItemValue>{`${filterPair[0]}:${filterPair[1]}`}</S.TooltipItemValue>
              ))}
            </div>
          </>
        )}
      </div>
    ) : (
      ''
    )
  }

  return (
    <div>
      {pageFavorites.map((pageFavorite: UserPageFavorite) => {
        if (isEditPageMode) {
          return (
            <div key={`${pageFavorite.title}_${pageFavorite.url}_${pageFavorite._id}_editing`}>
              <Tooltip title={renderTooltip(pageFavorite)} arrow>
                <S.FavoriteContainer>
                  <Entity
                    title={pageFavorite.title}
                    description={pageFavorite.description}
                    data-pho={pageFavorite.title}
                    onClick={() => handleEditFavoriteModal(pageFavorite)}
                  />
                  {isEditPageMode && (
                    <IconButton
                      icon="TrashF"
                      size="sm"
                      onClick={() => handleDeleteFavorite(pageFavorite._id)}
                    />
                  )}
                </S.FavoriteContainer>
              </Tooltip>
            </div>
          )
        } else
          return (
            <Link
              key={`${pageFavorite.title}_${pageFavorite.url}_${pageFavorite._id}`}
              onClick={handleClose}
              to={pageFavorite.url}
            >
              <Tooltip title={renderTooltip(pageFavorite)} arrow>
                <S.FavoriteContainer>
                  <Entity
                    title={pageFavorite.title}
                    description={pageFavorite.description}
                    data-pho={pageFavorite.title}
                  />
                </S.FavoriteContainer>
              </Tooltip>
            </Link>
          )
      })}
    </div>
  )
}

interface PageFavoritesListProps {
  handleClose: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  pageFavorites: UserPageFavorite[]
  handleDeleteFavorite: Function
  isEditPageMode: boolean
  handleEditFavoriteModal: Function
}
