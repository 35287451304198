import * as React from 'react'

function SvgEyeCrossF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 22" {...props}>
      <path
        d="M28.93 10.63C28.75 10.19 24.57 0 15 0a12.618 12.618 0 00-5.5 1.24l4.81 4.81a5.002 5.002 0 015.64 5.64l5.36 5.36a21.28 21.28 0 003.62-5.68l.15-.37-.15-.37zm-5.04 7.84l-4.71-4.71-6.94-6.94-4.53-4.53-2-2-1.42 1.42 1.82 1.82a20.82 20.82 0 00-5.04 7.1L.92 11l.15.37C1.25 11.81 5.43 22 15 22a12.69 12.69 0 007.29-2.29l2 2 1.42-1.42-1.82-1.82zM15 16a5.002 5.002 0 01-5-5 4.9 4.9 0 01.83-2.75l6.92 6.92A4.9 4.9 0 0115 16z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgEyeCrossF
