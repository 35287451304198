import * as React from 'react'

function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 25" {...props}>
      <path
        d="M28 9v13.182A2.962 2.962 0 0124.92 25H0v-2h24.92c.586 0 1.08-.375 1.08-.818V9h2zM17.88 1.7a3.005 3.005 0 014.239.005l.176.176A3.006 3.006 0 0122.3 6.12L8.584 19.884l-5.958 1.49 1.49-5.958zm-1.565 4.382L5.92 16.44l-.545 2.186 2.186-.545L18.983 6.616l-2.668-.534zm4.39-2.963a1.003 1.003 0 00-1.414-.001L18.02 4.384l2.657.531.206-.207a1.003 1.003 0 00-.002-1.413z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgEdit
