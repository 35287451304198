import * as React from 'react'

function SvgSpeakerSound(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 24" {...props}>
      <path
        d="M17 .132v23.567L6.741 18H3a3.003 3.003 0 01-3-3v-5a3.003 3.003 0 013-3h3.697L17 .132zm-2 3.736L8 8.535v7.877l7 3.889V3.868zm9.163.136a9.99 9.99 0 014 8c0 3.148-1.482 6.111-4 8l-1.44-1.44a7.975 7.975 0 000-13.12l1.44-1.44zm-2.95 2.95a5.949 5.949 0 012.95 5.05 5.962 5.962 0 01-2.95 5.05l-1.47-1.47a4.135 4.135 0 002.42-3.58 4.123 4.123 0 00-2.42-3.58l1.47-1.47zM6 9H3a1 1 0 00-1 1v5a1 1 0 001 1h3V9z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSpeakerSound
