import * as React from 'react'

function SvgShield(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 30" {...props}>
      <path
        d="M12 .902l12 5.454V9.47c0 8.09-4.419 15.534-11.521 19.408l-.479.261-.479-.261A22.107 22.107 0 010 9.47V6.356L12 .902zm-1 2.65L2 7.644V9.47c0 6.78 3.412 13.06 9 16.761V3.551zm2 0v22.679c5.589-3.702 9-9.982 9-16.76V7.643l-9-4.091z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgShield
