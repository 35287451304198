import * as React from 'react'

function SvgSmartWatchF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 28" {...props}>
      <path
        d="M20 12h-.2a9.9 9.9 0 00-4-6.1l-1-5.9H5.2l-1 5.9A9.9 9.9 0 000 14a10.1 10.1 0 004.2 8.1l1 5.9h9.6l1-5.9a10.2 10.2 0 004-6.1h.2a1.1 1.1 0 001-1v-2a1 1 0 00-1-1zM6.8 2h6.4l.4 2.7A8.6 8.6 0 0010 4a8.6 8.6 0 00-3.6.7L6.8 2zM11 9v8H9V9h2zm-6 7v-3h2v4H6a1.1 1.1 0 01-1-1zm8.2 10H6.8l-.4-2.7a10.5 10.5 0 003.6.7 10.5 10.5 0 003.6-.7l-.4 2.7zM15 16a1.1 1.1 0 01-1 1h-1v-6h2v5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSmartWatchF
