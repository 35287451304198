import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import isFinite from 'lodash/isFinite'
import Alert from 'components/shared/Alert'
import { TextInput } from 'components/shared/TextInput'
import { FormLabel } from 'components/shared/OptionGroup/styled'
import { Button } from 'components/shared/Button'
import * as S from './styles'

function ActionsMenu({
  label,
  filterId,
  handleRangeChange,
  defaultValue,
}: {
  label: string
  filterId: string
  handleRangeChange: Function
  defaultValue: any
}) {
  const seqStartFilterValue = defaultValue?.filter((value: string) => value.includes('start'))
  const seqEndFilterValue = defaultValue?.filter((value: string) => value.includes('end'))

  // start:10 -> 10 || null
  const defaultSeqStart = !isEmpty(seqStartFilterValue)
    ? seqStartFilterValue[0].split(':')[1]
    : null

  // end:20 -> 20 || null
  const defaultSeqEnd = !isEmpty(seqEndFilterValue) ? seqEndFilterValue[0].split(':')[1] : null

  const [seqStartInput, setSeqStartInput] = useState<null | number>(defaultSeqStart)
  const [seqEndInput, setSeqEndInput] = useState<null | number>(defaultSeqEnd)
  const [alertMessage, setAlertMessage] = useState('')

  const resetRange = () => {
    if (!defaultSeqStart && !defaultSeqEnd) {
      setAlertMessage('No range to reset.')
    } else {
      setAlertMessage('')
      handleRangeChange(filterId, [])
    }
  }

  const goToRange = () => {
    if (defaultSeqStart === seqStartInput && defaultSeqEnd === seqEndInput) {
      setAlertMessage('Please choose different start or end range than current.')
    } else {
      setAlertMessage('')
      const seqStartToSet = seqStartInput ? [`start:${seqStartInput}`] : []
      const seqEndToSet = seqEndInput ? [`end:${seqEndInput}`] : []

      handleRangeChange(filterId, seqStartToSet.concat(seqEndToSet))
    }
  }

  const handleSeqStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    const valueToSet = isFinite(value) && value > 0 ? value : null
    setSeqStartInput(valueToSet)
  }

  const handleSeqEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    const valueToSet = isFinite(value) && value > 0 ? value : null
    setSeqEndInput(valueToSet)
  }

  return (
    <S.StyledFormControl component="fieldset">
      {alertMessage && (
        <S.StyledAlertContainer>
          <Alert children={alertMessage} variant="page-error" isPersistent />
        </S.StyledAlertContainer>
      )}
      {label && <FormLabel data-test="autocompleteLabel">{label}</FormLabel>}
      <S.SeqRangeContainer>
        <S.InputContainer>
          <TextInput
            key="Number_Input_seq_start"
            label="Start"
            type="number"
            value={seqStartInput ? seqStartInput.toString() : ''}
            onChange={handleSeqStartChange}
          />
          <TextInput
            key="Number_Input_seq_end"
            label="End"
            type="number"
            value={seqEndInput ? seqEndInput.toString() : ''}
            onChange={handleSeqEndChange}
          />
        </S.InputContainer>
        <S.SeqRangeActions>
          <Button variant="primary" onClick={goToRange}>
            Set Range
          </Button>
          <Button variant="borderless" onClick={resetRange}>
            Reset Range
          </Button>
        </S.SeqRangeActions>
      </S.SeqRangeContainer>
    </S.StyledFormControl>
  )
}

export default ActionsMenu
