import React from 'react'
import { isEmpty } from 'lodash'
import MuiSelect from '@material-ui/core/Select'

import * as S from './styled'

function Input({
  classes,
  inputRef,
  refFunc,
  helperText,
  label,
  color,
  isFocused,
  className,
  options,
  error,
  ...otherProps
}: InputProps): React.FunctionComponentElement<InputProps> {
  function handleSelect(
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) {
    otherProps.onChange && otherProps.onChange((e.target.value as any).value || e.target.value)
  }

  function renderInput() {
    if (!isEmpty(options)) {
      return (
        <MuiSelect
          onChange={handleSelect}
          input={<S.StyledInput {...otherProps} color={color as any} data-cy="inputSelect" />}
        >
          {renderOptions()}
        </MuiSelect>
      )
    }

    return (
      <S.StyledInput
        {...otherProps}
        inputRef={
          refFunc &&
          (node => {
            refFunc(node)
            inputRef && inputRef(node)
          })
        }
        color={color as any}
      />
    )
  }

  function renderOptions() {
    return (
      options &&
      options.map(option => (
        <S.MenuItem
          key={option.value === undefined ? option : option.value}
          value={option.value === undefined ? option : option.value}
          data-cy="inputMenuItem"
        >
          <S.SuggestionText>{option.label || option}</S.SuggestionText>
        </S.MenuItem>
      ))
    )
  }

  return (
    <S.FormControl error={!!error} className={className}>
      {label && (
        <S.InputLabel isFocused={isFocused} color={color}>
          {label}
        </S.InputLabel>
      )}
      {renderInput()}
      {helperText && <S.HelpText>{helperText}</S.HelpText>}
      {error && <S.ErrorText>{error}</S.ErrorText>}
    </S.FormControl>
  )
}

interface InputProps {
  classes?: object
  inputRef?: Function
  refFunc?: Function
  helperText?: string
  label?: string
  color: string
  isFocused?: boolean
  className?: string
  options?: Array<any>
  error?: string
  onChange?: any
  type?: string
}

Input.defaultProps = {
  inputRef: () => {},
}

export default Input
