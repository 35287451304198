import * as React from 'react'

function SvgKibana(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group">
          <path
            d="M-1.11022302e-15,7.4999988 L-1.11022302e-15,17.99375 L7.459375,9.4 C5.17114832,8.15227945 2.60629754,7.49897854 -1.11022302e-15,7.4999988 L-1.11022302e-15,7.4999988 Z"
            id="Path"
            fill="#343741"
          ></path>
          <path
            d="M0,0 L0,7.5 C2.60656748,7.49993709 5.1714345,8.15431665 7.459375,9.403125 L15.625,0 L0,0 Z"
            id="Path"
            fill="#F04E98"
          ></path>
          <path
            d="M9.0625,10.40625 L0.740625,20 L15.3125,20 C14.5174126,16.1275949 12.2840534,12.7005054 9.0625,10.409375 L9.0625,10.40625 Z"
            id="Path"
            fill="#00BFB3"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default SvgKibana
