import styled from 'styled-components'

export const Container = styled.div<{
  height?: number
}>`
  font-family: 'Open Sans';
  width: 100%;
  height: ${props => (props.height ? props.height + 'px' : '100%')};
  > div {
    font-size: 0.875rem;
  }

  > div > div {
    padding: 0.375rem;
  }

  && > div > div > span {
    white-space: nowrap;
    line-height: 1.2;
  }

  h4 {
    margin-bottom: 0;
  }
`

export const MiniContainer = styled(Container)`
  padding: 0;
  text-align: center;

  > div {
    padding: 0;
  }
`

export const EmptyBuffer = styled.div<{
  height?: number
}>`
  height: ${props => props.height}px;
`
