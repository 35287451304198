import * as React from 'react'

function SvgTriangle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 25" {...props}>
      <path
        d="M27.685 25H.315L14-.088 27.685 25zm-24-2h20.63L14 4.088 3.685 23z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTriangle
