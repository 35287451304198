import React, { ReactNode } from 'react'
import { camelCase } from 'lodash'
import { useDarkMode } from 'components/App/Providers/DarkMode'
import { IconName } from 'components/shared/icon'
import { colors, Color } from 'components/shared/utils/colors'
import * as S from './styles'

export interface IconButtonProps extends React.PropsWithoutRef<JSX.IntrinsicElements['button']> {
  /** A valid Kite icon name (ie: CheckmarkCircleF.) */
  icon: IconName
  /** How big or small the icon button should be. */
  size?: 'xs' | 'sm' | 'md' | 'lg'
  /** Override the default icon color. */
  color?: string
  /** A React node used to render a custom icon, not found in the Kite library. Overrides the Kite icon if one was provided. */
  customIcon?: ReactNode
  /** Used to prevent interaction and give the user feedback that either the button is preparing to be clickable or was clicked and is processing something. */
  loading?: boolean
  /** Optional props used to tag Icon component */
  'data-pho'?: string
}

const sizes = {
  xs: '36px',
  sm: '40px',
  md: '44px',
  lg: '48px',
}

export const IconButton = ({ size = 'md', color, children, ...props }: IconButtonProps) => {
  const { isDarkMode } = useDarkMode()
  const kiteColor = colors[camelCase(color) as Color] as string | undefined
  return (
    <S.IconButton
      width={sizes[size]}
      variant="borderless"
      iconSize={size}
      iconColor={kiteColor || color}
      $height={sizes[size]}
      $isDarkMode={isDarkMode}
      {...props}
    />
  )
}
