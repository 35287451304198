import moment, { Moment } from 'moment'
import { StepConfigItem } from './types'
import { endOfYesterday, startOfYesterday } from 'date-fns'
import { getFiscalMonthDates } from './fiscalTimeDefinitions'

export enum RelativePeriods {
  LastOneHour = '1h',
  LastTwoHours = '2h',
  LastTwelveHours = '12h',
  LastTwentyFourHours = '24h',
  LastThreeDays = '3d',
  LastSevenDays = '7d',
  LastFourteenDays = '14d',
  LastThirtyDays = '30d',
  Yesterday = '1d',
  VOCLastFiscalWeek = '1fw',
  VOCLastFiscalMonth = '1fm',
  LastTwoMonths = '2mo',
  LastThreeMonths = '3mo',
  LastSixMonths = '6mo',
  LastThirteenMonths = '13mo',
  WeekToDate = 'wtd',
  MonthToDate = 'mtd',
  YearToDate = 'ytd',
}

export enum DateFormats {
  HoursAndMinutes = 'h:mm a',
  DayAndAbbreviatedMonth = 'D MMM',
  DayAbbreviatedMonthYear = 'D MMM YYYY',
}

export type VoCRelativeValue = 'fm' | 'fw' | 'm' | 'w' | 'd'
export const VoCRelativeValues: Array<VoCRelativeValue> = ['fm', 'fw', 'm', 'w', 'd']
export const VoCRelativeRegexValues = VoCRelativeValues.reduce<Record<string, RegExp>>(
  (acc, value) => ({ ...acc, [value]: new RegExp(`^\\d+${value}$`) }),
  {}
)

export const ONE_HOUR_IN_MILLISECONDS = 3600000

export function timeNow(): number {
  return moment().valueOf()
}

export function startOfThisHour(): number {
  return moment().startOf('hour').valueOf()
}

export function todayDayStart(): number {
  return moment().startOf('day').valueOf()
}

export function yesterdayDayStart(): number {
  return moment().subtract(1, 'days').startOf('day').valueOf()
}

export function yesterdayDayEnd(): number {
  return moment().subtract(1, 'days').endOf('day').valueOf()
}

export function sevenDaysBackStart(): number {
  return moment().subtract(7, 'days').startOf('day').valueOf()
}

export function twoWeeksBackStart(): number {
  return moment().subtract(14, 'days').startOf('day').valueOf()
}

export function getDays(days: string): number {
  return moment().subtract(days, 'days').valueOf()
}

export function getHours(hours: string): number {
  return moment().subtract(hours, 'hours').valueOf()
}

export function getMinutes(minutes: string): number {
  return moment().subtract(minutes, 'minutes').valueOf()
}

export function getDayStart(days: string): number {
  return moment().subtract(days, 'days').startOf('day').valueOf()
}

export function getTimestampDayStart(timestamp: number, timezoneOffset?: number): number {
  if (timezoneOffset) {
    return getTimestampForDayPicker(timestamp, timezoneOffset || 0)
  }
  return moment(timestamp).clone().startOf('day').valueOf()
}

export function getTimestampDayEnd(timestamp: number, timezoneOffset?: number): number {
  if (timezoneOffset) {
    return getTimestampForDayPicker(timestamp, timezoneOffset || 0)
  }
  return moment(timestamp).clone().endOf('day').valueOf()
}

export function getLatestTimeAvailable(timestamp: number): number {
  const today = moment().endOf('day').valueOf()
  const timestampEndOfDay = moment(timestamp).endOf('day').valueOf()
  if (today === timestampEndOfDay) {
    return timestamp
  } else return timestampEndOfDay
}

export function getDaysFromStartingTimestamp(
  days: string | number,
  startingTimestamp: number
): number {
  const d = parseInt(`${days}`)
  return moment(startingTimestamp).subtract(d, 'days').valueOf()
}

export function getHoursFromStartingTimestamp(hours: string, startingTimestamp: number): number {
  return moment(startingTimestamp).subtract(hours, 'hours').valueOf()
}

export function getMinutesFromStartingTimestamp(
  minutes: string,
  startingTimestamp: number
): number {
  return moment(startingTimestamp).subtract(minutes, 'minutes').valueOf()
}

export function getLastNthHourInterval(hour: number): Moment {
  const start = moment()
  const remainder = start.hour() % hour
  return moment(start).subtract(remainder, 'hours').startOf('hour')
}

export function getLastNthMinuteInterval(minute: number): Moment {
  const start = moment()
  const remainder = start.minute() % minute
  return moment(start).subtract(remainder, 'minutes').startOf('minute')
}

export function getLastNthSecondInterval(second: number): Moment {
  const start = moment()
  const remainder = start.second() % second
  return moment(start).subtract(remainder, 'seconds').startOf('second')
}

export function lastFiveMinuteInterval(): number {
  return getLastNthMinuteInterval(5).valueOf()
}

export function lastFiveMinuteIntervalYesterday(): number {
  const lastFiveMinuteIntervalToday = getLastNthMinuteInterval(5)
  return moment(lastFiveMinuteIntervalToday).subtract(1, 'days').valueOf()
}

export function lastOneMinuteInterval(): number {
  return getLastNthMinuteInterval(1).valueOf()
}

export function getDistanceInMinutes(start: number = 0, end: number = 0): number {
  const startMoment = moment(start)
  const rawDiffInMinutes = moment.duration(startMoment.diff(moment(end))).asMinutes()

  return Math.floor(Math.abs(rawDiffInMinutes))
}

export function getDistanceInHours(start: number = 0, end: number = 0): number {
  const startMoment = moment(start)
  const rawDiffInHours = moment.duration(startMoment.diff(moment(end))).asHours()

  return Math.floor(Math.abs(rawDiffInHours))
}

export function getDistanceInDays(start: number = 0, end: number = 0): number {
  const startMoment = moment(start)
  const rawDiffInDays = moment.duration(startMoment.diff(moment(end))).asDays()

  return Math.floor(Math.abs(rawDiffInDays))
}

export function getPeriodInHours(period: string): number {
  const normalizedPeriod = normalizeToAbsolutePeriod(period)
  const [dateFrom, dateTo] = normalizedPeriod.split(':')

  //need startOf hour to not take in account lastFiveMinuteInterval
  const dateFromString = moment(parseInt(dateFrom)).startOf('hour')
  const dateToString = moment(parseInt(dateTo))
  return dateToString.diff(dateFromString, 'hours')
}

export function msToOpenSearchTimeFormat(period: string): {
  openSearchFrom: string
  openSearchTo: string
} {
  const [dateFrom, dateTo] = period.split(':')
  const openSearchFrom = `${moment
    .unix(parseInt(dateFrom) / 1000)
    .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`
  const openSearchTo = `${moment.unix(parseInt(dateTo) / 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`

  return { openSearchFrom, openSearchTo }
}

export function getDaysToDate(relativePeriod: string): number {
  if (relativePeriod?.includes('w')) {
    return 7 - moment().isoWeekday()
  } else if (relativePeriod?.includes('m')) {
    return moment().date()
  } else {
    return moment().dayOfYear() * 30
  }
}

export function getFiscalWeek(offset: number): string {
  const today = moment().format('dddd')
  // New fiscal week starts on Saturday and ends on Friday, so "last fiscal week" depends on current date
  const isDateInNextFiscalWeek = ['Saturday'].includes(today)
  const weekToSubtract = isDateInNextFiscalWeek ? offset - 1 : offset
  const lastFiscal = moment().subtract(weekToSubtract, 'weeks')
  const startDate = moment(lastFiscal).startOf('week').subtract(1, 'days')
  const endDate = offset === 0 ? moment() : moment(lastFiscal).endOf('week').subtract(1, 'day')

  return `${startDate.startOf('day').valueOf()}:${endDate.endOf('day').valueOf()}`
}

export function getFiscalMonth(offset: number): string {
  const todaysNumber = moment().date()
  // New fiscal month starts on the 29th, so "last fiscal month" depends on current date
  const isDateInNextFiscalMonth = [29, 30, 31].includes(todaysNumber)
  const monthsToSubtract = isDateInNextFiscalMonth ? offset - 1 : offset

  // We're using moment.subtract to handle the start of the new year so we don't get to month -1
  const lastFiscal = moment().subtract(monthsToSubtract, 'months')
  const lastFiscalMonth = lastFiscal.month()
  const todaysYear = lastFiscal.year()

  const isLeapYear = lastFiscal.isLeapYear()

  const { start: startDates, end: endDates } = getFiscalMonthDates(
    todaysYear,
    lastFiscalMonth,
    isLeapYear
  )

  const startDate = moment(startDates)
  const endDate = offset === 0 ? moment() : moment(endDates)

  return `${startDate.startOf('day').valueOf()}:${endDate.endOf('day').valueOf()}`
}

export function getYesterday(): string {
  const start = moment(startOfYesterday()).valueOf()
  const end = moment(endOfYesterday()).valueOf()
  return `${start}:${end}`
}

export function getEndOfYesterday(): number {
  return moment(endOfYesterday()).valueOf()
}

export function getAbsolutePeriod(
  relativePeriod: string,
  separator: string,
  unit: 'months' | 'weeks' | 'days',
  offset: number
) {
  const period = parseInt(relativePeriod.split(separator)[0])

  // All filters should start at yesterday except for '1d'
  let endDate = period === 1 && unit === 'days' ? moment() : moment().subtract(1, 'day')

  const unitsToSubtract = period * offset
  endDate.subtract(unitsToSubtract, unit)

  if (endDate > moment()) endDate = moment().endOf('day')
  return `${moment(endDate)
    .subtract(period - 1, unit)
    .startOf('day')
    .valueOf()}:${endDate.endOf('day').valueOf()}`
}

export function getAbsoluteFromRelativePeriodVoC(
  relativePeriod: string,
  periodOffset?: string | number
): string {
  const offset = parseInt(`${periodOffset || 0}`)
  if (relativePeriod?.match(VoCRelativeRegexValues['fm'])) return getFiscalMonth(offset)
  else if (relativePeriod?.match(VoCRelativeRegexValues['fw'])) return getFiscalWeek(offset)
  else if (relativePeriod?.match(VoCRelativeRegexValues['m']))
    return getAbsolutePeriod(relativePeriod, 'm', 'months', offset)
  else if (relativePeriod?.match(VoCRelativeRegexValues['w']))
    return getAbsolutePeriod(relativePeriod, 'w', 'weeks', offset)
  return getAbsolutePeriod(relativePeriod || '7d', 'd', 'days', offset)
}

export function getAbsoluteFromRelativePeriod(relativePeriod: string): string {
  if (relativePeriod?.includes('td')) {
    const days = getDaysToDate(relativePeriod)
    return `${getDaysFromStartingTimestamp(
      days,
      lastFiveMinuteInterval()
    )}:${lastFiveMinuteInterval()}`
  }
  if (relativePeriod?.includes('mo')) {
    const days = Number(relativePeriod.split('mo')[0]) * 30
    return `${getDaysFromStartingTimestamp(
      days,
      lastFiveMinuteInterval()
    )}:${lastFiveMinuteInterval()}`
  }
  if (relativePeriod?.includes('d')) {
    const days = relativePeriod.split('d')[0]
    return `${getDaysFromStartingTimestamp(
      days,
      lastFiveMinuteInterval()
    )}:${lastFiveMinuteInterval()}`
  } else if (relativePeriod?.includes('h')) {
    const hours = relativePeriod.split('h')[0]
    return `${getHoursFromStartingTimestamp(
      hours,
      lastFiveMinuteInterval()
    )}:${lastFiveMinuteInterval()}`
  } else if (relativePeriod?.includes('m')) {
    const minutes = relativePeriod.split('m')[0]
    return `${getMinutesFromStartingTimestamp(
      minutes,
      lastFiveMinuteInterval()
    )}:${lastFiveMinuteInterval()}`
  } else {
    return `${getDaysFromStartingTimestamp(
      '7',
      lastFiveMinuteInterval()
    )}:${lastFiveMinuteInterval()}`
  }
}

export function normalizeToAbsolutePeriod(period: string): string {
  if (!period) return ''
  const relativePeriods = Object.values(RelativePeriods) as string[]
  return relativePeriods.includes(period as string)
    ? getAbsoluteFromRelativePeriod(period as string)
    : period
}

export function normalizeToAbsolutePeriodVoC(period: string): string {
  if (!period) return ''
  const relativePeriods = Object.values(RelativePeriods) as string[]
  return relativePeriods.includes(period as string)
    ? getAbsoluteFromRelativePeriodVoC(period as string)
    : period
}

export function getRelativeValueIsValid(period: string): boolean {
  return Object.values(VoCRelativeRegexValues).some(regex => period.match(regex))
}

const stepConfig = [
  { value: 60000, label: '1min', getLastIncrement: lastOneMinuteInterval },
  { value: 300000, label: '5min', getLastIncrement: lastFiveMinuteInterval },
  {
    value: 600000,
    label: '10min',
    getLastIncrement: (): number => getLastNthMinuteInterval(10).valueOf(),
  },
  {
    value: 1800000,
    label: '30min',
    getLastIncrement: (): number => getLastNthMinuteInterval(30).valueOf(),
  },
  {
    value: 3600000,
    label: '1h',
    getLastIncrement: (): number => getLastNthHourInterval(1).valueOf(),
  },
  {
    value: 43200000,
    label: '12h',
    getLastIncrement: (): number => getLastNthHourInterval(12).valueOf(),
  },
  { value: 86400000, label: '1d', getLastIncrement: todayDayStart },
  { value: 172800000, label: '2d', getLastIncrement: todayDayStart },
]

export function getLastIncrementFromStep(step: number, showPartialData: boolean = false): number {
  if (showPartialData) {
    return getLastNthSecondInterval(30).valueOf()
  }
  const stepConfigItem = (stepConfig.find(steps => steps.value === step) as StepConfigItem) || {
    getLastIncrement: lastFiveMinuteInterval,
  }

  return stepConfigItem.getLastIncrement()
}

export function getNextIncrementFromStep(step: number, showPartialData: boolean = false): number {
  const previousIncrement = getLastIncrementFromStep(Number(step), showPartialData)
  const stepToAdd = showPartialData ? 30000 : step
  return moment(previousIncrement).add(stepToAdd).valueOf()
}

export function getTimestampForDayPicker(timestamp: number, timezoneOffset: number): number {
  return timestamp + timezoneOffset
}

export function getPrintableStepName(step: number | string): undefined | StepConfigItem {
  return stepConfig.find(({ value: stepValue }) => stepValue === step)
}

export function getIsToDateYesterday(period: string) {
  const toDate = period.split(':')[1]

  const yesterday = moment().subtract(1, 'day')
  const toDateMoment = moment.unix(parseInt(toDate) / 1000)

  return toDateMoment.isSame(yesterday, 'day')
}

export function getDefaultTimeFilterRange() {
  const relativePeriods = Object.values(RelativePeriods) as string[]
  const isRelativePeriod = relativePeriods.includes(
    process.env.REACT_APP_DEFAULT_TIME_FILTER_RANGE as string
  )
  return isRelativePeriod
    ? process.env.REACT_APP_DEFAULT_TIME_FILTER_RANGE
    : RelativePeriods.LastTwentyFourHours
}

export function getDefaultTimeFilterStep() {
  const steps = stepConfig.map(step => step.value)
  const step = parseInt(process.env.REACT_APP_DEFAULT_TIME_FILTER_STEP || '')
  const isStep = steps.includes(step)
  return isStep ? step : ONE_HOUR_IN_MILLISECONDS
}
