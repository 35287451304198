import * as React from 'react'

function SvgPdf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M21 0H5a3.009 3.009 0 00-3 3v10H0v8h2v7h19a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3zM2 15h9v4H2v-4zm20 10a1.003 1.003 0 01-1 1H4v-5h9v-8H4V3a1.003 1.003 0 011-1h16c.552.002.998.448 1 1v22z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPdf
