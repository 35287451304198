import React from 'react'
import * as S from './styles'

interface ScreenContentContainerProps {
  children: React.ReactNode
  'data-pho'?: string
  'data-bg'?: string
  docsScreen?: boolean
}

export default function ScreenContentContainer({
  children,
  ...props
}: ScreenContentContainerProps) {
  return (
    <S.ScreenContentContainer
      id="content-container"
      data-pho={props['data-pho']}
      bg={props['data-bg']}
    >
      <S.ScreenContent docsScreen={props.docsScreen}>{children}</S.ScreenContent>
    </S.ScreenContentContainer>
  )
}

export function FullScreenContentContainer({ children, ...props }: ScreenContentContainerProps) {
  return (
    <S.ScreenContentContainer
      id="content-container"
      data-pho={props['data-pho']}
      bg={props['data-bg']}
    >
      <S.FullScreenContent docsScreen={props.docsScreen}>{children}</S.FullScreenContent>
    </S.ScreenContentContainer>
  )
}
