import map from 'lodash/map'
import omit from 'lodash/omit'
import difference from 'lodash/difference'

export function getDataWithoutColumnNullValues(chartData: Record<string, any>[]) {
  const columnsNames = chartData.reduce(
    (acc: any, data: Record<string, any>) => {
      map(data, (value, key) => {
        if (value && !acc.columnsWithData.includes(key)) {
          acc.columnsWithData.push(key)
        }
        if (!acc.allColumns.includes(key)) {
          acc.allColumns.push(key)
        }
      })
      return acc
    },
    { allColumns: [], columnsWithData: [] }
  )

  return chartData.reduce((acc: Record<string, any>[], data: Record<string, any>) => {
    const columnsWithoutData: string[] = difference(
      columnsNames.allColumns,
      columnsNames.columnsWithData
    )
    return acc.concat([omit(data, columnsWithoutData)])
  }, [])
}
