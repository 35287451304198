import styled, { css } from 'styled-components'
import { isNil } from 'lodash'
import Flex from 'components/shared/flex/Flex'
import Typography from 'components/shared/typography/Typography'
import { colors } from 'components/shared/utils/colors'

interface BadgeProps {
  $isDarkMode: boolean
  $backgroundColor?: string
}

interface BadgeTypographyProps {
  $color?: string
  $paddingLeftRight?: number
}

export const Badge = styled(Flex).attrs({
  minWidth: 20,
  alignItems: 'center',
  justifyContent: 'center',
})<BadgeProps>`
  border-radius: ${({ minWidth }) => minWidth / 2}px;
  background-color: ${({ $backgroundColor, $isDarkMode }) =>
    $backgroundColor || ($isDarkMode ? colors.red10 : colors.red20)};
`

export const BadgeTypography = styled(Typography).attrs({
  variant: 'caption',
  scale: 'small',
  noMargin: true,
})<BadgeTypographyProps>`
  color: ${({ $color }) => $color};
  font-weight: 500;
  ${({ $paddingLeftRight }) =>
    !isNil($paddingLeftRight) &&
    css`
      padding-left: ${$paddingLeftRight}px;
      padding-right: ${$paddingLeftRight}px;
    `}
`
