import * as React from 'react'

function SvgPinF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M23.12 19v1H13v8h-2v-8H.88v-1a11.159 11.159 0 015.83-9.78L7.51 2H4.88V0h14.24v2h-2.63l.8 7.22A11.159 11.159 0 0123.12 19z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPinF
