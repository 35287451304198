import * as React from 'react'

function SvgRouterWaveF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 28" {...props}>
      <path
        d="M15.95 23.46l-.51-3.52.52-16.44A3.005 3.005 0 0013 0H3A3.005 3.005 0 00.04 3.5l.52 16.44-.5 3.47A3 3 0 003 27v1h3v-1h4v1h3v-1a3.001 3.001 0 002.95-3.54zM2.42 21l.14-.94L2.53 19h10.94l-.03 1.06.14.94H2.42z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRouterWaveF
