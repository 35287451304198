import React, { useState, useEffect } from 'react'
import Icon from 'components/shared/icon/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import * as S from './styles'

function SearchBar({
  setSearchText,
  setSelectedFeedbackIds,
  searchText,
  placeholder = 'Search Verbatim',
}: {
  setSearchText: Function
  setSelectedFeedbackIds?: Function
  searchText?: string
  placeholder?: string
}) {
  const [searchBarValue, setSearchBarValue] = useState('')

  useEffect(() => {
    if (searchText !== undefined) {
      setSearchBarValue(searchText)
    }
  }, [searchText])

  function handleKeyDown({ key }: React.KeyboardEvent<HTMLInputElement>) {
    if (key === 'Enter') {
      setSearchText(searchBarValue)
    }
  }

  function handleChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
    setSearchBarValue(value)
  }

  function handleClick() {
    setSelectedFeedbackIds && setSelectedFeedbackIds({})
    setSearchText(searchBarValue)
  }

  return (
    <S.SearchField
      id="input-with-icon-adornment"
      data-pho="feedbackTableSearch"
      endAdornment={
        <InputAdornment position="end" onClick={handleClick} data-pho="feedbackTableSearchButton">
          <Icon name="Search" />
        </InputAdornment>
      }
      value={searchBarValue}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      fullWidth
    />
  )
}

export default SearchBar
