import * as React from 'react'

function SvgArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 28" {...props}>
      <path
        d="M29 13v2H4.473l11.021 11.021-1.414 1.414L.645 14 14.08.565l1.414 1.414L4.473 13z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgArrowLeft
