export const primary = {
    darkBlue10Alt: '#003661',
    darkBlue30: '#001019',
    darkBlue20: '#002133',
    darkBlue10: '#004366',
    blue30: '#0062B2',
    blue20: '#0073D1',
    blue10: '#008CFF',
  }
  
  export const status = {
    green20: '#008516',
    green10: '#00BF1F',
    yellow20: '#FEB533',
    yellow10: '#FFD400',
    red20: '#D6312B',
    red10: '#FF4D4A',
    lightBlue20: '#5DB8FC',
  }
  
  export const neutral = {
    black: '#000000',
    gray40: '#455161',
    gray30: '#63738A',
    gray25: '#9BA9BD',
    gray20: '#D8DDE6',
    gray10: '#F8F8F8',
    white: '#FFFFFF',
    lightBlue10: '#B7CEE5',
  }
  
  export const entity = {
    teal: '#00C6C0',
    salmon: '#F16666',
    purple: '#827CDB',
    orange: '#feb533',
    red: '#983433',
    green: '#589D5A',
  }
  
  export const accent = status.yellow20
  export const border = neutral.gray20
  
  export const lightMode = {
    shadow: 'rgba(0,0,0,0.1)',
    background: neutral.gray10,
    elevation: {
      0: neutral.white,
      1: neutral.white,
      2: neutral.white,
      3: neutral.white,
    },
    text: neutral.black,
    subText: neutral.gray25,
    placeholder: neutral.gray25,
  }
  
  export const darkMode = {
    shadow: 'rgba(0,0,0,0.5)',
    // background: '#081017',
    // elevation: {
    //   0: '#0e161d',
    //   1: '#141c23',
    //   2: '#1b2228',
    //   3: '#21282e',
    // },
    background: '#1B252E',
    elevation: {
      0: '#222F39',
      1: '#28343e',
      2: '#2d3943',
      3: '#333f48',
    },
    text: neutral.white,
    subText: neutral.gray25,
    placeholder: neutral.gray30,
  }
  
  export const colors: Record<string, any> = {
    ...primary,
    ...status,
    ...neutral,
    ...entity,
    accent,
    border,
    darkMode,
    lightMode,
  }
  
  export type Color = keyof typeof colors
  