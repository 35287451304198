import * as React from 'react'

function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M6 0v3h14V0h2v3h4v20a3.003 3.003 0 01-3 3H3a3.003 3.003 0 01-3-3V3h4V0h2zm18 10H2v13a1 1 0 001 1h20a1 1 0 001-1V10zm-3 7v4h-4v-4h4zm3-12H2v3h22V5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCalendar
