import styled from 'styled-components'
import { colors } from 'components/shared/utils/colors'
import Icon from 'components/shared/icon/Icon'
import { fontStyles } from 'components/shared/typography'

export interface StyledInputProps {
  $displayValidationError?: boolean
  disabled?: boolean
  $rows?: number
  $isDarkMode?: boolean
  $width?: string
}

interface StyledInputAttrs extends StyledInputProps {
  $background?: string
  $placeholderColor?: string
  $color?: string
  $counterColor?: string
  $labelColor?: string
  $focusOutlineColor?: string
  $focusBorderColor?: string
  $borderColor?: string
  $textAreaHeight?: number
  $padding?: number
}

interface DocBackgroundWrapperProps {
  $isDarkMode: boolean
}

const getInputBaseAttrs = ({ $isDarkMode, $width }: StyledInputProps): StyledInputAttrs => ({
  $background: $isDarkMode ? colors.darkBlue20 : colors.white,
  $placeholderColor: $isDarkMode ? colors.darkMode.placeholder : colors.lightMode.placeholder,
  $color: $isDarkMode ? colors.darkMode.text : colors.lightMode.text,
  $borderColor: $isDarkMode ? colors.gray30 : colors.gray30,
  $labelColor: $isDarkMode ? colors.darkMode.text : colors.lightMode.text,
  $focusBorderColor: $isDarkMode ? colors.red20 : colors.gray30,
  $focusOutlineColor: $isDarkMode ? colors.blue10 : colors.darkBlue20,
  $counterColor: $isDarkMode ? colors.gray25 : colors.gray30,
  $width: $width ? $width : '14rem',
})

const getInputDisplayValidationErrorAttrs = ({
  $displayValidationError,
  $isDarkMode,
}: StyledInputProps): StyledInputAttrs =>
  $displayValidationError
    ? {
        $borderColor: $isDarkMode ? colors.red10 : colors.red20,
      }
    : {}

const getInputDisabledAttrs = ({ disabled, $isDarkMode }: StyledInputProps): StyledInputAttrs =>
  disabled
    ? {
        $placeholderColor: $isDarkMode ? colors.gray30 : colors.gray25,
        $color: $isDarkMode ? colors.gray30 : colors.gray25,
      }
    : {}

export const StyledInputBase = styled.div<StyledInputAttrs>`
  display: flex;
  flex-direction: column;
  width: ${props => props.$width};
  ${fontStyles};
  label {
    margin-bottom: 0.25rem;
    color: ${props => props.$color};
  }
  label ~ * {
    align-self: flex-end;
    color: ${props => props.$counterColor};
  }
  input,
  textarea {
    ${fontStyles};
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    padding: 0 ${props => props.$padding}px;
    border-radius: 0;
    color: ${props => props.$color};
    background: ${props => props.$background};
    border: 1px solid ${props => props.$borderColor};
  }
  input {
    height: 44px;
  }
  textarea {
    padding: ${props => props.$padding}px;
    height: ${props => props.$textAreaHeight};
    resize: none;
  }
  input:focus,
  textarea:focus {
    outline: solid ${props => props.$focusOutlineColor} 1px;
    border: solid ${props => props.$focusBorderColor} 1px;
  }
  input::placeholder,
  textarea::placeholder {
    color: ${props => props.$placeholderColor};
  }
`

export const StyledInput = styled(StyledInputBase).attrs((props: any) => ({
  ...getInputBaseAttrs(props),
  ...getInputDisplayValidationErrorAttrs(props),
  ...getInputDisabledAttrs(props),
  $textAreaHeight: `${props.$rows > 1 ? props.$rows : 1}em`,
  $padding: 8,
}))``

export const StyledErrorMessage = styled.span`
  font-size: 16px;
  padding-top: 0.2rem;
  color: ${props => colors.red20};
`

export const PasswordContainer = styled.div`
  position: relative;
`

export const StyledIcon = styled(Icon).attrs(props => ({
  color: colors.blue20,
  ...props,
}))`
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
  margin: auto 0 auto;
  cursor: pointer;
`

export const ConfirmIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 1.5rem;
  bottom: 0;
  margin: auto 0.5rem auto;
  color: ${props => colors.green20};
`

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`
export const Input = styled.input``

export const InputMultiLineContainer = styled.div``

export const CustomIcon = styled.div``

export const DocBackgroundWrapper = styled.div<DocBackgroundWrapperProps>`
  padding: 1rem;

  background-color: ${({ $isDarkMode }) =>
    $isDarkMode ? colors.darkMode.background : colors.lightMode.background};
`
