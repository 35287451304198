import * as React from 'react'

function SvgChevronDownCircleF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14C27.992 6.272 21.728.008 14 0zm0 19.16l-7.21-7.2 1.42-1.42 5.79 5.8 5.79-5.8 1.42 1.42-7.21 7.2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronDownCircleF
