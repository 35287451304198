import * as React from 'react'

function SvgAudioDescription(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 20" {...props}>
      <path
        d="M24.24.81a12.988 12.988 0 010 18.38l-1.41-1.41c4.293-4.298 4.293-11.262 0-15.56zm-2.83 2.83a8.98 8.98 0 010 12.72L20 14.95a7.001 7.001 0 000-9.9zM7.996 4v12h-2v-3H2.273l-.28 3.09L0 15.91l.835-9.181A2.985 2.985 0 013.822 4h4.174zm7 0a3.003 3.003 0 013 3v6a3.003 3.003 0 01-3 3h-5V4h5zm0 2h-3v8h3a1 1 0 001-1V7a1 1 0 00-1-1zm-9 0H3.822a.995.995 0 00-.995.91L2.455 11h3.541V6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAudioDescription
