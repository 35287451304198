import * as React from 'react'

function SvgDashboardF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0C6.272.008.008 6.272 0 14v1h.05C.567 22.323 6.659 28 14 28c7.341 0 13.433-5.677 13.95-13H28v-1C27.992 6.272 21.728.008 14 0zM6.07 13A8.004 8.004 0 0114 6a7.924 7.924 0 014.9 1.69l-2.88 2.88A3.89 3.89 0 0014 10a4 4 0 00-3.86 3H6.07zM16 14a2 2 0 11-4 0 2 2 0 014 0zm1 9h-6v-2h6v2zm.86-10a4.166 4.166 0 00-.43-1.02l2.88-2.88a7.941 7.941 0 011.62 3.9h-4.07zm6.09 0a10 10 0 00-19.9 0H2.04a12.002 12.002 0 0123.92 0h-2.01z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDashboardF
