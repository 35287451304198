import * as React from 'react'

function SvgShare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 27" {...props}>
      <path
        d="M21 17a4.985 4.985 0 00-4.383 2.644l-6.959-3.866a4.658 4.658 0 00.159-3.046l7.527-3.346a5.17 5.17 0 10-1.04-1.727l-7.38 3.28a4.99 4.99 0 10-.337 6.53l7.452 4.141c-.01.13-.039.256-.039.39a5 5 0 105-5zm0-14a3 3 0 110 6 3 3 0 010-6zM5 17a3 3 0 110-6 3 3 0 010 6zm16 8a3 3 0 110-6 3 3 0 010 6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgShare
