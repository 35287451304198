import * as React from 'react'

function SvgRefresh(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M17.626 10.54l5.041 5.04 5.04-5.04-1.414-1.414-2.639 2.64a11.817 11.817 0 10-4.72 9.701l-1.201-1.6a9.745 9.745 0 01-5.9 1.966 9.834 9.834 0 119.82-10.094l-2.612-2.613-1.415 1.415z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRefresh
