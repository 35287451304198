import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'
import { Button } from 'components/shared/Button'
import Modal from 'components/shared/Modal'
import useAuth from 'hooks/useAuth'
import Krypto from './Krypto'
import analytics from 'utils/analytics'
import * as S from './styles'

function DecryptButton() {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const location = useLocation()
  const auth = useAuth()
  const hasTransformerAccess = auth.user?.roles.includes('transformer_access')
  const hasTransformerDecryptAccess = auth.user?.roles.includes('transformer-decrypt')

  function handleClick() {
    return setIsModalOpen(true)
  }

  function handleGoBackToSite() {
    setIsModalOpen(false)
  }

  const currPageAppSection = analytics.getCurrentPageAppSection(location)

  useEffect(() => {
    if (isModalOpen) {
      analytics.trackPageViewInit({
        currPageTitle: 'Krypto Modal',
        currPageAppSection,
        currPageName: 'kryptoModal',
        currPageIsLazyLoad: false,
        msgTriggeredBy: 'user',
      })
      analytics.trackPageView()
    }
  }, [isModalOpen, currPageAppSection])

  function getModalContent() {
    if (hasTransformerAccess) return <Krypto hasDecryptAccess={hasTransformerDecryptAccess} />
    return (
      <S.DoNotHaveAccessContainer>
        <h3>You do not have permission to use the tool!</h3>
      </S.DoNotHaveAccessContainer>
    )
  }

  return (
    <>
      {hasTransformerAccess && (
        <>
          <Modal
            open={isModalOpen}
            onClose={() => handleGoBackToSite()}
            xClick={() => handleGoBackToSite()}
            content={getModalContent()}
            justifyActionsContent="center"
            title={hasTransformerDecryptAccess ? 'Encrypt and Decrypt values' : 'Encrypt values'}
            PaperProps={{ style: { maxWidth: '100%' } }}
          />
          <Button onClick={handleClick} variant="neutral" icon="Lock" data-pho="topbarKrypto">
            Krypto
          </Button>
        </>
      )}
    </>
  )
}

export default DecryptButton
