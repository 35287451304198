import * as React from 'react'

function SvgAndroid(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 16" {...props}>
      <path
        d="M20.444 11.898a1.166 1.166 0 110-2.332 1.166 1.166 0 010 2.332m-12.888 0a1.166 1.166 0 110-2.332 1.166 1.166 0 010 2.332m13.306-7.023l2.33-4.036a.485.485 0 10-.84-.484l-2.36 4.086a14.648 14.648 0 00-11.985 0L5.647.354a.485.485 0 10-.839.485l2.33 4.036A13.758 13.758 0 000 15.888h28a13.76 13.76 0 00-7.138-11.013"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAndroid
