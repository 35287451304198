import React from 'react'
import { Dropdown } from 'components/shared/Dropdown'
import MenuList from '@material-ui/core/MenuList'
import PopoverMenu from 'components/shared/popover'
import DocsList from './DocsList'
import * as S from './styles'

function Popover({ handleClose }: PopoverProps) {
  return (
    <S.MenuContainer>
      <MenuList>
        <DocsList handleClose={handleClose} />
      </MenuList>
    </S.MenuContainer>
  )
}

interface PopoverProps {
  handleClose: (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void
}

function DocsSwitcher() {
  return (
    <>
      <div data-pho="topbarDocsSwitcher">
        <PopoverMenu anchorContent={<Dropdown label="Docs" placeholder="" items={[]} isInline />}>
          {({ isPopoverOpen, handleClose }) =>
            isPopoverOpen ? <Popover handleClose={handleClose} /> : null
          }
        </PopoverMenu>
      </div>
    </>
  )
}

export default DocsSwitcher
