import React from 'react'
import PrismReactErrorMessage from './PrismReactErrorMessage'
import useAuth from 'hooks/useAuth'
import analytics from 'utils/analytics'

function ErrorMessage({
  title,
  copy,
  includeMailTo,
  errorMessage,
  errorType,
  mailToDetails,
}: ErrorMessageProps) {
  const auth = useAuth()
  const accessTokenExp = auth.exp || 0
  const login = auth.login

  React.useEffect(() => {
    const now = new Date()

    analytics.track('error', {
      appErrorType: errorType ? errorType : 'generic',
      ...(errorMessage ? { appErrorMessage: errorMessage } : {}),
    })

    if (accessTokenExp < now.getUTCSeconds()) {
      analytics.track('error', {
        appErrorType: 'authentication',
        appErrorMessage: 'User not authenticated.',
      })
      login()
    }
  }, [errorMessage, errorType, accessTokenExp, login])
  return (
    <PrismReactErrorMessage
      title={title}
      {...{ includeMailTo, copy: copy ?? errorMessage }}
      errorMessage={errorMessage}
      errorType={errorType}
      mailToDetails={mailToDetails}
      data-pho="errorMessage"
    />
  )
}

interface ErrorMessageProps {
  title?: string
  copy?: React.ReactNode
  includeMailTo?: boolean
  errorMessage: string
  errorType?: 'application' | 'api' | 'generic'
  mailToDetails?: {
    text: string
    email: string
    subject: string
    body: string
  }
}

export default ErrorMessage
