import styled, { css } from 'styled-components'
import { colors } from 'components/shared/utils/colors'
import { lightMode } from 'components/shared/utils/elevation'
import { element } from 'components/shared/utils/spacing'
import { DateRangePicker as rdrDateRangePicker } from 'react-date-range'

interface DateRangePickerProps {
  $hideStaticRangesSection: boolean
  $isDarkMode: boolean
}

interface MenuProps {
  $isDarkMode: boolean
  $height?: number
  $isScrollable?: boolean
}

export const MenuContainer = styled.div`
  position: relative;
`
export const ErrorMessage = styled.div`
  color: ${colors.red10};
  margin: 0.2rem 0 0 0.5rem;
  font-size: 0.9rem;
`

export const Menu = styled.div<MenuProps>`
  height: ${({ $height }) => ($height ? `${$height}px` : 'unset')};
  overflow-y: ${({ $isScrollable }) => ($isScrollable ? 'scroll' : 'hidden')};
  overflow-x: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  right: 0;
  border: 1px solid ${colors.gray20};
  z-index: 100;
  background-color: ${({ $isDarkMode }) => ($isDarkMode ? colors.darkBlue10 : colors.white)};

  && {
    ${lightMode[2]}
  }
`

export const ActionBar = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: ${element.sm};
  border-bottom: 1px solid ${colors.gray20};
`

export const DateRangePicker = styled(rdrDateRangePicker).attrs({
  showDateDisplay: false,
  color: colors.blue10,
  rangeColors: [colors.blue10],
})<DateRangePickerProps>`
  font-family: 'Spectrum Sans', 'Open Sans', 'San Francisco', 'Helvetica', 'Arial', sans-serif;
  justify-content: center;

  .rdrStaticRanges {
    max-height: 330px;
    overflow-y: auto;
  }

  .rdrStaticRange {
    background: ${({ $isDarkMode }) => ($isDarkMode ? colors.darkBlue10 : colors.white)};
  }

  .rdrInputRanges {
    padding: 0;
  }

  ${({ $hideStaticRangesSection }) =>
    $hideStaticRangesSection &&
    css`
      .rdrDefinedRangesWrapper {
        display: none;
      }
    `}
`

export const Range = styled.div<{
  isDarkMode?: boolean
}>`
  padding: 0.25rem;
  user-select: none;
  cursor: pointer;

  :hover {
    background-color: ${({ isDarkMode }) => (isDarkMode ? colors.gray30 : colors.gray20)};
  }
`

export const AccordionContainer = styled.div`
  overflow-y: scroll;
  margin-top: -1px;
  border-right: 1px solid ${colors.gray20};
`

export const DateRangeAlert = styled.div`
  padding-bottom: 10px;
`
