import * as React from 'react'

function SvgDownload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M2 16v5a1 1 0 001 1h22a1 1 0 001-1v-5h2v5a3.003 3.003 0 01-3 3H3a3.003 3.003 0 01-3-3v-5h2zM15 0v15.795l6.342-5.548 1.317 1.505L14 19.329l-8.658-7.577 1.317-1.505L13 15.795V0h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDownload
