import { services } from 'common'
import { axios, axiosConfigBase } from './useApi'
import { Method } from 'axios'
import { rootApiUrl } from 'common/constants'

type AccessLogFunction = (action: string) => void

interface UserAccessLogTool {
  name: string
  operations: string[]
}

interface AccessUser {
  keycloakID: string
  email: string
  roles: string[]
}

interface UserAccessLogResponse {
  id: string
  timestamp: string
  tools: UserAccessLogTool[]
  user: AccessUser
}

export function useUserAccessLog(tool: string, roles: string[] = []): AccessLogFunction {
  return (...actions: string[]) => {
    const id = localStorage.getItem('id') as string

    const axiosConfig = {
      ...axiosConfigBase(),
      method: 'POST' as Method,
      data: {
        tool: {
          name: tool,
          operations: actions ?? [],
        },
        roles,
      },
      url: `${rootApiUrl}${services.userAccessLog.url}/${id}`,
    }

    axios({ ...axiosConfig })
      .then(() => {
        console.log('Successfully logged action')
      })
      .catch((err: any) => {
        throw new Error('User update failed: ' + err.message)
      })
  }
}
