import * as React from 'react'

function SvgRecordCancel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M13 0C5.82 0 0 5.82 0 13s5.82 13 13 13 13-5.82 13-13C25.992 5.824 20.176.008 13 0zm11 13a10.947 10.947 0 01-2.552 7.034L5.966 4.552A10.992 10.992 0 0124 13zM2 13a10.947 10.947 0 012.552-7.034l15.482 15.482A10.992 10.992 0 012 13z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRecordCancel
