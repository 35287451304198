import React from 'react'
import isNil from 'lodash/isNil'
import { IconButton } from 'components/shared/IconButton'
import Typography from 'components/shared/typography/Typography'
import * as T from '../types'
import * as S from '../styles'

const Heading = ({
  title,
  handleEditMode,
  isEditMode,
  setIsEditMode,
  dataTest,
  margin,
}: T.HeadingProps) => {
  const handleEditModeDefault = () => {
    !!(setIsEditMode || false) && setIsEditMode(!isEditMode)
  }
  const viewOnlyMode = isNil(isEditMode)
  const EditIcon = ({ viewOnlyMode }: { viewOnlyMode: boolean }) => (
    <>
      {viewOnlyMode ? (
        <></>
      ) : (
        <IconButton
          icon={isEditMode ? 'EditF' : 'Edit'}
          onClick={handleEditMode ?? handleEditModeDefault}
        />
      )}
    </>
  )
  return (
    <S.TitleContainer margin={margin}>
      <Typography variant="title6" noMargin data-test={dataTest ? dataTest : 'heading'}>
        {title}
      </Typography>
      <EditIcon viewOnlyMode={viewOnlyMode} />
    </S.TitleContainer>
  )
}

export default Heading
