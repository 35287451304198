import * as React from 'react'

function SvgModem(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 28" {...props}>
      <path
        d="M9 0a3.003 3.003 0 013 3v23h2v2H0v-2h2V3a3.003 3.003 0 013-3h4zm0 2H5a1 1 0 00-1 1v23h6V3a1 1 0 00-1-1zM8 19v2H6v-2h2zm0-4v2H6v-2h2zm0-4v2H6v-2h2zm0-4v2H6V7h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgModem
