import * as React from 'react'

function SvgLaptop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 22" {...props}>
      <path
        d="M26 16V0H2v16H0v3a3.003 3.003 0 003 3h22a3.003 3.003 0 003-3v-3h-2zM4 2h20v14H4V2zm22 17a1 1 0 01-1 1H3c-.552-.001-1-.448-1-1v-1h24v1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLaptop
