import styled, { css } from 'styled-components'
import { fontStyles } from '../typography'
import { durations, timingFunctions } from 'components/shared/utils/transition'
import { colors } from 'components/shared/utils/colors'

interface StyledLinkProps {
  $variant: string
  $isDarkMode?: boolean
  $disableVisited?: boolean
  $disableUnderline?: boolean
  disabled?: boolean
}

export const Link = styled.a<StyledLinkProps>`
  white-space: nowrap;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  text-decoration: ${({ $variant }) => ($variant === 'inline' ? 'underline' : 'none')};
  ${fontStyles}
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &,
  span {
    transition-property: color;
    transition-duration: ${durations.short};
    transition-timing-function: ${timingFunctions.standard};
  }

  ${({ disabled, $isDarkMode }) => {
    const color = $isDarkMode ? colors.white : colors.blue20
    return css`
      &,
      > span {
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        color: ${disabled ? colors.gray30 : color};
      }
    `
  }}

  ${({ disabled, $isDarkMode, $variant, $disableUnderline }) => {
    const color = $isDarkMode ? colors.gray25 : colors.darkBlue10
    return css`
      :hover,
      :hover > span {
        text-decoration: ${$variant === 'standalone' && (disabled || $disableUnderline)
          ? 'none'
          : 'underline'};
        color: ${disabled ? colors.gray30 : color};
      }
    `
  }}

  ${({ disabled, $isDarkMode, $variant }) => {
    const color = $isDarkMode ? colors.gray30 : colors.darkBlue20
    return css`
      :active,
      :active > span {
        text-decoration: ${$variant === 'standalone' && disabled ? 'none' : 'underline'};
        color: ${disabled ? colors.gray30 : color};
      }
    `
  }}

  ${({ disabled, $disableVisited, $isDarkMode }) => {
    const color = $isDarkMode ? colors.gray30 : colors.darkBlue20
    return (
      !$disableVisited &&
      css`
        :visited,
        :visited > span {
          color: ${$isDarkMode ? colors.gray25 : colors.darkBlue10};
        }
        :active,
        :active > span {
          text-decoration: underline;
          color: ${disabled ? colors.gray30 : color};
        }
      `
    )
  }}
`
