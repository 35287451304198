import * as React from 'react'

function SvgStreamingBoxF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M0 10v11a3.009 3.009 0 003 3h22a3.009 3.009 0 003-3V10H0zm8 10H4v-2h4v2zm15 0a1 1 0 110-2 1 1 0 010 2zm3-6H2v-2h24v2zM22.568 3.904a20.061 20.061 0 00-17.136 0l-.852-1.81a22.06 22.06 0 0118.84 0l-.852 1.81zm-2.956 4.019a14.58 14.58 0 00-11.224 0l-.766-1.848a16.562 16.562 0 0112.756 0l-.766 1.848z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgStreamingBoxF
