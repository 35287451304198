import * as React from 'react'

function SvgPlay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 26" {...props}>
      <path
        d="M0 26V0l20.893 13L0 26zM2 3.6v18.8L17.108 13 2 3.6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPlay
