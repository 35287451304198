import { useState } from 'react'
import { RoundedSearch } from 'components/shared/Search'
import PopoverMenu from 'components/shared/popover'
import Applications from './Applications'
import { Dropdown } from 'components/shared/Dropdown'
import * as S from './styles'

export default function ApplicationSwitcher() {
  const [searchInput, setSearchInput] = useState('')
  const [isViewingAll, setIsViewingAll] = useState(false)

  function resetData() {
    setSearchInput('')
    setIsViewingAll(false)
  }

  function handleClickViewAll() {
    setIsViewingAll(true)
  }

  return (
    <div data-pho="topbarApplicationSwitcher">
      <PopoverMenu
        onClose={resetData}
        anchorContent={<Dropdown label="Application" placeholder="" items={[]} isInline />}
      >
        {({ isPopoverOpen, handleClose }) =>
          isPopoverOpen && (
            <S.MenuContainer>
              <RoundedSearch input={searchInput} onChangeInput={setSearchInput} />
              <Applications
                searchInput={searchInput}
                isViewingAll={isViewingAll}
                handleClickViewAll={handleClickViewAll}
                handleApplicationSwitcherClose={handleClose}
              />
            </S.MenuContainer>
          )
        }
      </PopoverMenu>
    </div>
  )
}
