import React from 'react'
import compact from 'lodash/compact'
import { Link } from '@reach/router'
import { IconButton } from 'components/shared/IconButton'
import useGroups from 'hooks/useGroups'
import usePrismUser from 'hooks/usePrismUser'
import Entity from 'components/shared/Entity'
import * as S from '../../styles'

export default function GroupFavoritesList({
  groupFavorites,
  isEditGroupsMode,
  setIsEditGroupsMode,
  handleClose,
}: GroupFavoritesListProps) {
  const { groups } = useGroups()
  const prismUser = usePrismUser()

  const matchedGroups = groupFavorites.map((groupId: string) => {
    return groups.find(group => group._id === groupId)
  })

  async function handleDeleteFavorite(groupIdToDelete: string) {
    const updatedFavorites = (prismUser.groupFavorites || []).filter(
      group => group !== groupIdToDelete
    )

    await prismUser.update({ groupFavorites: compact(updatedFavorites) })
    groupFavorites.length === 1 && setIsEditGroupsMode(false)
  }

  return (
    <div>
      {matchedGroups.map((group: any) => {
        if (isEditGroupsMode) {
          return (
            <div key={`${group._id}_editing`}>
              <S.FavoriteContainer>
                <Entity
                  title={group.name}
                  description={group.description}
                  data-pho={`${group.name}-editing`}
                />
                {isEditGroupsMode && (
                  <IconButton
                    icon="TrashF"
                    size="sm"
                    onClick={() => handleDeleteFavorite(group._id)}
                  />
                )}
              </S.FavoriteContainer>
            </div>
          )
        } else
          return (
            <Link key={group._id} to={`/${group._id}`} onClick={() => handleClose()}>
              <S.FavoriteContainer>
                <Entity title={group.name} description={group.description} data-pho={group.name} />
              </S.FavoriteContainer>
            </Link>
          )
      })}
    </div>
  )
}

interface GroupFavoritesListProps {
  groupFavorites: string[]
  isEditGroupsMode: boolean
  setIsEditGroupsMode: Function
  handleClose: Function
}
