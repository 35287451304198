import * as React from 'react'

function SvgRecordSeries(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 14" {...props}>
      <path
        d="M21 0H7a7 7 0 000 14h14a7 7 0 000-14zM2 7a5 5 0 1110 0A5 5 0 012 7zm9.895 5l.027-.03c.079-.077.15-.162.226-.243.077-.085.158-.167.231-.255.073-.087.138-.18.206-.27.068-.09.138-.177.202-.27.065-.096.123-.198.184-.297.057-.094.118-.185.171-.282.057-.105.106-.214.158-.322.047-.097.098-.193.141-.293.048-.113.087-.23.13-.347.036-.1.077-.197.109-.299.04-.128.07-.26.102-.39.024-.095.053-.187.073-.284.032-.153.051-.31.073-.467.01-.078.027-.153.035-.231.05-.479.05-.961 0-1.44-.008-.078-.025-.153-.035-.23-.022-.157-.041-.315-.073-.468-.02-.097-.05-.19-.073-.284-.033-.13-.062-.263-.102-.39-.032-.102-.073-.2-.11-.3-.042-.115-.08-.233-.129-.346-.043-.1-.094-.196-.141-.293-.052-.108-.1-.217-.158-.322-.053-.097-.114-.188-.17-.281-.062-.1-.12-.202-.185-.298-.064-.094-.135-.18-.202-.27-.068-.09-.134-.183-.206-.27-.073-.088-.154-.17-.231-.255-.075-.081-.147-.166-.226-.244-.01-.009-.018-.02-.027-.029H14a5 5 0 010 10h-2.105zM21 12h-2.11a6.978 6.978 0 000-10H21a5 5 0 010 10z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRecordSeries
