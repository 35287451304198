import * as React from 'react'

function SvgTruckF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 12h-1.31l-1.5-4-.77-2.05A2.972 2.972 0 0019 4.03V2h3V0H0v2h3v2a3.009 3.009 0 00-3 3v12a3.009 3.009 0 003 3h1.35a3.48 3.48 0 006.3 0h6.7a3.48 3.48 0 006.3 0H28v-7a3.009 3.009 0 00-3-3zm-8-8h-5V2h5v2zM5 2h5v2H5V2zm2.5 20a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM14 12V8h6l1.56 4H14zm6.5 10a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTruckF
