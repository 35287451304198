import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

export const TopChartContainer = styled.div<{ isTopChartWide?: Boolean }>`
  min-width: 0;
  height: 100%;
  flex: ${({ isTopChartWide }) => (isTopChartWide ? 1 : 1)};
  padding-right: 50px;
`

export const BarChartContainer = styled.div<{ isTopChartWide?: Boolean }>`
  min-width: 0;
  height: 100%;
  flex: ${({ isTopChartWide }) => (isTopChartWide ? 1 : 2)};
`

export const MiniContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
`
