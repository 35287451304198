import * as React from 'react'

function SvgDoorbell(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M24 18.597l-4-5v-2.299a8.498 8.498 0 00-5-7.699V0H9v3.6a8.3 8.3 0 00-2.6 1.7A7.998 7.998 0 004 10.997v2.6l-4 4.999v5.399h7.1a5 5 0 009.8 0H24v-5.4zM11 2h2v1.1h-2V2zM7.8 6.699a6.1 6.1 0 014.4-1.7 6.2 6.2 0 015.8 6.299v1.7H6v-2a5.798 5.798 0 011.8-4.3zM12 25.995a2.9 2.9 0 01-2.8-2h5.6a2.9 2.9 0 01-2.8 2zm10-3.999H2v-2.6l3.5-4.399h13l3.5 4.4v2.6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDoorbell
