import * as React from 'react'

function SvgPhone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M23.135 26l-.132-.003c-14.379-.667-22.333-8.62-23-23A2.884 2.884 0 01.793.883 2.825 2.825 0 012.855 0h5.9l2.402 8.406L7.6 10.93a19.606 19.606 0 007.577 7.514l2.394-3.607L26 17.246v5.899a2.823 2.823 0 01-.884 2.062 2.882 2.882 0 01-1.98.793zM2.855 2A.861.861 0 002 2.904C2.622 16.281 9.719 23.378 23.096 24a.813.813 0 00.64-.239.844.844 0 00.264-.615v-4.392l-5.571-1.591-2.56 3.854-.783-.374a21.155 21.155 0 01-9.673-9.592l-.366-.76 3.797-2.696L7.246 2H2.854z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPhone
