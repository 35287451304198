import React from 'react'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import CustomLoading from 'components/shared/customLoading'
import FormatData from './FormatData'
import MultiChartData from './MultiChartData'
import SingleChartData from './SingleChartData'
import { DataType } from 'components/shared/chart/types'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import * as T from './types'
import { getDataSource } from './utils'

function ChartData({
  queries,
  rawDataArray,
  types,
  chartType,
  isMultiChart,
  title,
  children,
  isSmall,
  extendContent,
  tabButtonProps,
  filterValues,
  handleTabChange,
  ActionsMenu,
  renderActionsMenu,
  renderTabButtons,
  componentsWithProps,
}: {
  queries?: T.Query[]
  rawDataArray?: T.RawDataObj[]
  types: DataType[]
  chartType?: DataType
  isMultiChart?: boolean
  title: string
  children: T.ChildrenCallback
  isSmall?: boolean
  extendContent?: boolean
  tabButtonProps?: {
    mainTitle?: string
    description?: string
    activeTab: number
    tabTitles: string[]
  }
  filterValues?: Record<string, any>
  handleTabChange?: any
  ActionsMenu?: React.ReactElement
  renderActionsMenu: Function
  renderTabButtons?: Function
  componentsWithProps?: Record<string, any>
}) {
  const dataSources = types.map((source: DataType) => {
    return getDataSource(source)
  })
  const allSourcesEqual = dataSources.every((source, i, dataSources) => source === dataSources[0])

  if (rawDataArray) {
    if (isEmpty(rawDataArray)) {
      return (
        <CustomLoading
          title={title}
          dataSource={allSourcesEqual ? dataSources[0] : dataSources.join('/ ')}
        />
      )
    }
    return (
      <FormatData
        rawDataArray={rawDataArray}
        title={title}
        types={types}
        chartType={chartType}
        filterValues={filterValues}
      >
        {children}
      </FormatData>
    )
  }
  if (isMultiChart && queries) {
    return (
      <MultiChartData
        queries={queries}
        types={types}
        title={title}
        tabButtonProps={tabButtonProps}
        filterValues={filterValues}
        handleTabChange={handleTabChange}
        componentsWithProps={componentsWithProps}
        renderActionsMenu={renderActionsMenu}
        renderTabButtons={renderTabButtons}
      >
        {children}
      </MultiChartData>
    )
  }
  if (queries) {
    return (
      <SingleChartData
        query={first(queries) as T.Query}
        type={first(types) as DataType}
        title={title}
        isSmall={isSmall}
        extendContent={extendContent}
        filterValues={filterValues}
        ActionsMenu={ActionsMenu}
        renderActionsMenu={renderActionsMenu}
      >
        {children}
      </SingleChartData>
    )
  }

  return (
    <NoDataChartMessage
      title={title}
      dataSource={allSourcesEqual ? dataSources[0] : dataSources.join('/ ')}
    />
  )
}

export default ChartData
