import * as React from 'react'

function SvgPlayerInPlayer(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 0a3.003 3.003 0 013 3v18a3.003 3.003 0 01-3 3H0V3a3.003 3.003 0 013-3h22zm0 2H3a1 1 0 00-1 1v19h23a1 1 0 001-1V3a1 1 0 00-1-1zm-1 9v9H12v-9h12zm-2 2h-8v5h8v-5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPlayerInPlayer
