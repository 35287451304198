import * as React from 'react'

function SvgHomeCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 26" {...props}>
      <path
        d="M8 12a4 4 0 110-8 4 4 0 010 8zm0-6a2 2 0 100 4 2 2 0 000-4zm-1 9h2v2H7v-2zm9-1V8A8 8 0 100 8v6a8 8 0 007 7.9V24H2a2 2 0 00-2 2h16a2 2 0 00-2-2H9v-2.1a8 8 0 007-7.9zM2 14V8a6 6 0 1112 0v6a6 6 0 11-12 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHomeCamera
