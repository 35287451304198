import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { FormattedPercentageData } from 'components/shared/ChartData/types'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import * as S from './styled'
import ChartsHeading from 'components/shared/chart/ChartsHeading'

export default function PercentageChart({
  data,
  title,
  percentageLabel,
  mini,
}: {
  data: FormattedPercentageData
  title?: string
  percentageLabel?: string
  mini?: boolean
}) {
  if (isEmpty(data)) {
    return <NoDataChartMessage title={title} />
  }
  return (
    <S.Container mini={mini}>
      <ChartsHeading title={title} />
      <S.Percentage>
        {percentageLabel}
        {data.percentage}%
      </S.Percentage>
    </S.Container>
  )
}
