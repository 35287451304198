import Flex from 'components/shared/flex'
import * as S from './styles'

export interface LoadingSpinnerProps {
  white?: boolean
  small?: boolean
  dialog?: boolean
}

export const LoadingSpinner = ({ dialog, white, small, ...props }: LoadingSpinnerProps) => {
  const Container = Flex
  const size = small ? '24px' : '80px'
  return (
    <Container width="100%" height="100%" minWidth="24px" minHeight="24px" center>
      <S.Spinner
        width={size}
        height={size}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <S.Path $white={white} fill="none" strokeWidth={small ? '6' : '4'} cx="33" cy="33" r="30" />
      </S.Spinner>
    </Container>
  )
}
