import * as React from 'react'

function SvgFolderF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 4h-9.76a.992.992 0 01-.9-.55l-.89-1.79A2.992 2.992 0 0010.76 0H3a3.009 3.009 0 00-3 3v21h25a3.009 3.009 0 003-3V7a3.009 3.009 0 00-3-3zm1 7H2V9h24v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFolderF
