import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'components/shared/utils/colors'
import NivoContainer from 'components/shared/NivoContainer'

export const Container = styled(
  ({
    tooltipYOffset,
    children,
    onMouseMove,
    dataPho,
    ...otherProps
  }: {
    tooltipYOffset?: number
    onMouseMove?: (e: any) => void
    children: React.ReactNode
    dataPho?: string
  }) => <NivoContainer children={children} dataPho={dataPho} {...otherProps} />
)`
  width: 100%;
  height: 100%;
  font-family: 'Open Sans';
  > div > div > div > div > div {
    top: ${props => `${props.tooltipYOffset}px !important`};
    transition: top 0.5s;
  }
`

export const MiniContainer = styled.div`
  width: 100%;
  height: 100%;
  font-family: 'Open Sans';
`

export const MiniChartContainer = styled(Container)`
  height: 33%;
`

export const Info = styled.p<{ isActive?: boolean }>`
  font-size: 0.875rem;
  color: ${colors.gray30};
  margin-bottom: 0.25rem;
  white-space: pre-wrap;
  height: 42px;
  ${props =>
    props.isActive &&
    css`
      font-weight: 500;
    `};
`

export const TooltipContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

export const TooltipContentText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 15px;
`

export const ColorBlock = styled.div<{ color?: string }>`
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${props => props.color};
  margin-right: 0.5rem;
  border-radius: 50%;
`

export const Title = styled.h4<{ isActive?: boolean }>`
  flex-grow: 0;
  ${props =>
    !props.isActive &&
    css`
      font-weight: 400;
    `};
`
