import * as React from 'react'

function SvgBiometrics(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 28" {...props}>
      <path
        d="M10 14v5a7.008 7.008 0 007 7v2a9.01 9.01 0 01-9-9v-5h2zm-3.847 6.834a10.905 10.905 0 002.66 5.497L7.327 27.67a12.905 12.905 0 01-3.146-6.503zM.916 6.6l1.832.8A8.944 8.944 0 002 11v8a14.77 14.77 0 001.518 6.56l-1.795.88A16.744 16.744 0 010 19v-8a10.91 10.91 0 01.916-4.4zM11 8a3 3 0 013 3v8a3 3 0 006 0v-1h2v1a5 5 0 01-10 0v-8a1 1 0 00-2 0v1H8v-1a3 3 0 013-3zm6.903 1.834c.065.385.097.775.097 1.166v9h-2v-9c0-.28-.023-.558-.07-.834zM11 4a7.024 7.024 0 016.063 3.499l-1.731 1.002A5 5 0 006 11v8H4v-8a7.008 7.008 0 017-7zm0-4c6.072.007 10.993 4.928 11 11v5h-2v-5A9 9 0 003.8 5.6L2.2 4.398A10.916 10.916 0 0111 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgBiometrics
