import * as React from 'react'

function SvgAnnouncement(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 26" {...props}>
      <path
        d="M0 16.72l2 .667V25h3a3.003 3.003 0 003-3v-2.613l12 4V.539L0 8.316v8.405zM6 22a1 1 0 01-1 1H4v-4.946l2 .667V22zm12-1.388l-2-.666V4.24l2-.778v17.15zM2 9.684l12-4.667V19.28l-12-4V9.684zm20.293-6.391l3-3 1.414 1.414-3 3-1.414-1.414zm0 19.414l1.414-1.414 3 3-1.414 1.414-3-3zM23 12h5v2h-5v-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAnnouncement
