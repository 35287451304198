import * as React from 'react'

function SvgOutage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 30" {...props}>
      <path
        d="M1.71 3.29l22 22-1.42 1.42-6.07-6.07-1.03 1.8L11 29.77V19H2.28l3.44-6.03 1.03-1.8L.29 4.71l1.42-1.42zM13 17.42v4.81l.72-1.26 1.03-1.8L13 17.42zM13 .23V11h8.72l-3.44 6.03-1.47-1.47L18.28 13h-4.03L11 9.75V7.77l-.72 1.26-1.47-1.47L13 .23zM8.22 12.64l-1.03 1.8L5.72 17h6.86l-4.36-4.36z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgOutage
