import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import uniq from 'lodash/uniq'
import isNull from 'lodash/isNull'
import isBoolean from 'lodash/isBoolean'
import {
  VocFilterOption,
  ProcessedCategoryGroupData,
} from 'screens/entityID/client-analytics/voice-of-customer/State'

export function getGroupsFromCurrentCategories(
  groupIds: string[],
  processedCategoryGroupData: ProcessedCategoryGroupData,
  categoriesToSet: string[]
) {
  return groupIds.reduce((acc: string[], groupId: string) => {
    const groupCategoryIds = processedCategoryGroupData[groupId].map(
      (value: VocFilterOption) => value.value
    )
    const currentlySelectedGroupCategories = groupCategoryIds.filter((categoryId: string) =>
      categoriesToSet.includes(categoryId)
    )

    if (currentlySelectedGroupCategories.length === groupCategoryIds.length) {
      return [...acc, groupId]
    } else return acc
  }, [])
}

export function getNotClearedCategories(
  groupsFromCurrentCategories: string[],
  processedCategoryGroupData: ProcessedCategoryGroupData,
  vocCategory?: string[]
) {
  const categoriesToClearArrays = groupsFromCurrentCategories.map((categoryGroupId: string) => {
    return (
      processedCategoryGroupData[categoryGroupId].map((value: VocFilterOption) => value.value) || []
    )
  })
  const categoriesToClear = flatten(categoriesToClearArrays)
  return vocCategory?.filter((category: string) => !categoriesToClear.includes(category))
}

export function getNotRemovedCategories(
  processedCategoryGroupData: ProcessedCategoryGroupData,
  removedOption: string,
  groupCategoryIds: string[],
  categoriesToSet: string[]
) {
  const removeEvaluationCategories = processedCategoryGroupData[removedOption].map(
    (value: VocFilterOption) => value.value
  )

  const categoriesToRemove = removeEvaluationCategories.reduce(
    (acc: string[], categoryId: string) => {
      const currentlyAppliedGroupCategories = uniq(groupCategoryIds)

      if (currentlyAppliedGroupCategories.includes(categoryId)) {
        return acc
      } else return [...acc, categoryId]
    },
    []
  )

  const categories = uniq(categoriesToSet).filter(
    category => !categoriesToRemove.includes(category)
  )
  return categories
}

export function areFilterValuesEqual(
  filtersOne: Record<string, any> = {},
  filtersTwo: Record<string, any> = {}
) {
  const filtersOneFiltered = pickBy(filtersOne, value => {
    return (!isEmpty(value) && !isNull(value)) || isBoolean(value)
  })

  const filtersTwoFiltered = pickBy(filtersTwo, value => {
    return (!isEmpty(value) && !isNull(value)) || isBoolean(value)
  })

  return isEqual(filtersOneFiltered, filtersTwoFiltered)
}
