import React from 'react'
import FavoritesModal from 'components/shared/FavoritesModal'
import Typography from 'components/shared/typography/Typography'
import { IconButton } from 'components/shared/IconButton'
import usePrismUser from 'hooks/usePrismUser'
import { HandleApplicationSwitcherClose } from 'components/layout/Topbar/ApplicationSwitcher'
import ApplicationsList from './ApplicationsList'
import * as S from './styles'

export default function Favorites({
  handleApplicationSwitcherClose,
}: {
  handleApplicationSwitcherClose: (event: HandleApplicationSwitcherClose) => void
}) {
  const prismUser = usePrismUser()
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  if (prismUser.favorites === null) {
    return <p style={{ color: 'red' }}>There was an error fetching your favorites</p>
  }

  function handleClick() {
    setIsModalOpen(true)
  }

  function onModalClose() {
    setIsModalOpen(false)
  }

  return (
    <>
      <S.TitleContainer data-pho="applicationFavorites">
        <Typography variant="title6" noMargin>
          Favorites
        </Typography>
        <IconButton icon="Edit" onClick={handleClick} data-pho="editApplicationFavorites" />
      </S.TitleContainer>
      <S.FavoriteApplicationsContainer>
        <ApplicationsList
          apps={prismUser.favorites as string[]}
          isFavoritesList
          handleApplicationSwitcherClose={handleApplicationSwitcherClose}
        />
      </S.FavoriteApplicationsContainer>
      <FavoritesModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        handleApplicationSwitcherClose={handleApplicationSwitcherClose}
      />
    </>
  )
}
