import styled from 'styled-components'
import { element } from 'components/shared/utils/spacing'
import { durations, timingFunctions } from 'components/shared/utils/transition'
import { colors } from 'components/shared/utils/colors'

export const Toast = styled.div<{
  $isToastShown?: boolean
  $isDarkMode?: boolean
  $placement: 'top-left' | 'top' | 'top-right' | 'bottom-right' | 'bottom' | 'bottom-left'
}>`
  display: flex;
  position: fixed;
  width: fit-content;
  max-width: calc(100vw - ${element.sm} * 2);
  z-index: 1000;
  left: 50%;
  right: 50%;
  ${({ $placement }) => ($placement.includes('top') ? `top: ${element.md}` : '')};
  ${({ $placement }) => ($placement.includes('bottom') ? `bottom: ${element.md}` : '')};
  ${({ $placement }) =>
    $placement.includes('right') ? `right: ${element.md}; left: unset` : ''};
  ${({ $placement }) =>
    $placement.includes('left') ? `left: ${element.md}; right: unset;` : ''};
  ${({ $placement }) =>
    $placement === 'bottom' || $placement === 'top' ? `margin: 0% auto; left: 0; right: 0;` : ''};
  ${({ $isToastShown }) => ($isToastShown ? '' : 'display: none;')};
  transition-property: opacity;
  transition-duration: ${durations.medium};
  transition-timing-function: ${timingFunctions.standard};
  align-items: center;
  padding: calc(${element.sm} - 1px);
  border-radius: 4px;
  color: ${colors.white};
  border: solid 1px transparent;
  background-color: ${({ $isDarkMode }) => ($isDarkMode ? colors.darkBlue10 : colors.darkBlue30)};
  box-shadow: 0 4px 4px
    ${({ $isDarkMode }) => ($isDarkMode ? colors.lightMode.shadow : colors.darkMode.shadow)};
`
