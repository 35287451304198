import React from 'react'
import { HeaderContext, HeaderState, HeaderValue } from 'components/App/Providers/Header'

export default function useHeader(
  headerStateUpdates?: Omit<HeaderState, 'areFiltersReady'>,
  resetHeader?: boolean
): Omit<HeaderValue, 'updateState' | 'resetState'> {
  const {
    updateState: updateHeaderState,
    resetState,
    timeValues,
    Header,
    FilterMenu,
    filterValues,
    groupByValues,
    updateValues,
    batchUpdateValues,
    state,
    rawValues,
  } = React.useContext(HeaderContext)

  if (resetHeader) {
    resetState()
  }

  React.useEffect(() => {
    headerStateUpdates && updateHeaderState({ ...headerStateUpdates, areFiltersReady: true })
  }, [updateHeaderState, headerStateUpdates])

  return {
    Header,
    FilterMenu,
    timeValues,
    filterValues,
    groupByValues,
    updateValues,
    batchUpdateValues,
    state,
    rawValues,
  }
}
