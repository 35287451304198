import React, { Dispatch } from 'react'
import 'react-grid-layout/css/styles.css'
import { RouteComponentProps } from '@reach/router'
import { StyledComponent } from 'styled-components'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import useHeader from 'hooks/useHeader'
import useApps from 'hooks/useApps'
import useGroups from 'hooks/useGroups'
import Chart from 'components/shared/chart'
import { ChartObj } from 'components/shared/chart/types'
import useFilteredCharts from './useFilteredCharts'
import { getErrorTypeFromQuery } from 'utils'
import * as S from './styled'
import * as U from './utils'

function Charts({
  className,
  charts,
  ChartsContainer = S.ChartsContainer,
  ChartContainer = S.ChartContainer,
  chartContainerProps = { hasContentPadding: false },
  children,
  location,
  shouldAddMarginTop,
  shouldWrapText,
  onResponse,
  isMetricChart,
  reloadDataForExport,
  setReloadDataForExport,
}: ChartsProps) {
  const [filteredChartsQueries, setFilteredChartsQueries] = React.useState<any>([])
  const { state, filterValues } = useHeader()
  const { currentApps, currentDomains, currentDataSources, entityID, appsByDataSource } = useApps()
  const { currentGroup } = useGroups()
  const errorType = getErrorTypeFromQuery(location)

  const filteredCharts = U.filterCharts(charts, {
    dataSources: currentDataSources,
    domains: currentDomains,
    group: currentGroup?.name,
    apps: currentApps,
    appNames: currentApps?.map(app => app.split('@')[0]),
    appTypes: currentApps?.map(app => app.split('@')[1]),
    errorTypes: [errorType],
  })

  useFilteredCharts({
    filteredCharts,
    filterValues,
    currentApps,
    entityID,
    appsByDataSource,
    onResponse,
    filteredChartsQueries,
    isMetricChart,
    setFilteredChartsQueries,
    reloadDataForExport,
    setReloadDataForExport,
  })

  if (!state.areFiltersReady) {
    return <LoadingSpinner />
  }

  return (
    <ChartsContainer
      shouldAddMarginTop={shouldAddMarginTop}
      shouldWrapText={shouldWrapText}
      className={className}
    >
      {filteredCharts.map((chart, index) => {
        const { chartIsScrollable, ...restChartProps } = chart
        return (
          <ChartContainer
            key={`Chart${index}`}
            width={chart.width}
            height={chart.height}
            extendContent={chart.extendContent}
            removeColumnsWithoutValues={chart.removeColumnsWithoutValues}
            chartIsScrollable={chartIsScrollable}
            {...chartContainerProps}
          >
            <Chart location={location} chart={{ ...restChartProps }} />
          </ChartContainer>
        )
      })}
      {children}
    </ChartsContainer>
  )
}

interface ChartsProps extends RouteComponentProps {
  className?: string
  charts: ChartObj[]
  ChartsContainer?: React.ComponentClass<any, any> | React.FC<any> | StyledComponent<any, any>
  ChartContainer?:
    | React.ComponentClass<{ width?: number; height?: number }>
    | React.FC<{ width?: number; height?: number }>
    | StyledComponent<any, any>
  children?: React.ReactElement
  chartContainerProps?: object
  shouldAddMarginTop?: boolean
  shouldWrapText?: boolean
  onResponse?: Function
  isMetricChart?: boolean
  reloadDataForExport?: boolean
  setReloadDataForExport?: Dispatch<any>
}

export default Charts
