import React from 'react'
import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'
import reduce from 'lodash/reduce'
import first from 'lodash/first'
import map from 'lodash/map'
import { DataType } from 'components/shared/chart/types'
import { ServiceDefinition } from 'common/services'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import * as T from './types'
import { getDataSource } from './utils'

function FormatData({
  children,
  rawDataArray,
  types,
  chartType,
  title,
  filterValues,
  metaData,
}: {
  children: any
  rawDataArray: T.RawDataObj[]
  types: DataType[]
  chartType?: DataType
  services?: ServiceDefinition[]
  title?: string
  filterValues?: Record<string, any>
  metaData?: string
}) {
  const dataSources = types.map((source: DataType) => {
    return getDataSource(source)
  })
  const allSourcesEqual = dataSources.every((source, i, dataSources) => source === dataSources[0])

  if (isEmpty(rawDataArray)) {
    return (
      <NoDataChartMessage
        title={title}
        dataSource={allSourcesEqual ? dataSources[0] : dataSources.join('/ ')}
      />
    )
  }

  function getLineData(rawData?: any) {
    if (rawData.compareData) {
      const formattedLineData = formatCompareLineData(rawData.data as T.RawLineData, filterValues)
      const formattedCompareLineData = formatCompareLineData(
        rawData.compareData as T.RawLineData,
        filterValues,
        rawData.data as T.RawLineData
      )
      return formattedLineData.concat(formattedCompareLineData)
    }
    return formatLineData(rawData.data as T.RawLineData)
  }

  return children(
    types.map((type, index) => {
      switch (type) {
        case DataType.LINE:
        case DataType.METRIC_LINE:
        case DataType.METRIC_TABLE:
        case DataType.OS_LINE:
        case DataType.TECH_MOBILE_LINE: {
          const formattedData = {
            lineData: getLineData(rawDataArray[index]),
            aggrData: rawDataArray[index].aggrData,
          }
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.PERCENTAGE: {
          const formattedData = formatPercentageData(rawDataArray[index].data as T.RawAggData)
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.GRID: {
          const formattedData = formatTopData({
            rawTopData: rawDataArray[index].data as T.RawTopData,
          })
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.TOP: {
          const formattedData = {
            topData: formatTopData({ rawTopData: rawDataArray[index].data as T.RawTopData }),
            aggrData: rawDataArray[index].aggrData,
          }
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.NETWORK: {
          const formattedData = {
            networkData: formatNetworkData({
              rawNetworkQueryData: rawDataArray[index].data,
              rawNetworkAggrQueryData: rawDataArray[index].aggrData,
            }),
          }
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.OS_TOP: {
          const formattedData = {
            topData: formatTopData({ rawEsData: rawDataArray[index].data as T.RawEsData }),
            aggrData: rawDataArray[index].aggrData,
          }
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.SUNBURST:
        case DataType.OS_SUNBURST: {
          const formattedData = formatSunburstData(rawDataArray[index].data as T.RawBubbleData)
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.SUNBURST_WITH_TOP: {
          const formattedData = {
            sunburstData: formatSunburstData(rawDataArray[index].data as T.RawBubbleData),
            topData: {
              topData: formatTopData({
                rawBubbleData: rawDataArray[index].data as T.RawBubbleData,
              }),
              aggrData: rawDataArray[index].aggrData,
            },
          }
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.OS_SUNBURST_WITH_TOP: {
          const formattedData = formatSunburstWithTopData(
            rawDataArray[index].data as T.RawBubbleData
          )
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.METRIC_BAR: {
          const formattedData = formatMetricBarData(
            rawDataArray[index].data as T.RawLineData,
            filterValues?.step?.toString()
          )
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.BAR: {
          const formattedData = formatBarData(rawDataArray[index].data as T.RawBarData)
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }

        case DataType.BAR_WITH_TOP: {
          const formattedData = {
            barData: formatBarData(rawDataArray[index].data as T.RawBarData),
            topData: {
              topData: formatTopData({ rawTopData: rawDataArray[index].data as T.RawTopData }),
              aggrData: rawDataArray[index].aggrData,
            },
          }
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.SANKEY: {
          const formattedData = rawDataArray[index].data as T.RawSankeyData
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.OS_BAR_WITH_TOP: {
          const formattedData = {
            barData: formatEsBarData((rawDataArray[index].data as T.RawEsData) || []),
            topData: {
              topData: formatTopData({
                rawEsData: (rawDataArray[index].data as T.RawEsData) || [],
              }),
              aggrData: rawDataArray[index].aggrData,
            },
          }
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.OS_HORIZONTAL_BAR:
        case DataType.HORIZONTAL_BAR: {
          const formattedData = formatHorizontalBarChartData(
            rawDataArray[index].data as T.RawBarData
          )
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        case DataType.METRIC_AGGREGATE: {
          const formattedData = {
            data: formatAggregateData(rawDataArray[index].data as T.RawLineData),
            previousData: formatAggregateData(rawDataArray[index].previousData as T.RawLineData),
          }
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
        default: {
          const formattedData = rawDataArray[index]
          return metaData
            ? {
                formattedData,
                metaData,
              }
            : formattedData
        }
      }
    })
  )
}

export function formatNetworkData({
  rawNetworkQueryData,
  rawNetworkAggrQueryData,
}: {
  rawNetworkQueryData?: any
  rawNetworkAggrQueryData?: T.RawAggData
}) {
  if (isEmpty(rawNetworkQueryData)) {
    return {}
  }

  const invertedSuccessRates = (rate: number) => {
    return 100 - rate
  }
  const cleanApiCallNumbers = (apiCall: number) => {
    return apiCall / 100
  }
  const firstNodeId = rawNetworkQueryData?.rows
    ? rawNetworkQueryData?.rows[0]?.applicationName ?? ''
    : ''

  const firstNodeSuccessRate = rawNetworkQueryData?.rows?.length
    ? rawNetworkQueryData?.rows?.reduce((apiSuccessTotal: number, apiInfo: object) => {
        return apiSuccessTotal + invertedSuccessRates(apiInfo['successRate'])
      }, 0) / rawNetworkQueryData?.rows?.length || 1
    : 0

  const firstNodeCallTotal = rawNetworkQueryData?.rows?.reduce(
    (apiCallTotal: number, apiInfo: object) => {
      return apiCallTotal + cleanApiCallNumbers(apiInfo['apiCalls'])
    },
    0
  )

  const getNodePosition = () => {
    const width = typeof window !== 'undefined' ? window.innerWidth / 60 : 100
    return width / 6
  }

  const firstNode = {
    id: firstNodeId,
    apiName: '',
    successRate: firstNodeSuccessRate,
    calls: firstNodeCallTotal,
    x: getNodePosition(),
    y: 160,
  }

  const nodeArray = rawNetworkQueryData?.rows?.map((dataObj: any, index: number) => {
    const nodeTotal = rawNetworkQueryData?.rows.length
    const containerLength = window.innerWidth / 3
    const visibleHeight = 325
    const yDiffs = visibleHeight / nodeTotal < 50 ? visibleHeight / nodeTotal : 50
    const yAngledUp =
      index <= nodeTotal / 2 ? 175 - yDiffs * (index || 1) : 50 + (yDiffs + index * 10)
    const yAngledDown =
      index <= nodeTotal / 2 ? 175 + yDiffs * index : visibleHeight - (yDiffs + index * 10)
    const dynamicY = index % 2 === 0 ? yAngledUp : yAngledDown
    const calculateXValue = (containerLength / nodeTotal) * (index || 1)
    const dynamicX = calculateXValue === containerLength ? calculateXValue - 50 : calculateXValue
    const { apiHostName, apiName, successRate, apiCalls } = dataObj
    const newNodeObject = {
      id: apiHostName || 'unknown',
      apiName,
      successRate: invertedSuccessRates(successRate),
      calls: cleanApiCallNumbers(apiCalls),
      x: dynamicX,
      y: dynamicY,
    }
    return newNodeObject
  })

  const nodes = nodeArray ? [firstNode, ...nodeArray] : []

  const targets = rawNetworkQueryData?.rows?.map((item: object) => item['apiHostName'] || 'unknown')

  const uniqueTargetArray = targets?.filter(
    (apiHostName: string, i: number) => targets?.indexOf(apiHostName) === i
  )

  const links = uniqueTargetArray?.map((apiHostName: string) => {
    const linkObject = {
      source: firstNodeId,
      target: apiHostName,
    }
    return linkObject
  })

  const formattedNetworkData = {
    nodes,
    links,
  }

  return formattedNetworkData
}

export function formatTopData({
  rawTopData,
  rawBubbleData,
  rawEsData,
}: {
  rawTopData?: T.RawTopData
  rawBubbleData?: T.RawBubbleData
  rawEsData?: T.RawEsData
}) {
  try {
    if (rawTopData) {
      return rawTopData?.rows || []
    }
    if (rawBubbleData) {
      return rawBubbleData?.bubbles?.slice(0, 10) || []
    }
    if (rawEsData) {
      return rawEsData || []
    }
    return
  } catch (error) {
    console.error(error)
    return []
  }
}

export function formatSunburstData(rawBubbleData: T.RawBubbleData) {
  const result = rawBubbleData?.bubbles?.reduce(
    (accum: T.FormattedSunburstData, value: { group: string; name: string; value: number }) => {
      const index = findIndex(accum.children, { name: value.group })
      if (index === -1) {
        accum.children.push({
          name: value.group,
          children: [{ name: value.name, value: value.value }],
        })
      } else {
        ;(accum.children[index] as T.FormattedSunburstData).children.push({
          name: value.name,
          value: value.value,
        })
      }
      return accum
    },
    {
      name: 'Sunburst',
      children: [],
    }
  )

  return result || []
}

function formatSunburstWithTopData(rawBubbleData: T.RawBubbleData) {
  return {
    topData: formatTopData({ rawBubbleData: rawBubbleData }),
    sunburstData: formatSunburstData(rawBubbleData),
  }
}

function formatPercentageData(rawAggData: T.RawAggData) {
  return {
    percentage: rawAggData.avg,
  }
}

export function formatLineData(rawLineData: T.RawLineData) {
  return reduce(
    rawLineData,
    (accum: T.FormattedLineData, numbers, key) => {
      if (key === 'timestamps') {
        return accum
      }

      return accum.concat({
        id: key,
        data: numbers.map((value, index) => ({
          x: rawLineData.timestamps && rawLineData.timestamps[index],
          y: value,
        })),
      })
    },
    []
  )
}
// This  is only used for line chart data compare functionality (it is only used in release summary detail page)
export function formatCompareLineData(
  rawLineData: T.RawLineData,
  filterValues?: Record<string, any>,
  compareData?: T.RawLineData
) {
  function getCompareID() {
    // Compare functionality is using main charts query, but changing filter or period. So both main query and compare query has equal id's, that is why we are adding unique id.
    switch (filterValues?.compareFeature) {
      case 'allData': {
        return 'All App Data'
      }
      case 'anotherVersion': {
        return `Another Version (Release ${filterValues?.compareVersion})`
      }
      case 'previousPeriod': {
        return 'Previous Period (All Data)'
      }
      case 'previousPeriodOtherVersion': {
        return `Previous Period (Release ${filterValues?.compareVersion})`
      }
      default:
        return null
    }
  }

  const id = compareData ? getCompareID() : `Release ${filterValues?.releaseVersion}`
  return reduce(
    rawLineData,
    (accum: T.FormattedLineData, numbers, key) => {
      if (key === 'timestamps') {
        return accum
      }
      return accum.concat({
        id: id || key,
        data: numbers.reduce((acc: any, value, index) => {
          // main query timestamps are added to compare query, because that way we can render "previous period" line in main query period
          const x = compareData
            ? compareData?.timestamps?.[index]
            : rawLineData.timestamps && rawLineData.timestamps[index]
          const y = value
          if (x && y) {
            return acc.concat([{ x, y }])
          }
          return acc
        }, []),
      })
    },
    []
  )
}

export function formatMetricBarData(rawLineData: T.RawLineData, step: string) {
  return reduce(
    rawLineData,
    (accum: any, numbers, key) => {
      if (key === 'timestamps') {
        return accum
      }

      if (isEmpty(accum)) {
        return rawLineData[key].map((value, index) => {
          return {
            index: rawLineData.timestamps[index],
            [key]: value,
          }
        })
      }

      return accum.map((value: Record<string, any>, index: number) => {
        return {
          ...value,
          [key]: rawLineData[key][index],
        }
      })
    },
    []
  )
}

export function formatBarData(rawBarData: T.RawBarData) {
  if (!isEmpty(rawBarData?.rows)) {
    return rawBarData.rows.map(obj => {
      return reduce(
        obj,
        (
          acc: { category: number | string; count: number | string },
          value: number | string,
          key: string
        ) => {
          if (key !== 'Count') {
            acc.category = value
          } else {
            acc.count = value
          }
          return acc
        },
        { category: '', count: 0 }
      )
    })
  } else {
    const keys = Object.keys(rawBarData)
    return keys.map(key => {
      return { category: key, count: rawBarData[key].value ?? rawBarData[key].Events }
    })
  }
}

export function formatEsBarData(rawBarData: T.RawEsData) {
  return rawBarData?.map(obj => {
    return reduce(
      obj,
      (acc: any, value: any, key: any) => {
        if (key !== 'Count') {
          acc.category = value
        } else {
          acc.count = value
        }
        return acc
      },
      { category: '', count: 0 }
    )
  })
}

export function formatHorizontalBarChartData(rawBarData: T.RawBarData) {
  const formattedBarData = map(rawBarData, (value, key) => {
    return { ...value, title: key }
  })

  const barKeys = reduce(
    rawBarData,
    (acc: any, value: any, key: any) => {
      map(value, (objValue: any, objKey: any) => {
        const checkIfTitleAlreadyExists = acc.some((title: any) => {
          return objKey === title
        })
        if (!checkIfTitleAlreadyExists) {
          acc.push(objKey)
        }
      })
      return acc
    },
    []
  )

  return {
    barData: formattedBarData,
    barKeys,
  }
}

export function formatAggregateData(rawLineData: T.RawLineData): number | undefined {
  return first(
    reduce(rawLineData, (accum: any, value, key) => {
      if (key === 'timestamps') {
        return accum
      }

      return first(value)
    })
  )
}

export default FormatData
