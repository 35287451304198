import React from 'react'
import * as S from './styles'
import { useLocation, Link } from '@reach/router'
import Tooltip from '@material-ui/core/Tooltip'
import { IconButton } from 'components/shared/IconButton'

function CategorizeFeedbackIcon({ feedbackID }: { feedbackID?: string }) {
  const { pathname, search } = useLocation()

  const pathNameDeslugged = pathname.split('/')
  const steps = pathname.includes('/overview/details') ? 2 : 1
  pathNameDeslugged.splice(pathNameDeslugged.length - steps, steps, 'edit-reviews')
  const newPathname = pathNameDeslugged.join('/')

  const searchDeslugged = search.split('&')
  searchDeslugged.push(`vocFeedbackID=${feedbackID}`)
  const newSearch = searchDeslugged.join('&')

  return (
    <Tooltip title={`Redirect to categorize this feedback`}>
      <S.StyledEditLink>
        <Link to={newPathname + newSearch} data-pho={`categorizeReviewsRedirect-${feedbackID}`}>
          <IconButton icon="Edit" />
        </Link>
      </S.StyledEditLink>
    </Tooltip>
  )
}

export default CategorizeFeedbackIcon
