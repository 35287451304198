import React from 'react'
import Autocomplete from 'components/shared/Autocomplete'
import * as T from './types'
import * as S from './styled'
import isEmpty from 'lodash/isEmpty'
import { Tooltip } from '@material-ui/core'

const ACTIVATION_PATHS_SUCCESS = {
  IVR: [7],
  MSA: [0, 3, 9, 13],
  Portal: [2, 17],
  TechMobile: [1, 4, 5, 6, 8, 11, 14, 16, 18],
  OnScreen: [12, 15],
}
const ACTIVATION_PATHS_FAILURE = {
  IVR: [0, 1, 2, 3, 4, 5, 6],
  MSA: [1, 4, 8, 14],
  Portal: [3, 4, 5, 13, 14, 15, 16],
  TechMobile: [],
  OnScreen: [11],
}

function getActivationMethodPaths(
  activationMethod: string,
  activationTypeOutcomeSuccess: boolean,
  activationTypeOutcomeFailure?: boolean
) {
  if (activationTypeOutcomeSuccess && activationTypeOutcomeFailure) {
    return ACTIVATION_PATHS_SUCCESS[activationMethod].concat(
      ACTIVATION_PATHS_FAILURE[activationMethod]
    )
  }

  if (activationTypeOutcomeSuccess) return ACTIVATION_PATHS_SUCCESS[activationMethod]

  return ACTIVATION_PATHS_FAILURE[activationMethod]
}

const getActivationPath = (
  activationMethod: string | string[],
  activationTypeOutcomeSuccess: boolean,
  activationTypeOutcomeFailure?: boolean
) => {
  if (Array.isArray(activationMethod)) {
    return Array.from(activationMethod)
      .filter(method => method !== 'Not Applicable')
      .map(method => {
        const activationMethodPaths = getActivationMethodPaths(
          method,
          activationTypeOutcomeSuccess,
          activationTypeOutcomeFailure
        )
        return activationMethodPaths
      })
      .flat()
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a - b)
  } else {
    const activationMethodPaths = getActivationMethodPaths(
      activationMethod,
      activationTypeOutcomeSuccess,
      activationTypeOutcomeFailure
    )
    return activationMethodPaths
  }
}

function getVocActivationTypeOutcome(value: string | string[]) {
  const vocActivationTypeOutcomeSuccess = value?.includes('Success')
  const vocActivationTypeOutcomeFailure = value?.includes('Failure')

  return {
    vocActivationTypeOutcomeSuccess,
    vocActivationTypeOutcomeFailure,
  }
}

function VoCActivationMethodFilter({
  filter,
  filterValues,
  onChange,
}: {
  filter: T.Filter
  onChange: (filterValues: T.FilterValues) => void
  filterValues: T.FilterValues
}) {
  const { vocActivationMethod, vocActivationTypeOutcome } = filterValues
  function handleActivationMethodChange(value: string[]) {
    if (isEmpty(value) && Array.isArray(value) && vocActivationTypeOutcome) {
      onChange({ vocActivationMethod: [], vocActivationTypeOutcome: [], activationMethod: [] })
      return
    }
    if (vocActivationTypeOutcome && !isEmpty(vocActivationTypeOutcome) && value) {
      const { vocActivationTypeOutcomeSuccess, vocActivationTypeOutcomeFailure } =
        getVocActivationTypeOutcome(vocActivationTypeOutcome)

      const newActivationMethod =
        getActivationPath(
          value,
          vocActivationTypeOutcomeSuccess,
          vocActivationTypeOutcomeFailure
        ) || []
      onChange({ vocActivationMethod: value, activationMethod: newActivationMethod })
      return
    }
    onChange({ vocActivationMethod: value })
  }

  function handleActivationTypeOutcomeChange(value: string[]) {
    if (vocActivationMethod && value && !isEmpty(value)) {
      const { vocActivationTypeOutcomeSuccess, vocActivationTypeOutcomeFailure } =
        getVocActivationTypeOutcome(value)

      const newActivationMethod = getActivationPath(
        vocActivationMethod,
        vocActivationTypeOutcomeSuccess,
        vocActivationTypeOutcomeFailure
      )

      onChange({ vocActivationTypeOutcome: value, activationMethod: newActivationMethod })
      return
    }
    onChange({ vocActivationTypeOutcome: value })
  }

  const tooltipTitle = !vocActivationMethod || isEmpty(vocActivationMethod)
  ? 'Please select an Activation Method first'
  : '';

  return (
    <S.VocCategoryGroupContainer key={`${vocActivationMethod}_${vocActivationTypeOutcome}`}>
      <Autocomplete
        label="Activation Method"
        options={['IVR', 'TechMobile', 'Portal', 'MSA', 'OnScreen', 'Not Applicable']}
        defaultValue={vocActivationMethod}
        onChange={value => handleActivationMethodChange(value)}
        fullHeight
        width={S.FILTER_WIDTH}
        showTagTooltip
        data-pho="filterMenuAutoCompleteInput"
        canSelectMultipleValues
      />
      <Tooltip
        title={tooltipTitle}
        placement="top"
        arrow
      >
        <span>
          <Autocomplete
            label="Activation Type Outcome"
            options={['Success', 'Failure']}
            defaultValue={vocActivationTypeOutcome}
            onChange={value => handleActivationTypeOutcomeChange(value)}
            fullHeight
            width={S.FILTER_WIDTH}
            showTagTooltip
            data-pho="filterMenuAutoCompleteInput"
            canSelectMultipleValues
            disabled={!vocActivationMethod || isEmpty(vocActivationMethod)}
          />
        </span>
      </Tooltip>
    </S.VocCategoryGroupContainer>
  )
}

export default VoCActivationMethodFilter
