import * as React from 'react'

function SvgLeaf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M26.984 8.822l-.124-.682-.682-.124a20.048 20.048 0 00-10.942 1.398 16.411 16.411 0 00-6.74-8.282L8 .848l-.496.284C7.197 1.307 0 5.517 0 14s7.693 12.825 8 13c1.393.394 2.836.58 4.283.553a14.137 14.137 0 0010.243-4.026c5.963-5.951 4.522-14.35 4.458-14.705zM2 14C2 7.905 6.483 4.254 8.002 3.192 9.523 4.25 14 7.88 14 14a13.223 13.223 0 01-5 10.034V11H7v13.041A13.18 13.18 0 012 14zm19.112 8.113c-3.448 3.455-7.998 3.604-10.648 3.32L13 23l7.673-7.26-1.346-1.48-3.844 3.5c.342-1.224.515-2.489.517-3.76a14.216 14.216 0 00-.251-2.611 17.81 17.81 0 019.355-1.497c.186 2.025.3 7.937-3.992 12.221z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLeaf
