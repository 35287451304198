import * as React from 'react'

function SvgCallOutgoingF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 28" {...props}>
      <path
        d="M9.09 1.67l3.17 3.17L8.57 11H4.66a10.316 10.316 0 00.08 6h3.83l3.69 6.16-3.17 3.17a3.061 3.061 0 01-2.14.88h-.07a2.902 2.902 0 01-2.11-.96c-6.34-6.936-6.34-17.564 0-24.5.54-.6 1.304-.947 2.11-.96.828-.03 1.63.29 2.21.88zm8.753 3.621l8.568 8.567-8.571 8.571-1.414-1.414 6.128-6.128L11.04 15l-.02-2 11.59-.114-6.181-6.181 1.414-1.414z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCallOutgoingF
