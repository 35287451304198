import * as React from 'react'

function SvgLocationArrowF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M27.76.24l-10.6 27.1L12 18.06l.65-2.71-2.71.65-9.28-5.16z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLocationArrowF
