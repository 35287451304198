import React, { ReactNode, CSSProperties } from 'react'
import { IntrinsicElementsKeys } from 'styled-components'
import { camelCase } from 'lodash'
import * as S from './styles'
import { numberToPixels } from 'components/shared/utils/number-to-pixels'
import { element, SpacingElement } from 'components/shared/utils/spacing'
import { colors } from 'components/shared/utils/colors'

export interface FlexProps extends React.PropsWithoutRef<JSX.IntrinsicElements['div']> {
  /** Whether this flexbox should be an inline span or a block div. */
  inline?: boolean
  /** The CSS flex-direction property. */
  direction?: CSSProperties['flexDirection']
  /** Sets the CSS flex-direction property to "column." */
  column?: boolean
  /** Whether or not to apply the "wrap" value for the CSS flex-wrap property. */
  wrap?: boolean
  /** A string or number (of pixels) to set the width to. (If you manually set a width, consider using Prism React's "Grid" component so the div can "snap" to the grid.) */
  width?: string | number
  /** A string or number (of pixels) to set the height to. (If you manually set a height, consider using Prism React's "Grid" component so the div can "snap" to the grid.) */
  height?: string | number
  /** A string or number (of pixels) to set the min-width to. */
  minWidth?: string | number
  /** A string or number (of pixels) to set the min-height to. */
  minHeight?: string | number
  /** A string or number (of pixels) to set the max-width to. */
  maxWidth?: string | number
  /** A string or number (of pixels) to set the max-height to. */
  maxHeight?: string | number
  /** The CSS border property. */
  border?: string
  /** Either a Kite color name or a CSS color. */
  backgroundColor?: string
  /** Anything that you want contained by the flex box. */
  children?: ReactNode
  /** The CSS flex property. */
  flex?: string | number
  /** The CSS justify-content property. */
  justifyContent?: CSSProperties['justifyContent']
  /** The CSS align-content property. */
  alignContent?: CSSProperties['alignContent']
  /** The CSS justify-items property. */
  justifyItems?: CSSProperties['justifyItems']
  /** The CSS align-items property. */
  alignItems?: CSSProperties['alignItems']
  /** The CSS margin between children elements (can use Kite spacing, ie: "sm", "md", "lg") */
  marginBetween?: SpacingElement | string
  /** The CSS padding property. */
  padding?: string
  /** The CSS margin property. */
  margin?: string
  /** The CSS position property. */
  position?: CSSProperties['position']
  /** The CSS z-index property. */
  zIndex?: CSSProperties['zIndex']
  /** The CSS top property. */
  top?: CSSProperties['top']
  /** The CSS bottom property. */
  bottom?: CSSProperties['bottom']
  /** The CSS left property. */
  left?: CSSProperties['left']
  /** The CSS right property. */
  right?: CSSProperties['right']
  /** Just centers every alignment and justification CSS property. */
  center?: boolean
  /** Override the underlying element for the Flex component */
  as?: IntrinsicElementsKeys
  /** Separation between Flex elements */
  gap?: string
  /** The CSS flex-grow property */
  grow?: CSSProperties['flexGrow']
  ref?: React.MutableRefObject<any>
  /** The CSS overflow property */
  overflow?: CSSProperties['overflow']
}

export default ({
  inline = false,
  direction = 'row',
  column = false,
  wrap = false,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  border,
  backgroundColor,
  flex,
  justifyContent,
  alignContent,
  justifyItems,
  alignItems,
  marginBetween,
  padding,
  position,
  zIndex,
  margin,
  center,
  top,
  bottom,
  left,
  right,
  gap,
  as,
  grow,
  ...props
}: FlexProps) => {
  const pixelWidth = numberToPixels(width)
  const pixelMinWidth = numberToPixels(minWidth)
  const pixelMaxWidth = numberToPixels(maxWidth)
  const pixelHeight = numberToPixels(height)
  const pixelMinHeight = numberToPixels(minHeight)
  const pixelMaxHeight = numberToPixels(maxHeight)
  const pixelPadding = element[padding as unknown as number] || numberToPixels(padding)
  const pixelMargin = element[margin as unknown as number] || numberToPixels(margin)
  const pixelMarginBetween =
    element[marginBetween as string] || numberToPixels(marginBetween)
  const kiteColor = colors[camelCase(backgroundColor)]
  const Component = inline ? S.Span : S.Div
  const dataTestID = props['data-testid'] || 'flex'
  return (
    <Component
      $inline={inline}
      $width={pixelWidth}
      $minWidth={pixelMinWidth}
      $maxWidth={pixelMaxWidth}
      $height={pixelHeight}
      $minHeight={pixelMinHeight}
      $maxHeight={pixelMaxHeight}
      $border={border}
      $direction={column ? 'column' : direction}
      $backgroundColor={kiteColor || backgroundColor}
      $flex={flex}
      $wrap={wrap}
      $justifyContent={justifyContent}
      $alignContent={alignContent}
      $justifyItems={justifyItems}
      $alignItems={alignItems}
      $marginBetween={pixelMarginBetween}
      $padding={pixelPadding}
      $position={position}
      $zIndex={zIndex}
      $margin={pixelMargin}
      $center={center}
      $top={top}
      $bottom={bottom}
      $left={left}
      $right={right}
      $gap={gap}
      $grow={grow}
      as={as as undefined}
      {...props}
      data-testid={dataTestID}
    />
  )
}
