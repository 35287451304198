import * as React from 'react'

function SvgMaintenanceF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 7a7.008 7.008 0 01-7 7 6.832 6.832 0 01-2.35-.41L5.12 25.12a2.982 2.982 0 01-4.24-.01 2.97 2.97 0 01.01-4.23L12.41 9.35A6.833 6.833 0 0112 7a7.008 7.008 0 017-7 6.834 6.834 0 013.03.69l1.26.61-4.28 4.29 1.4 1.4 4.29-4.28.61 1.26c.46.944.696 1.98.69 3.03z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMaintenanceF
