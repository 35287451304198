import * as React from 'react'

function SvgCompass(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M13,0 C20.1797017,0 26,5.82029825 26,13 C25.9917065,20.1762637 20.1762637,25.9917065 13,26 C5.82029825,26 0,20.1797017 0,13 C0,5.82029825 5.82029825,0 13,0 Z M13,2 C6.92486775,2 2,6.92486775 2,13 C2,19.0751322 6.92486775,24 13,24 C19.0751322,24 24,19.0751322 24,13 C23.9931389,6.92771198 19.072288,2.00686108 13,2 Z M13,20 C13.5522847,20 14,20.4477153 14,21 C14,21.5522847 13.5522847,22 13,22 C12.4477153,22 12,21.5522847 12,21 C12,20.4477153 12.4477153,20 13,20 Z M19,7 L16,16 L7,19 L10,10 L19,7 Z M15.84,10.16 L11.58,11.58 L14.42,14.42 L15.84,10.16 Z M5,12 C5.55228475,12 6,12.4477153 6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 C4,12.4477153 4.44771525,12 5,12 Z M21,12 C21.5522847,12 22,12.4477153 22,13 C22,13.5522847 21.5522847,14 21,14 C20.4477153,14 20,13.5522847 20,13 C20,12.4477153 20.4477153,12 21,12 Z M13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 C12.4477153,6 12,5.55228475 12,5 C12,4.44771525 12.4477153,4 13,4 Z"
        fill="currentColor"
        fillRule="evenodd"
      ></path>
    </svg>
  )
}

export default SvgCompass
