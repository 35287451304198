import * as React from 'react'

function SvgFullScreenReturn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 0a3.003 3.003 0 013 3v18a3.003 3.003 0 01-3 3H0V3a3.003 3.003 0 013-3h22zm0 2H3c-.552 0-1 .447-1 1v19h23a1 1 0 001-1V3a1 1 0 00-1-1zM12 14v6h-2v-4H4v-2h8zm12 0v2h-6v4h-2v-6h8zM12 4v6H4V8h6V4h2zm6 0v4h6v2h-8V4h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFullScreenReturn
