import * as React from 'react'

function SvgZeplin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 18" {...props}>
      <path
        d="M18 1.046c-3.195.078-6.364.59-9.42 1.52L5.74.55A3.02 3.02 0 002.625.333 2.983 2.983 0 001 2.983v3.638a3.614 3.614 0 00-1 2.375c.03.887.385 1.732 1 2.375v3.638a2.954 2.954 0 001.63 2.653c.422.223.892.34 1.37.338a3.016 3.016 0 001.74-.557l2.84-2.017a35.307 35.307 0 009.42 1.52c7.38 0 10-4.283 10-7.95s-2.62-7.95-10-7.95zM8.61 4.634a31.102 31.102 0 019.39-1.6c4.55 0 7.43 1.838 7.92 4.969H2.43c.89-1.094 3.06-2.296 5.8-3.24.12-.05.25-.09.38-.13zM3 2.984a.962.962 0 01.54-.885.983.983 0 011.04.07l1.69 1.193A19.199 19.199 0 003 4.962V2.984zm1.58 12.84a.983.983 0 01-1.04.07.962.962 0 01-.54-.885V13.03a19.087 19.087 0 003.26 1.6l-1.68 1.193zM18 14.959a31.104 31.104 0 01-9.39-1.6H8.6a3.842 3.842 0 01-.36-.13h-.01c-2.74-.943-4.91-2.146-5.8-3.239h23.49c-.49 3.13-3.37 4.969-7.92 4.969z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgZeplin
