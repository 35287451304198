import * as React from 'react'

function SvgListExpand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 24" {...props}>
      <path
        d="M13 0l7.207 7.207-1.414 1.414L13 2.828 7.207 8.621 5.793 7.207 13 0zm0 24l-7.207-7.207 1.414-1.414L13 21.172l5.793-5.793 1.414 1.414L13 24zM0 11h26v2H0v-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgListExpand
