import * as React from 'react'

function SvgChevronLeftCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14C6.272 27.99.009 21.728 0 14 0 6.268 6.268 0 14 0zm0 2C7.373 2 2 7.372 2 14c0 6.627 5.373 12 12 12 6.624-.008 11.993-5.376 12-12 0-6.628-5.373-12-12-12zm2.043 4.792l1.414 1.415L11.664 14l5.793 5.792-1.414 1.415L8.836 14l7.207-7.208z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronLeftCircle
