import * as React from 'react'

function SvgOutageF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 30" {...props}>
      <path
        d="M1.71 3.29l22 22-1.42 1.42-6.07-6.07-1.03 1.8L11 29.77V19H2.28l3.44-6.03 1.03-1.8L.29 4.71l1.42-1.42zM13 .23V11h8.72l-3.44 6.03-9.47-9.47L13 .23z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgOutageF
