import { PlatformModel } from 'components/voiceOfCustomer/types'
import { getVocClient } from 'components/voiceOfCustomer/utils'
import { useEffect, useState } from 'react'
import useFilterValues from './useFilterValues'
import { useLazyQuery } from '@apollo/client'
import useApps from 'hooks/useApps'
import { queries } from 'components/voiceOfCustomer'

const usePlatform = (): PlatformModel | undefined => {
  const [platform, setPlatform] = useState<PlatformModel>()

  const { currentApps } = useApps()
  const { environment } = useFilterValues()
  const vocClient = getVocClient(environment as string)

  const [fetchPlatform, { data }] = useLazyQuery<{ getPlatformByApplicationIDs: PlatformModel }>(
    queries.GET_PLATFORM_BY_APPLICATION_IDS,
    {
      client: vocClient,
    }
  )

  useEffect(() => {
    if (currentApps)
      void fetchPlatform({
        variables: {
          applicationIDs: currentApps,
        },
      })
  }, [currentApps])

  useEffect(() => {
    setPlatform(data?.getPlatformByApplicationIDs)
  }, [data])

  return platform
}

export default usePlatform
