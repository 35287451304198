import * as React from 'react'

function SvgMoreVert(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 6 28" {...props}>
      <path
        d="M3 22a3 3 0 110 6 3 3 0 010-6zm0-11a3 3 0 110 6 3 3 0 010-6zM3 0a3 3 0 110 6 3 3 0 010-6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMoreVert
