import * as React from 'react'

function SvgHomeApplianceF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M0 13v15h21a3 3 0 003-3V13H0zm6 10H4v-8h2v8zM21 0H3a2.9 2.9 0 00-3 3v8h24V3a2.9 2.9 0 00-3-3zM6 9H4V4h2v5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHomeApplianceF
