import * as React from 'react'

function SvgBatteryEmpty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 28" {...props}>
      <path
        d="M12 0v4h1a3.003 3.003 0 013 3v18a3.003 3.003 0 01-3 3H3a3.003 3.003 0 01-3-3V7a3.003 3.003 0 013-3h1V0h8zm1 6H3c-.552 0-1 .448-1 1v18c0 .552.448 1 1 1h10c.552 0 1-.448 1-1V7c0-.552-.448-1-1-1zm-2.707 6.293l1.414 1.414L9.414 16l2.293 2.293-1.414 1.414L8 17.414l-2.293 2.293-1.414-1.414L6.586 16l-2.293-2.293 1.414-1.414L8 14.586l2.293-2.293zM10 2H6v2h4V2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgBatteryEmpty
