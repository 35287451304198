import moment from 'moment'
import toNumber from 'lodash/toNumber'

export function getMonth(defaultRange?: { from: string; to: string }, isDoubleCalendar?: boolean) {
  if (isDoubleCalendar) {
    return defaultRange?.from
      ? new Date(toNumber(defaultRange.from))
      : moment().subtract(1, 'month').toDate()
  }
  return defaultRange ? new Date(toNumber(defaultRange.from)) : new Date()
}
