import * as React from 'react'

function SvgInternetF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M22.9 17a12.021 12.021 0 01-6.89 6.29A13.495 13.495 0 0019.42 17h3.48zm1.1-5a12.331 12.331 0 01-.39 3h-3.8a23.42 23.42 0 00.19-3 18.497 18.497 0 00-.25-3h3.86c.253.98.384 1.988.39 3zm-1.1-5h-3.59A15.353 15.353 0 0015.79.62 12.045 12.045 0 0122.9 7zm-5.7 0H6.8A12.18 12.18 0 0112 .14 12.18 12.18 0 0117.2 7zm.13 10A10.937 10.937 0 0112 23.84 10.937 10.937 0 016.67 17h10.66zm-9.34 6.29A11.973 11.973 0 011.11 17h3.48a13.399 13.399 0 003.4 6.29zM18 12a20.258 20.258 0 01-.24 3H6.24A20.258 20.258 0 016 12c.002-1.006.095-2.01.28-3h11.44c.185.99.278 1.994.28 3zM4 12a21.677 21.677 0 00.2 3H.4a11.45 11.45 0 010-6h3.85A18.497 18.497 0 004 12zM8.22.62A15.37 15.37 0 004.69 7H1.11A12.017 12.017 0 018.22.62z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgInternetF
