import * as React from 'react'

function SvgFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M25.389 1.054c-4.29-1.81-8.513-.92-12.596-.058-3.955.835-7.692 1.623-11.404.057L0 .468V26h2V15.441c1.322.381 2.692.57 4.068.559 2.408-.084 4.8-.418 7.139-.996 3.956-.834 7.693-1.624 11.404-.057l1.389.585V1.312l-.611-.258zM24 12.608c-3.813-1.122-7.565-.33-11.206.439-3.74.788-7.284 1.534-10.794.294V3.39c3.811 1.12 7.564.33 11.206-.438 3.74-.79 7.283-1.536 10.794-.294v9.95z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFlag
