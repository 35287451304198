import React, { useState } from 'react'
import filter from 'lodash/filter'
import Tooltip from '@material-ui/core/Tooltip'
import { IconButton } from 'components/shared/IconButton'
import { Method } from 'axios'
import useGroups from 'hooks/useGroups'
import { axios, axiosConfigBase } from 'hooks/useApi'
import services from 'common/services'
import EditGroup from './EditGroup'
import { rootApiUrl } from 'common/constants'
import * as S from './styles'

function EditOrDeleteGroups({ handleCloseModal }: any) {
  const [editGroup, setEditGroup] = useState()
  const { groups, fetchNewData } = useGroups()

  async function deleteGroup(id: string) {
    const axiosConfig = {
      ...axiosConfigBase(),
      method: 'DELETE' as Method,
      headers: { authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      url: `${rootApiUrl}${services.groups.url}/${id}`,
    }
    await axios({ ...axiosConfig })
    const filteredGroups = filter(groups, (group: any) => {
      return id !== group._id
    })

    fetchNewData(filteredGroups)

    if (filteredGroups.length === 0) handleCloseModal()
  }

  function handleEditIconClick(group: any) {
    setEditGroup(group)
  }

  if (editGroup) {
    return <EditGroup group={editGroup} handleCloseModal={handleCloseModal} />
  }

  return (
    <S.GroupContainer>
      <S.ModalTitle variant="title6">Groups</S.ModalTitle>
      {groups?.map((group: any, index: number) => {
        return (
          <S.GroupListContainer key={`groupListConatiner${group.name}${index}`}>
            {group.name}
            <S.IconContainer>
              <Tooltip title="Edit Group">
                <IconButton icon="Edit" size="sm" onClick={() => handleEditIconClick(group)} />
              </Tooltip>
              {groups.length > 1 && (
                <Tooltip title="Delete Group">
                  <IconButton icon="Trash" size="sm" onClick={() => deleteGroup(group._id)} />
                </Tooltip>
              )}
            </S.IconContainer>
          </S.GroupListContainer>
        )
      })}
    </S.GroupContainer>
  )
}

export default EditOrDeleteGroups
