import * as React from 'react'

function SvgCoffeeMakerF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M20 1.994V0H4v1.994H0V28h24V1.994h-4zm-6 2.074h2v1.995h-2V4.068zm8 21.938h-2v-3.989h-3.56a5.877 5.877 0 001.47-2.991H20V17.03h-2v-1.994H6v2.991a5.953 5.953 0 001.54 3.99H4v3.988H2V3.989h2v5.065a2.003 2.003 0 002 1.994h5v1.995h2v-1.995h5a2.003 2.003 0 002-1.994V3.99h2v22.017z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCoffeeMakerF
