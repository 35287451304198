import styled from 'styled-components'
import Typography from 'components/shared/typography/Typography'

export const StyledAutocompleteContainer = styled.div`
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  && fieldset {
    width: 100%;
    padding: 1rem 0 0;
  }
  && fieldset > div {
    width: 100%;
    height: 100%;
  }
`

export const ModalContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  min-width: 34.375rem;
  padding-right: 3rem;
`

export const GroupListContainer = styled.div`
  margin: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const NewGroupInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const GroupContainer = styled.div`
  width: 100%;
`

export const ModalTitle = styled(Typography)`
  width: 100%;
  text-align: center;
`

export const CreateNewGroupButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

export const IconContainer = styled.div``

export const StyledAlertContainer = styled.div`
  margin-bottom: 1rem;
`
