import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import queryString from 'query-string'
import { useLocation } from '@reach/router'
import Modal from 'components/shared/Modal'
import { colors } from 'components/shared/utils/colors'
import Rating from '@material-ui/lab/Rating'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import analytics from 'utils/analytics'
import useHeader from 'hooks/useHeader'
import * as S from './styles'
import NeedHelpButton from 'components/shared/NeedHelpButton'
import Icon from 'components/shared/icon/Icon'
import Typography from 'components/shared/typography/Typography'
import Flex from 'components/shared/flex/Flex'
import Link from 'components/shared/Link/PrismReactLink'
import { TextInput } from 'components/shared/TextInput'
import { Button } from 'components/shared/Button'

const feedbackCategories = [
  'General/Miscellaneous',
  'Errors',
  'Feature Request',
  'Usability',
  'Questions',
]

const renderModalContent = (
  formContent: string,
  setFormContent: Function,
  category: string,
  handleCategoryChange: (args: string) => void,
  isFeedbackSubmitted: boolean,
  error: null | boolean,
  setRating: Function,
  rating?: number
) => {
  if (isFeedbackSubmitted) {
    return (
      <S.SuccessContainer>
        <Icon name="CheckmarkCircleF" color={colors.green20} />
        <S.ModalTitle>Feedback Submitted Successfully</S.ModalTitle>
        <div>Thank you for submitting your feedback.</div>
      </S.SuccessContainer>
    )
  } else {
    return (
      <div>
        <Typography variant="title3">Need Help?</Typography>
        <NeedHelpButton />
        <S.ModalTitle data-pho="feedbackModalTitle">Send us your feedback!</S.ModalTitle>
        <div>
          Your opinion is important to us. We take all suggestions seriously, and we are always
          working to provide you with the best possible experience.
        </div>
        <S.CategoryContainer variant="outlined">
          <Flex justifyContent="space-around" width="100%" margin="0 0 2rem">
            <Link
              href="https://chalk.charter.com/x/pSl8eg"
              icon
              variant="standalone"
              data-test="SuggestFeature"
              disableVisited
              newTab
            >
              Suggest a Feature
            </Link>
            <Link
              href="https://chalk.charter.com/x/pSl8eg"
              icon
              variant="standalone"
              data-test="ReportIssue"
              disableVisited
              newTab
            >
              Report an Issue
            </Link>
          </Flex>
          <label>Category</label>
          <Select
            value={category}
            displayEmpty
            onChange={e => handleCategoryChange(e.target.value as string)}
          >
            <MenuItem value="" disabled>
              Select a category
            </MenuItem>
            {feedbackCategories.map(category => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </S.CategoryContainer>

        {!isEmpty(category) && (
          <>
            <S.StarRatingContainer>
              <span>How would you rate your experience?</span>
              <Rating
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue)
                }}
              />
            </S.StarRatingContainer>
            <TextInput
              id="multiline-input"
              width="100%"
              label="Your Suggestions"
              hint="Enter your suggestions here"
              type="multiline"
              rows={4}
              onChange={(e: any) => setFormContent(e.target.value)}
              value={formContent}
            />
          </>
        )}
        <S.FeedbackErrorText shouldDisplay={!!error} data-test="errorMessage">
          Please enter feedback before submitting.
        </S.FeedbackErrorText>
      </div>
    )
  }
}

function FeedbackButton() {
  const location = useLocation()
  const query = queryString.parse((location && location.search) || '', {
    parseBooleans: true,
  })
  const [formContent, setFormContent] = useState('')
  const [error, setError] = useState<null | boolean>(null)
  const [category, setCategory] = useState((query.opUserCat as string) || '')
  const [rating, setRating] = useState()
  const [isModalOpen, setIsModalOpen] = useState(!!query.openFeedbackModal)
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false)
  const { updateValues, filterValues } = useHeader()

  React.useEffect(() => {
    if (query.openFeedbackModal !== isModalOpen) {
      setIsModalOpen(!!query.openFeedbackModal)
    }
    if (query.opUserCat && !category && query.opUserCat !== category) {
      setCategory(query.opUserCat as string)
    }
  }, [query.openFeedbackModal, isModalOpen, category, query.opUserCat])

  function handleClick() {
    updateValues({ openFeedbackModal: true })
  }

  function handleSubmit() {
    if (isEmpty(formContent)) {
      return setError(true)
    }

    setError(null)
    analytics.track('userFeedback', {
      opUserCat: category,
      userName: 'feedbackForm', // operation.userEntry.entryType
      opUserFeedback: formContent,
      // opFeedbackID: 1,
      ...(rating ? { opUserEntryNumeric: rating } : {}), // rating,
      questionType: 'starRating',
      opSurveyId: 'generalExperienceStarRating',
      // opUserEntryStrValue: formContent,
      // opUserText: 'What the user entered',
    })

    setFormContent('')
    setCategory(first(feedbackCategories) as string)
    setIsFeedbackSubmitted(true)
    updateValues({ ...filterValues, opUserCat: null })

    setTimeout(() => {
      setCategory('')
      setError(null)
    }, 1500)
  }

  function handleCategoryChange(category: string) {
    updateValues({ ...filterValues, opUserCat: category })
    setCategory(category)
  }

  const content = renderModalContent(
    formContent,
    setFormContent,
    category,
    handleCategoryChange,
    isFeedbackSubmitted,
    error,
    setRating,
    rating
  )

  function handleGoBackToSite() {
    updateValues({
      ...filterValues,
      opUserCat: null,
      openFeedbackModal: null,
    })
    setTimeout(() => {
      setIsFeedbackSubmitted(false)
    }, 1500)
  }

  const submitAction = (
    <Flex width="100%" justifyContent="space-between" data-test="Submit">
      <S.ModalSubmitButton onClick={handleSubmit}>Submit Feedback</S.ModalSubmitButton>
    </Flex>
  )

  const closeAction = (
    <span>
      <S.ModalSubmitButton onClick={() => handleGoBackToSite()} data-test="GoBack">
        Go Back To Site
      </S.ModalSubmitButton>
    </span>
  )

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => handleGoBackToSite()}
        xClick={() => handleGoBackToSite()}
        content={content}
        justifyActionsContent="center"
        actions={isFeedbackSubmitted ? closeAction : submitAction}
      />
      <S.FeedbackButtonContainer>
        <Button onClick={handleClick} data-pho="feedbackButton" variant="neutral" icon="Support">
          Need Help
        </Button>
      </S.FeedbackButtonContainer>
    </>
  )
}

export default FeedbackButton
