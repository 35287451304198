import * as React from 'react'

function SvgPlayF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 26" {...props}>
      <path d="M20.89 13L0 26V0z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default SvgPlayF
