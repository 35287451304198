import React from 'react'
import { useLocation } from '@reach/router'
import services from 'common/services'
import useApi from 'hooks/useApi'
import { checkIfGroupsAreTheSame } from 'utils'

interface GroupsState {
  groups: Group[]
  currentGroup?: Group
  fetchNewData?: any
}

export interface Group {
  applicationIDs: string[]
  description: string
  _id: string
  name: string
}

const initialState = { groups: [] }

export const GroupsContext = React.createContext<
  GroupsState & { update: (updates: Partial<GroupsState>) => void }
>({ ...initialState, update: () => null })

function GroupsFetcher() {
  const { groups, currentGroup, update } = React.useContext(GroupsContext)
  const { data: newGroups = [], mutate } = useApi({ service: services.groups })
  const fetchNewData = React.useCallback(mutate, [mutate])
  const location = useLocation()
  const path = location.pathname

  React.useEffect(() => {
    const newCurrentGroup = getCurrentGroup(newGroups, path)
    const areGroupsTheSame = checkIfGroupsAreTheSame(groups, newGroups)
    if (!areGroupsTheSame) {
      update({ groups: newGroups, currentGroup: newCurrentGroup, fetchNewData })
    } else if (currentGroup !== newCurrentGroup) {
      update({ currentGroup: newCurrentGroup })
    }
  }, [groups, path, currentGroup, update, fetchNewData, newGroups])

  return null
}

function GroupsProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = React.useState<GroupsState>(initialState)

  function update(updates: Partial<GroupsState>) {
    setState({ ...state, ...updates })
  }

  return (
    <GroupsContext.Provider value={{ ...state, update }}>
      <>
        <GroupsFetcher />
        {children}
      </>
    </GroupsContext.Provider>
  )
}

export function getCurrentGroup(groups: Group[], path: string): Group | undefined {
  const currentRootPath = decodeURIComponent(path).split('/')[1]
  return groups.find(group => group?._id === currentRootPath)
}

export default GroupsProvider
