import React from 'react'

export const RightSidebarContext = React.createContext<RightSidebarValue>({} as RightSidebarValue)

interface RightSidebarValue {
  state: RightSidebarStateProps
  containerRef: React.RefObject<HTMLDivElement>
  setState: Function
}

export interface RightSidebarStateProps {
  isOpen: boolean
}

const INITIAL_STATE = {
  state: {
    isOpen: false,
  },
  setState: () => {},
}

function RightSidebarState({ children }: { children: React.ReactNode }) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [state, setState] = React.useState(INITIAL_STATE.state)

  return (
    <RightSidebarStateProvider value={{ state, setState, containerRef }}>
      {children}
    </RightSidebarStateProvider>
  )
}

export default RightSidebarState
export const RightSidebarStateProvider = RightSidebarContext.Provider
export const RightSidebarStateConsumer = RightSidebarContext.Consumer
