import * as React from 'react'

function SvgPresentationF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M27.98 13.72L25.999 0H2L.02 13.72a1.985 1.985 0 00.475 1.592c.384.437.94.688 1.527.688h10.967v6H9.957a2.017 2.017 0 00-2.022 2h12.13a2.017 2.017 0 00-2.022-2h-3.032v-6h10.967a2.03 2.03 0 001.527-.688 1.985 1.985 0 00.475-1.592zM9.957 5h8.086v2H9.957V5zm12.13 6H5.913V9h16.174v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPresentationF
