import styled from 'styled-components'

export const Container = styled.div<{ hoverInfoColor?: string }>`
  font-family: 'Open Sans';
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans';
  color: ${props => props.hoverInfoColor};

  div:nth-child(2) {
    position: absolute;
  }
`

export const InfoText = styled.p`
  text-align: right;
  font-size: 0.75rem;
  position: absolute;
  bottom: 24px;
`
