import * as React from 'react'

function SvgBankF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 11V7.43L14.51.72a3.018 3.018 0 00-3.02 0L0 7.43V11h4v9H2v2h22v-2h-2v-9h4zm-14 9H6v-9h6v9zm8 0h-6v-9h6v9zM0 24h26v2H0v-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgBankF
