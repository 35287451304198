import React from 'react'
import styled, { css } from 'styled-components'
import { element } from 'components/shared/utils/spacing'

export const Circle = styled(({ color, ...otherProps }: { color?: string; [key: string]: any }) => (
  <div {...otherProps} />
))`
  width: 9px;
  height: 9px;
  background-color: ${props => props.color};
  margin-right: 4px;
  border-radius: 50%;
`
export const LegendDisplay = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`
export const LegendContainer = styled.div<{ withoutAbsolutePosition?: boolean }>`
  display: flex;
  margin-top: ${props => !props.withoutAbsolutePosition && '0.2rem'};
  /* ${props => !props.withoutAbsolutePosition && 'position: absolute'}; */
  z-index: 9;
  min-height: 1rem;
  flex-wrap: wrap;
  gap: ${element.sm};
  width: 100%;
  justify-content: center;
`

export const LabelContainer = styled.div<{ clickable?: any; color?: string }>`
  font-family: 'Open Sans';
  min-width: 4rem;
  display: flex;
  font-size: 0.75rem;
  cursor: pointer;
  align-items: center;
  line-height: 1;
  padding: 4px;

  /* border: 1px solid ${({ theme: { colors }, color }) => color || colors.gray20}; */
  border-radius: 4px;
  padding: 0;
  justify-content: center;

  ${props =>
    props.clickable &&
    css`
      font-weight: bold;
      color: ${({ theme: { colors } }) => colors.blue20};

      span:last-child {
        color: ${({ theme: { colors } }) => colors.blue20};
        opacity: 0;
      }
    `}

  :hover {
    background-color: ${({ theme: { colors, isDarkMode } }) =>
      isDarkMode ? colors.gray30 : colors.gray20};
    ${props =>
      props.clickable &&
      css`
        color: ${({ theme: { colors } }) => colors.blue20};
        text-decoration: underline;

        span:last-child {
          opacity: 1;
        }
      `}
  }
`
