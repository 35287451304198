import * as React from 'react'

function SvgSend(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M17.157 27.342L11.9 17.88l2.96-4.74-4.74 2.96-9.46-5.258L27.763.236 17.157 27.342zm-2.937-9.403l2.623 4.72 7.393-18.895-18.894 7.393 4.719 2.623 11.08-6.92-6.921 11.08z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSend
