import React, { useEffect } from 'react'
import { useLocation, navigate } from '@reach/router'
import queryString from 'query-string'
import map from 'lodash/map'
import debounce from 'lodash/debounce'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import * as S from './styles'

function RoundedSearch({
  id,
  className,
  input,
  onChangeInput,
  onEnter,
  placeholder,
  searchBy,
  type,
  dataPho,
  initialValue,
  filterId,
}: RoundedSearchProps) {
  const [value, setValue] = React.useState(input ?? '')
  const [searchInitialValue, setSearchInitialValue] = React.useState(initialValue ?? '')
  const debouncedChange = onChangeInput && debounce(onChangeInput, 10)
  const { pathname } = useLocation()

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value)
    setSearchInitialValue('')
    debouncedChange && debouncedChange(e.target.value)
  }

  function handleByChange(e: React.ChangeEvent<{ name?: string; value: any }>) {
    searchBy && searchBy.onChange(e.target.value)
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Enter' && onEnter) {
      onEnter && onEnter(value)
      filterId && navigateTo(value)
    }
  }

  function handleClick() {
    onEnter && onEnter(value)
    filterId && navigateTo(value)
  }

  function navigateTo(value: string) {
    if (filterId) {
      const nextQueryString = queryString.stringify({ [filterId]: value })
      navigate(`${pathname}${nextQueryString ? `?${nextQueryString}` : ''}`, {
        replace: true,
      })
    }
  }

  // rerenders the input if input is changed outside the handleChange function
  useEffect(() => {
    setValue(input ? input : '')
  }, [input])

  return (
    <S.RoundedContainer hasSearchBy={!!searchBy} className={className}>
      {searchBy && (
        <S.SearchBy>
          <S.SearchByTitle>Search by</S.SearchByTitle>
          <S.Select value={searchBy.value} onChange={handleByChange} input={<InputBase />}>
            {map(searchBy.options, (byVal, byKey) => (
              <MenuItem key={byKey} value={byKey}>
                {byVal}
              </MenuItem>
            ))}
          </S.Select>
        </S.SearchBy>
      )}
      <S.StyledInput
        id={id}
        value={searchInitialValue || value}
        placeholder={placeholder || 'Search...'}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        type={type}
        spellCheck="false"
        data-pho={searchBy ? `searchBy${dataPho ?? ''}Input` : `${dataPho ?? ''}Input`}
      />
      <S.StyledIconButton
        onClick={handleClick}
        aria-label="Search"
        icon="Search"
        data-pho={`searchIconButton${dataPho ?? ''}`}
      />
    </S.RoundedContainer>
  )
}

interface RoundedSearchProps {
  id?: string
  className?: string
  input?: string
  initialValue?: string
  onChangeInput?: (value: string) => void
  onEnter?: (value: string) => void
  placeholder?: string
  searchBy?: {
    value?: string
    options: Record<string, string>
    onChange: (value: string) => void
  }
  type?: string
  dataPho?: string
  filterId?: string
}

export default RoundedSearch
