import React from 'react'
import styled, { css } from 'styled-components'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import List from '@material-ui/core/List'
import MuiLaunchIcon from '@material-ui/icons/Launch'
import Avatar from '@material-ui/core/Avatar'
import { Card } from 'components/shared/Card'
import ListItem from '@material-ui/core/ListItem'
import MuiExpandLessIcon from '@material-ui/icons/ExpandLess'
import MuiExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiChip from '@material-ui/core/Chip'

export const Item = styled(({ isIndented, isMenu, isCondensed, ...props }) => (
  <ListItem {...props} />
))`
  padding: 8px 16px 8px 16px;
  line-height: 1.15;

  ${({ isMenu, isCondensed, theme: { colors } }) =>
    isCondensed &&
    css`
      background-color: ${colors.gray10};
    `}

  ${props =>
    props.isIndented &&
    css`
      padding-left: 40px;
      width: 100%;
    `}

  ${({ theme: { isDarkMode, colors } }) =>
    isDarkMode &&
    css`
      &:hover {
        background: ${colors.gray20};
      }
    `}
`
export const Container = styled(Card)`
  && {
    border-radius: 0;
    overflow-y: auto;
    border: none;
    border-right: ${({ theme: { isDarkMode, colors } }) =>
      isDarkMode ? 'none' : `1px solid ${colors.gray20}`};
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  overflow-x: hidden;
  padding: 1.5rem 0;
  padding-top: 0;
  background-color: transparent;
`

export const ItemTitle = styled(({ isActive, theme, ...props }) => <ListItemText {...props} />)`
  color: ${({ isActive, theme: { colors, isDarkMode } }) => {
    if (isDarkMode) {
      return isActive ? colors.blue10 : colors.gray10
    } else return isActive ? colors.blue20 : colors.gray30
  }};
  font-size: 0.875rem;
`

export const MenuTitle = styled(ItemTitle)`
  color: ${({ theme: { colors } }) => colors.black};
  > span {
    font-weight: 500;
  }
`

export const ItemIcon = styled(({ isActive, isGrayscaleImg, ...props }) => (
  <ListItemIcon {...props} />
))`
  min-width: 2rem;

  svg {
    color: ${({ isActive, theme: { colors, isDarkMode } }) => {
      if (isDarkMode) {
        return isActive ? colors.blue10 : colors.gray10
      } else return isActive ? colors.blue20 : colors.gray30
    }};
  }
  // this fixes a bug in the compass icon of prism-react
  svg > path {
    fill: ${({ isActive, theme: { colors, isDarkMode } }) => {
      if (isDarkMode) {
        return isActive ? colors.blue10 : colors.gray10
      } else return isActive ? colors.blue20 : colors.gray30
    }};
  }
  img {
    filter: ${({ isActive, isGrayscaleImg, theme: { isDarkMode } }) =>
      isActive
        ? 'sepia(100%) hue-rotate(190deg) saturate(500%)'
        : isGrayscaleImg
        ? isDarkMode
          ? 'grayscale(1) brightness(5) brightness(.9)'
          : 'grayscale(1)'
        : 'none'};
  }
`

export const LaunchIconContainer = styled(ItemIcon)`
  padding-left: 1rem;
`

export const ApplicationIDIcon = styled(Avatar)`
  font-size: 0.75rem;
  height: 1.875rem;
  width: 1.875rem;
  margin-right: 0.5rem;
`

export const LaunchIcon = styled(MuiLaunchIcon)`
  height: 1.125rem;
`

export const ItemExpandLessIcon = styled(MuiExpandLessIcon)`
  color: ${({ theme: { colors, isDarkMode } }) => (isDarkMode ? colors.gray10 : colors.gray30)};
`

export const ItemExpandMoreIcon = styled(MuiExpandMoreIcon)`
  color: ${({ theme: { colors, isDarkMode } }) => (isDarkMode ? colors.gray10 : colors.gray30)};
`

export const MenuExpandLessIcon = styled(MuiExpandLessIcon)`
  color: ${({ theme: { colors, isDarkMode } }) => (isDarkMode ? colors.white : colors.black)};
`

export const MenuExpandMoreIcon = styled(MuiExpandMoreIcon)`
  color: ${({ theme: { colors, isDarkMode } }) => (isDarkMode ? colors.white : colors.black)};
`

export const MenuContent = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
`

export const Menu = styled(Item)`
  min-height: 4rem;
`

export const MenuTitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.black};
  justify-content: space-between;
`

export const BetaChip = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

export const MenuDescription = styled.div`
  color: ${({ theme: { colors, isDarkMode } }) => (isDarkMode ? colors.gray10 : colors.gray30)};
  font-size: 0.8rem;
  max-width: 12.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Menus = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
`

export const Chip = styled(MuiChip)<{ isRound?: boolean }>`
  font-size: 0.6rem;
  height: 1.25rem;
  background-color: ${({ theme: { colors } }) => colors.blue10};
  font-weight: 500;
  margin: 0 0.5rem;
  color: white;
  > span {
    padding: 0px 5px;
  }

  ${props =>
    props.isRound &&
    css`
      width: 1.25rem;
    `}
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`
export const CondensedHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
`

export const SidebarFooter = styled.div`
  display: block;
`

export const ApplicationVersion = styled.span`
  display: inline-block;
  align-self: flex-start;
  color: ${({ theme: { colors } }) => colors.gray30};
  padding: 0.375rem;
  font-size: 0.8rem;
`

export const SidebarContent = styled.div`
  width: 100%;
`

export const LogoContainer = styled.img<{
  width?: string
  maxHeight?: string
  hasPaddingLeft?: boolean
}>`
  border: none;
  width: ${props => props.width || '1rem'};
  max-height: ${props => props.maxHeight || '1rem'};
  padding-left: ${({ hasPaddingLeft }) => (hasPaddingLeft ? '2px' : 'unset')};
`
