import React from 'react'
import useRightSidebarState from 'hooks/useRightSidebarState'
import debounce from 'lodash/debounce'

const HALF_SECOND = 500

function useElementWidth({ initialWidth }: { initialWidth: number }) {
  const { state: rightSidebarState } = useRightSidebarState()
  const elementRef = React.useRef<HTMLDivElement | null>(null)
  const [elementWidth, setElementWidth] = React.useState(initialWidth)
  const [hasInitialized, setHasInitialized] = React.useState(false)
  const rightSidebarIsOpen = rightSidebarState?.isOpen

  const currentWidth = elementRef?.current?.offsetWidth

  React.useEffect(() => {
    if (elementRef.current && !hasInitialized) {
      const newWidth = elementRef?.current?.offsetWidth

      setElementWidth(newWidth)
      setHasInitialized(true)
    }

    if (hasInitialized && currentWidth && elementWidth !== currentWidth) {
      setElementWidth(currentWidth)
    }
  }, [elementRef, elementWidth, hasInitialized, currentWidth])

  React.useEffect(() => {
    function handleResize() {
      if (elementRef.current) {
        const newWidth = elementRef?.current?.offsetWidth

        newWidth !== elementWidth && setElementWidth(newWidth)
      }
    }

    const debouncedHandleResize = debounce(handleResize, HALF_SECOND)

    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  }, [elementRef, elementWidth])

  React.useEffect(() => {
    if (elementRef.current) {
      const newWidth = elementRef?.current?.offsetWidth

      newWidth !== elementWidth && setElementWidth(newWidth)
    }
  }, [rightSidebarIsOpen, elementWidth])

  return { elementRef, elementWidth }
}

export default useElementWidth
