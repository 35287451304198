import * as React from 'react'

function SvgCallMissed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 28" {...props}>
      <path
        d="M6.913.788a2.992 2.992 0 012.175.886l3.172 3.17L8.567 11H5.66a10.354 10.354 0 00.08 6h2.826l3.693 6.155-3.172 3.171a3.039 3.039 0 01-2.14.886l-.072-.001a2.911 2.911 0 01-2.107-.957c-6.34-6.94-6.34-17.569 0-24.508A2.992 2.992 0 016.913.788zm.036 2h-.025a.928.928 0 00-.674.302C.606 9.268.606 18.732 6.25 24.91c.173.19.417.3.674.302.28.008.552-.1.75-.3l2.067-2.067L7.434 19H4.279l-.228-.684c-1.084-3.252-1.063-6.528.054-8.763L4.383 9h3.052L9.74 5.156 7.674 3.088a1.03 1.03 0 00-.725-.3zM26 11v6h-2v-2.586l-6 6-7.707-7.707 1.414-1.414L18 17.586 22.586 13H20v-2h6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCallMissed
