import * as React from 'react'

function SvgCreditCardFail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M18 3.004v2H3c-.552 0-.999.448-1 1v1h16.01v2H2v2h23V9h2v11.004a3.003 3.003 0 01-3 3H0v-17a3.003 3.003 0 013-3h15zm7 10H2v8h22c.553 0 1-.448 1-1v-7zM14 17v2H3v-2h11zM26.293.293l1.414 1.414L25.414 4l2.293 2.293-1.414 1.414L24 5.414l-2.293 2.293-1.414-1.414L22.586 4l-2.293-2.293L21.707.293 24 2.586 26.293.293z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCreditCardFail
