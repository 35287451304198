import * as React from 'react'

function SvgDesktopF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M28 17V2a2 2 0 00-2-2H2a2 2 0 00-2 2v15a2 2 0 002 2h8.8l-.6 3H9a2 2 0 00-2 2h14a2 2 0 00-2-2h-1.2l-.6-3H26a2 2 0 002-2zm-12.2 5h-3.6l.6-3h2.4l.6 3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDesktopF
