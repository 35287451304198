import * as React from 'react'

function SvgCar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M9 10h10v2H9v-2zm19-6h-3.4l-1.2-2.3A2.8 2.8 0 0020.8 0H7.2a2.8 2.8 0 00-2.6 1.7L3.4 4H0v2h2.4L.3 10.1a3.2 3.2 0 00-.3 1.4V24h4a2.9 2.9 0 003-3v-1h14v1a2.9 2.9 0 003 3h4V11.5a3.2 3.2 0 00-.3-1.4L25.6 6H28V4zm-6.8 8l4.7-.8a.4.4 0 01.1.3V18h-3v-4H5v4H2v-6.5a.9.9 0 01.1-.5l4.7 1 .4-2L3 9.2 3.6 8h20.8l.6 1.3-4.2.7.4 2zM6.3 2.6a1.1 1.1 0 01.9-.6h13.6a1.1 1.1 0 01.9.6L23.4 6H4.6l1.7-3.4zM5 21a.9.9 0 01-1 1H2v-2h3v1zm2-3v-2h14v2H7zm17 4a.9.9 0 01-1-1v-1h3v2h-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCar
