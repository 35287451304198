import * as React from 'react'

function SvgWeatherF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
      <path
        d="M16 25v4.144h-2V25h2zm-8.707-3.707l1.414 1.414-4 4-1.414-1.415 4-4zm15.414 0l4 4-1.414 1.414-4-4 1.414-1.415zM15 7a8 8 0 110 16 8 8 0 010-16zm10.002 7l4.14.004-.003 2-4.14-.004.003-2zM5 14.002v2H.86v-2H5zm20.293-10.71l1.414 1.415-4 4-1.414-1.415 4-4zm-20.586 0l4 4-1.414 1.415-4-4 1.414-1.415zM16 .854V5h-2V.854h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgWeatherF
