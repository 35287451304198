import React from 'react'

export interface DarkMode {
  isDarkMode: boolean
  toggleDarkMode: () => void
  setIsDarkMode: (newState: boolean) => void
}

export const DarkModeContext = React.createContext<DarkMode>({
  isDarkMode: false,
  toggleDarkMode: () => null,
  setIsDarkMode: () => null,
})

export const DarkModeProvider: React.FC<{ startDark?: boolean }> = ({ startDark = false, children }) => {
  const [isDarkMode, setIsDarkMode] = React.useState(startDark)

  function toggleDarkMode(): void {
    setIsDarkMode(!isDarkMode)
  }

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode, setIsDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  )
}
