import styled from 'styled-components'
import { colors } from 'components/shared/utils/colors'

export const StyledNoDataMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #aaa;
  font-weight: bold;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 0.25rem;
`

export const TopContainer = styled.div`
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
`

export const BottomContainer = styled.div<{
  height?: number
}>`
  height: ${props => (props.height ? props.height + 'px' : '100%')};
  flex: 1;
  min-height: 0;
`

export const TabbedChartTopContainer = styled.div`
  flex: unset;
  flex-direction: column;
  display: flex;
  position: relative;
`

export const ChartDescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 44px;
`

export const ChartDescription = styled.div`
  color: ${colors.gray30};
  font-size: 0.7rem;
`

export const ButtonTabContainer = styled.div`
  display: flex;
  margin: 0.5rem 0;
`

export const ChartFooter = styled.div`
  border-top: solid ${colors.gray20} 1px;
  display: flex;
  justify-content: space-between;
  margin: 0 -1rem;
  padding: 0.3rem 1rem;
`

export const UnderConstructionTitle = styled.p`
  font-size: 1.5rem;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  margin-bottom: 15px;
`

export const UnderConstructionExpectedBy = styled.div`
  font-size: 0.825rem;
  font-weight: 500;
`

export const UnderConstructionContainer = styled.div`
  flex-direction: column;
  text-align: center;
`
