import * as React from 'react'

function SvgCc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 24H0V3a3.003 3.003 0 013-3h22a3.003 3.003 0 013 3v18a3.003 3.003 0 01-3 3zM2 22h23a1 1 0 001-1V3a1 1 0 00-1-1H3a1 1 0 00-1 1v19zm8-4H8a3.003 3.003 0 01-3-3V9a3.003 3.003 0 013-3h2a3.003 3.003 0 013 3h-2a1 1 0 00-1-1H8a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1h2a3.003 3.003 0 01-3 3zm10 0h-2a3.003 3.003 0 01-3-3V9a3.003 3.003 0 013-3h2a3.003 3.003 0 013 3h-2a1 1 0 00-1-1h-2a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1h2a3.003 3.003 0 01-3 3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCc
