import * as React from 'react'

function SvgBatteryFull(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 28" {...props}>
      <path
        d="M12 0v4h1a3.003 3.003 0 013 3v18a3.003 3.003 0 01-3 3H3a3.003 3.003 0 01-3-3V7a3.003 3.003 0 013-3h1V0h8zm1 6H3a1 1 0 00-1 1v18a1 1 0 001 1h10a1 1 0 001-1V7a1 1 0 00-1-1zm-1 2v16H4V8h8zm-2-6H6v2h4V2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgBatteryFull
