import React from 'react'
import * as S from './styles'

function EntityIcon({ letters }: EntityIconProps) {
  const firstLetter = letters[0]
  const secondLetter = letters[1]
  return (
    <S.IconContainer>
      {firstLetter}
      {secondLetter}
    </S.IconContainer>
  )
}

interface EntityIconProps {
  letters: string
}

export default EntityIcon
