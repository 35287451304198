import React from 'react'
import { Dropdown } from 'components/shared/Dropdown'
import MenuList from '@material-ui/core/MenuList'
import PopoverMenu from 'components/shared/popover'
import DataSourcesList from './DataSourcesList'
import * as S from './styles'

function Popover({ handleClose }: { handleClose: (event: any) => void }) {
  return (
    <S.MenuContainer>
      <MenuList>
        <DataSourcesList handleClose={handleClose} />
      </MenuList>
    </S.MenuContainer>
  )
}

function DataSourcesSwitcher() {
  return (
    <>
      <div data-pho="topbarDataSourcesSwitcher">
        <PopoverMenu
          anchorContent={
            <>
              <Dropdown label="Data Sources" placeholder="" items={[]} isInline />
            </>
          }
        >
          {({ isPopoverOpen, handleClose }) =>
            isPopoverOpen ? <Popover handleClose={handleClose} /> : null
          }
        </PopoverMenu>
      </div>
    </>
  )
}

export default DataSourcesSwitcher
