import * as React from 'react'

function SvgMaintenance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M25.311 3.97l-.614-1.261-4.285 4.285-1.407-1.405 4.285-4.284L22.03.69A6.848 6.848 0 0019 0a7.009 7.009 0 00-7 7c-.001.803.137 1.6.41 2.356L.888 20.877a2.994 2.994 0 104.23 4.238L16.646 13.59a6.886 6.886 0 002.356.41 7.009 7.009 0 007-7 6.863 6.863 0 00-.69-3.028zM3.7 23.707a.99.99 0 01-1.407-.01.973.973 0 01.005-1.4l11.104-11.104a7.017 7.017 0 00.694.788c.223.221.46.428.711.618L3.7 23.706zM19 12a4.88 4.88 0 01-2.157-.492 5.034 5.034 0 01-2.353-2.355V9.15A4.867 4.867 0 0114 7a5.006 5.006 0 015.716-4.95l-3.54 3.54 4.235 4.233 3.54-3.54A5.006 5.006 0 0119.002 12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMaintenance
