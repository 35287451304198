import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation } from '@reach/router'
import useApps from 'hooks/useApps'
import services from 'common/services'
import { GET_FILTERED_VARIANTS_IDS } from 'components/shared/FilterMenu/queries'
import { getAppNames, getAppTypes } from 'utils/sharedUtils'
import { times } from 'utils'
import moment from 'moment'
import { axios, axiosConfigBase } from 'hooks/useApi'
import { rootApiUrl } from 'common/constants'

interface VariantNamesData {
  variantNames: Record<string, string>
}

interface Variant {
  uuid: number
  name: string
}

interface Experiment {
  variants: Variant[]
}

export const VariantNamesContext = React.createContext<VariantNamesData>({} as VariantNamesData)

function VariantNames({ children }: { children: React.ReactNode }) {
  const { search } = useLocation()
  const { currentApps } = useApps()
  const queries = queryString.parse(search || '', { arrayFormat: 'bracket' })
  const [fromDate, toDate] = times.normalizeToAbsolutePeriod(queries?.period as string).split(':')
  const [experimentData, setExperimentData] = useState<Record<string, any>>()
  const appNames = getAppNames(currentApps)
  const appTypes = getAppTypes(currentApps)
  let activatedExperiments: string = ''
  if (Array.isArray(queries?.activatedExperiments) && queries.activatedExperiments[0] !== null) {
    activatedExperiments = queries.activatedExperiments[0]
  }

  useEffect(() => {
    if (!activatedExperiments) return
    const fetchExperimentData = async () => {
      let activatedExperimentsQuery: string[] = ['']
      if (Array.isArray(queries?.activatedExperiments) && queries?.activatedExperiments?.length) {
        activatedExperimentsQuery = queries?.activatedExperiments.join('').split(',')
      }
      const { data } = await axios({
        ...axiosConfigBase(),
        url: `${rootApiUrl}${services.distillery.url}`,
        method: 'POST',
        data: {
          query: GET_FILTERED_VARIANTS_IDS,
          variables: {
            where: {
              uuid: {
                in: activatedExperimentsQuery,
              },
              AND: [
                {
                  startTime: {
                    gte: moment(parseInt(fromDate)).toISOString(),
                  },
                },
                {
                  startTime: {
                    lte: moment(parseInt(toDate)).toISOString(),
                  },
                },
              ],
              experimentToProductFeatures: {
                every: {
                  product: {
                    is: {
                      OR: [
                        {
                          quantumApplicationName: {
                            in: appNames,
                          },
                          quantumApplicationType: {
                            in: appTypes,
                          },
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
        },
      })
      setExperimentData(data)
    }
    if (fromDate) {
      fetchExperimentData()
    }
  }, [fromDate, activatedExperiments])

  const experiments = experimentData?.data?.experiments
  const variantNames = experiments?.reduce((acc: string[], experiment: Experiment) => {
    const { variants } = experiment
    variants.forEach((variant: Variant) => {
      acc = { ...acc, [variant.uuid]: variant.name }
    })
    return acc
  }, {})
  return (
    <VariantNamesContext.Provider value={{ variantNames }}>{children}</VariantNamesContext.Provider>
  )
}

export default VariantNames
