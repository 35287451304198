import { types } from '..'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import * as S from '../styles'
export default function SortLabel({
  sortField,
  header,
  sortDirection,
  handleSortChange,
  columnWidths,
  setColumnWidths
}: {
  sortField: string
  header: types.ColumnHeader
  sortDirection: string
  handleSortChange: Function
  columnWidths: any
  setColumnWidths: any
}) {
  function renderTableSortLabel(
    sortField: string,
    header: { id: string; label: string; align?: string },
    sortDirection: string
  ) {
    return (
      <TableSortLabel
        key={header.id}
        active={sortField === header.id}
        direction={sortDirection as any}
        onClick={() => handleSortChange(header.id)}
        data-pho="feedbackTableSortLabel"
      >
        {header.label}
      </TableSortLabel>
    )
  }

  switch (header.id) {
    case 'awsSentiment':
      return (
        <TableCell
          key={header.id}
          align={(header.align as any) ?? 'left'}
          data-pho={`awsSentimentFeedbackTableHeader`}
          style={{ minWidth: columnWidths[header.id], width: columnWidths[header.id] }}
        >
          {header.label}
          <S.MultipleTableSorts >
            {header.sublabels?.map((sublabel: { id: string; label: string }) =>
              renderTableSortLabel(sortField, sublabel, sortDirection)
            )}
          </S.MultipleTableSorts>
        </TableCell>
      )
    default:
      return (
        <TableCell
          key={header.id}
          align={(header.align as any) ?? 'left'}
          style={{ width: `${columnWidths[header.id]}px` }}
        >
          <div>
            <S.RndPanel
              disableDragging={true}
              size={{ width: Math.max(columnWidths[header.id], 100) }}
              enableResizing={{ right: true }}
              onResizeStop={(e: any, direction: any, ref: any, delta: any) => {
                const newWidth = Math.max(columnWidths[header.id] + delta.width, 100);
                const newWidths = { ...columnWidths, [header.id]: newWidth };
                setColumnWidths(newWidths);
              }}
              style={{
                borderRight: '2px solid gray',
                marginRight: '10px',
                position: 'relative',
              }}
            >
              {renderTableSortLabel(sortField, header, sortDirection)}
            </S.RndPanel>
          </div>
        </TableCell>
      )
  }
}
