import { useRef, useState } from 'react'
import last from 'lodash/last'
import * as S from './styles'

function RowCell(props: RowCellProps) {
  const { textAlign, width, rowContent, trimValue } = props
  const [overflowTitle, setOverflowTitle] = useState('')
  const TitleRef = useRef<any>(null)

  const handleOverflowTitle = (title: string) => {
    if (TitleRef.current === undefined || TitleRef.current === null) return
    const { clientWidth, scrollWidth } = TitleRef.current
    if (scrollWidth > clientWidth) {
      setOverflowTitle(title)
    } else {
      setOverflowTitle('')
    }
    return
  }

  return (
    <S.Cell
      ref={TitleRef}
      onMouseEnter={() => handleOverflowTitle(rowContent || '')}
      textAlign={textAlign}
      title={overflowTitle}
      width={width}
      data-pho="TopChartCell"
    >
      {trimValue ? last(rowContent?.split(':')) : rowContent}
    </S.Cell>
  )
}

interface RowCellProps {
  textAlign: string
  width: string
  rowContent?: string
  trimValue?: boolean
}

export default RowCell
