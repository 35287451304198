import * as React from 'react'

function SvgRecordSeriesF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 14" {...props}>
      <path
        d="M21 0H7a7 7 0 000 14h14a7 7 0 000-14zm-7 12h-2.11l.03-.03c.08-.08.15-.16.23-.24.08-.09.16-.17.23-.26a2.69 2.69 0 00.2-.27c.07-.09.14-.17.21-.27.06-.09.12-.2.18-.3.063-.09.12-.183.17-.28.06-.104.113-.21.16-.32.05-.1.1-.19.14-.29.05-.114.093-.231.13-.35.04-.1.08-.2.11-.3.04-.13.07-.26.1-.39.03-.09.06-.19.08-.28.03-.15.05-.31.07-.47.015-.076.025-.153.03-.23a6.503 6.503 0 000-1.44 1.81 1.81 0 00-.03-.23c-.02-.16-.04-.32-.07-.47-.02-.09-.05-.19-.08-.28-.03-.13-.06-.26-.1-.39-.03-.1-.07-.2-.11-.3a3.612 3.612 0 00-.13-.35c-.04-.1-.09-.19-.14-.29-.047-.11-.1-.216-.16-.32a2.592 2.592 0 00-.17-.28c-.06-.1-.12-.21-.18-.3-.07-.1-.14-.18-.21-.27a2.69 2.69 0 00-.2-.27c-.07-.09-.15-.17-.23-.26-.08-.08-.15-.16-.23-.24L11.89 2H14a5 5 0 010 10zm7 0h-2.11a6.98 6.98 0 000-10H21a5 5 0 010 10z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRecordSeriesF
