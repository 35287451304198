import React from 'react'
import FilterMenu from 'components/shared/FilterMenu'
import { FilterMenuProps } from 'components/shared/FilterMenu/types'
import { QueryParamsProps } from './useQueryStringParams'

export default function useFilterMenu({
  filters,
  values,
  updateValues,
  batchUpdateValues,
}: Pick<FilterMenuProps, 'filters' | 'values' | 'updateValues' | 'batchUpdateValues'> &
  Omit<QueryParamsProps, 'params'>) {
  function FilterMenuWithValues({
    isGroupByMenu,
    defaultX,
    defaultY,
    disabled,
    useFilterApplyButton,
  }: Omit<FilterMenuProps, 'filters' | 'values' | 'batchUpdateValues' | 'updateValues'> & {
    isGroupByMenu?: boolean
  }) {
    return (
      <FilterMenu
        filters={filters}
        values={values}
        batchUpdateValues={batchUpdateValues}
        updateValues={updateValues}
        isGroupByMenu={isGroupByMenu}
        defaultX={defaultX}
        defaultY={defaultY}
        disabled={disabled}
        useFilterApplyButton={useFilterApplyButton}
      />
    )
  }

  return {
    filterValues: values,
    FilterMenu: FilterMenuWithValues,
    updateFilterValues: updateValues,
    batchUpdateFilterValues: batchUpdateValues,
  }
}
