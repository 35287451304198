import * as React from 'react'

function SvgStreamingBox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M28 10v11a3.003 3.003 0 01-3 3H3a3.003 3.003 0 01-3-3V10h28zm-2 6H2v5a1 1 0 001 1h22a1 1 0 001-1v-5zm-3 2a1 1 0 110 2 1 1 0 010-2zM8 18v2H4v-2h4zm18-6H2v2h24v-2zm-5.622-5.925l-.766 1.848a14.58 14.58 0 00-11.224 0l-.766-1.848a16.562 16.562 0 0112.756 0zm3.042-3.981l-.852 1.81a20.061 20.061 0 00-17.136 0l-.852-1.81a22.06 22.06 0 0118.84 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgStreamingBox
