import * as React from 'react'

function SvgFastForwardF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 20" {...props}>
      <path
        d="M10 19.79v-6.15L0 19.79V.22l10 6.15V.21L25.91 10z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFastForwardF
