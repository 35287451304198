import * as React from 'react'

function SvgSpeaker(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 24" {...props}>
      <path
        d="M6.697 7.084H3a3.003 3.003 0 00-3 3v5a3.003 3.003 0 003 3h3.741L17 23.784V.216L6.697 7.084zm-4.697 8v-5a1 1 0 011-1h3v7H3a1 1 0 01-1-1zm13 5.301l-7-3.889V8.62l7-4.667v16.432z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSpeaker
