import * as React from 'react'

function SvgTriangleF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 25" {...props}>
      <path d="M27.685 25H.315L14-.088z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default SvgTriangleF
