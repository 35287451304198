import React from 'react'
import 'react-grid-layout/css/styles.css'
import { Method } from 'axios'
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import { axios, axiosConfigBase } from 'hooks/useApi'
import { rootApiUrl } from 'common/constants'
import { ChartObj } from 'components/shared/chart/types'
import queryString from 'query-string'
import { getService, getQueryAppsStringified } from 'components/shared/ChartData//utils'
import { generateChartQueryString } from 'components/shared/chart/utils'

async function getDataFilteredCharts({
  filteredCharts,
  filterValues,
  currentApps,
  entityID,
  appsByDataSource,
  onResponse,
}: UseFilteredDataArguments) {
  const result = filteredCharts.map(async (chart: any) => {
    const service = getService(chart?.type)
    const generatedQuery = generateChartQueryString(
      chart?.query,
      filterValues,
      chart?.filterTemplates,
      chart?.type,
      chart?.customFilters
    )
    const query = `${generatedQuery}&${queryString.stringify(
      getQueryAppsStringified(currentApps, generatedQuery, entityID, appsByDataSource.qube)
    )}`

    // Do not call API if metric is outdated
    if (chart.isMetricOutdated) {
      return {
        chart: {
          query: queryString.parse(query),
          type: chart.type,
          title: chart.title,
        },
      }
    } else {
      const axiosConfig = {
        ...axiosConfigBase(),
        method: 'GET' as Method,
        url: `${rootApiUrl}${service.url}?${query}`,
      }
      const response = await axios(axiosConfig)

      return {
        data: response?.data?.data,
        chart: {
          query: queryString.parse(query),
          type: chart.type,
          title: chart.title,
        },
      }
    }
  })

  const allData = await Promise.all(result)
  onResponse && onResponse({ responses: allData })
  return allData
}

// Called when filteredCharts changed
function useFilteredCharts({
  filteredCharts,
  filterValues,
  currentApps,
  entityID,
  appsByDataSource,
  onResponse,
  filteredChartsQueries,
  isMetricChart,
  setFilteredChartsQueries,
  reloadDataForExport = false,
  setReloadDataForExport,
}: UseFilteredChartArguments) {
  React.useEffect(() => {
    // Array of chart queries
    const chartsQueries: string[] = filteredCharts.map((chart: any) => {
      const service = getService(chart?.type)
      const generatedQuery =
        chart?.query && generateChartQueryString(chart?.query, filterValues, chart.filterTemplates)
      const query = `${generatedQuery}&${queryString.stringify(
        getQueryAppsStringified(currentApps, generatedQuery, entityID, appsByDataSource.qube)
      )}`
      return `${rootApiUrl}${service.url}?${query}`
    })
    // Checking for differences between new array of queries and old array of queries
    const chartQueriesAreEqual = difference(chartsQueries, filteredChartsQueries)

    // Checking if new filteredCharts queries are equal to existing queries. To avoid unneeded loops
    if (
      !isEmpty(filteredCharts) &&
      isMetricChart &&
      !isEmpty(chartQueriesAreEqual) &&
      filterValues.period
    ) {
      // If data are not equal. Setting new values to state and calling getData() which will fetch new data
      setFilteredChartsQueries(chartsQueries)
    }
  }, [filteredCharts]) // eslint-disable-line react-hooks/exhaustive-deps

  const [exportLoading, setExportLoading] = React.useState(false)
  React.useEffect(() => {
    if (reloadDataForExport && !exportLoading) {
      setExportLoading(true)
      const reloadData = async () => {
        await getDataFilteredCharts({
          filteredCharts,
          filterValues,
          currentApps,
          entityID,
          appsByDataSource,
          onResponse,
        })
        setReloadDataForExport && setReloadDataForExport(false)
        setExportLoading(false)
      }
      reloadData()
    }
  }, [
    reloadDataForExport,
    setReloadDataForExport,
    setExportLoading,
    exportLoading,
    filteredCharts,
    filterValues,
    currentApps,
    entityID,
    appsByDataSource,
    onResponse,
  ])
}
interface UseFilteredDataArguments {
  filteredCharts: ChartObj[]
  filterValues: Record<string, any>
  currentApps?: string[]
  entityID: string | undefined
  appsByDataSource: Record<string, string[]>
  onResponse: Function | undefined
}
interface UseFilteredChartArguments extends UseFilteredDataArguments {
  filteredChartsQueries: any
  isMetricChart: boolean | undefined
  setFilteredChartsQueries: React.Dispatch<any>
  reloadDataForExport?: boolean
  setReloadDataForExport?: React.Dispatch<any>
}

export default useFilteredCharts
