import React from 'react'
import styled from 'styled-components'
import { colors } from 'components/shared/utils/colors'
import notFound from 'assets/notFound.svg'

/* STYLES */
const NotAuthenticatedContainer = styled.div<{ isCompact?: boolean }>`
  width: ${({ isCompact }) => (isCompact ? 'auto' : '100%')};
  height: ${({ isCompact }) => (isCompact ? 'auto' : '100%')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3rem;
`

const NotAuthenticatedImage = styled.img`
  margin-bottom: 3rem;
  height: 50%;
  max-height: 20rem;
`

const Title = styled.h1`
  margin-bottom: 1rem;
  letter-spacing: -0.3px;
`

const NotAuthenticatedText = styled.p`
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: -0.3px;
  color: ${colors.gray30};
  max-width: 40rem;
  margin: 0;

  a {
    color: ${colors.blue10};
  }
`

const NotAuthenticatedImageSmall = styled(NotAuthenticatedImage)`
  margin-bottom: 2rem;
  max-height: 15rem;
`

const TitleSmall = styled.h3`
  margin-bottom: 0.5rem;
  letter-spacing: -0.3px;
`

const NotAuthenticatedTextSmall = styled(NotAuthenticatedText)`
  font-size: 0.875rem;
`

/* PRESENTATION */
function NotAuthenticated({
  title = 'Not Authenticated',
  text = (
    <>
      You failed to authenticate with our servers. If this is a mistake, please contact your
      supervisor.
    </>
  ),
  className,
  isSmall,
  isCompact,
}: {
  title?: string
  text?: React.ReactNode
  className?: string
  isSmall?: boolean
  isCompact?: boolean
}) {
  if (isSmall) {
    return (
      <NotAuthenticatedContainer className={className} isCompact={isCompact}>
        <NotAuthenticatedImageSmall src={notFound} alt="not authorized" />
        <TitleSmall>{title}</TitleSmall>
        <NotAuthenticatedTextSmall>{text}</NotAuthenticatedTextSmall>
      </NotAuthenticatedContainer>
    )
  }
  return (
    <NotAuthenticatedContainer className={className} isCompact={isCompact}>
      <NotAuthenticatedImage src={notFound} alt="not authorized" />
      <Title>{title}</Title>
      <NotAuthenticatedText>{text}</NotAuthenticatedText>
    </NotAuthenticatedContainer>
  )
}

export default NotAuthenticated
