import * as React from 'react'

function SvgDocumentF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M21.27 0H6.73A2.797 2.797 0 004 2.84V4H2.82A2.836 2.836 0 000 6.85V28h17.18a2.814 2.814 0 002.68-2h1.41A2.787 2.787 0 0024 23.15V2.84A2.79 2.79 0 0021.27 0zM12 17H5v-2h7v2zm3-4H5v-2h10v2zm7 10.15a.799.799 0 01-.73.85H20V6.85A2.836 2.836 0 0017.18 4H6V2.84A.795.795 0 016.73 2h14.54a.795.795 0 01.73.84v20.31z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDocumentF
