import * as React from 'react'

function SvgStartOver(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 25" {...props}>
      <path
        d="M8.707 12.293l-1.414 1.414L.586 7 7.293.293l1.414 1.414L4.414 6H17.5a9.5 9.5 0 019.496 9.23l.004.27a9.5 9.5 0 01-9.5 9.5H6v-2h11.5a7.5 7.5 0 000-15H4.414l4.293 4.293z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgStartOver
