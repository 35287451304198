import React from 'react'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { useLocation, WindowLocation } from '@reach/router'
import * as history from 'history'

import * as S from './styles'

function Link({
  to,
  href,
  target,
  onClick,
  underline,
  preserveQueries,
  className,
  style,
  children,
  dataCy,
}: LinkProps): React.FunctionComponentElement<LinkProps> {
  const location = useLocation()
  function formatTo({
    to,
    location,
    preserveQueries,
  }: {
    to?: string | history.Location
    location?: WindowLocation
    preserveQueries?: boolean
  }) {
    if (!preserveQueries) return to

    if (isObject(to)) {
      return {
        ...to,
        search: queryString.stringify({
          ...queryString.parse((location && location.search) || ''),
          ...queryString.parse(to.search || ''),
        }),
      }
    }

    return { pathname: to, search: location && location.search }
  }

  function getLinkType() {
    if (!isEmpty(href)) {
      return 'anchor'
    }

    if (!isEmpty(to)) {
      return 'router'
    }

    return 'span'
  }

  const linkType = getLinkType()

  function LinkComponent(props: any) {
    if (!isEmpty(href)) {
      return <S.AnchorLink {...props} />
    }

    if (!isEmpty(to)) {
      return <S.ReactRouterLink {...props} />
    }

    return <S.SpanLink {...props} />
  }

  if (linkType === 'router') {
    return (
      <LinkComponent
        to={formatTo({ to, location, preserveQueries })}
        href={href}
        target={target}
        onClick={onClick}
        underline={underline}
        className={className}
        style={style}
        data-cy={dataCy}
      >
        {children}
      </LinkComponent>
    )
  }

  return (
    <LinkComponent
      href={href}
      target={target}
      onClick={onClick}
      underline={underline}
      className={className}
      style={style}
      data-cy={dataCy}
    >
      {children}
    </LinkComponent>
  )
}

export interface LinkProps {
  to?: string | history.Location
  href?: string
  target?: string
  onClick?:
    | (((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) & Function)
    | undefined
  underline?: boolean
  preserveQueries?: boolean
  className?: string
  style?: object
  children?: React.ReactNode
  dataCy?: string
}

export default Link
export { S as Styles }
