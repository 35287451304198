import * as React from 'react'

function SvgSports(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M21 3h-2V0H5v3H3C1.5 2.9.1 4 0 5.6v3.9c0 1 .7 1.9 1.6 2.3.4.1.9.2 1.4.2v-2c-.2 0-.4 0-.6-.1-.2-.1-.4-.3-.4-.5V5.6c0-.3.4-.6 1-.6h2v5c0 3.5 2.6 6.4 6 6.9V20H8.5c-1.1-.1-2.1.8-2.2 1.9-.1 0-.1.1-.2.1C5 22 4 22.9 4 24v4h16v-4c0-1.1-1-2-2.2-2h-.2c-.1-1.1-1-2-2.2-2H13v-3.1c3.4-.5 6-3.4 6-6.9V5h2c.6 0 1 .3 1 .6v3.9c0 .2-.4.6-1 .6v2c1.5.1 2.9-1 3-2.6V5.6C23.9 4 22.5 2.9 21 3zm-5.3 19c.1 1.1 1 2 2.2 2h.2v2H6v-1.9c.1 0 .1-.1.2-.1 1.1.1 2.1-.8 2.2-1.9.1 0 .1-.1.2-.1h7.1zM17 10c0 2.8-2.2 5-5 5s-5-2.2-5-5V2h10v8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSports
