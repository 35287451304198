import * as React from 'react'

function SvgCautionCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.733 0 14 6.268 14 14-.008 7.728-6.271 13.991-14 14C6.268 28 0 21.73 0 14 0 6.266 6.27 0 14 0zm0 2C7.373 2 2 7.373 2 14s5.373 12 12 12c6.628 0 12-5.373 12-12-.007-6.625-5.375-11.993-12-12zm0 16a2 2 0 110 4 2 2 0 010-4zm1.5-12v10h-3V6h3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCautionCircle
