import * as React from 'react'

function SvgMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 20" {...props}>
      <path
        d="M25 2H0V0h25a1 1 0 010 2zm0 9H0V9h25a1 1 0 010 2zm0 9H0v-2h25a1 1 0 010 2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMenu
