import axios from 'axios'
// @ts-ignore-next-line
import { axiosPlugin } from '@charter/helix/build/modules/apiCalls'
import analytics from '..'

const { customPlugin: HelixAxios } = axiosPlugin

const defaultPlugin = () => HelixAxios(analytics.track, axios.create())

export { defaultPlugin }
