import * as React from 'react'

function SvgCarF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M28 4h-3.4l-1.1-2.3A3.3 3.3 0 0020.8 0H7.2a3.3 3.3 0 00-2.7 1.7L3.4 4H0v2h2.4L.3 10.2a2.804 2.804 0 00-.3 1.3V24h4a2.9 2.9 0 003-3v-1h14v1a2.9 2.9 0 003 3h4V11.5a2.804 2.804 0 00-.3-1.3L25.6 6H28V4zM9 10h10v2H9v-2zm-6.9 1.1L3 9.2l4.2.8-.4 2-4.7-.9zM5 21a1 1 0 01-1 1H2v-2h3v1zm2-3v-2h14v2H7zm19 4h-2a1 1 0 01-1-1v-1h3v2zm-.1-10.9h.1l-4.8.8-.4-2 4.2-.7.9 1.9z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCarF
