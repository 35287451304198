import styled, { css } from 'styled-components'
import * as U from './utils'
import Flex from 'components/shared/flex'
import { Card } from 'components/shared/Card'

interface StyledAlertProps {
  $variant: string
  $isFixed: boolean
}

export const GlobalAlert = styled(Flex)<StyledAlertProps>`
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ $variant }) => U.getAlertColor($variant)};

  ${({ $isFixed }) =>
    $isFixed &&
    css`
      z-index: 100;
      position: fixed;
      top: 0;
      left: 0;
    `};
`

export const PageAlert = styled(Card)<StyledAlertProps>`
  width: 100%;

  ${({ $isFixed }) =>
    $isFixed &&
    css`
      z-index: 100;
      position: fixed;
      top: 0;
      left: 0;
    `};
`

export const Stripe = styled.div<{ $variant: string }>`
  min-width: 8px;
  min-height: 100%;
  background-color: ${({ $variant }) => U.getAlertColor($variant)};
`
