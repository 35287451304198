import React from 'react'
import SchemaTable from 'components/shared/eventSchemaTable'

import * as S from './styled'

export default function TableChart({
  data,
  columnHeaders = [],
  ...props
}: {
  data: any
  columnHeaders?: string[]
}) {
  return (
    <S.Container>
      <SchemaTable data={data.data} columnHeaders={columnHeaders} sortByColumn={false} {...props} />
    </S.Container>
  )
}
