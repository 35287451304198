import * as React from 'react'

function SvgChevronUpCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14S0 21.732 0 14C.009 6.272 6.272.01 14 0zm0 2C7.373 2 2 7.373 2 14c.007 6.625 5.376 11.993 12 12 6.627 0 12-5.372 12-12 0-6.627-5.373-12-12-12zm0 6.836l7.207 7.207-1.414 1.415L14 11.665l-5.793 5.793-1.414-1.415L14 8.836z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronUpCircle
