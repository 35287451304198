import React, { useEffect, useState } from 'react'
import { Sunburst, LabelSeries } from 'react-vis'
import get from 'lodash/get'

const colorPallete = [
  { color: '#1f77b4', 1: { color: '#00bbe8' } },
  { color: '#ff7f0e', 1: { color: '#ff9e00' } },
  { color: '#2ca02c', 1: { color: '#22cf24' } },
  { color: '#d62728', 1: '#ec4f4a' },
  { color: '#9467bd', 1: '' },
  { color: '#8c564b', 1: '' },
  { color: '#e377c2', 1: '' },
  { color: '#7f7f7f', 1: '' },
  { color: '#bcbd22', 1: '' },
  { color: '#17becf', 1: '' },
]

const LABEL_STYLE = {
  fontSize: '16px',
  textAnchor: 'middle',
}
// @ts-ignore
function getKeyPath(node: any) {
  if (!node.parent) {
    return []
  }

  return [(node.data && node.data.name) || node.name].concat(getKeyPath(node.parent))
}

function addColorToData(data: any, indexForDeep = 0, colorPath = '', isChildren = false) {
  const coloredData =
    data.children &&
    data.children.map((child: any, index: number) => {
      if (child.children) {
        return {
          ...child,
          color: get(colorPallete, colorPath ? colorPath : index).color,
          children: addColorToData(
            child,
            indexForDeep + 1,
            `${colorPath || index}.${indexForDeep + 1}`,
            true
          ),
        }
      } else {
        return { ...child, color: get(colorPallete, colorPath ? colorPath : index).color }
      }
    })

  if (isChildren) {
    return coloredData
  } else {
    return { ...data, children: coloredData }
  }
}

function updateData(data: any, keyPath: any) {
  if (data.children) {
    data.children.map((child: any) => updateData(child, keyPath))
  }
  data.style = {
    ...data.style,
    fillOpacity: keyPath && !keyPath[data.name] ? 0.2 : 1,
  }

  return data
}

const SunburstChart = ({ data, hoveredItemPath, hoveredItem }: SunburstChartProps) => {
  const [finalValue, setFinalValue] = useState<any>()
  const [pathValue, setPathValue] = useState<any>()
  const [stateData, setStateData] = useState(addColorToData(data))

  useEffect(() => {
    setStateData(addColorToData(data))
  }, [data])

  return (
    <>
      <Sunburst
        data={stateData}
        animation
        onValueMouseOver={(node: any) => {
          const path = getKeyPath(node).reverse()
          const pathAsMap = path.reduce((res: any, row: any) => {
            res[row] = true
            return res
          }, {})
          setFinalValue(node)
          setPathValue(path.join(' > '))
          setStateData(updateData(stateData, pathAsMap))
          hoveredItem && hoveredItem(path[path.length - 1])
          hoveredItemPath && hoveredItemPath(path)
        }}
        onValueMouseOut={() => {
          setFinalValue(false)
          setPathValue(false)
          setStateData(updateData(stateData, false))
          hoveredItem && hoveredItem(undefined)
          hoveredItemPath && hoveredItemPath(undefined)
        }}
        hideRootNode
        colorType="literal"
        style={{
          stroke: '#ddd',
          strokeOpacity: 0.3,
          strokeWidth: '0.5',
        }}
        getSize={(d: any) => d.value}
        getColor={(d: any) => d.color}
        height={300}
        width={350}
      >
        {finalValue && (
          <LabelSeries data={[{ x: 0, y: 0, label: finalValue.name, style: LABEL_STYLE }]} />
        )}
        {finalValue && (
          <LabelSeries
            data={[
              {
                x: 0,
                y: -30,
                label: finalValue.value && finalValue.value.toLocaleString('en-US'),
                style: LABEL_STYLE,
              },
            ]}
          />
        )}
      </Sunburst>
      <div>{pathValue}</div>
    </>
  )
}

interface SunburstChartProps {
  data: { name?: string; children: { [key: string]: any }[] }
  hoveredItemPath?: Function
  hoveredItem?: Function
}

export default SunburstChart
