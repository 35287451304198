import React from 'react'
import isEmpty from 'lodash/isEmpty'
import findKey from 'lodash/findKey'
import { useLocation } from '@reach/router'
import useApi from 'hooks/useApi'
import useGroups from 'hooks/useGroups'
import services from 'common/services'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { AppsData, CurrentInfoState } from './types'

const initialState = {
  apps: [],
  appsWithAlias: [],
  appsByDomain: {},
  appsByDataSource: {},
  quantumAppsByClient: {},
  app: {},
}

export const AppsContext = React.createContext<AppsData & CurrentInfoState>(initialState)

function Apps({ children }: { children: React.ReactNode }) {
  const {
    data: {
      applications: apps = [],
      applicationsWithAlias: appsWithAlias = [],
      applicationsByDomain: appsByDomain = {},
      applicationsByDataSource: appsByDataSource = {},
      quantumApplicationsByClient: quantumAppsByClient = {},
    } = {},
  } = useApi({ service: services.applications })

  const journeySurveyDomain = { journey_survey: appsByDomain.journey_survey || [] }
  const employeeExperienceDomain = { employee_experience: appsByDomain.employee_experience || [] }
  const cstDomain = { cst_survey: appsByDomain.cst_survey || [] }

  const appsData = {
    apps: isEmpty(apps) ? apps : [...apps, 'TVE'],
    appsWithAlias: isEmpty(appsWithAlias)
      ? appsWithAlias
      : [...appsWithAlias, { app: 'TVE', alias: [] }],
    appsByDomain: isEmpty(appsByDomain)
      ? appsByDomain
      : {
          internal_apps: appsByDomain.internal_apps || [],
          portals: appsByDomain.portals || [],
          video: appsByDomain.video || [],
          ...journeySurveyDomain,
          ...employeeExperienceDomain,
          ...cstDomain,
          tve: ['TVE'],
        },
    appsByDataSource: isEmpty(appsByDomain) ? appsByDomain : { ...appsByDataSource, tve: ['TVE'] },
    quantumAppsByClient,
  }

  const currentInfo = useGetCurrentInfo(appsData)
  return (
    <AppsContext.Provider value={{ ...appsData, ...currentInfo }}>{children}</AppsContext.Provider>
  )
}

function useGetCurrentInfo({
  apps,
  appsByDomain,
  appsByDataSource,
}: {
  apps: AppsData['apps']
  appsByDomain: AppsData['appsByDomain']
  appsByDataSource: AppsData['appsByDataSource']
}): CurrentInfoState {
  const { currentGroup } = useGroups()
  const location = useLocation()
  const [currentInfoState, setCurrentInfoState] = React.useState<CurrentInfoState>({
    app: {},
  })

  useDeepCompareEffect(() => {
    if (apps.length > 0) {
      const currentRootPath = decodeURIComponent(location.pathname).split('/')[1]
      const currentApps = currentGroup
        ? currentGroup.applicationIDs
        : (apps as string[]).filter(app => app === currentRootPath)
      const currentDomains = currentApps.reduce((accum, app) => {
        const domain = findKey(appsByDomain, domainApps => domainApps.includes(app))
        if (domain && !accum.includes(domain)) {
          return accum.concat(domain)
        }
        return accum
      }, [] as string[])
      const currentDataSources = currentApps.reduce((accum, app) => {
        const dataSource = findKey(appsByDataSource, dataSourceApps => dataSourceApps.includes(app))
        if (dataSource && !accum.includes(dataSource)) {
          return accum.concat(dataSource)
        }
        return accum
      }, [] as string[])

      const entityID = currentGroup ? currentGroup._id : currentApps[0]
      const splitEntityID = entityID?.split('@') || []
      const app = {
        id: entityID,
        name: splitEntityID[0],
        type: splitEntityID[1],
        techType: splitEntityID[2],
      }
      const newCurrentInfoState = {
        currentApps,
        currentDomains,
        currentDataSources,
        entityID,
        app,
      }
      setCurrentInfoState(newCurrentInfoState)
    }
  }, [apps, appsByDomain, appsByDataSource, location.pathname, currentGroup])

  return currentInfoState
}

export default Apps
