import * as React from 'react'

function SvgOutletF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M23 0H3a2.9 2.9 0 00-3 3v23h23a2.9 2.9 0 003-3V3a2.9 2.9 0 00-3-3zM6 5h4v9H6V5zm10 16h-6v-2a2 2 0 012-2h2a2 2 0 012 2v2zm4-7h-4V5h4v9z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgOutletF
