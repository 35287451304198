import * as React from 'react'

function SvgPlayCircleF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.728.008 13.992 6.272 14 14 0 7.732-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm-4 7.2v13.6L20.89 14 10 7.2zm2 3.6l5.11 3.2L12 17.2v-6.4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPlayCircleF
