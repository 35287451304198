import { createGlobalStyle } from 'styled-components'
import { colors } from 'components/shared/utils/colors'
import { createTheme } from '@material-ui/core/styles'

export const GlobalStyle = createGlobalStyle`
  html,body {
    margin: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  div,
  input {
    box-sizing: border-box;
  }

  li {
    letter-spacing: normal;
    padding-left: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  ol {
    margin-bottom: 0.75rem;
    list-style-position: outside;
  }

  ul {
    padding-left: 1.25rem;
    margin-bottom: 1.5rem;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:active {
      outline: none;
    }
  }
`

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Spectrum Sans',
      // 'Brush Script MT',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: { main: colors.blue10 },
    secondary: { main: colors.blue20 },
  },
  overrides: {},
})

export const fontFamily = [
  'Brush Script MT',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
]
