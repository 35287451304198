import * as React from 'react'

function SvgServiceAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 17" {...props}>
      <path
        d="M28.002 2.973A3.005 3.005 0 0024.415.058c-.765.147-1.44.59-1.878 1.233l-2.169 2.89A2.986 2.986 0 0018 3H8.816A2.995 2.995 0 006 .998H3a3 3 0 00-3 3v13h6A2.995 2.995 0 008.816 15H19a3 3 0 002.496-1.335l5.942-8.911a3.135 3.135 0 00.564-1.781zM7 14a1 1 0 01-1 1H2V4a1 1 0 011-1h3a1 1 0 011 1v10zM25.832 3.556l-6 9A1 1 0 0119 13H9V5h9a1 1 0 010 2h-4v2h4a2.978 2.978 0 002.551-1.446c.365-.603.702-1.221 1.125-1.784l2.492-3.323a1 1 0 011.665 1.11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgServiceAlt
