import useVocState from 'hooks/useVocState'
import isEmpty from 'lodash/isEmpty'
import Autocomplete from 'components/shared/Autocomplete'
import { VocFilterOption } from 'screens/entityID/client-analytics/voice-of-customer/State'
import { ManualCategory, CategoryModel } from 'components/voiceOfCustomer/types'
import { getUnarchivedMidLevelCategories } from 'components/voiceOfCustomer/utils'

import * as T from './types'
import * as S from './styled'

function VoCMiddleCategoryFilter({
  filter,
  onChange,
  filterValues,
}: {
  filter: T.Filter
  onChange: (filterValues: T.FilterValues) => void
  filterValues: T.FilterValues
}) {
  const { vocCategory, middleCategory } = filterValues
  const { state } = useVocState()
  const { categoriesData, middleCategoryOptions } = state || {}

  const unarchivedMiddleCategoryOptions = getUnarchivedMidLevelCategories(
    middleCategoryOptions,
    categoriesData
  ) as VocFilterOption[]
  const middleCategories = unarchivedMiddleCategoryOptions.filter(
    (middleCategoryOption: VocFilterOption) =>
      !middleCategoryOption.isArchived &&
      (isEmpty(vocCategory) || vocCategory?.includes(middleCategoryOption?.parent || '')) &&
      !categoriesData?.find(
        (stateCategory: CategoryModel) => stateCategory._id === middleCategoryOption.parent
      )?.isArchived
  )

  const filterManualCategories = (categoriesToSet: string[]) => {
    const manualcats = (filterValues.manualCategory as string[]) || []

    return manualcats.filter((cat: string) => {
      const catObj = state.manualCategories.find((c: ManualCategory) => c._id === cat)
      return categoriesToSet.includes(catObj.category)
    })
  }

  function handleCategoryChange(categoriesToSet: string[], reason: string) {
    if (reason === 'clear') {
      onChange({ middleCategory: [] })
    } else {
      const manCats = filterManualCategories(categoriesToSet)
      onChange({
        manualCategory: manCats,
        middleCategory: categoriesToSet,
      })
    }
  }

  return (
    <S.VocCategoryGroupContainer key={JSON.stringify(middleCategory)}>
      <Autocomplete
        label="Middle-Level Category"
        defaultValue={middleCategory}
        onChange={(value, reason) => handleCategoryChange(value, reason)}
        options={middleCategories}
        fullHeight
        width={S.FILTER_WIDTH}
        showTagTooltip
        data-pho="filterMenuAutoCompleteInput"
      />
    </S.VocCategoryGroupContainer>
  )
}

export default VoCMiddleCategoryFilter
