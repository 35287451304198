import React from 'react'
import { Box } from '@material-ui/core'
import WebexButton from '../WebexButton'
import CopyButton from 'components/shared/CopyButton'
import useAuth from 'hooks/useAuth'
import * as S from './styles'
import Link from '../Link/PrismReactLink'
import Typography from '../typography/Typography'
import Flex from '../flex/Flex'
import { element } from 'components/shared/utils/spacing'

function NeedHelpButton({
  dlEmail = 'DL-DPI-Tools@charter.com',
}: {
  footerLinks?: {}
  dlEmail?: string
  requestAccessEmail?: string
  collapsed?: boolean
}) {
  const auth = useAuth()

  return (
    <Flex direction="row" justifyContent="space-between" margin={`0 0 ${element.md}`}>
      <Box>
        <Typography variant="title6" noMargin>
          Our Team DL
        </Typography>
        <div>
          <Link href={`mailto:${dlEmail}`} variant="standalone">
            {dlEmail}
          </Link>
          <CopyButton textToCopy={dlEmail} />
        </div>
      </Box>
      <S.LiveSupport>
        <WebexButton
          type="joinRoom"
          roomName="DPI Tools Support"
          text="Webex Support Chatroom"
          tooltip="Access DPI Tools Support Webex room"
          markdownMessage={`${auth.user?.name || ''} - Ask your question here`}
        />
      </S.LiveSupport>
    </Flex>
  )
}

export default NeedHelpButton
