import * as React from 'react'

function SvgCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M18.764 0a2.984 2.984 0 012.684 1.659l1.17 2.34H28v20H0V4h5.382l1.17-2.34a2.984 2.984 0 012.684-1.66zM26 6H2v16h24V6zM14 8a6.007 6.007 0 016 6 6 6 0 11-6-6zm0 2a4 4 0 100 8 4 4 0 000-8zm10-2v2h-4V8h4zm-5.236-6H9.236a1.009 1.009 0 00-.895.553L7.62 4h12.763l-.723-1.447A.995.995 0 0018.764 2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCamera
