import styled from 'styled-components'
import { element } from 'components/shared/utils/spacing'

export const LinkTooltip = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: ${element.sm};
`

export const NodeTooltip = styled.div`
  font-weight: 600;
`

export const TooltipChip = styled.span<{ color: string }>`
  width: 12px;
  height: 12px;
  background-color: ${props => props.color};
  display: inline-block;
`
