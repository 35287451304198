import * as React from 'react'

function SvgRecordFAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
      <circle
        cx={16}
        cy={16}
        r={7}
        transform="translate(-9 -9)"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRecordFAlt
