import React from 'react'

// Need to wrap Nivo chart in this kind of container, else it is expanding endlessly - https://github.com/plouc/nivo/issues/109
function NivoContainer({ children, dataPho }: NivoContainerProps) {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }} data-pho={dataPho}>
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>{children}</div>
    </div>
  )
}

interface NivoContainerProps {
  children: React.ReactNode
  dataPho?: string
}

export default NivoContainer
