import * as React from 'react'

function SvgGridF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M12 0v12H0V3a3.009 3.009 0 013-3h9zm14 3v9H14V0h9a3.009 3.009 0 013 3zM0 14h12v12H0V14zm26 0v9a3.009 3.009 0 01-3 3h-9V14h12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgGridF
