import * as React from 'react'

function SvgSendF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <path d="M25 0L15 25l-4-8 6-9-9 6-8-4z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default SvgSendF
