import React, {
  ReactNode,
  useState,
  useEffect,
  ChangeEvent,
  PropsWithoutRef,
  KeyboardEvent,
} from 'react'
import { camelCase } from 'lodash'
import * as S from './styles'
import { useDarkMode } from 'components/App/Providers/DarkMode'
import { colors, Color } from 'components/shared/utils/colors'
import { IconName } from 'components/shared/icon'
import Flex from 'components/shared/flex'
import Typography from 'components/shared/typography'
import { DarkModeProvider } from 'components/App/Providers/DarkMode'

type KiteTextInputValidatorHandler = (
  event: ChangeEvent<HTMLInputElement>,
  value: string,
  label: string
) => boolean
type KiteTextInputHandler = (
  event: ChangeEvent<HTMLInputElement>,
  value?: string,
  label?: string
) => void

export interface TextInputProps extends PropsWithoutRef<JSX.IntrinsicElements['input']> {
  /** The text for the button. (Limited to 28 characters.) */
  children?: string
  /** Basic Input Types such as text, password, number, email, number, time, tel, multiline (textarea) */
  type?: 'text' | 'multiline' | 'password' | 'email' | 'number' | 'date' | 'time' | 'tel'
  /** If you manually set a width, use a layout grid to standardize the button width across your application. (Hint: you can use Prism React's "Grid" component.) */
  width?: string | number
  /** Clear and concise text to describe the input. Appears above the input field */
  label: string
  /** Hide label of the control */
  hideLabel?: boolean
  /** Value of the control */
  value?: string
  /** A valid Kite icon name (ie: CheckmarkCircleF.) */
  icon?: IconName
  /** A React node used to render a custom icon, not found in the Kite library. Overrides the Kite icon if one was provided. */
  customIcon?: ReactNode
  /** Override the default color with a Kite color name or a CSS color. */
  iconColor?: string
  /** Used to prevent interaction and give the user feedback that either the button is preparing to be clickable or was clicked and is processing something. */
  loading?: boolean
  /** The form control itself as well as any preset value should appear dimmed */
  disabled?: boolean
  /** Prevents the user from editing the control whilst displaying the text field data. Common uses include for the user to select and copy, and/or used to be submitted along with other form data. */
  readOnly?: boolean
  /** Visually indicates that it is required and it is marked as invalid until a user has specifically entered the text field, and either entered an invalid string, or left the input without entering information at all */
  required?: boolean
  /** Override the default Required Indicator asterisk (*) */
  requiredIndicator?: string
  /** If required, a validation error message would be shown by default*/
  showValidationError?: boolean
  /** Error Validator. Handler that returns a boolean or a regex string */
  errorValidator?: KiteTextInputValidatorHandler | string
  /** Error message to be displayed when Error Validation fails */
  error?: string
  /** Hint text shown as a placeholder when no text has been input by the user */
  hint?: string
  /** type Password. Hint text shown when type password is selected */
  passwordIsValid?: boolean | Function
  /** type Multiline. Amount of Rows to be displayed */
  rows?: number
  /** type Multiline. Limits the amount of text to be input */
  textLimit?: number
  /** type. Multiline. Shows a counter of your current characters input and the limit set by 'max' */
  showCounter?: boolean
  /** onChange eventHandler ( Input ChangeEvent, InputValue, Label ) */
  onChange?: any
  /** Overrides the default Photon Identifier for Testing purposes. Default is label and type */
  photon?: string
}

export const TextInput = ({
  children,
  type,
  width,
  label,
  value,
  icon,
  customIcon,
  iconColor,
  loading = false,
  disabled,
  readOnly,
  required = false,
  requiredIndicator = '*',
  showValidationError,
  errorValidator,
  error = '',
  hint,
  passwordIsValid,
  rows,
  onChange,
  photon,
  textLimit,
  hideLabel,
  ...props
}: TextInputProps) => {
  const { isDarkMode } = useDarkMode()
  const kiteColor = colors[camelCase(iconColor) as Color] as string | undefined
  const [inputValue, setInputValue] = useState(value)
  const [showPassword, setShowPassword] = useState(false)
  const [displayValidationError, setDisplayValidationError] = useState(showValidationError)

  useEffect(() => {
    setDisplayValidationError(showValidationError)
  }, [showValidationError])

  const truncateString = (value: string, limit: any) =>
    value.length <= limit || !limit ? value : value.slice(0, limit) + '...'

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const enteredValue = e.target.value
    if (enteredValue === undefined || enteredValue === null) {
      setDisplayValidationError(true)
      onChange && onChange(e, enteredValue, label)
      return
    }
    if (errorValidator && typeof errorValidator === 'string') {
      try {
        const isValid = new RegExp(errorValidator).test(enteredValue)
        setDisplayValidationError(isValid)
        if (!isValid) return
      } catch (e) {
        setDisplayValidationError(true)
      }
    }
    setDisplayValidationError(false)
    setInputValue(truncateString(enteredValue, textLimit))
    onChange && onChange(e, enteredValue, label)
  }

  const handleTabError = (e: KeyboardEvent<HTMLInputElement>) => {
    if (required && e.keyCode === 9 && !inputValue) {
      setDisplayValidationError(true)
    }
  }

  const handleEyeClick = () => {
    setShowPassword(!showPassword)
  }

  const isPasswordType = type === 'password'
  const isTextArea = type === 'multiline'

  return (
    <S.StyledInput
      $isDarkMode={isDarkMode}
      $displayValidationError={displayValidationError}
      $rows={rows}
      disabled={disabled}
      onKeyDown={handleTabError}
      $width={width as any}
    >
      <Flex direction="row" justifyContent="space-between">
        <Typography>
          {required && `${requiredIndicator} `}
          {!hideLabel && label}
        </Typography>
        {textLimit && (
          <Typography>
            {inputValue?.length ?? '0'}/{textLimit}
          </Typography>
        )}
      </Flex>
      <S.InputContainer>
        <S.Input
          as={isTextArea ? 'textarea' : 'input'}
          id={`${type}_${label}`}
          {...(!isTextArea ? { type: showPassword ? 'text' : type } : {})}
          value={value}
          onChange={handleChange}
          spellCheck={false}
          autoCorrect="off"
          placeholder={hint}
          disabled={disabled}
          data-pho={photon ?? `${type}_${label}`}
          required={required}
          {...props}
        />
        {isPasswordType && passwordIsValid && <S.ConfirmIcon name={'Checkmark'} />}
        {isPasswordType ? (
          <S.StyledIcon name={showPassword ? 'EyeCrossF' : 'EyeF'} onClick={handleEyeClick} />
        ) : (
          icon && <S.StyledIcon name={icon} color={kiteColor} />
        )}
      </S.InputContainer>
      {error && displayValidationError && <S.StyledErrorMessage>{error}</S.StyledErrorMessage>}
    </S.StyledInput>
  )
}

export const DocBackground = ({ children, isDarkMode = false }: any) => (
  <S.DocBackgroundWrapper $isDarkMode={isDarkMode}>{children}</S.DocBackgroundWrapper>
)

export const Doc = ({ children }: any) => (
  <>
    <DocBackground>{children}</DocBackground>
    <DocBackground isDarkMode>
      <DarkModeProvider startDark>{children}</DarkModeProvider>
    </DocBackground>
  </>
)
