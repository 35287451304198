import * as React from 'react'

function SvgSpace(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 7" {...props}>
      <path
        d="M26 0v5H2V0H0v5a2 2 0 002 2h24a2 2 0 002-2V0h-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSpace
