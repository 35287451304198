import * as React from 'react'

function SvgBilling(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M19 0a3.003 3.003 0 013 3v7h1a3.003 3.003 0 013 3v10a3.003 3.003 0 01-3 3H3a3.003 3.003 0 01-3-3V2.923A2.927 2.927 0 012.923 0zm0 2H2.923A.924.924 0 002 2.923V23a1 1 0 001 1h17.171c-.03-.087-.047-.179-.07-.268-.014-.057-.033-.111-.044-.169A3 3 0 0120 23V3a1 1 0 00-1-1zm4 10h-1v11a1 1 0 002 0V13a1 1 0 00-1-1zm-13 0v2H5v-2h5zm7 0v2h-3v-2h3zm0-4v2H5V8h12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgBilling
