import * as React from 'react'

function SvgKiteF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M13 0L0 13l13 13 13-13L13 0zM9 13l4-4 4 4-4 4-4-4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgKiteF
