import styled from 'styled-components'
import Flex from 'components/shared/flex/Flex'

export const MainHeader = styled(Flex)`
  width: 100%;
`

export const HeadlineContainer = styled(Flex)<{
  removeBottomSpace?: Boolean
  topMargin?: string
  $isDarkMode?: boolean
}>`
  width: 100%;
  margin-top: ${props => (props?.topMargin ? props?.topMargin : 0)};
  margin-bottom: 0.5rem;
  & > * {
    flex: 1 1 0;
    width: 100%;
  }
`

export const ToolbarContainer = styled.div<{
  toolbarStyles?: Record<string, any>
}>`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: ${props => props?.toolbarStyles?.alignItems ?? 'center'};
  justify-content: ${props => props?.toolbarStyles?.justifyContent ?? 'flex-end'};
`

export const BadgeContainer = styled.div`
  display: flex;
  & > div {
    margin-right: 0.5rem;
  }
`
