import * as React from 'react'

function SvgSaveF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M19.41 0L26 6.59V26H0V0h19.41zM4 2H2v22h2V10h18v14h2V7.41L18.59 2H14v5H4V2zm9 12a4 4 0 100 8 4 4 0 000-8zm0 2a2 2 0 110 4 2 2 0 010-4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSaveF
