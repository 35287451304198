import * as React from 'react'

function SvgChevronLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 28" {...props}>
      <path
        d="M14.146 27.707L.439 14 14.146.293l1.415 1.414L3.268 14l12.293 12.293z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronLeft
