import * as React from 'react'

function SvgRecordF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <circle
        cx={16}
        cy={16}
        r={13}
        transform="translate(-3 -3)"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRecordF
