import * as React from 'react'

function SvgLikeF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M25.45 11.27A3.019 3.019 0 0023 10h-5.02a17.929 17.929 0 002-6.67A2.99 2.99 0 0017 0h-3.88v1c0 4.34-2.29 8-5 8H0v17h20.47a3.01 3.01 0 002.9-2.24l2.45-9.75a3.006 3.006 0 00-.37-2.74zm-1.54 2.16l-2.48 9.83a.994.994 0 01-.96.74H8V11h.12c3.62 0 6.61-3.95 6.97-9H17a.98.98 0 01.75.34.915.915 0 01.24.75 14.39 14.39 0 01-2.65 7.29L14.05 12H23a.987.987 0 01.82.42c.198.3.232.68.09 1.01z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLikeF
