import styled, { css } from 'styled-components'
import { element } from 'components/shared/utils/spacing'
import { colors } from 'components/shared/utils/colors'
import Icon from 'components/shared/icon/Icon'
import { lightMode } from 'components/shared/utils/elevation'

interface InputContainerProps {
  $isInline: boolean
  $hasPlaceholderOrSelection: boolean
  $isDisabled: boolean
}

interface EntryFieldProps {
  $isOpen: boolean
  $isDisabled: boolean
  $isInline: boolean
  $isDarkMode: boolean
  $minWidth: string
}

interface StyledIconProps {
  $isSelected: boolean
}

interface DropdownProps {
  $isOpen: boolean
  $position?: string
}

interface DropdownItemProps {
  $isSelected: boolean
  $isDarkMode: boolean
  $width?: number
}

export const DropdownContainer = styled.div<{ $position: string }>`
  display: block;
  width: fit-content;
  position: ${({ $position }) => $position};
`

export const InputContainer = styled.div<InputContainerProps>`
  ${({ $isInline }) =>
    $isInline &&
    css`
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    `}

  & > div:first-child {
    cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
    ${({ $isInline, $hasPlaceholderOrSelection }) =>
      $isInline &&
      $hasPlaceholderOrSelection &&
      css`
        font-weight: 700;
      `}
  }
`

export const EntryField = styled.div<EntryFieldProps>`
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: ${({ $isInline }) => ($isInline ? 'fit-content' : '44px')};
  ${({ $isInline, $minWidth }) => {
    if (!$minWidth) {
      return css`
        width: ${$isInline ? 'fit-content' : '288px'};
      `
    }
    return
  }}
  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth};
    `}
  background: ${({ $isDarkMode, $isInline }) => getBackgroundColor($isDarkMode, $isInline)};
  border: ${({ $isOpen, $isDisabled, $isDarkMode }) =>
    `${$isOpen ? 2 : 1}px solid ${getBorderColor($isDisabled, $isDarkMode, $isOpen)}`};
  ${({ $isInline }) =>
    $isInline &&
    css`
      border: none;
    `}
  padding: 0 ${element.xs};
  && > span {
    ${({ $isDarkMode, $isDisabled }) =>
      $isDisabled &&
      css`
        color: ${$isDarkMode ? colors.gray30 : colors.gray25};
      `}
  }
`

export const StyledIcon = styled(Icon)<StyledIconProps>`
  opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0)};
  padding-right: ${element.xs};
`

export const Dropdown = styled.div<DropdownProps>`
  position: ${({ $position }) => ($position ? $position : 'unset')};
  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      display: none;
    `}
  border-radius: 4px;
  border: 1px solid ${colors.gray20};
  cursor: pointer;
  z-index: 100;
  max-height: 500px;
  overflow-y: auto;
  && {
    ${lightMode[2]}
  }
`

export const DropdownItem = styled.div<DropdownItemProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  padding: ${element.sm} ${element.xs};
  background-color: ${({ $isDarkMode }) => ($isDarkMode ? colors.darkBlue20 : colors.white)};
  ${({ $width }) =>
    $width &&
    css`
      width: calc(${$width}px - 1px);
    `}

  & > * {
    ${({ $isSelected }) =>
      $isSelected &&
      css`
        font-weight: 700;
      `}
  }

  &&:not(:last-child) {
    border-bottom: 1px solid ${colors.gray20};
  }

  &&:hover {
    /* Cannot use chroma.js here as transparency causes elements underneath dropdown to show through */
    background-color: ${({ $isDarkMode }) => ($isDarkMode ? '#212a34' : '#f9f9f9')};
  }
`

const getBackgroundColor = ($isDarkMode: boolean, $isInline: boolean) => {
  if ($isInline) return 'transparent'
  return $isDarkMode ? colors.darkBlue20 : colors.white
}

const getBorderColor = ($isDisabled: boolean, $isDarkMode: boolean, $isOpen: boolean) => {
  if ($isDarkMode) {
    return $isOpen ? colors.white : colors.gray30
  }

  return $isDisabled ? colors.gray25 : colors.gray30
}
