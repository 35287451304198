import * as React from 'react'

function SvgRadioF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M12 0c6.627 0 12 5.373 12 12-.007 6.624-5.376 11.993-12 12-6.627 0-12-5.373-12-12S5.373 0 12 0zm0 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c-.006-5.52-4.48-9.994-10-10zm0 3a7 7 0 110 14 7 7 0 010-14z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRadioF
