import * as React from 'react'

function SvgLike(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M25.45 11.271A3.004 3.004 0 0023 10h-5.017a18.103 18.103 0 001.997-6.67c.1-.842-.166-1.685-.728-2.318A3.013 3.013 0 0017 0h-3.88v1c0 4.337-2.29 8-5 8H0v17h20.467a3.01 3.01 0 002.9-2.236l2.457-9.755a3.004 3.004 0 00-.373-2.738zM2 24V11h4v13H2zm21.912-10.571l-2.48 9.83c-.118.437-.513.74-.965.741H8V11h.12c3.624 0 6.613-3.954 6.965-9H17c.289 0 .563.124.755.34a.948.948 0 01.238.75 14.345 14.345 0 01-2.657 7.289L14.05 12h8.949a.988.988 0 01.817.424c.2.297.236.675.096 1.005z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLike
