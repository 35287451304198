import * as React from 'react'

function SvgOtaCross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M1.707.293l26 26-1.414 1.414-9.535-9.534c-.53.349-1.126.597-1.758.727V25h1a2.015 2.015 0 012 2h-8a2.006 2.006 0 012-2h1v-6.1A5.017 5.017 0 019 14a4.963 4.963 0 01.829-2.755l-4.278-4.28A10.976 10.976 0 003 14a10.897 10.897 0 003.23 7.78l-1.42 1.41a12.965 12.965 0 01-.672-17.637L.293 1.707 1.707.293zm18.554 2.312A12.996 12.996 0 0127.001 14c.01 2.47-.695 4.892-2.031 6.97l-1.45-1.45A10.994 10.994 0 008.49 4.49L7.03 3.03a12.996 12.996 0 0113.231-.425zm-8.969 10.101a3.002 3.002 0 004.002 4.002z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgOtaCross
