import * as React from 'react'

function SvgUpload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 25" {...props}>
      <path
        d="M4 3v2H3a1 1 0 00-1 1v16a1 1 0 001 1h22a1 1 0 001-1V6a1 1 0 00-1-1h-1V3h1a3.003 3.003 0 013 3v16a3.003 3.003 0 01-3 3H3a3.003 3.003 0 01-3-3V6a3.003 3.003 0 013-3h1zM14 .671l8.658 7.576-1.316 1.506L15 4.204V20h-2V4.204L6.658 9.753 5.342 8.247 14 .671z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgUpload
