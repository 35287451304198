import * as React from 'react'

function SvgList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 20" {...props}>
      <path
        d="M24 1a.96.96 0 01-.91 1H4V0h19.09A.96.96 0 0124 1zm0 9a.96.96 0 01-.91 1H4V9h19.09a.96.96 0 01.91 1zm0 9a.96.96 0 01-.91 1H4v-2h19.09a.96.96 0 01.91 1zM0 18h2v2H0v-2zm0-9h2v2H0V9zm0-9h2v2H0V0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgList
