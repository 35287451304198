import React from 'react'
import { DarkModeProvider } from '../DarkMode'
import { ThemeProvider } from '../Theme'
import { ViewportSizeProvider } from '../ViewportSize'

export const AllProviders: React.FC = ({ children }) => (
  <DarkModeProvider>
    <ThemeProvider>
      <ViewportSizeProvider>{children}</ViewportSizeProvider>
    </ThemeProvider>
  </DarkModeProvider>
)
