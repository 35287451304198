import * as React from 'react'

function SvgInternet(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0C6.27 0 0 6.268 0 14 0 21.73 6.27 28 14 28c7.732 0 14-6.269 14-14C27.992 6.27 21.73.008 14 0zm10.895 9H21.31a15.363 15.363 0 00-3.523-6.38A12.046 12.046 0 0124.896 9zM20 14a20.02 20.02 0 01-.238 3H8.238A20.022 20.022 0 018 14c0-1.007.095-2.011.281-3H19.72c.186.989.28 1.993.28 3zm-6 11.84A10.941 10.941 0 018.67 19h10.663A10.941 10.941 0 0114 25.84zM8.797 9A12.155 12.155 0 0114 2.144 12.156 12.156 0 0119.204 9H8.797zm1.418-6.38A15.365 15.365 0 006.692 9H3.106a12.047 12.047 0 017.11-6.38zM2.395 11h3.853A18.672 18.672 0 006 14a22.374 22.374 0 00.196 3h-3.8a11.596 11.596 0 010-6zm.71 8h3.48a13.58 13.58 0 003.402 6.295A12.045 12.045 0 013.106 19zm14.91 6.295a13.582 13.582 0 003.4-6.295h3.48a12.046 12.046 0 01-6.88 6.295zM25.604 17h-3.8c.132-.995.198-1.997.195-3a18.687 18.687 0 00-.247-3h3.853a11.593 11.593 0 010 6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgInternet
