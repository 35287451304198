import React from 'react'
import moment from 'moment'
import Flex from 'components/shared/flex/Flex'
import Typography from 'components/shared/typography/Typography'
import { TextInput } from 'components/shared/TextInput'
import * as S from '../styled'

function getDateValue(absoluteDate?: string) {
  return absoluteDate
    ? moment(parseInt(absoluteDate || '') || absoluteDate).format('YYYY-MM-DD')
    : absoluteDate
}

function DateInputs({
  defaultAbsoluteSelectedRange,
  handleAbsoluteTimeChange,
  setInputRange,
  inputRange,
}: {
  defaultAbsoluteSelectedRange?: { from: string; to: string }
  handleAbsoluteTimeChange: (newPeriod: { from: string | Date; to: string | Date }) => void
  setInputRange: React.Dispatch<React.SetStateAction<{ from?: Date; to?: Date } | undefined>>
  inputRange?: { from?: Date; to?: Date }
}) {
  const startDateValue = getDateValue(defaultAbsoluteSelectedRange?.from)
  const endDateValue = getDateValue(defaultAbsoluteSelectedRange?.to)
  return (
    <S.DateInputContainer padding="sm" marginBetween="sm">
      <div>
        <Typography>Start Date</Typography>
        <Flex direction="row" marginBetween="sm">
          <TextInput
            label="Start Date"
            hideLabel
            type="date"
            error="error message"
            width="11rem"
            value={startDateValue}
            onChange={(event: any) => {
              handleAbsoluteTimeChange({
                from: new Date(event.target.value),
                to: new Date(
                  parseInt(defaultAbsoluteSelectedRange?.to || '') ||
                    defaultAbsoluteSelectedRange?.to ||
                    ''
                ),
              })
              setInputRange({
                ...inputRange,
                from: new Date(event.target.value),
              })
            }}
          />
        </Flex>
      </div>
      <div>
        <Typography>End Date</Typography>
        <Flex direction="row" marginBetween="sm">
          <TextInput
            label="End Date"
            hideLabel
            type="date"
            error="error message"
            width="11rem"
            value={endDateValue}
            onChange={(event: any) => {
              handleAbsoluteTimeChange({
                from: new Date(
                  parseInt(defaultAbsoluteSelectedRange?.from || '') ||
                    defaultAbsoluteSelectedRange?.from ||
                    ''
                ),
                to: new Date(event.target.value),
              })
              setInputRange({
                ...inputRange,
                to: new Date(event.target.value),
              })
            }}
          />
        </Flex>
      </div>
    </S.DateInputContainer>
  )
}

export default DateInputs
