import React, { ReactNode } from 'react'
import { camelCase } from 'lodash'
import { useDarkMode } from 'components/App/Providers/DarkMode'
import Icon from 'components/shared/icon/Icon'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import { IconName } from 'components/shared/icon'
import { numberToPixels } from 'components/shared/utils/number-to-pixels'
import { colors, Color } from 'components/shared/utils/colors'
import * as S from './styles'
import * as U from './utils'

export interface ButtonProps extends React.PropsWithoutRef<JSX.IntrinsicElements['button']> {
  /** The text for the button. (Limited to 28 characters.) */
  children?: string
  /** The different button types. */
  variant?: 'primary' | 'secondary' | 'borderless' | 'neutral'
  /** If you manually set a width, use a layout grid to standardize the button width across your application. (Hint: you can use Prism React's "Grid" component.) */
  width?: string | number
  /** Used to prevent interaction and give the user feedback that either the button is preparing to be clickable or was clicked and is processing something. */
  loading?: boolean
  /** Override the default background and text color with your own snazzy one! Or type out the name of a kite color (ie: dark-blue-30). */
  color?: string
  /** A valid Kite icon name (ie: CheckmarkCircleF.) */
  icon?: IconName
  /** Override the default icon color with a Kite color name or a CSS color. */
  iconColor?: string
  /** A named size which corresponds to Kite's iconography scale. Only applies if an icon is used. */
  iconSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  /** A React node used to render a custom icon, not found in the Kite library. Overrides the Kite icon if one was provided. */
  customIcon?: ReactNode
  /** A ref for attaching things like Dialogs and Menus to Buttons. */
  ref?: React.MutableRefObject<any>
  'data-testid'?: string
  'data-test'?: string
}

export const Button = ({
  children,
  variant = 'primary',
  width = 'auto',
  loading = false,
  color,
  icon,
  iconColor,
  iconSize,
  customIcon,
  disabled,
  ...props
}: ButtonProps) => {
  const { isDarkMode } = useDarkMode()
  const ButtonVariant: any = U.getButtonVariant({
    variant,
    disabled,
    isDarkMode,
  })
  const pixelWidth = numberToPixels(width)
  const fullText: any = children
  const hasText = !!fullText
  const truncatedText = fullText?.slice(0, 28)
  const isTruncated = fullText?.length > truncatedText?.length
  const text = `${truncatedText || ''}${truncatedText && isTruncated ? '...' : ''}`
  const kiteIcon = icon ? (
    <Icon name={icon} size={iconSize} margin={hasText ? { right: 'md' } : undefined} />
  ) : null
  const iconContent = customIcon ? (
    <S.CustomIcon hasText={hasText}>{customIcon}</S.CustomIcon>
  ) : (
    kiteIcon
  )
  const kiteColor = colors[camelCase(color) as Color] as string | undefined
  const kiteIconColor = colors[camelCase(iconColor) as Color] as string | undefined

  return (
    <ButtonVariant
      $width={pixelWidth}
      $isDarkMode={isDarkMode}
      $hasText={hasText}
      $color={kiteColor || color}
      $iconColor={kiteIconColor || iconColor}
      disabled={loading || disabled}
      {...props}
      data-testid={(props['data-testid'] as string) || 'button'}
      data-test={props['data-test'] || 'button'}
    >
      <S.ButtonContent tabIndex={-1} data-testid="button-content">
        {!loading && iconContent}
        {!loading && text}
        {loading && <LoadingSpinner small />}
      </S.ButtonContent>
    </ButtonVariant>
  )
}
