import React from 'react'
import CustomNode from './CustomNode'
import { NetworkChartNode } from './utils'

export type LabelPosition = 'left' | 'center' | 'right' | 'top' | 'bottom'

const customLabel = (node: NetworkChartNode) => node.id
const customWidth = () => window.innerWidth / 3

export const config = {
  collapsible: false,
  directed: true,
  highlightDegree: 1,
  highlightOpacity: 0.6,
  linkHighlightBehavior: true,
  nodeHighlightBehavior: false,
  maxZoom: 1,
  minZoom: 1,
  panAndZoom: false,
  staticGraph: false,
  staticGraphWithDragAndDrop: true,
  width: customWidth(),
  height: 325,
  d3: {
    alphaTarget: 0.05,
    gravity: -100,
    linkLength: 65,
    linkStrength: 1,
    disableLinkForce: true,
  },
  node: {
    fontColor: 'black',
    fontSize: 12,
    fontWeight: 'normal',
    highlightFontSize: 12,
    highlightFontWeight: 'bold',
    labelProperty: customLabel,
    labelPosition: 'bottom',
    mouseCursor: 'pointer',
    opacity: 1,
    renderLabel: true,
    size: 500,
    viewGenerator: (node: NetworkChartNode) => <CustomNode node={node} />,
  },
  link: {
    color: '#d3d3d3',
    opacity: 1,
    semanticStrokeWidth: false,
    strokeWidth: 4,
    highlightColor: '#008cff',
  },
}
