import { useState, useEffect } from 'react'
import useAuth from 'hooks/useAuth'

enum ROLES {
  PRISM_ADMIN = 'prism-admin',
  MSE_USER = 'mse-user',
}

const checkCSTReadAccess = (roles?: string[]): boolean =>
  roles?.includes(ROLES.PRISM_ADMIN) || roles?.includes(ROLES.MSE_USER) || false

const useCSTAccess = () => {
  const auth = useAuth()
  const roles = auth.user?.roles

  const [hasCSTReadAccess, setHasCSTReadAccess] = useState(checkCSTReadAccess(roles))

  useEffect(() => {
    if (roles) {
      setHasCSTReadAccess(checkCSTReadAccess(roles))
    }
  }, [roles])

  return {
    hasCSTReadAccess,
  }
}

export default useCSTAccess
