import styled from 'styled-components'

export const Container = styled.div<{ tooltipYOffset?: number }>`
  width: 100%;
  height: 100%;
  border: red;
  font-family: 'Open Sans';
  > div > div > div {
    top: ${props => `${props.tooltipYOffset}px !important`};
    transition: top 0.5s;
  }
`

export const Node = styled.div<{ fill?: string }>`
  width: 40px;
  height: 30px;
  line-height: 30px;
  font-size: 0.65rem;
  font-weight: 600;
  background-color: ${props => props.fill};
  color: #2f313a;
  font-family: 'Open Sans';
  text-align: center;
`
