export function getColor(rate: number) {
  // colors from Material UI
  if (0 <= rate && rate < 10) return '#f44336'
  if (10 <= rate && rate < 50) return '#ef5350'
  if (50 <= rate && rate < 80) return '#ff9800'
  if (80 <= rate && rate < 90) return '#ffeb3b'
  if (90 <= rate && rate <= 99) return '#4caf50'
  return '#388e3c'
}

export type NetworkChartNode = {
  id: string
  successRate: number | string
}

const utils = {
  getColor,
}

export default utils
