import * as React from 'react'

function SvgHomeF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 27" {...props}>
      <path
        d="M26 10.5V24a3.009 3.009 0 01-3 3h-7v-7a3 3 0 00-6 0v7H3a3.009 3.009 0 01-3-3V10.5L13 .75l13 9.75z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHomeF
