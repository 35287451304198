import React from 'react'
import { Link } from '@reach/router'
import Entity from 'components/shared/Entity'

export default function ToolList({
  handleClose,
}: {
  handleClose: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}) {
  const dataSources = [
    { name: 'Overview', id: 'overview' },
    { name: 'Quantum', id: 'quantum' },
    { name: 'Qube', id: 'qube' },
    { name: 'Airlytics', id: 'airlytics' },
    { name: 'Glue', id: 'glue-tables' },
  ]

  const dataSourcesRows = dataSources.map(
    ({
      name,
      description,
      id,
      isBeta,
    }: {
      name: string
      description?: string
      id: string
      isBeta?: boolean
    }) => {
      return (
        <Link onClick={handleClose} to={`/data-sources/${id}`}>
          <Entity title={name} description={description} isBeta={isBeta} />
        </Link>
      )
    }
  )

  return <div>{dataSourcesRows}</div>
}
