import React, { ReactNode } from 'react'
import ReactDOM from 'react-dom'
import { kebabCase, uniqueId } from 'lodash'
import { Button, ButtonProps } from 'components/shared/Button'
import { IconButton } from 'components/shared/IconButton'
import Flex from 'components/shared/flex/Flex'
import Typography from 'components/shared/typography/Typography'
import * as S from './styles'
import { useDarkMode } from 'components/App/Providers/DarkMode'
import Icon, { IconName } from 'components/shared/icon/Icon'
import { Color } from 'components/shared/utils/colors'
import { usePortal } from 'hooks/usePortal'
import { v4 as uuid } from 'uuid'

const defaultDialogRoot = document.body

export interface DialogProps {
  children?: ({
    isOpen,
    toggleOpen,
  }: {
    isOpen: boolean
    toggleOpen: () => void
  }) => React.ReactNode | ReactNode
  onCancel?: () => void
  onAccept?: () => void
  cancelLabel?: string
  acceptLabel?: string
  hideCancel?: boolean
  hideAccept?: boolean
  /** If manually setting a width, it's advised to snap it to your 4 or 12 column grid using the "Grid" component. */
  width?: string
  /** Take over control of whether the Dialog is open or not. */
  isOpen?: boolean
  title?: string
  /** The kite icon name to use. */
  icon?: IconName
  /** Use to color the icon. Can be a kite color name or a CSS color string. Only utilized if an icon is provided. */
  iconColor?: Color | string
  /** The DOM node to use as a root for the dialog component. */
  dialogRoot?: HTMLElement
  onClose?: () => void
  body?: React.ReactNode | ((toggleOpen: (isOpenOverride?: boolean) => void) => React.ReactNode)
  bottomLeftButtons?:
    | ButtonProps[]
    | ((toggleOpen: (isOpenOverride?: boolean) => void) => ButtonProps[])
  bottomRightButtons?:
    | ButtonProps[]
    | ((toggleOpen: (isOpenOverride?: boolean) => void) => ButtonProps[])
  type?: string
  rootRef?: React.MutableRefObject<HTMLElement>
  maxHeight?: string | number
  minWidth?: string | number
}

export const Dialog = ({
  children,
  onCancel,
  onAccept,
  cancelLabel = 'Cancel',
  acceptLabel = 'Accept',
  hideCancel = false,
  hideAccept = false,
  width = '600px',
  type = 'default',
  isOpen: isControlledOpen,
  title,
  rootRef,
  onClose,
  icon,
  iconColor,
  body,
  dialogRoot = defaultDialogRoot,
  bottomLeftButtons,
  maxHeight,
  minWidth,
  bottomRightButtons,
  ...props
}: DialogProps) => {
  const [isOpen, setIsOpen] = React.useState(true)
  const isTrulyOpen = isControlledOpen ?? isOpen
  const { isDarkMode } = useDarkMode()
  const id = title ? uniqueId(`${kebabCase(title)}-`) : uniqueId('confirmation-')

  const [cardID] = React.useState(uuid())
  const [isRestrictedToClose, setIsRestrictedToClose] = React.useState(false)

  const isBodyARenderProp = typeof body === 'function'

  const isBottomLeftButtonsARenderProp = typeof bottomLeftButtons === 'function'
  const isBottomRightButtonsARenderProp = typeof bottomRightButtons === 'function'

  function getIsOutside(event: React.MouseEvent<HTMLElement>) {
    const card = document.getElementById(cardID)
    if (card && card.contains(event.target as Node)) {
      return false
    }
    return true
  }

  const {
    isOpen: isOpenPortal,
    toggleOpen: portaltoggleOpen,
    portal,
  } = usePortal({
    isOpen: isControlledOpen,
    rootRef,
    content: toggleOpen => {
      function handleToggle(isOpenOverride?: boolean) {
        const isAnOpen = isOpenOverride ?? !isOpenPortal
        if (onClose && !isAnOpen) {
          onClose()
        }
        toggleOpen(isOpenOverride)
      }

      function handleOutsideClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation()
        if (isRestrictedToClose) {
          setIsRestrictedToClose(false)
        } else {
          getIsOutside(e) && handleToggle(false)
        }
      }

      return (
        <Flex
          onClick={handleOutsideClick}
          tabIndex={-1}
          width="100vw"
          height="100vh"
          position="fixed"
          zIndex={10001}
          backgroundColor={isDarkMode ? 'rgba(0,16,25,0.9)' : 'rgba(0,33,51,0.8)'}
          top={0}
          center
        >
          <S.DialogCardDs
            id={cardID}
            aria-label={title}
            role="dialog"
            onClick={e => e.stopPropagation()}
            onMouseDown={() => setIsRestrictedToClose(true)}
            onMouseUp={() => setIsRestrictedToClose(false)}
            maxHeight={maxHeight}
            minWidth={minWidth}
          >
            <Flex height="100%" column justifyContent="space-between">
              <Flex column height="100%">
                <Flex position="absolute" top="16px" right="16px">
                  <IconButton
                    icon="X"
                    size="sm"
                    color={isDarkMode ? 'white' : 'black'}
                    onClick={() => handleToggle()}
                  />
                </Flex>
                {(title || icon) && (
                  <Flex alignItems="center" margin="0 0 16px 0" marginBetween="sm">
                    {icon && <Icon name={icon} size="lg" color={iconColor} />}
                    <Typography noMargin variant="title4">
                      {title}
                    </Typography>
                  </Flex>
                )}
                {isBodyARenderProp
                  ? (body as (toggleOpen: () => void) => React.ReactNode)(handleToggle)
                  : body}
              </Flex>
              {(bottomLeftButtons || bottomRightButtons) && (
                <Flex margin="24px 0 0 0" justifyContent="space-between">
                  {bottomLeftButtons && (
                    <Flex>
                      {(isBottomLeftButtonsARenderProp
                        ? bottomLeftButtons(handleToggle)
                        : bottomLeftButtons
                      ).map(button => (
                        <Button {...button} />
                      ))}
                    </Flex>
                  )}
                  {bottomRightButtons && (
                    <Flex justifyContent="flex-end">
                      {(isBottomRightButtonsARenderProp
                        ? bottomRightButtons(handleToggle)
                        : bottomRightButtons
                      ).map(button => (
                        <Button {...button} />
                      ))}
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>
          </S.DialogCardDs>
        </Flex>
      )
    },
  })

  const closeDialog = React.useCallback(() => {
    onCancel && onCancel()
    setIsOpen(false)
  }, [setIsOpen, onCancel])

  const acceptAndClose = React.useCallback(() => {
    onAccept && onAccept()
    setIsOpen(false)
  }, [onAccept, setIsOpen])

  const closeDialogByID = React.useCallback(
    (e: MouseEvent) => {
      if ((e.target as Element).id) {
        closeDialog()
      }
    },
    [closeDialog]
  )
  React.useEffect(() => {
    window.addEventListener('click', closeDialogByID)
    return () => window.removeEventListener('click', closeDialogByID)
  }, [])

  function handleToggle(isOpenOverride?: boolean) {
    const isAnOpen = isOpenOverride ?? !isOpen
    if (onClose && !isAnOpen) {
      onClose()
    }
    portaltoggleOpen(isOpenOverride)
  }

  if (type == 'default') {
    return isTrulyOpen
      ? ReactDOM.createPortal(
          <Flex
            id={id}
            tabIndex={-1}
            width="100vw"
            height="100vh"
            position="fixed"
            zIndex={10001}
            backgroundColor={isDarkMode ? 'rgba(0,16,25,0.9)' : 'rgba(0,33,51,0.8)'}
            top={0}
            center
            {...props}
          >
            <S.DialogCard
              id={`${id}-card`}
              tabIndex={-1}
              aria-labelledby={title && `${id}-title`}
              aria-label={title ? undefined : 'Confirmation'}
              role="dialog"
              width={width}
              elevation={3}
              disablePadding
              disableBorder
              disableShadow
            >
              <Typography role="document">
                <S.ButtonContainer>
                  <IconButton
                    icon="Close"
                    color={isDarkMode ? 'white' : 'black'}
                    onClick={closeDialog}
                    data-test="closeDialogButton"
                  />
                </S.ButtonContainer>
                {title && (
                  <Flex>
                    {icon && (
                      <Icon
                        name={icon}
                        size="lg"
                        color={iconColor}
                        margin={{ top: '3px', right: 'xs' }}
                      />
                    )}
                    <h4 id={`${id}-title`} style={{ marginBottom: '16px' }}>
                      {title}
                    </h4>
                  </Flex>
                )}
                <p style={{ marginBottom: '24px' }}>
                  <strong>{children}</strong>
                </p>
                {(!hideCancel || !hideAccept) && (
                  <Flex marginBetween="xl">
                    {!hideCancel && (
                      <Button
                        width="100%"
                        variant="secondary"
                        onClick={closeDialog}
                        data-pho="cancelButton"
                      >
                        {cancelLabel}
                      </Button>
                    )}
                    {!hideAccept && (
                      <Button width="100%" onClick={acceptAndClose} data-pho="acceptButton">
                        {acceptLabel}
                      </Button>
                    )}
                  </Flex>
                )}
              </Typography>
            </S.DialogCard>
          </Flex>,
          dialogRoot
        )
      : null
  } else {
    return (
      <>
        {children && children({ isOpen, toggleOpen: handleToggle })}
        {portal}
      </>
    )
  }
}
