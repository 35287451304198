import * as React from 'react'

function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 27" {...props}>
      <path
        d="M26.707 25.293l-5.906-5.906c3.967-4.724 3.51-11.735-1.038-15.903C15.215-.684 8.19-.53 3.83 3.831-.532 8.193-.685 15.217 3.484 19.764c4.169 4.547 11.18 5.005 15.903 1.037l5.906 5.906 1.414-1.414zM12 21.5a9.5 9.5 0 119.5-9.5 9.51 9.51 0 01-9.5 9.5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSearch
