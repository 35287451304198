import React from 'react'
import includes from 'lodash/includes'
import compact from 'lodash/compact'
import { Button } from 'components/shared/Button'
import Tooltip from 'components/shared/Tooltip'
import usePrismUser from 'hooks/usePrismUser'
import MenuList from '@material-ui/core/MenuList'
import Entity from 'components/shared/Entity'
import useApps from 'hooks/useApps'
import useGroups from 'hooks/useGroups'
import Flex from 'components/shared/flex/Flex'
import SmallerHeading from '../SmallerHeading'
import { filterOutDatedFavorites } from 'components/shared/FavoritesModal/utils'

const CurrentlyOnAppOrGroupOrGroup = ({
  currentGroupFavorites,
  currentAppFavorites,
}: {
  currentGroupFavorites: string[]
  currentAppFavorites: string[]
}) => {
  const { entityID, app, apps } = useApps()
  const { currentGroup } = useGroups()
  const prismUser = usePrismUser()

  const handleAddCurrentApplication = async () => {
    const currentFavorites = filterOutDatedFavorites(prismUser.favorites, apps)

    if (!includes(currentFavorites, entityID)) {
      const newAppFavorites = entityID ? currentFavorites.concat(entityID) : currentFavorites
      await prismUser.update({ favorites: compact(newAppFavorites) })
    }
  }

  const handleAddCurrentGroup = async () => {
    const currentGroupFavorites = prismUser.groupFavorites || []

    if (!includes(currentGroupFavorites, entityID)) {
      const newGroupFavorites = entityID
        ? currentGroupFavorites.concat(entityID)
        : currentGroupFavorites

      await prismUser.update({ groupFavorites: compact(newGroupFavorites) })
    }
  }

  const AddGroupButton = () =>
    currentGroupFavorites.length < 5 ? (
      <Button
        variant="borderless"
        icon="PlusCircleF"
        width="100%"
        onClick={handleAddCurrentGroup}
        data-test="addGroupCurrentSectionButton"
      >
        Add Current Group
      </Button>
    ) : (
      <Tooltip title="5 Group Favorite limit reached. Click edit icon next to the Groups menu to remove some.">
        <Button
          variant="borderless"
          icon="PlusCircleF"
          width="100%"
          disabled
          data-test="addGroupCurrentSectionButton"
        >
          Add Current Group
        </Button>
      </Tooltip>
    )

  const AddAppButton = () =>
    currentAppFavorites.length < 5 ? (
      <Button
        variant="borderless"
        icon="PlusCircleF"
        width="100%"
        onClick={handleAddCurrentApplication}
        data-test="addAppCurrentSectionButton"
      >
        Add Current App
      </Button>
    ) : (
      <Tooltip title="5 App Favorite limit reached. Click edit icon next to the Apps menu to remove some.">
        <Button
          variant="borderless"
          icon="PlusCircleF"
          width="100%"
          disabled
          data-test="addAppCurrentSectionButton"
        >
          Add Current App
        </Button>
      </Tooltip>
    )

  if (currentGroup) {
    return (
      <Flex direction="column">
        <SmallerHeading title="on Group" indent={true} dataTest="onGroupTitle" />
        <MenuList>
          <Entity
            title={currentGroup.name}
            description={currentGroup.description}
            data-pho={`${currentGroup.name}-onApp`}
          />
        </MenuList>
        <AddGroupButton />
      </Flex>
    )
  } else {
    return (
      <Flex direction="column">
        <SmallerHeading title="on App" indent={true} dataTest="onAppTitle" />
        <MenuList>
          <Tooltip dataTest="onAppTooltip" title={app.name || ''} placement="top">
            <Entity
              title={app.name || ''}
              description={app.type || ''}
              data-pho={`${app.name || ''}-onApp`}
            />
          </Tooltip>
        </MenuList>
        <AddAppButton />
      </Flex>
    )
  }
}

export default CurrentlyOnAppOrGroupOrGroup
