import { colors } from 'components/shared/utils/colors'
import { element } from 'components/shared/utils/spacing'
import Flex from 'components/shared/flex/Flex'
import styled from 'styled-components'
import { lighten } from 'polished'

export const ButtonTabTitle = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
`

export const TabButton = styled.button.attrs({
  $size: '24px',
  $fontSize: '13px',
  $color: colors.gray30,
})`
  vertical-align: middle;
  text-align: center;
  padding: 0 ${element.xs};
  max-width: 200px;
  font-size: ${p => p.$fontSize};
  border: 0px;
  height: ${p => p.$size};
  border-radius: ${p => p.$size};
  background-color: ${p => lighten(0.5, p.$color)};
  color: ${p => p.$color};
  cursor: pointer;
  &:hover {
    transition: background-color 0.5s ease;
    background-color: ${p => lighten(0.4, p.$color)};
  }
  &:hover:disabled,
  &:disabled {
    color: ${p => lighten(0.3, p.$color)};
    background-color: ${p => lighten(0.5, p.$color)};
    cursor: default;
  }
`

export const ActiveTabButton = styled(TabButton).attrs({
  $color: colors.blue20,
})``

export const TabButtonContainer = styled(Flex).attrs({})``
