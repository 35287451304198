import React from 'react'
import isNil from 'lodash/isNil'
import numeral from 'numeral'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import { CustomMetricProperties } from 'screens/entityID/client-analytics/metrics/shared/types'
import { getPercentageChange } from 'utils'
import * as S from './styles'

export default function AggregateChart({
  data,
  title,
  mini,
  customProperties,
}: {
  data: { data: number; previousData?: number }
  title?: string
  mini?: boolean
  customProperties?: CustomMetricProperties
}) {
  const currentPeriodData = data.data
  const previousPeriodData = data?.previousData

  const changeDirection =
    previousPeriodData && currentPeriodData > previousPeriodData ? 'increase' : 'decrease'

  const formatValue = customProperties?.roundingFormula || '0,0'

  if (isNil(data)) {
    return <NoDataChartMessage title={title} />
  }

  const valueToDisplay = getValueToDisplay(currentPeriodData, customProperties)

  return (
    <S.Container mini={mini}>
      <S.ChartValue>
        {customProperties?.displaySymbolBefore || null}{' '}
        {numeral(valueToDisplay).format(formatValue)} {customProperties?.displaySymbolAfter || null}
      </S.ChartValue>
      {previousPeriodData && (
        <S.PreviousChartValue changeDirection={changeDirection}>
          {`${changeDirection === 'increase' ? '+' : ''}${getPercentageChange(
            previousPeriodData,
            currentPeriodData
          )}% Previous Period`}
        </S.PreviousChartValue>
      )}
      {customProperties?.subtitle && <S.Subtitle>{customProperties?.subtitle}</S.Subtitle>}
    </S.Container>
  )
}

function getValueToDisplay(rawValue: number, customProperties?: CustomMetricProperties): number {
  if (isNil(customProperties?.arithmeticOperand)) return rawValue

  const operation = customProperties?.arithmeticOperation || 'addition'
  const operand = customProperties?.arithmeticOperand as number

  switch (operation) {
    case 'addition': {
      return Number(rawValue) + Number(operand)
    }
    case 'subtraction': {
      return Number(rawValue) - Number(operand)
    }
    case 'multiplication': {
      return Number(rawValue) * Number(operand)
    }
    case 'division': {
      return Number(rawValue) / Number(operand)
    }
    default: {
      return Number(rawValue)
    }
  }
}
