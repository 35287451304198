import * as React from 'react'

function SvgSupport(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0C6.27 0 0 6.268 0 14 0 21.73 6.27 28 14 28c7.732 0 14-6.269 14-14C27.992 6.27 21.73.008 14 0zm7.741 4.845L18.185 8.4a6.946 6.946 0 00-8.369 0L6.26 4.845a11.952 11.952 0 0115.481 0zM14.001 19a5 5 0 110-10 5 5 0 010 10zM2 14a11.94 11.94 0 012.845-7.741l3.556 3.556a6.946 6.946 0 000 8.369L4.846 21.74A11.94 11.94 0 012 14zm4.259 9.154l3.556-3.556a6.945 6.945 0 008.369 0l3.556 3.556a11.952 11.952 0 01-15.481 0zm16.895-1.414L19.6 18.184a6.945 6.945 0 000-8.369l3.556-3.556a11.952 11.952 0 010 15.481z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSupport
