import * as React from 'react'

function SvgEye(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 22" {...props}>
      <path
        d="M15 0c9.567 0 13.755 10.194 13.929 10.629l.148.371-.148.371C28.755 11.806 24.567 22 15 22S1.245 11.806 1.071 11.371L.923 11l.148-.371C1.245 10.194 5.433 0 15 0zm0 2C7.747 2 3.91 9.257 3.094 11c.817 1.743 4.653 9 11.906 9 7.253 0 11.09-7.257 11.906-9C26.089 9.257 22.253 2 15 2zm0 3a6 6 0 016 6 6.007 6.007 0 01-6 6 6 6 0 110-12zm0 2a4 4 0 100 8 4 4 0 000-8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgEye
