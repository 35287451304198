import Helix from '@charter/helix/build/portals/index.min'
import extensions from '@charter/helix/build/extensions'
import { EventId, EventProperties, Generic_Error_Internal_Apps } from './HelixRequirements'
import camelCase from 'lodash/camelCase'
import { WindowLocation } from '@reach/router'
import packageJson from '../../../package.json'
import analyticsConfig from './config'

const BREAKPOINT = [1920, 1280, 960, 720, 600, 320]

export default (() => {
  const state = {
    config: {
      ...analyticsConfig,
      breakpoint: BREAKPOINT,
      collector: {
        appVersion: packageJson?.version,
        endpoint: analyticsConfig.quantum.endpoint,
        ...(analyticsConfig?.collector || {}),
      },
      debugAppEvents: analyticsConfig.debugAppEvents,
    },
    defaultData: {},
    errors: {},
  }
  const generateTraceId = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, x => {
      const t = (16 * Math.random()) | 0
      return ('x' === x ? t : (3 & t) | 8).toString(16)
    })

  const getVisitID = () => Helix.get('visitId')
  const getAppSessionId = () => {
    const { state = '{}' } = Helix.analytics.core.getStoredState({
      applicationName: analyticsConfig.collector.appName,
      applicationType: analyticsConfig.collector.appType,
    })
    if (state) {
      const { model: { visit: { appSessionId = '' } = {} } = {} } = JSON.parse(state)
      return appSessionId
    }
    return ''
  }

  const init = () => {
    try {
      Helix.enablePersistence()

      if (state?.config?.quantum?.endpoint) {
        Helix.set('endpoint', state.config.quantum.endpoint)
      }

      const initialConfiguration = Object.assign(
        {},
        state?.config?.helix || {},
        state?.config?.collector || {}
      )

      Helix.init(extensions.fetchDefaultData(initialConfiguration)).then(() => {
        if (Helix.get('currentState') === 'blank') {
          Helix.trackStartSession(state?.config?.collector?.startSession || '')
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  function track<T extends EventId>(
    eid: T,
    adHocData: EventProperties[T],
    options?: Record<string, any>
  ) {
    try {
      if (state?.config?.debugAppEvents) {
        console.table({ eid, adHocData, options })
      }
      Helix.track(eid, adHocData, options)
    } catch (err) {
      if (state.config?.debugAppEvents) {
        console.error(err)
      }
    }
  }

  const trackErrorUnique = (
    adHocData: Generic_Error_Internal_Apps,
    options?: Record<string, any>
  ) => {
    const entry = JSON.stringify(adHocData)
    const triggered = state.errors[entry]
    if (!triggered) {
      state.errors[entry] = Date.now()
      track('generic_error_internal_apps', adHocData, options)
    }
  }

  const trackPageViewInit = (adHocData: EventProperties['generic_pageViewInit_internal_apps']) => {
    try {
      track('generic_pageViewInit_internal_apps', {
        currPageUrl: window.location.href,
        currPageTitle: adHocData.currPageTitle,
        msgTriggeredBy: adHocData.msgTriggeredBy || 'user',
        currPageName: adHocData.currPageName,
        currPageAppSection: adHocData.currPageAppSection,
        currPageIsLazyLoad: adHocData.currPageIsLazyLoad,
        uiResponsiveBreakpoint: extensions.getCurrentBreakpoint(),
      })
    } catch (err) {
      if (state?.config?.debugAppEvents) {
        console.error(err)
      }
    }
  }

  const trackPageView = () => {
    try {
      Helix.track('generic_pageView_internal_apps', { msgEventType: 'pageViewCompleted' })
    } catch (err) {
      if (state?.config?.debugAppEvents) {
        console.error(err)
      }
    }
  }

  function getCurrentPageAppSection(location: WindowLocation): string {
    const rootPath = location.pathname.split('/')[1]
    // prettier-ignore
    switch (rootPath) {
      case '': return 'home'
      case 'landing':
      case 'welcome': return 'welcome'
      case 'applications':
      case 'groups': return 'deprecated'
      default: {
        const secondPath = location.pathname.split('/')[2]
        switch (secondPath) {
          case 'dashboards': return 'dashboards'
          case 'client-analytics': return 'clientAnalytics'
          case 'server-analytics': return 'serverAnalytics'
          case 'quantum-resources': return 'quantumResources'
          default: return rootPath && secondPath ? camelCase(`${rootPath}-${secondPath}`) : secondPath ? rootPath : '404'
        }
      }
    }
  }

  init()
  return {
    track,
    trackPageViewInit,
    trackPageView,
    getVisitID,
    getAppSessionId,
    getUUIDV4: extensions.getUUIDV4,
    generateTraceId,
    getCurrentPageAppSection,
    trackErrorUnique,
  }
})()
