import startCase from 'lodash/startCase'
import { UserPageFavorite } from 'components/App/Providers/Data/PrismUser'
import * as T from './types'
const WHITE_SPACES_REGEX = /\s+/g

export const getTitle = (path: string, entityID?: string) => {
  const pathWithoutEntityId = entityID ? path.replace(`/${entityID}`, '') : path
  const pathArray = pathWithoutEntityId.split('/').filter(value => value !== '')
  const pathToReturn = pathArray.reduce((acc: string, currentValue: string, index: number) => {
    const titleChunk = startCase(currentValue).replace(WHITE_SPACES_REGEX, '')
    return index === 0 ? titleChunk : `${acc} | ${titleChunk}`
  }, '')

  return pathToReturn
}
export const checkDuplicateFavoritesPage = (
  currentPageFavorites: UserPageFavorite[],
  pageFavoriteToAdd: T.PageFavoriteToAdd
) => {
  const filteredCurrentPageFavorites = currentPageFavorites.map(
    ({ _id, ...keepAttribute }) => keepAttribute
  )
  const isDuplicatePageFavorites = filteredCurrentPageFavorites.some(
    filteredCurrentPageFavorite =>
      JSON.stringify(pageFavoriteToAdd) === JSON.stringify(filteredCurrentPageFavorite)
  )
  return isDuplicatePageFavorites
}
