import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { colors } from 'components/shared/utils/colors'
import { Dialog, DialogContentText } from '@material-ui/core'

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CloseButton = styled(IconButton)`
  padding: 0;
`

export const StyledDialog = styled(Dialog)({
  '& .MuiBackdrop-root': {
    backgroundColor: colors.darkBlue20,
    opacity: '0.8 !important',
  },
  '& .MuiPaper-root': {
    padding: '2rem',
  },
})

export const StyledDialogTitle = styled(DialogTitle)`
  font-size: 1.5rem;
`

export const StyledDialogAlert = styled.h5``

export const StyledDialogContent = styled(DialogContent)``

export const StyledDialogText = styled(DialogContentText).attrs(() => ({
  variant: 'subtitle1',
}))`
  color: ${colors.black};
`

export const StyledDialogActions = styled(DialogActions)`
  padding: 1rem 1.5rem;
`

export const StyledDownloadLink = styled.a`
  font-weight: 500;
  color: ${colors.blue20};
  &:hover {
    text-decoration: underline;
  }
`
