import * as React from 'react'

function SvgHomeSpeakerF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M12 14.003A3.998 3.998 0 1012.001 22 3.998 3.998 0 0012 14.003zm0 0A3.998 3.998 0 1012.001 22 3.998 3.998 0 0012 14.003zM.006 1.006v23.995A3.099 3.099 0 003.005 28h17.99a2.999 2.999 0 002.999-3V1.007a.9.9 0 00-1-1H1.006a.9.9 0 00-1 1zm11.994 3a2.898 2.898 0 012.998 2.999 2.999 2.999 0 11-5.996 0 2.9 2.9 0 012.998-3zM12 24a5.998 5.998 0 01-5.997-5.999A5.998 5.998 0 1112 24.001zm0-9.998A3.998 3.998 0 1012.001 22 3.998 3.998 0 0012 14.003zm0-5.998a1.1 1.1 0 001-1 1 1 0 10-2 0 1.1 1.1 0 001 1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHomeSpeakerF
