import { useState } from 'react'
import { navigate, useLocation } from '@reach/router'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import queryString from 'query-string'
import Link from 'components/shared/Link/PrismReactLink'
import { Tabs } from 'components/shared/Tabs'
import useFilterValues from 'hooks/useFilterValues'
import MultiChart from 'components/shared/multiChart'
import * as S from 'components/shared/multiChart/styled'
import ChartData from 'components/shared/ChartData'
import { getDataSource } from 'components/shared/ChartData/utils'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import CustomLoading from 'components/shared/customLoading'
import ActionsMenu from 'components/shared/chart/actionsMenu'
import { getTimeZone } from 'components/shared/chart/utils'
import TabButton from 'components/shared/multiChart/TabButton'
import { chartColors } from 'common/constants'
import { debug } from 'common/services'
import useHeader from 'hooks/useHeader'
import * as T from './types'
import {
  getComponent,
  generateChartQueryString,
  generateChartCompareQueryString,
  getShowWarning,
  getUnappliedFilterLabels,
} from './utils'

function MultiChartContainer({ multiChart }: { multiChart: T.MultiChartObj }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const filterValues = useFilterValues()
  const location = useLocation()
  const query = queryString.parse((location && location.search) || '')
  const { groupBy } = query
  const { areFiltersReady } = useHeader().state
  const multiChartProps = omit(multiChart, ['charts', 'type'])
  const queries = !multiChartProps.rawDataArray
    ? multiChart.charts.map(subChart => ({
        query: generateChartQueryString(subChart.query, filterValues, subChart.filterTemplates),
        aggrQuery: subChart.aggrQuery
          ? generateChartQueryString(subChart.aggrQuery, filterValues, subChart.filterTemplates)
          : null,
        compareQuery: subChart.isComparable
          ? generateChartCompareQueryString(subChart.query, filterValues)
          : null,
      }))
    : [{ query: '', aggrQuery: '', compareQuery: '' }]
  const multiChartTypes = multiChart.charts.map(subChart => {
    return subChart.type
  })

  const chartTitle = multiChart.mainTitle || ''
  const dataSources = multiChart.charts.map(chart => {
    return getDataSource(chart.type, chart.dataSource)
  })
  const allSourcesEqual = dataSources.every((source, i, dataSources) => source === dataSources[0])

  const handleTabChange = (index: any) => {
    setActiveTabIndex(index)
  }

  const componentsWithProps = multiChart.charts.map((subChart, index) => ({
    Component: getComponent(subChart.type),
    props: {
      key: `Chart_${subChart.type}_${Math.floor(Math.random() * 100000 + 1)}`,
      ...omit(subChart, ['type', 'query']),
      colors: subChart.colors || chartColors,
      zoomOption: subChart.type === T.DataType.LINE || subChart.type === T.DataType.OS_LINE,
      dataSource: getDataSource(subChart.type),
      handleTabChange: handleTabChange,
      isBubbleData:
        subChart.type === T.DataType.SUNBURST_WITH_TOP ||
        subChart.type === T.DataType.OS_SUNBURST_WITH_TOP,
      showWarning: getShowWarning(subChart, groupBy),
      unappliedFilterLabels: getUnappliedFilterLabels(subChart, filterValues),
      subChartQuery: subChart.query,
      subChartType: subChart.type,
    },
  }))

  const renderActionsMenu = ({
    getOpenSearchUrl,
    zoomOption,
    handleLineZoom,
    yMinScaleIsAuto,
    currentData,
    title,
    metaData,
    mainTitle,
    summaryContentData,
    dataSource,
    linkPath,
    bottomLinkObj,
    isQubeApp,
    hideActionMenu,
  }: {
    getOpenSearchUrl?: (args: T.OpenSearchUrlArgs) => string
    handleLineZoom?: Function
    hideActionMenu?: boolean
    yMinScaleIsAuto?: boolean
    metaData?: Record<string, any>
    currentData?: any
    zoomOption?: boolean
    mainTitle?: string
    summaryContentData?: JSX.Element
    dataSource?: string
    linkPath?: string
    bottomLinkObj?: Record<string, any>
    isQubeApp?: boolean
    title?: string
  }) => (
    <S.ActionMenuSummaryContainer>
      {!hideActionMenu && (
        <ActionsMenu
          getOpenSearchUrl={getOpenSearchUrl}
          zoomOption={zoomOption}
          handleLineZoom={handleLineZoom}
          isLineChartZoomedOut={!yMinScaleIsAuto}
          chartData={currentData}
          chartTitle={title}
          metaData={metaData}
          hasAbsolutePosition={!mainTitle && !summaryContentData}
          timeZone={getTimeZone({
            query,
            location,
            isQubeApp,
            dataSource,
          })}
          dataSource={dataSource}
          linkPath={linkPath}
          link={
            bottomLinkObj ? (
              <Link disableVisited icon variant="standalone" to={linkPath}>
                {bottomLinkObj?.title}
              </Link>
            ) : undefined
          }
        />
      )}
    </S.ActionMenuSummaryContainer>
  )

  const multiChartTabTitles = multiChart.charts
    ? multiChart.charts.map(chart => {
        return chart.title
      })
    : []

  const tabButtonProps = multiChartProps.isTabbed
    ? {
        tabButtonProps: {
          mainTitle: multiChartProps.mainTitle,
          description: multiChartProps.description,
          activeTab: activeTabIndex,
          tabTitles: multiChartTabTitles,
        },
      }
    : {}

  const handleTab = (index: number) => {
    debug('>>>>> HANDLE Tab clicked', { index })

    setActiveTabIndex(index)
    handleTabChange(index)

    multiChartProps.addTabIndexInURL &&
      navigate(`${location.pathname}?${queryString.stringify({ ...query, tab: index })}`, {
        replace: true,
      })
  }

  const handleTabClick = (index: number) => () => {
    debug('>>>>> CLICK Tab clicked', { index })

    setActiveTabIndex(index)
    handleTabChange(index)

    multiChartProps.addTabIndexInURL &&
      navigate(`${location.pathname}?${queryString.stringify({ ...query, tab: index })}`, {
        replace: true,
      })
  }

  const renderTabButtons = (isLoading?: boolean, dataArray?: Record<string, any>) => {
    const topContentWithTitleAndDescription = (
      <S.ButtonTabContainer hasTopMargin={multiChartProps.mainTitle ?? multiChartProps.description}>
        {queries.map((query, index) => {
          const componentProps = componentsWithProps[index].props
          if (index === activeTabIndex) {
            return (
              <TabButton
                key={`Tab${index}`}
                title={componentProps.title}
                index={index}
                handleTabClick={handleTabClick}
                active
              />
            )
          }
          return (
            <TabButton
              key={`Tab${index}`}
              title={componentProps.title}
              index={index}
              handleTabClick={handleTabClick}
              disabled={isLoading}
            />
          )
        })}
      </S.ButtonTabContainer>
    )

    const tabs = queries.map((query, index) => ({
      title: componentsWithProps[index].props.title,
      index: index,
    }))

    const topContentWithoutTitleAndDescription = (
      <>
        <Tabs
          tabs={tabs}
          tabIndex={activeTabIndex}
          initialTabIndex={activeTabIndex}
          onClick={(tab, index) => handleTab(index)}
        />
        {renderActionsMenu(
          dataArray
            ? { currentData: dataArray, ...componentsWithProps[activeTabIndex].props }
            : componentsWithProps[activeTabIndex].props
        )}
      </>
    )
    return multiChartProps.mainTitle || multiChartProps.description
      ? topContentWithTitleAndDescription
      : topContentWithoutTitleAndDescription
  }

  if (!areFiltersReady || !filterValues?.period) {
    return (
      <CustomLoading
        title={chartTitle}
        dataSource={allSourcesEqual ? dataSources[0] : dataSources.join('/ ')}
        tabButtonChart
        tabButtonProps={tabButtonProps as any}
        renderTabButtons={renderTabButtons}
      />
    )
  }

  if (isEmpty(queries)) {
    return (
      <NoDataChartMessage
        title={chartTitle}
        dataSource={allSourcesEqual ? dataSources[0] : dataSources.join('/ ')}
        tabButtonChart
        tabButtonProps={tabButtonProps as any}
        ActionsMenu={renderActionsMenu({})}
        renderTabButtons={renderTabButtons}
      />
    )
  }

  return (
    <ChartData
      key={`Chart_Data_${Math.floor(Math.random() * 100000 + 1)}`}
      queries={queries}
      types={multiChartTypes}
      title={chartTitle}
      isMultiChart
      rawDataArray={multiChartProps.rawDataArray}
      filterValues={filterValues}
      handleTabChange={handleTabChange}
      renderActionsMenu={renderActionsMenu}
      renderTabButtons={renderTabButtons}
      componentsWithProps={componentsWithProps}
      {...tabButtonProps}
    >
      {dataArray => (
        <MultiChart
          dataArray={dataArray}
          activeTab={activeTabIndex}
          queries={queries}
          componentsWithProps={componentsWithProps}
          renderActionsMenu={renderActionsMenu}
          tabButtons={renderTabButtons(false, get(dataArray, [activeTabIndex, 'formattedData']))}
          {...multiChartProps}
        />
      )}
    </ChartData>
  )
}

export default MultiChartContainer
