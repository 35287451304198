import React from 'react'

import * as S from './styles'

function SearchInput(props: React.HTMLAttributes<HTMLInputElement>) {
  return <S.StyledInput {...props} noBorderBottom />
}

export default SearchInput
export { S as Styles }
