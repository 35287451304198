import React from 'react'
import { Avatar, AvatarProps } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { colors } from 'components/shared/utils/colors'
import { generateUserInitials } from './utils'

function UserAvatar({
  user = { name: 'Prism Explorer' },
  userImage,
  userInitials,
  style,
  className,
  backgroundColor = colors.gray10,
  textColor = colors.blue30,
}: UserAvatarProps) {
  return (
    <Tooltip title={user?.name} aria-label={user?.name} placement="top" data-pho="avatar">
      <Avatar
        className={{ ...className }}
        style={{
          ...{
            backgroundColor,
            color: textColor,
          },
          ...style,
        }}
        src={userImage}
      >
        {!userImage && !userInitials ? generateUserInitials(user?.name) : userInitials}
      </Avatar>
    </Tooltip>
  )
}

interface UserAvatarProps extends AvatarProps {
  user: Record<string, any> | undefined
  userImage?: string
  userInitials?: string
  style?: Record<string, any>
  className?: any
  backgroundColor?: string
  textColor?: string
}

export default UserAvatar
