import * as React from 'react'

function SvgMapPoint(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 29" {...props}>
      <path
        d="M11 0c6.075 0 11 4.924 11 11 0 8.383-9.952 16.442-10.375 16.78l-.625.5-.625-.5C9.952 27.443 0 19.384 0 11 0 4.924 4.925 0 11 0zm0 2a9.01 9.01 0 00-9 9c0 6.298 6.865 12.814 9 14.687 2.135-1.873 9-8.389 9-14.688a9.01 9.01 0 00-9-9zm0 3a6 6 0 016 6 6.007 6.007 0 01-6 6 6 6 0 110-12zm0 2a4 4 0 100 8 4 4 0 000-8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMapPoint
