import * as React from 'react'

function SvgOnDemand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 24" {...props}>
      <path
        d="M24 0v24h2V0h-2zm-4 13v11h2V0h-2v13zm-5 11H0V3a3.003 3.003 0 013-3h12a3.003 3.003 0 013 3v18a3.003 3.003 0 01-3 3zM2 22h13a1 1 0 001-1V3a1 1 0 00-1-1H3a1 1 0 00-1 1v19z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgOnDemand
