import * as React from 'react'

function SvgIllustratorSwatch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M4 14h10V4H4v10zm2-8h6v6H6V6zm12 0V0H0v18h6v6h18V6h-6zM2 16V2h14v14H2zm20 6H8v-4h10V8h4v14z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgIllustratorSwatch
