import * as React from 'react'

function SvgSetTopBoxF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 16" {...props}>
      <path
        d="M0 0v14h2v2h5v-2h14v2h5v-2h2V0H0zm5 4a1 1 0 110 2 1 1 0 010-2zm6 6H4V8h7v2zm12 0a1 1 0 110-2 1 1 0 010 2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSetTopBoxF
