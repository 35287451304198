import * as React from 'react'

function SvgCastF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 25" {...props}>
      <path
        d="M26 0a3.004 3.004 0 013 3v21.004h-9V24h-1a19.765 19.765 0 00-.87-5.87A15.764 15.764 0 006.87 6.87 19.765 19.765 0 001 6L.99 5 1 0h25zM1 9c1.589-.005 3.17.227 4.69.69a14.402 14.402 0 019.62 9.62c.463 1.52.695 3.101.69 4.69h-2A12.716 12.716 0 001 11V9zm0 5a9.89 9.89 0 0110 10H9a7.825 7.825 0 00-8-8v-2zm0 4.91a5.475 5.475 0 015.09 5.089H2a1 1 0 01-1-1v-4.09z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCastF
