import * as React from 'react'

function SvgOta(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M13 8a5 5 0 011 9.899V24h1a2 2 0 012 2H9a2 2 0 012-2h1v-6.101A5 5 0 0113 8zm0-7.996c7.18 0 13 5.82 13 13a12.878 12.878 0 01-3.814 9.19l-1.411-1.419A10.888 10.888 0 0024 13.004c0-6.075-4.924-11-11-11-6.075 0-11 4.925-11 11a10.888 10.888 0 003.226 7.771l-1.411 1.418A12.875 12.875 0 010 13.003c0-7.179 5.82-13 13-13zm0 4.005a8.952 8.952 0 018.952 8.942 8.854 8.854 0 01-2.63 6.327l-1.355-1.365a6.943 6.943 0 002.062-4.962 7.029 7.029 0 00-14.057 0 6.943 6.943 0 002.062 4.962l-1.355 1.365a8.854 8.854 0 01-2.63-6.327A8.952 8.952 0 0113 4.01zM13 10a3 3 0 100 6 3 3 0 000-6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgOta
