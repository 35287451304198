import * as React from 'react'

function SvgCompose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M16 0v2H3a1 1 0 00-1 1v21h21a1 1 0 001-1V10h2v13a3.003 3.003 0 01-3 3H0V3a3.003 3.003 0 013-3h13zm7.683.107a3.125 3.125 0 011.401 5.228L11.632 18.788l-6.049 1.63 1.628-6.051L20.665.916a3.125 3.125 0 013.018-.81zM22.08 2.33L9.004 15.404l-.587 2.178 2.178-.587L23.67 3.92A1.126 1.126 0 0022.08 2.33z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCompose
