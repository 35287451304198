import * as React from 'react'

function SvgFlagF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 1.31v14.22l-1.39-.58c-3.71-1.57-7.45-.78-11.4.05a34.414 34.414 0 01-7.14 1A14.318 14.318 0 012 15.44V26H0V.47l1.39.58c3.71 1.57 7.45.78 11.4-.05 4.09-.87 8.31-1.76 12.6.05l.61.26z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFlagF
