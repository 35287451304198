import styled from 'styled-components'

export const StyledTotal = styled.div`
  font-size: 3rem;
  padding: 0.2rem;
`

export const StyledStat = styled.div`
  font-size: 1.25rem;
`
