import React from 'react'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import * as S from './styles'
import ChartContainer from 'components/shared/chart/ChartContainer'
import { DataType } from 'components/shared/chart/types'

function CustomLoading({
  title = '',
  tabButtonProps,
  isExtendContentActive,
  chartType,
  renderTabButtons,
}: {
  title?: string
  dataSource?: string | boolean
  tabButtonChart?: boolean
  tabButtonProps?: {
    mainTitle?: string
    description?: string
    activeTab: number
    tabTitles: string[]
  }
  isExtendContentActive?: boolean
  chartType?: DataType
  renderTabButtons?: Function
}) {
  const component = (
    <S.StyledLoader>
      <LoadingSpinner />
    </S.StyledLoader>
  )

  const tabButtons = renderTabButtons && renderTabButtons(true)

  const chartContainerProps = {
    component,
    chartTitle: tabButtonProps?.mainTitle || title,
    chartDescription: tabButtonProps?.description,
    chartsHeadingProps: {
      title: tabButtonProps?.mainTitle || title,
      description: tabButtonProps?.description,
    },
    tabButtons: tabButtons,
    isExtendContentActive,
  }

  return <ChartContainer {...chartContainerProps} chartType={chartType} />
}
export default CustomLoading
