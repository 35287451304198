import React from 'react'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import Tooltip from 'components/shared/Tooltip'
import Icon from 'components/shared/icon'
import Typography from 'components/shared/typography'
import UniqueUserWarning from './UniqueUserWarning'
import * as S from './styled'

function ChartWarnings({
  showUniqueUserWarning,
  showWarning,
  unappliedFilterLabels,
  showWarningTooltipValue,
  elementIndex,
}: ChartWarningsProps) {
  return (
    <S.WarningContainer>
      {showUniqueUserWarning && <UniqueUserWarning />}
      {showWarning && (
        <Tooltip
          title={showWarningTooltipValue || 'Group By filter is not applied!'}
          dataTest={`groupWarning${isNil(elementIndex) ? '' : `:element${elementIndex}`}`}
        >
          <S.WarningContent>
            <Icon name="LayersF" color="yellow-20" size="sm" />
            <Typography variant="caption" color="yellow-20" noMargin data-test="GroupWarning">
              Group Warning!
            </Typography>
          </S.WarningContent>
        </Tooltip>
      )}
      {!isEmpty(unappliedFilterLabels) && (
        <Tooltip title={`Can't filter this metric by: ${(unappliedFilterLabels || []).join(', ')}`}>
          <S.WarningContent>
            <Icon name="FilterF" color="yellow-20" size="sm" />
            <Typography variant="caption" color="yellow-20" noMargin>
              Filter Warning!
            </Typography>
          </S.WarningContent>
        </Tooltip>
      )}
    </S.WarningContainer>
  )
}

interface ChartWarningsProps {
  showUniqueUserWarning?: boolean
  showWarning?: boolean
  unappliedFilterLabels?: string[]
  showWarningTooltipValue?: string
  elementIndex?: number
}

export default ChartWarnings
