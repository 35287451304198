import { useState, useEffect } from 'react'
import useAuth from 'hooks/useAuth'

enum ROLES {
  CPE_READ = 'cpe-user',
  CPE_WRITE = 'cpe-admin',
}

const checkCPEReadAccess = (roles?: string[]): boolean => roles?.includes(ROLES.CPE_READ) ?? false
const checkCPEWriteAccess = (roles?: string[]): boolean => roles?.includes(ROLES.CPE_WRITE) ?? false

const useCPEAccess = () => {
  const auth = useAuth()
  const roles = auth.user?.roles

  const [hasCPEReadAccess, setHasCPEReadAccess] = useState(checkCPEReadAccess(roles))
  const [hasCPEWriteAccess, setHasCPEWriteAccess] = useState(checkCPEWriteAccess(roles))

  useEffect(() => {
    if (roles) {
      setHasCPEWriteAccess(checkCPEWriteAccess(roles))
      setHasCPEReadAccess(checkCPEReadAccess(roles))
    }
  }, [roles])

  return {
    hasCPEWriteAccess,
    hasCPEReadAccess,
  }
}

export default useCPEAccess
