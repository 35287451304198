import React from 'react'
import isEmpty from 'lodash/isEmpty'
import Tooltip from 'components/shared/Tooltip'
import Flex from 'components/shared/flex/Flex'
import { IconButton } from 'components/shared/IconButton'
import useGroups from 'hooks/useGroups'
import AppFavoritesList from './AppFavoritesList'
import SmallerHeading from '../SmallerHeading'
import * as S from '../styles'

export default function FavoriteApps({
  handleAddCurrentApplication,
  handleAppEditClick,
  currentAppFavorites,
  handleClose,
}: FavoriteAppsProps) {
  const { currentGroup } = useGroups()

  const AddButton = () =>
    !currentGroup ? (
      <S.StyledAddPageButton
        variant="borderless"
        icon="PlusCircleF"
        width="100%"
        onClick={() => handleAddCurrentApplication()}
        data-test="addAppFavoritesSectionButton"
      >
        Add Current App
      </S.StyledAddPageButton>
    ) : (
      <Tooltip title={'Add application by first visiting one from the top menu bar'}>
        <S.StyledAddPageButton
          variant="borderless"
          icon="PlusCircleF"
          width="100%"
          onClick={() => handleAddCurrentApplication()}
          disabled
          data-test="addAppFavoritesSectionButton"
        >
          Add Current App
        </S.StyledAddPageButton>
      </Tooltip>
    )

  return (
    <S.FavoriteTypeContainer>
      <Flex direction="column" width="12rem" padding="0 0 1.25rem 0" data-test="appsSection">
        <Flex alignItems="center" height="3rem">
          <SmallerHeading title="Apps" dataTest="AppsSectionHeading" />
          <IconButton
            icon="Edit"
            onClick={() => handleAppEditClick()}
            data-pho="editApplicationFavorites"
          />
        </Flex>
        {isEmpty(currentAppFavorites) ? (
          <AddButton />
        ) : (
          <AppFavoritesList appFavorites={currentAppFavorites} handleClose={handleClose} />
        )}
      </Flex>
    </S.FavoriteTypeContainer>
  )
}

interface FavoriteAppsProps {
  handleAddCurrentApplication: Function
  handleAppEditClick: Function
  currentAppFavorites: string[]
  handleClose: Function
}
