import React from 'react'
import Typography from 'components/shared/typography/Typography'
import { Badge } from 'components/shared/Badge'
import Flex from 'components/shared/flex/Flex'
import { colors } from 'components/shared/utils/colors'
import { IconButton } from 'components/shared/IconButton'
import * as S from './styles'
import { useDarkMode } from 'components/App/Providers/DarkMode'

const DEFAULT_TAB_TITLE_SLICE_NUMBER = 18
export interface TabProps {
  title: string
  content?: React.ReactNode
  disabled?: boolean
  badgeCount?: number
  handleRemoveTab?: (tabIndex: number) => void
  'data-pho'?: string
  tabTitleSliceNumber?: number
  /** Custom Index can be used when tabs order or tabs count changes, but tab still should have constant index. */
  customIndex?: number
}

export default ({
  tab,
  index,
  tabColor,
  isActive,
  handleTabClick,
  overflowTextHasEllipsis,
  tabTitleSliceNumber = DEFAULT_TAB_TITLE_SLICE_NUMBER,
}: {
  tab: TabProps
  index: number
  tabColor: string
  isActive: boolean
  handleTabClick: Function
  overflowTextHasEllipsis?: any
  tabTitleSliceNumber?: number
}) => {
  const { isDarkMode } = useDarkMode()
  const removeTabButtonRef = React.useRef<HTMLDivElement>(null)
  const disabledColor = colors.gray30

  const handleTabClickCallback = (
    tab: string,
    tabIndex: number,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const isRemoveTabButtonClick =
      removeTabButtonRef && removeTabButtonRef?.current?.contains(e.target as Node)

    if (!removeTabButtonRef || !isRemoveTabButtonClick) {
      handleTabClick(tab, tabIndex)
    }
  }

  return (
    <Flex overflow={overflowTextHasEllipsis && 'hidden'}>
      <S.Tab
        key={`tab_${index}`}
        role="tab"
        $isActive={isActive}
        onClick={e => handleTabClickCallback(tab.title, index, e)}
        tabIndex={isActive ? 1 : 0}
        aria-selected={isActive ? true : false}
        $isDarkMode={isDarkMode}
        data-pho={tab['data-pho'] || `tab${index + 1}`}
        disabled={tab?.disabled}
      >
        <Flex alignItems="center">
          <Typography
            variant="body"
            color={tab?.disabled ? disabledColor : tabColor}
            noMargin
            ellipsis={overflowTextHasEllipsis && 1}
          >
            {tab.title.slice(0, tabTitleSliceNumber)}
          </Typography>
          {tab?.badgeCount && (
            <S.BadgeContainer>
              <Badge
                backgroundColor={colors.blue20}
                value={tab.badgeCount}
                dataTest={`${tab['data-pho'] || `tab${index + 1}`}Badge`}
              />
            </S.BadgeContainer>
          )}
          {tab?.handleRemoveTab && (
            <S.IconButtonContainer ref={removeTabButtonRef}>
              <IconButton
                icon="XAlt"
                size="xs"
                disabled={tab?.disabled}
                data-test={`deleteTab${index + 1}`}
                onClick={() => tab?.handleRemoveTab && tab.handleRemoveTab(index)}
              />
            </S.IconButtonContainer>
          )}
        </Flex>
      </S.Tab>
    </Flex>
  )
}
