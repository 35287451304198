import * as React from 'react'

function SvgSignLanguage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 25" {...props}>
      <path
        d="M25.15 4.49a3.002 3.002 0 00-2.18-1.38 2.436 2.436 0 00-.54-.01L20.21.88a3.088 3.088 0 00-4.25 0L14.74 2.1a3.047 3.047 0 00-2.78.78L10.74 4.1a3.04 3.04 0 00-2.78.78l-.87.88-.8-.8a3.247 3.247 0 00-4.58 0 3.006 3.006 0 000 4.25l14 14c.562.563 1.325.88 2.12.88H19v-2h-1.17a1.017 1.017 0 01-.71-.3l-14-14a1.002 1.002 0 010-1.41 1.237 1.237 0 011.76 0l5.41 5.41 3.53 3.53.56.56c.54.504 1.252.783 1.99.78.269.003.536-.04.79-.13a3.252 3.252 0 002.15-2.35c.036-.158.063-.319.08-.48v-.01c.268-.144.514-.326.73-.54l.11-.11c.037.558.145 1.109.32 1.64a8.31 8.31 0 001.74 2.89l2.07 2.07 1.42-1.42-.29-.29-1.78-1.77c-.33-.34-3.18-3.42 0-6.59l-1.42-1.42-.4.41-1.42 1.41-1.62 1.63-.14.14c-.393.39-1.027.39-1.42 0l-.39-.39-.49-.49.89-.89 1.59-1.58 1.41-1.42 1.59-1.58.01-.01a.968.968 0 01.82-.29.92.92 0 01.33.11c.17.081.312.21.41.37L27 10.31V12h2V9.64l-3.85-5.15zm-7.77-2.2a1.034 1.034 0 011.41 0l1.68 1.68-1.58 1.59-2.39-2.39.88-.88zm-4 2a1.034 1.034 0 011.41 0l2.68 2.68-1.58 1.59-3.39-3.39.88-.88zm3.15 10.35a.936.936 0 01-.74-.18L8.5 7.17l.88-.88a1.024 1.024 0 011.41 0l3.68 3.68-.88.89 2.29 2.29c.383.39.868.664 1.4.79-.134.333-.409.59-.75.7zM5.232 18.768L6.465 20H4a1 1 0 01-1-1v-5H1v5a3.003 3.003 0 003 3h2.707l-1.353 1.354 1.414 1.414 3.646-3.647-3.768-3.767-1.414 1.414z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSignLanguage
