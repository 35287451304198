export const GET_EXPERIMENTS_IDS = `
query getExperiments($where: ExperimentWhereInput!) {
  experiments(where: $where) {
    id
    uuid
    name
    experimentStatusId
    experimentStatus {
      name
    }
    variants {
      uuid
      name
    }
    startTime
    createdTime
  }
} 
`

export const GET_FILTERED_VARIANTS_IDS = `
query getExperiments($where: ExperimentWhereInput!) {
  experiments(where: $where) {
    id
    uuid
    variants {
      uuid
      name
    }
  }
} 
`
