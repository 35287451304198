import * as React from 'react'

function SvgGameConsoleF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 22" {...props}>
      <path
        d="M26.995 4.805c-.4-2.3-2.2-4.799-4.698-4.799a5.897 5.897 0 00-4.698 2H10.4a5.897 5.897 0 00-4.698-2c-2.399 0-4.298 2.5-4.698 4.899l-1 12.996a3.6 3.6 0 001.1 2.8A3.898 3.898 0 004.005 22c3.498 0 4.797-1.3 5.897-3.499.1-.3.3-.4.3-.5h7.596c0 .1.2.2.3.5 1.1 2.2 2.5 3.499 5.898 3.499a3.898 3.898 0 002.899-1.3 3.6 3.6 0 001.1-2.799l-1-13.096zM11 11.003H9.002v2h-2v-2H5.005v-2h1.999V7.005h2v2H11v1.999zm5.998 0h-2v-2h2v2zm2.999 3h-2v-2h2v2zm0-6h-2V6.005h2v2zm2.998 3h-1.999v-2h2v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgGameConsoleF
