import styled, { css } from 'styled-components'
import { Checkbox } from 'components/shared/Checkbox'
import { RadioButton } from 'components/shared/RadioButton'

export const buttonStyle = css`
  && {
    margin-bottom: 0.5rem;
  }
`

export const PrismRadioButton = styled(RadioButton)`
  ${buttonStyle}
`

export const PrismReactCheckbox = styled(Checkbox)`
  ${buttonStyle}
`
