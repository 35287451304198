import * as React from 'react'

function SvgInfoCircleF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14c.008 7.728 6.272 13.992 14 14zm1.5-6h-3V12h3v10zM14 6a2 2 0 110 4 2 2 0 010-4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgInfoCircleF
