import * as React from 'react'

function SvgFullScreen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 0a3.003 3.003 0 013 3v18a3.003 3.003 0 01-3 3H0V3a3.003 3.003 0 013-3h22zm0 2H3a1 1 0 00-1 1v19h23a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12v6h-8v-2h6v-4h2zM6 14v4h6v2H4v-6h2zm6-10v2H6v4H4V4h8zm12 0v6h-2V6h-6V4h8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFullScreen
