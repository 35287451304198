import * as React from 'react'

function SvgListClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 24" {...props}>
      <path
        d="M13 8.621L5.793 1.414 7.207 0 13 5.793 18.793 0l1.414 1.414L13 8.621zm0 6.758l7.207 7.207L18.793 24 13 18.207 7.207 24l-1.414-1.414L13 15.379zM0 11h26v2H0v-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgListClose
