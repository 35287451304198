import * as React from 'react'

function SvgPlaceholderImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 24" {...props}>
      <path
        d="M23 0a3.009 3.009 0 013 3v18a3.009 3.009 0 01-3 3H0V3a3.009 3.009 0 013-3zM6.951 8.463L2 13.414V22h21a1.003 1.003 0 001-1v-5.532l-4.938-4.115-5.11 5.11-7-8zM23 2H3a1.003 1.003 0 00-1 1v7.586l5.049-5.049 7 8 4.89-4.89L24 12.865V3a1.003 1.003 0 00-1-1zm-8 1a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPlaceholderImage
