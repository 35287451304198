import React from 'react'
import useVocState from 'hooks/useVocState'
import { VocFilterOption } from 'screens/entityID/client-analytics/voice-of-customer/State'
import flatten from 'lodash/flatten'
import keys from 'lodash/keys'
import uniq from 'lodash/uniq'
import Autocomplete from 'components/shared/Autocomplete'
import { ManualCategory, CategoryModel } from 'components/voiceOfCustomer/types'
import {
  getGroupsFromCurrentCategories,
  getNotClearedCategories,
  getNotRemovedCategories,
} from './utils'
import * as T from './types'
import * as S from './styled'

const noCategoryFilter = [{ label: 'No Category', value: 'No Category' }]

function VocCategoryGroupFilter({
  filter,
  onChange,
  filterValues,
}: {
  filter: T.Filter
  onChange: (filterValues: T.FilterValues) => void
  filterValues: T.FilterValues
}) {
  const { vocCategory, vocCategoryGroup, middleCategory } = filterValues
  const { state } = useVocState()
  const { processedCategoryGroupData, categoryOptions, categoryGroupOptions, categoriesData } =
    state || {}

  const filterOptionsWithNoCategoryFilter = noCategoryFilter.concat(categoryOptions)

  const filterManualCategories = (categoriesToSet: string[]) => {
    const manualcats = (filterValues.manualCategory as string[]) || []

    return manualcats.filter((cat: string) => {
      const catObj = state.manualCategories.find((c: ManualCategory) => c._id === cat)
      return categoriesToSet.includes(catObj.category)
    })
  }

  const filterMiddleCategories = (categoriesToSet: string[]) => {
    return middleCategory?.filter((catId: string) => {
      const catObj = categoriesData.find((category: CategoryModel) => category._id === catId)
      return categoriesToSet.includes(catObj?.parent)
    })
  }

  function handleCategoryGroupChange(
    vocCategoryGroups: string[],
    reason: string,
    details?: { option: string }
  ) {
    const groupCategoryIdArrays = vocCategoryGroups.map((categoryGroupId: string) => {
      return (
        processedCategoryGroupData[categoryGroupId].map((value: VocFilterOption) => value.value) ||
        []
      )
    })
    const groupCategoryIds = flatten(groupCategoryIdArrays)
    const categoriesToSet = groupCategoryIds.concat(vocCategory || [])
    const groupIds = keys(processedCategoryGroupData)

    const groupsFromCurrentCategories = getGroupsFromCurrentCategories(
      groupIds,
      processedCategoryGroupData,
      categoriesToSet
    )

    // Remove cleared group categories, leave others
    if (reason === 'clear') {
      const notClearedCategories = getNotClearedCategories(
        groupsFromCurrentCategories,
        processedCategoryGroupData,
        vocCategory
      )

      onChange({ vocCategory: notClearedCategories, vocCategoryGroup: [], manualCategory: [] })
    }
    // Remove removed option categories if not in other groups that are still selected
    else if (reason === 'remove-option' && details?.option) {
      const removedOption = details.option
      const notRemovedCategories = getNotRemovedCategories(
        processedCategoryGroupData,
        removedOption,
        groupCategoryIds,
        categoriesToSet
      )

      const manCats = filterManualCategories(notRemovedCategories)
      const filteredMiddleCategories = filterMiddleCategories(categoriesToSet)

      onChange({
        vocCategory: notRemovedCategories,
        vocCategoryGroup: vocCategoryGroups,
        manualCategory: manCats,
        middleCategory: filteredMiddleCategories,
      })
    }
    // Add new categories from selected group
    else {
      const manCats = filterManualCategories(categoriesToSet)
      const filteredMiddleCategories = filterMiddleCategories(categoriesToSet)
      onChange({
        vocCategory: uniq(categoriesToSet),
        vocCategoryGroup: groupsFromCurrentCategories,
        manualCategory: manCats,
        middleCategory: filteredMiddleCategories,
      })
    }
  }

  function handleCategoryChange(categoriesToSet: string[], reason: string) {
    if (reason === 'clear') {
      onChange({ vocCategory: [], vocCategoryGroup: [] })
    }
    // Change also selected category groups based on categoriesToSet
    else {
      const groupIds = keys(processedCategoryGroupData)
      const groupsFromCurrentCategories = getGroupsFromCurrentCategories(
        groupIds,
        processedCategoryGroupData,
        categoriesToSet
      )
      const manCats = filterManualCategories(categoriesToSet)
      const filteredMiddleCategories = filterMiddleCategories(categoriesToSet)
      onChange({
        vocCategory: categoriesToSet,
        vocCategoryGroup: groupsFromCurrentCategories,
        manualCategory: manCats,
        middleCategory: filteredMiddleCategories,
      })
    }
  }

  return (
    <S.VocCategoryGroupContainer key={JSON.stringify(vocCategoryGroup)}>
      <Autocomplete
        label="Category Groups"
        defaultValue={vocCategoryGroup}
        onChange={(value, reason, details) => handleCategoryGroupChange(value, reason, details)}
        options={categoryGroupOptions}
        useGetOptions={filter.useGetOptions}
        getOptionLabel={filter.getOptionLabel}
        realtimeDashboardID={filter.realtimeDashboardID}
        isSequenceEventTypeFilter={filter.isSequenceEventTypeFilter}
        invertedLabel={filter.invertedLabel}
        fullWidth={filter.fullWidth}
        fullHeight
        width={S.FILTER_WIDTH}
        showTagTooltip
        data-pho="filterMenuAutoCompleteInput"
      />
      <Autocomplete
        label="Top-Level Category"
        defaultValue={vocCategory}
        onChange={(value, reason) => handleCategoryChange(value, reason)}
        options={filterOptionsWithNoCategoryFilter}
        useGetOptions={filter.useGetOptions}
        getOptionLabel={filter.getOptionLabel}
        realtimeDashboardID={filter.realtimeDashboardID}
        isSequenceEventTypeFilter={filter.isSequenceEventTypeFilter}
        invertedLabel={filter.invertedLabel}
        fullWidth={filter.fullWidth}
        fullHeight
        width={S.FILTER_WIDTH}
        showTagTooltip
        data-pho="filterMenuAutoCompleteInput"
      />
    </S.VocCategoryGroupContainer>
  )
}

export default VocCategoryGroupFilter
