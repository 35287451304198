import React from 'react'
import { RouteComponentProps } from '@reach/router'
import MultiChartContainer from './multiChartContainer'
import SingleChartContainer from './singleChartContainer'
import * as T from './types'

export default function Chart({
  chart,
}: RouteComponentProps & {
  chart: T.SingleChartObj | T.MultiChartObj
}) {
  if (chart.type === T.DataType.CUSTOM) {
    return (chart as any).content
  }

  if (chart.type === T.DataType.MULTI) {
    return <MultiChartContainer multiChart={chart as T.MultiChartObj} />
  }

  return <SingleChartContainer chart={chart as T.SingleChartObj} />
}
