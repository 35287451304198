import * as React from 'react'

function SvgHomeSpeaker(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M12 12a6 6 0 110 12 6 6 0 010-12zm0 2a4 4 0 100 8 4 4 0 000-8zm0-10a3 3 0 013 3 2.9 2.9 0 01-2.8 3H12a2.9 2.9 0 01-3-3 3 3 0 013-3zm0 2a.9.9 0 00-1 1 1 1 0 002 0 .9.9 0 00-1-1zm9 22H3a2.9 2.9 0 01-3-3V0h24v25a2.9 2.9 0 01-3 3zM2 2v23a.9.9 0 001 1h18a.9.9 0 001-1V2H2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHomeSpeaker
