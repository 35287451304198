import * as React from 'react'

function SvgGuide(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 2V0H0v2h6v6H0v2h18v6H0v2h6v6H0v2h26v-2H8v-6h18v-2h-6v-6h6V8H8V2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgGuide
