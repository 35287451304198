import Tooltip from '@material-ui/core/Tooltip'
import { IconButton } from 'components/shared/IconButton'

export default function PrevPeriodButton({ handleMoveToPast }: { handleMoveToPast: () => void }) {
  return (
    <Tooltip title={'Previous Period'} placement="top">
      <div>
        <IconButton icon="ChevronLeft" size="md" onClick={handleMoveToPast} />
      </div>
    </Tooltip>
  )
}
