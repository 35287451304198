import styled from 'styled-components'
import { colors } from 'components/shared/utils/colors'
import { lightMode } from 'components/shared/utils/elevation'
import { element } from 'components/shared/utils/spacing'

export const MenuContainer = styled.div`
  position: relative;
`

export const Menu = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  ${({ $isOpen }) => ($isOpen ? '' : 'display: none;')}
  border-radius: 4px;
  right: 0px;
  border: 1px solid ${colors.gray20};
  cursor: pointer;
  z-index: 100;

  && {
    ${lightMode[2]}
  }
`

export const LeftSideContainer = styled.div`
  display: flex;
`

export const RedDotContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: right;
`

export const RedDot = styled.div<{
  $isDarkMode: boolean
}>`
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background: ${({ $isDarkMode }) => ($isDarkMode ? colors.red10 : colors.red20)};
`

export const BadgeContainer = styled.div`
  margin-left: 2rem;
`

export const MenuItem = styled.div<{
  $isDarkMode: boolean
  $width?: number
  disabled?: boolean
}>`
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  min-width: 120px;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  padding: ${element.sm};
  background-color: ${({ $isDarkMode }) => ($isDarkMode ? colors.darkBlue20 : colors.white)};
  ${({ $width }) => ($width ? `width: calc(${$width}px - 1px);` : '')}

  &&[disabled] {
    cursor: not-allowed;
  }

  &&:not(:last-child) {
    border-bottom: 1px solid ${colors.gray20};
  }

  &&:hover {
    /* Cannot use chroma.js here as transparency causes elements underneath dropdown to show through */
    background-color: ${({ $isDarkMode }) => ($isDarkMode ? '#212a34' : '#f9f9f9')};
  }
`
