import * as React from 'react'

function SvgTwitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M0 2v22a2 2 0 002 2h22a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2zm19.463 7.685c.01.149.01.288.01.436a9.486 9.486 0 01-14.598 7.986 6.689 6.689 0 004.93-1.384 3.34 3.34 0 01-3.11-2.312c.5.094 1.013.074 1.504-.055a3.348 3.348 0 01-2.674-3.269v-.046c.464.258.983.4 1.514.418A3.333 3.333 0 016.008 7.01a9.482 9.482 0 006.871 3.482 3.34 3.34 0 015.683-3.046 6.75 6.75 0 002.117-.808 3.371 3.371 0 01-1.467 1.848 6.898 6.898 0 001.913-.53 6.836 6.836 0 01-1.662 1.728z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTwitter
