export const timingFunctions = {
    standard: 'cubic-bezier(.25, 0.1, .25, 1)',
    entry: 'cubic-bezier(0, 0, 0.58, 1)',
    exit: 'cubic-bezier(0.42, 0, 1, 1)',
  }
  
  export const durations = {
    short: '200ms',
    medium: '400ms',
    long: '750ms',
  }
  