import React from 'react'
import isEmpty from 'lodash/isEmpty'

export default function useQueued(updateValues: (newValues: Record<string, any>) => void) {
  const [queuedValues, setQueuedValues] = React.useState<Record<string, any>>({})

  function updateQueued(newValues: Record<string, any>) {
    const updatedQueue = { ...queuedValues, ...newValues }
    setQueuedValues(updatedQueue)
    return updatedQueue
  }

  function clearQueue() {
    if (!isEmpty(queuedValues)) {
      setQueuedValues({})
    }
  }

  function updateAndClear(newValues: Record<string, any>) {
    clearQueue()
    updateValues(newValues)
  }

  function batchUpdateValues({
    values,
    delay,
    batchID,
  }: {
    values: Record<string, any>
    delay?: number
    batchID?: number
  }) {
    if (batchID) {
      clearTimeout(batchID)
    }
    const updatedQueue = updateQueued(values)
    function updateAndClearWithNew() {
      updateAndClear(updatedQueue)
    }

    return setTimeout(updateAndClearWithNew, delay || 1000)
  }

  return batchUpdateValues
}
