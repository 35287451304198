import * as React from 'react'

function SvgPhoneOffice(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M28 0v24H9V0h19zM7 0v24H3a3.003 3.003 0 01-3-3V3a3.003 3.003 0 013-3h4zm19 2H11v20h15V2zM5 2H3c-.552 0-1 .448-1 1v18c0 .552.448 1 1 1h2V2zm19 16v2H13v-2h11zm0-4v2H13v-2h11zm0-10v8H13V4h11zm-2 2h-7v4h7V6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPhoneOffice
