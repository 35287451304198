import React from 'react'
import styled from 'styled-components'
import { colors } from 'components/shared/utils/colors'

export const Container = styled(
  ({ mini, ...otherProps }: { mini?: boolean; [key: string]: any }) => <div {...otherProps} />
)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ChartValue = styled.p`
  font-size: 2.5rem;
`

export const PreviousChartValue = styled.p<{ changeDirection?: string }>`
  font-size: 1.2rem;
  color: ${({ changeDirection }) => {
    switch (changeDirection) {
      case 'increase':
        return `${colors.green10}`
      case 'decrease':
        return `${colors.red10}`
      case 'equal':
        return `${colors.gray30}`
      default:
        return 'initial'
    }
  }};
`

export const Subtitle = styled.p`
  font-size: 1.5rem;
`
