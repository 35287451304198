import * as React from 'react'

function SvgSave(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M19.414 0L26 6.586V26H0V0h19.414zM20 12H6v12h14V12zM4 2H2v22h2V10h18v14h2V7.414L18.586 2H14v5H4V2zm9 12a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4zM12 2H6v3h6V2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSave
