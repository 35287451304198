import * as React from 'react'

function SvgBackspace(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 20" {...props}>
      <path
        d="M26 0a3.064 3.064 0 013 3.117v13.766A3.064 3.064 0 0126 20H10.495a3.004 3.004 0 01-2.242-1.006L.693 10l7.56-8.994A3.004 3.004 0 0110.495 0zm0 2H10.495c-.282 0-.55.119-.74.327L3.307 10l6.459 7.686a.989.989 0 00.73.314H26a1.064 1.064 0 001-1.117V3.002A1.064 1.064 0 0026 2zm-4.707 3.293l1.414 1.414L19.414 10l3.293 3.293-1.414 1.414L18 11.414l-3.293 3.293-1.414-1.414L16.586 10l-3.293-3.293 1.414-1.414L18 8.586l3.293-3.293z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgBackspace
