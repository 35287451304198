import React, { useState } from 'react'
import isNaN from 'lodash/isNaN'
import isNumber from 'lodash/isNumber'
import inRange from 'lodash/inRange'
import toNumber from 'lodash/toNumber'
import { FormLabel } from 'components/shared/OptionGroup/styled'
import Button from '@material-ui/core/Button'
import * as S from './styled'

function checkIsOutOfLimitRange(input: number, rangeLimit: { from: number; to: number }) {
  return !inRange(toNumber(input), rangeLimit.from, rangeLimit.to + 1)
}

export default function RangeFilter({
  label,
  filterId,
  handleRangeChange,
  defaultValue,
  rangeLimit,
  useFilterApplyButton,
  setDisableApplyButton,
}: RangeFilterProps) {
  const splitDefaultValue = (defaultValue && defaultValue?.split(':')) || []
  const defaultFrom = splitDefaultValue[0] || null
  const defaultTo = splitDefaultValue[1] || null

  const [fromInput, setFromInput] = useState(defaultFrom)
  const [toInput, setToInput] = useState(defaultTo)

  function resetRange() {
    handleRangeChange(filterId, '')
  }

  function applyRange() {
    handleRangeChange(filterId, `${fromInput}:${toInput}`)
  }

  function handleFromChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(e.target.value)
    const valueToSet = !isNaN(value) && isNumber(value) && value >= 0 ? e.target.value : ''
    setFromInput(valueToSet)
    if (useFilterApplyButton) {
      const newRangeValue = valueToSet && toInput ? `${valueToSet}:${toInput}` : null
      handleRangeChange(filterId, newRangeValue)

      setDisableApplyButton &&
        setDisableApplyButton(
          Boolean(
            rangeLimit && valueToSet && checkIsOutOfLimitRange(parseInt(valueToSet), rangeLimit)
          ) ||
            ((toInput || valueToSet) && (!toInput || !valueToSet))
        )
    }
  }

  function handleToChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(e.target.value)
    const valueToSet = !isNaN(value) && isNumber(value) && value >= 0 ? e.target.value : ''
    setToInput(valueToSet)
    if (useFilterApplyButton) {
      const newRangeValue = valueToSet && fromInput ? `${fromInput}:${valueToSet}` : null
      handleRangeChange(filterId, newRangeValue)

      setDisableApplyButton &&
        setDisableApplyButton(
          Boolean(
            rangeLimit && valueToSet && checkIsOutOfLimitRange(parseInt(valueToSet), rangeLimit)
          ) ||
            ((fromInput || valueToSet) && (!fromInput || !valueToSet))
        )
    }
  }

  const isOutOfFromRangeLimit =
    rangeLimit && fromInput && checkIsOutOfLimitRange(fromInput, rangeLimit)
  const isOutOfToRangeLimit = rangeLimit && toInput && checkIsOutOfLimitRange(toInput, rangeLimit)

  return (
    <S.StyledFormControl component="fieldset">
      {label && <FormLabel data-test="autocompleteLabel">{label}</FormLabel>}
      <S.RangeContainer>
        <S.RangeInput
          key={`Number_Input_From`}
          label="From"
          value={fromInput}
          onChange={handleFromChange}
          error={Boolean(isOutOfFromRangeLimit)}
        />
        <S.RangeInput
          key={`Number_Input_To`}
          label="To"
          value={toInput}
          onChange={handleToChange}
          error={Boolean(isOutOfToRangeLimit)}
        />
        <S.RangeActions>
          {!useFilterApplyButton && (
            <>
              <Button
                color="primary"
                disabled={
                  (defaultFrom === fromInput && defaultTo === toInput) ||
                  Boolean(isOutOfFromRangeLimit) ||
                  Boolean(isOutOfToRangeLimit) ||
                  !(fromInput && toInput)
                }
                onClick={applyRange}
              >
                Set Range
              </Button>
              <Button disabled={!defaultFrom && !defaultTo} onClick={resetRange}>
                Reset Range
              </Button>
            </>
          )}
        </S.RangeActions>
      </S.RangeContainer>
    </S.StyledFormControl>
  )
}

interface RangeFilterProps {
  defaultValue: any
  handleRangeChange: any
  label: any
  filterId: any
  rangeLimit?: { from: number; to: number }
  useFilterApplyButton?: boolean
  setDisableApplyButton?: (disable: boolean) => void
}
