import useSWR, { KeyedMutator } from 'swr'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import * as services from 'common/services'
import { autoTrack } from 'utils/analytics'
import Helix from '@charter/helix/build/portals/index.min'
import { fetch } from 'common/network'

type SWRMetaData<T = any> = {
  realtimeDashboardUrl?: string
  metaData?: T
  druidUrl?: string
}

export type SWRData<T, M = any> = ({ data: T } | T) & SWRMetaData<M>

export type APIDataResult<T = any, E = any, M = any> = {
  isLoading: boolean
  error: E
  data?: T
  mutate: KeyedMutator<SWRData<T>>
  metaData?: M
}

interface ApiArgs<T = any, V = Record<string, any> | string> {
  service: services.ServiceDefinition | null
  query?: string
  variables?: V
  route?: string
  method?: string
  body?: T
  isCachedData?: boolean
  refreshInterval?: number
}

export interface FetchArgs<T = any, V = Record<string, any> | string> {
  service: services.ServiceDefinition
  query: string
  variables?: V
  route: string
  method?: string
  body?: T
}
export const axios = autoTrack.apiCalls.defaultPlugin()

export const axiosConfigBase = () => {
  const userId = localStorage.getItem('id') ?? ''

  return {
    headers: {
      authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      ...Helix.getQubeHeaders(userId),
    },
  }
}

/**
 * This function provides caching for all API calls. Any time the reference
 * to any of the parameters change, the service will be called again. To prevent
 * infinite loops, avoid providing inline variables to useApi().
 * @param service A registered service found in src/common/services
 * @param query For REST requests, this is the query variable. For GraphQL requests, this is the GraphQL query
 * @param variables Only used as variables supplied to GraphQL queries
 * @param route For dynamic routes of api calls
 * @param method To determine the HTTP method for the request
 * @param body To specify the body for the HTTP request
 * @param isCachedData To instead use a stored copy of data rather than recently fetched data
 * @param refreshInterval The interval in number of milliseconds to refetch data
 */
function useApi<T = any, B = any, V = Record<string, any> | string>({
  service,
  query,
  variables,
  route,
  method,
  body,
  isCachedData,
  refreshInterval,
}: ApiArgs<B, V>): APIDataResult<T> {
  const { data, error, mutate } = useSWR<SWRData<T>>(
    isNil(service) ? null : { service, query, variables, route, method, body },
    fetch,
    {
      onSuccess: () => {},
      onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
        if (error.status !== 429) return
        if (retryCount >= 2) return

        setTimeout(() => revalidate({ retryCount }), 1000)
      },
      revalidateOnFocus: false,
      ...(isNil(refreshInterval) ? {} : { refreshInterval }),
    }
  )

  const apiDataNotCached = data && get(data, 'data') && 'data' in data ? data?.data : (data as T)
  const apiDataCached = data as T

  const apiData = isCachedData ? apiDataCached : apiDataNotCached

  return {
    isLoading: !data && !error,
    error,
    data: apiData,
    mutate,
    metaData: data?.realtimeDashboardUrl || data?.metaData || data?.druidUrl,
  }
}

export default useApi
