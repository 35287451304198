import * as React from 'react'

function SvgLayers(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 16.27v2.31L13 26 0 18.58v-2.31l13 7.43 13-7.43zM13 0l11.457 6.547a3 3 0 010 5.209L13 18.303 1.543 11.756a3 3 0 010-5.21L13 0zm0 2.303L2.535 8.283a1 1 0 000 1.737L13 16l10.465-5.98a1 1 0 000-1.737L13 2.303z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLayers
