import * as React from 'react'

function SvgExpand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M12 0v2h8.581l-7.583 7.583 1.414 1.415L22 3.409V12h2V0H12zm0 24v-2H3.419l7.583-7.583-1.414-1.415L2 20.591V12H0v12h12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgExpand
