import {
  DefaultLink,
  DefaultNode,
  ResponsiveSankey,
  SankeyLinkDatum,
  SankeyNodeDatum,
} from '@nivo/sankey'
import { colors } from 'components/shared/utils/colors'
import { chartColors } from 'common/constants'
import NivoContainer from 'components/shared/NivoContainer'
import * as S from './styled'
import numeral from 'numeral'
import Icon from '../icon/Icon'
import Flex from '../flex/Flex'
import Typography from '../typography/Typography'
import { element } from 'components/shared/utils/spacing'
import { useDarkMode } from 'components/App/Providers/DarkMode'

export type Node = DefaultNode & { label: string }
export type Link = DefaultLink
export type Datum = Omit<SankeyNodeDatum<DefaultNode, DefaultLink>, 'color' | 'label'>

export default function Sankey({
  data,
  margin = { top: 8, right: 0, bottom: 55, left: 0 },
  align = 'justify',
  total,
}: {
  data: {
    nodes: Node[]
    links: Link[]
  }
  margin?: Partial<{
    bottom: number
    left: number
    right: number
    top: number
  }>
  align?: 'justify' | 'center' | 'start' | 'end'
  total?: number
}) {
  const { isDarkMode } = useDarkMode()

  /* COLORS */
  let sankeyColors = [...chartColors]
  const colorsObj = data.nodes.reduce((accum, node) => {
    let color = sankeyColors.shift()
    if (!color) {
      sankeyColors = [...chartColors]
      color = sankeyColors.shift()
    }
    return accum[node.label] ? accum : { ...accum, [node.label]: color }
  }, {})
  const colorsFunc = (nodeOrLink: any) => {
    if (nodeOrLink.source) {
      return colors.gray30
    }
    return colorsObj[nodeOrLink.label]
  }

  const chartTheme = {
    tooltip: {
      fontSize: '12px',
      fontFamily: 'Open Sans',
      container: {
        background: isDarkMode ? colors.darkMode.background : colors.white,
      },
    },
    fontSize: 12,
    textColor: isDarkMode ? 'white' : 'black',
    axis: {
      ticks: {
        text: {
          fontSize: '12px',
          fontFamily: 'Open Sans',
        },
      },
    },
  }
  return (
    <NivoContainer key={JSON.stringify(data)}>
      <ResponsiveSankey
        data={data}
        theme={chartTheme}
        margin={margin}
        align={align}
        colors={colorsFunc}
        nodeOpacity={1}
        nodeHoverOthersOpacity={0.25}
        nodeBorderWidth={0}
        linkOpacity={0.15}
        linkHoverOpacity={0.15}
        linkHoverOthersOpacity={0.05}
        linkTooltip={({ link }: { link: SankeyLinkDatum<Node, DefaultLink> }) => {
          return (
            <Flex
              alignItems="left"
              justifyContent="center"
              direction="column"
              gap={element.xs}
              backgroundColor="white"
              padding={element.xs}
            >
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                gap={element.xs}
              >
                <Typography variant="caption" noMargin style={{ fontWeight: 600 }}>
                  {total && `${numeral(link.value / total).format('0.00%')}`}
                </Typography>
                <Typography variant="caption" noMargin data-test="tooltipValue">
                  ({link.value.toLocaleString()})
                </Typography>
              </Flex>
              <S.LinkTooltip>
                <Flex alignItems="center" gap={element.xs}>
                  <S.TooltipChip color={link.source.color} data-test="tooltipSourceLink" />
                  <span>{link.source.label?.split('->')?.[0] || link.source.label}</span>
                </Flex>
                <Icon name="ArrowRight" size="sm" />
                <Flex alignItems="center" gap={element.xs}>
                  <span>{link.target.label?.split('->')?.[0] || link.target.label}</span>
                  <S.TooltipChip color={link.target.color} data-test="tooltipTargetLink" />
                </Flex>
              </S.LinkTooltip>
            </Flex>
          )
        }}
        labelOrientation="horizontal"
        labelPadding={16}
        labelTextColor={'#000000'}
        nodeTooltip={(props: { node: SankeyNodeDatum<DefaultNode, DefaultLink> }) => {
          return (
            <Flex
              alignItems="left"
              justifyContent="center"
              direction="column"
              gap={element.xs}
              backgroundColor="white"
              padding={element.xs}
            >
              <S.NodeTooltip>
                <Flex alignItems="center" gap={element.xs}>
                  <S.TooltipChip color={props.node.color} />
                  {props.node.label} ({props.node.value.toLocaleString()}
                  {total && ` - ${numeral(props.node.value / total).format('0.00%')}`})
                </Flex>
              </S.NodeTooltip>
            </Flex>
          )
        }}
      />
    </NivoContainer>
  )
}
