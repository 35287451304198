import * as React from 'react'

function SvgPlumePodF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 24" {...props}>
      <path
        d="M18.484 12.1h.098L16.134 17H9.866l-2.448-4.9v-.2H7.32l2.448-4.8h6.268l2.448 4.9v.1zm7.149-1.5l-4.31-9A3.124 3.124 0 0018.68 0H7.32a3.124 3.124 0 00-2.644 1.6l-4.309 9a2.851 2.851 0 000 2.8l4.31 9A3.124 3.124 0 007.32 24h11.36a3.124 3.124 0 002.644-1.6l4.309-9c.49-.866.49-1.934 0-2.8zM20.247 13l-2.35 4.9a2.052 2.052 0 01-1.763 1.1H9.866a2.052 2.052 0 01-1.762-1.1L5.754 13a1.832 1.832 0 010-2l2.35-4.9A2.052 2.052 0 019.866 5h6.268a2.052 2.052 0 011.762 1.1l2.35 4.9a1.832 1.832 0 010 2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPlumePodF
