import * as React from 'react'

function SvgPauseF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 26" {...props}>
      <path d="M12 0h8v26h-8V0zM0 0h8v26H0V0z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default SvgPauseF
