import * as React from 'react'

function SvgLibraryF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M23 4h-1V3a3.009 3.009 0 00-3-3H3a3.009 3.009 0 00-3 3v19h4v4h19a3.002 3.002 0 003-3V7a3.009 3.009 0 00-3-3zM8 6l7 5-7 5V6zm16 17a.997.997 0 01-1 1H6v-2h13a3.002 3.002 0 003-3V6h1c.552.002.998.448 1 1v16z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLibraryF
