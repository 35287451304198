import * as React from 'react'

function SvgSprinklerF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 28" {...props}>
      <path
        d="M5.602 5.308l3.062 2.998L7.22 9.72 4.157 6.722l1.445-1.414zm14.862.008l1.444 1.414-3.033 2.97-1.445-1.415 3.034-2.97zM14.022 5v4H11.98V5h2.043zm10.533-3.653L26 2.76l-2.023 1.98-1.444-1.415 2.022-1.98zm-23.11-.044l2.044 2.001-1.445 1.414-2.044-2 1.445-1.415zM14.022 0v3H11.98V0h2.043zm3.065 22h2.043v-2h-2.043v-2a2.867 2.867 0 00-.866-2.152A2.997 2.997 0 0014.022 15v-2h2.044c0-1.105-.915-2-2.044-2H11.98c-1.128 0-2.043.895-2.043 2h2.043v2a2.997 2.997 0 00-2.199.848A2.867 2.867 0 008.914 18v2H6.871v2h2.043v4H.742v2H25.26v-2h-8.173v-4zm-6.13-4a.889.889 0 01.263-.743c.2-.195.48-.29.76-.257h2.042c.28-.033.56.062.76.257.198.195.295.47.262.743v2h-4.086v-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSprinklerF
