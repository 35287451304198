import * as React from 'react'

function SvgMicrophone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M2 12c0 4.41 4.487 8 10 8 5.514 0 10-3.59 10-8h2c0 5.232-4.85 9.531-11 9.957V26h3a2 2 0 012 2H6a2 2 0 012-2h3v-4.043C4.85 21.531 0 17.232 0 12zM16 0a3.003 3.003 0 013 3v8c0 2.986-2.164 6-7 6-4.835 0-7-3.014-7-6V3a3.003 3.003 0 013-3h8zm0 2H8a1 1 0 00-1 1v8c0 2.504 1.87 4 5 4 3.131 0 5-1.496 5-4V3a1 1 0 00-1-1zm-1 7v2H9V9h6zm0-4v2H9V5h6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMicrophone
