import * as React from 'react'

function SvgJumpForwardThirty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M10.707.293L15.414 5l-4.707 4.707-1.414-1.414 2.27-2.27A10 10 0 1022 16h2c.004 6.573-5.283 11.924-11.855 12C5.573 28.074.164 22.846.016 16.275-.13 9.706 5.037 4.24 11.606 4.02L9.293 1.707 10.707.293zM19 12v8h-6v-8h6zm-8 0v8H5v-2h4v-1H5v-2h4v-1H5v-2h6zm6 2h-2v4h2v-4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgJumpForwardThirty
