import React from 'react'
import _ from 'lodash'
import { findClosestNumber } from './utils'
import * as S from './styles'
import { useDarkMode } from 'components/App/Providers/DarkMode'

interface Mark {
  value: number
  step: number
  label: string
}
export interface SliderProps {
  disabled?: boolean
  value?: number
  onChange?: Function
  /** Marks to be displayed on the slider.  (ie: [{value: 0, step: 1800000, label: '30min'}, {value: 33, step: 3600000, label: '1h'}, {value: 66, step: 43200000, label: '12h'}, {value: 100, step: 86400000, label: '1d'}] ) */
  marks: Mark[]
}

export const Slider = ({ value = 0, disabled = false, marks, onChange }: SliderProps) => {
  const { isDarkMode } = useDarkMode()
  const sliderMarks = marks.map(mark => mark.value)

  function handleOnAfterChange(value: any, index: number) {
    const closestMarkValue = findClosestNumber(value, sliderMarks)
    const toReturn = marks.find(mark => mark.value == closestMarkValue)

    onChange && onChange(toReturn)
  }

  const Thumb = (props: any) => {
    const markLabel = marks?.find(mark => mark?.value == value)?.label
    return (
      <S.Thumb
        {...props}
        disabled={disabled}
        isDarkMode={isDarkMode}
        data-test={`activeStep${markLabel}`}
      ></S.Thumb>
    )
  }

  const Track = (props: any, state: any) => (
    <S.Track {...props} index={state.index} disabled={disabled} isDarkMode={isDarkMode} />
  )

  const Mark = (props: any) => {
    //Logic to display mark labels above the slider. react-slider doesn't support this out of the box.
    const tickWidth = 70
    const markWidth = 10
    const markBorder = 2

    const markTickProps = {
      ...props,
      style: { ...props.style, left: -30 },
    }

    const markLabel = marks.find(mark => mark.value == props.key)?.label

    return (
      <>
        <S.Mark markWidth={markWidth} markBorder={markBorder} {...props}>
          <S.MarkTick {...markTickProps} tickWidth={tickWidth} data-test={`markLabel${markLabel}`}>
            {markLabel}
          </S.MarkTick>
        </S.Mark>
      </>
    )
  }

  return (
    <>
      <S.Slider
        renderTrack={Track}
        renderThumb={Thumb}
        renderMark={Mark}
        disabled={disabled}
        value={value}
        marks={sliderMarks}
        onAfterChange={handleOnAfterChange}
      />
    </>
  )
}
