import * as React from 'react'

function SvgInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M14.221 0c2.44.005 2.931.023 4.139.078a9.542 9.542 0 013.156.604 6.647 6.647 0 013.802 3.802 9.542 9.542 0 01.604 3.156C25.985 9.027 26 9.47 26 13c0 3.53-.015 3.973-.078 5.36a9.542 9.542 0 01-.604 3.156 6.647 6.647 0 01-3.802 3.802 9.542 9.542 0 01-3.156.604c-1.387.063-1.83.078-5.36.078-3.53 0-3.973-.015-5.36-.078a9.542 9.542 0 01-3.156-.604 6.647 6.647 0 01-3.802-3.802 9.542 9.542 0 01-.604-3.156c-.055-1.208-.073-1.7-.077-4.139V11.78c.004-2.44.022-2.931.077-4.139a9.542 9.542 0 01.604-3.156A6.647 6.647 0 014.484.682 9.542 9.542 0 017.64.078C8.848.023 9.34.005 11.78.001zm-.296 2.343h-1.85c-2.635.002-3.09.019-4.328.075-.824.01-1.641.161-2.414.448a4.305 4.305 0 00-2.467 2.467 7.193 7.193 0 00-.448 2.414c-.056 1.238-.073 1.693-.075 4.328v1.85c.002 2.635.019 3.09.075 4.328.01.824.161 1.641.448 2.414a4.305 4.305 0 002.467 2.467c.773.287 1.59.438 2.414.448 1.238.056 1.693.073 4.327.075h1.851c2.635-.002 3.09-.019 4.328-.075a7.194 7.194 0 002.414-.448 4.305 4.305 0 002.467-2.467c.287-.773.438-1.59.448-2.414.056-1.238.073-1.693.075-4.328v-1.85c-.002-2.635-.019-3.09-.075-4.328a7.194 7.194 0 00-.448-2.414 4.305 4.305 0 00-2.467-2.467 7.193 7.193 0 00-2.414-.448c-1.238-.056-1.693-.073-4.328-.075zM13 6.324a6.676 6.676 0 110 13.352 6.676 6.676 0 010-13.352zm1.658 2.673a4.333 4.333 0 10-3.316 8.006 4.333 4.333 0 003.316-8.006zM19.94 4.5a1.56 1.56 0 110 3.12 1.56 1.56 0 010-3.12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgInstagram
