import * as React from 'react'

function SvgSmartWatch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 28" {...props}>
      <path
        d="M6.998 13v4h-1a1 1 0 01-1-1v-3h2zm7.997-2v5a1 1 0 01-1 1h-.999v-6h2zm-3.998-2v8h-2V9h2zm8.997 3h-.2a9.9 9.9 0 00-3.999-6.1l-1-5.9H5.199l-1 5.9A9.9 9.9 0 000 14a10.1 10.1 0 004.199 8.1l1 5.9h9.597l1-5.9a10.2 10.2 0 003.998-6.1h.2a1 1 0 001-1v-2a.9.9 0 00-1-1zM6.798 2h6.398l.4 2.7A8.595 8.595 0 009.997 4a8.595 8.595 0 00-3.599.7l.4-2.7zm6.398 24H6.798l-.4-2.7c1.15.443 2.368.68 3.599.7 1.231-.02 2.45-.257 3.599-.7l-.4 2.7zm-3.199-4a7.999 7.999 0 01-7.998-8 7.999 7.999 0 117.998 8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSmartWatch
