import * as React from 'react'

function SvgCashPaymentsF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 22" {...props}>
      <path
        d="M28 20v2H0v-2h28zM25 0a3.009 3.009 0 013 3v12a3.009 3.009 0 01-3 3H0V3a3.009 3.009 0 013-3zM2 12.1V16h3.9A5.01 5.01 0 002 12.1zm24 0a5.01 5.01 0 00-3.9 3.9H25a1.003 1.003 0 001-1zM14 4a5 5 0 100 10 5 5 0 000-10zm0 2a3 3 0 110 6 3 3 0 010-6zm11-4h-2.9A5.01 5.01 0 0026 5.9V3a1.003 1.003 0 00-1-1zM5.9 2H3a1.003 1.003 0 00-1 1v2.9A5.01 5.01 0 005.9 2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCashPaymentsF
