import * as React from 'react'

function SvgHosting(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 14v9a3.003 3.003 0 01-3 3H3a3.003 3.003 0 01-3-3v-9h26zm-2 2H2v7a1 1 0 001 1h20a1 1 0 001-1v-7zm-2 3v2H10v-2h12zM7 19v2H4v-2h3zM26 0v12H0V0h26zm-2 2H2v8h22V2zM7 5v2H4V5h3zm15 0v2H10V5h12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHosting
