import * as React from 'react'

function SvgFastForward(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 20" {...props}>
      <path
        d="M25.909 10.004L10 .214v6.151L0 .215v19.578l10-6.15v6.151l15.909-9.79zM12 3.794l10.092 6.21L12 16.214V3.794zM2 16.215V3.793l8 4.92v2.582l-8 4.92z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFastForward
