import * as React from 'react'

function SvgHomeCameraF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 26" {...props}>
      <path
        d="M16 14V8A8 8 0 100 8v6a8 8 0 007 7.9V24H2a2 2 0 00-2 2h16a2 2 0 00-2-2H9v-2.1a8 8 0 007-7.9zm-8-4a2 2 0 100-4 2 2 0 000 4zM4 8a4 4 0 118 0 4 4 0 01-8 0zm3 9v-2h2v2H7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHomeCameraF
