import React from 'react'
import { DropdownItem, Label } from './DropdownItem'
import { Items } from './Dropdown'
import * as S from './styles'

const DROPDOWN_ITEM_WIDTH = 144

export interface DropdownItemsProps {
  isDarkMode: boolean
  position?: string
  isOpen?: boolean
  handleSelect: (value: any, label: Label) => void
  items: Items
  hasSelection?: any
  actualSelected?: boolean
  inputWidth?: number
}

export const DropdownItems = ({
  handleSelect,
  isDarkMode,
  position,
  isOpen,
  items,
  hasSelection,
  actualSelected,
  inputWidth,
}: DropdownItemsProps) => {
  return (
    <S.Dropdown $isOpen={isOpen as any} $position={position}>
      {items.map((item, i) => (
        <DropdownItem
          handleSelect={handleSelect}
          isSelected={hasSelection && item?.value === actualSelected}
          isDarkMode={isDarkMode}
          width={Math.max(DROPDOWN_ITEM_WIDTH, inputWidth as any)}
          key={i}
          {...item}
        />
      ))}
    </S.Dropdown>
  )
}
