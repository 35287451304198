import * as React from 'react'

function SvgJumpBackTen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M13.293.293l1.414 1.414-2.313 2.313c6.569.22 11.737 5.685 11.59 12.256-.148 6.57-5.557 11.799-12.129 11.723C5.283 27.924-.004 22.573 0 16h2a10 10 0 1010.436-9.978l2.271 2.271-1.414 1.414L8.586 5 13.293.293zM9 12v8H7v-8h2zm8 0v8h-6v-8h6zm-2 2h-2v4h2v-4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgJumpBackTen
