import * as React from 'react'

function SvgArrowDownRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M.293 1.707L1.707.293 24 22.586V7h2v19H7v-2h15.586z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgArrowDownRight
