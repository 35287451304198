import * as React from 'react'

function SvgRecordAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
      <path
        d="M7 14a7 7 0 117-7 7.008 7.008 0 01-7 7zM7 2a5 5 0 100 10A5 5 0 007 2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRecordAlt
