import * as React from 'react'

function SvgStar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 27" {...props}>
      <path
        d="M6.633 27.002a2.095 2.095 0 01-1.977-2.735l2.35-7.28-6.15-4.498a2.086 2.086 0 011.224-3.77h7.595l2.349-7.277a2.076 2.076 0 013.952 0l2.349 7.276h7.596a2.086 2.086 0 011.224 3.77l-6.151 4.499 2.35 7.28a2.079 2.079 0 01-3.2 2.327L14 22.102l-6.143 4.493a2.067 2.067 0 01-1.224.407zM2.08 10.718c-.093.12-.067.139-.043.157l7.317 5.35-2.794 8.657a.073.073 0 00.03.099L14 19.624l7.324 5.356c.137-.035.127-.067.116-.099l-2.794-8.656 7.319-5.351a.073.073 0 00.029-.1l-9.124-.056-2.797-8.66C14.063 2.024 14.055 2 14 2l-2.869 8.717H2.08z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgStar
