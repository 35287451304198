import * as React from 'react'

function SvgYoutube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M24 0a2.006 2.006 0 012 2v22a2.006 2.006 0 01-2 2H2a2.006 2.006 0 01-2-2V2a2.006 2.006 0 012-2zM13.88 6.657L13 6.66a53.187 53.187 0 00-7.03.38c-.78.208-1.387.82-1.59 1.6A23.468 23.468 0 004 13c-.01 1.462.118 2.922.38 4.36.203.781.81 1.392 1.59 1.6 2.332.282 4.68.409 7.03.38 2.35.029 4.698-.098 7.03-.38a2.238 2.238 0 001.59-1.6c.262-1.438.39-2.898.38-4.36a23.468 23.468 0 00-.38-4.36 2.238 2.238 0 00-1.59-1.6A53.187 53.187 0 0013 6.66zm-2.72 3.663l4.7 2.68-4.7 2.68v-5.36z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgYoutube
