import find from 'lodash/find'
import SearchBar from '../SearchBar'
import * as S from '../styles'
import Flex from 'components/shared/flex/Flex'
import { Dropdown } from 'components/shared/Dropdown'

export default function TableFilters({
  handleSetTextToSearch,
  textToSearch,
  searchBy,
  searchByFields,
  sortField,
  includeNullVerbatims,
  setSearchBy,
  setSortField,
  setSortDirection,
  setIncludeNullVerbatims,
}: {
  handleSetTextToSearch: Function
  textToSearch: string
  searchBy: {
    label: string
    value: string
  }
  searchByFields: any
  sortField: string
  includeNullVerbatims: boolean
  setSearchBy: Function
  setSortField: Function
  setSortDirection: Function
  setIncludeNullVerbatims: Function
}) {
  function handleSearchByChange(searchBy: string) {
    const searchByFieldToSet = find(searchByFields, { value: searchBy }) || searchByFields[0]
    handleSetTextToSearch('')
    setSearchBy(searchByFieldToSet)
  }

  function toggleSort() {
    setSortField(sortField === 'timestamp' ? 'visitId' : 'timestamp')
    setSortDirection(sortField === 'desc' ? 'asc' : 'desc')
  }

  function toggleValidVerbatim() {
    setIncludeNullVerbatims(!includeNullVerbatims)
  }

  return (
    <>
      <S.SearchContainer>
        <SearchBar
          setSearchText={handleSetTextToSearch}
          searchText={textToSearch}
          placeholder={`Search by ${searchBy.label}`}
        />
      </S.SearchContainer>
      <Flex center justifyContent="space-around" flex="1 1 0" margin="12px">
        <Dropdown
          label="Search By"
          isInline
          items={searchByFields}
          selected={searchBy.value}
          handleChange={handleSearchByChange}
          minWidth="150px"
          data-pho="searchBy"
          usePopperDropdown
        />
        <S.StyledSwitch
          active={sortField === 'visitId'}
          innerLabel
          onSwitch={toggleSort}
          data-pho="randomSortToggle"
        >
          Random Sort
        </S.StyledSwitch>
        <S.StyledSwitch
          active={includeNullVerbatims}
          innerLabel
          onSwitch={toggleValidVerbatim}
          data-pho="includeNullVerbatimsToggle"
        >
          Null Verbatims
        </S.StyledSwitch>
      </Flex>
    </>
  )
}
