import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import * as S from './styled'

function TabButton({ handleTabClick, title, index, active, disabled }: TabButtonProps) {
  const [showTooltip] = useState(false)

  if (showTooltip) {
    return (
      <S.TabButtonContainer>
        {active ? (
          <Tooltip title={title}>
            <S.ActiveTabButton disabled={disabled}>{title}</S.ActiveTabButton>
          </Tooltip>
        ) : (
          <Tooltip title={title}>
            <S.TabButton onClick={handleTabClick && handleTabClick(index)} disabled={disabled}>
              {title}
            </S.TabButton>
          </Tooltip>
        )}
      </S.TabButtonContainer>
    )
  }

  return (
    <S.TabButtonContainer>
      {active ? (
        <S.ActiveTabButton disabled={disabled}>{title}</S.ActiveTabButton>
      ) : (
        <S.TabButton onClick={handleTabClick && handleTabClick(index)} disabled={disabled}>
          {title}
        </S.TabButton>
      )}
    </S.TabButtonContainer>
  )
}

interface TabButtonProps {
  handleTabClick?: any
  title: string
  index: number
  active?: boolean
  disabled?: boolean
}

export default TabButton
