import * as React from 'react'

function SvgLibrary(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 27" {...props}>
      <path
        d="M19 .004a3.003 3.003 0 013 3v1h1a3.003 3.003 0 013 3v16a3.003 3.003 0 01-3 3H4v-4H0v-19a3.003 3.003 0 013-3zm4 6h-1v13a3.003 3.003 0 01-3 3H6v2h17c.553 0 1-.448 1-1v-16c0-.552-.447-1-1-1zm-4-4H3c-.552 0-.999.448-1 1v17h17c.553 0 1-.448 1-1v-16c0-.552-.447-1-1-1zM7 4.056L16.72 11 7 17.943V4.056zm2 3.887v6.113L13.28 11 9 7.943z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLibrary
