import * as React from 'react'

function SvgCompare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 26" {...props}>
      <polygon
        id="Compare1"
        points="26.41406 6.707 13.82812 6.707 19.12112 1.414 17.70706 -7.10542736e-15 10 7.707 17.707 15.414 19.12106 14 13.82812 8.707 26.41406 8.707"
      ></polygon>
      <polygon
        id="Compare2"
        transform="translate(8.207030, 17.707000) rotate(180.000000) translate(-8.207030, -17.707000) "
        points="16.41406 16.707 3.82812 16.707 9.12112 11.414 7.70706 10 -4.22772928e-13 17.707 7.707 25.414 9.12106 24 3.82812 18.707 16.41406 18.707"
      ></polygon>
    </svg>
  )
}

export default SvgCompare
