import * as React from 'react'

function SvgGameConsole(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 22" {...props}>
      <path
        d="M23.996 22c-3.399 0-4.798-1.3-5.898-3.599l-.3-.4h-7.596c0 .1-.2.2-.3.4-1.1 2.3-2.4 3.599-5.898 3.599a3.898 3.898 0 01-2.899-1.3 3.6 3.6 0 01-1.1-2.799l1-12.996c.4-2.4 2.2-4.899 4.698-4.899a5.897 5.897 0 014.698 2H17.6a5.897 5.897 0 014.698-2c2.499 0 4.298 2.5 4.698 4.799l1 13.096a3.6 3.6 0 01-1.1 2.8 3.898 3.898 0 01-2.9 1.299zm-3.998-9.997v2h-2v-2h2zM9.002 7.004v2h2v1.999h-2v2h-2v-2H5.005v-2h1.999V7.005h2zm7.997 2v1.999h-2v-2h2zm5.997 0v1.999h-1.999v-2h2zm-2.998-3v2h-2v-2h2zM9.902 16.002h8.196c.2 0 1.1.2 1.8 1.6C20.598 19 21.397 20 23.996 20a2.599 2.599 0 001.5-.6 2.1 2.1 0 00.499-1.3l-1-12.996c-.2-1.5-1.399-3.1-2.698-3.1-1.3 0-2.6.8-3.599 1.7l-.3.3H9.602l-.3-.3c-1-.9-1.7-1.7-3.599-1.7-1.899 0-2.499 1.6-2.698 3.2l-1 12.896a2.1 2.1 0 00.5 1.3c.423.354.948.564 1.5.6 2.598 0 3.298-.8 4.097-2.4.8-1.6 1.6-1.6 1.8-1.6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgGameConsole
