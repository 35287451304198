import * as React from 'react'

function SvgLightningF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 30" {...props}>
      <path d="M9 29.77V19H.28L11 .23V11h8.72z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default SvgLightningF
