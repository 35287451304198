import * as React from 'react'

function SvgSunrise(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M12 17.586l4.707 4.707-1.414 1.414L13 21.414V28h-2v-6.586l-2.293 2.293-1.414-1.414L12 17.586zM12 5a8.996 8.996 0 018.941 8H24v2H0v-2h3.059A8.996 8.996 0 0112 5zm0 2a7 7 0 00-6.929 6H18.93A7 7 0 0012 7zM2.707 3.293l2.222 2.222-1.414 1.414-2.222-2.222 1.414-1.414zm18.586 0l1.414 1.414-2.222 2.222-1.414-1.414 2.222-2.222zM13 0v3h-2V0h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSunrise
