import * as React from 'react'

function SvgA(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M24 7v2h-9v19h-2V17h-2v11H9V9H0V7h24zM12 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgA
