import * as React from 'react'

function SvgBackspaceF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 20" {...props}>
      <path
        d="M26 0H10.5c-.86 0-1.678.368-2.25 1.01L.69 10l7.56 8.99A3.017 3.017 0 0010.5 20H26a3.068 3.068 0 003-3.12V3.12A3.068 3.068 0 0026 0zm-3.29 13.29l-1.42 1.42-3.29-3.3-3.29 3.3-1.42-1.42 3.3-3.29-3.3-3.29 1.42-1.42L18 8.59l3.29-3.3 1.42 1.42-3.3 3.29 3.3 3.29z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgBackspaceF
