import * as React from 'react'

function SvgChevronLeftCircleF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14C27.992 6.272 21.728.008 14 0zm3.46 19.79l-1.42 1.42L8.84 14l7.2-7.21 1.42 1.42-5.8 5.79 5.8 5.79z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronLeftCircleF
