import * as React from 'react'

function SvgPaymentF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.728.008 13.992 6.272 14 14 0 7.732-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm1 5h-2v2h-1a3.009 3.009 0 00-3 3v2a3.009 3.009 0 003 3h1v4h-1a1.003 1.003 0 01-1-1v-1H9v1a3.009 3.009 0 003 3h1v2h2v-2h1a3.009 3.009 0 003-3v-2a3.009 3.009 0 00-3-3h-1V9h1c.552.002.998.448 1 1v1h2v-1a3.009 3.009 0 00-3-3h-1V5zm1 10c.552.002.998.448 1 1v2a1.003 1.003 0 01-1 1h-1v-4h1zm-3-6v4h-1a1.003 1.003 0 01-1-1v-2a1.003 1.003 0 011-1h1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPaymentF
