import * as React from 'react'

function SvgSort(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 26" {...props}>
      <path
        d="M9 21.586V4.505L14.495 10l1.46-1.459L8 .586.045 8.541 1.505 10 7 4.505v17.081l-5.293-5.293-1.414 1.414L8 25.414l7.707-7.707-1.414-1.414z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSort
