import React, { Fragment } from 'react'
import Link from '../Link/PrismReactLink'
import * as S from './styles'

const defaultProps = {
  title: 'Nothing on the event horizon...',
  includeMailTo: false,
  errorMessage:
    'An error occurred trying to fetch the data. Try refreshing the page or coming back later.',
  mailToDetails: {
    text: 'If the issue persists, please contact',
    email: 'DL-XGClientAnalytics-Tools',
    subject: 'Encountered a Problem',
    body: 'Paste your url below and describe the issue in detail. Thanks!',
  },
  errorType: 'generic',
}

interface ErrorMessageProps {
  /** The title text for the error message */
  title?: string
  /** Decides whether to include the support email details. Defaults to false */
  includeMailTo?: boolean
  /** The error text for the error message */
  errorMessage: string
  /** The type of error to send to the analytics.track function. Defaults to 'generic' */
  errorType?: string
  /** The details for emailing support */
  mailToDetails?: {
    text: string
    email: string
    subject: string
    body: string
  }
  /** The authentication hook used to authenticate the user for analytics */
  useAuth?: Function
  /** The analytics object that supplies the 'track' function */
  analytics?: {
    track: Function
  }
}

export default ({
  title = defaultProps.title,
  includeMailTo = defaultProps.includeMailTo,
  errorMessage = defaultProps.errorMessage,
  errorType = defaultProps.errorType,
  mailToDetails = defaultProps.mailToDetails,
  useAuth,
  analytics,
}: ErrorMessageProps) => {
  if (useAuth && analytics) {
    const auth = useAuth()
    const accessTokenExp = auth.exp || 0
    const login = auth.login

    React.useEffect(() => {
      const now = new Date()

      analytics.track('error', {
        appErrorType: errorType,
        ...(errorMessage ? { appErrorMessage: errorMessage } : {}),
      })

      if (accessTokenExp < now.getUTCSeconds()) {
        analytics.track('error', {
          appErrorType: 'authentication',
          appErrorMessage: 'User not authenticated.',
        })
        login()
      }
    }, [errorMessage, errorType, accessTokenExp, login])
  }

  function renderMailTo() {
    if (includeMailTo)
      return (
        <Fragment>
          {' '}
          {mailToDetails?.text}{' '}
          <Link href={`mailto:${mailToDetails?.email}?subject=${mailToDetails?.subject}`}>
            {mailToDetails?.body}
          </Link>
          .
        </Fragment>
      )
    return null
  }

  return (
    <S.PageNotFoundContainer data-pho="errorMessage">
      <S.PageNotFoundContent>
        <S.PageNotFoundImage />
        <S.Title>{title}</S.Title>
        <S.PageNotFoundText>
          {errorMessage}
          {renderMailTo()}
        </S.PageNotFoundText>
      </S.PageNotFoundContent>
    </S.PageNotFoundContainer>
  )
}
