import React, { ReactNode } from 'react'
import * as S from './styles'
import Flex from '../flex'
import Typography from '../typography'
import CopyButton from 'components/shared/CopyButton'

export interface Panel {
  title: string
  label?: ReactNode
  labelVariant?: string
  content: ReactNode
  disabled?: boolean
}

export interface AccordionPanelProps extends Panel {
  isExpanded: boolean
  index: number
  handleClick: (index: number) => void
  onClick?: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void
  asList: boolean
  asCard: boolean
  isDarkMode?: boolean
  maxHeightWhenExpanded?: string
  enablePanelTitleCopy?: boolean
}

const getLabelColor = (labelColor?: string, isDarkMode = false) => {
  if (!labelColor) return isDarkMode ? 'white' : 'black'
  switch (labelColor) {
    case 'alert':
      return isDarkMode ? 'red-10' : 'red-20'
    case 'warning':
      return isDarkMode ? 'yellow-10' : 'yellow-20'
    case 'confirmation':
      return isDarkMode ? 'green-10' : 'green-20'
    default:
      return labelColor
  }
}

export const AccordionPanel = ({
  title,
  label,
  labelVariant,
  content,
  isExpanded,
  handleClick,
  onClick,
  index,
  asList,
  asCard,
  isDarkMode,
  maxHeightWhenExpanded = '100rem',
  disabled = false,
  enablePanelTitleCopy,
}: AccordionPanelProps) => {
  const accordionDetailsRef = React.useRef(null)
  const copyButtonRef = React.useRef<any>(null)

  const handleClickWithIndex = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const copyButtonClick = copyButtonRef && copyButtonRef?.current?.contains(e.target)

      if (!copyButtonRef || !copyButtonClick) {
        onClick && onClick(e, index)
        handleClick(index)
      }
    },
    [onClick, handleClick, index]
  )

  return (
    <S.PanelContainer as={asList ? 'li' : 'div'} $asCard={asCard} $isDarkMode={isDarkMode}>
      <S.PanelButton
        aria-expanded={isExpanded}
        $isExpanded={isExpanded}
        $isDarkMode={isDarkMode as any}
        onClick={handleClickWithIndex}
      >
        <S.PanelButtonContent
          inline
          width="100%"
          height="100%"
          justifyContent="space-between"
          alignItems="center"
          tabIndex={-1}
          data-pho="accordion-item"
        >
          <Flex direction="row" alignItems="center">
            <Typography variant="body" noMargin data-pho={`accordion-title-${index}`}>
              {title}
            </Typography>
            {enablePanelTitleCopy && (
              <S.CopyButtonContainer ref={copyButtonRef} id="copyButtonContainer">
                <CopyButton textToCopy={title} />
              </S.CopyButtonContainer>
            )}
          </Flex>
          <Flex inline center marginBetween="16px">
            {typeof label === 'string' ? (
              <Typography variant="body" color={getLabelColor(labelVariant, isDarkMode)} noMargin>
                {label}
              </Typography>
            ) : (
              label
            )}
            <S.StyledIcon
              name="ChevronUp"
              color={isDarkMode ? 'white' : 'gray-30'}
              size="xs"
              $rotation={isExpanded ? '0deg' : '180deg'}
            />
          </Flex>
        </S.PanelButtonContent>
      </S.PanelButton>
      <S.PanelContentContainer
        ref={accordionDetailsRef}
        $isExpanded={isExpanded}
        $maxHeightWhenExpanded={maxHeightWhenExpanded}
      >
        <S.PanelContent $isExpanded={isExpanded}>
          {typeof content === 'string' ? (
            <Typography variant="body" noMargin>
              {content}
            </Typography>
          ) : (
            content
          )}
        </S.PanelContent>
      </S.PanelContentContainer>
    </S.PanelContainer>
  )
}
