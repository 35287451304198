import fileDownload from 'js-file-download'
import * as converter from 'json-2-csv'

export async function downloadData(
  path: string,
  data?: any,
  fetchData?: () => Promise<any>,
  isJson?: boolean
) {
  const fileType = isJson ? 'JSON' : 'CSV'
  const fileName = path.includes('/') ? path.split('/').pop() : path

  const fetchedData = fetchData ? await fetchData() : data

  if (isJson) {
    fileDownloader(JSON.stringify(fetchedData, null, 4), fileType, fileName)
  } else {
    converter.json2csv(fetchedData, (error, value) => {
      if (error) {
        console.error('Error converting JSON to CSV!')
        console.error(error)
        fileDownloader([], fileType, fileName)
      } else {
        fileDownloader(value, fileType, fileName)
      }
    })
  }
}

function fileDownloader(downloadData: any, fileType: string, fileName?: string) {
  return fileDownload(downloadData, `${fileName}_data.${fileType.toLowerCase()}`)
}
