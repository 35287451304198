import { DateTime } from 'luxon'
import { chartColors } from 'common/constants'
import { FeedbackBucketedByTimestamp } from './types'
import * as S from './styles'
import Charts from 'components/shared/charts'
import { DataType } from 'components/shared/chart/types'
import { colors } from 'components/shared/utils/colors'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import { roundToTwoDecimals } from 'utils'

function MultiChart({ data = [] }: MultiChartProps) {
  const dataAsProps = buildData(data)

  dataAsProps[dataAsProps.length - 1].width = !(dataAsProps.length % 2 === 0) ? 4 : 2

  return (
    <S.ChartContainer data-pho="nullVerbatimsCountTrend">
      <Charts charts={dataAsProps} />
    </S.ChartContainer>
  )
}

const nameMappings = {
  avgCSAT: 'CSAT',
  // avgCES: 'CES', *commented out until proper equations are used to get these stats
  avgNPS: 'NPS',
  avgSR: 'SR',
  avgAWSPositive: 'Positive',
  avgAWSNegative: 'Negative',
  avgAWSMixed: 'Mixed',
  avgAWSNeutral: 'Neutral',
  avgStarRating: 'Star Rating (Internal)',
  avgStarRatingExternal: 'Star Rating (App Store)',
}

const sentimentNames = ['avgAWSPositive', 'avgAWSNegative', 'avgAWSMixed', 'avgAWSNeutral']

function buildAverageData(feedbackData: FeedbackBucketedByTimestamp[], type: string) {
  const formattedData = [
    {
      data: feedbackData.reduce((acc, data: FeedbackBucketedByTimestamp) => {
        if (!acc[nameMappings[type]]) {
          acc[nameMappings[type]] = []
          acc['timestamps'] = []
        }

        acc[nameMappings[type]] = [
          ...acc[nameMappings[type]],
          type === 'avgSR' ? data.avgSR * 100 : data[type],
        ]

        acc['timestamps'] = [...acc['timestamps'], DateTime.fromISO(data.date).toMillis()]
        return acc
      }, {}),
    },
  ]
  return formattedData
}

function buildAWSAverageData(feedbackData: FeedbackBucketedByTimestamp[]) {
  return [
    {
      data: feedbackData.reduce((acc, data) => {
        if (!acc['timestamps']) {
          const accKeys = sentimentNames.reduce(
            (accu, name) => ({ ...accu, [nameMappings[name]]: [] }),
            {}
          )
          acc = {
            timestamps: [],
            ...accKeys,
          }
        }

        const sentimentValues = sentimentNames.reduce(
          (accum, name) => ({
            ...accum,
            [nameMappings[name]]: [...acc[nameMappings[name]], [data[name]]],
          }),
          {}
        )

        acc['timestamps'] = [...acc['timestamps'], DateTime.fromISO(data.date).toMillis()]
        return { ...acc, ...sentimentValues }
      }, {}),
    },
  ]
}

const baseCharts = [
  {
    id: 'sentiment',
    title: 'Sentiment',
    customLegend: true,
    type: DataType.LINE,
    yFormat: (value: any) => `${value * 100}%`,
    min: 0,
    max: 1,
    description: 'Customer Review Text (Verbatim) Analysis',
    colors: [colors.green10, colors.red10, colors.purple, colors.blue10],
    dataSource: 'Voice of Customer API',
    isBubbleData: false,
    chartContainerProps: { marginTop: true },
    width: 2,
    margin: { top: 8, right: 45, bottom: 29, left: 20 },
    tooltipFormat: (value: any) => `${roundToTwoDecimals(value)} `,
  },
  {
    id: 'avgStarRating',
    title: 'Star Ratings (Internal)',
    description: 'Average Internal Star Ratings',
    type: DataType.LINE,
    min: 1,
    max: 5,
    colors: chartColors,
    dataSource: 'Voice of Customer API',
    isBubbleData: false,
    chartContainerProps: { marginTop: true },
    width: 2,
    tooltipFormat: (value: any) => `${roundToTwoDecimals(value)} `,
  },
  {
    id: 'avgStarRatingExternal',
    title: 'Star Ratings (App Store)',
    description: 'Average External Star Ratings',
    type: DataType.LINE,
    min: 1,
    max: 5,
    colors: chartColors,
    dataSource: 'Voice of Customer API',
    isBubbleData: false,
    chartContainerProps: { marginTop: true },
    width: 2,
    tooltipFormat: (value: any) => `${roundToTwoDecimals(value)} `,
  },
  {
    id: 'avgCSAT',
    title: 'CSAT',
    description: 'Average Customer Satisfaction Score',
    type: DataType.LINE,
    min: 0,
    max: 100,
    colors: chartColors,
    dataSource: 'Voice of Customer API',
    isBubbleData: false,
    chartContainerProps: { marginTop: true },
    width: 2,
  },
  // {
  //   id: 'avgCES',
  //   title: 'CES',
  //   description: 'Average Customer Effort Score',
  //   type: DataType.LINE,
  //   min: 1,
  //   max: 7,
  //   colors: chartColors,
  //   dataSource: 'Voice of Customer API',
  //   isBubbleData: false,
  //   chartContainerProps: { marginTop: true },
  //   width: 2,
  // },
  {
    id: 'avgNPS',
    title: 'NPS',
    description: 'Net Promoter Score',
    type: DataType.LINE,
    min: -100,
    max: 100,
    colors: chartColors,
    dataSource: 'Voice of Customer API',
    isBubbleData: false,
    chartContainerProps: { marginTop: true },
    width: 2,
  },
  {
    id: 'avgSR',
    title: 'SR',
    description: 'Average Customer Success Rate',
    type: DataType.LINE,
    min: 0,
    max: 100,
    colors: chartColors,
    dataSource: 'Voice of Customer API',
    isBubbleData: false,
    chartContainerProps: { marginTop: true },
    width: 2,
    yFormat: (value: any) => `${value}%`,
  },
]

function buildData(data: FeedbackBucketedByTimestamp[]) {
  const dataWithValues = data.map(node => {
    return omitBy(node, isNil)
  })
  const dataKeys = Object.keys(nameMappings)

  const filteredKeys = dataKeys.filter(key => dataWithValues.some(values => values[key]))
  const filteredCharts = baseCharts.filter(chart =>
    ['sentiment', ...filteredKeys].includes(chart.id)
  )
  return filteredCharts.map(chart => ({
    ...chart,
    'data-pho': `${chart.id}Chart`,
    rawDataArray:
      chart.id === 'sentiment' ? buildAWSAverageData(data) : buildAverageData(data, chart.id),
  }))
}

interface MultiChartProps {
  data: FeedbackBucketedByTimestamp[]
}

export default MultiChart
