import * as React from 'react'

function SvgSpeakerMute(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 24" {...props}>
      <path
        d="M6.697 7H3a3.003 3.003 0 00-3 3v5a3.003 3.003 0 003 3h3.741L17 23.7V.131L6.697 7zM2 15v-5a1 1 0 011-1h3v7H3a1 1 0 01-1-1zm13 5.3l-7-3.888V8.535l7-4.667v16.433zM28.707 8.708l-1.414-1.414L24 10.586l-3.293-3.293-1.414 1.414L22.586 12l-3.293 3.293 1.414 1.414L24 13.414l3.293 3.293 1.414-1.414L25.414 12l3.293-3.293z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSpeakerMute
