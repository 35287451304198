import React, { ReactNode, ComponentType } from 'react'
import * as S from './styles'
import Icon, { IconName } from '../icon'
import { useDarkMode } from 'components/App/Providers/DarkMode'

export interface LinkProps extends React.PropsWithoutRef<JSX.IntrinsicElements['a']> {
  /** The text or wrapped elements for the link. */
  children: ReactNode
  /** Choose between the inline style of link (underlined by default) and standalone (not underlined by default.) */
  variant?: 'inline' | 'standalone'
  /** Used to disable the link from being clicked. */
  disabled?: boolean
  /** (Not recommended, as can poorly impact screen readers.) Used to disable the darker coloration for visited links. */
  disableVisited?: boolean
  /** (Not recommended, as can poorly impact screen readers.) Used to disable the hover underline for standalone links. */
  disableUnderline?: boolean
  /** Opens the link in a new tab (or new window, depending on the browser) and adds the "ki-linkout" icon, automatically. */
  newTab?: boolean
  /** Use to override the underlying <a> element with a custom component (like React Router or Reach Router's "Link" component.) */
  as?: ComponentType<any>
  /** If using a React Router or Reach Router Link component in the "as" prop, you can pass your route string here. */
  to?: string
  /** The traditional "href" prop for anchor elements. (Not to be used in conjunction with the "to" prop.) */
  href?: string
  /** Used to hook into the click event. (Note: It's bad UX to use the onClick for navigation, as the user cannot hover over the link to see where it will take them. Use in conjunction with the "href" or "to" props to perform additional behind the scenes actions on a link click.) */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  /** Used to add an icon to the right of the link. Set "true" for a Chevron icon pointing right, or type a Kite icon name. (Only used on "standalone" variants.) */
  icon?: boolean | IconName
  /** Specify the icon size. Only used if an icon is specified */
  iconSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export default ({
  children,
  variant = 'inline',
  disabled = false,
  disableVisited = false,
  newTab = false,
  iconSize = 'xs',
  disableUnderline = false,
  to,
  href,
  onClick,
  icon,
  ...props
}: LinkProps) => {
  const { isDarkMode } = useDarkMode()
  const target = newTab ? { target: '_blank' } : {}
  const customIconName = typeof icon === 'boolean' ? 'ChevronRight' : icon
  const iconName = newTab ? 'Linkout' : customIconName
  return (
    <S.Link
      $variant={variant}
      $isDarkMode={isDarkMode}
      $disableVisited={disabled || disableVisited}
      $disableUnderline={disableUnderline}
      disabled={disabled}
      to={disabled ? undefined : to}
      href={disabled ? undefined : href}
      onClick={disabled ? undefined : onClick}
      {...target}
      {...props}
    >
      {children}
      {variant === 'standalone' && iconName && (
        <Icon
          name={iconName}
          size={iconName === 'ChevronRight' || iconName === 'Linkout' ? '12px' : iconSize}
          margin={{ left: iconName === 'ChevronRight' ? 'xxs' : 'xs' }}
        />
      )}
    </S.Link>
  )
}
