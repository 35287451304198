import * as React from 'react'

function SvgPlayerInPlayerF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 0a3.009 3.009 0 013 3v18a3.009 3.009 0 01-3 3H0V3a3.009 3.009 0 013-3zm-1 11H12v9h12v-9zm-2 2v5h-8v-5h8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPlayerInPlayerF
