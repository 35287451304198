import React from 'react'
import {
  FilterMenuOpenCloseStateContext,
  FilterMenuOpenCloseStateValue,
} from 'components/App/Providers/FilterMenuOpenCloseState'

export default function useFilterMenuOpenCloseState(): FilterMenuOpenCloseStateValue {
  const {
    isFilterMenuOpen,
    setIsFilterMenuOpen,
    toggleFilterMenu,
    closeFilterMenu,
    filterMenuPosition,
    setFilterMenuPosition,
  } = React.useContext(FilterMenuOpenCloseStateContext)

  return {
    isFilterMenuOpen,
    setIsFilterMenuOpen,
    toggleFilterMenu,
    closeFilterMenu,
    filterMenuPosition,
    setFilterMenuPosition,
  }
}
