import * as React from 'react'

function SvgZoom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 27" {...props}>
      <path
        d="M3.83 3.831C8.19-.531 15.215-.684 19.763 3.484c4.547 4.168 5.005 11.179 1.038 15.903l5.906 5.906-1.414 1.414-5.906-5.906c-4.724 3.968-11.734 3.51-15.903-1.037-4.169-4.547-4.016-11.57.346-15.933zM12 2.5a9.5 9.5 0 000 19 9.51 9.51 0 009.5-9.5A9.5 9.5 0 0012 2.5zM13 7v4h4v2h-4v4h-2v-4H7v-2h4V7h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgZoom
