import * as React from 'react'

function SvgLinkBroken(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M4.838 9.207l1.408 1.409-2.816 2.817a5.133 5.133 0 000 7.144 5.129 5.129 0 007.142 0l2.816-2.817 1.408 1.409-2.816 2.817A6.94 6.94 0 017.05 24a6.939 6.939 0 01-5.03-2.013 7.146 7.146 0 010-9.961l2.817-2.818zm.768-5.009l14.225 14.23-1.422 1.423L4.184 5.621l1.422-1.423zm3.491 9.163l1.423 1.423-2.845 2.846-1.422-1.423 2.844-2.846zm12.84-11.298a7.046 7.046 0 010 9.962l-2.816 2.817-1.408-1.409 2.816-2.817a5.133 5.133 0 000-7.144 5.129 5.129 0 00-7.141 0l-2.816 2.817L9.163 4.88l2.817-2.817a7.04 7.04 0 019.958 0zM16.24 6.42l1.423 1.423-2.845 2.846-1.423-1.423 2.845-2.846z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLinkBroken
