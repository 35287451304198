import * as React from 'react'

function SvgMailF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M0 .54l14 12.82L28 .55V21a3.009 3.009 0 01-3 3H3a3.009 3.009 0 01-3-3V.54zM25.64 0C21.83 3.47 15.97 8.83 14 10.64L2.37 0h23.27z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMailF
