import * as React from 'react'

function SvgUpgrade(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M24 12h-7.06v12H7.06V12H0L12 0l12 12zM2 26h20a2 2 0 012 2H0a2 2 0 012-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgUpgrade
