import React from 'react'
import { Dropdown } from 'components/shared/Dropdown'
import MenuList from '@material-ui/core/MenuList'
import GroupList from './GroupList'
import * as S from './styles'
import PopoverMenu from 'components/shared/popover'

function GroupSwitcher() {
  return (
    <>
      <div data-pho="topbarGroupSwitcher">
        <PopoverMenu anchorContent={<Dropdown label="Groups" placeholder="" items={[]} isInline />}>
          {({ isPopoverOpen, handleClose }) =>
            isPopoverOpen && (
              <S.MenuContainer>
                <MenuList>
                  <GroupList handleGroupSwitcherClose={handleClose} />
                </MenuList>
              </S.MenuContainer>
            )
          }
        </PopoverMenu>
      </div>
    </>
  )
}

export default GroupSwitcher
