import styled, { css } from 'styled-components'
import chroma from 'chroma-js'
import { colors } from 'components/shared/utils/colors'
import { durations, timingFunctions } from 'components/shared/utils/transition'
import { element } from 'components/shared/utils/spacing'
import { fontStyles } from '../typography'

export const ButtonContent = styled.span`
  ${fontStyles};
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  line-height: 44px;
  padding: 0 15px;
  border: 1px solid transparent;
  box-sizing: border-box;
`

export interface StyledButtonProps {
  $width: string
  $hasText: boolean
  $isDarkMode?: boolean
  $iconColor?: string
  $color?: string
}

const StyledButton = styled.button<StyledButtonProps>`
  min-width: 120px;
  width: ${({ $width }) => $width};
  height: 44px;
  padding: 0;
  border: none;
  border-radius: ${({ $hasText }) => ($hasText ? '4px' : '50%')};
  box-sizing: border-box;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: ${durations.short};
  transition-timing-function: ${timingFunctions.standard};

  /* Disable outline when click-focused */
  :focus,
  > ${ButtonContent} {
    outline: none;
  }

  /* Enable outline when tab-focused */
  :focus > ${ButtonContent} {
    border-radius: ${({ $hasText }) => ($hasText ? '4px' : '50%')};
    box-shadow: ${({ $isDarkMode }) => ($isDarkMode ? colors.white : colors.blue20)} 0px 0px 2px 2px;
  }

  /* Interactions */
  :hover {
    background-color: ${({ $color }) =>
      $color ? chroma($color).darken(0.5).hex() : colors.blue30};
  }

  :active {
    background-color: ${({ $color }) =>
      chroma($color || colors.blue30)
        .darken(0.5)
        .hex()};
  }
`

export const CustomIcon = styled.span<{ hasText: boolean }>`
  display: inline-flex;
  margin-right: ${props => props.hasText && element.md};
`

const transparentStyle = css<{ $isDarkMode?: boolean }>`
  background-color: transparent;

  /* Interactions */
  :hover {
    background-color: rgba(
      ${({ $isDarkMode }) =>
        chroma($isDarkMode ? colors.white : colors.blue20)
          .alpha(0.1)
          .rgba()
          .join(',')}
    );
  }

  :active {
    background-color: rgba(
      ${({ $isDarkMode }) =>
        chroma($isDarkMode ? colors.white : colors.blue20)
          .alpha(0.2)
          .rgba()
          .join(',')}
    );
  }
`

export const PrimaryButton = styled(StyledButton)`
  background-color: ${({ $color }) => $color || colors.blue20};

  ${ButtonContent} {
    color: ${colors.white};
  }

  svg,
  circle {
    color: ${({ $iconColor }) => $iconColor || colors.white};
    stroke: ${({ $iconColor }) => $iconColor || colors.white};
  }
`

export const PrimaryButtonLightDisabled = styled(PrimaryButton)`
  cursor: not-allowed;
  background-color: ${colors.gray20};

  ${ButtonContent} {
    color: ${colors.gray25};
  }

  svg {
    color: ${({ $iconColor }) => $iconColor || colors.gray25};
    stroke: ${({ $iconColor }) => $iconColor || colors.gray25};
  }

  :hover,
  :active {
    background-color: ${colors.gray20};
  }
`

export const PrimaryButtonDarkDisabled = styled(PrimaryButton)`
  cursor: not-allowed;
  background-color: ${colors.darkBlue10};

  ${ButtonContent} {
    color: ${colors.gray30};
  }

  svg {
    color: ${({ $iconColor }) => $iconColor || colors.gray30};
    stroke: ${({ $iconColor }) => $iconColor || colors.gray30};
  }

  :hover,
  :active {
    background-color: ${colors.darkBlue10};
  }
`

export const SecondaryButtonLight = styled(StyledButton)`
  ${transparentStyle};
  border: 1px solid ${colors.blue20};

  ${ButtonContent} {
    color: ${colors.blue20};
  }

  svg,
  circle {
    color: ${({ $iconColor }) => $iconColor || colors.blue20};
    stroke: ${({ $iconColor }) => $iconColor || colors.blue20};
  }
`

export const SecondaryButtonDark = styled(SecondaryButtonLight)`
  border: 1px solid ${colors.white};

  ${ButtonContent} {
    color: ${colors.white};
  }

  svg,
  circle {
    color: ${({ $iconColor }) => $iconColor || colors.white};
    stroke: ${({ $iconColor }) => $iconColor || colors.white};
  }
`

export const SecondaryButtonDisabled = styled(SecondaryButtonLight)`
  cursor: not-allowed;
  border: 1px solid ${colors.gray25};

  ${ButtonContent} {
    color: ${colors.gray25};
  }

  svg {
    color: ${({ $iconColor }) => $iconColor || colors.gray25};
    stroke: ${({ $iconColor }) => $iconColor || colors.gray25};
  }

  :hover,
  :active {
    background-color: transparent;
  }
`

export const NeutralButtonLight = styled(StyledButton)`
  ${transparentStyle};

  ${ButtonContent} {
    color: ${colors.black};
  }

  svg,
  circle {
    color: ${({ $iconColor }) => $iconColor || colors.black};
    stroke: ${({ $iconColor }) => $iconColor || colors.black};
  }
`

export const NeutralButtonDark = styled(NeutralButtonLight)`
  ${ButtonContent} {
    color: ${colors.white};
  }

  svg,
  circle {
    color: ${({ $iconColor }) => $iconColor || colors.white};
    stroke: ${({ $iconColor }) => $iconColor || colors.white};
  }
`

export const NeutralButtonDisabled = styled(NeutralButtonLight)`
  cursor: not-allowed;

  ${ButtonContent} {
    color: ${colors.gray25};
  }

  svg {
    color: ${({ $iconColor }) => $iconColor || colors.gray25};
    stroke: ${({ $iconColor }) => $iconColor || colors.gray25};
  }

  :hover,
  :active {
    background-color: transparent;
  }
`

export const BorderlessButtonLight = styled(StyledButton)`
  ${transparentStyle};

  ${ButtonContent} {
    color: ${colors.blue20};
  }

  svg,
  circle {
    color: ${({ $iconColor }) => $iconColor || colors.blue20};
    stroke: ${({ $iconColor }) => $iconColor || colors.blue20};
  }
`

export const BorderlessButtonDark = styled(BorderlessButtonLight)`
  ${ButtonContent} {
    color: ${colors.white};
  }

  svg,
  circle {
    color: ${({ $iconColor }) => $iconColor || colors.white};
    stroke: ${({ $iconColor }) => $iconColor || colors.white};
  }
`

export const BorderlessButtonDisabled = styled(BorderlessButtonLight)`
  cursor: not-allowed;

  ${ButtonContent} {
    color: ${colors.gray25};
  }

  svg {
    color: ${({ $iconColor }) => $iconColor || colors.gray25};
    stroke: ${({ $iconColor }) => $iconColor || colors.gray25};
  }

  :hover,
  :active {
    background-color: transparent;
  }
`
