import styled, { css } from 'styled-components'
import MuiSelect from '@material-ui/core/Select'
import { IconButton } from 'components/shared/IconButton'
import { colors } from 'components/shared/utils/colors'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

export const RoundedContainer = styled.div<{ hasSearchBy: boolean }>`
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  padding: 0.5rem 0;
  border: solid 1px ${colors.gray20};
  border-radius: 21px;
  background: ${colors.white};
  overflow: hidden;

  ${props =>
    props.hasSearchBy &&
    css`
      padding: 0.25rem 0;
    `}
`

export const SearchBy = styled.div`
  padding: 0.25rem 0 0.25rem 1rem;
  margin: -0.25rem 0;
  background: ${colors.blue20};
  font-weight: 500;
  color: ${colors.white};
  display: flex;
  align-items: center;

  svg {
    color: ${colors.white};
  }
`

export const Select = styled(MuiSelect)`
  && {
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 500;
    color: ${colors.white};
  }
`

export const StyledInput = styled.input`
  flex: 1;
  line-height: normal;
  border: none;
  background: transparent;
  margin-left: 0.75rem;
  padding-right: 8.5rem;

  :focus {
    outline: none;
  }
`

export const StyledIconButton = styled(IconButton)<{ disabled: boolean }>`
  && {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: ${props => (props.disabled ? colors.gray20 : colors.blue20)};
  }
`

export const SearchByTitle = styled(InputLabel)`
  margin-right: 0.25rem;
  color: ${colors.white};
`

export const SearchStatus = styled.div`
  position: absolute;
  right: 2.75rem;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  > * {
    margin-left: 0.5rem;
  }
  > svg {
    width: 0.8em;
    height: 0.8em;
  }
`

export const SearchStatusValid = styled(SearchStatus)`
  color: ${colors.green10};
  opacity: 0.7;
`

export const SearchStatusInvalid = styled(SearchStatus)`
  color: ${colors.yellow10};
`

export const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  padding-left: 2rem;
`

export const SeqRangeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const StyledFormControl = styled(FormControl as any)`
  min-width: ${props => (props.fullWidth ? '100%' : '12rem')};
`

export const InputContainer = styled.div`
  display: flex;
`

export const SeqRangeActions = styled.div`
  display: flex;
`

export const StyledAlertContainer = styled.div`
  margin-bottom: 1rem;
`
