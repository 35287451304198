import * as React from 'react'

function SvgKey(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 30" {...props}>
      <path
        d="M2.856 3.854A8.592 8.592 0 0118 9.504a9.172 9.172 0 01-.13 1.47c-.04.18-.07.31-.1.43-.054.25-.125.498-.21.74L29 23.584v5.42h-5.41L22 27.414v-2.01l-.42-.41h-2L18 23.404v-2l-.42-.41h-2l-3.44-3.44c-.255.09-.515.163-.78.22a2.21 2.21 0 01-.46.1 8.592 8.592 0 01-8.044-14.02zm13.794 10.21a8.253 8.253 0 01-1.49 1.75c-.11.1-.23.2-.35.3-.13.1-.27.21-.41.3-.102.08-.21.153-.32.22l-.02.01 2.35 2.35h2l1.59 1.59v2l.41.41h2l1.59 1.58v2.01l.41.42H27v-2.59zm-4.9-10.66a6.493 6.493 0 10-1.19 12.5.777.777 0 00.2-.04l.13-.03c.252-.051.5-.121.74-.21a1.82 1.82 0 00.21-.08l.17-.08a6.28 6.28 0 003.45-3.45l.1-.18c.05-.13.1-.26.14-.4l.01-.04c.04-.14.08-.29.11-.44l.04-.15.03-.12a6.493 6.493 0 00-4.14-7.28zM8 4.996a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgKey
