import * as React from 'react'

function SvgComposeF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M16 0v2H3a1 1 0 00-1 1v21h21a1 1 0 001-1V10h2v13a3.003 3.003 0 01-3 3H0V3a3.003 3.003 0 013-3h13zm9.08.92c.594.581.926 1.379.92 2.21 0 .83-.332 1.625-.92 2.21L11.63 18.79l-6.05 1.63 1.63-6.05L20.66.92a3.127 3.127 0 014.42 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgComposeF
