import Flex from 'components/shared/flex/Flex'
import NucleusReactDatePicker from 'components/shared/DatePicker'
import DateInputs from './DateInputs'
import * as S from '../styled'
import * as T from '../types'

export default function VoCTimeMenu({
  periods,
  handlePeriodClick,
  isPeriodSelected,
  inputRange,
  handleAbsoluteTimeChange,
  getDefaultAbsoluteSelectedRange,
  error,
  setInputRange,
}: {
  periods: T.Period[]
  handlePeriodClick: Function
  isPeriodSelected: Function
  inputRange?: { from?: Date; to?: Date }
  handleAbsoluteTimeChange: (newPeriod: { from: string | Date; to: string | Date }) => void
  getDefaultAbsoluteSelectedRange: Function
  error: Nullable<string>
  setInputRange: React.Dispatch<
    React.SetStateAction<
      | {
          from?: Date | undefined
          to?: Date | undefined
        }
      | undefined
    >
  >
}) {
  return (
    <Flex>
      <Flex direction="column" minWidth="20%" padding="1rem">
        {periods.map((period: T.Period) => (
          <S.VoCTimeListItem
            onClick={handlePeriodClick(period)}
            data-test="timePeriodValue"
            data-pho="timeMenuPeriodvalue"
            isActive={isPeriodSelected(period)}
          >
            {period.label}
          </S.VoCTimeListItem>
        ))}
      </Flex>
      <Flex justifyContent="center" column>
        <S.DatePickerContainer isVoC>
          <NucleusReactDatePicker
            key={JSON.stringify(inputRange)}
            onSelectDate={(newPeriod: { from: string; to: string }) =>
              handleAbsoluteTimeChange(newPeriod)
            }
            defaultRange={getDefaultAbsoluteSelectedRange(null)}
            isDoubleCalendar
          />
          {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
        </S.DatePickerContainer>
        <DateInputs
          defaultAbsoluteSelectedRange={getDefaultAbsoluteSelectedRange(true)}
          handleAbsoluteTimeChange={handleAbsoluteTimeChange}
          setInputRange={setInputRange}
          inputRange={inputRange}
        />
      </Flex>
    </Flex>
  )
}
