import React, { useState, useRef, useEffect } from 'react'
import { isEmpty } from 'lodash'
import ChartsHeading from 'components/shared/chart/ChartsHeading'
import ChartWarnings from 'components/shared/chart/ChartWarnings'

import { checkIfDateIncludedInPeriod } from 'components/shared/chart/utils'
import { getFullElementHeight } from 'utils'
import * as S from './styled'
import * as T from '../types'

export default function ChartContainer({
  chartTitle,
  component,
  title,
  chartsHeadingProps,
  showWarning,
  unappliedFilterLabels,
  metric,
  dataArray,
  tabButtons,
  isExtendContentActive,
  chartType,
}: any) {
  const [loaderHeight, setLoaderHeight] = useState(0)
  const chartContainerRef = useRef(null)
  const loaderContainerRef = useRef(null)

  useEffect(() => {
    if (isExtendContentActive) {
      const fullLoaderHeight = getFullElementHeight(chartContainerRef, loaderContainerRef)
      setLoaderHeight(fullLoaderHeight)
    }
  }, [isExtendContentActive])

  // This warning is based on this ticket https://gitlab.spectrumflow.net/client-analytics/prism/-/issues/1299
  const showUniqueUserWarning = metric?.includes('_users') && checkIfDateIncludedInPeriod(dataArray)
  const hasWarning = showUniqueUserWarning || showWarning || !isEmpty(unappliedFilterLabels)
  const warnings = hasWarning ? (
    <ChartWarnings
      showUniqueUserWarning={showUniqueUserWarning}
      showWarning={showWarning}
      unappliedFilterLabels={unappliedFilterLabels}
    />
  ) : (
    false
  )
  const multiChartHeaderWithTitleAndDescription = (
    <>
      <S.MultiChartDescriptionContainer>
        <ChartsHeading
          warnings={warnings}
          data-pho={chartsHeadingProps.title}
          {...chartsHeadingProps}
        />
      </S.MultiChartDescriptionContainer>
      {tabButtons}
    </>
  )

  const multiChartHeaderWithoutTitleAndDescription = (
    <S.MultiChartDescriptionContainer>{tabButtons}</S.MultiChartDescriptionContainer>
  )

  const multiChartHeader =
    chartsHeadingProps.title || chartsHeadingProps.description
      ? multiChartHeaderWithTitleAndDescription
      : multiChartHeaderWithoutTitleAndDescription
  const singleChartHeader = (
    <ChartsHeading
      warnings={warnings}
      data-pho={chartsHeadingProps.title}
      {...chartsHeadingProps}
    />
  )
  return (
    <S.Container ref={chartContainerRef} data-pho={`chart${chartsHeadingProps.title}`}>
      <S.TopContainer>
        <S.TopContent>
          <S.ChartDescriptionContainer data-pho={chartTitle}>
            {tabButtons ? multiChartHeader : singleChartHeader}
          </S.ChartDescriptionContainer>
        </S.TopContent>
      </S.TopContainer>
      <>
        <S.BottomContainer
          ref={loaderContainerRef}
          height={loaderHeight}
          data-pho={`chartType:${T.DataType[chartType] ?? T.DataType[component?.props?.chartType]}`}
        >
          {component}
        </S.BottomContainer>
      </>
    </S.Container>
  )
}
