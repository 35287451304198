import * as React from 'react'

function SvgMinusAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 4" {...props}>
      <path d="M0 0h28v4H0z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default SvgMinusAlt
