import * as React from 'react'

function SvgOtherF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M13 12l.784 3.137A3.983 3.983 0 0115 18c0 1.08-.44 2.113-1.216 2.863L13 24H9l-.784-3.137a3.978 3.978 0 010-5.726L9 12h4zm13-7a2 2 0 012 2v15a2 2 0 01-2 2h-7a2 2 0 01-2-2V7a2 2 0 012-2h7zM11 16a2 2 0 100 4 2 2 0 000-4zM28 0v3h-2V2H2v15h3v2H0V0h28z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgOtherF
