import * as React from 'react'

function SvgCheckmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 18" {...props}>
      <path
        d="M9 18.061L.293 9.354l1.414-1.415L9 15.232 24.293-.061l1.414 1.415z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCheckmark
