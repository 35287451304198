import React from 'react'
import isNil from 'lodash/isNil'
import numeral from 'numeral'
import Flex from '../flex/Flex'
import Typography from '../typography/Typography'
import useFilterValues from 'hooks/useFilterValues'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import { CustomMetricProperties } from 'screens/entityID/client-analytics/metrics/shared/types'
import * as S from './styles'

const ONE_MILLISECOND = 60000
const ONE_HOUR = 3600000
const ONE_DAY = 86400000

export default function AggregateChart({
  data,
  title,
  customProperties,
  displayOnlyTotal,
}: {
  data: { data: { avg: number; max: number; min: number; total: number } }
  title?: string
  mini?: boolean
  customProperties?: CustomMetricProperties
  displayOnlyTotal?: boolean
}) {
  const { step } = useFilterValues()

  if (isNil(data)) {
    return <NoDataChartMessage title={title} />
  }

  function getStepValue(step: number) {
    if (step < ONE_HOUR) return step / ONE_MILLISECOND
    if (step < ONE_DAY) return step / ONE_HOUR
    return step / ONE_DAY
  }

  function getStepLabel(step: number) {
    if (step < ONE_HOUR) return 'minutes'
    if (step < ONE_DAY) return 'hours'
    return 'days'
  }

  const stepValue = getStepValue(step)
  const stepLabel = stepValue === 1 ? getStepLabel(step).slice(0, -1) : getStepLabel(step)

  return (
    <Flex width="100%" height="100%" direction="column">
      <Flex
        width="100%"
        height={displayOnlyTotal ? '100%' : '50%'}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography>
          <h4>TOTAL</h4>
        </Typography>
        <S.StyledTotal>{numeral(data.data.total).format('0,0')}</S.StyledTotal>
      </Flex>
      {!displayOnlyTotal && (
        <Flex width="100%" height="10%" alignItems="center" justifyContent="center">
          <Typography>
            <p>{`Per ${stepValue} ${stepLabel}`}</p>
          </Typography>
        </Flex>
      )}
      {!displayOnlyTotal && (
        <Flex
          width="100%"
          height="40%"
          direction="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <Flex direction="column" gap="1rem" alignItems="center">
            <S.StyledStat>{numeral(data.data.min).format('0,0')}</S.StyledStat>
            <Typography>
              <h4>MIN</h4>
            </Typography>
          </Flex>
          <Flex direction="column" gap="1rem" alignItems="center">
            <S.StyledStat>{numeral(data.data.avg).format('0,0')}</S.StyledStat>
            <Typography>
              <h4>AVG</h4>
            </Typography>
          </Flex>
          <Flex direction="column" gap="1rem" alignItems="center">
            <S.StyledStat>{numeral(data.data.max).format('0,0')}</S.StyledStat>
            <Typography>
              <h4>MAX</h4>
            </Typography>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
