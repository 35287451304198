import Tooltip from '@material-ui/core/Tooltip'
import { Button } from 'components/shared/Button'
import { IconName } from 'components/shared/icon'
import { IconButton } from 'components/shared/IconButton'

function ButtonIconWithTooltip({
  title,
  buttonText,
  iconSize,
  icon,
  variant = 'primary',
  onClick,
  onClickParams = [],
  disabled = false,
}: ButtonIconWithTooltipProps) {
  return buttonText ? (
    <Tooltip title={title} placement="top" arrow>
      <div>
        <Button
          icon={icon}
          iconSize={iconSize}
          variant={variant}
          disabled={disabled}
          {...(onClick ? { onClick: () => onClick(...onClickParams) } : {})}
          children={buttonText}
          data-pho="buttonIconWithTooltip"
        />
      </div>
    </Tooltip>
  ) : (
    <Tooltip title={title} placement="top" arrow>
      <div>
        <IconButton
          icon={icon}
          size={iconSize}
          disabled={disabled}
          {...(onClick ? { onClick: () => onClick(...onClickParams) } : {})}
        />
      </div>
    </Tooltip>
  )
}

interface ButtonIconWithTooltipProps {
  title: string
  buttonText: string
  variant?: 'primary' | 'secondary' | 'borderless'
  icon: IconName
  iconSize: 'sm' | 'md' | 'lg'
  onClick?: Function
  onClickParams?: any[]
  disabled?: boolean
}

export default ButtonIconWithTooltip
