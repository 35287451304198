import * as React from 'react'

function SvgTicket(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 25" {...props}>
      <path
        d="M28 .004v9h-1a3 3 0 000 6h1v9H0v-9h1a3 3 0 000-6H0v-9h28zm-19 2H2v5.1a5 5 0 010 9.8v5.1h7V20h2v2.004h15v-5.1a5 5 0 010-9.8v-5.1H11V13H9V2.004zM11 15v3H9v-3h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTicket
