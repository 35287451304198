import * as React from 'react'

function SvgMapPointF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 29" {...props}>
      <path
        d="M11 0C4.926.004.004 4.926 0 11c0 8.38 9.95 16.44 10.38 16.78l.62.5.62-.5C12.05 27.44 22 19.38 22 11 21.996 4.926 17.074.004 11 0zm0 16a5 5 0 110-10 5 5 0 010 10z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMapPointF
