import React from 'react'
import get from 'lodash/get'
import PopoverMenu from 'components/shared/popover'
import Alert from 'components/shared/Alert'
import { Button } from 'components/shared/Button'
import Autocomplete from 'components/shared/Autocomplete'
import Typography from '../typography/Typography'
import { common } from 'components/shared/FilterMenu'
import { OptionProps } from 'components/shared/OptionGroup'
import * as S from './styled'
import * as U from './utils'

function CompareMenu({ values, updateValues }: CompareMenuProps) {
  const [selectedFeature, setSelectedFeature] = React.useState(values.compareFeature || null)
  const [selectedVersion, setSelectedVersion] = React.useState<Nullable<string>>(
    values.compareVersion || null
  )
  const [alertMessage, setAlertMessage] = React.useState('')

  function handleFeatureChange(feature?: any) {
    setAlertMessage('')
    setSelectedFeature(feature?.value)
    setSelectedVersion(null)
  }

  function handleVersionChange(feature?: OptionProps) {
    setSelectedVersion(get(feature, 'value', feature))
  }

  function handleUpdateValues() {
    if (
      (selectedFeature === 'anotherVersion' || selectedFeature === 'previousPeriodOtherVersion') &&
      !selectedVersion
    ) {
      setAlertMessage('Please select the compare version.')
    } else {
      setAlertMessage('')
      updateValues({
        ...values,
        compareFeature: selectedFeature,
        compareVersion: selectedVersion,
      })
    }
  }

  return (
    <PopoverMenu
      anchorContent={
        <>
          <Button
            data-pho="compareMenuButton"
            data-test="compareMenuButton"
            aria-haspopup="true"
            icon="IllustratorSwatch"
            iconSize="xs"
            variant="neutral"
          >
            Compare
          </Button>
        </>
      }
    >
      {({ isPopoverOpen }: { isPopoverOpen: boolean }) =>
        isPopoverOpen && (
          <S.CompareMenuContainer data-test="compareMenuContainer">
            {alertMessage && (
              <S.StyledAlertContainer data-test="alertContainer">
                <Alert children={alertMessage} variant="page-error" isPersistent />
              </S.StyledAlertContainer>
            )}
            <div>
              <Typography
                variant="title6"
                data-pho="compareVersionHeader"
                data-test="compareVersionHeader"
              >
                Compare version {values.releaseVersion} to:
              </Typography>
            </div>
            <S.AutocompleteContainer>
              <div data-pho="compareFeatureDropdown" data-test="compareFeatureDropdown">
                <Autocomplete
                  options={U.compareMenuFeatureMenuOptions}
                  onChange={handleFeatureChange}
                  placeholder="Choose Compare Feature"
                  data-test="compareFeatureAutocomplete"
                  canSelectMultipleValues={false}
                  defaultValue={U.compareMenuOptionLabels[selectedFeature]}
                  disableCloseOnSelect={false}
                  width="16rem"
                ></Autocomplete>
              </div>
              {(selectedFeature === 'anotherVersion' ||
                selectedFeature === 'previousPeriodOtherVersion') && (
                <div data-pho="compareVersionDropdown" data-test="compareVersionDropdown">
                  <Autocomplete
                    key={selectedFeature}
                    useGetOptions={common.useGetAppVersions}
                    onChange={handleVersionChange}
                    placeholder="Choose Compare Version"
                    data-test="compareVersionAutocomplete"
                    canSelectMultipleValues={false}
                    defaultValue={selectedVersion}
                    disableCloseOnSelect={false}
                    width="16rem"
                    filterOutOption={selectedFeature === 'anotherVersion' && values.releaseVersion}
                  ></Autocomplete>
                </div>
              )}
            </S.AutocompleteContainer>
            <S.ApplyButtonContainer>
              <Button
                data-pho="compareApplyButton"
                data-test="compareApplyButton"
                onClick={handleUpdateValues}
              >
                Apply
              </Button>
            </S.ApplyButtonContainer>
          </S.CompareMenuContainer>
        )
      }
    </PopoverMenu>
  )
}

export interface CompareMenuProps {
  values: Record<string, any>
  updateValues: (newVals: Record<string, any>) => void
}

export default CompareMenu
