import React from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Icon, { IconProps } from '../icon/Icon'
import { colors } from 'components/shared/utils/colors'

export const StyledDialog = styled(Dialog)`
  && {
    color: ${colors.black};
  }
`
export const StyledDialogContent = styled(({ entityList, ...props }) => (
  <DialogContent {...props} />
))`
  && {
    ${props => !props.entityList && 'padding: 0 3rem 3rem'};
  }
`
export const StyledDialogActions = styled(DialogActions)`
  && {
    justify-content: ${(props: { justifyActionsContent?: string }) => props.justifyActionsContent};
    padding: 0 3rem 3rem 3rem;
    margin: 0;
  }
`

export const StyledIcon = styled(Icon)<IconProps>`
  && {
    margin: 1.5rem 1.5rem 0.5rem 0;
    text-align: right;
    cursor: pointer;
  }
`

export const StyledDialogTitle = styled(({ justifyTitle, ...props }) => <DialogTitle {...props} />)`
  && {
    padding: 0 3rem 1.5rem;
    justify-content: ${props => props.justifyTitle};
    display: flex;
  }
  && h2 {
    margin-bottom: 1rem;
    line-height: 1.285714286;
    letter-spacing: -1px;
    font-size: 1.5rem;
    color: ${colors.black};
  }
`

export const IconContainer = styled.div`
  text-align: right;
`
