import * as React from 'react'

function SvgPrinterF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M23 6h-3V0H6v6H3a3.009 3.009 0 00-3 3v8a3.009 3.009 0 003 3h3v6h14v-6h3a3.009 3.009 0 003-3V9a3.009 3.009 0 00-3-3zM10 22v-2h6v2h-6zm6-4h-4v-2h4v2zm8-1a1.003 1.003 0 01-1 1h-3v-4h2v-2H4v2h2v4H3a1.003 1.003 0 01-1-1V9a1.003 1.003 0 011-1h20c.552.002.998.448 1 1v8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPrinterF
