import * as React from 'react'

function SvgSpeakerF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 24" {...props}>
      <path
        d="M6.7 7.08H3a3.009 3.009 0 00-3 3v5a3.002 3.002 0 003 3h3.74L17 23.78V.22L6.7 7.08zm-.7 9H3a.997.997 0 01-1-1v-5a1.003 1.003 0 011-1h3v7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSpeakerF
