import React from 'react'
import services from 'common/services'
import useAuth from 'hooks/useAuth'
import useApi from 'hooks/useApi'
import { Button } from 'components/shared/Button'
import Tooltip from '@material-ui/core/Tooltip'

function Active({ data, setButtonText }: { data: WebexProps; setButtonText: Function }) {
  const auth = useAuth()
  const { isLoading, error } = useApi({
    service: services.webexApi,
    method: 'POST',
    body: {
      path: window?.location?.pathname,
      title: document.title,
      type: data.type,
      user: auth?.user,
      message: data?.markdownMessage,
      roomName: data.roomName,
    },
  })
  if (error) {
    setButtonText('Could not connect to Webex')
    return <></>
  }
  if (isLoading) {
    setButtonText('Loading')
    return <></>
  }
  setButtonText('Webex session started')
  return <></>
}

function WebexButton(data: WebexProps) {
  const [chatStarted, setChatStarted] = React.useState(false)
  const [buttonText, setButtonText] = React.useState(data.text)
  return (
    <Tooltip title={data.tooltip} placement="top" aria-label="Webex support">
      <>
        {chatStarted && <Active data={data} setButtonText={setButtonText} />}
        <Button
          variant="primary"
          disabled={chatStarted}
          onClick={() => setChatStarted(true)}
          data-pho="webexButton"
        >
          {buttonText}
        </Button>
      </>
    </Tooltip>
  )
}

interface WebexProps {
  type: string
  text: string
  tooltip: string
  markdownMessage?: string
  developer?: Array<string>
  roomName: string
}

export default WebexButton
