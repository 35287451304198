import React from 'react'
import { Button } from 'components/shared/Button'
import * as S from './styles'

function WelcomeModalContent({ handleContinue }: WelcomeModalContentProps) {
  function handleClick() {
    handleContinue()
  }

  return (
    <S.Container>
      <S.WelcomeCopy>
        We notice that this is your first time visiting. Please take a moment to select some of your
        favorite applications.
      </S.WelcomeCopy>
      <Button onClick={handleClick} data-pho="welcomeModalContinueButton">
        Continue
      </Button>
    </S.Container>
  )
}

interface WelcomeModalContentProps {
  handleContinue: () => void
}

export default WelcomeModalContent
