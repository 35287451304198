import * as React from 'react'

function SvgDropdown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 24" {...props}>
      <path
        d="M8 0l7.207 7.207-1.414 1.414L8 2.828 2.207 8.621.793 7.207 8 0zm0 24L.793 16.793l1.414-1.414L8 21.172l5.793-5.793 1.414 1.414L8 24z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDropdown
