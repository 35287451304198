import 'normalize.css'
import 'index.css'
import React from 'react'
import { StylesProvider, ThemeProvider, createGenerateClassName } from '@material-ui/core/styles'
import { LocationProvider } from '@reach/router'
import { AllProviders } from 'components/App/Providers/All'
import Auth from './Auth'
import Data from './Data'
import Analytics from './Analytics'
import ScrollToTop from './ScrollToTop'
import Header from './Header'
import RightSidebar from './RightSidebar'
import FilterMenuOpenCloseState from './FilterMenuOpenCloseState'
import * as S from './styles'

const generateClassName = createGenerateClassName({
  productionPrefix: 'prismjss',
})

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <AllProviders>
      <StylesProvider injectFirst generateClassName={generateClassName}>
        <ThemeProvider theme={S.theme}>
          <S.GlobalStyle />
          <LocationProvider>
            <Auth>
              <Data>
                <Header>
                  <RightSidebar>
                    <FilterMenuOpenCloseState>
                      <Analytics>
                        <ScrollToTop>{children}</ScrollToTop>
                      </Analytics>
                    </FilterMenuOpenCloseState>
                  </RightSidebar>
                </Header>
              </Data>
            </Auth>
          </LocationProvider>
        </ThemeProvider>
      </StylesProvider>
    </AllProviders>
  )
}
