import * as React from 'react'

function SvgAmplifiedAudio(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 25" {...props}>
      <path
        d="M12.696 2.011a8.003 8.003 0 016.27-1.954A8.155 8.155 0 0126 8.294c0 1.952-.531 3.868-1.535 5.542l-.215.358A8.773 8.773 0 0023 18.706v1.122a5.132 5.132 0 01-4.513 5.149A5 5 0 0113 20h2a3.001 3.001 0 003.298 2.986A3.118 3.118 0 0021 19.828v-1.122c0-1.952.531-3.868 1.535-5.542l.215-.358A8.773 8.773 0 0024 8.294a6.234 6.234 0 00-5.268-6.251A6.003 6.003 0 0012 8h-2c0-2.29.982-4.47 2.696-5.989zm-8.506 1.8A12.979 12.979 0 018 13a12.98 12.98 0 01-3.81 9.19l-1.41-1.41A10.983 10.983 0 006 13a10.983 10.983 0 00-3.22-7.78zM1.36 6.64A8.972 8.972 0 014 13a8.972 8.972 0 01-2.64 6.36l-1.41-1.41A6.976 6.976 0 002 13 6.976 6.976 0 00-.05 8.05zM18 4a4 4 0 014 4h-2a2.003 2.003 0 00-2-2 2.003 2.003 0 00-2 2v1a4 4 0 110 8v-2a2 2 0 100-4v1h-2V8a4 4 0 014-4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAmplifiedAudio
