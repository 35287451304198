import * as React from 'react'

function SvgHome(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 27" {...props}>
      <path
        d="M23 27h-7v-7a3 3 0 00-6 0v7H3a3.003 3.003 0 01-3-3V10.5L13 .75l13 9.75V24a3.003 3.003 0 01-3 3zm-5-2h5a1 1 0 001-1V11.5L13 3.25 2 11.5V24a1 1 0 001 1h5v-5a5 5 0 0110 0v5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHome
