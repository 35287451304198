import * as React from 'react'

function SvgThermostat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14A14 14 0 0014 0zm4.5 21.8l-1-1.7a7 7 0 00-2.5-13V10h-2V7.1a7 7 0 00-2.5 13l-1 1.7a9 9 0 119 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgThermostat
