import styled, { css } from 'styled-components'
import MuiSelect from '@material-ui/core/Select'
import { IconButton } from 'components/shared/IconButton'
import { colors } from 'components/shared/utils/colors'

export const RoundedContainer = styled.div<{ hasSearchBy: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  padding: 0.5rem 0;
  border: solid 1px ${colors.gray20};
  border-radius: 21px;
  background: ${colors.white};
  overflow: hidden;

  ${props =>
    props.hasSearchBy &&
    css`
      padding: 0.25rem 0;
      flex: 1;
    `}
`

export const SearchBy = styled.div`
  padding: 0.25rem 0 0.25rem 1rem;
  margin: -0.25rem 0;
  background: ${colors.blue20};
  font-weight: 500;
  color: ${colors.white};
  display: flex;
  align-items: center;

  svg {
    color: ${colors.white};
  }
`

export const Select = styled(MuiSelect)`
  && {
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 500;
    color: ${colors.white};
  }
`

export const StyledInput = styled.input`
  flex: 1;
  line-height: normal;
  border: none;
  background: transparent;
  margin-left: 0.75rem;
  padding-right: 2.75rem;

  :focus {
    outline: none;
  }
`

export const StyledIconButton = styled(IconButton)`
  && {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`
export const SearchByTitle = styled.div`
  margin-right: 0.25rem;
`
