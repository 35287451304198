import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import Alert from 'components/shared/Alert'
import { TextInput } from 'components/shared/TextInput'
import { Button } from 'components/shared/Button'
import { Method } from 'axios'
import { axios, axiosConfigBase } from 'hooks/useApi'
import useApps from 'hooks/useApps'
import useGroups from 'hooks/useGroups'
import services from 'common/services'
import Autocomplete from 'components/shared/Autocomplete'
import { rootApiUrl } from 'common/constants'
import * as S from './styles'

function CreateGroup({ handleCloseModal }: any) {
  const { groups, fetchNewData } = useGroups()
  const { appsByDomain } = useApps()
  const [chosenApplications, setChosenApplications] = useState([])
  const [groupName, setGroupName] = useState('')
  const [groupDescription, setGroupDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [applicationsDomain, setApplicationsDomain] = useState()
  const [alertMessage, setAlertMessage] = useState('')

  async function addNewGroup() {
    if (isEmpty(groupName) || isEmpty(groupDescription) || isEmpty(applicationsDomain)) {
      setAlertMessage('Please fill out all fields')
    } else if (chosenApplications.length < 2) {
      setAlertMessage('Please choose at least 2 applications.')
    } else {
      setAlertMessage('')
      setIsLoading(true)
      const axiosConfig = {
        ...axiosConfigBase(),
        method: 'POST' as Method,
        url: `${rootApiUrl}${services.groups.url}/new`,
        data: {
          applicationIDs: chosenApplications,
          name: groupName,
          description: groupDescription,
        },
      }
      await axios({ ...axiosConfig })

      const newGroups = groups.concat([
        {
          applicationIDs: chosenApplications,
          name: groupName,
          description: groupDescription,
          _id: 'temporaryID',
        },
      ])

      fetchNewData(newGroups)
      handleCloseModal()
    }
  }

  function handleChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
    setGroupName(value)
  }

  function handleDescriptionChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
    setGroupDescription(value)
  }

  function handleApplicationChange(value: any) {
    setChosenApplications(value)
  }

  function handleApplicationTypeChange(value: any) {
    setApplicationsDomain(value)
    setChosenApplications([])
  }

  function getAppsByDomain() {
    if (applicationsDomain === 'Internal') {
      return appsByDomain.internal_apps
    } else if (applicationsDomain === 'Video') {
      return appsByDomain.video
    } else {
      return appsByDomain.portals
    }
  }

  return (
    <S.GroupContainer>
      {alertMessage && (
        <S.StyledAlertContainer>
          <Alert children={alertMessage} variant="page-error" isPersistent />
        </S.StyledAlertContainer>
      )}
      <S.ModalTitle variant="title6">Create New Group</S.ModalTitle>
      <S.NewGroupInputContainer>
        <TextInput
          width="100%"
          label="Group Name"
          hint="Add Group name"
          type="text"
          onChange={handleChange}
        />
        <TextInput
          width="100%"
          label="Group Description"
          hint="Add Group description"
          type="text"
          onChange={handleDescriptionChange}
        />
      </S.NewGroupInputContainer>
      <S.StyledAutocompleteContainer>
        <Autocomplete
          options={['SSPP', 'Video', 'Internal']}
          onChange={handleApplicationTypeChange}
          placeholder="Choose Applications domain"
          canSelectMultipleValues={false}
          disableCloseOnSelect={false}
          fullWidth={true}
          fullHeight={true}
        ></Autocomplete>
      </S.StyledAutocompleteContainer>
      <S.StyledAutocompleteContainer>
        <Autocomplete
          options={applicationsDomain ? getAppsByDomain() : []}
          onChange={handleApplicationChange}
          placeholder="Add Applications"
          value={chosenApplications}
          fullWidth={true}
          fullHeight={true}
        ></Autocomplete>
      </S.StyledAutocompleteContainer>
      <S.CreateNewGroupButtonContainer>
        <Button onClick={addNewGroup} loading={isLoading}>
          Create
        </Button>
      </S.CreateNewGroupButtonContainer>
    </S.GroupContainer>
  )
}

export default CreateGroup
