import React from 'react'
import slice from 'lodash/slice'
import numeral from 'numeral'
import { navigate } from '@reach/router'
import queryString from 'query-string'
import * as S from './styles'
import { getColor, getColumnCount } from './utils'

function GridChart({
  data,
  limit,
  firstRowPathObj,
  location,
}: {
  data: { 'API Name': string; 'Success Rate': number }[]
  limit?: number
  firstRowPathObj?: {
    pathName: string
    queryParam: string
    queryParamFromUrl?: string
    customQueryParams?: any
  }
  location?: any
}) {
  function handleSquareClick(apiName: string) {
    const extraQueryParamFromUrl = firstRowPathObj?.queryParamFromUrl || ''
    const query = queryString.parse((location && location.search) || '')

    if (firstRowPathObj?.customQueryParams) {
      const newQuery = queryString.stringify(
        {
          ...query,
          ...firstRowPathObj?.customQueryParams[apiName],
        },
        { encode: false }
      )
      navigate(`${firstRowPathObj.pathName}?${newQuery}`)
      return
    }

    const newQuery = queryString.stringify({
      ...query,
      [firstRowPathObj?.queryParam as any]: apiName,
      [extraQueryParamFromUrl]: query[extraQueryParamFromUrl as any],
    })
    if (firstRowPathObj) {
      navigate(`${firstRowPathObj.pathName}?${newQuery}`)
    }
  }

  const limitedData = slice(data, 0, limit)

  return (
    <S.Container>
      <S.GridHover>
        <S.GridContainer columnCount={getColumnCount(limitedData.length)}>
          {limitedData.map((item: any, index: number) => {
            return (
              <S.LightTooltip
                title={
                  <S.TooltipContainer>
                    <S.Colorblock color={getColor(item['Success Rate'])} />
                    {item['API Name']}: {item['Success Rate']}%
                  </S.TooltipContainer>
                }
                key={`Grid_Chart_Tooltip_${index}_${item['API Name']}`}
              >
                <S.GridSquare
                  color={getColor(item['Success Rate'])}
                  onClick={() => handleSquareClick(item['API Name'])}
                >
                  <S.GridNumber>{numeral(item['Success Rate']).format('0.0')}%</S.GridNumber>
                </S.GridSquare>
              </S.LightTooltip>
            )
          })}
        </S.GridContainer>
      </S.GridHover>
    </S.Container>
  )
}

export default GridChart
