import * as React from 'react'

function SvgLockUnlock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M10.99.073A7.001 7.001 0 0118.71 5h-2.12A5.005 5.005 0 007 7v3h17v15a3.009 3.009 0 01-3 3H3a3.009 3.009 0 01-3-3V10h5V7A7.001 7.001 0 0110.99.073zM22 12H2v13c.002.552.448.998 1 1h18a1.003 1.003 0 001-1V12zm-9 3v8h-2v-8h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLockUnlock
