import * as React from 'react'

function SvgRewind(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 20" {...props}>
      <path
        d="M26 .207l-10 6.15V.206L.09 9.996 16 19.786v-6.151l10 6.15V.207zM14 16.206L3.908 9.996 14 3.786v12.42zm10 0l-8-4.918V8.705l8-4.92v12.422z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRewind
