import * as React from 'react'

function SvgTablet(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 28" {...props}>
      <path
        d="M19 0a3.003 3.003 0 013 3v22a3.003 3.003 0 01-3 3H3a3.003 3.003 0 01-3-3V3a3.003 3.003 0 013-3zm1 6H2v19a1 1 0 001 1h16a1 1 0 001-1V6zm-6 16v2H8v-2h6zm5-20H3a1 1 0 00-1 1v1h18V3a1 1 0 00-1-1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTablet
