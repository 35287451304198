import styled from 'styled-components'

export const AppContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  min-width: 1000px;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
`
