import React from 'react'
import moment from 'moment'
import services from 'common/services'
import { rootApiUrl } from 'common/constants'
import useApi from 'hooks/useApi'
import { axios, axiosConfigBase } from 'hooks/useApi'
import useHeader from 'hooks/useHeader'
import useApps from 'hooks/useApps'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import Autocomplete from 'components/shared/Autocomplete'
import {
  GET_EXPERIMENTS_IDS,
  GET_FILTERED_VARIANTS_IDS,
} from 'components/shared/FilterMenu/queries'
import { times } from 'utils'
import * as S from './styled'
import * as T from './types'
import { getAppNames, getAppTypes } from 'utils/sharedUtils'
import useVariantNames from 'hooks/useVariantNames'

export const FILTER_WIDTH = '40rem'

function ExperimentAndVariantUuidsFilter({ filter }: { filter: any }) {
  const { currentApps } = useApps()
  const [options, setOptions] = React.useState<any>()
  const { filterValues, updateValues } = useHeader()
  const { variantNames } = useVariantNames()

  const [fromDate, toDate] = times.normalizeToAbsolutePeriod(filterValues.period).split(':')

  const appNames = getAppNames(currentApps)
  const appTypes = getAppTypes(currentApps)

  const defaultApiVariables = {
    appName: appNames,
    appType: appTypes,
    startDate: moment(parseInt(fromDate)).toISOString(),
    endDate: moment(parseInt(toDate)).toISOString(),
  }

  const [apiVariables] = React.useState(defaultApiVariables)
  const experimentData = useApi({
    service: services.distillery,
    query: GET_EXPERIMENTS_IDS,
    variables: {
      where: {
        AND: [
          {
            startTime: {
              gte: apiVariables.startDate,
            },
          },
          {
            startTime: {
              lte: apiVariables.endDate,
            },
          },
        ],
        experimentToProductFeatures: {
          every: {
            product: {
              is: {
                AND: [
                  {
                    quantumApplicationName: {
                      in: apiVariables.appName,
                    },
                  },
                  {
                    quantumApplicationType: {
                      in: apiVariables.appType,
                    },
                  },
                ],
              },
            },
          },
        },
      },
    },
  })

  async function fetchDataFilteredVariants(value: any) {
    const { data } = await axios({
      ...axiosConfigBase(),
      url: `${rootApiUrl}${services.distillery.url}`,
      method: 'POST',
      data: {
        query: GET_FILTERED_VARIANTS_IDS,
        variables: {
          where: {
            uuid: {
              in: [...value],
            },
            AND: [
              {
                startTime: {
                  gte: apiVariables.startDate,
                },
              },
              {
                startTime: {
                  lte: apiVariables.endDate,
                },
              },
            ],
            experimentToProductFeatures: {
              every: {
                product: {
                  is: {
                    OR: [
                      {
                        quantumApplicationName: {
                          in: apiVariables.appName,
                        },
                        quantumApplicationType: {
                          in: apiVariables.appType,
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
        },
      },
    })
    const experiments = get(data, 'data.experiments')
    const uuids = experiments?.reduce((acc: string[], value: Record<string, any>) => {
      const variants = get(value, 'variants')
      const variantUuids: string[] = variants?.map((variant: { uuid: string }) => {
        return variant.uuid
      })

      return acc.concat(variantUuids)
    }, [])

    if (!isEqual(filterValues.variantUuids, uuids)) {
      setOptions(experiments)
      updateValues && updateValues({ activatedExperiments: value, variantUuids: uuids })
    } else {
      updateValues && updateValues({ activatedExperiments: value })
    }
  }

  React.useEffect(() => {
    if (variantNames === undefined || !Object.keys(variantNames).length) return
    if (
      (options === undefined && !isEmpty(filterValues.activatedExperiments)) ||
      Object.keys(variantNames).length !== options?.variants?.length
    ) {
      const variantSet = new Set()
      Object.entries(variantNames).forEach(element => {
        variantSet.add({ uuid: element[0], name: element[1] })
      })
      const variantOptions = [{ variants: Array.from(variantSet) }]
      if (!isEqual(variantOptions, options)) {
        setOptions(variantOptions)
      }
    }
  }, [variantNames])

  function handleExperimentChange(value: any) {
    if (!isEmpty(value)) {
      fetchDataFilteredVariants(value)
    } else {
      setOptions([])
      updateValues && updateValues({ activatedExperiments: value })
    }
  }

  function handleVariantChange(value: any) {
    updateValues({ variantUuids: value })
  }

  const status: T.Status[] = ['RUNNING', 'COMPLETED', 'CANCELLED', 'DELETED', 'TESTING']
  const experiments = get(experimentData, 'data.experiments')
  const filteredExperimentDataByStatus = experiments?.filter((experiment: T.Experiment) =>
    status.includes(experiment.experimentStatus?.name)
  )

  const experimentDataOptions =
    filteredExperimentDataByStatus?.map((experiment: { name: string; uuid: string }) => {
      return { label: `${experiment.name} (${experiment.uuid})`, value: experiment.uuid }
    }) || []

  function getVariantDataOptions() {
    const variants =
      isEmpty(options) || !filterValues?.activatedExperiments?.length
        ? filteredExperimentDataByStatus
        : options

    return variants?.reduce((acc: string[], value: Record<string, any>) => {
      const variantUuids: string[] = value?.variants?.map(
        (variant: { name: string; uuid: string }) => {
          return { label: `${variant.name} (${variant.uuid})`, value: variant.uuid }
        }
      )
      return acc.concat(variantUuids)
    }, [])
  }

  function getOption(optionLabel: string) {
    const splitLabel = optionLabel.split('(')
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div>{splitLabel[0]}</div>
        <div>({splitLabel[1]}</div>
      </div>
    )
  }
  return (
    <S.ExperimentAndVariantUuidsContainer>
      <Autocomplete
        label="Experiment"
        onChange={value => handleExperimentChange(value)}
        options={experimentDataOptions}
        useGetOptions={filter.useGetOptions}
        getOptionLabel={filter.getOptionLabel}
        renderOption={(option: T.RenderOption) => {
          return getOption(option.label)
        }}
        value={experimentData.isLoading ? [] : filterValues.activatedExperiments || []}
        realtimeDashboardID={filter.realtimeDashboardID}
        isSequenceEventTypeFilter={filter.isSequenceEventTypeFilter}
        invertedLabel={filter.invertedLabel}
        fullWidth={filter.fullWidth}
        fullHeight
        width={FILTER_WIDTH}
        showTagTooltip
        data-pho="filterMenuAutoCompleteInput"
        isLoading={experimentData.isLoading}
      />
      <Autocomplete
        label="Variants"
        onChange={value => handleVariantChange(value)}
        options={getVariantDataOptions()}
        useGetOptions={filter.useGetOptions}
        getOptionLabel={filter.getOptionLabel}
        renderOption={(option: T.RenderOption) => {
          return getOption(option.label)
        }}
        realtimeDashboardID={filter.realtimeDashboardID}
        isSequenceEventTypeFilter={filter.isSequenceEventTypeFilter}
        invertedLabel={filter.invertedLabel}
        fullWidth={filter.fullWidth}
        fullHeight
        width={FILTER_WIDTH}
        showTagTooltip
        data-pho="filterMenuAutoCompleteInput"
        value={experimentData.isLoading ? [] : filterValues.variantUuids || []}
        isLoading={experimentData.isLoading}
      />
    </S.ExperimentAndVariantUuidsContainer>
  )
}

export default ExperimentAndVariantUuidsFilter
