import * as React from 'react'

function SvgCautionAlert(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0l14 28H0L14 0zm0 4.472L3.236 26h21.528L14 4.472zM14 20a2 2 0 110 4 2 2 0 010-4zm1.5-9v7h-3v-7h3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCautionAlert
