import * as React from 'react'

function SvgTrash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M14 0a3.003 3.003 0 013 3v1h7v2h-2.044l-.87 19.136A2.993 2.993 0 0118.088 28H5.912a2.994 2.994 0 01-2.997-2.865L2.045 6H0V4h7V3a3.003 3.003 0 013-3zm5.954 6H4.047l.866 19.045a.998.998 0 00.999.955h12.177a.997.997 0 00.998-.955L19.954 6zM17 9v14h-2V9h2zm-4 0v14h-2V9h2zM9 9v14H7V9h2zm5-7h-4a1 1 0 00-1 1v1h6V3a1 1 0 00-1-1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTrash
