import * as React from 'react'

function SvgArrowUpLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M25.707 24.293l-1.414 1.414L2 3.414V19H0V0h19v2H3.414z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgArrowUpLeft
