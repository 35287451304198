import {
  isDate,
  isThisYear as checkIfThisYear,
  isSameYear as checkIfSameYear,
  isSameMonth as checkIfSameMonth,
  isSameDay as checkIfSameDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  endOfWeek,
  endOfMonth,
  subHours,
  subDays,
  subWeeks,
  subMonths,
  startOfToday,
  startOfYesterday,
  endOfYesterday,
  toDate,
  format,
} from 'date-fns'
import parseDuration from 'parse-duration'
import humanizeDuration from 'humanize-duration'

export const DAY_IN_MILLISECONDS = 86400000
export const MONTH_IN_MILLISECONDS = 86400000 * 30
export const WEEK_IN_MILLISECONDS = 86400000 * 7
export const HALF_DAY_IN_MILLISECONDS = 86400000 / 2
export const HOUR_IN_MILLISECONDS = 86400000 / 24
export const HALF_HOUR_IN_MILLISECONDS = 86400000 / 48
export const TEN_MIN_IN_MILLISECONDS = 86400000 / 144
export const FIVE_MIN_IN_MILLISECONDS = 86400000 / 288
export const MIN_IN_MILLISECONDS = 86400000 / 1440

export type Range = string | EpochRange

export interface EpochRange {
  startMs: number
  endMs: number
}

export interface DateRange {
  startDate: Date
  endDate: Date
}

export interface RangeObj {
  rangeKey: String
  label: String
  isSelected: Function
  range: Function
}

export interface RangeSection {
  label: String
  sectionRanges: RangeObj[]
}

export type UtcOffset =
  | '-12'
  | '-11'
  | '-10'
  | '-09'
  | '-08'
  | '-07'
  | '-06'
  | '-05'
  | '-04'
  | '-03'
  | '-02'
  | '-01'
  | '+00'
  | '+01'
  | '+02'
  | '+03'
  | '+04'
  | '+05'
  | '+06'
  | '+07'
  | '+08'
  | '+09'
  | '+10'
  | '+11'
  | '+12'
  | '+13'
  | '+14'

export interface State {
  range: EpochRange | string
  step: number
  period?: string
  utcOffset: UtcOffset
  isPartialData: boolean
  isFiscalTime: boolean
  stepOptions?: Array<any>
}

export interface StaticRange {
  label: string
  isSelected: () => boolean
  range: any
}

export function checkIfIsEpochRange(range: unknown): boolean {
  const epochRange = range as EpochRange | undefined
  return typeof epochRange?.startMs === 'number' && typeof epochRange?.endMs === 'number'
}

export function checkIfIsDateRange(range: unknown): boolean {
  const dateRange = range as DateRange | undefined
  return isDate(dateRange?.startDate) && isDate(dateRange?.endDate)
}

export function convertToDurationMs(range: string | number | EpochRange | DateRange): number {
  if (typeof range === 'string') {
    const durationMsFromText = parseDuration(range as string)
    if (durationMsFromText === null) {
      throw new Error('Bad input received for duration abbreviation')
    }
    return durationMsFromText as number
  }
  if (typeof range === 'number') {
    return range as number
  }
  const isEpochRange = checkIfIsEpochRange(range)
  if (isEpochRange) {
    return (range as EpochRange).startMs - (range as EpochRange).endMs
  }
  const isDateRange = checkIfIsDateRange(range)
  if (isDateRange) {
    return (range as DateRange).startDate.valueOf() - (range as DateRange).endDate.valueOf()
  }
  throw new Error('Could not convert to millisecond duration')
}

export function convertToDateRange(range: string | number | EpochRange | DateRange): DateRange {
  if (!range) {
    return {
      startDate: null as any,
      endDate: null as any,
    }
  }

  const isEpochRange = checkIfIsEpochRange(range)
  if (isEpochRange) {
    return {
      startDate: toDate((range as EpochRange).startMs),
      endDate: toDate((range as EpochRange).endMs),
    }
  }
  const isDateRange = checkIfIsDateRange(range)
  if (isDateRange) {
    return {
      startDate: toDate((range as DateRange).startDate),
      endDate: toDate((range as DateRange).endDate),
    }
  }
  if (typeof range === 'string' || typeof range === 'number') {
    const durationMs = convertToDurationMs(range)
    return {
      startDate: toDate(Date.now() + durationMs),
      endDate: toDate(Date.now()),
    }
  }
  throw new Error('Could not convert to date range')
}

function getStepType(step: number) {
  if (step >= MONTH_IN_MILLISECONDS) {
    return 'mo'
  } else if (step >= DAY_IN_MILLISECONDS) {
    return 'd'
  } else if (step >= HOUR_IN_MILLISECONDS) {
    return 'h'
  } else return 'm'
}

export function constructDateRangeLabelFormats(
  dateRange: DateRange,
  step: number = DAY_IN_MILLISECONDS
): { start: string; end: string } {
  const stepType = getStepType(step)
  const isMonthStep = stepType === 'mo'
  const isDayOrBiggerStep = isMonthStep || stepType === 'd'
  const isHourOrSmallerStep = !isDayOrBiggerStep
  const isHourOrBiggerStep = isDayOrBiggerStep || stepType === 'h'
  const isSameYear = checkIfSameYear(dateRange.startDate, dateRange.endDate)
  const isSameMonth = checkIfSameMonth(dateRange.startDate, dateRange.endDate)
  const isSameDay = checkIfSameDay(dateRange.startDate, dateRange.endDate)
  const isThisYear = checkIfThisYear(dateRange.endDate)
  // Year
  const startYearFormat = isSameYear ? '' : 'y'
  const sameDayYearFormat = isSameDay && !isThisYear ? 'y' : ''
  const endYearFormat = sameDayYearFormat || (isSameYear && isThisYear) ? '' : 'y'
  // Month
  const startMonthFormat = 'MMM'
  const endMonthFormat =
    !isSameMonth || (isSameMonth && !isSameDay && isHourOrSmallerStep) ? 'MMM' : ''
  // Day
  const startDayFormat = isMonthStep ? '' : 'd'
  const endDayFormat = isMonthStep || isSameDay ? '' : 'd'
  // Hour
  const startHourFormat = isDayOrBiggerStep ? '' : 'h'
  const endHourFormat = isDayOrBiggerStep ? '' : 'h'
  // Minute
  const startMinuteFormat = isHourOrBiggerStep ? '' : ':mm'
  const endMinuteFormat = isHourOrBiggerStep ? '' : ':mm'
  // AM/PM
  const amPmFormat = isDayOrBiggerStep ? '' : 'aaa'
  // Separators
  const startHourSeparator = startHourFormat && ', '
  const startMonthDaySeparator = startMonthFormat && startDayFormat && ' '
  const startYearSeparator = startYearFormat && ', '
  const sameDayYearSeparator = sameDayYearFormat && ', '
  const endHourSeparator = endHourFormat && endDayFormat && ', '
  const endMonthDaySeparator = endMonthFormat && endDayFormat && ' '
  const endYearSeparator = endYearFormat && ', '
  // Combine
  const startMonthDay = `${startMonthFormat}${startMonthDaySeparator}${startDayFormat}`
  const startHourMinute = `${startHourSeparator}${startHourFormat}${startMinuteFormat}${amPmFormat}`
  const startFormat = `${startMonthDay}${sameDayYearSeparator}${sameDayYearFormat}${startHourMinute}${startYearSeparator}${startYearFormat}`
  const endMonthDay = `${endMonthFormat}${endMonthDaySeparator}${endDayFormat}`
  const endHourMinute = `${endHourSeparator}${endHourFormat}${endMinuteFormat}${amPmFormat}`
  const endFormat = `${endMonthDay}${endHourMinute}${endYearSeparator}${endYearFormat}`
  // Format
  if (startFormat === '' || endFormat === '') {
    throw new Error('The start and end date/time is the same for the provided range and step')
  }
  return { start: startFormat, end: endFormat }
}

export function constructDateRangeLabel({
  epochRange,
  step = DAY_IN_MILLISECONDS,
}: {
  epochRange: EpochRange
  step?: number
}): string {
  const zonedDateRange = convertToDateRange(epochRange)

  const isSameDay = checkIfSameDay(zonedDateRange.startDate, zonedDateRange.endDate)

  const isSameMonth = checkIfSameMonth(zonedDateRange.startDate, zonedDateRange.endDate)

  const isSameYear = checkIfSameYear(zonedDateRange.startDate, zonedDateRange.endDate)

  if (isSameDay && isSameMonth && isSameYear) {
    const stepType = getStepType(step)
    const startFormat = stepType === 'm' ? 'MMM d, y, hh:mmaaa' : 'MMM d, y, hhaaa'
    const endFormat = stepType === 'm' ? 'hh:mmaaa' : 'haaa'

    return `${format(zonedDateRange.startDate, startFormat)} - ${format(
      zonedDateRange.endDate,
      endFormat
    )}`
  } else {
    const dateRangeLabelFormats = constructDateRangeLabelFormats(zonedDateRange, step)

    const startText = new Date(zonedDateRange.startDate).toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
    })
    const endText = new Date(zonedDateRange.endDate).toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
    })

    return `${startText} - ${endText}`
  }
}

export function constructRelativeLabel(duration: string): string {
  const durationMs = convertToDurationMs(duration)
  const directionText = duration.includes('-') ? 'Last' : 'Next'

  return `${directionText} ${humanizeDuration(durationMs, {
    units: durationMs >= -86400000 ? ['h'] : ['y', 'mo', 'd'],
  })}`
}

export function constructButtonLabel({
  range,
  step = DAY_IN_MILLISECONDS,
}: {
  range: Range
  step?: number
}): string {
  const isEpochRange = checkIfIsEpochRange(range)

  return isEpochRange
    ? constructDateRangeLabel({
        epochRange: range as EpochRange,
        step,
      })
    : constructRelativeLabel(range as string)
}

export function getClientUtcOffset() {
  return format(new Date(), 'X') as UtcOffset
}

export const defaultAbsoluteTimeSteps = {
  xxs: [
    { value: 0, step: MIN_IN_MILLISECONDS, label: '1min' },
    { value: 50, step: FIVE_MIN_IN_MILLISECONDS, label: '5min' },
    { value: 100, step: TEN_MIN_IN_MILLISECONDS, label: '10min' },
  ],
  xs: [
    { value: 0, step: FIVE_MIN_IN_MILLISECONDS, label: '5min' },
    { value: 33, step: TEN_MIN_IN_MILLISECONDS, label: '10min' },
    { value: 66, step: HALF_HOUR_IN_MILLISECONDS, label: '30min' },
    { value: 100, step: HOUR_IN_MILLISECONDS, label: '1h' },
  ],
  sm: [
    { value: 0, step: HALF_HOUR_IN_MILLISECONDS, label: '30min' },
    { value: 33, step: HOUR_IN_MILLISECONDS, label: '1h' },
    { value: 66, step: HALF_DAY_IN_MILLISECONDS, label: '12h' },
    { value: 100, step: DAY_IN_MILLISECONDS, label: '1d' },
  ],
  md: [
    { value: 0, step: HOUR_IN_MILLISECONDS, label: '1h' },
    { value: 50, step: HALF_DAY_IN_MILLISECONDS, label: '12h' },
    { value: 100, step: DAY_IN_MILLISECONDS, label: '1d' },
  ],
  lg: [
    { value: 0, step: DAY_IN_MILLISECONDS, label: '1d' },
    { value: 50, step: WEEK_IN_MILLISECONDS, label: '7d' },
    { value: 100, step: MONTH_IN_MILLISECONDS, label: '30d' },
  ],
  xl: [
    { value: 0, step: WEEK_IN_MILLISECONDS, label: '7d' },
    { value: 100, step: MONTH_IN_MILLISECONDS, label: '30d' },
  ],
  xxl: [
    { value: 0, step: FIVE_MIN_IN_MILLISECONDS, label: '5min' },
    { value: 20, step: TEN_MIN_IN_MILLISECONDS, label: '10min' },
    { value: 40, step: HALF_HOUR_IN_MILLISECONDS, label: '30min' },
    { value: 60, step: HOUR_IN_MILLISECONDS, label: '1h' },
    { value: 80, step: HALF_DAY_IN_MILLISECONDS, label: '12h' },
    { value: 100, step: DAY_IN_MILLISECONDS, label: '1d' },
  ],
  xxxl: [
    { value: 0, step: HALF_DAY_IN_MILLISECONDS, label: '12h' },
    { value: 50, step: DAY_IN_MILLISECONDS, label: '1d' },
    { value: 100, step: WEEK_IN_MILLISECONDS, label: '7d' },
  ],
  xxxxl: [
    { value: 0, step: HALF_DAY_IN_MILLISECONDS, label: '12h' },
    { value: 33, step: DAY_IN_MILLISECONDS, label: '1d' },
    { value: 66, step: WEEK_IN_MILLISECONDS, label: '7d' },
    { value: 100, step: MONTH_IN_MILLISECONDS, label: '30d' },
  ],
}

export const defaultAbsoluteTimeStepsWithFiscalSteps = {
  xxs: defaultAbsoluteTimeSteps.xxs,
  xs: defaultAbsoluteTimeSteps.xs,
  sm: defaultAbsoluteTimeSteps.sm,
  xxl: defaultAbsoluteTimeSteps.xxl,
  xxxl: defaultAbsoluteTimeSteps.xxxl,
  xxxxl: defaultAbsoluteTimeSteps.xxxxl,
  md: [
    { value: 0, step: HALF_DAY_IN_MILLISECONDS, label: '12h' },
    { value: 33, step: DAY_IN_MILLISECONDS, label: '1d' },
    { value: 66, step: WEEK_IN_MILLISECONDS, label: '7d' },
    { value: 100, step: WEEK_IN_MILLISECONDS, label: '7d fiscal' },
  ],
  lg: [
    { value: 0, step: DAY_IN_MILLISECONDS, label: '1d' },
    { value: 25, step: WEEK_IN_MILLISECONDS, label: '7d' },
    { value: 50, step: WEEK_IN_MILLISECONDS, label: '7d fiscal' },
    { value: 75, step: MONTH_IN_MILLISECONDS, label: '30d' },
    { value: 100, step: MONTH_IN_MILLISECONDS, label: '30d fiscal' },
  ],
  xl: [
    { value: 0, step: WEEK_IN_MILLISECONDS, label: '7d' },
    { value: 33, step: WEEK_IN_MILLISECONDS, label: '7d fiscal' },
    { value: 66, step: MONTH_IN_MILLISECONDS, label: '30d' },
    { value: 100, step: MONTH_IN_MILLISECONDS, label: '30d fiscal' },
  ],
}

export function getStepOptions(selection: any, enableFiscalTimeSteps?: boolean) {
  const isAbsolute = selection.key === 'selection' || (selection.startMs && selection.endMs)

  const absoluteTimeSteps = enableFiscalTimeSteps
    ? defaultAbsoluteTimeStepsWithFiscalSteps
    : defaultAbsoluteTimeSteps

  if (isAbsolute) {
    const startMs = selection?.startDate?.valueOf() || selection?.startMs
    const endMs = selection?.endDate?.valueOf() || selection?.endMs
    const periodInDays = Math.round((endMs - startMs) / DAY_IN_MILLISECONDS)

    if (periodInDays <= 1) return absoluteTimeSteps.xs

    if (periodInDays === 2 || periodInDays === 3) return absoluteTimeSteps.xxl

    if (periodInDays > 3 && periodInDays <= 14) return absoluteTimeSteps.sm

    if (periodInDays > 14 && periodInDays <= 31) return absoluteTimeSteps.xxxl
    if (periodInDays > 31) return absoluteTimeSteps.xxxxl

    if (periodInDays >= 7 && periodInDays < 30) return absoluteTimeSteps.md
    if (periodInDays >= 30 && periodInDays < 90) return absoluteTimeSteps.lg
    return absoluteTimeSteps.xl
  }
  if (selection === '-2d' || selection === '-3d') return absoluteTimeSteps.xxl

  if (selection === '-1h') return absoluteTimeSteps.xxs
  if (selection === '-2h' || selection === '-1d' || selection === '-12h')
    return absoluteTimeSteps.xs
  if (selection === '-1w' || selection === '-7d' || selection === '-14d')
    return absoluteTimeSteps.sm
  if (selection === '-30d') return absoluteTimeSteps.md
  return absoluteTimeSteps.xs
}

export function customStepOptions(selection: any, enableFiscalTimeSteps?: boolean) {
  const isAbsolute = selection.key === 'selection' || (selection.startMs && selection.endMs)
  const absoluteTimeSteps = enableFiscalTimeSteps
    ? defaultAbsoluteTimeStepsWithFiscalSteps
    : defaultAbsoluteTimeSteps
  if (isAbsolute) {
    const startMs = selection?.startDate?.valueOf() || selection?.startMs
    const endMs = selection?.endDate?.valueOf() || selection?.endMs
    const periodInDays = Math.round((endMs - startMs) / DAY_IN_MILLISECONDS)
    if (periodInDays <= 1) return absoluteTimeSteps.xs
    if (periodInDays === 2 || periodInDays === 3) return absoluteTimeSteps.xxl

    if (periodInDays > 3 && periodInDays <= 14) return absoluteTimeSteps.sm

    if (periodInDays >= 7 && periodInDays < 30) return absoluteTimeSteps.md
    if (periodInDays > 14 && periodInDays <= 31) return absoluteTimeSteps.xxxl

    if (periodInDays > 31) return absoluteTimeSteps.xxxxl
    if (periodInDays >= 30 && periodInDays < 90) return absoluteTimeSteps.lg
    return absoluteTimeSteps.xl
  }
  if (selection === '-2d' || selection === '-3d') return absoluteTimeSteps.xxl

  if (selection === '-1h') return absoluteTimeSteps.xxs
  if (selection === '-2h' || selection === '-1d' || selection === '-12h')
    return absoluteTimeSteps.xs
  if (selection === '-1w' || selection === '-7d' || selection === '-14d')
    return absoluteTimeSteps.sm
  if (selection === '-30d') return absoluteTimeSteps.md
  return absoluteTimeSteps.xs
}

function getNow() {
  return new Date()
}

export const ranges: Record<string, any> = {
  last1Hour: {
    rangeKey: 'last1Hour',
    label: 'Last 1 hour',
    isSelected: () => false,
    range: () => ({
      startDate: subHours(getNow(), 1),
      endDate: getNow(),
      key: '-1h',
    }),
  },
  last2Hours: {
    rangeKey: 'last2Hour',
    label: 'Last 2 hours',
    isSelected: () => false,
    range: () => ({
      startDate: subHours(getNow(), 2),
      endDate: getNow(),
      key: '-2h',
    }),
  },
  last12Hours: {
    rangeKey: 'last12Hours',
    label: 'Last 12 hours',
    isSelected: () => false,
    range: () => ({
      startDate: subHours(getNow(), 12),
      endDate: getNow(),
      key: '-12h',
    }),
  },
  last24Hours: {
    rangeKey: 'last24Hours',
    label: 'Last 24 hours',
    isSelected: () => false,
    range: () => ({
      startDate: subDays(getNow(), 1),
      endDate: getNow(),
      key: '-1d',
    }),
  },
  today: {
    rangeKey: 'today',
    label: 'Today',
    isSelected: () => false,
    range: () => ({
      startDate: startOfToday(),
      endDate: getNow(),
      type: 'staticAbsolute',
    }),
  },
  yesterday: {
    rangeKey: 'yesterday',
    label: 'Yesterday',
    isSelected: () => false,
    range: () => ({
      startDate: startOfYesterday(),
      endDate: endOfYesterday(),
      type: 'staticAbsolute',
    }),
  },
  last2Days: {
    // ie, this key is what gets applied
    rangeKey: 'last2Days',
    label: 'Last 2 days',
    isSelected: () => false,
    range: () => ({
      startDate: subDays(getNow(), 2),
      endDate: getNow(),
      key: '-2d',
    }),
  },
  last3Days: {
    // and this one
    rangeKey: 'last3Days',
    label: 'Last 3 days',
    isSelected: () => false,
    range: () => ({
      startDate: subDays(getNow(), 3),
      endDate: getNow(),
      key: '-3d',
    }),
  },
  last7Days: {
    rangeKey: 'last7Days',
    label: 'Last 7 days',
    isSelected: () => false,
    range: () => ({
      startDate: subDays(getNow(), 7),
      endDate: getNow(),
      key: '-7d',
    }),
  },
  last14Days: {
    rangeKey: 'last14Days',
    label: 'Last 14 days',
    isSelected: () => false,
    range: () => ({
      startDate: subDays(getNow(), 14),
      endDate: getNow(),
      key: '-14d',
    }),
  },
  last30Days: {
    rangeKey: 'last30Days',
    label: 'Last 30 days',
    isSelected: () => false,
    range: () => ({
      startDate: subDays(getNow(), 30),
      endDate: getNow(),
      key: '-30d',
    }),
  },
  lastOneWeek: {
    rangeKey: 'lastOneWeek',
    label: 'Last 1 week',
    isSelected: () => false,
    range: () => ({
      startDate: subDays(getNow(), 6),
      endDate: getNow(),
    }),
  },
  previousWeek: {
    rangeKey: 'previousWeek',
    label: 'Previous week',
    isSelected: () => false,
    range: () => ({
      startDate: startOfWeek(subWeeks(getNow(), 1)),
      endDate: endOfWeek(subWeeks(getNow(), 1)),
    }),
  },
  weekToDate: {
    rangeKey: 'weekToDate',
    label: 'Week to Date',
    isSelected: () => false,
    range: () => ({
      startDate: startOfWeek(getNow()),
      endDate: getNow(),
      type: 'staticAbsolute',
    }),
  },
  last1Month: {
    rangeKey: 'last1Months',
    label: 'Last 1 month',
    isSelected: () => false,
    range: () => ({
      startDate: subMonths(getNow(), 1),
      endDate: getNow(),
      type: 'staticAbsolute',
    }),
  },
  last3Months: {
    rangeKey: 'last3Months',
    label: 'Last 3 months',
    isSelected: () => false,
    range: () => ({
      startDate: subMonths(getNow(), 3),
      endDate: getNow(),
      type: 'staticAbsolute',
    }),
  },
  last6Months: {
    rangeKey: 'last6Months',
    label: 'Last 6 months',
    isSelected: () => false,
    range: () => ({
      startDate: subMonths(getNow(), 6),
      endDate: getNow(),
      type: 'staticAbsolute',
    }),
  },
  last13Months: {
    rangeKey: 'last13Months',
    label: 'Last 13 months',
    isSelected: () => false,
    range: () => ({
      startDate: subMonths(getNow(), 13),
      endDate: getNow(),
      type: 'staticAbsolute',
    }),
  },
  previousMonth: {
    rangeKey: 'previousMonth',
    label: 'Previous month',
    isSelected: () => false,
    range: () => ({
      startDate: startOfMonth(subMonths(getNow(), 1)),
      endDate: endOfMonth(subMonths(getNow(), 1)),
    }),
  },
  previous3Months: {
    rangeKey: 'previous3Months',
    label: 'Previous 3 months',
    isSelected: () => false,
    range: () => ({
      startDate: startOfMonth(subMonths(getNow(), 3)),
      endDate: endOfMonth(subMonths(getNow(), 1)),
    }),
  },
  previous6Months: {
    rangeKey: 'previous6Months',
    label: 'Previous 6 months',
    isSelected: () => false,
    range: () => ({
      startDate: startOfMonth(subMonths(getNow(), 6)),
      endDate: endOfMonth(subMonths(getNow(), 1)),
    }),
  },
  monthToDate: {
    rangeKey: 'monthToDate',
    label: 'Month to Date',
    isSelected: () => false,
    range: () => ({
      startDate: startOfMonth(getNow()),
      endDate: getNow(),
      type: 'staticAbsolute',
    }),
  },
  yearToDate: {
    rangeKey: 'yearToDate',
    label: 'Year to Date',
    isSelected: () => false,
    range: () => ({
      startDate: startOfYear(getNow()),
      endDate: getNow(),
      type: 'staticAbsolute',
    }),
  },
}

export function customDefaultStaticRanges(selected: State): RangeSection[] {
  const sections = [
    {
      label: 'Hours',
      sectionRanges: [ranges.last1Hour, ranges.last2Hours, ranges.last12Hours, ranges.last24Hours],
    },
    {
      label: 'Days',
      sectionRanges: [
        ranges.today,
        ranges.yesterday,
        ranges.last2Days,
        ranges.last3Days,
        ranges.last7Days,
        ranges.last14Days,
        ranges.last30Days,
      ],
    },
    {
      label: 'Weeks',
      sectionRanges: [ranges.lastOneWeek, ranges.previousWeek, ranges.weekToDate],
    },
    {
      label: 'Months',
      sectionRanges: [
        ranges.last1Month,
        ranges.last3Months,
        ranges.last6Months,
        ranges.last13Months,
        ranges.previousMonth,
        ranges.previous3Months,
        ranges.previous6Months,
        ranges.monthToDate,
      ],
    },
    {
      label: 'Years',
      sectionRanges: [ranges.yearToDate],
    },
  ]

  return sections
}

export function defaultStaticRanges(selected: State): RangeSection[] {
  const sections = [
    {
      label: 'Hours',
      sectionRanges: [ranges.last1Hour, ranges.last2Hours, ranges.last12Hours, ranges.last24Hours],
    },
    {
      label: 'Days',
      sectionRanges: [
        ranges.today,
        ranges.yesterday,
        ranges.last7Days,
        ranges.last14Days,
        ranges.last30Days,
      ],
    },
    {
      label: 'Weeks',
      sectionRanges: [ranges.lastOneWeek, ranges.previousWeek, ranges.weekToDate],
    },
    {
      label: 'Months',
      sectionRanges: [
        ranges.last1Month,
        ranges.last3Months,
        ranges.last6Months,
        ranges.last13Months,
        ranges.previousMonth,
        ranges.previous3Months,
        ranges.previous6Months,
        ranges.monthToDate,
      ],
    },
    {
      label: 'Years',
      sectionRanges: [ranges.yearToDate],
    },
  ]

  return sections
}

export const getISODate = (date: Date): string => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const dateMonth = month < 10 ? '0' + month : month
  const dateDay = day < 10 ? '0' + day : day

  return `${year}-${dateMonth}-${dateDay}`
}

export const getISOTime = (date: Date, defaultTime?: string): string => {
  const hour = date.getHours()
  const minute = date.getMinutes()

  const timeHour = hour < 10 ? '0' + hour : hour
  const timeMinute = minute < 10 ? '0' + minute : minute

  const time = defaultTime ? defaultTime : `${timeHour}:${timeMinute}`
  return time
}

export const utcOffsetOptions = [
  { label: 'UTC-12', value: '-12' },
  { label: 'UTC-11', value: '-11' },
  { label: 'UTC-10', value: '-10' },
  { label: 'UTC-9', value: '-09' },
  { label: 'UTC-8', value: '-08' },
  { label: 'UTC-7', value: '-07' },
  { label: 'UTC-6', value: '-06' },
  { label: 'UTC-5', value: '-05' },
  { label: 'UTC-4', value: '-04' },
  { label: 'UTC-3', value: '-03' },
  { label: 'UTC-2', value: '-02' },
  { label: 'UTC-1', value: '-01' },
  { label: 'UTC+0', value: '+00' },
  { label: 'UTC+1', value: '+01' },
  { label: 'UTC+2', value: '+02' },
  { label: 'UTC+3', value: '+03' },
  { label: 'UTC+4', value: '+04' },
  { label: 'UTC+5', value: '+05' },
  { label: 'UTC+6', value: '+06' },
  { label: 'UTC+7', value: '+07' },
  { label: 'UTC+8', value: '+08' },
  { label: 'UTC+9', value: '+09' },
  { label: 'UTC+10', value: '+10' },
  { label: 'UTC+11', value: '+11' },
  { label: 'UTC+12', value: '+12' },
  { label: 'UTC+13', value: '+13' },
  { label: 'UTC+14', value: '+14' },
]

export function getPeriodTimestampsBasedOnDays(days: number) {
  const currentDate = new Date()
  const pastDate = new Date(currentDate)
  pastDate.setDate(currentDate.getDate() - days)
  const currentTimestamp = currentDate.getTime()
  const pastTimestamp = pastDate.getTime()
  return `${pastTimestamp}:${currentTimestamp}`
}
