import * as React from 'react'

function SvgPauseCircleF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.728.008 13.992 6.272 14 14 0 7.732-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm-1 8H7v12h6V8zm8 0h-6v12h6V8zm-10 2v8H9v-8h2zm8 0v8h-2v-8h2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPauseCircleF
