import useVocState from 'hooks/useVocState'
import { types } from 'components/voiceOfCustomer'
import isEmpty from 'lodash/isEmpty'
import Autocomplete from 'components/shared/Autocomplete'
import { getUnarchivedManualCategories } from 'components/voiceOfCustomer/utils'

import * as T from './types'
import * as S from './styled'

export function getCategoryByID(categories: types.CategoryModel[], id: string | null) {
  return categories.find((category: types.CategoryModel) => category._id === id)
}

function VocManualCategoryFilter({
  filter,
  handleMultipleFilterChange,
  filterValues,
}: {
  filter: T.Filter
  handleMultipleFilterChange: (filterValues: { manualCategory?: string[] }) => void
  filterValues: T.FilterValues
}) {
  const { state } = useVocState()

  const selectedVocCategories = filterValues.vocCategory
  const selectedMiddleCategories = filterValues.middleCategory
  const manualCategory = filterValues.manualCategory as string[] | undefined
  const manualCategories = getUnarchivedManualCategories(
    state?.manualCategories,
    state?.categoriesData
  ) as types.ManualCategory[]

  // If Category is selected filter manualCategories related to selected Categories and/or Middle Categories
  const usableManualCategories = manualCategories?.filter(
    (stateManualCategory: types.ManualCategory) => {
      if (isEmpty(selectedMiddleCategories)) {
        if (isEmpty(selectedVocCategories)) {
          return true
        }

        const manualCategoryFromMiddleCategoryOptionsState = state.middleCategoryOptions?.some(
          (middleCategoryValue: Record<string, unknown>) => {
            if (!selectedVocCategories?.includes(middleCategoryValue.parent as string)) return false
            return middleCategoryValue.value === stateManualCategory?.category
          }
        )

        const hasVocCategoryParent = selectedVocCategories?.includes(
          stateManualCategory?.category || ''
        )
        return manualCategoryFromMiddleCategoryOptionsState || hasVocCategoryParent
      }

      const hasMiddleCategoryParent = selectedMiddleCategories?.includes(
        stateManualCategory?.category || ''
      )
      if (isEmpty(selectedVocCategories)) {
        return hasMiddleCategoryParent
      }

      const selectedVocCategoriesWithoutAMiddleCategorySelected =
        selectedVocCategories?.filter(vocCategoryID => {
          return !state.middleCategoryOptions.some((middleCategoryOption: types.CategoryModel) => {
            return middleCategoryOption.parent === vocCategoryID
          })
        }) || []

      if (isEmpty(selectedVocCategoriesWithoutAMiddleCategorySelected)) {
        return hasMiddleCategoryParent
      }

      const topCategoryParent = selectedVocCategories?.includes(stateManualCategory?.category || '')

      return hasMiddleCategoryParent || topCategoryParent
    }
  )

  const options = usableManualCategories?.map((stateManualCategory: types.ManualCategory) => {
    const category = getCategoryByID(state?.categoriesData, stateManualCategory?.category)?.category

    return {
      label: `${stateManualCategory.manualCategory} (${category})`, // To support existing labeling for option
      value: stateManualCategory?._id,
      subAndManualCategories: stateManualCategory,
    }
  })

  function onChangeHandle(value: string[]) {
    const activeManualCategories = state?.manualCategories?.filter(
      (manualCategory: types.ManualCategory) => {
        return value.includes(manualCategory?._id)
      }
    )
    const activeManualCategoriesIDs = activeManualCategories?.map(
      (activeManualCategory: types.ManualCategory) => activeManualCategory._id
    )

    handleMultipleFilterChange &&
      handleMultipleFilterChange({
        manualCategory: activeManualCategoriesIDs,
      })
  }

  const selectedManualCategoriesIDs = state?.manualCategories?.reduce(
    (acc: string[], stateManualCategory: types.ManualCategory) => {
      if (manualCategory?.includes(stateManualCategory._id))
        return acc.concat([stateManualCategory._id])
      return acc
    },
    []
  )

  const defaultValue = selectedManualCategoriesIDs

  return (
    <S.VocCategoryGroupContainer key={JSON.stringify(defaultValue)}>
      <Autocomplete
        label="Manual Category"
        defaultValue={defaultValue}
        onChange={onChangeHandle}
        options={options}
        invertedLabel={filter.invertedLabel}
        fullWidth={filter.fullWidth}
        fullHeight
        width={S.FILTER_WIDTH}
        showTagTooltip
        data-pho="filterMenuAutoCompleteInput"
      />
    </S.VocCategoryGroupContainer>
  )
}

export default VocManualCategoryFilter
