import * as React from 'react'

function SvgDocument(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M21.27.004A2.79 2.79 0 0124 2.844v20.31a2.795 2.795 0 01-2.73 2.85h-1.413A2.816 2.816 0 0117.182 28H0V6.846A2.835 2.835 0 012.818 4H4V2.844A2.79 2.79 0 016.73.004zM17.182 6H2.818A.833.833 0 002 6.846V26h15.182a.833.833 0 00.819-.846V6.846A.834.834 0 0017.182 6zm4.088-3.996H6.73a.791.791 0 00-.73.84V4h11.182A2.836 2.836 0 0120 6.846v17.158h1.27a.799.799 0 00.73-.85V2.844a.792.792 0 00-.73-.84zM12 15v2H5v-2h7zm3-4v2H5v-2h10z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDocument
