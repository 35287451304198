import * as React from 'react'

function SvgLocationArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M17.157 27.342l-5.16-9.286.649-2.702-2.701.65-9.287-5.161L27.764.236 17.157 27.342zm-3.033-9.577l2.719 4.893 7.393-18.894-18.894 7.393 4.893 2.72 5.119-1.231-1.23 5.119z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLocationArrow
