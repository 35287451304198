import React from 'react'
import moment from 'moment'
import startCase from 'lodash/startCase'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { AutoResizer } from 'react-base-table'
import useElementWidth from 'hooks/useElementWidth'
import NotFound from 'components/shared/NotFound'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import Tooltip from '@material-ui/core/Tooltip'
import * as S from './styled'
import { withStyles } from '@material-ui/core/styles'

const INITIAL_WIDTH_PX = 468

const TooltipHoverStyle = withStyles(() => ({
  popper: {
    zIndex: 10002,
  },
}))(Tooltip)

function Table({
  data,
  title,
  group,
  step,
  isChart,
  showBorder = true,
  heightPercentage = 100,
}: TableProps) {
  const [tableSort, setTableSort] = React.useState({ timestamp: 'asc' } as any)
  const [sortedTableData, setSortedTableData] = React.useState(null as unknown)
  const { elementWidth, elementRef } = useElementWidth({ initialWidth: INITIAL_WIDTH_PX })

  function getColumns() {
    const defaultColumnProps = {
      flexGrow: 1,
      flexShrink: 0,
      resizable: true,
      sortable: true,
      width: group ? '30%' : '50%',
    }
    const baseColumns = [
      { dataKey: 'date', key: 'date', title: 'Date', ...defaultColumnProps },
      { dataKey: 'count', key: 'count', title: startCase(title), ...defaultColumnProps },
    ]
    if (group) {
      const groupColumn = {
        dataKey: group,
        key: group,
        title: startCase(group),
        cellRenderer: ({ cellData }: { cellData: string }) => {
          if (group === 'api_name') {
            return (
              <TooltipHoverStyle title={cellData}>
                <S.ApiNameContainer data-test={cellData}>{cellData}</S.ApiNameContainer>
              </TooltipHoverStyle>
            )
          } else {
            return <div data-test={cellData}>{cellData}</div>
          }
        },
        ...defaultColumnProps,
      }
      return baseColumns.concat(groupColumn)
    }
    return baseColumns
  }

  function getTableData(): any {
    if (data.lineData) {
      return data.lineData
        .map(({ data, id }: { data: any[]; id: string }) => {
          return data
            .map(({ x, y }: { x: number; y: number }, i: number) => {
              const currentGroup = group && { [group]: id }
              const rows = {
                count: y,
                date: moment(x).format(
                  parseInt(`${step}`) < 43200000 ? 'MMM D, YYYY h:mm A' : 'MMM D, YYYY'
                ),
                id: i,
              }
              return currentGroup ? { ...rows, ...currentGroup } : rows
            })
            .reverse()
        })
        .flat()
    }

    return data
      .map((value: Record<string, any>, index: number) => {
        const date = value.index
        return map(omit(value, 'index'), (value, key) => {
          return {
            date,
            count: value,
            [group]: key,
            id: index,
          }
        })
      })
      .flat()
  }

  const columns = getColumns()
  const tableData = getTableData()

  function sortColumns({ key, order }: { key: string; order: string }) {
    const prop = key === 'timestamp' ? 'date' : key
    const sortedData = tableData.sort((a: any, b: any) => {
      if (key === 'timestamp' || key === 'date') {
        if (order === 'asc') return new Date(a[prop]) < new Date(b[prop]) ? -1 : 1
        return new Date(a[prop]) > new Date(b[prop]) ? -1 : 1
      }
      if (order === 'asc') return a[prop] < b[prop] ? -1 : 1
      return a[prop] > b[prop] ? -1 : 1
    })
    setSortedTableData(sortedData)
    setTableSort({ [key]: order })
  }

  return isEmpty(tableData) ? (
    <NoDataChartMessage />
  ) : (
    <S.TableContainer isChart={isChart} ref={elementRef} showBorder={showBorder}>
      <AutoResizer>
        {({ height }: { height: number }) => {
          return (
            <S.StyledBaseTable
              columns={columns}
              data={sortedTableData || tableData}
              fixed={false}
              emptyRenderer={<NotFound isSmall />}
              height={height * (heightPercentage / 100)}
              onColumnSort={sortColumns}
              sortState={tableSort}
              width={isChart ? elementWidth - 20 : INITIAL_WIDTH_PX}
            />
          )
        }}
      </AutoResizer>
    </S.TableContainer>
  )
}

export default Table

interface TableProps {
  data: any
  group: string
  title: string
  step: string | number
  isChart?: boolean
  showBorder?: boolean
  heightPercentage?: number
}
