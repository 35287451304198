import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import queryString from 'query-string'
import services from 'common/services'
import { DataType } from 'components/shared/chart/types'

export function getService(dataType: DataType) {
  switch (dataType) {
    case DataType.OS_TOP:
    case DataType.OS_LINE:
    case DataType.OS_TABLE:
    case DataType.OS_SANKEY:
    case DataType.OS_BAR_WITH_TOP:
    case DataType.OS_SUNBURST_WITH_TOP:
    case DataType.OS_SUNBURST:
      return services.openSearchCharts
    case DataType.TECH_MOBILE_LINE:
      return services.techMobile
    case DataType.METRIC_LINE:
    case DataType.METRIC_BAR:
    case DataType.METRIC_TABLE:
      return services.metric
    default:
      return services.realtimeDashboardApi
  }
}

export function getDataSource(type: DataType, dataSource?: string) {
  if (!isNil(dataSource)) return dataSource
  switch (type) {
    case DataType.LINE:
    case DataType.PERCENTAGE:
    case DataType.MULTI:
    case DataType.TOP:
    case DataType.SUNBURST:
    case DataType.SUNBURST_WITH_TOP:
    case DataType.BAR:
    case DataType.BAR_WITH_TOP:
    case DataType.SANKEY:
    case DataType.CUSTOM:
    case DataType.HORIZONTAL_BAR:
    case DataType.TABLE:
    case DataType.TECH_MOBILE_LINE:
    case DataType.GRID:
    case DataType.NETWORK:
    case DataType.METRIC_LINE:
    case DataType.METRIC_BAR:
    case DataType.METRIC_TABLE:
      return 'Druid'
    case DataType.OS_TOP:
    case DataType.OS_LINE:
    case DataType.OS_TABLE:
    case DataType.OS_SUNBURST_WITH_TOP:
    case DataType.OS_BAR_WITH_TOP:
    case DataType.OS_SANKEY:
    case DataType.OS_SUNBURST:
    case DataType.OS_HORIZONTAL_BAR:
      return 'Open Search'
    default:
      return 'Unknown'
  }
}

export function getAppsFromFilter(appliedFilters: string): string[] | null {
  const filterValue = appliedFilters?.split(':')?.[1]
  const appsFromFilterValue = filterValue ? filterValue?.split(',') : []
  return !isEmpty(appsFromFilterValue) ? appsFromFilterValue : null
}

// This is used to apply application (applicationNameAndType) filter for Group https://gitlab.spectrumflow.net/client-analytics/prism/-/issues/666
export function getCurrentApps(
  currentApps: string[],
  appliedFilters?: string | string[]
): string[] {
  if (isString(appliedFilters)) {
    if (appliedFilters?.includes('applicationNameAndType:')) {
      return getAppsFromFilter(appliedFilters) || currentApps
    }
    return currentApps
  }

  const applicationNameAndTypeFilter = appliedFilters?.filter((appliedFilter: string) =>
    appliedFilter?.includes('applicationNameAndType:')
  )
  if (applicationNameAndTypeFilter && !isEmpty(applicationNameAndTypeFilter)) {
    return getAppsFromFilter(applicationNameAndTypeFilter[0]) || currentApps
  }
  return currentApps
}

export function getQueryAppsStringified(
  currentApps?: string[],
  query?: any,
  entityID?: string,
  qubeApps?: string[]
) {
  // this logic is for groups, that "qube" and "internal" apps could add in one group
  // https://gitlab.spectrumflow.net/client-analytics/prism/-/merge_requests/115#note_1166386
  if (currentApps) {
    const parsedQuery = queryString.parse(query)

    const filter = parsedQuery?.filter as string
    // This is used to apply application filter for Group https://gitlab.spectrumflow.net/client-analytics/prism/-/issues/666
    const apps = filter ? getCurrentApps(currentApps, filter) : currentApps

    const filteredCurrentApps = apps?.filter(app =>
      parsedQuery.source === 'qube' ? qubeApps?.includes(app) : !qubeApps?.includes(app)
    )

    return filteredCurrentApps.length > 1
      ? {
          appIDs: filteredCurrentApps,
        }
      : {
          appID: filteredCurrentApps,
        }
  }
  return {
    appID: entityID,
  }
}
