import * as React from 'react'

function SvgPayment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.732 0 14 6.268 14 14-.009 7.728-6.272 13.991-14 14-7.732 0-14-6.268-14-14S6.268 0 14 0zm0 2C7.373 2 2 7.373 2 14s5.373 12 12 12 12-5.373 12-12c-.007-6.624-5.376-11.993-12-12zm1 3v2h1a3.003 3.003 0 013 3v1h-2v-1a1 1 0 00-1-1h-1v4h1a3.003 3.003 0 013 3v2a3.003 3.003 0 01-3 3h-1v2h-2v-2h-1a3.003 3.003 0 01-3-3v-1h2v1a1 1 0 001 1h1v-4h-1a3.003 3.003 0 01-3-3v-2a3.003 3.003 0 013-3h1V5h2zm1 10h-1v4h1a1 1 0 001-1v-2a1 1 0 00-1-1zm-3-6h-1a1 1 0 00-1 1v2a1 1 0 001 1h1V9z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPayment
