import * as React from 'react'

function SvgStopF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path d="M0 0h26v26H0z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default SvgStopF
