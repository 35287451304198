import * as React from 'react'

function SvgStopCircleF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.728.008 13.992 6.272 14 14 0 7.732-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm5 9H9v10h10V9zm-2 2v6h-6v-6h6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgStopCircleF
