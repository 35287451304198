import * as React from 'react'

function SvgAutopay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M2 13a10.995 10.995 0 0020.79 5H20v-2h6v6h-2v-2.08A12.997 12.997 0 010 13h2zm12-9v2h1a3.003 3.003 0 013 3v1h-2V9a1 1 0 00-1-1h-1v4h1a3.003 3.003 0 013 3v2a3.003 3.003 0 01-3 3h-1v2h-2v-2h-1a3.003 3.003 0 01-3-3v-1h2v1a1 1 0 001 1h1v-4h-1a3.003 3.003 0 01-3-3V9a3.003 3.003 0 013-3h1V4h2zm1 10h-1v4h1a1 1 0 001-1v-2a1 1 0 00-1-1zM16.604.509A12.997 12.997 0 0126 13h-2A10.995 10.995 0 003.21 8H6v2H0V4h2v2.08A12.997 12.997 0 0116.604.509zM12 7.999h-1a1 1 0 00-1 1v2a1 1 0 001 1h1V8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAutopay
