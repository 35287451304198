import queryString from 'query-string'
import moment from 'moment'
import isNil from 'lodash/isNil'
import last from 'lodash/last'
import first from 'lodash/first'
import round from 'lodash/round'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import numeral from 'numeral'
import { FormattedLineData, DataPoint } from 'components/shared/ChartData/types'
import { LineData, FormatterArg } from './types'
import * as T from './types'

export function getTotal(data: LineData) {
  const result = data?.aggrData?.total
  if (isNil(result)) return 'Loading'
  return result === 0 ? 'No Data' : result
}

export function getAverage({ lineData, aggrData }: LineData) {
  const lineDataAvg = lineData
    ? lineData.reduce((acc: number, { data }: { id: string; data: DataPoint[] }) => {
        const percentilePercent = data.reduce((acc: number, dataPoint: any) => {
          const percentage = parseInt(dataPoint.y, 10) / data.length
          return acc + percentage
        }, 0)
        const avgOfPercentiles = percentilePercent / lineData.length
        return acc + avgOfPercentiles
      }, 0)
    : null
  const result = aggrData ? aggrData?.avg : lineDataAvg
  if (isNil(result)) return 'Loading'
  return result === 0 ? 'No Data' : result
}

export function getLatest(data: FormattedLineData) {
  const result = last(last(data)?.data)?.y as number
  if (isNil(result)) return 'Loading'
  return result === 0 ? 'No Data' : result
}

export function defaultFormatter({ total, average, latest, data }: FormatterArg) {
  return numeral(total).format('0a')
}

export const timeMenuFilters = ['fiscalTime', 'origintz', 'partialData', 'period', 'step']

export function getLineChartKey(lineData: Record<string, any>[]) {
  const firstLine: Record<string, any>[] = first(lineData)?.data
  const lastLine: Record<string, any>[] = last(lineData)?.data
  const firstLineFirstTimeStamp = first(firstLine)?.x
  const lastLineLastTimeStamp = last(lastLine)?.x
  return `${firstLineFirstTimeStamp}_${lastLineLastTimeStamp}`
}

export function getTickValues({
  timestamp,
  lineData,
  origintz,
  numericalStep,
  step,
  isVocChart,
}: {
  timestamp?: string
  lineData?: { x: number }[]
  origintz?: boolean
  numericalStep?: boolean
  step?: number
  isVocChart?: boolean
}) {
  if (timestamp && !Array.isArray(timestamp)) {
    const [dateFrom, dateTo] = timestamp.split(':')
    const maxTickCount = 6
    const dateFromString = moment(parseInt(dateFrom)).startOf('hour') //need startOf hour to not take in account lastFiveMinuteInterval
    const dateToString = moment(parseInt(dateTo))

    const periodInHours = dateToString.diff(dateFromString, 'hours')
    const listOfTimestamps = lineData?.map((timestamp: { x: number }) => timestamp.x)
    const numberOfTimestampsDividedByMaxTickCount =
      listOfTimestamps && listOfTimestamps?.length >= maxTickCount
        ? listOfTimestamps?.length / maxTickCount
        : 1
    // to avoid showing more than "maxTickCount" tickValues from timestamps
    const timestamps = listOfTimestamps?.filter(
      (timestamp: number, index: number) =>
        index % round(numberOfTimestampsDividedByMaxTickCount) === 0
    )

    if (periodInHours <= 1) {
      return 'every 10 minute'
    } else if (periodInHours <= 2) {
      if (step === 1800000) {
        return 'every 30 minute'
      }
      return 'every 20 minute'
    } else if (periodInHours > 2 && periodInHours <= 12) {
      return 'every 3 hour'
    } else if (periodInHours > 12 && periodInHours <= 24) {
      return origintz && step && step === 86400000 ? listOfTimestamps : 'every 6 hour'
    } else if (periodInHours > 24 && periodInHours <= 240) {
      return isVocChart || (origintz && step && step >= 86400000) ? listOfTimestamps : 'every 1 day'
    } else if (periodInHours > 240 && periodInHours <= 336 && !numericalStep) {
      return origintz ? listOfTimestamps : 'every week'
    } else if (periodInHours > 240 && periodInHours <= 336) {
      return origintz && step && step >= 86400000
        ? listOfTimestamps?.filter((timestamp: number, index: number) => index % 2 === 0)
        : 'every 2 day'
    } else if (periodInHours > 336 && periodInHours <= 720 && !numericalStep) {
      return origintz ? listOfTimestamps : 'every week'
    } else if (periodInHours > 336 && periodInHours <= 720) {
      return origintz && step && step >= 86400000
        ? listOfTimestamps?.filter((timestamp: number, index: number) => index % 4 === 0)
        : 'every 5 day'
    } else if (periodInHours > 720 && periodInHours <= 2160) {
      return origintz ? timestamps : 'every 2 week'
    } else if (periodInHours > 2160 && periodInHours <= 4320) {
      return origintz ? timestamps : 'every month'
    } else if (periodInHours > 4320 && periodInHours <= 5999) {
      return origintz ? timestamps : 'every month'
    } else if (periodInHours > 6000) {
      return origintz && !numericalStep ? timestamps : 'every month'
    } else {
      return 'every 7 day'
    }
  }
  return
}

export function getAxisBottomValue(value: any, tickValues?: string | number[]) {
  const day = moment(value).format('D MMM')
  const hoursMinutes = moment(value).format('h:mm a')
  switch (tickValues) {
    case 'every 10 minute':
    case 'every 20 minute':
    case 'every 30 minute':
      return hoursMinutes
    case 'every 3 hour':
    case 'every 6 hour':
      return `${day} ${hoursMinutes}`
    default:
      return day
  }
}

export function getAxisRightTickValues(chartHeight: any) {
  // 5 and 2 are thick value count. 120 is min height of chart which support 5 thick values
  return !chartHeight || chartHeight > 120 ? 5 : 2
}

export function createLabelsArray(
  data: any,
  variantNames: Record<string, string>,
  query: queryString.ParsedQuery<string>
) {
  const dataArr = data.lineData
  return dataArr.map((x: { id: string }) => {
    const customLabel =
      variantNames && query.groupBy === 'variantUuids' ? variantNames?.[x.id] : null
    return customLabel || x.id
  })
}

export function getChartTheme(isDarkMode: boolean) {
  return {
    textColor: isDarkMode ? 'white' : 'black',
    crosshair: {
      line: {
        stroke: isDarkMode ? 'white' : 'black',
      },
    },
    axis: {
      ticks: {
        text: {
          fontSize: '12px',
          fontFamily: 'Open Sans',
        },
      },
    },
  }
}

export function checkIfAllDataIsNull(data: T.LineData) {
  if (isArray(data?.lineData)) {
    const filteredData = data?.lineData?.filter((lineData: { id: string; data: DataPoint[] }) => {
      const notEmptyData = lineData?.data?.filter((data: DataPoint) => data.y !== null)
      return !isEmpty(notEmptyData)
    })
    return isEmpty(filteredData)
  }

  return false
}

// For only one point increase point size to be more visible.
export function getPointSize(data: any) {
  const lineData = get(data, 'lineData[0].data')
  const pointCountWithoutNullValues = lineData?.reduce((acc: number, data: any) => {
    if (data?.y !== null) {
      return acc + 1
    }
    return acc
  }, 0)
  if (data?.lineData.length === 1 && pointCountWithoutNullValues === 1) {
    return 5
  }
  return 2
}

export const getPointColor = (color: string, hoveredColor: Nullable<string>) => {
  if (hoveredColor) {
    return color === hoveredColor ? color : 'transparent'
  } else return color
}

export function getHalfHeights(
  tooltipRef: React.MutableRefObject<null>,
  ChartContainerRef: React.MutableRefObject<null>
) {
  const tooltipHeight = tooltipRef && tooltipRef?.current && get(tooltipRef, 'current.offsetHeight')
  const chartHeight =
    ChartContainerRef &&
    ChartContainerRef?.current &&
    get(ChartContainerRef, 'current.offsetHeight')

  const halfTooltipHeight = tooltipHeight && tooltipHeight / 3
  const halfChartHeight = chartHeight && chartHeight / 2

  return { halfTooltipHeight, halfChartHeight }
}
