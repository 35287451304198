import * as React from 'react'

function SvgLighting(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 28" {...props}>
      <path
        d="M9 0a9.01 9.01 0 00-9 9c0 2.614 1.468 5.12 2.647 7.133A9.183 9.183 0 014 19v4l1.22 5h7.56l1.19-4.758L13.99 20H14v-1a9.183 9.183 0 011.353-2.867C16.533 14.12 18 11.614 18 9a9.01 9.01 0 00-9-9zm2.22 26H6.78l-.5-2h5.44l-.5 2zm.78-4H6v-2h6v2zm1.627-6.878A15.528 15.528 0 0012.17 18H10v-6h1a1 1 0 000-2H7a1 1 0 000 2h1v6H5.83a15.528 15.528 0 00-1.457-2.878C3.26 13.224 2 11.072 2 9a7 7 0 0114 0c0 2.072-1.26 4.224-2.373 6.122z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLighting
