import * as React from 'react'

function SvgChevronUpCircleF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14C27.992 6.272 21.728.008 14 0zm5.79 17.46L14 11.66l-5.79 5.8-1.42-1.42L14 8.84l7.21 7.2-1.42 1.42z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronUpCircleF
