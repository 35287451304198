import * as React from 'react'

function SvgMoreHorz(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 6" {...props}>
      <path
        d="M3 0a3 3 0 110 6 3 3 0 010-6zm22 0a3 3 0 110 6 3 3 0 010-6zM14 0a3 3 0 110 6 3 3 0 010-6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMoreHorz
