import React from 'react'
import { Link, useLocation } from '@reach/router'
import Entity from 'components/shared/Entity'
import { getRouteWithQueries } from 'utils'
import { docsWikis } from 'screens/docs/constants'

function DocsList({ handleClose }: DocsListProps) {
  const location = useLocation()

  return (
    <div>
      {docsWikis.map(tool => (
        <Link
          key={tool.id}
          onClick={handleClose}
          to={getRouteWithQueries(`/documentation/${tool.id}/${tool.homePage}`, location, [])}
        >
          <Entity
            title={tool.title}
            data-pho={tool.title}
            hasBottomDivider={tool.hasBottomDivider}
          />
        </Link>
      ))}
    </div>
  )
}

interface DocsListProps {
  handleClose: (
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}

export default DocsList
