import React, { ReactNode, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import GridLayout from 'react-grid-layout'
import { useMutation } from '@apollo/client'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import useFilterValues from 'hooks/useFilterValues'
import useAuth from 'hooks/useAuth'
import { getVocClient } from 'components/voiceOfCustomer/utils'
import { mutations } from 'components/voiceOfCustomer'
import Autocomplete from 'components/shared/Autocomplete'
import { sortedColumnOptions } from '../constants'
import { getColumnValuesToSet, getLayoutOrder } from '../utils'
import * as T from '../types'
import * as S from './styles'
import { IconButton } from 'components/shared/IconButton'
import { Button } from 'components/shared/Button'
import Typography from 'components/shared/typography/Typography'
import Icon from 'components/shared/icon/Icon'
import Alert from 'components/shared/Alert'

export interface Panel {
  title: string
  label?: ReactNode
  labelVariant?: string
  content: ReactNode
  disabled?: boolean
}

function TableViewDialog({
  isPatch,
  name,
  defaultOptions,
  id,
  handleSelectView,
  handleCloseSettings,
  refetchGetUser,
  currentOrder,
}: TableViewDialogProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [viewName, setViewName] = useState(isPatch ? name : '')
  const [selectedColumns, setSelectedColumns] = useState(defaultOptions || [])
  const [newOrder, setNewOrder] = useState(currentOrder || {})
  const [error, setError] = useState(false)
  const [errorDetails, setErrorDetails] = useState({
    errorMessage: '',
  })

  const auth = useAuth()
  const userID = auth.user?.id || ''
  const authID = auth?.user?.id ?? ''

  const { environment } = useFilterValues()
  const client = getVocClient(environment as string)
  const [insertColumnGroup] = useMutation(mutations.INSERT_COLUMN_GROUP, {
    client,
  })
  const [updateColumnGroup] = useMutation(mutations.UPDATE_COLUMN_GROUP, {
    client,
  })

  function handleClose() {
    setError(false)
    setErrorDetails({
      errorMessage: '',
    })
    setViewName(isPatch ? name : '')
    setIsOpen(false)
  }

  function handleColumnListChange(columnValues: string[]) {
    setSelectedColumns(columnValues)
  }

  async function handleSave() {
    const columnValuesToSet = getColumnValuesToSet(selectedColumns, newOrder)

    if (!viewName) {
      setError(true)
      setErrorDetails({
        errorMessage: 'Please enter View Name to save.',
      })
      return
    }

    if (isEmpty(columnValuesToSet)) {
      setError(true)
      setErrorDetails({
        errorMessage: 'Please select columns to save.',
      })
      return
    }

    if (isPatch) {
      await updateColumnGroup({
        variables: {
          authID,
          columnGroup: {
            _id: id,
            userID,
            name: viewName,
            columns: columnValuesToSet,
          },
        },
      })
    } else {
      await insertColumnGroup({
        variables: {
          authID,
          columnGroup: {
            name: viewName,
            columns: columnValuesToSet,
          },
        },
      })
    }
    await refetchGetUser()
    if (isPatch) {
      const updatedColumnLabels = compact(columnValuesToSet.map(column => column.label))
      handleSelectView && handleSelectView(updatedColumnLabels)
      handleCloseSettings()
    }
    handleClose()
  }

  function handleDrop(layout: T.DraggableColumn[]) {
    const order = getLayoutOrder(layout)
    setNewOrder(order)
  }

  return (
    <>
      {isPatch ? (
        <IconButton
          icon="EditF"
          size="sm"
          onClick={() => setIsOpen(true)}
          data-pho={`${viewName}-editBtn`}
        />
      ) : (
        <Button
          variant="borderless"
          icon="PlusCircleF"
          width="100%"
          onClick={() => setIsOpen(true)}
        >
          Add Custom View
        </Button>
      )}
      <Dialog open={isOpen} onClose={handleClose}>
        <S.ModalContentContainer>
          {error && (
            <Alert variant="page-error" isPersistent children={errorDetails.errorMessage} />
          )}
          <DialogTitle id="alert-dialog-title">
            <span>{isPatch ? 'Update' : 'Create'} View</span>
          </DialogTitle>
          <DialogContent>
            <S.StyledTextField
              value={viewName}
              required
              label="View Name"
              onChange={({ target: { value } }) => setViewName(value)}
              placeholder={'Add a View Name'}
              data-pho="viewName"
            />
            <Autocomplete
              options={sortedColumnOptions}
              onChange={handleColumnListChange}
              placeholder="Columns in View"
              defaultValue={defaultOptions}
              fullHeight
              fullWidth
              textFieldIdentifier="columnView"
              groupBy={(option: ColumnOptions) => option.section}
              renderOption={(option: any) => (
                <div data-pho={`${option.section}-${option.value}`}>{option.label}</div>
              )}
            ></Autocomplete>
            {selectedColumns && (
              <Typography noMargin>
                <p>Column Order:</p>
              </Typography>
            )}
            <GridLayout
              className="layout"
              cols={1}
              autoSize
              width={500}
              rowHeight={30}
              onLayoutChange={handleDrop}
            >
              {selectedColumns.map((selectedColumn: string, index: number) => {
                const endPosition = selectedColumns.length
                const patchOrderNumber =
                  currentOrder && !isNil(currentOrder[selectedColumn])
                    ? currentOrder[selectedColumn]
                    : endPosition

                const orderNumber = isPatch ? patchOrderNumber : index
                const columnLabel =
                  find(sortedColumnOptions, { value: selectedColumn })?.label || selectedColumn

                return (
                  <S.ColumnDragContainer
                    key={selectedColumn}
                    data-grid={{ x: 0, y: orderNumber, w: 1, h: 1, isResizable: false }}
                  >
                    <Typography noMargin>
                      <p>{columnLabel}</p>
                    </Typography>

                    <Icon name="DragDrop" size="sm" />
                  </S.ColumnDragContainer>
                )
              })}
            </GridLayout>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} variant="primary" data-pho="Save">
              Save
            </Button>
          </DialogActions>
        </S.ModalContentContainer>
      </Dialog>
    </>
  )
}

type ColumnOptions = {
  value: string
  label: string
  section: string
}

interface TableViewDialogProps {
  id?: string
  isPatch: boolean
  name?: string
  defaultOptions?: string[]
  handleSelectView?: Function
  handleCloseSettings: Function
  refetchGetUser: Function
  currentOrder?: { [key: string]: number }
}

export default TableViewDialog
