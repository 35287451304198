import * as React from 'react'

function SvgCloud(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 22" {...props}>
      <path
        d="M28 14.5a7.454 7.454 0 00-5.074-7.092A8.805 8.805 0 0014 0a8.8 8.8 0 00-8.92 7.408A7.49 7.49 0 007 21.975V22h14v-.025a7.503 7.503 0 007-7.475zM20.5 20h-13a5.5 5.5 0 114.637-8.46l1.683-1.08A7.474 7.474 0 007.5 7c-.107 0-.21.012-.316.016A6.88 6.88 0 0114 2a6.759 6.759 0 016.991 6.223l.032.758.74.173A5.496 5.496 0 0120.5 20z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCloud
