import React from 'react'
import fileDownload from 'js-file-download'
import { json2csvAsync, IFullOptions } from 'json-2-csv'
import { Button } from 'components/shared/Button'
import Alert from 'components/shared/Alert'
export default function useDownloadCsv(
  filename: string,
  data?: object[],
  csvOptions?: IFullOptions
) {
  // State
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<Nullable<string>>(null)

  // Vars
  const hasData = !!data?.length && data.length > 0
  const sanitizedFilename = filename.replace(/\//g, '').replace('.csv', '')

  // Callbacks
  const downloadCsv = React.useCallback(async () => {
    if (hasData) {
      try {
        setIsLoading(true)
        setError(null)
        json2csvAsync(data, csvOptions).then(csv => {
          fileDownload(csv, `${sanitizedFilename}.csv`)
          setIsLoading(false)
        })
      } catch (error) {
        setError(`Error exporting: ${(error as Record<string, string>).message || error}`)
        setIsLoading(false)
      }
    }
  }, [hasData, data, csvOptions, sanitizedFilename])

  const handleAlertClose = React.useCallback(() => {
    setError(null)
  }, [setError])

  // Components
  const DownloadCsvButton = React.useMemo(
    () =>
      ({ children = 'Download CSV', disabled }: { children?: string; disabled?: boolean }) => {
        return (
          <Button
            data-test="DownloadCsv"
            disabled={disabled ?? !hasData}
            onClick={downloadCsv}
            variant="borderless"
            icon={!!error ? 'CautionAlert' : 'Download'}
            iconSize="sm"
            loading={isLoading}
          >
            {children}
          </Button>
        )
      },
    [isLoading, error, hasData, downloadCsv]
  )

  const DownloadCsvAlert = React.useMemo(
    () => () => {
      if (error) {
        return (
          <Alert variant="page-error" onClose={handleAlertClose}>
            {error}
          </Alert>
        )
      }
      return null
    },
    [error, handleAlertClose]
  )

  return { downloadCsv, isLoading, error, disabled: !hasData, DownloadCsvButton, DownloadCsvAlert }
}
