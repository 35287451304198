import React, { useState } from 'react'
import includes from 'lodash/includes'
import get from 'lodash/get'
import { WindowLocation, useLocation } from '@reach/router'
import queryString from 'query-string'
import useApps from 'hooks/useApps'
import { ChartData } from 'components/shared/ChartData/types'
import { ChartAndMultiChartProps } from 'components/shared/chart/types'
import ChartContainer from 'components/shared/chart/ChartContainer'
import { getTotal, getAverage, getLatest } from 'components/shared/lineChart'
import { getLabelFromTimestamps, genericTimeValueList } from 'components/shared/VoCTimeMenu/utils'
import { getRouteWithQueries } from 'utils'

export default function MultiChart({
  dataArray,
  isTabbed,
  yFormat,
  legends,
  margin,
  componentsWithProps,
  queries,
  hideActionMenu = false,
  addTabIndexInURL,
  hasTabButtons,
  description,
  mainTitle,
  bottomLinkObj,
  activeTab,
  renderActionsMenu,
  tabButtons,
}: ChartAndMultiChartProps & {
  dataArray: ChartData[]
  componentsWithProps: Array<{
    Component: React.FunctionComponent<any>
    props: Record<string, any>
  }>
  queries?: any[]
  hideActionMenu?: boolean
  isTabbed?: boolean
  location?: WindowLocation
  addTabIndexInURL?: boolean
  hasTabButtons?: boolean
  description?: string
  mainTitle?: string
  activeTab: number
  bottomLinkObj?: {
    path: string
    title: string
  }
  renderActionsMenu: Function
  tabButtons: JSX.Element
}) {
  // #region Temporary UTC Fix
  const { appsByDataSource, entityID } = useApps()
  const isQubeApp = includes(appsByDataSource['qube'], entityID)
  // #endregion

  const location = useLocation()
  const query = queryString.parse((location && location.search) || '')
  const [yMinScaleIsAuto, setYMinScaleIsAuto] = useState(false)

  const { period } = query
  const periodLabel = getLabelFromTimestamps(genericTimeValueList, period as string)

  const currentData = get(dataArray[activeTab], 'formattedData') || dataArray[activeTab]
  const metaData = get(dataArray[activeTab], 'metaData')

  const CurrentComponent = componentsWithProps[activeTab].Component
  const {
    getOpenSearchUrl,
    zoomOption,
    title,
    handleTabChange,
    dataSource,
    showWarning,
    unappliedFilterLabels,
    subChartQuery,
    subChartType,
    ...currentComponentProps
  } = componentsWithProps[activeTab].props

  const handleLineZoom = () => {
    setYMinScaleIsAuto(!yMinScaleIsAuto)
  }

  const componentProps = componentsWithProps[activeTab].props
  const summaryContentData =
    componentProps?.buttonTabbedSummaryFormatter &&
    componentProps?.buttonTabbedSummaryFormatter({
      total: getTotal(currentData),
      average: getAverage(currentData),
      latest: getLatest(currentData?.lineData),
      data: currentData,
      timePeriod: periodLabel,
    })

  const summaryContent = {
    value: summaryContentData?.title,
    valueLabel: summaryContentData?.time,
    fullNumber: summaryContentData?.fullNumber,
  }

  const linkPath = bottomLinkObj ? getRouteWithQueries(bottomLinkObj?.path, location) : undefined
  const actionMenu = renderActionsMenu({
    getOpenSearchUrl,
    zoomOption,
    handleLineZoom,
    yMinScaleIsAuto,
    currentData,
    metaData,
    title,
    mainTitle,
    summaryContentData,
    linkPath,
    dataSource,
    isQubeApp,
    bottomLinkObj,
  })

  const component = (
    <CurrentComponent
      key={`CurrentComponent${activeTab}`}
      title={title}
      data={currentData}
      yFormat={yFormat}
      legends={legends}
      margin={margin}
      showTitle={false}
      yMinScaleIsAuto={yMinScaleIsAuto}
      hasTabButtons={isTabbed}
      chartType={subChartType}
      {...currentComponentProps}
    />
  )
  const chartContainerProps = {
    component,
    chartTitle: mainTitle,
    chartDescription: description,
    metric: subChartQuery?.metric,
    dataArray,
    showWarning,
    unappliedFilterLabels,
    chartsHeadingProps: {
      title: mainTitle,
      description: description,
      value: summaryContent?.value,
      valueLabel: summaryContent?.valueLabel,
      valueTooltip: summaryContent?.fullNumber,
      actionMenu: actionMenu,
    },
    tabButtons: tabButtons,
  }
  return <ChartContainer {...chartContainerProps} />
}
