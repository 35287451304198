import * as React from 'react'

function SvgSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 28" {...props}>
      <path
        d="M19 0v2.131a2.749 2.749 0 001.095 2.19l.39.292a2.74 2.74 0 003.16.088l1.673-1.115 4.03 6.717-2.13 1.42a2.737 2.737 0 000 4.554l2.13 1.42-4.03 6.717-1.673-1.115a2.744 2.744 0 00-3.16.087l-.39.293A2.75 2.75 0 0019 25.87V28h-8v-2.131a2.749 2.749 0 00-1.095-2.19l-.39-.292a2.74 2.74 0 00-3.16-.088l-1.673 1.115-4.03-6.717 2.13-1.42a2.737 2.737 0 000-4.554l-2.13-1.42 4.03-6.717 1.673 1.115a2.738 2.738 0 003.16-.087l.39-.293A2.75 2.75 0 0011 2.13V0h8zm-2 2h-4v.131a4.76 4.76 0 01-1.895 3.79l-.39.293a4.743 4.743 0 01-5.397.2l-1.97 3.283.543.362a4.737 4.737 0 010 7.882l-.543.362 1.97 3.284a4.746 4.746 0 015.397.2l.39.292A4.76 4.76 0 0113 25.869V26h4v-.131a4.76 4.76 0 011.895-3.79l.39-.293a4.745 4.745 0 015.397-.2l1.97-3.283-.543-.362a4.737 4.737 0 010-7.882l.543-.362-1.97-3.284a4.743 4.743 0 01-5.397-.2l-.39-.292A4.76 4.76 0 0117 2.131V2zm-2 6a6 6 0 016 6 6.007 6.007 0 01-6 6 6 6 0 110-12zm0 2a4 4 0 100 8 4 4 0 000-8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSettings
