import React from 'react'
import axios from 'axios'
import queryString from 'query-string'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import styled from 'styled-components'
import { useLocation, navigate } from '@reach/router'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import NotAuthenticated from 'components/shared/NotAuthenticated'
import analytics from 'utils/analytics'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface AuthUser {
  id: string
  name: string
  firstName: string
  lastName: string
  email: string
  pid: string
  roles: string[]
}

interface TokenMetadata {
  iat?: number
  exp?: number
}

interface AuthState extends TokenMetadata {
  isAuthenticated?: boolean
  isAuthorized?: boolean
  isDocsAuthorized?: boolean
  login: () => void
  accessToken?: string
  user?: AuthUser
}

const reactAPIEndpoint = process.env.REACT_APP_PRISM_API_ENDPOINT || 'http://localhost:8081'

axios.interceptors.response.use(
  response => response,
  async error => {
    if (error.message.includes('Request failed with status code 401')) {
      login()
    }
    return Promise.reject(error)
  }
)

function login(): void {
  window.location.href = `${
    process.env.REACT_APP_PRISM_API_ENDPOINT
  }/login?redirect=${encodeURIComponent(window.location.href)}`
}

const initialState = {
  login,
}

export const AuthContext = React.createContext<AuthState>(initialState)

function Auth({ children }: { children: React.ReactNode }) {
  const [authState, setAuthState] = React.useState<AuthState>(initialState)
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const queryAccessToken = queryParams.accessToken as string | null

  const authenticate = React.useCallback(
    async (accessToken: string) => {
      try {
        localStorage.removeItem('id')
        localStorage.removeItem('accessToken')
        analytics.track('loginStart', { opType: 'tokenExchange' })
        if (accessToken === 'undefined') {
          throw new Error('Authentication failed')
        }
        const { data: verifiedToken } = await axios.post<TokenMetadata & AuthUser>(
          `${reactAPIEndpoint}/tokens/access/verify`,
          { authorization: accessToken }
        )
        if (verifiedToken) {
          analytics.track('loginStop', {
            accountNumber: verifiedToken.id,
            opSuccess: true,
            userToken: verifiedToken.pid,
            configurationFactors: verifiedToken.email,
          })
          localStorage.setItem('id', verifiedToken.id)
          localStorage.setItem('accessToken', accessToken)
          setAuthState({
            ...authState,
            isAuthenticated: true,
            isAuthorized: verifiedToken.roles.some((role: string) => role.includes('prism-basic')),
            isDocsAuthorized: verifiedToken.roles.some((role: string) =>
              role.includes('prism-docs')
            ),
            ...pick(verifiedToken, ['iat', 'exp']),
            accessToken,
            user: omit(verifiedToken, ['iat', 'exp']),
          })
        } else {
          throw new Error('Access token invalid')
        }
      } catch (error) {
        analytics.track('loginStop', {
          opSuccess: false,
          appErrorCode: '0',
          appErrorType: 'authentication',
        })
        setAuthState({ ...authState, isAuthenticated: false })
      }
    },
    [authState]
  )

  // Authenticate
  React.useEffect(() => {
    analytics.track('loginStart', { opType: 'resumeAuth' })
    if (queryAccessToken) {
      analytics.track('loginStop', { opSuccess: true })
      const search = queryString.stringify(omit(queryParams, ['accessToken']))
      navigate(`${location.pathname}?${search}`)
      authenticate(queryAccessToken)
    } else {
      analytics.track('loginStop', {
        opSuccess: false,
        appErrorCode: '401',
        appErrorType: 'authentication',
      })
      login()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Render
  if (authState.isAuthenticated) {
    return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  }
  if (authState.isAuthenticated === false) {
    return <NotAuthenticated />
  }
  return (
    <Container>
      <LoadingSpinner />
    </Container>
  )
}

export default Auth
