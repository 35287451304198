import * as React from 'react'

function SvgWeather(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
      <path
        d="M15 23a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-14a6 6 0 106 6 6.007 6.007 0 00-6-6zm6.293-1.707l4-4 1.414 1.414-4 4-1.414-1.415zm-18 18l4-4 1.414 1.414-4 4-1.414-1.415zm0-20.586l1.414-1.415 4 4-1.414 1.415-4-4zm18 18l1.414-1.415 4 4-1.414 1.415-4-4zM14 .854h2V5h-2V.854zM14 25h2v4.144h-2V25zM.86 14.002H5v2H.86v-2zM25 16l.002-2 4.14.004-.003 2-4.14-.004z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgWeather
