import React from 'react'
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded'
import EntityIcon from './EntityIcon'
import * as S from './styles'
import Tooltip from '@material-ui/core/Tooltip'
import Flex from '../flex/Flex'
import Typography from 'components/shared/typography/Typography'
import { Group } from 'hooks/useGroups'

function Entity({
  title,
  description,
  isFavorite,
  disableClick = false,
  isExternal,
  currentGroup,
  isBeta,
  hasBottomDivider,
  domain,
  ...props
}: EntityProps) {
  const firstLetter = title[0]
  const secondLetter = description ? description[0] : title[1]
  const applicationName = currentGroup?.applicationIDs

  return (
    <S.EntityContainer
      disableClick={disableClick}
      disableRipple={disableClick}
      hasBottomDivider={hasBottomDivider}
      {...props}
    >
      <Tooltip
        arrow={true}
        title={
          applicationName
            ? applicationName?.map(data => (
                <Flex height={40} style={{ wordBreak: 'break-all' }}>
                  <ul>
                    <li>{data}</li>
                  </ul>
                </Flex>
              ))
            : ''
        }
        placement="right"
      >
        <S.EntityContent>
          <EntityIcon letters={`${firstLetter}${secondLetter}`} />
          {props.isCondensed !== true && (
            <S.ApplicationIDContainer>
              <S.ApplicationName>
                <S.StyleGroupRow>{title}</S.StyleGroupRow>
              </S.ApplicationName>
              {description && <S.AppTypeText>{description}</S.AppTypeText>}
            </S.ApplicationIDContainer>
          )}
        </S.EntityContent>
      </Tooltip>
      {isFavorite === true && <S.StyledStarIcon data-test="starIcon" />}
      {isFavorite === false && <StarBorderRoundedIcon data-test="starBorderRoundedIcon" />}
      {isBeta && <S.StyledChip label="BETA" color="secondary" />}
      {isExternal && <S.StyledLaunchIcon data-test="launchIcon" />}
      {domain && (
        <Typography variant="bodyDisplay" noMargin>
          {domain}
        </Typography>
      )}
    </S.EntityContainer>
  )
}

interface EntityProps {
  title: string
  description?: string
  isFavorite?: boolean
  disableClick?: boolean
  isExternal?: boolean
  isBeta?: boolean
  onClick?: Function
  currentGroup?: Group
  isCondensed?: boolean
  hasBottomDivider?: boolean
  domain?: string
}

export default Entity
