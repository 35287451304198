import * as React from 'react'

function SvgSketch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M23.268 1.42A2.843 2.843 0 0020.803 0H7.197a2.843 2.843 0 00-2.465 1.42L0 9.13 14 28 28 9.13l-4.732-7.71zM8.478 8.116L14 2.435l5.521 5.681H8.48zm11.832 2.029L14 23.942 7.69 10.145h12.62zm-14.789 0l3.648 8.014-6.014-8.014H5.52zm16.958 0h2.366l-6.014 8.014 3.648-8.014zm2.563-2.029h-2.169v-3.55l2.17 3.55zm-4.14-6.087v4.667l-4.536-4.667h4.535zm-9.268 0L7.099 6.696V2.029h4.535zM5.127 4.565v3.551h-2.17l2.17-3.55z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSketch
