import * as React from 'react'

function SvgChevronRightCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 0c7.728.01 13.991 6.272 14 14 0 7.732-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm0 2C7.376 2.008 2.007 7.376 2 14c0 6.628 5.373 12 12 12s12-5.372 12-12c0-6.627-5.373-12-12-12zm-2.043 4.793L19.164 14l-7.207 7.208-1.414-1.415L16.336 14l-5.793-5.792 1.414-1.415z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronRightCircle
