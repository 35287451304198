import React from 'react'
import omit from 'lodash/omit'
import reduce from 'lodash/reduce'
import { Link, useLocation, navigate } from '@reach/router'
import useApps from 'hooks/useApps'
import useHeader from 'hooks/useHeader'
import Entity from 'components/shared/Entity'
import {
  getServerAnalyticsItems,
  getTVEResourcesItems,
  getVoiceOfCustomerItems,
} from 'components/layout/DashboardSidebar/items'
import {
  getAppOrGroupLink,
  getClientAnalyticsDevResourcesItems,
  getDomain,
  APP_PLACEHOLDER,
} from 'components/layout/Topbar/utils'
import * as S from '../../styles'

export default function AppFavoritesList({ appFavorites, handleClose }: AppFavoritesListProps) {
  const { appsByDataSource, appsByDomain, entityID } = useApps()
  const { filterValues, updateValues } = useHeader()
  const location = useLocation()

  const clientAnalyticsDevResourcesItems = getClientAnalyticsDevResourcesItems()
  const serverAnalyticsItems = getServerAnalyticsItems(APP_PLACEHOLDER)
  const tveItems = getTVEResourcesItems(APP_PLACEHOLDER)
  const voiceOfCustomerItems = getVoiceOfCustomerItems(APP_PLACEHOLDER)

  function getSection(app: string) {
    if (appsByDataSource.quantum.includes(app)) return 'client-analytics'
    if (appsByDataSource.qube.includes(app)) return 'server-analytics'
    if (appsByDataSource.tve.includes(app)) return 'tve-dashboards'
    if (appsByDataSource.journey_survey?.includes(app)) return 'voice-of-customer'
    if (appsByDataSource.employee_experience?.includes(app)) return 'voice-of-customer'
    return 'client-analytics'
  }

  function getItems(app: string) {
    if (appsByDataSource.quantum.includes(app)) return clientAnalyticsDevResourcesItems
    if (appsByDataSource.qube.includes(app)) return serverAnalyticsItems
    if (appsByDataSource.tve.includes(app)) return tveItems
    if (appsByDataSource.journey_survey?.includes(app)) return voiceOfCustomerItems
    if (appsByDataSource.employee_experience?.includes(app)) return voiceOfCustomerItems
    return clientAnalyticsDevResourcesItems
  }

  return (
    <div>
      {appFavorites.map((appFavorite: string) => {
        const hasAppType = appFavorite.includes('@')
        const section = getSection(appFavorite)
        const domain = getDomain(appFavorite, appsByDomain)
        const items = getItems(appFavorite)

        const link = getAppOrGroupLink(
          items,
          domain,
          appFavorite,
          location.pathname,
          entityID,
          section,
          false
        )

        function handleLinkClick(value: any) {
          const filtersWithoutDefaultFilters = omit(filterValues, [
            'environment',
            'period',
            'step',
            'groupBy',
          ])
          const filtersWithEmptyValues = reduce(
            filtersWithoutDefaultFilters,
            (acc, value, key) => {
              acc[key] = []
              return acc
            },
            {}
          )
          // This is used to remove unnecessary filters when app or group is switched.
          // Most of filter values are not relevant to different app. For example, application version, experiment uuids are different for each app.
          updateValues(filtersWithEmptyValues)
          handleClose()
          navigate(link)
        }

        return (
          <Link key={`${appFavorite}`} onClick={handleLinkClick} to={link}>
            <S.FavoriteContainer>
              <Entity
                title={hasAppType ? appFavorite.split('@')[0] : appFavorite}
                description={hasAppType ? appFavorite.split('@')[1] : ''}
                data-pho={appFavorite}
              />
            </S.FavoriteContainer>
          </Link>
        )
      })}
    </div>
  )
}

interface AppFavoritesListProps {
  appFavorites: string[]
  handleClose: Function
}
