import React from 'react'
import { IconButton, IconButtonProps } from 'components/shared/IconButton'
import { useToggle } from 'hooks/useToggle'
import {MenuItem, MenuItemObject } from './MenuItem'
import * as S from './styles'
import { useDarkMode } from 'components/App/Providers/DarkMode'

export interface MenuProps {
  /** Takes the shape of { label: string; onClick: () => void; icon?: IconName; iconColor?: string; disabled?: boolean; isDestructive?: boolean; isLoading?: boolean } */
  items: MenuItemObject[]
  /** Options to customize the underlying default icon button for the menu */
  iconButton?: IconButtonProps
  /** A render prop for custom anchors for the menu */
  children?: (toggleOpen: (isOpen?: boolean) => void) => React.ReactNode
  /** Optional props used to tag Menu component */
  'data-test'?: string
  /** Optional props used to tag Menu component */
  'data-pho'?: string
  /** Optional props for the icon button that acts an anchor for the menu */
  anchorIconProps?: IconButtonProps
}

export const Menu = ({ items, iconButton, children, ...props }: MenuProps) => {
  const { isDarkMode } = useDarkMode()
  const menuRef = React.useRef<HTMLDivElement>(null)
  const [isOpen, toggleOpen] = useToggle()

  function handleClickOutside(event: MouseEvent) {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      toggleOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [menuRef])

  const badgesApplied = items.filter(item => item?.badgeCount) || []
  const enableRedDot = badgesApplied.length > 0

  return (
    <S.MenuContainer ref={menuRef}>
      {children ? (
        children(toggleOpen)
      ) : (
        <>
          {enableRedDot && (
            <S.RedDotContainer>
              <S.RedDot $isDarkMode={isDarkMode} />
            </S.RedDotContainer>
          )}
          <IconButton icon="MoreVert" {...iconButton} onClick={() => toggleOpen()} />
        </>
      )}

      <S.Menu $isOpen={isOpen} data-pho={props['data-test']}>
        {items.map(item => (
          <MenuItem toggleOpen={toggleOpen} {...item} isDarkMode={isDarkMode} />
        ))}
      </S.Menu>
    </S.MenuContainer>
  )
}
