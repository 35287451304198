import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import useApi from 'hooks/useApi'
import useApps from 'hooks/useApps'
import useChartRefresh from 'hooks/useChartRefresh'
import useFilterValues from 'hooks/useFilterValues'
import { getRefreshIntervalFromStep } from 'utils'
import CustomLoading from 'components/shared/customLoading'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import { DataType } from 'components/shared/chart/types'
import FormatData from './FormatData'
import { getService, getDataSource, getQueryAppsStringified } from './utils'
import * as T from './types'

function SingleChartData({
  children,
  query,
  type,
  title,
  isSmall,
  extendContent,
  filterValues,
  ActionsMenu,
  renderActionsMenu,
}: {
  children: T.ChildrenCallback
  query: T.Query
  type: DataType
  title: string
  isSmall?: boolean
  extendContent?: boolean
  filterValues?: Record<string, any>
  ActionsMenu?: React.ReactElement
  renderActionsMenu: Function
}) {
  const { step, partialData } = useFilterValues()
  const { currentApps, entityID, appsByDataSource } = useApps()
  const service = getService(type)
  const hasAggrQuery = !isEmpty(query.aggrQuery)
  const [refreshInterval, setRefreshInterval] = useState<number>(() =>
    getRefreshIntervalFromStep(Number(step), !!partialData)
  )
  const chartQuery = useApi({
    service,
    query: `${query.query}&${queryString.stringify(
      getQueryAppsStringified(currentApps, query?.query, entityID, appsByDataSource.qube)
    )}`,
    refreshInterval,
  })

  const previousQuery = useApi(
    query?.previousQuery
      ? {
          service,
          query: `${query.previousQuery}&${queryString.stringify(
            getQueryAppsStringified(currentApps, query?.query, entityID, appsByDataSource.qube)
          )}`,
          refreshInterval,
        }
      : { service: null }
  )

  const aggrQuery = useApi(
    query.aggrQuery
      ? {
          service,
          query: `${query.aggrQuery}&${queryString.stringify(
            getQueryAppsStringified(currentApps, query?.aggrQuery, entityID, appsByDataSource.qube)
          )}`,
        }
      : { service: null }
  )

  const compareQuery = useApi(
    query.compareQuery
      ? {
          service,
          query: `${query.compareQuery}&${queryString.stringify(
            getQueryAppsStringified(
              currentApps,
              query?.compareQuery,
              entityID,
              appsByDataSource.qube
            )
          )}`,
        }
      : { service: null }
  )

  useChartRefresh(setRefreshInterval, refreshInterval, chartQuery.data)

  if (chartQuery.isLoading || (hasAggrQuery && aggrQuery.isLoading)) {
    return (
      <CustomLoading
        title={title}
        dataSource={!isSmall && getDataSource(type)}
        isExtendContentActive={extendContent}
        chartType={type}
      />
    )
  }

  if (isEmpty(chartQuery.data) || chartQuery.error || aggrQuery.error) {
    return (
      <NoDataChartMessage
        title={title}
        dataSource={!isSmall && getDataSource(type)}
        ActionsMenu={renderActionsMenu([
          { metaData: chartQuery.metaData || chartQuery.error || aggrQuery.error },
        ])}
        isExtendContentActive={extendContent}
      />
    )
  }

  return (
    <FormatData
      rawDataArray={[
        {
          data: chartQuery.data,
          aggrData: aggrQuery.data,
          compareData: compareQuery.data,
          previousData: previousQuery.data,
        },
      ]}
      types={[type]}
      chartType={type}
      filterValues={filterValues}
      metaData={chartQuery.metaData}
    >
      {children}
    </FormatData>
  )
}

export default SingleChartData
