import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import isNumber from 'lodash/isNumber'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isBoolean from 'lodash/isBoolean'
import get from 'lodash/get'
import take from 'lodash/take'
import join from 'lodash/join'
import { types } from 'components/voiceOfCustomer'
import { OptionProps } from 'components/shared/OptionGroup'
import { getCategoryByID } from 'screens/entityID/client-analytics/voice-of-customer/edit-reviews/utils'

const PILL_BLOCK_LIST = [
  'filters',
  'period',
  'periodOffset',
  'step',
  'groupBy',
  'origintz',
  'hiddenEventTypes',
  'variantUuids',
  'accessToken',
]
export type Status = 'RUNNING' | 'COMPLETED' | 'CANCELLED' | 'DELETED' | 'TESTING'

export function getVoCPillValues(
  filterValues: Record<string, any>,
  state: Record<string, any>
): Record<string, any> {
  const { categoryOptions, categoryGroupOptions, manualCategories, middleCategoryOptions } = state
  const filterNames = [
    'vocCategory',
    'manualCategory',
    'vocCategoryGroup',
    'middleCategory',
    'vocMidCategory',
  ]

  const valuesToUpdate = filterNames.reduce((acc, id) => {
    if (filterValues[id]) {
      if (id === 'manualCategory') {
        const newValues = filterValues[id].map((value: string) => {
          const selectedManualCategory = manualCategories.find(
            (manualCategory: types.ManualCategory) => manualCategory?._id === value
          )
          const category = getCategoryByID(
            state?.categoriesData,
            selectedManualCategory?.category
          )?.category
          return `${selectedManualCategory?.manualCategory} (${category})`
        })
        acc[id] = newValues
      } else if (id === 'vocCategory') {
        const newValues = filterValues.vocCategory.map(
          (value: string) =>
            categoryOptions.find((option: Record<string, string>) => option.value === value)
              ?.label ?? ''
        )
        acc[id] = newValues
      } else if (id === 'vocMidCategory') {
        const newValues = filterValues.vocMidCategory.map(
          (value: string) =>
            middleCategoryOptions.find((option: Record<string, string>) => option.value === value)
              ?.label ?? ''
        )
        acc[id] = newValues
      } else if (id === 'vocCategoryGroup') {
        const newValues = filterValues.vocCategoryGroup.map(
          (value: string) =>
            categoryGroupOptions.find((option: Record<string, string>) => option.value === value)
              ?.label ?? ''
        )
        acc[id] = newValues
      } else if (id === 'middleCategory') {
        const newValues = filterValues.middleCategory.map(
          (value: string) =>
            middleCategoryOptions.find((option: Record<string, string>) => option.value === value)
              ?.label ?? ''
        )
        acc[id] = newValues
      }
    }
    return acc
  }, {})
  return { ...filterValues, ...valuesToUpdate }
}

export const getPillsWithValues = (
  filterValues: Record<string, any> = {},
  state: Record<string, any> = {},
  isVoC?: boolean
) => {
  const pills = omit(isVoC ? getVoCPillValues(filterValues, state) : filterValues, PILL_BLOCK_LIST)

  const pillsToReturn = pickBy(pills, pill => {
    return (isNumber(pill) || !isNil(pill)) && (!isEmpty(pill) || isBoolean(pill) || isNumber(pill))
  })
  return pillsToReturn
}

export const getSequenceEventTypeValues = (filterValues: Record<string, any>) => {
  const filteredFilters = filterValues.filters.filter(
    (value: Record<string, any>) => value.id === 'hiddenEventTypes'
  )
  const eventTypeFilter = !isEmpty(filteredFilters) && filteredFilters[0]

  if (!isEmpty(eventTypeFilter.options) && !isEmpty(filterValues.hiddenEventTypes)) {
    const pillValues = eventTypeFilter.options
      .filter((option: OptionProps) => filterValues.hiddenEventTypes.includes(get(option, 'value')))
      .map((option: OptionProps) => {
        return {
          count: get(option, 'label').replace(/\D/g, ''),
          pillLabel: get(option, 'value'),
        }
      })

    return pillValues.sort((a: any, b: any) => a.count - b.count)
  } else return []
}

export const getEventTypePillLabel = (sequenceEventTypeValues: EventValueType[]) => {
  const firstThreeValues = take(sequenceEventTypeValues, 3).map(
    (value: EventValueType) => value.pillLabel
  )
  const joinedLabels = join(firstThreeValues, ', ')

  return sequenceEventTypeValues.length <= 3
    ? `Hidden Event Types: ${joinedLabels}`
    : `Hidden Event Types: ${joinedLabels}...`
}

export const getVariantUuidLabel = (variantUuidValues: string[]) => {
  const firstThreeValues = take(variantUuidValues, 1).map((value: string) => value)
  const joinedLabels = join(firstThreeValues, ', ')

  return variantUuidValues.length <= 1
    ? `VariantUuids: ${joinedLabels}`
    : `VariantUuids: ${joinedLabels}...`
}

export type EventValueType = {
  count: string
  pillLabel: string
}

export interface Experiment {
  experimentStatus: {
    name: Status
  }
}

export type ExperimentUUID = {
  name: string
  uuid: string
  variants: { name: string; uuid: string }[]
  experimentStatus: { name: string }
  startTime: string
  stopTime: string
  lastModifiedTime: string
}
