import styled, { css } from 'styled-components'
import Typography from 'components/shared/typography'
import { IconButton } from 'components/shared/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Link from 'components/shared/Link'
import { colors } from 'components/shared/utils/colors'
import Flex from 'components/shared/flex'

export const ActionsMenuContainer = styled.div<{
  inSmallTabsContainer?: boolean
  hasAbsolutePosition?: boolean
}>`
  flex-grow: 0;
  margin-top: ${props => (props.inSmallTabsContainer ? '-0.7rem' : 'initial')};
  ${props =>
    props.hasAbsolutePosition &&
    css`
      position: absolute;
      top: 0;
      right: 0;
    `}
`

export const BottomContainer = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`

export const ChartFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0rem 0.5rem 0rem;
  align-items: center;
  height: 2.3125rem;
`
export const AdditionalLink = styled(Flex)``

export const ChartFooterContainer = styled.div`
  margin: 1rem 0 0;
`

export const ChartInformation = styled.div`
  display: inline-block;
`

export const ChartMetaData = styled.div`
  display: inherit;
`

export const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    top: 0;
    right: 0;
  }
`

export const Container = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: 'Open Sans';
  height: 100%;
  padding: 1rem;
  width: 100%;
`

export const OpenSearchLinkContainer = styled(MenuItem)`
  padding: 6px 31px;
  && {
    min-width: 120px;
    width: auto;
    height: 44px;
  }
`

export const OpenSearchLogo = styled.img`
  height: 1rem;
  width: 1rem;
  margin: 0 16px 0 0;
`

export const TableContainer = styled.div`
  padding: 1rem;
  width: 500px;
  height: 70vh;
`

export const TopContainer = styled.div<{ withTopMargin?: boolean }>`
  display: flex;
  flex-grow: 0;
  justify-content: space-between;
`

export const Warning = styled.p`
  font-size: 0.7rem;
  color: ${colors.red10};
  line-height: 1rem;
  margin-bottom: 0;
`

export const InfoContainer = styled.div`
  height: 0.8rem;
  margin-bottom: -0.8rem;
  display: flex;
  justify-content: space-between;
`

export const ActionIconButtons = styled(IconButton)`
  padding: 0.2rem;
  margin-left: 0.5rem;
`

export const TopContent = styled.div`
  display: flex;
  width: 100%;
`

export const ChartDescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1 0 0;
`

export const ChartTitle = styled(Typography)`
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
export const ChartDescription = styled(Typography)`
  margin-top: 4px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const ChartValueLabel = styled(Typography)`
  min-width: 100px;
  text-align: right;
`

export const SummaryContainer = styled.div`
  text-align: right;
`

export const SummaryDescription = styled.p`
  && {
    color: ${colors.gray30};
    line-height: 1rem;
    margin-bottom: 0;
  }
`

export const StyledLink = styled(Link)`
  font-size: 0.875rem;
`

export const UniqueUserWarningLink = styled(Link)`
  color: ${colors.blue20};
  text-decoration: underline;
`

export const MetricQuery = styled.div`
  white-space: pre-wrap;
  min-width: 600px;
`

export const AlertContainer = styled.div`
  && div {
    font-size: 12px;
  }
  && a {
    font-size: 12px;
  }
  padding: 0.5rem 1rem 0;
  margin: 0 -1rem;
`

export const WarningContainer = styled.div`
  display: flex;
  margin-bottom: -0.5rem;

  && a {
    margin-left: 0.25rem;
    font-size: 12px;
  }

  > div {
    margin-right: 1.5rem;
  }
`

export const WarningContent = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-right: 0.5rem;
  }
`

export const MetaDataContainer = styled.div`
  display: flex;
  overflow: auto;
  white-space: nowrap;
`
