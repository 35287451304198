import * as React from 'react'

function SvgLightingF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 28" {...props}>
      <path
        d="M9 0a9 9 0 00-9 9c0 2.6 1.5 5.1 2.6 7.1A9.1 9.1 0 014 19v4l1.2 5h7.6l1.2-4.8V19a9.1 9.1 0 011.4-2.9c1.1-2 2.6-4.5 2.6-7.1a9 9 0 00-9-9zM7 10h4a1 1 0 010 2h-1v6H8v-6H7a1 1 0 010-2zm-.2 16l-.5-2h5.4l-.5 2H6.8zm5.2-4H6v-2h6v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLightingF
