import { ButtonProps } from './Button'
import * as S from './styles'

export function getButtonVariant({
  variant,
  disabled,
  isDarkMode,
}: Pick<ButtonProps, 'disabled' | 'variant'> & {
  isDarkMode: boolean
}) {
  switch (variant) {
    case 'secondary': {
      if (disabled) return S.SecondaryButtonDisabled
      return isDarkMode ? S.SecondaryButtonDark : S.SecondaryButtonLight
    }
    case 'neutral': {
      if (disabled) return S.NeutralButtonDisabled
      return isDarkMode ? S.NeutralButtonDark : S.NeutralButtonLight
    }
    case 'borderless': {
      if (disabled) return S.BorderlessButtonDisabled
      return isDarkMode ? S.BorderlessButtonDark : S.BorderlessButtonLight
    }
    default: {
      if (disabled) {
        return isDarkMode ? S.PrimaryButtonDarkDisabled : S.PrimaryButtonLightDisabled
      }
      return S.PrimaryButton
    }
  }
}
