import * as React from 'react'

function SvgCreditCard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 20" {...props}>
      <path
        d="M25 0a3.003 3.003 0 013 3v14a3.003 3.003 0 01-3 3H0V3a3.003 3.003 0 013-3zm1 10H2v8h23a1 1 0 001-1v-7zm-12 4v2H3v-2h11zm12-8H2v2h24V6zm-1-4H3a1 1 0 00-1 1v1h24V3a1 1 0 00-1-1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCreditCard
