import first from 'lodash/first'
import isNil from 'lodash/isNil'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import numeral from 'numeral'
import { WindowLocation } from '@reach/router'
import Flex from 'components/shared/flex/Flex'
import { periodType } from 'components/shared/VoCTimeMenu/types'
import { VocFilterOption } from 'screens/entityID/client-analytics/voice-of-customer/State'
import {
  devVoiceOfCustomerClient,
  prodVoiceOfCustomerClient,
  devVoiceOfCustomerClientNoCache,
  prodVoiceOfCustomerClientNoCache,
  devVocLink,
  prodVocLink,
  devVocLinkNoCache,
  prodVocLinkNoCache,
} from 'utils/graphQL'
import {
  reviewsView,
  detailsView,
  epepView,
  allColumnHeaderLabels,
  reviewsViewColumnHeaderLabels,
  detailsViewColumnHeaderLabels,
  epepViewColumnHeaderLabels,
  sortedColumnOptions,
  DESCRIPTION_CHARACTER_LIMIT,
} from './constants'
import * as T from './types'

export function getVocClient(environment: string, disableCache?: boolean) {
  switch (environment) {
    case 'staging':
      return disableCache ? devVoiceOfCustomerClientNoCache : devVoiceOfCustomerClient
    default:
      return disableCache ? prodVoiceOfCustomerClientNoCache : prodVoiceOfCustomerClient
  }
}

export function getVocUrl(environment: string, disableCache?: boolean) {
  switch (environment) {
    case 'staging':
      return disableCache ? devVocLinkNoCache : devVocLink
    default:
      return disableCache ? prodVocLinkNoCache : prodVocLink
  }
}

export function getVocPath(page: string, entityID?: string, location?: WindowLocation) {
  return `/${entityID}/voice-of-customer/${page}${location?.search ?? ''}`
}

export function getIsReviewed(vocIsReviewed?: any) {
  const matchesTrue = /true/i
  if (matchesTrue.test(vocIsReviewed) || matchesTrue.test(vocIsReviewed && first(vocIsReviewed))) {
    return { isReviewed: true }
  }
  if (vocIsReviewed === false) {
    return { isReviewed: false }
  }
  return {}
}

export function getFeedbackMetrics(feedback: T.FeedbackModel): {
  CSAT: string | undefined
  CES: string | undefined
  NPS: string | undefined
  SR: string | undefined
  SCP: boolean
  starRating: string | undefined
  starRatingExternal: string | undefined
  userSelectedCategory: string | undefined
} {
  return {
    CSAT: feedback.CSAT ? feedback.CSAT : undefined,
    CES: feedback.CES ? feedback.CES : undefined,
    NPS: feedback.NPS ? feedback.NPS : undefined,
    SR: feedback.SR ? feedback.SR : undefined,
    SCP: feedback.SCP,
    starRating: feedback.starRating ? feedback.starRating : undefined,
    starRatingExternal: feedback.starRatingExternal ? feedback.starRatingExternal : undefined,
    userSelectedCategory: feedback.userSelectedCategory ? feedback.userSelectedCategory : undefined,
  }
}

export function formatBoolean(value?: Nullable<string | number>, showNA?: boolean): string | null {
  if (isNil(value) || value === '') {
    return showNA ? 'N/A' : null
  }
  return Number(value) === 1 ? 'Yes' : 'No'
}

export const VoCTimeFilterOptions = [
  {
    label: 'Yesterday',
    value: '1d',
    valueInHrs: 24,
    type: periodType.absoluteDays,
    steps: [
      {
        value: 100,
        step: 86400000 / 24,
        label: 'yesterday',
      },
    ],
    periodOffset: 1,
  },
  {
    label: 'Last 10 days',
    value: '10d',
    valueInHrs: 240,
    type: periodType.absoluteDays,
    steps: [
      {
        value: 100,
        step: 86400000 / 24,
        label: 'yesterday',
      },
    ],
    periodOffset: 0,
  },
  {
    label: 'Last 30 days',
    value: '30d',
    valueInHrs: 720,
    type: periodType.absoluteDays,
    steps: [
      {
        value: 100,
        step: 86400000,
        label: '30d',
      },
    ],
    periodOffset: 0,
  },
  {
    label: 'Last Fiscal Week',
    value: '1fw',
    valueInHrs: 168,
    type: periodType.absoluteWeeks,
    steps: [
      {
        value: 100,
        step: (86400000 / 24) * 7,
        label: '1fw',
      },
    ],
    periodOffset: 1,
  },
  {
    label: 'Last Fiscal Month',
    value: '1fm',
    valueInHrs: 720,
    type: periodType.absoluteMonths,
    steps: [
      {
        value: 100,
        step: 86400000,
        label: '1fm',
      },
    ],
    periodOffset: 1,
  },
  {
    label: 'Current Fiscal Month (To Date)',
    value: '1fm',
    valueInHrs: 720,
    type: periodType.absoluteMonths,
    steps: [
      {
        value: 100,
        step: 86400000,
        label: '1fm',
      },
    ],
    periodOffset: 0,
  },
]

export function getMetricTrendDisplay(metricName: string, value: number) {
  switch (metricName) {
    case 'SR':
    case 'avgSR':
    case 'Success Rate':
    case '% of Total':
    case '% of Reviewed':
      return numeral(value).format('0.0%')
    case 'feedbackCount':
    case 'awsSentiment':
    case 'nullVerbatimsCount':
    case 'Null Verbatim Count':
      return numeral(value).format('0,0')
    case 'NPS':
    case 'avgNPS':
    case 'CSAT':
    case 'avgCSAT':
      return numeral(value).format('0,0.0')
    case 'percentageChange':
      return numeral(value).format('0%')
    default:
      return numeral(value).format('0,0.00')
  }
}

export function numberWithCommas(num: number = 0) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function getCategoryFilterFromState(
  category: string[] | null | undefined
): { category: string[] } | {} {
  return !isNil(category) && !isEmpty(category) ? { category } : {}
}

export function getManualCategoryFilterFromState(
  manualCategory: string[] | null | undefined
): { manualCategory: string[] } | {} {
  return !isNil(manualCategory) && !isEmpty(manualCategory) ? { manualCategory } : {}
}

export function getMatchingGroupFromId(columnGroups: T.ColumnGroup[], viewId?: string) {
  return find(columnGroups, function (columnGroup: T.ColumnGroup) {
    return columnGroup._id === viewId
  })
}

export function getCurrentOrder(columnGroup: T.ColumnGroup) {
  return columnGroup.columns.reduce((acc: any, column: T.UserColumn) => {
    return { ...acc, [column.name]: column.orderNumber }
  }, {})
}

export function getSortedColumnHeaders(
  currentOrder: { [key: string]: number },
  columnHeaders: T.DefaultColumnHeader[]
) {
  return isEmpty(currentOrder)
    ? columnHeaders
    : sortBy(columnHeaders, function (header) {
        return currentOrder[header.id]
      })
}

export function getActiveColumnHeaders(newViewId: string, columnGroups: T.ColumnGroup[]) {
  const matchingSavedGroup = columnGroups.find(
    (columnGroup: T.ColumnGroup) => columnGroup._id === newViewId
  )

  const matchingSavedGroupIds = matchingSavedGroup
    ? matchingSavedGroup.columns.map((column: T.UserColumn) => column.label)
    : []

  if (!isEmpty(matchingSavedGroupIds)) {
    return matchingSavedGroupIds
  } else if (newViewId === detailsView.value) {
    return detailsViewColumnHeaderLabels
  } else if (newViewId === reviewsView.value) {
    return reviewsViewColumnHeaderLabels
  } else if (newViewId === epepView.value) {
    return epepViewColumnHeaderLabels
  } else {
    return allColumnHeaderLabels
  }
}

export function getColumnValuesToSet(
  selectedColumns: string[],
  newOrder: { [key: string]: number }
) {
  return selectedColumns.map((selectedColumn: string) => {
    const columnToSet = find(sortedColumnOptions, { value: selectedColumn })
    return {
      name: columnToSet?.value,
      label: columnToSet?.label,
      orderNumber: columnToSet?.value ? newOrder[columnToSet?.value] : 0,
    }
  })
}

export function getLayoutOrder(layout: T.DraggableColumn[]) {
  return layout.reduce((acc: any, column: T.DraggableColumn) => {
    return { ...acc, [column.i]: column.y }
  }, {})
}

export function getHelperText(descriptionLength: number, textError?: boolean) {
  const helperTextCharacterLimit = `Character limit: ${DESCRIPTION_CHARACTER_LIMIT}`
  const helperTextCharacterLeft = `Character left: ${
    DESCRIPTION_CHARACTER_LIMIT - descriptionLength
  }`

  const helperText = (
    <Flex justifyContent="space-between">
      <Flex>Type the Description</Flex>
      <Flex>{descriptionLength > 0 ? helperTextCharacterLeft : helperTextCharacterLimit}</Flex>
    </Flex>
  )

  const helperTextError = (
    <Flex justifyContent="flex-end">
      <Flex>
        {`Exceeded the character limit
        ${descriptionLength}/${DESCRIPTION_CHARACTER_LIMIT}`}
      </Flex>
    </Flex>
  )
  return textError ? helperTextError : helperText
}

export function wrapBoldMarkDown(string: string) {
  return `**${string}**`
}

export function wrapLinkMarkDown(link: string, linkName: string) {
  return `[${linkName}](${link}) `
}

export function getUsableMiddleLevelCategories(
  middleLevelValues: T.CategoryModel[],
  topLevelValues: T.CategoryModel[],
  selectedParentId?: string | null
) {
  return middleLevelValues?.filter(value => {
    const selectedParentCheck = selectedParentId ? value.parent === selectedParentId : true
    const directParentCheck = topLevelValues.some(
      topLevelValue => topLevelValue._id === value.parent
    ) // For now we are not using third level categories: https://gitlab.spectrumflow.net/client-analytics/sst/dev/prism/-/issues/4848

    if (selectedParentId) {
      return selectedParentCheck && directParentCheck
    } else return directParentCheck
  })
}

export function getMiddleLevelCategories(categories: T.CategoryModel[]) {
  return categories?.filter(value => value.category !== null && !isNil(value.parent))
}

export function getTopLevelCategories(categories: T.CategoryModel[]) {
  return categories?.filter(value => value.category !== null && isNil(value.parent))
}

export function groupCategories(
  editableOptions: T.CategoryModel[],
  categoryValues: T.CategoryModel[]
) {
  return editableOptions.reduce(
    (acc: Record<string, T.ManualCategory[]>, editableOption: T.ManualCategory) => {
      const categoryId = editableOption.category
      const categoryName =
        categoryValues.find(categoryValue => categoryValue._id === categoryId)?.category || ''

      if (!isNil(categoryId)) {
        const manualCategoriesToAdd = !isNil(acc[categoryName])
          ? acc[categoryName].concat(editableOption)
          : [editableOption]

        return {
          ...acc,
          [categoryName]: manualCategoriesToAdd,
        }
      } else return acc
    },
    {}
  )
}

/**
 * Filters out archived categories from the list of categories
 * If the mid-level category OR its parent category is archived, it will be filtered out
 */
export function getUnarchivedTopLevelCategories(
  categoriesToFilter: T.CategoryModel[]
): T.CategoryModel[] {
  return categoriesToFilter.filter(category => !category.isArchived && !category.parent)
}

/**
 * Filters out archived categories from the list of categories
 * If the mid-level category OR its parent category is archived, it will be filtered out
 */
export function getUnarchivedMidLevelCategories(
  categoriesToFilter: Array<VocFilterOption | T.CategoryModel>,
  allCategories: T.CategoryModel[]
) {
  return categoriesToFilter.filter(category => {
    const parentCategory = allCategories.find(parent => parent._id === category.parent)
    return !category.isArchived && !parentCategory?.isArchived
  })
}

/**
 * Filters out archived manual categories from the list of categories
 * If the manual cateogry, its mid-level parent AND/OR its top-level parent category is archived, it will be filtered out
 * This logic is needed because in some cases, there can be a manual category that has a top-level parent, instead of a mid-level parent
 */
export function getUnarchivedManualCategories(
  categoriesToFilter: Array<VocFilterOption | T.ManualCategory>,
  allCategories: T.CategoryModel[]
) {
  return categoriesToFilter.filter(manualCategory => {
    const parentCategory = allCategories.find(category => category._id === manualCategory.category)
    const topLevelCategory = allCategories.find(
      (category: T.CategoryModel) => category._id === parentCategory?.parent
    )
    return (
      !manualCategory.isArchived && !parentCategory?.isArchived && !topLevelCategory?.isArchived
    )
  })
}
