import * as React from 'react'

function SvgLayersF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M26 16.27v2.31L13 26 0 18.58v-2.31l13 7.43 13-7.43zM13 0l11.46 6.55a2.971 2.971 0 011.51 2.6 3 3 0 01-1.51 2.61L13 18.3 1.54 11.76a3.002 3.002 0 010-5.21L13 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLayersF
