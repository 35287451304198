import * as React from 'react'

function SvgMail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M0 0v21a3.003 3.003 0 003 3h22a3.003 3.003 0 003-3V0H0zm23.43 2L14 10.643 4.572 2H23.43zM25 22H3a1 1 0 01-1-1V2.356l12 11 12-11V21a1 1 0 01-1 1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMail
