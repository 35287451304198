import * as React from 'react'

function SvgShieldF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 30" {...props}>
      <path
        d="M12 .901L0 6.356V9.47a22.095 22.095 0 0011.521 19.408l.479.26.479-.26A22.095 22.095 0 0024 9.47V6.356L12 .901zM2 9.47V7.644l9-4.09v22.672A20.092 20.092 0 012 9.47z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgShieldF
