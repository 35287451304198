import { ChangeEventHandler, MouseEventHandler } from 'react'
import Files from 'react-files'
import Typography from 'components/shared/typography/Typography'
import { Button } from 'components/shared/Button'
import * as S from './styles'

export default function Encryption({
  handleEncryptTextareaOnChange,
  encryptedData,
  uploadEncryptFileIsLoading,
  handleEncryptButton,
  encryptIsLoading,
  onFilesChange,
  onFilesError,
}: {
  handleEncryptTextareaOnChange: ChangeEventHandler<HTMLTextAreaElement>
  encryptedData: any
  uploadEncryptFileIsLoading: boolean
  handleEncryptButton: MouseEventHandler<HTMLButtonElement>
  encryptIsLoading: boolean
  onFilesChange: Function
  onFilesError: Function
}) {
  return (
    <S.EncryptContainer>
      <Typography variant="title5" as="label">
        Encrypt
      </Typography>
      <S.TopContainer />
      <S.StyledTextarea
        data-pho="encryptTextbox"
        onChange={handleEncryptTextareaOnChange}
        value={encryptedData}
      />
      <S.ActionButtonContainer>
        <Files
          className="files-dropzone"
          onChange={(file: any) => onFilesChange(file, 'encrypt')}
          onError={(error: any) => onFilesError(error, 'encrypt')}
          accepts={['.csv']}
          // maxFileSize={10000000}
          clickable
        >
          <Button
            data-pho="uploadCSVEncrypt"
            variant="secondary"
            loading={uploadEncryptFileIsLoading}
          >
            Upload CSV
          </Button>
        </Files>
        <Button data-pho="encryptButton" loading={encryptIsLoading} onClick={handleEncryptButton}>
          Encrypt
        </Button>
      </S.ActionButtonContainer>
    </S.EncryptContainer>
  )
}
