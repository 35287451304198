import * as React from 'react'

function SvgSportsF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
      <path
        d="M24 5.56v3.88A2.813 2.813 0 0121 12v-2c.61 0 1-.33 1-.56V5.56c0-.23-.39-.56-1-.56h-2v5a7.003 7.003 0 01-6 6.92V20h2.5a2.097 2.097 0 012.17 1.99c0-.03.06.01.16.01 1.15-.044 2.12.85 2.17 2v4H4v-4a2.092 2.092 0 012.17-2 .263.263 0 00.18-.06A2.053 2.053 0 018.5 20H11v-3.08A7.003 7.003 0 015 10V5H3c-.61 0-1 .33-1 .56v3.88c0 .15.17.33.41.44.186.082.387.123.59.12v2c-.486.003-.967-.1-1.41-.3A2.516 2.516 0 010 9.44V5.56A2.813 2.813 0 013 3h2V0h14v3h2a2.813 2.813 0 013 2.56z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSportsF
