import * as React from 'react'

function SvgShareF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 26" {...props}>
      <path
        d="M27 21a5 5 0 01-10 0c0-.13.03-.26.04-.39l-7.45-4.14a5.013 5.013 0 11.33-6.53l7.38-3.28A5.085 5.085 0 0117 5a5.027 5.027 0 111.34 3.39l-7.52 3.34c.11.415.171.841.18 1.27a5.029 5.029 0 01-.34 1.78l6.96 3.86A4.983 4.983 0 0127 21z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgShareF
