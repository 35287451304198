import * as React from 'react'

function SvgActivity(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 25" {...props}>
      <path
        d="M8 8v17H3a3.003 3.003 0 01-3-3V8h8zm20-3v17a3.003 3.003 0 01-3 3h-5V5h8zM18 0v25h-8V0h8zM6 10H2v12a1 1 0 001 1h3V10zm20-3h-4v16h3a1 1 0 001-1V7zM16 2h-4v21h4V2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgActivity
