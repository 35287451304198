import * as React from 'react'

function SvgPhoneOfficeF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M28 0v24H9V0h19zM7 0v24H3a3.009 3.009 0 01-3-3V3a3.009 3.009 0 013-3h4zm17 18H13v2h11v-2zm0-4H13v2h11v-2zm0-10H13v8h11V4zm-2 2v4h-7V6h7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPhoneOfficeF
