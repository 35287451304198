import * as React from 'react'

function SvgChatF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M13 0h-.01a13.242 13.242 0 00-9.3 3.27C1.28 5.54 0 8.91 0 13v13h13c8.02 0 13-4.98 13-13A12.577 12.577 0 0013 0zm2 16H6v-2h9v2zm5-4H6v-2h14v2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChatF
