import * as React from 'react'

function SvgKeyF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 29" {...props}>
      <path
        d="M17.56 12.14c.085-.242.155-.49.21-.74.03-.12.06-.25.1-.43.083-.486.126-.977.13-1.47a8.592 8.592 0 10-7.1 8.37c.156-.017.31-.05.46-.1.264-.057.525-.13.78-.22l3.44 3.44h2l.42.41v2l1.58 1.59h2l.42.41v2.01L23.59 29H29v-5.42L17.56 12.14zM8 10a2 2 0 110-4 2 2 0 010 4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgKeyF
