import * as React from 'react'

function SvgOutlet(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M14.002 17.002a2 2 0 012 2v2h-6v-2a2 2 0 012-2h2zm-4-12v9h-4v-9h4zm10 0v9h-4v-9h4zm3 21h-23v-23a2.9 2.9 0 013-3h20a2.9 2.9 0 013 3v20a2.9 2.9 0 01-3 3zm-21-2h21a.9.9 0 001-1v-20a.9.9 0 00-1-1h-20a.9.9 0 00-1 1v21z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgOutlet
