import * as React from 'react'

function SvgCallIncoming(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 28" {...props}>
      <path
        d="M6.913.788a2.992 2.992 0 012.175.886l3.171 3.17L8.566 11H5.66a10.353 10.353 0 00.08 6h2.826l3.693 6.155-3.171 3.171a3.038 3.038 0 01-2.14.886l-.072-.001a2.911 2.911 0 01-2.107-.957c-6.34-6.94-6.34-17.569 0-24.508A2.992 2.992 0 016.913.788zm.035 2h-.024a.928.928 0 00-.674.302C.605 9.268.605 18.732 6.25 24.91c.173.19.417.299.674.302.28.008.552-.1.75-.3l2.067-2.067L7.434 19H4.279l-.228-.684c-1.084-3.252-1.063-6.528.054-8.763L4.382 9h3.052L9.74 5.155 7.674 3.088a1.03 1.03 0 00-.726-.3zM18.19 5.29l1.41 1.42-6.17 6.17 11.58.12-.02 2-11.52-.12 6.13 6.13-1.41 1.42-8.57-8.57 8.57-8.57z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCallIncoming
