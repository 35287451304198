import * as React from 'react'

function SvgMobile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 28" {...props}>
      <path
        d="M15 0H3a3.003 3.003 0 00-3 3v22a3.003 3.003 0 003 3h12a3.003 3.003 0 003-3V3a3.003 3.003 0 00-3-3zM3 2h12c.552 0 1 .448 1 1v1H2V3c0-.552.448-1 1-1zm13 4v16H2V6h14zm-1 20H3c-.552 0-1-.448-1-1v-1h14v1c0 .552-.448 1-1 1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMobile
