import React, { useState } from 'react'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import useApi from 'hooks/useApi'
import services from 'common/services'
import { DataType } from 'components/shared/chart/types'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import CustomLoading from 'components/shared/customLoading'
import useApps from 'hooks/useApps'
import useChartRefresh from 'hooks/useChartRefresh'
import useFilterValues from 'hooks/useFilterValues'
import { getRefreshIntervalFromStep } from 'utils'
import { getService, getDataSource, getQueryAppsStringified } from './utils'
import FormatData from './FormatData'
import * as T from './types'

function MultiData({
  children,
  queries,
  types,
  title,
  tabButtonProps,
  filterValues,
  handleTabChange,
  renderActionsMenu,
  renderTabButtons,
  componentsWithProps,
}: {
  children: T.ChildrenCallback
  queries: T.Query[]
  types: DataType[]
  title: string
  tabButtonProps?: {
    mainTitle?: string
    description?: string
    activeTab: number
    tabTitles: string[]
  }
  filterValues?: Record<string, any>
  handleTabChange?: any
  renderActionsMenu: Function
  renderTabButtons?: Function
  componentsWithProps?: Record<string, any>
}) {
  const { step, partialData } = useFilterValues()

  const { currentApps, entityID, appsByDataSource } = useApps()
  const [refreshInterval, setRefreshInterval] = useState<number>(() =>
    getRefreshIntervalFromStep(Number(step), !!partialData)
  )
  const chartQuery = queries.map((query, index) => {
    const serviceName = getService(types[index]).url
    const aggrQuery = query.aggrQuery
      ? {
          aggrQuery: {
            args: `${query.aggrQuery}&${queryString.stringify(
              getQueryAppsStringified(currentApps, query.aggrQuery, entityID, appsByDataSource.qube)
            )}`,
            serviceName,
          },
        }
      : {}
    const compareQuery = query.compareQuery
      ? {
          compareQuery: {
            args: `${query.compareQuery}&${queryString.stringify(
              getQueryAppsStringified(
                currentApps,
                query?.compareQuery,
                entityID,
                appsByDataSource.qube
              )
            )}`,
            serviceName,
          },
        }
      : {}

    return {
      query: {
        args: `${query.query}&${queryString.stringify(
          getQueryAppsStringified(currentApps, query?.query, entityID, appsByDataSource.qube)
        )}`,
        serviceName,
      },
      ...aggrQuery,
      ...compareQuery,
    }
  })

  const filteredQueries = chartQuery.map((query, index) => {
    if (tabButtonProps) {
      if (tabButtonProps.activeTab === index) return query
      else return { query: {}, aggrQuery: {} }
    } else return query
  })

  const [cachedApiVariables] = useState({
    charts: filteredQueries,
  })

  const { isLoading, error, data } = useApi({
    service: services.multiChart,
    body: cachedApiVariables,
    refreshInterval,
    method: 'POST',
  })

  useChartRefresh(setRefreshInterval, refreshInterval, data)

  const dataSources = types.map((source: DataType) => {
    return getDataSource(source)
  })
  const allSourcesEqual = dataSources.every((source, i, dataSources) => source === dataSources[0])

  if (isLoading) {
    if (tabButtonProps) {
      return (
        <CustomLoading
          title={title}
          dataSource={allSourcesEqual ? dataSources[0] : dataSources.join('/ ')}
          tabButtonChart
          tabButtonProps={tabButtonProps}
          renderTabButtons={renderTabButtons}
        />
      )
    }

    return (
      <CustomLoading
        title={title}
        dataSource={allSourcesEqual ? dataSources[0] : dataSources.join('/ ')}
      />
    )
  }

  const activeTabData = data && data[tabButtonProps?.activeTab || 0]?.data

  if (isEmpty(data) || isEmpty(activeTabData) || error) {
    if (tabButtonProps) {
      const activeChartProps = componentsWithProps?.[tabButtonProps?.activeTab || 0]?.props
      const metaData = (data && data[tabButtonProps?.activeTab || 0]?.dataMeta) || {}

      return (
        <NoDataChartMessage
          title={title}
          dataSource={allSourcesEqual ? dataSources[0] : dataSources.join('/ ')}
          tabButtonChart
          tabButtonProps={tabButtonProps}
          ActionsMenu={renderActionsMenu({
            metaData: { error: error, ...metaData },
            ...activeChartProps,
          })}
          renderTabButtons={renderTabButtons}
        />
      )
    }
    return (
      <NoDataChartMessage
        title={title}
        dataSource={allSourcesEqual ? dataSources[0] : dataSources.join('/ ')}
        ActionsMenu={renderActionsMenu({ metaData: { error: error } })}
        renderTabButtons={renderTabButtons}
      />
    )
  }

  function getMetaData(data: any) {
    return data.reduce((acc: string[], chart: Record<string, any>) => {
      const metaDataFromChart = chart.dataMeta?.realtimeDashboardInfo || chart.dataMeta?.filters
      if (metaDataFromChart) {
        return acc.concat(metaDataFromChart)
      }
      return acc
    }, [])
  }
  return (
    <FormatData
      rawDataArray={data}
      types={types}
      filterValues={filterValues}
      metaData={getMetaData(data)}
    >
      {children}
    </FormatData>
  )
}

export default MultiData
