import { Children, cloneElement, ReactNode, useCallback, useEffect, useState } from 'react'
import * as S from './styles'
import { debounce } from 'lodash'

interface CenterEllipsisProps {
  children: any
  setTooltipText: any
  setEllipsisActive: any
}

export const CenterEllipsis = ({
  children,
  setTooltipText,
  setEllipsisActive,
}: CenterEllipsisProps) => {
  const [currentWidth, setCurrentWidth] = useState<string>('')
  const [txtWidth, setTxtWidth] = useState<string>('')
  const [innerText, setInnerText] = useState<string>('')

  const ref = useCallback(node => {
    if (node !== null) {
      window.addEventListener(
        'resize',
        // If tooltip is active, get outside width
        debounce(
          () =>
            setCurrentWidth(
              node.parentNode?.parentNode?.offsetWidth || node.getBoundingClientRect().width
            ),
          200,
          { maxWait: 400 }
        )
      )
      // If tooltip is active, get outside width
      setCurrentWidth(
        node.parentNode?.parentNode?.offsetWidth || node.getBoundingClientRect().width
      )
      setTxtWidth(node?.childNodes[0]?.offsetWidth)
      setInnerText(node.innerText)
      setTooltipText(node.innerText)
    }
  }, [])

  useEffect(() => {
    if (currentWidth < txtWidth) setEllipsisActive(true)
    else if (currentWidth > txtWidth) setEllipsisActive(false)
  }, [currentWidth])

  const mappedChildren = Children.map(children, child => {
    if (txtWidth > currentWidth) {
      const str = innerText
      const strChar = str.length
      const avgLetterSize = parseInt(txtWidth) / strChar
      const canFit = (parseInt(txtWidth) - parseInt(currentWidth)) / avgLetterSize
      const delEachSide = (canFit + 8) / 2
      const endLeft = Math.floor(strChar / 2 - delEachSide)
      const startRight = Math.ceil(strChar / 2 + delEachSide)

      return cloneElement(child as any, {
        ...child.props,
        children: str.substr(0, endLeft) + '...' + str.substr(startRight),
      })
    }
    return child
  })

  return (
    <div ref={ref}>
      <S.CenterEllipsis>{mappedChildren}</S.CenterEllipsis>
    </div>
  )
}

export const RightEllipsis = ({
  children,
  ellipsis,
}: {
  children: ReactNode
  ellipsis: number
}) => <S.Ellipsis $ellipsis={ellipsis}>{children}</S.Ellipsis>
