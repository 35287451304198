import * as React from 'react'

function SvgPrint(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 27" {...props}>
      <path
        d="M20 .004v6h3a3.009 3.009 0 013 3v8a3.009 3.009 0 01-3 3h-3v6H6v-6H3a3.009 3.009 0 01-3-3v-8a3.009 3.009 0 013-3h3v-6h14zm-2 14H8v10h10v-10zM16 20v2h-6v-2h6zm7-11.996H3a1.003 1.003 0 00-1 1v8c.002.552.449.999 1 1h3V14H4v-2h18v2h-2v4.004h3a1.003 1.003 0 001-1v-8a1.003 1.003 0 00-1-1zM16 16v2h-4v-2h4zm2-13.996H8v4h10v-4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPrint
