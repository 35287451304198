import * as React from 'react'

function SvgLiveTv(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M25 0a3.003 3.003 0 013 3v14a3.003 3.003 0 01-3 3H15v2h4a2 2 0 012 2H7a2 2 0 012-2h4v-2H3a3.003 3.003 0 01-3-3V3a3.003 3.003 0 013-3zm0 2H3a1 1 0 00-1 1v14a1 1 0 001 1h22a1 1 0 001-1V3a1 1 0 00-1-1zM10 4.276L20.016 10 10 15.723V4.276zm2 3.447v4.553L15.985 10 12 7.723z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLiveTv
