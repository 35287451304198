import * as React from 'react'

function SvgWifiF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 23" {...props}>
      <path
        d="M20.28 17l1.01.52L17.53 23h-5.06l-3.75-5.47 1-.52A12.181 12.181 0 0120.28 17zm4.21-5.87l.82.57-3.15 4.56-.81-.49a12.999 12.999 0 00-12.69-.01l-.81.51L4.7 11.7l.8-.57c5.2-3.7 13.72-3.71 18.99 0zm4.13-5.92l.72.58-3.1 4.65-.84-.64c-5.67-4.29-15.19-4.29-20.79-.01l-.85.65L.65 5.78l.73-.57c7.43-5.79 19.9-5.79 27.24 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgWifiF
