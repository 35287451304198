import * as React from 'react'

function SvgCopy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M2 4v20h21v2H0V4h2zm21-4a3.003 3.003 0 013 3v15a3.003 3.003 0 01-3 3H5V3a3.003 3.003 0 013-3h15zm0 2H8c-.552 0-1 .448-1 1v16h16c.552 0 1-.448 1-1V3c0-.552-.448-1-1-1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCopy
