import * as React from 'react'

function SvgHand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <path
        d="M23 3h-1.184A2.995 2.995 0 0019 1h-1.78A2.982 2.982 0 0015 0h-3v1.184A2.965 2.965 0 0011 1H8v14.824l-4.88-4.186-2.241 2.24a3 3 0 00-.008 4.235L8.579 26H23a3.003 3.003 0 003-3V6a3.003 3.003 0 00-3-3zm1 12v8c0 .552-.448 1-1 1H9.42l-7.127-7.293a1.001 1.001 0 010-1.414l.93-.93L10 20.175V3h1c.552 0 1 .448 1 1v9h2V2h1c.552 0 1 .448 1 1v10h2V3h1c.552 0 1 .448 1 1v9h2V5h1c.552 0 1 .448 1 1v9z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHand
