import { LegendProps } from '@nivo/legends'
import { Box } from '@nivo/core'
import { ValueFormat, DatumValue } from '@nivo/core'
import { WindowLocation } from '@reach/router'
import { Layout } from 'react-grid-layout'
import { FormattedData, RawDataObj } from 'components/shared/ChartData/types'
import { LineData, FormatterArg } from 'components/shared/lineChart/types'
import { CustomMetricProperties } from 'screens/entityID/client-analytics/metrics/shared/types'

export interface ChartAndMultiChartProps {
  legends?: LegendProps[]
  margin?: Box
  yFormat?: string | ValueFormat<DatumValue>
}

export interface ChartProps extends ChartAndMultiChartProps {
  data: FormattedData | LineData
  mini?: boolean
  min?: string | number
  max?: string | number
  title?: string
  colors?: string[]
  isSmall?: boolean
}

export interface SingleChartObj {
  aggrQuery?: Query
  appNames?: string[]
  apps?: string[]
  appTypes?: string[]
  bottomLinkObj?: {
    title: string
    path: string
  }
  buttonTabbedSummaryFormatter?: (arg: FormatterArg) => any
  chartIsScrollable?: boolean
  chartQuery?: JSX.Element
  colors?: string[]
  columnHeaders?: string[]
  columns?: any
  content?: React.ReactNode
  customDescription?: string
  customFilters?: Record<string, any>
  customProperties?: CustomMetricProperties
  customLegend?: boolean
  customName?: string
  showRealTitleTooltip?: boolean
  dataSource?: string
  dataSources?: string[]
  description?: string
  displayMetaData?: boolean
  domains?: string[]
  excludeApps?: string[]
  excludeAppTypes?: string[]
  excludeDataSources?: string[]
  excludeDomains?: string[]
  excludeGroups?: string[]
  exludeAppNames?: string[]
  extendContent?: boolean
  removeColumnsWithoutValues?: boolean
  filterTemplates?: string[]
  firstRowPathObj?: {
    pathName: string
    queryParam: string
    customQueryParams?: Record<string, string>
  }
  getOpenSearchUrl?: (args: OpenSearchUrlArgs) => string
  groups?: string[]
  height?: number
  history?: JSX.Element
  id?: string
  isAreaChart?: boolean
  isComparable?: boolean
  isEditing?: boolean
  isSmall?: boolean
  isStacked?: boolean
  layout?: Layout
  limit?: number
  location?: WindowLocation
  margin?: {
    bottom?: number
    left?: number
    right?: number
    top?: number
  }
  max?: string | number
  min?: string | number
  query?: Query
  rawDataArray?: RawDataObj[]
  removeChart?: Function
  showPreviousQuery?: boolean
  showSearch?: boolean
  summaryFormatter?: (arg: FormatterArg) => string
  tableData?: any
  title: string
  tooltipFormat?: (tooltip: string | number | Date) => string
  type: DataType
  underConstruction?: boolean
  underConstructionExpectedDate?: string
  underConstructionText?: React.ReactElement | string
  width?: number
  xFormat?: string | ((x: string | number | Date) => string)
  yFormat?: string | ((y: string | number | Date) => string)
  isMetricOutdated?: boolean
  total?: number
  displayOnlyTotal?: boolean
  filterValuesToTrim?: string[]
}

export interface MultiChartObj {
  addTabIndexInURL?: boolean
  chartIsScrollable?: boolean
  charts: SingleChartObj[]
  customFilters?: Record<string, any>
  dataSource?: string
  description?: string
  extendContent?: boolean
  removeColumnsWithoutValues?: boolean
  hasTabButtons?: boolean
  height?: number
  isTabbed?: boolean
  layout?: Layout
  location?: WindowLocation
  mainTitle?: string
  rawDataArray?: RawDataObj[]
  type: DataType
  width?: number
}

export interface OSQuery {
  type: string
  last?: string
  period?: string
  metric: string
  limit?: number
  filter?: string[]
  messageName?: string
  group?: string | string[] | null
  formatters?: string[]
}

export interface DruidQuery extends OSQuery {
  source?: string
  step?: number
  filter?: string[]
  filterNot?: string[]
  messageName?: string
  divide?: {
    metric: string
    name: string
    type: string
    last?: string
    period?: string
    source: string
    step: number
  }
}

export type Query = OSQuery | DruidQuery

export enum DataType {
  LINE,
  PERCENTAGE,
  MULTI,
  TOP,
  OS_TOP,
  OS_LINE,
  SUNBURST,
  SUNBURST_WITH_TOP,
  OS_TABLE,
  OS_SUNBURST_WITH_TOP,
  OS_BAR_WITH_TOP,
  BAR,
  BAR_WITH_TOP,
  SANKEY,
  OS_SANKEY,
  CUSTOM,
  HORIZONTAL_BAR,
  OS_HORIZONTAL_BAR,
  OS_SUNBURST,
  TABLE,
  TECH_MOBILE_LINE,
  GRID,
  NETWORK,
  METRIC_LINE,
  METRIC_BAR,
  METRIC_TABLE,
  METRIC_AGGREGATE,
  STATS,
}

export interface OpenSearchUrlArgs {
  name?: string
  type?: string
  interval: string
  errorCode: string
  apiName: string
  pageName: string
  eventCaseID: string
  indexId: string
  openSearchIndex: string
  openSearchFrom: string
  openSearchTo: string
  appNameString: string
  appTypeString: string
  appNameMatchPhraseList: any
  appTypeMatchPhraseList: any
  appNameStringSecond: string
  appTypeStringSecond: string
  currentGroupsIds: string[]
  releaseVersion: string
  isLessThanDayDistance?: boolean
}

export type ChartObj = SingleChartObj | MultiChartObj
