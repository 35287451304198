import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import SunburstChart from 'components/shared/sunburstChart'
import TopChart from 'components/shared/topChart'
import { FormattedTopData, FormattedSunburstData } from 'components/shared/ChartData/types'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import * as S from './styled'

export default function SunburstWithTopChart({
  data,
  mini,
  title,
  ...props
}: {
  data: {
    sunburstData: FormattedSunburstData
    topData: {
      topData: FormattedTopData
      aggrData?: {
        avg: number
        total: number
      }
    }
  }
  mini?: boolean
  title?: string
}) {
  const [hoveredItemPath, setHoveredItemPath] = useState<string[] | undefined>()

  function hanldeHoveredItemPath(path: string[]) {
    setHoveredItemPath(path)
  }

  if (mini) {
    return (
      <S.MiniContainer>
        <h4>{title}</h4>
      </S.MiniContainer>
    )
  }
  if (isEmpty(data)) {
    return <NoDataChartMessage title={title} />
  }

  return (
    <S.Container>
      <TopChart data={data.topData} hoveredItemPath={hoveredItemPath} {...props} />
      <SunburstChart
        key={`sunburst ${title}`}
        data={data.sunburstData}
        hoveredItemPath={hanldeHoveredItemPath}
        {...props}
      />
    </S.Container>
  )
}
