// filterTemplates are used to define which filter can be applied to specific chart
export const filterTemplates = {
  video: [
    'applicationType',
    'technologyType',
    'applicationName',
    'networkStatus',
    'connectionType',
    'deviceLocation',
    'legacyCompany',
    'computedMSO',
    'applicationAndVersion',
    'locationState',
    'experimentUuids',
    'variantUuids',
    'appVersion',
    'appSection',
    'applicationNameAndType',
    'activatedExperiments',
    'apiClusterVersion',
    'division',
  ],
  portals: [
    'platformType',
    'browser',
    'legacyCompany',
    'computedMSO',
    'appVersion',
    'appSection',
    'applicationType',
    'applicationName',
    'applicationNameAndType',
    'experimentUuids',
    'variantUuids',
    'activatedExperiments',
    'mobileStatus',
    'configurationFactors',
    'apiClusterVersion',
    'division',
    'environmentName',
    'operatingSystem',
  ],
  specGuide: [
    'legacyCompany',
    'computedMSO',
    'networkStatus',
    'connectionType',
    'appVersion',
    'appSection',
    'division',
    'applicationNameAndType',
    'experimentUuids',
    'variantUuids',
    'activatedExperiments',
    'apiClusterVersion',
    'division',
  ],
  playbackFilters: [
    'applicationType',
    'technologyType',
    'applicationName',
    'streamType',
    'streamContentFormat',
    'contentClass',
    'networkStatus',
    'connectionType',
    'deviceLocation',
    'legacyCompany',
    'computedMSO',
    'streamCdn',
    'drmType',
    'daienabled',
    'applicationAndVersion',
    'locationState',
    'experimentUuids',
    'variantUuids',
    'applicationNameAndType',
    'activatedExperiments',
    'apiClusterVersion',
    'division',
  ],
  playbackFiltersFixedType: [
    'applicationType',
    'technologyType',
    'applicationName',
    'streamContentFormat',
    'networkStatus',
    'connectionType',
    'deviceLocation',
    'legacyCompany',
    'computedMSO',
    'streamCdn',
    'drmType',
    'daienabled',
    'applicationAndVersion',
    'locationState',
    'experimentUuids',
    'variantUuids',
    'applicationNameAndType',
    'activatedExperiments',
    'apiClusterVersion',
    'division',
  ],
  avgLoginDuration: [
    'applicationType',
    'technologyType',
    'applicationName',
    'networkStatus',
    'connectionType',
    'deviceLocation',
    'legacyCompany',
    'computedMSO',
    'applicationAndVersion',
    'applicationNameAndType',
    'experimentUuids',
    'variantUuids',
    'activatedExperiments',
    'apiClusterVersion',
    'division',
    'operatingSystem',
  ],
}
