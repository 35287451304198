import * as React from 'react'

function SvgNotes(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 20" {...props}>
      <path
        d="M24.2 2H9V0h15.2a.917.917 0 01.8 1 .917.917 0 01-.8 1zm-.2 9H5V9h19a1 1 0 010 2zM0 0h7v2H0V0zm0 9h3v2H0V9zm24.2 11H9v-2h15.2a.917.917 0 01.8 1 .917.917 0 01-.8 1zM0 18h7v2H0v-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgNotes
