import * as React from 'react'

function SvgSpectrumSans(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 26" {...props}>
      <path
        d="M29 26h-8.2l-2-5h-8.6l-2.1 5H0L10.8 0h7.4L29 26zm-6.8-2H26L16.8 2h-4.6L3 24h3.8l2.1-5h11.2l2.1 5zm-2.9-7H9.7l4.8-11.6L19.3 17zm-6.6-2h3.6l-1.8-4.4-1.8 4.4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSpectrumSans
