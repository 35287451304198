import * as React from 'react'

function SvgCashPayments(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 22" {...props}>
      <path
        d="M28 20v2H0v-2h28zM25 0a3.003 3.003 0 013 3v12a3.003 3.003 0 01-3 3H0V3a3.003 3.003 0 013-3zM2 12.1V16h3.9A5.015 5.015 0 002 12.1zm24 0a5.015 5.015 0 00-3.9 3.9H25a1 1 0 001-1zM20.08 2H7.92A7.005 7.005 0 012 7.92v2.16A7.005 7.005 0 017.92 16h12.16A7.005 7.005 0 0126 10.08V7.92A7.005 7.005 0 0120.08 2zM14 4a5 5 0 110 10 5 5 0 010-10zm0 2a3 3 0 100 6 3 3 0 000-6zm11-4h-2.9A5.015 5.015 0 0026 5.9V3a1 1 0 00-1-1zM5.9 2H3a1 1 0 00-1 1v2.9A5.015 5.015 0 005.9 2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCashPayments
