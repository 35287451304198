import React from 'react'
import Autocomplete from 'components/shared/Autocomplete'

import * as T from './types'
import * as S from './styled'

const ACTIVATION_METHOD_PATHS = {
  0: 'IVR/MSA',
  1: 'IVR/MSA/TechMobile',
  2: 'IVR/Portal',
  3: 'IVR/Portal/MSA',
  4: 'IVR/Portal/MSA/TechMobile',
  5: 'IVR/Portal/TechMobile',
  6: 'IVR/TechMobile',
  7: 'IVR Only',
  8: 'MSA/TechMobile',
  9: 'MSA Only',
  10: 'Not Applicable',
  11: 'OnScreen/TechMobile',
  12: 'OnScreen Only',
  13: 'Portal/MSA',
  14: 'Portal/MSA/TechMobile',
  15: 'Portal/OnScreen',
  16: 'Portal/TechMobile',
  17: 'Portal Only',
  18: 'TechMobile Only',
}

export const getPathValue = (activationMethodPathNumbers?: number[] | string[]) => {
  return activationMethodPathNumbers?.map(
    activationMethodPathNumber => ACTIVATION_METHOD_PATHS[activationMethodPathNumber] as string
  )
}
function VoCActivationMethodPathFilter({
  filter,
  onChange,
  filterValues,
}: {
  filter: T.Filter
  onChange: (filterValues: T.FilterValues) => void
  filterValues: T.FilterValues
}) {
  const { activationMethod } = filterValues

  function handleActivationMethodChange(values: number[]) {
    if (values.length >= 1) {
      const newActivationMethod = values.map(path => {
        return Object.keys(ACTIVATION_METHOD_PATHS).find(
          key => ACTIVATION_METHOD_PATHS[key] === path
        )
      }) as string[]

      onChange({
        activationMethod: newActivationMethod,
        vocActivationMethod: null,
        vocActivationTypeOutcome: null,
      })
      return
    }

    onChange({
      activationMethod: [],
      vocActivationMethod: null,
      vocActivationTypeOutcome: null,
    })
  }

  const defaultValue = getPathValue(activationMethod)

  return (
    <S.VocCategoryGroupContainer key={JSON.stringify(activationMethod)}>
      <Autocomplete
        label="Activation Method Path"
        options={Object.values(ACTIVATION_METHOD_PATHS)}
        defaultValue={defaultValue}
        onChange={value => handleActivationMethodChange(value)}
        fullHeight
        width={S.FILTER_WIDTH}
        showTagTooltip
        data-pho="filterMenuAutoCompleteInput"
      />
    </S.VocCategoryGroupContainer>
  )
}

export default VoCActivationMethodPathFilter
