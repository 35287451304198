import React from 'react'
import isEmpty from 'lodash/isEmpty'
import lodashDefaults from 'lodash/defaults'
import startCase from 'lodash/defaults'
import isEqual from 'lodash/isEqual'
import compact from 'lodash/compact'
import useTimeMenu, { TimeValues } from 'hooks/useTimeMenu'
import useFilterMenu from 'hooks/useFilterMenu'
import useCompareMenu from 'hooks/useCompareMenu'
import useQueryStringParams from 'hooks/useQueryStringParams'
import useApps from 'hooks/useApps'
import useGroups from 'hooks/useGroups'
import { Period } from 'components/shared/VoCTimeMenu'
import { getDefaultFilters } from 'components/shared/VoCTimeMenu/utils'
import { Filter } from 'components/shared/FilterMenu/types'
import PillGroup from 'components/shared/PillGroup'
import { Header, HeaderProps } from 'components/shared/Header'
import { colors } from 'components/shared/utils/colors'
import Typography from 'components/shared/typography/Typography'
import { getDefaultTimeFilterRange, getDefaultTimeFilterStep } from 'utils/times/times'
import defaults from './defaults'
import { useLocation } from '@reach/router'

export interface HeaderState {
  groupBys?: Filter[] | null
  filters?: Filter[]
  periods?: Period[]
  title?: string
  pageName: string
  pageTitle?: string
  pageType?: string
  description?: string
  subTitle?: string
  displayFiscalFilter?: boolean
  displayTimeZoneFilter?: boolean
  hideStepSlider?: boolean
  hideHeader?: boolean
  hidePills?: boolean
  toolbarStyles?: Record<string, any>
  hideTitle?: boolean
  showToolbar?: boolean
  refresh?: number
  areFiltersReady: boolean
  compare?: Filter[] | null
  periodParams?: any
  absoluteTimeRangeLimit?: number
  isVoC?: boolean
  belowTheFold?: string
}

export interface HeaderComponentProps extends HeaderProps {
  disabledFilterMenu?: boolean
  useFilterApplyButton?: boolean
}

export interface HeaderValue {
  filterValues: Record<string, any>
  timeValues: Partial<TimeValues>
  groupByValues: Record<string, any>
  updateState: (newState: Partial<HeaderState>) => void
  resetState: () => void
  filters?: Filter[]
  Header: React.FC<HeaderComponentProps>
  FilterMenu: React.FC<any>
  updateValues: (newParamValues: Record<string, any>, initialize?: boolean) => void
  batchUpdateValues: (update: { values: Record<string, any>; delay?: any; batchID?: number }) => any
  state: HeaderState
  rawValues: Record<string, any>
}

const initialValue: HeaderValue = {
  groupByValues: {},
  filterValues: {},
  timeValues: {},
  updateState: () => null,
  resetState: () => null,
  Header: () => null,
  FilterMenu: () => null,
  updateValues: () => null,
  batchUpdateValues: () => 0,
  state: {
    pageName: '',
    areFiltersReady: false,
  },
  rawValues: {},
}

export const HeaderContext = React.createContext<HeaderValue>(initialValue)

function getTimeParams(
  periods?: Period[],
  displayTimeZoneFilter?: boolean,
  isVoC?: boolean
): Filter[] {
  if (isEmpty(periods)) return []
  const defaultTimeFilterRange = getDefaultTimeFilterRange()
  const defaultTimeFilterStep = getDefaultTimeFilterStep()
  return (
    [
      {
        id: 'period',
        initialValue: defaultTimeFilterRange,
      },
      {
        id: 'step',
        initialValue: defaultTimeFilterStep,
      },
      {
        id: 'range',
        initialValue: null,
      },
      ...(isVoC ? [{ id: 'periodOffset', initialValue: 1 }] : []),
    ] as Filter[]
  ).concat(getDefaultFilters(displayTimeZoneFilter))
}

function HeaderProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation()
  const [currentPathname, setCurrentPathname] = React.useState(location.pathname)
  const [canStateReset, setCanStateReset] = React.useState(true)

  const defaultPeriods: Period[] = []

  const exceptions = ['schema', 'event-types', 'event-cases', 'test-cases', 'status']

  const inWhiteList = () =>
    Boolean(exceptions.find((item: string) => location.pathname.includes(item)))

  const defaultState: HeaderState = {
    groupBys: null,
    filters: defaults.filters,
    periods: inWhiteList() ? defaultPeriods : defaults.time.periods,
    compare: null,
    toolbarStyles: {
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    pageName: '',
    pageTitle: '',
    pageType: '',
    subTitle: '',
    refresh: 0,
    displayFiscalFilter: false,
    displayTimeZoneFilter: false,
    hideStepSlider: false,
    hidePills: false,
    hideHeader: false,
    hideTitle: false,
    showToolbar: true,
    areFiltersReady: false,
    isVoC: location?.pathname?.includes('/voice-of-customer'),
  }

  const [state, setState] = React.useState<HeaderState>(defaultState)

  const timeParams = getTimeParams(state.periods, state.displayTimeZoneFilter, state.isVoC)
  const allParams = {
    params: [
      ...(state.filters ?? []),
      ...timeParams,
      ...(state.groupBys ?? []),
      ...(state.compare ?? []),
    ],
  }

  const validPeriods =
    !state?.isVoC && currentPathname !== location.pathname ? defaults.time.periods : state.periods

  const { entityID, currentApps } = useApps()
  const { currentGroup } = useGroups()
  const currentAppOrGroup = currentGroup ? currentGroup.name : entityID || ''
  const { values, updateValues, batchUpdateValues } = useQueryStringParams(
    allParams,
    state,
    validPeriods,
    currentApps
  )
  const { filterValues: groupByValues, FilterMenu: GroupByMenu } = useFilterMenu({
    filters: state.groupBys || [],
    values,
    updateValues,
    batchUpdateValues,
  })
  const { filterValues, FilterMenu } = useFilterMenu({
    filters: state.filters || [],
    values,
    updateValues,
    batchUpdateValues,
  })
  const { timeValues, TimeMenu } = useTimeMenu({
    periods: validPeriods,
    values,
    updateValues,
  })

  const { CompareMenu } = useCompareMenu({
    values,
    updateValues,
  })

  function HeaderComponent(props: HeaderComponentProps) {
    return (
      <Header
        {...props}
        title={state.title}
        hideTitle={state.hideTitle}
        showToolbar={state.showToolbar}
        toolbarStyles={state.toolbarStyles}
        subTitle={state.subTitle}
        toolbar={
          <>
            {state.compare && <CompareMenu />}
            {state.groupBys && <GroupByMenu label="Group By" isGroupByMenu />}
            {!isEmpty(state.filters) && (
              <FilterMenu
                disabled={props.disabledFilterMenu}
                useFilterApplyButton={props.useFilterApplyButton}
              />
            )}
            {!isEmpty(state.periods) && (
              <TimeMenu
                hideStepSlider={state.hideStepSlider}
                displayFiscalFilter={state.displayFiscalFilter}
                displayTimeZoneFilter={state.displayTimeZoneFilter}
                absoluteTimeRangeLimit={state.absoluteTimeRangeLimit}
              />
            )}
          </>
        }
        belowTheFold={
          (!state.hidePills && <PillGroup updateValues={updateValues} />) ||
          (state.belowTheFold && (
            <Typography variant="caption" color={colors.gray40} data-test="CreatedOn">
              {state.belowTheFold}
            </Typography>
          ))
        }
      />
    )
  }

  function resetState(): void {
    if (canStateReset) {
      setState(defaultState)
      setCanStateReset(false)
    }
  }

  function updateState(headerStateUpdates: Partial<HeaderState>): void {
    const newState = lodashDefaults({}, headerStateUpdates, defaultState, state)

    if (!isEqual(newState, state)) {
      setState(newState)
      setCanStateReset(true)
    }
  }

  React.useEffect(() => {
    document.title = compact([
      state.title,
      state.pageType ? startCase(state.pageType) : '',
      'Prism',
      currentAppOrGroup,
    ]).join(' | ')
  }, [state.title, currentAppOrGroup]) // eslint-disable-line react-hooks/exhaustive-deps

  if (currentPathname !== location.pathname) {
    const newState = {
      ...state,
      areFiltersReady: false,
    }
    setState(newState)
    setCurrentPathname(location.pathname)
    return (
      <HeaderContext.Provider
        value={{
          updateState,
          resetState,
          timeValues,
          filterValues,
          groupByValues,
          filters: allParams.params,
          Header: HeaderComponent,
          FilterMenu,
          updateValues,
          batchUpdateValues,
          state: newState,
          rawValues: values,
        }}
      >
        {children}
      </HeaderContext.Provider>
    )
  }

  return (
    <HeaderContext.Provider
      value={{
        updateState,
        resetState,
        timeValues,
        filterValues,
        groupByValues,
        filters: allParams.params,
        Header: HeaderComponent,
        FilterMenu,
        updateValues,
        batchUpdateValues,
        state,
        rawValues: values,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderProvider

export const HeaderConextProvider = HeaderContext.Provider
export const HeaderConextConsumer = HeaderContext.Consumer
