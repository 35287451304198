import styled from 'styled-components'
import { colors } from 'components/shared/utils/colors'
import { element } from 'components/shared/utils/spacing'
import Link from 'components/shared/Link/PrismReactLink'

export const EncryptContainer = styled.div`
  text-align: center;
  margin-left: 1rem;
`

export const DecryptContainer = styled.div`
  text-align: center;
  margin-right: 1rem;
`

export const InstructionContainer = styled.div`
  max-width: 90%;
  margin: 0 1.5rem 0rem 0;
`

export const InstructionMessage = styled.p`
  white-space: nowrap;
`

export const StyledTextarea = styled.textarea`
  width: 20rem;
  height: 9rem;
`

export const DoNotHaveAccessContainer = styled.div`
  min-width: 25rem;
  min-height: 18.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DownloadButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1rem;
  && button {
    font-size: 0.7rem;
  }
`

export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const Subtitle = styled.div`
  font-size: 1.1rem;
  margin-bottom: 1.875rem;
  margin-top: 0.25rem;
`

export const FormatError = styled.div`
  font-size: 0.7rem;
  color: ${colors.red10};
`

export const TopContainer = styled.div`
  height: 1.2rem;
  display: flex;
`

export const StyledAlertContainer = styled.div`
  margin-bottom: 1rem;
`

export const PopoverContainer = styled.div`
  padding-right: 15px;
  margin-top: 0.5rem;
`

export const StyledLink = styled(Link)`
  margin-top: ${element.sm};
`

export const PopoverContentContainer = styled.div`
  padding: 1rem;
  width: 20rem;
`
