import { useState, useEffect } from 'react'
import { rootApiUrl } from 'common/constants'
import { axios, axiosConfigBase } from 'hooks/useApi'

const MINUTES_10 = 600000

async function getUserVersion() {
  try {
    const response = await axios.get(`${rootApiUrl}/version`, axiosConfigBase())
    const data = response.data
    if (!data) {
      throw new Error('The response did not have data')
    }
    return { data }
  } catch (error) {
    console.error(error)
    return { error: 'There was an error fetching data.' }
  }
}

async function compareAppVersions(currentAppVersion: string) {
  try {
    const apiAppVersionData = await getUserVersion()
    const apiAppVersion = apiAppVersionData?.data?.version
    return currentAppVersion >= apiAppVersion
  } catch {
    return true //if there was an error fetching data, true is returned to not display notification
  }
}

export default function useVersion(currentAppVersion: string) {
  const [isLatestVersion, setIsLatestVersion] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      async function fetchData() {
        const isAppUpToDate = await compareAppVersions(currentAppVersion)
        setIsLatestVersion(isAppUpToDate)
      }
      fetchData()
    }, MINUTES_10)
    return () => clearInterval(interval)
  }, [isLatestVersion, currentAppVersion])

  return isLatestVersion
}
