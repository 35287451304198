import * as React from 'react'

function SvgArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 29" {...props}>
      <path
        d="M15 29h-2V4.473L1.979 15.494.565 14.08 14 .645 27.435 14.08l-1.414 1.414L15 4.473z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgArrowUp
