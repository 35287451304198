import React from 'react'
import isEmpty from 'lodash/isEmpty'
import Flex from 'components/shared/flex/Flex'
import { IconButton } from 'components/shared/IconButton'
import MenuList from '@material-ui/core/MenuList'
import { UserPageFavorite } from 'components/App/Providers/Data/PrismUser'
import PageFavoritesList from './PageFavoritesList'
import SmallerHeading from '../SmallerHeading'
import * as S from '../styles'

export default function FavoritePages({
  currentPageFavorites,
  isEditPageMode,
  handleOpenAddDialog,
  handleClose,
  handleDeleteFavorite,
  handleEditFavoriteModal,
  setIsEditPageMode,
}: FavoritePagesProps) {
  return (
    <S.FavoriteTypeContainer>
      <Flex direction="column" width="16rem" padding="0 0 1.25rem 0" data-test="pagesSection">
        <Flex alignItems="center" height="3rem">
          <SmallerHeading title="Pages" dataTest="pagesSectionHeading" />
          {!isEmpty(currentPageFavorites) && (
            <IconButton
              icon={isEditPageMode ? 'EditF' : 'Edit'}
              onClick={() => setIsEditPageMode(!isEditPageMode)}
              data-pho="editPageFavorites"
            />
          )}
        </Flex>
        {isEmpty(currentPageFavorites) ? (
          <S.StyledAddPageButton
            variant="borderless"
            icon="PlusCircleF"
            width="100%"
            data-test="addPageFavoritesSectionButton"
            onClick={() => handleOpenAddDialog()}
          >
            Add Current Page
          </S.StyledAddPageButton>
        ) : (
          <MenuList>
            <PageFavoritesList
              handleClose={handleClose}
              pageFavorites={currentPageFavorites}
              handleDeleteFavorite={handleDeleteFavorite}
              isEditPageMode={isEditPageMode}
              handleEditFavoriteModal={handleEditFavoriteModal}
            />
          </MenuList>
        )}
      </Flex>
    </S.FavoriteTypeContainer>
  )
}

interface FavoritePagesProps {
  currentPageFavorites: UserPageFavorite[]
  isEditPageMode: boolean
  handleOpenAddDialog: Function
  handleClose: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  handleDeleteFavorite: Function
  handleEditFavoriteModal: Function
  setIsEditPageMode: Function
}
